import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
// import type { Dayjs } from 'dayjs';
import { PlusOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Drawer,
  Steps,
  Space,
  message,
  DrawerProps,
  Switch,
  Radio,
  RadioChangeEvent,
  TimePicker,
  Dropdown,
  Tooltip 
} from "antd";
import { useNavigate, useLocation } from 'react-router-dom'
import customParseFormat from "dayjs/plugin/customParseFormat";
import RuleGroupsSetting from "../../../Tag/RuleTags/CreateLabel/component/RuleGroupsSetting";
import CreatePrompt from "../../../Tag/RuleTags/CreateLabel/component/CreatePrompt";
import OperateLog from '../../../UserGrouping/components/OperateLog'
import RelationshipDivider from "../../../Tag/RuleTags/CreateLabel/component/RelationshipDivider";
import {
  crowdAddApi,
  crowdUpdateApi,
  crowdEstimateApi,
  crowdCheckEditApi,
  getCrowdInfoApi
} from "../../../../api/label/label";
import { createCrowdQuery } from "../../../../api/label/types";
import { GroupListVO } from "../../../../api/userGroup/type";
import { state } from "../../../../store";
import styles from "./index.module.scss";
import { CROWD_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import { getUserClickApi } from '../../../../api/home/home'

const { TextArea } = Input;

const radioGroupOptions = [
  { label: "每天", value: 0 },
  { label: "每周", value: 1 },
  { label: "每月", value: 2 },
];

const weekOptions = [
  { label: "星期一", value: "1" },
  { label: "星期二", value: "2" },
  { label: "星期三", value: "3" },
  { label: "星期四", value: "4" },
  { label: "星期五", value: "5" },
  { label: "星期六", value: "6" },
  { label: "星期日", value: "7" },
];

const monthOptions = [
  { label: "1号", value: "1" },
  { label: "2号", value: "2" },
  { label: "3号", value: "3" },
  { label: "4号", value: "4" },
  { label: "5号", value: "5" },
  { label: "6号", value: "6" },
  { label: "7号", value: "7" },
  { label: "8号", value: "8" },
  { label: "9号", value: "9" },
  { label: "10号", value: "10" },
  { label: "11号", value: "11" },
  { label: "12号", value: "12" },
  { label: "13号", value: "13" },
  { label: "14号", value: "14" },
  { label: "15号", value: "15" },
  { label: "16号", value: "16" },
  { label: "17号", value: "17" },
  { label: "18号", value: "18" },
  { label: "19号", value: "19" },
  { label: "20号", value: "20" },
  { label: "21号", value: "21" },
  { label: "22号", value: "22" },
  { label: "23号", value: "23" },
  { label: "24号", value: "24" },
  { label: "25号", value: "25" },
  { label: "26号", value: "26" },
  { label: "27号", value: "27" },
  { label: "28号", value: "28" }
];

const CreateUserGrouping = () => {
  const items = [
    {
      crowd_value_id: String(new Date().valueOf()) + "02", //生成
      id: String(new Date().valueOf()) + "02",
      operate_label_id: String(new Date().valueOf()) + "02",
      is_copy: false, // 此值用于标签值名称是以文本方式还是输入框方式展示
      label_open: true,
      label_value: "标签值",
      action_bar_show: false,
      people_num: "--",
      data_sources: "create",
      rules: {
        combinator: "and",
        rules: [
          {
            rules_value: "规则组",
            action_bar_show: false,
            rules_value_id: String(new Date().valueOf()) + "03",
            combinator: "and",
            data_sources: "create",
            is_copy: false, // 此值用于规则组名称是以文本方式还是输入框方式展示
            rules: [
              {
                rules_Label_value: null,
                rules_operate_id: String(new Date().valueOf()) + "04",
                table_name: "",
                field_type: "",
                field_code: "",
                label_id: null,
                field: null,
                field_id: null,
                min: "",
                max: "",
                time: null,
                time_value: null,
                time_key: '1',
                is_custom: false,
                custom_value: null,
                custom_checked: null,
                timeStore: "",
                operator: null,
                operator_id: null,
                operator_value: "",
                behavior: "做过",
                value: "",
                value_select: "",
                value_time: null,
                month_open: false,
                statistics_type: "总次数", // 统计方式
                behavior_field_id: null,
                behavior_field_value: "",
                behavior_time_open: false
              },
            ],
          },
        ],
      },
    },
  ];

  

  const navigate = useNavigate();

  let location = useLocation();

  const [flag, setFlag] = useState(location.state.flag);

  const [current, setCurrent] = useState(0);

  const [crowdId, setCrowdId] = useState("");

  const [openLog, setOpenLog] = useState(false);
  const [crowIdRecord, setCrowIdRecord] = useState({} as GroupListVO)
  const openLogs = (info: any) => {
    setCrowIdRecord(info)
    setOpenLog(true)
  }

  // 分群规则操作参数
  const [paramsList, setParamsList] = useState(
    JSON.parse(JSON.stringify(items))
  );

  const [accountForm] = Form.useForm();

  // 查看状态
  type FieldType = {
    crowdName: string;
    crowdDescribe: string;
    enable: number;
    enableFlag: boolean;
    updateType: string;
    updateRate: number;
    updateRateDate: any;
    weekDay: string;
    monthDay: string;
    // dateTime: any;
  };

  const formData: FieldType = {
    crowdName: "",
    crowdDescribe: "",
    enable: 1,
    enableFlag: true,
    updateType: "0",
    updateRate: 0,
    updateRateDate: null,
    weekDay: "",
    monthDay: "",
    // dateTime: "",
  };

  const [conditions, setConditions] = useState(
    JSON.parse(JSON.stringify(formData))
  );
  const changeConditions = (newConditions: any) => {
    if (newConditions.updateRate) {
      setConditions({
        ...conditions,
        ...newConditions,
        updateRateDate: null,
        dateTime: ''
      });
    } else {
      setConditions({
        ...conditions,
        ...newConditions,
      });
    }
  };

  // 编辑数据处理
  const handleData = () => {
    const obj = {
      crowdName: location.state.editData.crowdName,
      crowdDescribe: location.state.editData.crowdDescribe,
      enable: location.state.editData.enable,
      enableFlag: location.state.editData.enable === 1,
      updateType: location.state.editData.updateType,
      updateRate: location.state.editData.updateRate,
      updateRateDate: location.state.editData.updateRate === 0 ? null : location.state.editData.updateRate === 1 ? String(location.state.editData.weekDay) : String(location.state.editData.monthDay),
      weekDay: location.state.editData.weekDay,
      monthDay: location.state.editData.monthDay,
    };
    setConditions(obj);

    const storeArr = JSON.parse(location.state.editData.showJson).label_group;

    storeArr.forEach((val: any, idx: number) => {
      val.label_open = true;
      val.people_num = "--";
      val.rules.rules.forEach((a: any, b: number) => {
        a.rules.forEach((m: any, n: number) => {
          if(m.field_type === 'Date' || m.field_type === 'Year'){
            if(m.operator_id === 7){
              m["value_time"] = [
                dayjs(m.value[0], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                dayjs(m.value[1], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
              ];
            } else {
              m.value_time = m.field_type === "Date" ? dayjs(m.value, "YYYY-MM-DD") : dayjs(m.value, "YYYY");
            }
          }
          if(m.rules){
            m.rules.rules.forEach((p: any, q: number) => {
              if(p.field_type === 'Date' || p.field_type === 'Year'){
                if(p.operator_id === 7){
                  p["value_time"] = [
                    dayjs(p.value[0], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                    dayjs(p.value[1], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                  ];
                } else {
                  p.value_time = p.field_type === "Date" ? dayjs(p.value, "YYYY-MM-DD") : dayjs(p.value, "YYYY");
                }
              }
            })
          }
        })
      })
    });

    setParamsList(location.state.editData.showJson ? storeArr : []);

    accountForm.setFieldValue("crowdName", location.state.editData.crowdName);
    accountForm.setFieldValue("crowdDescribe", location.state.editData.crowdDescribe);
    accountForm.setFieldValue("enableFlag", location.state.editData.enableFlag);
    accountForm.setFieldValue("updateType", location.state.editData.updateType);
    accountForm.setFieldValue("updateRate", location.state.editData.updateRate);
    accountForm.setFieldValue("updateRateDate", location.state.editData.updateRateDate);
  };

  const [optionsValue, setOptionsValue] = useState([
    { label: "定时更新", value: "0" },
    { label: "手动更新", value: "3" }
  ]);

  // 获取规则参数
  const getRuleData = (val: any) => {
    setParamsList(val);
  };

  const hasDuplicates = (data: string[]) => {
    return new Set(data).size !== data.length;
  };

  // 添加规则组
  const addruleGroup = (id: string) => {
    const store = paramsList.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === id) {
        if (v.rules.rules.length > 9) {
          message.warning("最多只能添加10个规则组");
        } else {
          const rules_value_store: string[] = [];
          v.rules.rules.forEach((m: any, n: number) => {
            rules_value_store.push(m.rules_value);
          });

          if(!hasDuplicates(rules_value_store)){
            const obj = {
              rules_value: "规则组",
              action_bar_show: false,
              rules_value_id: String(new Date().valueOf()) + "03",
              combinator: "and",
              data_sources: "create",
              is_copy: true,
              rules: [
                {
                  rules_Label_value: null,
                  rules_operate_id: String(new Date().valueOf()) + "04",
                  table_name: "",
                  field_type: "",
                  label_id: null,
                  field: null,
                  field_id: null,
                  field_code: "",
                  min: "",
                  max: "",
                  time: null,
                  time_value: null,
                  time_key: '1',
                  is_custom: false,
                  custom_value: null,
                  custom_checked: null,
                  timeStore: "",
                  operator: null,
                  operator_id: null,
                  behavior: "做过",
                  value: "",
                  value_select: null,
                  value_time: null,
                  statistics_type: "总次数", // 统计方式
                  behavior_field_id: null,
                  behavior_field_value: "",
                  behavior_time_open: false
                },
              ],
            };
            v.rules.rules.unshift(obj);
          }
        }
      }
    });
    setParamsList(store);
  };

  // 估算
  const estimate = async (item: any) => {

    let f: boolean = true;

    const store: any = paramsList.map((v: any, i: number) => v);
    checkFillIn: for (var i = 0; i < store.length; i++) {
      for (var y = 0; y < store[i].rules.rules.length; y++) {
        for (var z = 0; z < store[i].rules.rules[y].rules.length; z++) {
          if (!store[i].rules.rules[y].rules[z].rules_Label_value) {
            message.warning("请设置分群规则");
            f = false;
            break checkFillIn;
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "1") {
            if (store[i].rules.rules[y].rules[z].label_id === null) {
              message.warning("请设置分群规则");
              f = false;
              break checkFillIn;
            }
            if (store[i].rules.rules[y].rules[z].operator_id === null) {
              f = false;
              message.warning("请设置分群规则");
              break checkFillIn;
            }
            if (
              store[i].rules.rules[y].rules[z].value === null ||
              store[i].rules.rules[y].rules[z].value === ""
            ) {
              f = false;
              message.warning("请设置分群规则");
              break checkFillIn;
            }
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "2") {
            if (store[i].rules.rules[y].rules[z].field_id === null) {
              f = false;
              message.warning("请设置分群规则");
              break checkFillIn;
            }
            if(store[i].rules.rules[y].rules[z].operator_id !== 5 && store[i].rules.rules[y].rules[z].operator_id !== 6){
              if (
                store[i].rules.rules[y].rules[z].value === null ||
                store[i].rules.rules[y].rules[z].value === ""
              ) {
                f = false;
                message.warning("请设置分群规则");
                break checkFillIn;
              }
            }
            if(store[i].rules.rules[y].rules[z].operator_id === 7){
              if(store[i].rules.rules[y].rules[z].field_type === 'Date' || 
                store[i].rules.rules[y].rules[z].field_type === 'Year' || 
                store[i].rules.rules[y].rules[z].field_type === 'Month'
              ){
                if(store[i].rules.rules[y].rules[z].value === '' ||
                store[i].rules.rules[y].rules[z].value === null || 
                store[i].rules.rules[y].rules[z].value.length === 0
                ){
                  f = false;
                  message.warning("请设置标签规则");
                  break checkFillIn;
                }
              } else {
                if(
                  store[i].rules.rules[y].rules[z].max === '' || 
                  store[i].rules.rules[y].rules[z].max === null ||
                  store[i].rules.rules[y].rules[z].min === '' ||
                  store[i].rules.rules[y].rules[z].min === null
                ){
                  f = false;
                  message.warning("请设置标签规则");
                  break checkFillIn;
                } else {
                  if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                    f = false;
                    message.warning("属性值区间请按照从小到大的顺序填写");
                    break checkFillIn;
                  }
                }
              }
            }
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "3") {
            if (store[i].rules.rules[y].rules[z].behavior_field_id === null) {
              f = false;
              message.warning("请设置分群规则");
              break checkFillIn;
            }
            if(store[i].rules.rules[y].rules[z].behavior === '做过' && store[i].rules.rules[y].rules[z].statistics_type === '总次数'){
              if (store[i].rules.rules[y].rules[z].operator_id === null) {    
                f = false;
                message.warning("请设置分群规则");
                break checkFillIn;
              }
              if (
                store[i].rules.rules[y].rules[z].value === null ||
                store[i].rules.rules[y].rules[z].value === ""
              ) {
                f = false;
                message.warning("请设置分群规则");
                break checkFillIn;
              }
              if (store[i].rules.rules[y].rules[z].operator_id === 7) {
                // 区间
                if (
                  store[i].rules.rules[y].rules[z].max === "" ||
                  store[i].rules.rules[y].rules[z].min === ""
                ) {
                  f = false;
                  message.warning("请设置分群规则");
                  break checkFillIn;
                } else {
                  if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                    f = false;
                    message.warning("行为次数区间请按照从小到大的顺序填写");
                    break checkFillIn;
                  }
                }
              }
            }
            if (store[i].rules.rules[y].rules[z].rules) {
              for (
                var s = 0;
                s < store[i].rules.rules[y].rules[z].rules.rules.length;
                s++
              ) {
                if (
                  store[i].rules.rules[y].rules[z].rules.rules[s].field_id ===
                  null
                ) {
                  f = false;
                  message.warning("请设置分群规则");
                  break checkFillIn;
                }
                if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 5 && store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 6){
                  if (
                    store[i].rules.rules[y].rules[z].rules.rules[s].value ===
                      null ||
                    store[i].rules.rules[y].rules[z].rules.rules[s].value === ""
                  ) {
                    f = false;
                    message.warning("请设置分群规则");
                    break checkFillIn;
                  }
                }
                if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id === 7){
                  if(store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Date' || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Year' || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Month'
                  ){
                    if(store[i].rules.rules[y].rules[z].rules.rules[s].value === '' ||
                      store[i].rules.rules[y].rules[z].rules.rules[s].value === null || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].value.length === 0
                    ){
                      f = false;
                      message.warning("请设置标签规则");
                      break checkFillIn;
                    }
                  } else {
                    if(
                      store[i].rules.rules[y].rules[z].rules.rules[s].max === '' || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].min === '' ||
                      store[i].rules.rules[y].rules[z].rules.rules[s].max === null || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].min === null
                    ){
                      f = false;
                      message.warning("请设置标签规则");
                      break checkFillIn;
                    } else {
                      if(Number(store[i].rules.rules[y].rules[z].rules.rules[s].max) < Number(store[i].rules.rules[y].rules[z].rules.rules[s].min)){
                        f = false;
                        message.warning("事件属性值区间请按照从小到大的顺序填写");
                        break checkFillIn;
                      }
                    }
                  }
                }
              }
            }
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "5") {
            if (
              store[i].rules.rules[y].rules[z].value === null
            ) {
              f = false;
              message.warning("请设置分群规则");
              break checkFillIn;
            }
          }
        }
      }
    }

    const label_group_params: any = [];
    store.forEach((v: any, i: number) => {
      // 最外层
      const obj = {} as any;
      obj.label_value_id = v.label_value_id;
      // obj.label_value = v.label_value;
      obj.rules = {};
      obj.rules.combinator = v.rules.combinator;
      obj.rules.rules = [];
      v.rules.rules.forEach((p: any, q: number) => {
        // 规则组
        const obj_child: any = {
          rules_value: p.rules_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E'),
          combinator: p.combinator,
          rule: []
        };
        p.rules.forEach((m: any, n: number) => {
          // 规则item
          const obj_child_child: any = {
            rules_Label_value: m.rules_Label_value,
            table_name: m.table_name,
            field: m.field,
            field_type: m.field_type === 'Date' || m.field_type === 'Long' || m.field_type === 'Decimal' || m.field_type === 'Label' || m.field_type === 'Crowd' ? m.field_type : 'String',
            label_id: m.label_id,
            time: m.time,
            operator: m.operator,
            behavior: m.behavior,
            value: m.value,
            statistics_type: m.statistics_type,
          }
          if(m.rules){
            if(m.rules.rules.length > 0){
              obj_child_child.rules = {};
              obj_child_child.rules.combinator = m.rules.combinator;
              obj_child_child.rules.rules = [];
              m.rules.rules.forEach((x: any, y: number) => {
                const obj_child_child_child: any = {
                  table_name: x.table_name,
                  field_type: x.field_type === 'Date' || x.field_type === 'Long' || x.field_type === 'Decimal' || x.field_type === 'Label' || x.field_type === 'Crowd' ? x.field_type : 'String',
                  field: x.field,
                  func: x.func_value.length > 1 ? x.func_value[1] : '',
                  operator: x.operator,
                  value: x.value
                }
                obj_child_child.rules.rules.push(obj_child_child_child);
              })
            } else {
              delete m.rules;
            }
          }
          obj_child.rule.push(obj_child_child);
        })
        obj.rules.rules.push(obj_child);
      })
      label_group_params.push(obj);
    });

    if (f) {
      const obj = {
        crowd_id: crowdId,
        label_group: label_group_params,
      };
      const params = {
        ruleJson: JSON.stringify(obj),
      };
      const res = await crowdEstimateApi(params);
      if(res){
        store[0].people_num = res.data;
      } else {
        store[0].people_num = '--';
      }
    }
    setParamsList(store);
  };

  // 获取且和或的change值
  const getChangeValue = (val: any) => {
    const store = paramsList.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === val.id) {
        v.rules.combinator = val.v;
      }
    });
    setParamsList(store);
  };

  // 下一步
  const nextStep = () => {
    if (!conditions.crowdName.trim()) {
      setLoading(true);
      return message.warning("请输入分群名称");
    }

    if (conditions.updateRate !== 0) {
      setLoading(true);
      if (!conditions.updateRateDate) {
        return message.warning("请选择更新日期");
      }
    }
    setCurrent(1);
  };

  // 提交
  const [loading, setLoading] = useState(true);
  const submit = async (isFinalConfirm: boolean) => {
    if (loading) {
      setLoading(false);
      if (!conditions.crowdName.trim()) {
        setLoading(true);
        return message.warning("请输入分群名称");
      }

      if (conditions.updateRate !== 0) {
        setLoading(true);
        if (!conditions.updateRateDate) {
          return message.warning("请选择更新日期");
        }
      }

      let f: boolean = true;

      const store: any = paramsList.map((v: any, i: number) => v);
      checkFillIn: for (var i = 0; i < store.length; i++) {
        for (var y = 0; y < store[i].rules.rules.length; y++) {
          if(store[i].rules.rules[y].rules_value.length > 20){
            message.warning("规则组名称最多20个字符");
            f = false;
            break checkFillIn;
          } else {
            for (var z = 0; z < store[i].rules.rules[y].rules?.length; z++) {
              if (!store[i].rules.rules[y].rules[z].rules_Label_value) {
                message.warning("请填写完整规则");
                f = false;
                break checkFillIn;
              }
              if (store[i].rules.rules[y].rules[z].rules_Label_value === "1") {
                if (store[i].rules.rules[y].rules[z].label_id === null) {
                  f = false;
                  message.warning("请选择标签");
                  break checkFillIn;
                }
                if (store[i].rules.rules[y].rules[z].operator_id === null) {
                  f = false;
                  message.warning("请选择运算条件");
                  break checkFillIn;
                }
                if (
                  store[i].rules.rules[y].rules[z].value === null ||
                  store[i].rules.rules[y].rules[z].value === ""
                ) {
                  f = false;
                  message.warning("请填写/选择标签值");
                  break checkFillIn;
                }
              }
              if (store[i].rules.rules[y].rules[z].rules_Label_value === "2") {
                if (store[i].rules.rules[y].rules[z].field_id === null) {
                  f = false;
                  message.warning("请选择用户属性");
                  break checkFillIn;
                }
                if(store[i].rules.rules[y].rules[z].operator_id !== 5 && store[i].rules.rules[y].rules[z].operator_id !== 6){
                  if (
                    store[i].rules.rules[y].rules[z].value === null ||
                    store[i].rules.rules[y].rules[z].value === ""
                  ) {
                    f = false;
                    message.warning("请填写/选择属性值");
                    break checkFillIn;
                  }
                }
                if(store[i].rules.rules[y].rules[z].operator_id === 7){
                  if(store[i].rules.rules[y].rules[z].field_type === 'Date' || 
                    store[i].rules.rules[y].rules[z].field_type === 'Year' || 
                    store[i].rules.rules[y].rules[z].field_type === 'Month'
                  ){
                    if(store[i].rules.rules[y].rules[z].value === '' ||
                      store[i].rules.rules[y].rules[z].value === null || 
                      store[i].rules.rules[y].rules[z].value.length === 0
                    ){
                      f = false;
                      message.warning("请选择事件属性值区间");
                      break checkFillIn;
                    }
                  } else {
                    if(
                      store[i].rules.rules[y].rules[z].max === '' || 
                      store[i].rules.rules[y].rules[z].min === ''
                    ){
                      f = false;
                      message.warning("请填写属性值区间");
                      break checkFillIn;
                    } else {
                      if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                        f = false;
                        message.warning("属性值区间请按照从小到大的顺序填写");
                        break checkFillIn;
                      }
                    }
                  }
                }
              }
              
              if (store[i].rules.rules[y].rules[z].rules_Label_value === "3") {
                if (store[i].rules.rules[y].rules[z].behavior_field_id === null) {
                  f = false;
                  message.warning("请选择用户行为");
                  break checkFillIn;
                }
  
                if(store[i].rules.rules[y].rules[z].behavior === '做过' && store[i].rules.rules[y].rules[z].statistics_type === '总次数'){
                  if (store[i].rules.rules[y].rules[z].operator_id === null) {    
                    f = false;
                    message.warning("请选择运算条件");
                    break checkFillIn;
                  }
                  if (
                    store[i].rules.rules[y].rules[z].value === null ||
                    store[i].rules.rules[y].rules[z].value === ""
                  ) {
                    f = false;
                    message.warning("请填写用户行为次数值");
                    break checkFillIn;
                  }
                  if (store[i].rules.rules[y].rules[z].operator_id === 7) {
                    // 区间
                    if (
                      store[i].rules.rules[y].rules[z].max === "" ||
                      store[i].rules.rules[y].rules[z].min === ""
                    ) {
                      f = false;
                      message.warning("请填写用户行为次数值");
                      break checkFillIn;
                    } else {
                      if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                        f = false;
                        message.warning("行为次数区间请按照从小到大的顺序填写");
                        break checkFillIn;
                      }
                    }
                  }
                }
                if (store[i].rules.rules[y].rules[z].rules) {
                  for (
                    var s = 0;
                    s < store[i].rules.rules[y].rules[z].rules.rules.length;
                    s++
                  ) {
                    if (
                      store[i].rules.rules[y].rules[z].rules.rules[s].field_id ===
                      null
                    ) {
                      f = false;
                      message.warning("请选择事件属性");
                      break checkFillIn;
                    }
                    if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 5 && store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 6){
                      if (
                        store[i].rules.rules[y].rules[z].rules.rules[s].value ===
                          null ||
                        store[i].rules.rules[y].rules[z].rules.rules[s].value === ""
                      ) {
                        f = false;
                        message.warning("请选择/填写用户行为值");
                        break checkFillIn;
                      }
                    }
                    if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id === 7){
                      if(store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Date' || 
                        store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Year' || 
                        store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Month'
                      ){
                        if(store[i].rules.rules[y].rules[z].rules.rules[s].value === '' ||
                          store[i].rules.rules[y].rules[z].rules.rules[s].value === null || 
                          store[i].rules.rules[y].rules[z].rules.rules[s].value.length === 0
                        ){
                          f = false;
                          message.warning("请选择事件属性值区间");
                          break checkFillIn;
                        }
                      } else {
                        if(
                          store[i].rules.rules[y].rules[z].rules.rules[s].max === "" || 
                          store[i].rules.rules[y].rules[z].rules.rules[s].max === null ||
                          store[i].rules.rules[y].rules[z].rules.rules[s].min === "" ||
                          store[i].rules.rules[y].rules[z].rules.rules[s].min === null
                        ){
                          f = false;
                          message.warning("请填写事件属性值区间");
                          break checkFillIn;
                        } else {
                          if(Number(store[i].rules.rules[y].rules[z].rules.rules[s].max) < Number(store[i].rules.rules[y].rules[z].rules.rules[s].min)){
                            f = false;
                            message.warning("事件属性值区间请按照从小到大的顺序填写");
                            break checkFillIn;
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (store[i].rules.rules[y].rules[z].rules_Label_value === "5") {
                if (
                  store[i].rules.rules[y].rules[z].value === null
                ) {
                  f = false;
                  message.warning("请选择分群");
                  break checkFillIn;
                }
              }
            }
          }
        }
      }

      const label_group_params: any = [];
      store.forEach((v: any, i: number) => {
        // 最外层
        const obj = {} as any;
        obj.label_value_id = v.label_value_id;
        obj.label_value = v.label_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E');
        obj.rules = {};
        obj.rules.combinator = v.rules.combinator;
        obj.rules.rules = [];
        v.rules.rules.forEach((p: any, q: number) => {
          // 规则组
          const obj_child: any = {
            rules_value: p.rules_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E'),
            combinator: p.combinator,
            rule: []
          };
          p.rules.forEach((m: any, n: number) => {
            // 规则item
            const obj_child_child: any = {
              rules_Label_value: m.rules_Label_value,
              table_name: m.table_name,
              field: m.field,
              field_type: m.field_type === 'Date' || m.field_type === 'Long' || m.field_type === 'Decimal' || m.field_type === 'Label' || m.field_type === 'Crowd' ? m.field_type : 'String',
              label_id: m.label_id,
              time: m.time,
              operator: m.operator,
              behavior: m.behavior,
              value: m.value,
              statistics_type: m.statistics_type,
            }
            if(m.rules){
              obj_child_child.rules = {};
              obj_child_child.rules.combinator = m.rules.combinator;
              obj_child_child.rules.rules = [];
              m.rules.rules.forEach((x: any, y: number) => {
                const obj_child_child_child: any = {
                  table_name: x.table_name,
                  field_type: x.field_type === 'Date' || x.field_type === 'Long' || x.field_type === 'Decimal' || x.field_type === 'Label' || x.field_type === 'Crowd' ? x.field_type : 'String',
                  field: x.field,
                  func: x.func_value.length > 1 ? x.func_value[1] : '',
                  operator: x.operator,
                  value: x.value
                }
                obj_child_child.rules.rules.push(obj_child_child_child);
              })
            }
            obj_child.rule.push(obj_child_child);
          })
          obj.rules.rules.push(obj_child);
        })
        label_group_params.push(obj);
      });

      if (f) {
        const rules = {
          crowd_id: crowdId,
          label_group: label_group_params,
        };

        const rules2 = {
          crowd_id: crowdId,
          label_group: paramsList,
        };

        const params: any = {
          crowdId: crowdId,
          crowdName: conditions.crowdName,
          crowdDescribe: conditions.crowdDescribe,
          enable: conditions.enableFlag ? 1 : 0,
          updateType: conditions.updateType,
          updateRate: conditions.updateRate,
          weekDay: conditions.updateRate === 1 ? conditions.updateRateDate : null,
          monthDay:
            conditions.updateRate === 2 ? conditions.updateRateDate : null,
          dateTime: dayjs(conditions.dateTime).format("HH:mm:ss"),
          ruleJson: JSON.stringify(rules),
          updateBy: state.user.userId,
          createMode: "0",
          isFinalConfirm: isFinalConfirm,
          showJson: JSON.stringify(rules2)
        };

        if (flag === "edit") {
          params.id = location.state.editData.id;
          params.calculateStatus = location.state.editData.calculateStatus;
          params.failMessage = location.state.editData.failMessage;
          params.groupNum = location.state.editData.groupNum;
          params.deleted = location.state.editData.deleted;
          params.createTime = location.state.editData.createTime;
          params.updateTime = location.state.editData.updateTime;
          params.contactCalcStatus = location.state.editData.contactCalcStatus;
          params.contactType = location.state.editData.contactType;
          params.contactDownloadAddress = location.state.editData.contactDownloadAddress;
          params.type = location.state.editData.type;
          params.isShow = location.state.editData.isShow;
          params.validTerm = location.state.editData.validTerm;
        }

        let res: any = {};

        if (flag === "create" || flag === "copy") {
          params.createBy = String(state.user.userId);
          res = await crowdAddApi(params);
          localStorage.removeItem('pageNum')
          localStorage.removeItem('pageSize')
          localStorage.removeItem('searchListData')
          getUserClickApi(state.childMenuId, 3, crowdId, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
        } else {
          params.createBy = location.state.editData.createBy;
          res = await crowdUpdateApi(params);
          getUserClickApi(state.childMenuId, 3, location.state.editData.id, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
        }

        if (res) {
          setLoading(true);
          if(res.code == 200){
            message.success(
              flag === "create"
                ? "创建成功"
                : flag === "edit"
                ? "编辑成功"
                : "复制成功"
            );
            navigate(-1);
          } else if(res.code == 506) {
            setLoading(true);
            setCreatePromptOpen(true);
            setPromptValue(res.msg);
          }
        } else {
          setLoading(true);
        }
      } else {
        setLoading(true);
      }
    }
  };

  // 提交弹窗提示
  const [createPromptOpen, setCreatePromptOpen] = useState(false);
  const [promptValue, setPromptValue] = useState("");
  const getCreatePromptOpenStatus = (val: boolean) => {
    setCreatePromptOpen(val);
  };
  const getConfirmOperate = () => {
    setCreatePromptOpen(false);
    submit(true);
  };

  // 查看切换编辑
  const edit = async () => {
    if(location.state.editData.calculateStatus === 1){
      message.warning('分群计算中，稍后重试');
    }else{
      if(loading){
        setLoading(false);
        const params = {
          crowdId: location.state.editData.crowdId
        };
        const res = await crowdCheckEditApi(params);
        if(res.data){
          setFlag('edit');
        } else {
          message.warning("该分群已被应用，不可进行修改操作");
        };
        setLoading(true);
      }
    }
  };

  // 数字千分位
  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 查看操作日志回调 - 关闭日志
  const getOpenlog = (log: boolean) => {
    setOpenLog(log)
  }

  // 获取分群详细信息——此接口用于记录操作日志，实际编辑数据从列表页接口拿
  const getCrowdInfo = async (id: string) => {
    const param: any = {
      crowdId: id 
    };
    const res = await getCrowdInfoApi(param);
  };

  useEffect(() => {
    setParamsList(JSON.parse(JSON.stringify(items)));
    if (flag === "edit" || flag === "view" || flag === "copy") {
      handleData();
      if (flag === "edit" || flag === "view") {
        setCrowdId(JSON.parse(location.state.editData.ruleJson).crowd_id);
        getCrowdInfo(JSON.parse(location.state.editData.ruleJson).crowd_id);
      } else {
        setCrowdId(String(new Date().valueOf()) + "02");
      }
    } else {
      setCrowdId(String(new Date().valueOf()) + "02");
    }

    return () => {
      // 组件卸载时清理message
      message.destroy();
    };
  }, [])

  return (
    <div className={styles.createUserGroupingBox}>
      <div className={styles.pageTitle}>
        <div className={styles.returnBtn} onClick={() => navigate(-1)}><LeftOutlined style={{marginRight: 8}} />返回</div>
        {flag === "create" ? "新建分群" : flag === "view" ? "查看分群" : flag === "copy" ? "复制分群" : "编辑分群"}
      </div>
      <div className={styles.stepsBox}>
        <Steps
          progressDot
          current={current}
          items={[
            { title: "分群基础信息设置" },
            { title: "分群规则设置" }
          ]}
        />
      </div>
      
      <div className={styles.labelBaseInfo} style={{marginLeft: current === 0 ? '12%' : '24px'}}>{current === 0 ? '分群基本信息' : '分群规则'}</div>
      {
        current === 0 && <div className={styles.formBox}>
          <Form layout="vertical" form={accountForm}>
            <Form.Item<FieldType>
              label="分群名称"
              name="crowdName"
              rules={[{ required: true, message: "请输入分群名称" }]}
            >
              <Input
                disabled={flag === "view"}
                autoComplete="off"
                showCount
                maxLength={50}
                placeholder="请输入分群名称"
                value={conditions.crowdName}
                onChange={(e) => changeConditions({ crowdName: e.target.value })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="状态"
              name="enableFlag"
              rules={[{ required: true, message: "" }]}
            >
              <Switch
                disabled={flag === "view"}
                checkedChildren="启用"
                unCheckedChildren="停用"
                defaultChecked
                checked={conditions.enableFlag}
                onChange={(e) => changeConditions({ enableFlag: e })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="更新类型"
              name="updateType"
              rules={[{ required: true, message: "请选择更新类型" }]}
            >
              <Select
                disabled={flag === "view"}
                placeholder="请选择"
                value={conditions.updateType}
                defaultValue={conditions.updateType}
                onChange={(e) => changeConditions({ updateType: e, updateRate: 0 })}
                options={optionsValue}
              />
            </Form.Item>
            {
              conditions.updateType === '0' && 
              <Form.Item<FieldType>
                label="更新频率"
                name="updateRate"
                rules={[{ required: true, message: "请选择更新频率" }]}
              >
                <Radio.Group
                  disabled={flag === "view"}
                  optionType="button"
                  options={radioGroupOptions}
                  value={conditions.updateRate}
                  onChange={(e) => changeConditions({ updateRate: e.target.value })}
                />
                <div style={{ display: "flex", marginTop: '10px' }}>
                  {conditions.updateRate !== 0 && (
                    <Select
                      disabled={flag === "view"}
                      placeholder="请选择"
                      style={{ marginRight: 8, width: 200 }}
                      value={conditions.updateRateDate}
                      onChange={(e) => changeConditions({ updateRateDate: e })}
                      options={
                        conditions.updateRate === 1 ? weekOptions : monthOptions
                      }
                    />
                  )}
                </div>
              </Form.Item>
            }
            <Form.Item<FieldType> 
              label="描述" 
              name="crowdDescribe"
              className={styles.labelDescription}
            >
              <TextArea
                disabled={flag === "view"}
                placeholder="请输入描述"
                showCount
                maxLength={100}
                value={conditions.crowdDescribe}
                style={{ height: 100, resize: "none" }}
                onChange={(e) =>
                  changeConditions({ crowdDescribe: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </div>
      }
      {
        current === 1 &&  <div className={styles.rulesSettingBox}>
          {
            flag !== "view" && <div className={styles.addBox}>
            <Button
              icon={<PlusOutlined />}
              disabled={flag === "view"}
              onClick={() => addruleGroup(paramsList[0].operate_label_id)}
            >
              添加规则组
            </Button>
            <div
              style={{
                lineHeight: 'var(--text-font15)',
                fontSize: "var(--text-font5)",
                color: "var(--text-font-color4)",
                fontWeight: "var(--text-font-weight2)",
              }}
            >
              预估人数：
              <span style={{ color: "var(--text-font-color7)" }}>
                {paramsList[0].people_num === '--' ? '--' : formatNumber(Number(paramsList[0].people_num))}
              </span>
              <span
                style={{
                  color: "var(--text-font-color3)",
                  display: "inline-block",
                  marginLeft: "5px",
                }}
              >
                人
              </span>
              <span
                style={{
                  color: "var(--text-font-color7)",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
                onClick={estimate}
              >
                估算
              </span>
            </div>
          </div>
          }
          <div
            style={{
              display:
                paramsList[0].rules?.rules?.length > 1 ? "flex" : "block",
              justifyContent: "space-between",
              paddingLeft: paramsList[0].rules?.rules?.length > 1 ? "14px" : 0
            }}
          >
            {paramsList[0].rules?.rules?.length > 1 && (
              <RelationshipDivider
                operateType={flag}
                size="32px"
                level="1"
                textFlag={paramsList[0].rules.combinator}
                dataParams={{
                  id: paramsList[0]["operate_label_id"],
                  flag: "p",
                }}
                getChangeValue={getChangeValue}
              />
            )}
            <div
              style={{
                width: paramsList[0].rules?.rules?.length > 1 ? "calc(100% - 20px)" : "100%",
              }}
            >
              {paramsList[0].rules.rules.map((val: any, idx: number) => {
                return (
                  <RuleGroupsSetting             
                    key={val.rules_value_id}
                    current={current}
                    labelId={crowdId}
                    parentLabelId={flag === "edit" ? location.state.editData.crowdId : ''}
                    operateType={flag}
                    flag="fq"
                    listData={paramsList}
                    itemData={paramsList[0]}
                    ruleData={val}
                    getRuleData={getRuleData}
                  />
                );
              })}
            </div>
          </div>
          <CreatePrompt
            createPromptOpen={createPromptOpen}
            promptValue={promptValue}
            getCreatePromptOpenStatus={getCreatePromptOpenStatus}
            getConfirmOperate={getConfirmOperate}
          />
        </div>
      }
      <div className={styles.buttonBox}>
        <Button style={{marginRight: 8}} onClick={() => navigate(-1)}>取消</Button>
        {flag === "view" && checkPermissions(CROWD_KEY.UPDATA) && <Button type="primary" onClick={edit} style={{marginRight: 8}}>编辑</Button>}
        {current === 0 && <Button type="primary" onClick={nextStep}>{flag === "view" ? "下一页" : "下一步"}</Button>}
        {current === 1 && <Button type="primary" onClick={() => setCurrent(0)} style={{marginRight: 8}}>{flag === "view" ? "上一页" : "上一步"}</Button>}
        {current === 1 && flag !== "view" && <Button type="primary" onClick={() => submit(false)}>确定</Button>}
        {flag === "view" && <Button type="primary" style={{marginLeft: 8}} onClick={() => {setOpenLog(true)}}>
          查看操作日志
        </Button>}
        </div>
        <OperateLog
          openLog={openLog}
          getOpenlog={getOpenlog}
          crowInfo={location.state.editData}
        />
    </div>
  );
};

export default CreateUserGrouping;
