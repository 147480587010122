import { Input, Drawer, Space, Button, Form, Select, message, Upload, Radio, Row, Col, Tooltip } from 'antd';
import React, { useEffect, useRef, ReactNode, useImperativeHandle, useState, forwardRef } from 'react';
import { getSmsTemplateDetail, createSmsTemplate, updateSmsTemplateInfo, getSmsPassageList } from '../../../../api/graph/graph';
import SettingTab from '../settingTab/index';
import MessageTextArea from '../../../../component/Input/MessageTextArea/messageTextArea';
import ShortMesLink from '../settingTab/component/shortMesLink';
import VariableMes from '../settingTab/component/variableMes';
import { QuestionCircleOutlined } from '@ant-design/icons';
import busEvent from '@/utils/bus';

let vars: any = [];
let chooseIndex = 0
let accountId = 0
let smsPassageList: any = 0

let smsTemplate = React.forwardRef(
    (
        props: {
            open?: boolean;
            drawer?: Function;
            templateType?: number;
            templateId?: number;
        },
        ref: any,
    ) => {
        const [creadForm] = Form.useForm();
        const [templateType, setTemplateType] = useState(props.templateType ?? 0);
        const [accountId2, setAccountId2] = useState(0);

        const messageRef: any = useRef(null); //短信文本框
        const shortMesLinkRef: any = useRef(null);
        const [nodeInfo, setNodeInfo] = useState<any>({});
        const [messageSignOptions, setMessageSignOptions] = useState([] as any); //短信签名
        const [unsubscribeOptions, setUnsubscribeOptions] = useState([] as any); //短退订文字
        const [variableShow, setVariableShow] = useState(false); //短信插入变量
        const [shortMesLinkShow, setShortMesLinkShow] = useState(false); //短信内容 短链接
        const [overflowText, setOverflowText] = useState(false); //短信内容 文字超出
        const [chargingNum, setChargingNum] = useState(1); //短信计费条数
        const [textNum, setTextNum] = useState(0); //短信内容 字数

        //短信 字数统计
        const changeNumText = (param?: any, addNum?: number) => {
            var textNum: number = 0;
            textNum = param.text ? Number(param.text.length) : 0;
            var num: number =
                textNum + 10
            // // 找到 `$` 的位置  将插入变量的长度替换成为空时显示的字符 算上了旁边的两个空格

            const str = param?.text ?? '';
            const lengthNullText: any = []; //找${} 中 长度
            //找${}位置
            function extractTemplateExpressions(str: string): string[] {
                const expressions: string[] = [];
                let currentIndex = 0;

                while (currentIndex < str?.length) {
                    //找$位置
                    const dollarIndex = str.indexOf('$', currentIndex);
                    if (dollarIndex === -1) break;

                    const braceIndex = str?.indexOf('}', dollarIndex);
                    if (braceIndex === -1) break;

                    const expression = str?.substring(dollarIndex, braceIndex + 1);
                    expressions.push(expression);
                    currentIndex = braceIndex + 1;

                    //找${} 中  !" " 位置
                    const doubleQuoteIndex = expression.indexOf('!"');
                    if (doubleQuoteIndex === -1) break;

                    const doubleQuoteLength = expression.indexOf('"}');
                    if (doubleQuoteLength === -1) break;
                    const expressionNullText = expression?.substring(doubleQuoteIndex + 2, doubleQuoteLength);
                    //!"" 中的字符
                    lengthNullText.push(expressionNullText);
                }
                return expressions;
            }
            // 提取模板表达式
            const templateExpressions = extractTemplateExpressions(str);

            // 计算每个表达式的长度
            const lengths = templateExpressions.map((expression: any) => expression.length);
            //${} 表达式是个数组，数组里是取的所有 ${} 的长度 包括本身
            var newNum = 0;
            lengths.map((item: any) => {
                newNum = newNum + item;
            });

            //newNumNull 是所有 变量为空时显示 的字符长度
            const lengthsNullShow = lengthNullText.map((expression: any) => expression.length);
            var newNumNull = 0;
            lengthsNullShow.map((item: any) => {
                newNumNull = newNumNull + item;
            });
            //重新结算长度
            num = num - newNum + newNumNull - 10;
            setTextNum(num);
            var charging = num > 70 ? Math.ceil(num / 67) : 1;
            setChargingNum(charging);
            if (num > 312) {
                setOverflowText(true);
            } else {
                setOverflowText(false);
            }
        };


        //获取短信通道
        const getSubAccountListFunc = async () => {
            let param = {
                pageSize: 10,
                pageNum: 1,
                status: 0
            }
            const res: any = await getSmsPassageList(param);
            if (res?.code === 200) {
                smsPassageList = res.data.records
                if (res.data.records.length > 0) {
                    accountId = res.data.records[chooseIndex].accountId
                    setAccountId2(accountId)
                }
            }
        };

        // 关闭抽屉
        const handleDrawerSwitch = () => {
            if (props.drawer) {
                creadForm.resetFields();
                props.drawer();
            }
            setVariableShow(false)
            setShortMesLinkShow(false)
            setNodeInfo({})
            setTextNum(0)
            setChargingNum(1)
        };

        // 新建模板
        const saveNewTemplate = async () => {
            const res = await createSmsTemplate({ ...nodeInfo });
            if (res) {
                message.success('新建成功');
                handleDrawerSwitch()
            }
        }

        // 更新模板
        const updateTemplate = async () => {
            let param = {
                ...nodeInfo,
                templateId: props.templateId
            }
            const res = await updateSmsTemplateInfo(param);
            if (res) {
                message.success('编辑成功');
                handleDrawerSwitch()
            }
        }

        // 确定保存
        const onFinish = (values: any) => {
            let contentRule = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")

            if (!nodeInfo.templateName) {
                message.warning('请输入短信模版名称')
                return
            }
            if (!nodeInfo.templateText) {
                message.warning('请输入短信模版内容')
                return
            }
            if (contentRule.test(nodeInfo.templateName)) {
                message.warning('模版名称不支持特殊字符，请重新输入')
                return
            }
            if (nodeInfo.templateText.length > 312) {
                message.warning('短信模版内容超出312字符，请重新输入')
                return
            }
            if (templateType < 1) {
                saveNewTemplate()
            } else {
                updateTemplate()
            }
        }

        // 获取短信模板详情
        const getTemplateInfo = async () => {
            const res: any = await getSmsTemplateDetail({ templateId: props.templateId });
            if (res?.code == 200) {
                if (res.data) {
                    const regexNew = /\s(\S+)\s/g;
                    let match;
                    const results: string[] = [];

                    while ((match = regexNew.exec(res.data.templateText)) !== null) {
                        results.push(match[1]);
                    }
                    setNodeInfo({ ...res.data, resultLink: results[0] })
                    messageRef.current?.textAreaChange(res.data.templateText)
                    creadForm.setFieldValue('templateName', res.data.templateName)
                    changeNumText({ text: res.data.templateText })
                }
            }
        }

        let isShowErrorText = false;
        const showErroeText = () => {
            if (isShowErrorText) {
                return;
            }
            isShowErrorText = true;
            message.error('请先输入短信内容');
            setTimeout(() => {
                isShowErrorText = false;
            }, 3000);
        };

        //短信插入变量
        const openVariableMes = (status: boolean, type?: number, param?: any) => {
            if (param) {
                vars.push({
                    field: param.field,
                    def: param.def,
                });
            }
            if (!nodeInfo.templateText) {
                return showErroeText();
            }
            //type 1为取消，2为确定
            setVariableShow(status);
            setShortMesLinkShow(false);
            // setTestRecordShow(false);
            if (type === 2) {
                setTimeout(() => {
                    // 更新文本区域
                    messageRef.current?.handleClickTitle(`${param.variableText}!"${param.def}"}`);
                }, 1);
            }
        };

        //短信插入转换短链接
        const openShortMes = (status: any, type?: number, param?: any) => {
            if (!nodeInfo.templateText) {
                return showErroeText();
            }

            setShortMesLinkShow(status);
            // setTestRecordShow(false);
            setVariableShow(false);
            if (type === 2) {
                setNodeInfo({ ...nodeInfo, resultLink: param.resultLink })
                setTimeout(() => {
                    messageRef.current?.handleClickTitle(param.resultLink);
                }, 1);
            }
        };

        useEffect(() => {
            console.log(props);

            if (props.templateId) {
                getTemplateInfo()
            }
            setTemplateType(props.templateType ?? 0)
        }, [props])

        useEffect(() => {
            getSubAccountListFunc()
            busEvent.on('linkError', () => {
                chooseIndex += 1
                if (chooseIndex > (smsPassageList.length - 1)) {
                    chooseIndex = smsPassageList.length - 1
                    message.error('暂无可用通道')
                    return
                }
                accountId = smsPassageList[chooseIndex].accountId
                setAccountId2(accountId)

                shortMesLinkRef.current?.resultShortLink(accountId)
            })
        }, [])

        return (
            <div>
                <Drawer
                    width={400}
                    title={templateType == 1 ? '查看短信模板' : templateType == 2 ? '编辑短信模板' : '新建短信模板'}
                    onClose={handleDrawerSwitch}
                    open={props.open}
                    extra={
                        <Space>
                            {templateType == 1 &&
                                <Button type="primary" onClick={() => {
                                    setTemplateType(2);
                                    // setTimeout(() => {
                                    //     messageRef.current?.textAreaChange(nodeInfo.templateText)
                                    // }, 10);
                                }}>
                                    编辑
                                </Button>}
                            {(templateType != 1) && <>
                                <Button onClick={handleDrawerSwitch}>取消</Button>
                                <Button type="primary" onClick={onFinish} htmlType="submit">
                                    确定
                                </Button>
                            </>
                            }
                        </Space>
                    }>
                    <Form form={creadForm} initialValues={{ remember: true }} onFinish={onFinish} style={{ maxWidth: 600 }} layout="vertical">
                        <Form.Item
                            label="短信模板名称"
                            name="templateName"
                            rules={[
                                { required: true, message: '请输入短信模板名称' },
                            ]}>
                            <Input disabled={templateType == 1} autoComplete="off" placeholder="请输入短信模板名称" showCount maxLength={20} onChange={(e) => { setNodeInfo({ ...nodeInfo, templateName: e.target.value }) }} />
                        </Form.Item>

                        {/* 插入变量弹窗 */}
                        {variableShow && <VariableMes openVariableMes={openVariableMes} top={370} left={5}></VariableMes>}
                        {/* 插入转换短链接弹窗 */}
                        {shortMesLinkShow && <ShortMesLink ref={shortMesLinkRef} top={370} left={5} type={'template'} accountId={accountId2} openShortMes={openShortMes}></ShortMesLink>}
                        <Form.Item
                            label="短信模板内容"
                            name="templateText"
                            rules={[
                                { required: true, message: '请输入短信模板名称' },
                            ]}
                        >
                            <MessageTextArea
                                ref={messageRef}
                                value={nodeInfo.templateText}
                                disabled={templateType == 1}
                                messageSign=''
                                // messageSign={
                                //     nodeInfo.messageSign
                                //         ? `【${messageSignOptions.filter((item: any) => item.value === nodeInfo.messageSign)[0]?.label}】`
                                //         : '【短信签名】'
                                // }
                                unsubscribe=''
                                // unsubscribe={
                                //     nodeInfo.unsubscribe
                                //         ? `${unsubscribeOptions.filter((item: any) => item.value === nodeInfo.unsubscribe)[0]?.label}`
                                //         : '退订文字'
                                // }
                                messageModel={nodeInfo.messageModel}
                                useLinkDisable={smsPassageList.length < 1 || nodeInfo?.resultLink?.length > 0}
                                onChangeText={(e: string) => {
                                    let varsNew: any = [];
                                    let params: any = { ...nodeInfo.param }
                                    //检查变量是否被删除
                                    vars.forEach((item: any) => {
                                        console.log(333, e, item.field);
                                        if (e.indexOf(item.field) !== -1) {
                                            varsNew.push(item);
                                            params[item.field] = item.def;
                                        }
                                    });
                                    vars = varsNew;
                                    var linkUrl = nodeInfo?.resultLink
                                    //如果输入的短信内容中不包含插入的短链接  则 清空短链接信息
                                    if (e.indexOf(nodeInfo?.resultLink) == -1) {
                                        linkUrl = ''
                                    }
                                    setNodeInfo({ ...nodeInfo, templateText: e, params, resultLink: linkUrl })
                                    changeNumText({ text: e })
                                }}
                                type={templateType == 1 ? '' : 'custom'}
                                openVariableMes={openVariableMes}
                                openShortMes={openShortMes}
                                graphStatus={templateType == 1 ? '1' : '0'}
                            />
                            <div style={{ color: 'var(--text-font-color4)' }}>
                                已输入
                                <span>{textNum}</span>
                                /312字(不包含短信签名)，当前计费：
                                <span style={{ marginLeft: 0 }}>
                                    {chargingNum}
                                </span>
                                条
                                <Tooltip
                                    title={
                                        <span style={{ whiteSpace: 'pre-wrap' }}>
                                            1、70个字符算一条短信，超过70个字符按67个字符算一条短信。
                                            <br />
                                            2、短信签名、退订文字字符个数包含在短信字符内。
                                            <br />
                                            3、请确保短链接【前后各加一个空格】，避免手机无法打开链接。使用长链接转换工具生成的短链接已默认前后各加一个空格。
                                            <br />
                                            4、请确保每条短信中只有一条短链接，否则无法统计短信发送情况。
                                            <br />
                                            5、短信内容只能为普通字符，不能包含:【、】、表情符等特殊字符。
                                        </span>
                                    }>
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'var(--text-font-color4)',
                                            fontSize: 'var(--text-font5)',
                                            marginLeft: '6px',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        )

    }
)
export default smsTemplate;
