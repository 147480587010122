import React, { useEffect, useState } from 'react';
import styles from "./index.module.scss";

function ModuleTitle(props: { icon: any; title: string}) {
  const { icon, title } = props;
  return (
    <div>
      <div className={styles.boxItem}>
        <img src={icon} />
        <span>{title}</span>
      </div>
    </div>
  );
}

export default ModuleTitle;
