/**
 * 顶部搜索组件
 */
import React, { useEffect, useState, useRef } from "react";
import { Button, Tooltip } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import ModuleTitle from "@/component/ModuleTitle";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import styles from "./index.module.scss";
import SearchForm from "./../SearchForm";

function Header(props: any) {
  const { title, searchForm, hasExport, toExport, setSearchForm } = props;
  const [iconColor, setIconColor] = useState("var(--text-font-color4)");
  const [arrow, setArrow] = useState(false);
  const handleMouseEnter = () => {
    setIconColor("var(--text-font-color9)");
  };
  const handleMouseLeave = () => {
    setIconColor("var(--text-font-color4)");
  };
  const getHeight = () => {
    return document.body.clientHeight - 300;
  };
  return (
    
      <div className={styles.headBox}>
        <div className={styles.titleColumn}>
          <div className={styles.titleBox}>
            <ModuleTitle mark="large" title={title} />
          </div>
          <div className={styles.searchBox}>
            <Tooltip placement="bottom" title={"过滤器"}>
              <Button
                onClick={() => setArrow(!arrow)}
                icon={
                  <FilterOutlined
                    style={{
                      color: iconColor,
                      fontSize: "var(--text-font7)",
                    }}
                  />
                }
                type="text"
                className="click-element"
                style={{ marginRight: "10px" }}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
              ></Button>
            </Tooltip>
            <span
              style={{
                width: "0",
                height: "16px",
                border: "var(--border3)",
                marginRight: 16,
              }}
          ></span>
          {
            hasExport &&
            <Button onClick={toExport} style={{ marginRight: 8 }}>
              导出
            </Button>
          }
            {/* <Button
            onClick={() => {
              setTaskListVisible(true);
            }}
            style={{ marginRight: 16 }}
          >
            任务列表
          </Button> */}
            <span>
              数据更新时间：
              <span style={{ color: "var(--text-font-color11)" }}>
                {dayjs().add(-1, "day").format("YYYY-MM-DD")}
              </span>
            </span>
          </div>
        </div>
        <div className={styles.motionContainer}>
          <motion.div
            onAnimationComplete={getHeight}
            className={styles["search-list"]}
            style={{ height: 0, overflow: "hidden" }}
            animate={{ height: arrow ? "auto" : 0 }}
          >
          <SearchForm onFinish={(v) => {
            setSearchForm({...v, isShopAll: v.shopId === 'all' ? 1: 0, isPlatformAll: v.platformType === 'all' ? 1: 0});
            }} type="customer" />
          </motion.div>
        </div>
      </div>
  );
}

export default Header;
