/**
 * 商品销售追踪-商品-商品销售帕累托分析
 */
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import * as echarts from "echarts";
import { getTopTenTradeList } from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
function AnalysisChart(props: any) {
  const { id, title, tips, searchForm, type } = props;
  const { channelAllList } = useGetChannelList("customer");
  const [chartData, setChartData] = useState<any>([]);
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");

  useEffect(() => {
    if (channelAllList.length > 0) {
      getSalesTracking();
    }
  }, [searchForm, channelAllList]);
  const getSalesTracking = async () => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? ["all"]
        : searchForm?.platformType?.split(","),
      shopIds: searchForm?.shopId?.includes("all")
        ? ["all"]
        : searchForm?.shopId?.split(","),
      goodType: type,
    };
    const res = await getTopTenTradeList(params);
    if (res?.code === 200) {
      setChartData(res.data);
    }
  };

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: 'item',  // 鼠标悬浮时触发的类型
        axisPointer: {
          type: 'shadow'  // 使用阴影指示器
        },
        formatter: function (params: any) {
          return `<strong>${chartData[params.dataIndex]?.name}</strong><br>
          <div style="display: flex; align-items: center;">
            <div style="width:10px; height:10px;margin-bottom: 4px; background-color: #5B8FF9;margin-right: 8px "></div>
            销售额：${chartData[params.dataIndex]?.num}<br>
          </div>
          <div style="display: flex; align-items: center;">
            <div style="width:10px; height:10px;margin-bottom: 4px; background-color: #5B8FF9;margin-right: 8px "></div>
            销售额占比：${chartData[params.dataIndex]?.rate}%<br>
          </div>
          <div style="display: flex; align-items: center;">
            <div style="width:10px; height:2px;margin-bottom: 4px; background-color: #61D7A7;margin-right: 8px "></div>
            累计销售额占比：${chartData[params.dataIndex]?.sumRate}%
          </div>
          `;
        },
      },
      legend: {
        // width: 400,
        left: 24,
        itemHeight: 10,
        itemWidth: 10,
        data: [
          { name: "销售额", icon: "rect" },
          { name: "累计销售额占比", icon: "line" },
        ],
        formatter: function (name: string) {
          if (name === "销售额") {
            return name;
          } else if (name === "累计销售额占比") {
            return "{line1|} " + name;
          }
        },
        textStyle: {
          rich: {
            square: {
              width: 10, // 正方形宽度
              height: 10, // 正方形高度
              backgroundColor: "#5B8FF9", // 正方形颜色
            },
            line1: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "#61D7A7", // 横线颜色
            },
          },
        },
      },
      series: [
        {
          name: "销售额",
          type: "bar",
          barWidth: 30,
          itemStyle: {
            color: "#5B8FF9",
          },
          // tooltip: {
          //   valueFormatter: function (value: string) {
          //     return value;
          //   },
          // },
          data: chartData.map((item: any) => item.num),
          yAxisIndex: 0,
        },
        {
          name: "累计销售额占比",
          type: "line",
          showSymbol: true,
          itemStyle: {
            color: "#61D7A7",
          },
          lineStyle: {
            color: "#61D7A7",
          },
          yAxisIndex: 1,

          markLine: {
            emphasis: {},
            data: [
              {
                yAxis: 80,
                xAxis: "Sleeping Existing",
                name: "累计占比80%",
                label: {
                  distance: [-94, 28],
                  formatter: function (params: { value: string }) {
                    return "{label|累计占比80%}";
                  },
                  rich: {
                    label: {
                      color: "#8C8C8C",
                      backgroundColor: "#fff",
                      padding: [0, 10, 0, 10],
                    },
                  },
                },
                lineStyle: {
                  color: "#FCC115",
                  type: "dashed",
                  width: 1,
                }, tooltip: { show: false }
              },
              {
                yAxis: 90,
                name: "累计占比90%",
                label: {
                  distance: [-94, 28],
                  formatter: function (params: { value: string }) {
                    return "{label|累计占比90%}";
                  },
                  rich: {
                    label: {
                      color: "#8C8C8C",
                      backgroundColor: "#fff",
                      padding: [0, 10, 0, 10],
                    },
                  },
                },
                lineStyle: {
                  color: "#FF7CB7",
                  type: "dashed",
                  width: 1,
                }, tooltip: { show: false }
              },
            ],
            symbol: false,
          },
          data: chartData.map((item: any) => item.sumRate),
        },
      ],
      grid: {
        left: 24,
        right: 24,
        bottom: 0,
        containLabel: true,
      },
      xAxis: [
        {
          axisLabel: {
            interval: 0,
            rotate: 35, // 设置横轴标签的旋转角度为45度
            fontSize: 14, // 设置字体大小
            color: "#333",
            formatter: function (value: string) {
              // 截取前5个字符
              return value.length > 5 ? `${value.substring(0, 5)}...` : value;
            },
          },
          type: "category",
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          data: chartData.map((item: { name: any }) => item.name),
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          min: 0,
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          name: "",
          min: 0,
          alignTicks: true,
          axisLabel: {
            formatter: "{value} %",
          },
        },
      ],
    };
    let chartDom = document.getElementById(id);
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [chartData]);
  return (
    <div className={styles.normalBox} style={{ height: 600, width: "100%" }}>
      <div className={styles.normalHeader}>
        <div>
          {title}
          <Tooltip
            title={<span style={{ whiteSpace: "pre-wrap" }}>{tips}</span>}
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div
        id={id}
        style={{
          width: "100%",
          height: "518px",
          backgroundColor: "#fff",
          paddingTop: "24px",
        }}
      />
    </div>
  );
}

export default AnalysisChart;
