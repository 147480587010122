import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import SystemButton from '@/component/Button/SystemButton';
import { Button, Checkbox, Table, Space, Select, DatePicker, Radio, Tooltip, message } from 'antd';
import BackList from '../../../component/BackList/backList';
import ModuleTitle from '../../../component/ModuleTitle';
import type { CheckboxProps, TableProps, RadioChangeEvent, GetProps } from 'antd';
import Histogram from './component/Histogram';
import YLIcon from '../../../../src/component/Icon/YLIcon.jsx';
import CheckGroup from '../component/settingTab/component/checkGroup';
import CreateGroup from '../component/settingTab/component/createGroup';
import { QuestionCircleOutlined, LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { getSummaryData, getVisitsData, getSummaryByPeriod, csvExport, addWorkflowVisitorToCrowd, createGroup } from '../../../api/graph/graph';
const ReportData = () => {
  type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
  let location = useLocation();
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState<any>(null);
  const graphStatusNew = location.state.graphStatusNew;
  
  //是否勾选了 显示重复进入的客户
  const [repeatCustom, setRepeatCustom] = useState(false);
  //数据列表
  const [listData, setListData] = useState([] as any[]);
  const [cycle, setCycle] = useState('hour'); //选择的周期 按时
  // 选中周期  今天 7天
  const [selectedCycle, setSelectedCycle] = useState('1');
  const [dateValue, setDateValue] = useState([dayjs().add(0, 'days'), dayjs()] as any);
  const { RangePicker } = DatePicker;
  const [groupPopShow, setGroupPopShow] = useState(false); //选择分群弹窗
  const [groupCreatePopShow, setGroupCreatePopShow] = useState(false); //创建分群弹窗
  const [chartListData, setChartListData] = useState([] as any);
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  const [crowd, setCrowd] = useState({
    crowdId: null,
    crowdName: null,
  });
  //tabel 列表字段
  const columns: TableProps<any>['columns'] = [
    {
      title: 'oneId',
      dataIndex: 'oneId',
      key: 'oneId',
    },
    {
      title: '手机号',
      dataIndex: 'maskPhoneNo',
      key: 'maskPhoneNo',
    },
    {
      title: '进入流程时间',
      dataIndex: 'enterTime',
      key: 'enterTime',
      render(value, record, index) {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      key: 'action',
      width: '120px',
      render: (data: any) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              localStorage.setItem('oneId', data.oneId);
              window.open(`/userInsight/userInsightDocument/UserDetail`);
            }}>
            查看详情
          </Button>
        </Space>
      ),
    },
  ];
  // 选择周期
  const options = [
    { label: '按时', value: 'hour', disabled: false },
    { label: '按天', value: 'day', disabled: selectedCycle === '1' },
    { label: '按月', value: 'month', disabled: selectedCycle === '1' || selectedCycle === '2' || selectedCycle === '3' },
  ];
  // 选择周期  今天  7天
  const selectCycle = [
    { label: '今天', value: '1' },
    { label: '最近7天', value: '2' },
    { label: '最近30天', value: '3' },
    { label: '最近90天', value: '4' },
    { label: '自定义', value: '5' },
  ];
  const cycleDays = [
    { day: 0, value: '1' },
    { day: 7, value: '2' },
    { day: 30, value: '3' },
    { day: 90, value: '4' },
  ];
  //改变按时,按天
  const changeCycle = ({ target: { value } }: RadioChangeEvent) => {
    setCycle(value);
    getChartData(value);
  };
  //改变选择天数
  const changeSelectedCycle = (e: any) => {
    //重新给日期选择框赋值
    if (e !== '5') {
      var day = cycleDays.filter((item: any) => item.value === e)[0].day;
      setDateValue([dayjs().add(-day, 'days'), dayjs()]);
    }
    //选择 7天 30 90 自定义 默认按天
    if (e === '1') {
      setCycle('hour');
    } else {
      setCycle('day');
    }
    setSelectedCycle(e);
  };

  // 改变时间时重新给查询条件/日期赋值
  const onChangeDate = (val: any, string: any) => {
    if (val) {
      setDateValue(val);
    }
  };
  useEffect(() => {
    getChartData();
    getVisitsDataFunc({ pageNum: 1, pageSize: 10, duplicateFlg: 0 });
    getSummaryDataFunc();
  }, [dateValue]);

  const getVisitsDataFunc = async (params: any) => {
    const res: any = await getVisitsData({
      canvasId: location.state.id,
      // canvasId: 'kcltest',
      ...params,
    });
    if (res.code === 200) {
      setListData(res.data.records);
      setPageQuery({ pageNum: res.data.current, pageSize: res.data.pageSize, total: res.data.total });
    }
  };
  const getSummaryDataFunc = async () => {
    const res = await getSummaryData({
      canvasId: location.state.id,
      // canvasId: 'kcltest',
    });
    if (res.code === 200) {
      setSummaryData(res.data);
    }
  };
  //获取折线图列表数据
  const getChartData = async (value?: any) => {
    var params = {
      // canvasId: 'kcltest',
      canvasId: location.state.id,
      enterTimeStart: `${dayjs(dateValue[0]).format('YYYY-MM-DD')} 00:00:00`,
      enterTimeEnd: `${dayjs(dateValue[1]).format('YYYY-MM-DD')} 23:59:59`,
      statisticType: value ? value : cycle,
      // selectedCycle,
    };
    console.log('=======params', params);
    const res = await getSummaryByPeriod(params);
    if (res.code === 200) {
      setChartListData(res.data);
    }
  };
  //禁止选择今天之后的日期
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // return current && current > dayjs().endOf('day');
    if (!current) {
      return false;
    }
    const today = dayjs();
    const oneYearAgo = today.subtract(1, 'year');
    // 禁止选择今天之后的日期
    if (current > today.endOf('day')) {
      return true;
    }
    // 禁止选择一年之前的日期
    if (current < oneYearAgo.startOf('day')) {
      return true;
    }
    return false;
  };
  //显示重复进入的客户
  const onChangeCustom: CheckboxProps['onChange'] = e => {
    setRepeatCustom(e.target.checked);
    setListData([]);
    getVisitsDataFunc({ pageNum: 1, pageSize: pageQuery.pageSize, duplicateFlg: e.target.checked ? 1 : 0 });
  };
  // 表格change事件
  const handleTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }
    getVisitsDataFunc({ pageSize: pagination.pageSize, pageNum: pagination.current, duplicateFlg: repeatCustom ? 1 : 0 });
  };
  //分群弹窗
  const openCheckGroupPop = (status: boolean, type?: number, param?: any) => {
    console.log('==========onClick={()=>openCheckGroupPop(true)}', status);

    if (status) {
      setGroupCreatePopShow(false);
    }
    //type 1为取消，2为确定
    if (type === 2) {
      //加入分群
      addNodeToGroup({ crowdId: param.crowdId, crowdName: param.crowdName });
    } else if (type === 3) {
      setCrowd(param);
    }
    setGroupPopShow(status);
  };
  const addNodeToGroup = async (crowd: any) => {
    const params = {
      // canvasId: 'kcltest',
      canvasId: location.state.id,
      ...crowd,
    };
    const res = await addWorkflowVisitorToCrowd(params);
    if (res) {
      console.log('保存分群', res.data);
      message.success('保存成功');
    }
  };
  //创建分群变量
  const openCreateGroupPop = (status: boolean, type?: number, param?: any) => {
    //如果是关闭，那就打开选择分群弹窗
    if (!status) {
      openCheckGroupPop(true);
    } else {
      openCheckGroupPop(false);
    }
    //type 1为取消，2为确定
    setGroupCreatePopShow(status);
    //如果是确定
    if (type === 2) {
      addGroup(param);
    }
  };
  //创建分群接口
  const addGroup = async (param: any) => {
    const res = await createGroup(param);
    if (res.code === 200) {
      console.log('==========res,res', res.data);
      openCheckGroupPop(true, 3, { crowdId: res.data, crowdName: param.crowdName });
    }
  };
  const exportFunc = async () => {
    const params = {
      canvasId: location.state.id,
      enterTimeStart: `${dayjs(dateValue[0]).format('YYYY-MM-DD')} 00:00:00`,
      enterTimeEnd: `${dayjs(dateValue[1]).format('YYYY-MM-DD')} 23:59:59`,
      statisticType: cycle,
    };
    const res = await csvExport(params);
    if (res?.code === 200) {
      message.success('导出中，请到下载中心查看进度');
    } else {
      message.success('导出失败');
    }
  };
  //返回
  const checkBack = () => {
    navigate(-1);
  };
  const returnTime = (time: number, type: number) => {
    time = time ?? 0;
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    const millisecond = time % 1000;
    switch (type) {
      case 1:
        return days;
      case 2:
        return hours;
        break;
      case 3:
        return minutes;
        break;

      default:
        break;
    }
  };
  return (
    <div className={styles['ReportData_container']}>
      {/* 表头 */}
      <div className="headBox" style={{ zIndex: '1000' }}>
        <div className={styles.titleBox}>
          <div className={styles.titleBox_left}>
            <LeftOutlined className={styles.titleBox_marginRight} onClick={checkBack} />
            <span className={styles.titleBox_span}></span>
            <ModuleTitle mark="large" title={'数据报表'} />
          </div>
        </div>
      </div>
      <div className={styles['ReportData_content']}>
        {/* 卡片 */}
        <div className={styles['ReportData_card']}>
          <div className={styles['ReportData_cardItem']} style={{ marginRight: '10px' }}>
            <div className={styles['ReportData_leftIcon']}>
              <YLIcon type="icon-leijijinruliucheng" size={28} color={'var(--text-font-color7)'}></YLIcon>
            </div>
            <div className={styles['ReportData_rightBox']}>
              <div className={styles['ReportData_cardIcon']}>
                <div>累计进入流程用户</div>
                <Tooltip title="从流程第一次启动开始，到当前时间进入该流程的客户总数">
                  <QuestionCircleOutlined className={styles['ReportData_cardIcon-icon']} />
                </Tooltip>
              </div>
              <div className={styles['ReportData_text']}>
                <span className={styles['ReportData_num']}>{summaryData?.userEnterCount}</span>人
              </div>
            </div>
          </div>
          <div className={styles['ReportData_cardItem']}>
            <div className={styles['ReportData_rightIcon']}>
              <YLIcon type="icon-liuchenglishi" size={28} color={'var(--text-font-color21)'}></YLIcon>
            </div>
            <div className={styles['ReportData_rightBox']}>
              <div className={styles['ReportData_cardIcon']}>
                <div>流程历程</div>
                <Tooltip title={graphStatusNew === '3' ? '从流程第一次启动开始，到流程结束时间所持续的总时间' : "从流程第一次启动开始，到当前时间所持续的总时间"}>
                  <QuestionCircleOutlined className={styles['ReportData_cardIcon-icon']} />
                </Tooltip>
              </div>
              <div className={styles['ReportData_text']}>
                <span className={styles['ReportData_num']}>{returnTime(summaryData?.flowDuration, 1)}</span>天
                <span className={`${styles['ReportData_num']} ${styles['ReportData_marginLeft4']}`}>{returnTime(summaryData?.flowDuration, 2)}</span>
                小时
                <span className={`${styles['ReportData_num']} ${styles['ReportData_marginLeft4']}`}>{returnTime(summaryData?.flowDuration, 3)}</span>
                分钟
              </div>
            </div>
          </div>
        </div>
        {/* 折线图 */}
        <div style={{ marginTop: '24px' }}>
          <div className={styles['ReportData_buttonSelect']}>
            <div>
              <Select
                defaultValue={selectedCycle}
                options={selectCycle}
                style={{ width: 88, marginRight: '8px' }}
                onChange={changeSelectedCycle}></Select>
              <RangePicker
                placeholder={['开始时间', '结束时间']}
                format="YYYY-MM-DD"
                value={dateValue}
                allowClear={false}
                disabled={selectedCycle !== '5'}
                disabledDate={disabledDate}
                style={{ marginRight: '24px', width: '356px' }}
                // onCalendarChange={val => setDates(val)}
                onChange={onChangeDate}
                // onOpenChange={onOpenChange}
              />
              <Radio.Group options={options} onChange={changeCycle} value={cycle} optionType="button" style={{ marginRight: '10px' }} />
            </div>
            <Button icon={<DownloadOutlined />} onClick={() => exportFunc()}>
              导出
            </Button>
          </div>
          <Histogram listData={chartListData} />
        </div>
        {/* 按钮 */}
        <div className={styles['ReportData_buttonBox']}>
          <div>
            <Checkbox onChange={onChangeCustom} checked={repeatCustom}>
              显示重复进入的客户
            </Checkbox>
            {/* <Button
              type="link"
              onClick={() => {
                setRepeatCustom(false);
              }}>
              重置
            </Button> */}
          </div>
          <div>
            <Button style={{ marginRight: '8px' }} disabled={listData.length === 0} onClick={() => openCheckGroupPop(true)}>
              保存分群
            </Button>
            <Button
              onClick={() => {
                getVisitsDataFunc({ pageNum: pageQuery.pageNum, pageSize: pageQuery.pageSize, duplicateFlg: repeatCustom ? 1 : 0 });
              }}>
              刷新客户列表
            </Button>
          </div>
          {/* 创建分群弹窗 */}
          {groupPopShow && (
            <CheckGroup openCreateGroupPop={openCreateGroupPop} openCheckGroupPop={openCheckGroupPop} crowd={crowd} top={80}></CheckGroup>
          )}
          {/* 创建分群弹窗 */}
          {groupCreatePopShow && <CreateGroup openCreateGroupPop={openCreateGroupPop} top={80}></CreateGroup>}
        </div>
        {/* 列表 */}
        <div style={{ marginTop: '24px' }}>
          <Table
            columns={columns}
            // scroll={{ y: 380 }}
            dataSource={listData}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              current: pageQuery.pageNum,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: total => `共 ${total} 条`,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};
export default ReportData;
