import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import BarLineCahrt from "../BarLineCahrt";
import RelationChart from "../RelationChart";
import type { IBarLineData } from "../index";
import type { BubbleDataItem } from '../RelationChart/utils';
import styles from "./index.module.scss";

const analysisDimMap: any = {
  单品: '商品',
  类目: '品类',
};

const series = [
  {
    name: '销售额',
    dataIndex: 'gmv',
    type: 'bar',
    color: '#1677FF',
  },
  {
    name: '购买人数',
    dataIndex: 'buyers',
    type: 'bar',
    color: '#7AA4FA',
  },
  {
    name: '跨单连带人数',
    dataIndex: 'people',
    type: 'bar',
    color: '#F6C022',
  },
  {
    name: '跨单连带-同品人数',
    dataIndex: 'sameProductPeople',
    type: 'bar',
    color: '#657798',
  },
  {
    name: '跨单连带-跨品人数',
    dataIndex: 'crossProductPeople',
    type: 'bar',
    color: '#5AD8A6',
  },
  {
    name: '跨单连带率',
    dataIndex: 'rate',
    type: 'line',
    color: '#F6C022',
  },
  {
    name: '跨单连带-同品连带率',
    dataIndex: 'sameProductRate',
    type: 'line',
    color: '#657798',
  },
  {
    name: '跨单连带-跨品连带率',
    dataIndex: 'crossProductRate',
    type: 'line',
    color: '#5AD8A6',
  },
];
// 购买人数 buyers
// 同单连带人数 people
// 同单连带-同品人数 sameProductPeople
// 同单连带-跨品人数 crossProductPeople
// 同单连带率 rate
// 同品连带-同品连带率 sameProductRate

interface IProps {
  barLineData: IBarLineData[];
  bubbleData: BubbleDataItem[];
  analysisDim: string;
}

const CrossOrderJoint: React.FC<IProps> = (props) => {
  const { barLineData = [], bubbleData = [], analysisDim } = props;
  return (
    <div className={styles.CrossOrderJoint}>
      <div className={styles.title}>
        <span>跨单连带（一人）</span>
        <Tooltip placement="top" title={<div>
          {
            analysisDim === '单品' ? <>
              1、反映同一人不同订单的商品连带情况；<br />
              2、订单口径：同一人同一天所有购买渠道的订单都统计为一笔订单<br />
              3、跨单连带（一人）：统计期内，产生购买订单的天数大于等于2，单天订单包含的正装商品sku数大于等于1<br />
            </> : <>
              1、反映同一人多订单的品类连带情况；<br />
              2、订单口径：同一人同一天所有购买渠道的订单都统计为一笔订单<br />
              3、跨单连带（一人）：统计期内，产生购买订单的天数大于等于2，单天订单包含的正装商品sku数大于等于1。<br />
            </>
          }
        </div>}>
          <QuestionCircleOutlined className={styles.questionIcon} />
        </Tooltip>
      </div>
      <div className={styles.subTitle}>
        <span>Top{analysisDimMap?.[analysisDim] ?? '品类'}</span>
        <Tooltip overlayStyle={{ maxWidth: 500 }} placement="right" title={<div>
          {
            analysisDim === '单品' ? <>
              销售额：统计期内购买指定商品的销售额；<br />
              购买人数：统计期内购买指定商品的去重人数；<br />
              跨单连带人数：统计期内，产生购买订单的天数大于等于2，且包含该商品的订单数大于1的去重客户数；<br />
              跨单连带率：跨单连带人数/购买人数<br />
              跨单连带-同品人数：统计期内，产生购买订单的天数大于等于2，且指定商品的购买天数大于等于2，计算去重购买人数；<br />
              跨单连带-同品连带率：跨单连带-同品人数/跨单连带人数；<br />
              跨单连带-跨品人数：统计期内，产生购买订单的天数大于等于2，且至少一笔订单包含指定商品，其余订单至少一笔包含其他正装商品，计算去重购买人数；<br />
              跨单连带-跨品连带率：跨单连带-跨品人数/跨单连带人数。<br />
            </> : <>
              销售额：统计期内购买指定品类的销售额；<br />
              购买人数：统计期内购买指定品类的去重人数；<br />
              跨单连带人数：统计期内，产生购买订单的天数大于等于2，且包含该品类的订单数大于1的去重客户数；<br />
              跨单连带率：跨单连带人数/购买人数<br />
              跨单连带-同品人数：统计期内，产生购买订单的天数大于等于2，且指定品类的购买天数大于等于2，计算去重购买人数；<br />
              跨单连带-同品连带率：跨单连带-同品人数/跨单连带人数；<br />
              跨单连带-跨品人数：统计期内，产生购买订单的天数大于等于2，且至少一笔订单包含指定品类，其余订单至少一笔包含其他品类正装商品，计算去重购买人数；<br />
              跨单连带-跨品连带率：跨单连带-跨品人数/跨单连带人数。<br />
            </>
          }
        </div>}>
          <QuestionCircleOutlined className={styles.questionIcon} />
        </Tooltip>
      </div>
      <BarLineCahrt series={series} data={barLineData} />
      <div className={styles.subTitle}>
        <span>Top{analysisDimMap?.[analysisDim] ?? '品类'}连带情况</span>
        <Tooltip placement="top" title={<div>
          {
            analysisDim === '单品' ? <>
              每一个气泡代表唯一1个商品，以Top商品为核心展开连带关系，气泡大小由商品销售额决定
            </> : <>
              每一个气泡代表唯一1个品类，以Top品类为核心展开连带关系，气泡大小由品类销售额决定。
            </>
          }
        </div>}>
          <QuestionCircleOutlined className={styles.questionIcon} />
        </Tooltip>
      </div>
      <RelationChart instanceName="cross" analysisDim={analysisDim} data={bubbleData} />
    </div>
  );
};

export default CrossOrderJoint;