import Login from '../pages/base/Login';
import LZLayout from '../pages/base/Layout';
import LoginVerify from '../pages/base/LoginVerify';
import Validation from '../pages/base/Validation';
import Reset from '../pages/base/Reset';
import AccountManagement from '../pages/SystemSettingManagement/AccountAuthority/AccountManagement';
// 个人中心修改信息
import UserInfoEdit from '../pages/PersonalCenter/EditUserInfo';
// 数据接入——start
import DataAccess from '../pages/Files/OriginalDataAccess/DataAccess';
import DataAccessDetails from '../pages/Files/OriginalDataAccess/DataAccessDetails';
import DataAccessDetailsList from '../pages/Files/OriginalDataAccess/DataAccessDetailsList';
// 数据接入——end
// import NotFound from "../pages/base/NotFound"
// 用户组管理
import UserGroupManagement from '../pages/SystemSettingManagement/AccountAuthority/UserGroupManagement';

// 角色管理
import RoleManagement from '../pages/SystemSettingManagement/AccountAuthority/RoleManagement';

// 配置中心/系统设置/授权管理
import AuthorizationManagement from '../pages/SystemSettingManagement/SystemSetting/AuthorizationManagement';
import AppearanceConfiguration from '../pages/SystemSettingManagement/SystemSetting/AppearanceConfiguration';

// 系统日志
import SystemLog from '../pages/SystemSettingManagement/AccountSysteLog/SystemLog';
// 忠诚度管理/记录管理/等级变更记录
import GradeChangeRecord from '../crm/page/Loyalty/RecordManagement/GradeChangeRecord';
// 忠诚度管理/记录管理/入退会记录
import MemberChangeRecord from '../crm/page/Loyalty/RecordManagement/MemberChangeRecord';
// 忠诚度管理/记录管理/成长值变更记录
import GrowthChangeRecord from '../crm/page/Loyalty/RecordManagement/GrowthChangeRecord';
// 忠诚度管理/记录管理/积分变更记录
import PointsChangeRecord from '../crm/page/Loyalty/RecordManagement/PointsChangeRecord';
// 忠诚度管理/忠诚度管理/会员卡列表
import MemberCardList from '../crm/page/Loyalty/MemberCard/MemberCardList';
// 忠诚度管理/奖励管理/奖励事件
import RewardEvents from '../crm/page/Loyalty/RewardManagement/RewardEvents';
// 忠诚度管理/奖励管理/批量发放积分
import DistributePoints from '../crm/page/Loyalty/RewardManagement/DistributePoints';
//忠诚度管理
import StockMemberManagement from '@/pages/Loyalty/StockMemberManagement';

// 用户分群/用户分群列表
import UserGroupingList from '../pages/UserGrouping/GroupingManagement/UserGroupingList';
// 用户分群/新建分群
import CreateUserGrouping from '../pages/UserGrouping/GroupingManagement/CreateUserGrouping';
import CreateExportMain from '../pages/UserGrouping/GroupingManagement/CreateExportMain';
import CreateUserGroupingChild from '../pages/UserGrouping/GroupingManagement/CreateUserGroupingChild';
//用户列表
import GroupUserList from '../pages/UserGrouping/GroupingManagement/UserList';

//用户类标签
import Tag from '../pages/Tag/RuleTags/Tags';
//导入标签
import CreateExportLabel from '../pages/Tag/RuleTags/CreateExportLabel';

// 新建标签
import CreateLabel from '../pages/Tag/RuleTags/CreateLabel';

//用户列表
import UserList from '../pages/Tag/UserList';

import marketRouteList from './marketRouterMap';

// 用户模型
import UserCenter from '../pages/DataIntegration/UserCenter';
// 业务模型
import BusinessModel from '../pages/DataIntegration/BusinessModel';
// 订单/订单商品表/退单表
import IntegrationTable from '../pages/DataIntegration/BusinessModel/IntegrationTable';
// 业务属性页面
import BusinessAttribute from '../pages/DataIntegration/BusinessModel/BusinessAttribute';
// 维度模型
import DimensionalModel from '../pages/DataIntegration/DimensionalModel';
// 商品管理
import GoodsManage from '../pages/DataIntegration/GoodsManage';
// 商品映射关系表
import CommodityMappingRelationship from '../pages/DataIntegration/CommodityMappingRelationship';

// OneID融合
import OneIDCoalesce from '../pages/OneIDManage/OneIDCoalesce';

// 数据看板/基础看板/数据总览
import DataScreening from '../crm/page/DataBoard/Basis/DataScreening';
// 数据看板/基础看板/客户资产分析
import CustomerAssetAnalysis from '../crm/page/DataBoard/Basis/CustomerAssetAnalysis';
// 数据看板/基础看板/客户交易分析
import CustomerTransactionAnalysis from '../crm/page/DataBoard/Basis/CustomerTransactionAnalysis';
// 数据看板/基础看板/会员资产分析
import MemberAssetAnalysis from '../crm/page/DataBoard/Basis/MemberAssetAnalysis';
// 数据看板/基础看板/会员交易分析
import MemberTransactionAnalysis from '../crm/page/DataBoard/Basis/MemberTransactionAnalysis';
// 数据看板/基础看板/RFM分析
import RFMAnalysis from '../crm/page/DataBoard/Basis/RFMAnalysis';
// 数据看板/基础看板/RFM分析/新建分析
import CreateRFMAnalysis from '../crm/page/DataBoard/Basis/RFMAnalysis/CreateRFMAnalysis';
// 数据看板/基础看板/RFM分析/分析结果
import RFMAnalysisDetails from '../crm/page/DataBoard/Basis/RFMAnalysis/RFMAnalysisResult';

// 数据看板/行业看板/核心指标看板
// 总览
import Overview from '@/crm/page/DataBoard/Industry/Overview';
// 人群细分
import CrowdSegmentation from '@/crm/page/DataBoard/Industry/CrowdSegmentation';
// 商品销售追踪
import ProductSalesTracking from '@/crm/page/DataBoard/Industry/ProductSalesTracking';
// 商品细分
import ProductSegmentation from '@/crm/page/DataBoard/Industry/ProductSegmentation';
import CreateProductSegmentation from '@/crm/page/DataBoard/Industry/ProductSegmentation/Create';
import ProductSegmentationDetails from '@/crm/page/DataBoard/Industry/ProductSegmentation/Details';

//客户洞察列表
import UserInsightUserList from '../pages/UserInsight/UserInsightDocument/UserList';
//群体画像洞察
import GroupUserInsight from '@/pages/UserInsight/GroupUserInsight/GroupUserInsight';
//群体画像洞察详情
import GroupUserInsightDetail from '@/pages/UserInsight/GroupUserInsight/GroupUserInsightDetail';
//群体画像洞察新增和编辑
import GroupUserInsightForm from '@/pages/UserInsight/GroupUserInsight/GroupUserInsightForm';
//群体画像洞察历史归档
import GroupUserInsightHistory from '@/pages/UserInsight/GroupUserInsight/GroupUserInsightHistory';
//客户查询
import UserInsightUserSerch from '../pages/UserInsight/UserInsightDocument/UserSerch';
//客户详情
import UserInsightUserDetail from '../pages/UserInsight/UserInsightDocument/UserDetail';
//用户洞察 - 交集一览
import UserIntersectionChart from '../pages/UserInsight/UserIntersectionChart/UserChart';
// 首页
import HomePage from '../pages/Home/HomePage/page';

// 鹿智 AI
import DatasetList from '@/pages/LuZhiBI/DatasetManagement/DatasetList';
import DatasetView from '@/pages/LuZhiBI/DatasetManagement/DatasetView';
import DataSourceAdd from '@/pages/LuZhiBI/DatasetManagement/DataSourceAdd';
import ChatBI from '@/pages/LuZhiBI/ChatBI';
import ChatBINoResult from '@/pages/LuZhiBI/ChatBI/ChatBINoResult';
import ReportFormList from '@/pages/LuZhiBI/ChatBI/ReportFormList';
import ReportFormDetail from '@/pages/LuZhiBI/ChatBI/ReportFormDetail';
import AtomicIndicators from "@/pages/LuZhiBI/IndicatorPlatform/AtomicIndicators";
import DerivedIndicators from "@/pages/LuZhiBI/IndicatorPlatform/DerivedIndicators";
import DimensionManagement from "@/pages/LuZhiBI/IndicatorPlatform/DimensionManagement";

//工具箱-公众号发送记录
import PublicAccountRecord from '@/pages/market/private/sms/publicAccountRecord';

//MA数据报表
import ReportData from '@/pages/MAgraph/ReportData';
//MA策略
import MAStrategy from '@/pages/MAgraph/MarketPlan/MAStrategy';
// 智慧营销-营销策略
import MAList from '../pages/MAgraph/MarketPlan/MAList';
// 智慧营销-短信发送管理
import SmsSend from '@/pages/MAgraph/reachSendManage/smsSend';
// 智慧营销-短信发送管理-短信明细
import SmsSendDetail from '@/pages/MAgraph/reachSendManage/smsSendDetail';
// 智慧营销-短信模板管理
import SmsTemplate from '@/pages/MAgraph/reachContentManage/smsTemplate';
// 智慧营销-短信通道管理
import SmsAccount from '@/pages/MAgraph/reachAccountManage/smsAccount';
const routeList = [
  { path: '/', name: 'main', component: <LZLayout />, auth: true, layout: true },
  { path: '/login', name: 'Login', component: <Login />, auth: false, layout: false },
  { path: '/loginVerify', name: 'LoginVerify', component: <LoginVerify />, auth: false, layout: false },
  { path: '/validation', name: 'Validation', component: <Validation />, auth: false, layout: false },
  { path: '/reset', name: 'Reset', component: <Reset />, auth: false, layout: false },
  {
    path: '/systemSettingManagement/accountAuthority/accountManagement',
    name: 'AccountManagement',
    component: <AccountManagement />,
    auth: true,
    layout: true,
    menu: true,
  },
  { path: '/home/homePage/page', name: 'home', component: <HomePage />, auth: true, layout: true, menu: true },
  // 个人中心信息修改
  { path: '/userInfoEdit', name: 'UserInfoEdit', component: <UserInfoEdit />, auth: true, layout: false },

  // 用户组管理
  {
    path: '/systemSettingManagement/accountAuthority/userGroupManagement',
    name: 'UserGroupManagement',
    component: <UserGroupManagement />,
    auth: true,
    layout: true,
    menu: true,
  },

  // 角色管理
  {
    path: '/systemSettingManagement/accountAuthority/roleManagement',
    name: 'RoleManagement',
    component: <RoleManagement />,
    auth: true,
    layout: true,
    menu: true,
  }, // 数据接入
  // 系统日志
  {
    path: '/systemSettingManagement/accountSysteLog/SystemLog',
    name: 'SystemLog',
    component: <SystemLog />,
    auth: true,
    layout: true,
    menu: true,
  },
  // 配置中心-系统设置-授权管理
  {
    path: '/systemSettingManagement/systemSetting/authorizationManagement',
    name: 'AuthorizationManagement',
    component: <AuthorizationManagement />,
    auth: true,
    layout: true,
    menu: true,
  },
  // 配置中心-系统设置-外观配置
  {
    path: '/systemSettingManagement/systemSetting/appearanceConfiguration',
    name: 'AppearanceConfiguration',
    component: <AppearanceConfiguration />,
    auth: true,
    layout: true,
    menu: true,
  },

  //忠诚度管理
  {
    path: '/Loyalty/RecordManagement/GradeChangeRecord',
    name: 'GradeChangeRecord',
    component: <GradeChangeRecord />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/Loyalty/RecordManagement/MemberChangeRecord',
    name: 'MemberChangeRecord',
    component: <MemberChangeRecord />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/Loyalty/RecordManagement/GrowthChangeRecord',
    name: 'GrowthChangeRecord',
    component: <GrowthChangeRecord />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/Loyalty/RecordManagement/PointsChangeRecord',
    name: 'PointsChangeRecord',
    component: <PointsChangeRecord />,
    auth: true,
    layout: true,
    menu: true,
  },
  { path: '/Loyalty/MemberCard/MemberCardList', name: 'MemberCardList', component: <MemberCardList />, auth: true, layout: true, menu: true },
  { path: '/Loyalty/RewardManagement/RewardEvents', name: 'RewardEvents', component: <RewardEvents />, auth: true, layout: true, menu: true },
  {
    path: '/Loyalty/RewardManagement/DistributePoints',
    name: 'DistributePoints',
    component: <DistributePoints />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/Loyalty/StockMemberManagement/StockMemberManagement',
    name: 'StockMemberManagement',
    component: <StockMemberManagement />,
    auth: true,
    layout: true,
    menu: true,
  },

  // 用户标签
  { path: '/tages/ruleTags/tags', name: 'Tags', component: <Tag />, auth: true, layout: true, menu: true },
  // 新建标签  
  { path: '/tages/ruleTags/tags/createLabel', name: 'CreateLabel', component: <CreateLabel />, menu: true, auth: true, layout: true, parentKey: '/tages/ruleTags/tags' },
  // 导入标签
  { path: '/tages/ruleTags/tags/CreateExportLabel', name: 'CreateExportLabel', component: <CreateExportLabel />, menu: true, auth: true, layout: true, parentKey: '/tages/ruleTags/tags' },
  // 标签用户列表
  {
    path: '/tages/ruleTags/tags/userList',
    name: 'UserList',
    component: <UserList />,
    menu: true,
    auth: true,
    layout: true,
    parentKey: '/tages/ruleTags/tags',
  },
  // 用户分群
  { path: '/userGrouping/groupingManagement/userGroupingList', name: 'UserGroupingList', component: <UserGroupingList />, auth: true, layout: true, menu: true },
  // 新建分群   
  { path: '/userGrouping/groupingManagement/createUserGrouping', name: 'CreateUserGrouping', component: <CreateUserGrouping />, auth: true, layout: true },
  // 导入创建 总页
  { path: '/userGrouping/groupingManagement/CreateExportMain', name: 'CreateExportMain', component: <CreateExportMain />, auth: true, layout: true ,menu: true , parentKey: '/userGrouping/groupingManagement/userGroupingList'},
  // 新建分群-2
  { path: '/userGrouping/groupingManagement/createUserGroupingChild', name: 'CreateUserGroupingChild', component: <CreateUserGroupingChild />, auth: true, layout: true },
  // 分群用户列表
  {
    path: '/userGrouping/groupingManagement/userGroupingList/userList',
    name: 'GroupUserList',
    component: <GroupUserList />,
    menu: true,
    auth: true,
    layout: true,
    parentKey: '/userGrouping/groupingManagement/userGroupingList',
  },
  // 客户洞察列表
  {
    path: '/userInsight/userInsightDocument/UserList',
    name: 'UserInsightUserList',
    component: <UserInsightUserList />,
    auth: true,
    layout: true,
    menu: true,
  },
  // 客户查询
  { path: '/userInsight/userInsightDocument/UserSerch', name: 'UserInsightUserSerch', component: <UserInsightUserSerch />, auth: true, layout: true },
  // 客户详情
  {
    path: '/userInsight/userInsightDocument/UserDetail',
    name: 'UserInsightUserDetail',
    component: <UserInsightUserDetail />,
    auth: true,
    layout: false,
    menu: true,
  },
  //用户洞察-交集一览
  {
    path: '/userInsight/userIntersectionChart/UserChart',
    name: 'UserIntersectionChart',
    component: <UserIntersectionChart />,
    auth: true,
    layout: true,
    menu: true,
  },

  // 分群画像洞察
  {
    path: '/userInsight/GroupUserInsight/GroupUserInsight',
    name: 'GroupUserInsight',
    component: <GroupUserInsight />,
    auth: true,
    layout: true,
    menu: true,
  },
  // 分群画像洞察报告详情
  {
    path: '/userInsight/GroupUserInsight/GroupUserInsight/details',
    name: 'GroupUserInsightDetail',
    component: <GroupUserInsightDetail />,
    menu: true,
    auth: true,
    layout: true,
    parentKey: '/userInsight/GroupUserInsight/GroupUserInsight',
  },
  // 分群画像洞察报告详情
  {
    path: '/userInsight/GroupUserInsight/GroupUserInsight/form',
    name: 'GroupUserInsightForm',
    component: <GroupUserInsightForm />,
    menu: true,
    auth: true,
    layout: true,
    parentKey: '/userInsight/GroupUserInsight/GroupUserInsight',
  },
  // 分群画像洞察历史归档
  { path: '/userInsight/GroupUserInsight/GroupUserInsight/history', name: 'GroupUserInsightHistory', component: <GroupUserInsightHistory />, menu: true, auth: true, layout: true, parentKey: '/userInsight/GroupUserInsight/GroupUserInsight' },

  // 数据档案
  // 数据接入——start
  { path: '/files/originalDataAccess/dataAccess', name: 'DataAccess', component: <DataAccess />, auth: true, layout: true, menu: true }, // 数据接入
  {
    path: '/files/originalDataAccess/dataAccess/details',
    name: 'DataAccessDetails',
    component: <DataAccessDetails />,
    auth: true,
    layout: true,
    menu: true,
    parentKey: '/files/originalDataAccess/dataAccess',
  }, // 数据接入查看详情
  {
    path: '/files/originalDataAccess/dataAccess/details/list',
    name: 'DataAccessDetailsList',
    component: <DataAccessDetailsList />,
    auth: true,
    layout: true,
    menu: true,
    parentKey: '/files/originalDataAccess/dataAccess',
  }, // 数据接入查看详情
  // 数据接入——end
  { path: '/files/dataIntegration/userCenter', name: 'UserCenter', component: <UserCenter />, auth: true, layout: true, menu: true },
  { path: '/files/dataIntegration/businessModel', name: 'BusinessModel', component: <BusinessModel />, auth: true, layout: true, menu: true },
  {
    path: '/files/dataIntegration/businessModel/integrationTable',
    name: 'IntegrationTable',
    component: <IntegrationTable />,
    auth: true,
    layout: true,
  },
  {
    path: '/files/dataIntegration/businessModel/businessAttribute',
    name: 'BusinessAttribute',
    component: <BusinessAttribute />,
    auth: true,
    layout: true,
  },
  {
    path: '/files/dataIntegration/dimensionalModel',
    name: 'DimensionalModel',
    component: <DimensionalModel />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/files/dataIntegration/goodsManage',
    name: 'GoodsManage',
    component: <GoodsManage />,
    auth: true,
    layout: true,
    menu: true,
    parentKey: '/files/dataIntegration/dimensionalModel',
  },
  {
    path: '/files/dataIntegration/commodityMappingRelationship',
    name: 'CommodityMappingRelationship',
    component: <CommodityMappingRelationship />,
    auth: true,
    layout: true,
    menu: true,
  },
  { path: '/files/oneIDManage/oneIDCoalesce', name: 'OneIDCoalesce', component: <OneIDCoalesce />, auth: true, layout: true, menu: true },

  //数据看板
  { path: '/DataBoard/Industry/Overview', name: 'Overview', component: <Overview />, auth: true, layout: true, menu: true },
  {
    path: '/DataBoard/Industry/CrowdSegmentation', name: 'CrowdSegmentation', component: <CrowdSegmentation />, auth: true, layout: true, menu: true,
  },
  {
    path: '/DataBoard/Industry/ProductSalesTracking', name: 'ProductSalesTracking', component: <ProductSalesTracking />, auth: true, layout: true, menu: true,
  },
  {
    path: '/DataBoard/Industry/ProductSegmentation', name: 'ProductSegmentation', component: <ProductSegmentation />, auth: true, layout: true, menu: true,
  },
  {
    path: '/DataBoard/Industry/ProductSegmentation/create', name: 'CreateProductSegmentation', component: <CreateProductSegmentation />, auth: true, layout: true, menu: true, parentKey: '/DataBoard/Industry/ProductSegmentation',
  },
  {
    path: '/DataBoard/Industry/ProductSegmentation/details', name: 'ProductSegmentationDetails', component: <ProductSegmentationDetails />, auth: true, layout: true, menu: true, parentKey: '/DataBoard/Industry/ProductSegmentation',
  },

  //行业看板
  { path: '/DataBoard/Basis/DataScreening', name: 'DataScreening', component: <DataScreening />, auth: true, layout: true, menu: true },
  {
    path: '/DataBoard/Basis/CustomerAssetAnalysis',
    name: 'CustomerAssetAnalysis',
    component: <CustomerAssetAnalysis />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/DataBoard/Basis/CustomerTransactionAnalysis',
    name: 'CustomerTransactionAnalysis',
    component: <CustomerTransactionAnalysis />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/DataBoard/Basis/MemberAssetAnalysis',
    name: 'MemberAssetAnalysis',
    component: <MemberAssetAnalysis />,
    auth: true,
    layout: true,
    menu: true,
  },
  {
    path: '/DataBoard/Basis/MemberTransactionAnalysis',
    name: 'MemberTransactionAnalysis',
    component: <MemberTransactionAnalysis />,
    auth: true,
    layout: true,
    menu: true,
  },
  { path: '/DataBoard/Basis/RFMAnalysis', name: 'RFMAnalysis', component: <RFMAnalysis />, auth: true, layout: true, menu: true },
  {
    path: '/DataBoard/Basis/RFMAnalysis/details',
    name: 'RFMAnalysisDetails',
    component: <RFMAnalysisDetails />,
    auth: true,
    layout: true,
    menu: true,
    parentKey: '/DataBoard/Basis/RFMAnalysis',
  },
  {
    path: '/DataBoard/Basis/RFMAnalysis/create',
    name: 'CreateRFMAnalysis',
    component: <CreateRFMAnalysis />,
    auth: true,
    layout: true,
    menu: true,
    parentKey: '/DataBoard/Basis/RFMAnalysis',
  },

    // 鹿智BI
    {
        path: '/files/LuZhiBI/IndicatorPlatform/AtomicIndicators', name: 'AtomicIndicators', component: <AtomicIndicators />, auth: true, layout: true, menu: true,
    },
    {
        path: '/files/LuZhiBI/IndicatorPlatform/DerivedIndicators', name: 'DerivedIndicators', component: <DerivedIndicators />, auth: true, layout: true, menu: true
    },
    {
        path: '/files/LuZhiBI/IndicatorPlatform/DimensionManagement', name: 'DimensionManagement', component: <DimensionManagement />, auth: true, layout: true, menu: true
    },
    {
        path: '/files/LuZhiBI/DatasetManagement/DatasetList', name: 'DatasetList', component: <DatasetList />, auth: true, layout: true, menu: true
    },
    {
        path: '/files/LuZhiBI/DatasetManagement/DatasetView', name: 'DatasetView', component: <DatasetView />, auth: true, layout: true, menu: true, parentKey: '/LuZhiBI/DatasetManagement/DatasetList'
    },
    {
        path: '/files/LuZhiBI/DatasetManagement/DataSourceAdd', name: 'DataSourceAdd', component: <DataSourceAdd />, auth: true, layout: true, menu: true, parentKey: '/LuZhiBI/DatasetManagement/DatasetList'
    },
    {
        path: '/DataBoard/LuZhiBI/ChatBI/toChat', name: 'ChatBI', component: <ChatBI />, auth: true, layout: true, menu: true, fullScreen: true
    },
    {
        path: '/DataBoard/LuZhiBI/ChatBI', name: 'ReportFormList', component: <ReportFormList />, auth: true, layout: true, menu: true
    },
    {
        path: '/DataBoard/LuZhiBI/ChatBI/reportFormDetail', name: 'ReportFormDetail', component: <ReportFormDetail />, auth: true, layout: true, menu: true
    },
    {
        path: '/DataBoard/LuZhiBI/ChatBI/noResult', name: 'ChatBINoResult', component: <ChatBINoResult />, auth: true, layout: true, menu: true, fullScreen: true
    },

  // 鹿智BI
  {
    path: '/files/LuZhiBI/IndicatorPlatform/AtomicIndicators', name: 'AtomicIndicators', component: <AtomicIndicators />, auth: true, layout: true, menu: true,
  },
  {
    path: '/files/LuZhiBI/IndicatorPlatform/DerivedIndicators', name: 'DerivedIndicators', component: <DerivedIndicators />, auth: true, layout: true, menu: true
  },
  {
    path: '/files/LuZhiBI/IndicatorPlatform/DimensionManagement', name: 'DimensionManagement', component: <DimensionManagement />, auth: true, layout: true, menu: true
  },
  {
    path: '/files/LuZhiBI/DatasetManagement/DatasetList', name: 'DatasetList', component: <DatasetList />, auth: true, layout: true, menu: true
  },
  {
    path: '/files/LuZhiBI/DatasetManagement/DatasetView', name: 'DatasetView', component: <DatasetView />, auth: true, layout: true, menu: true, parentKey: '/LuZhiBI/DatasetManagement/DatasetList'
  },
  {
    path: '/files/LuZhiBI/DatasetManagement/DataSourceAdd', name: 'DataSourceAdd', component: <DataSourceAdd />, auth: true, layout: true, menu: true, parentKey: '/LuZhiBI/DatasetManagement/DatasetList'
  },
  {
    path: '/DataBoard/LuZhiBI/ChatBI/toChat', name: 'ChatBI', component: <ChatBI />, auth: true, layout: true, menu: true, fullScreen: true
  },
  {
    path: '/DataBoard/LuZhiBI/ChatBI', name: 'ReportFormList', component: <ReportFormList />, auth: true, layout: true, menu: true
  },
  {
    path: '/DataBoard/LuZhiBI/ChatBI/reportFormDetail', name: 'ReportFormDetail', component: <ReportFormDetail />, auth: true, layout: true, menu: true
  },
  {
    path: '/DataBoard/LuZhiBI/ChatBI/noResult', name: 'ChatBINoResult', component: <ChatBINoResult />, auth: true, layout: true, menu: true, fullScreen: true
  },
  //工具箱-公众号
  {
    path: '/market/private/sms/publicAccountRecord/records',
    name: 'PublicAccountRecord',
    component: <PublicAccountRecord />,
    auth: true,
    layout: true,
    menu: true,
  },
  // 智慧营销-营销策略 暂时用
  { path: '/MA/MarketPlan/MAList', name: 'MAList', component: <MAList />, auth: true, layout: true, menu: true },
  // 智慧营销-短信发送管理 暂时用
  { path: '/MA/reachSend/SmsSend', name: 'SmsSend', component: <SmsSend />, auth: true, layout: true, menu: true },
  // 智慧营销-短信模板管理 暂时用
  {
    path: '/MA/reachContent/SmsTemplate',
    name: 'SmsTemplate',
    component: <SmsTemplate />,
    auth: true,
    layout: true,
    menu: true,
  },
  // 智慧营销-短信发送管理-明细 暂时用
  {
    path: '/MA/reachContent/SmsSendDetail',
    name: 'SmsSendDetail',
    component: <SmsSendDetail />,
    auth: true,
    layout: true,
    menu: false,
  },
  // 智慧营销-短信通道管理 暂时用
  { path: '/MA/reachAccount/SmsAccount', name: 'SmsAccount', component: <SmsAccount />, auth: true, layout: true, menu: true },
  {
    path: '/MA/MarketPlan/MAList/strategy',
    name: 'MAStrategy',
    component: <MAStrategy />,
    auth: true,
    layout: false,
    menu: false,
  },
  {
    path: '/MA/MarketPlan/MAList/ReportData',
    name: 'ReportData',
    component: <ReportData />,
    auth: true,
    layout: false,
    menu: true,
  },
  ...marketRouteList,
  // { path: "/error", name: "Error", component: < NotFound />, auth: false },
];

export default routeList;
