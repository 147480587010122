/**
 * 人群细分-老客By Recency回购-回购比例
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import retentionPng from "@/assets/images/retention.png";
import winbackPng from "@/assets/images/winback.png";
import sleepingPng from "@/assets/images/sleeping.png";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import { getGeneralChat } from "@/crm/api/industryBoardApi";
import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function MemberFourQuadrants(props: any) {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { searchForm } = props;
  const { metricAllList } = useGetMetricList();
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const testStyle = styles.retention;
  const initDataSource = [
    {
      titleZh: "留存回购率",
      metricId: "",
      value: null,
      type: "prop",
    },
    {
      titleZh: "留存回购率",
      metricId: "",
      value: null,
      type: "yoy",
    },
    {
      titleZh: "留存回购人数",
      metricId: "",
      value: null,
      type: "normal",
    },
    {
      titleZh: "留存回购人数占比",
      metricId: "",
      value: null,
      type: "prop",
    },
    {
      titleZh: "留存回购人数",
      metricId: "",
      value: null,
      type: "yoy",
    },
    {
      titleZh: "留存客户基数",
      metricId: "",
      value: null,
      type: "normal",
    },
    {
      titleZh: "留存客户基数",
      metricId: "",
      value: null,
      type: "yoy",
    },
    // 召回
    {
      titleZh: "召回回购率",
      metricId: "",
      value: null,
      type: "prop",
    },
    {
      titleZh: "召回回购率",
      metricId: "",
      value: null,
      type: "yoy",
    },
    {
      titleZh: "召回回购人数",
      metricId: "",
      value: null,
      type: "normal",
    },
    {
      titleZh: "召回回购人数占比",
      metricId: "",
      value: null,
      type: "prop",
    },
    {
      titleZh: "召回回购人数",
      metricId: "",
      value: null,
      type: "yoy",
    },
    {
      titleZh: "召回客户基数",
      metricId: "",
      value: null,
      type: "normal",
    },
    {
      titleZh: "召回客户基数",
      metricId: "",
      value: null,
      type: "yoy",
    },
    // 沉默
    {
      titleZh: "沉默回购率",
      metricId: "",
      value: null,
      type: "prop",
    },
    {
      titleZh: "沉默回购率",
      metricId: "",
      value: null,
      type: "yoy",
    },
    {
      titleZh: "沉默回购人数",
      metricId: "",
      value: null,
      type: "normal",
    },
    {
      titleZh: "沉默回购人数占比",
      metricId: "",
      value: null,
      type: "prop",
    },
    {
      titleZh: "沉默回购人数",
      metricId: "",
      value: null,
      type: "yoy",
    },
    {
      titleZh: "沉默客户基数",
      metricId: "",
      value: null,
      type: "normal",
    },
    {
      titleZh: "沉默客户基数",
      metricId: "",
      value: null,
      type: "yoy",
    },
  ];
  const [dataSource, setDataSource] = useState([
    {
      name: "Retention",
      icon: retentionPng,
      style: styles.retention,
      recencyRadio: 0, // 回购率（百分比、精确2位小数）
      valuePurchaser: 0, // purchaser人数 (整数)
      ttlPurchaser: 0, // ttl占比
      valueBase: 0, // base (整数)

      yoyPurchaser: 0, // purchaser同比（百分比、精确2位小数）
      yoyAll: 0, // 同比（百分比、精确2位小数）
      yoyBase: 0, // base同比（百分比、精确2位小数）
    },
    {
      name: "Winback",
      icon: winbackPng,
      style: styles.winback,
      recencyRadio: 0,
      yoyAll: 0,
      valuePurchaser: 0,
      yoyPurchaser: 0,
      ttlPurchaser: 0,
      yoyBase: 0,
      valueBase: 0,
    },
    {
      name: "Sleeping",
      icon: sleepingPng,
      style: styles.sleeping,
      recencyRadio: 0,
      yoyAll: 0,
      valuePurchaser: 0,
      yoyPurchaser: 0,
      ttlPurchaser: 0,
      yoyBase: 0,
      valueBase: 0,
    },
  ]);
  const [finalYoyDataSource, setFinalYoyDataSource] = useState<any>(dataSource);
  const [finalDataSource, setFinalDataSource] = useState<any>(dataSource);
  const [finalPropDataSource, setFinalPropDataSource] = useState<any>(dataSource);
  
  const [hasMetricIdDataSource, setHasMetricIdDataSource] = useState([]);
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);
  const [getNormalEnd, setGetNormalEnd] = useState(false);
  const setMetricId = () => {
    // @ts-ignore
    const copyDataSource = [];
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (
      searchForm?.shopId?.includes("all") &&
      !searchForm?.platformType?.includes("all")
    ) {
      str += "分渠道";
    } else if (
      searchForm?.shopId?.includes("all") &&
      searchForm?.platformType?.includes("all")
    ) {
      str = "";
    }
    const dataSource = initDataSource;
    dataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.titleZh + str;
      });
      if (findItem.length > 0) {
        copyDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setHasMetricIdDataSource(copyDataSource);
    setGetMetricIdEnd(true);
  };

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId();
    }
  }, [searchForm, metricAllList]);

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {

      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds:finalShopId,
      };
      setFinalDataSource([]);
      const groupedDataSource = groupArrByFrameWorkType(
        hasMetricIdDataSource.filter((item) => item.type === "normal")
      );
      const groupedDataKeys = Object.keys(groupedDataSource);
      const groupedDataValues = Object.values(groupedDataSource);
      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedDataKeys.forEach((item, index) => {
        getNumData(
          searchParams,
          groupedDataValues[index].map((i: any) => i.metricId)
        );
      });
      const groupedYoyDataSource = groupArrByFrameWorkType(
        hasMetricIdDataSource.filter((item) => item.type === "yoy")
      );
      const groupedYoyDataKeys = Object.keys(groupedYoyDataSource);
      const groupedYoyDataValues = Object.values(groupedYoyDataSource);
      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedYoyDataKeys.forEach((item, index) => {
        getYoyData(
          searchParams,
          groupedYoyDataValues[index].map((i: any) => i.metricId)
        );
      });

      const groupedPropDataSource = groupArrByFrameWorkType(
        hasMetricIdDataSource.filter((item) => item.type === "prop")
      );
      const groupedPropDataKeys = Object.keys(groupedPropDataSource);
      const groupedPropDataValues = Object.values(groupedPropDataSource);
      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedPropDataKeys.forEach((item, index) => {
        getPropData(
          searchParams,
          groupedPropDataValues[index].map((i: any) => i.metricId)
        );
      });
    }
  }, [hasMetricIdDataSource, channelAllList]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  const getPropData = async (searchParams: any, indexIdsArr: any) => {
    const copyDataSource = [...hasMetricIdDataSource];
    const params = {
      ...searchParams,
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      indexIds: indexIdsArr,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
        dateType: searchForm?.dateType,
      indexType: "prop", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        const dataSourceCopy = [];
        copyDataSource
          .filter((item) => item.type === "prop")
          .forEach((item) => {
            item.value = res?.data?.[item.metricId];
          });
        const dataResult = finalPropDataSource.concat(copyDataSource);
        const result = dataResult.filter((item) => item.type === "prop");
        const re_recencyRadio = dataResult.find(
          (item) => item.titleZh === "留存回购率"
        )?.value;
        const win_recencyRadio = dataResult.find(
          (item) => item.titleZh === "召回回购率"
        )?.value;
        const sleep_recencyRadio = dataResult.find(
          (item) => item.titleZh === "沉默回购率"
        )?.value;

        const re_prop = result.find(
          (item) => item.titleZh === "留存回购人数占比"
        )?.value;
        const win_prop = result.find(
          (item) => item.titleZh === "召回回购人数占比"
        )?.value;
        const sleep_prop = result.find(
          (item) => item.titleZh === "沉默回购人数占比"
        )?.value;

        dataSource.forEach((item, index) => {
          if (index === 0) {
            dataSourceCopy.push({
              ...item,
              ttlPurchaser: re_prop,
              recencyRadio: re_recencyRadio,
            });
          } else if (index === 1) {
            dataSourceCopy.push({
              ...item,
              ttlPurchaser: win_prop,
              recencyRadio: win_recencyRadio,
            });
          } else if (index === 2) {
            dataSourceCopy.push({
              ...item,
              ttlPurchaser:sleep_prop,
              recencyRadio: sleep_recencyRadio,
            });
          }
        });
        setFinalPropDataSource(dataSourceCopy);
      }
    }
  };

  const getYoyData = async (searchParams: any, indexIdsArr: any) => {
    const copyDataSource = [...hasMetricIdDataSource];
    const params = {
      ...searchParams,
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      indexIds: indexIdsArr,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
        dateType: searchForm?.dateType,
      indexType: "yoy", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        const dataSourceCopy = [];
        copyDataSource
          .filter((item) => item.type === "yoy")
          .forEach((item) => {
            item.value = res?.data?.[item.metricId];
          });
        const dataResult = finalYoyDataSource.concat(copyDataSource);
        const result = dataResult.filter((item) => item.type === "yoy");
        // console.log(result, "yoy结果");
        const re_yoyAll = result.find(
          (item) => item.titleZh === "留存回购率"
        )?.value;
        const re_yoyPurchaser = result.find(
          (item) => item.titleZh === "留存回购人数"
        )?.value;
        const re_yoyBase = result.find(
          (item) => item.titleZh === "留存客户基数"
        )?.value;
        const win_yoyAll = result.find(
          (item) => item.titleZh === "召回回购率"
        )?.value;
        const win_yoyPurchaser = result.find(
          (item) => item.titleZh === "召回回购人数"
        )?.value;
        const win_yoyBase = result.find(
          (item) => item.titleZh === "召回客户基数"
        )?.value;
        const sleep_yoyAll = result.find(
          (item) => item.titleZh === "沉默回购率"
        )?.value;
        const sleep_yoyPurchaser = result.find(
          (item) => item.titleZh === "沉默回购人数"
        )?.value;
        const sleep_yoyBase = result.find(
          (item) => item.titleZh === "沉默客户基数"
        )?.value;

        dataSource.forEach((item, index) => {
          if (index === 0) {
            dataSourceCopy.push({
              ...item,
              yoyAll: re_yoyAll,
              yoyBase: re_yoyBase,
              yoyPurchaser: re_yoyPurchaser,
            });
          } else if (index === 1) {
            dataSourceCopy.push({
              ...item,
              yoyAll: win_yoyAll,
              yoyBase: win_yoyBase,
              yoyPurchaser: win_yoyPurchaser,
            });
          } else if (index === 2) {
            dataSourceCopy.push({
              ...item,
              yoyAll: sleep_yoyAll,
              yoyBase: sleep_yoyBase,
              yoyPurchaser: sleep_yoyPurchaser,
            });
          }
        });
        setFinalYoyDataSource(dataSourceCopy);
      }
    }
  };
  // indexIdsArr循环的当次的frameWorkType
  const getNumData = async (searchParams: any, indexIdsArr: any) => {
    const copyDataSource = [...hasMetricIdDataSource];
    const params = {
      ...searchParams,
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      indexIds: indexIdsArr,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
        dateType: searchForm?.dateType,
      indexType: "num", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        const dataSourceCopy = [];
        copyDataSource
          .filter((item) => item.type === "normal")
          .forEach((item) => {
            item.value = res?.data?.[item.metricId];
          });
        const dataResult = finalDataSource.concat(copyDataSource);
        const re_valuePurchaser = dataResult.find(
          (item) => item.titleZh === "留存回购人数"
        )?.value;
        const re_valueBase = dataResult.find(
          (item) => item.titleZh === "留存客户基数"
        )?.value;
        const win_valuePurchaser = dataResult.find(
          (item) => item.titleZh === "召回回购人数"
        )?.value;
        const win_valueBase = dataResult.find(
          (item) => item.titleZh === "召回客户基数"
        )?.value;

        const sleep_valuePurchaser = dataResult.find(
          (item) => item.titleZh === "沉默回购人数"
        )?.value;
        const sleep_valueBase = dataResult.find(
          (item) => item.titleZh === "沉默客户基数"
        )?.value;

        dataSource.forEach((item, index) => {
          if (index === 0) {
            dataSourceCopy.push({
              ...item,
              valuePurchaser: re_valuePurchaser,
              valueBase: re_valueBase,
            });
          } else if (index === 1) {
            dataSourceCopy.push({
              ...item,
              valuePurchaser: win_valuePurchaser,
              valueBase: win_valueBase,
            });
          } else if (index === 2) {
            dataSourceCopy.push({
              ...item,
              valuePurchaser: sleep_valuePurchaser,
              valueBase: sleep_valueBase,
            });
          }
        });
        setFinalDataSource(dataSourceCopy);
      }
    }
  };
  // 同比数据显示
  const radioDataShow = (value: number, showIcon: boolean) => {
    if (value === '-') {
      return '-';
    }
    if (Number(value) === 0) {
      return '0%';
    }
    if (Number(value) > 0) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "6px" }}>{value}%</span>
          {showIcon && <span className={styles.arrowUp}></span>}
        </div>
      );
    } else if (Number(value) < 0) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "6px" }}>{value}%</span>
          {showIcon && <span className={styles.arrowDown}></span>}
        </div>
      );
    }
  };
  return (
    <div className={styles.normalBox}>
      <div className={styles.normalHeader}>
        <div>
          回购比例
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                不同客群回购情况 <br />
                Retention
                Existing：统计期前12个月有购买的老客且在统计期的回购人群 <br />
                Winback
                Existing：统计期前12个月没购买且前13个月-24个月有购买的客户在统计期购买的人群
                <br /> Sleeping
                Existing：统计期前24个月没购买且24个月之前有购买的客户在统计期购买的人群
                <br /> 回购率：客群回购人数/客群基数 <br />
                Purchaser：统计期内客群回购人数 <br /> Retention
                Base：统计期前12个月内的购买客户数 <br /> Winback
                Base：统计期前12个月没购买且前13个月-24个月有购买的客户数 <br />
                Sleeping Base：统计期前24个月没购买且24个月之前有购买的客户数
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.normalContent}>
        {finalDataSource.map((item, index) => {
          return (
            <div key={index} className={styles.groupBox}>
              <div className={`${styles.itemTotal} ${item.style}`}>
                <img src={item.icon} className={styles.icon} />
                <div className={styles.title}>{item.name} Existing</div>
                <div style={{ margin: "16px 0 16px 0" }}>
                  <div className={styles.grayTitle}>回购率</div>
                  <div className={styles.bigValue}>{finalPropDataSource[index].recencyRadio}%</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.grayTitle}>同比</div>
                  {radioDataShow(finalYoyDataSource[index].yoyAll, true)}
                </div>
              </div>
              <div className={styles.itemChildWrap}>
                <div
                  className={`${item.style} ${styles.itemChild}`}
                  style={{ flex: 1.27 }}
                >
                  <div style={{ margin: "16px 0 16px 0" }}>
                    <div>Purchaser</div>
                    <div>
                      <span className={styles.bigValue}>
                        {getNumberWithComma(item.valuePurchaser, 0)}
                      </span>
                      <span className={styles.grayTitle}>人</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.grayTitle}>占比TTL</div>
                    {/* {radioDataShow(item.ttlPurchaser)} */}
                  {radioDataShow(finalPropDataSource[index].ttlPurchaser, false)}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.grayTitle}>同比</div>
                    {radioDataShow(finalYoyDataSource[index].yoyPurchaser, true)}
                  </div>
                </div>
                <div
                  className={`${item.style} ${styles.itemChild}`}
                  style={{ flex: 1 }}
                >
                  <div style={{ margin: "16px 0 16px 0" }}>
                    <div>{item.name} Base</div>
                    <div>
                      <span className={styles.bigValue}>
                        {getNumberWithComma(item.valueBase, 0)}
                      </span>
                      <span className={styles.grayTitle}>人</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.grayTitle}>同比</div>
                    {radioDataShow(finalYoyDataSource[index].yoyBase, true)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MemberFourQuadrants;
