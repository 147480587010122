/**
 * 商品销售追踪-商品-top10销售分布
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip, Select } from "antd";
import * as echarts from "echarts";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import { getTopTenList } from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
function Top10Chart(props: any) {
  const { searchForm, type } = props;
  const colors = [
    "#68ABFC",
    "#5AD8A6",
    "#C2E45D",
    "#FFD84E",
    "#FFAE5E",
    "#F5906E",
    "#ED6D6D",
    "#F590A9",
    "#B0A2DB",
    "#948DED",
    "#446FD4",
  ];
  const top10Options = [
    { label: "销售额", value: "sales" },
    { label: "购买件数", value: "skuNum" },
    { label: "购买人数", value: "buyNum" },
    { label: "购买订单数", value: "ordNum" },
  ];
  const [checkedtop10Type, setCheckedtop10Type] = useState("sales");

  const { channelAllList } = useGetChannelList("customer");
  const [chartData, setChartData] = useState<any>([]);
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const [chartName, setChartName] = useState<any>(null);
  const [chartTTL, setChartTTL] = useState<any>(null);
  const [chartYoy, setChartYoy] = useState<any>(null);

  useEffect(() => {
    if (channelAllList.length > 0) {
      getSalesTracking();
    }
  }, [searchForm, channelAllList, checkedtop10Type, type]);
  const getSalesTracking = async () => {
    const params = {
      // 选择少于7天/周/月/年，但参数要传近7天/周/月/年，参数用paramStartDate和paramEndDate
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
       channels: searchForm?.platformType?.includes("all")
          ? ["all"]
          : searchForm?.platformType?.split(","),
        shopIds: searchForm?.shopId?.includes("all")
          ? ["all"]
          : searchForm?.shopId?.split(","),
      goodType: type,
      indexId: checkedtop10Type,
    };
    const res = await getTopTenList(params);
    if (res?.code === 200) {
      const nameArr = [];
      const finalArr = [];
      let allTTl = 0;
      res.data.forEach((item) => {
        allTTl += Number(item.num);
      });
      res.data.forEach((item: any, index: number) => {
        nameArr.push({ name: item.name, icon: "circle" });
        finalArr.push({
          value: item.num,
          ttl: Number(allTTl) === 0 ? 0 : Number(item.num) / allTTl,
          yoy: item.yoy,
          name: item.name,
          itemStyle: { color: colors[index] },
        });
      });
      setChartName(nameArr);
      setChartData(finalArr);
      // setChartTTL
    }
  };
  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
        itemWidth: 330,
        formatter: (params: any) => {
          return `
           <div style="width: 330px;">
           <div style="height: 40px;">${
             params.name.length > 40
               ? `<div> ${params.name.slice(0, 40)}<br/>${params.name.slice(
                   40,
                   params.name.length
                 )}</div>`
               : params.name
           }</div>
<div style="display: flex; align-items: center; justify-content: space-between;">
             <div style="width:10px; height:10px; border-radius:50%; background-color: ${
               colors[params.dataIndex]
             };margin-right: 8px "></div>
           ${
             params?.data.ttl > 0
               ? `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;width: 330px;">
              <span>${
                top10Options.filter(
                  (item) => item.value === checkedtop10Type
                )[0].label
              }</span>
              <div>
         <span>${getNumberWithComma(
           Number(params?.data.value),
           checkedtop10Type === "sales" ? 2 : 0
         )}</span>
          </div></div>`
               : `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;width: 270px;">
              <span>${
                top10Options.filter(
                  (item) => item.value === checkedtop10Type
                )[0].label
              }</span>
              <div>
         <span>${getNumberWithComma(
           Number(params?.data.value),
           checkedtop10Type === "sales" ? 2 : 0
         )}</span>
          </div></div>`
           }
</div>
           ${
             params?.data.yoy > 0
               ? `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>${
                top10Options.filter(
                  (item) => item.value === checkedtop10Type
                )[0].label
              }占比TTL</span>
              <div>
         <span>${handleValuePercent(params?.data.ttl)}%</span>
          </div></div>`
               : `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>${
                top10Options.filter(
                  (item) => item.value === checkedtop10Type
                )[0].label
              }占比TTL</span>
              <div>
         <span>${handleValuePercent(params?.data.ttl)}%</span>
          </div></div>`
           }
                      ${
                        params?.data.yoy > 0
                          ? `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>${
                top10Options.filter(
                  (item) => item.value === checkedtop10Type
                )[0].label
              }同比</span>
              <div>
             <div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:8px solid #F5222D;position:absolute;  top:122px; left:250px;"></div>
         <span>${
           params?.data.yoy === "-"
             ? "-"
             : `${Number(params?.data.yoy).toLocaleString()}%`
         }</span>
          </div></div>`
                          : `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>${
                top10Options.filter(
                  (item) => item.value === checkedtop10Type
                )[0].label
              }同比</span>
              <div>
           ${
             params?.data.yoy !== "-" ?
             `<div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-top:8px solid #52C41A;position:absolute;  top:122px; left:250px;"></div>`
           : ''}
         <span>${
           params?.data.yoy === "-"
             ? "-"
             : `${Number(params?.data.yoy).toLocaleString()}%`
         }</span>
          </div></div>`
                      }
             </div>`;
        },
      },
      grid: {
        left: 0,
        top: 0,
        right: 10,
        containLabel: true,
      },
      legend: {
        left: 12,
        right: 24,
        itemHeight: 10,
        itemWidth: 10,
        type: "scroll", // 启用滚动模式
        pageFormatter: "{current}/{total}", // 当前页/总页数
        pageIconSize: 14, // 分页按钮的大小
        pageIconColor: "rgba(0, 0, 0, 0.88)", // 分页按钮的颜色
        pageIcon: {
          previous: "M 0,0 L 0,10 L 10,5 Z", // 向左箭头（自定义路径）
          next: "M 0,0 L 10,5 L 0,10 Z", // 向右箭头（自定义路径）
        },
        data: chartName,
        formatter: function (name: any) {
          return name;
        },
        textStyle: {
          rich: {
            square: {
              width: 10, // 正方形宽度
              height: 10, // 正方形高度
              backgroundColor: "#5B8FF9", // 正方形颜色
            },
            line1: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "rgba(91, 143, 249, 0.85)", // 横线颜色
            },
            line2: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "#85E6BC", // 横线颜色
            },
            line3: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "#A1A5AB", // 横线颜色
            },
          },
        },
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["30%", "60%"],
          avoidLabelOverlap: false,

          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: chartData,
        },
      ],
    };
    let chartDom = document.getElementById("Top10Chart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [chartData]);
  return (
    <div className={styles.normalBox} style={{ height: 600, width: "100%" }}>
      <div className={styles.normalHeader}>
        <div>
          Top10销售分布
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                展示不同指标下top10商品分布
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </div>

      <Select
        options={top10Options}
        style={{ width: 360, margin: "24px 0 24px 12px" }}
        value={checkedtop10Type}
        onChange={(e) => setCheckedtop10Type(e)}
      ></Select>
      <div
        id="Top10Chart"
        style={{
          width: "100%",
          height: "460px",
          paddingRight: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
}

export default Top10Chart;
