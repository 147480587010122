import { useEffect, useState, useRef } from 'react';
import * as echarts from 'echarts';

interface IProps {
  nodes: any[];
  onLegendChange: (checkedList: string[]) => void;
}

const leftArrowSvgPath = 'path://M724.032 218.24V141.056a8 8 0 0 0-12.928-6.272L260.288 486.784a31.872 31.872 0 0 0 0 50.304l450.816 352.128a7.936 7.936 0 0 0 12.864-6.336v-77.248a16.192 16.192 0 0 0-6.08-12.608L357.888 512l360-281.088a16.192 16.192 0 0 0 6.08-12.608z';
const rightArrowSvgPath = 'path://M765.696 486.784L314.88 134.72a7.936 7.936 0 0 0-12.864 6.272v77.312c0 4.864 2.304 9.6 6.08 12.608L668.16 512l-360 281.088a15.872 15.872 0 0 0-6.08 12.608v77.312c0 6.72 7.68 10.368 12.864 6.272l450.816-352.064a32 32 0 0 0 0-50.432z';


const getInitLegend = (nodes: any[]) => {
  const topGoods = nodes.filter(node => !node.isLeaf).sort((a, b) => Number(b.saleAmount) - Number(a.saleAmount));
  const notTopGoods = nodes.filter(node => node.isLeaf).sort((a, b) => Number(b.saleAmount) - Number(a.saleAmount)).map(node => {
    if (node.isNotMatched) {
      return {
        ...node,
        name: `${node.parentName} - 未匹配的spu`,
      }
    }
    return node;
  })
  return [...topGoods, ...notTopGoods].map(node => ({ color: node.style.fill, id: node.id, label: node.name, checked: true, value: 0 }));
};

const Legend: React.FC<IProps> = props => {
  const { nodes } = props;

  const [legend, setLegend] = useState(getInitLegend(nodes));

  const legendRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const myChart = echarts.init(legendRef.current as HTMLDivElement);
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      color: legend.map(node => node.color),
      legend: {
        data: legend.map(item => item.label),
        type: 'scroll',
        left: 0,
        top: 0,
        pageIconSize: 12,
        pageIcons: {
          horizontal: [leftArrowSvgPath, rightArrowSvgPath],
        },
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: '55%',
          center: ['-50%', '-60%'],
          data: legend.map(node => ({ name: node.label })),
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };
    myChart.setOption(option);
    // 图例变化事件
    myChart.on('legendselectchanged', (params: any) => {
      const checkedList = legend
        .map(item => {
          item.checked = params.name === item.label ? params.selected[item.label] : item.checked;
          return item.checked ? item.id : '';
        })
        .filter(item => item);
      
      setLegend([...legend]);
      props.onLegendChange(checkedList);
    });
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }, []);
  return (
    <div
      ref={legendRef}
      style={{
        width: '100%',
        height: '20px',
      }}
    ></div>
  );
};

export default Legend;
