/**
 * 人群细分-客质拆分-笔单价分布
 */
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import { QuestionCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import * as echarts from "echarts";
import SplitDrawer from "../SplitDrawer";
import { getCrowdSegmentation, getNewGroup } from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function MemberFourQuadrants(props: any) {
  const { searchForm, setParamForDownLoad } = props;
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const colors = [
    "#FFF8D3",
    "#FFF1B8",
    "#FFE58F",
    "#FFD666",
    "#FFC53D",
    "#FAAD14",
    "#D48806",
    "#AD6800",
    "#874D00",
    "#613400",
  ];
  const [data, setData] = useState<any>([]);
  const [splitDialogVisible, setSplitDialogVisible] = useState(false);
  const [dataForShowThisTime, setDataForShowThisTime] = useState<any>([]);
  const option = {
    tooltip: {
      trigger: "item",
      itemWidth: 280,
      formatter: (params: any) => {
        return `
           <div style="width: 280px;">
<div style="display: flex; align-items: center; justify-content: space-between;">
             <div style="width:10px; height:10px; border-radius:50%; background-color: ${
               colors[params.dataIndex]
             };margin-right: 8px "></div>
           <div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;width: 270px;">
              <span>笔单价：</span>
              <div>
         <span>${params.name}</span>
          </div></div>
</div>
          <div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>订单笔数：</span>
              <div>
         <span>${getNumberWithComma(params?.data.num, 0)}笔</span>
          </div></div>
          <div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>订单笔数占比：</span>
              <div>
         <span>${data[params?.dataIndex]?.percent}</span>
          </div></div>
                      ${
                        params?.data.yoy > 0
                          ? `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>订单笔数占比同比:</span>
              <div>
             <div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:8px solid #F5222D;position:absolute; top:112px; left:200px;"></div>
         <span>${
           params?.data.yoy === "-"
             ? "-"
             : `${Number(params?.data.yoy).toLocaleString()}%`
         }</span>
          </div></div>`
                          : `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>订单笔数占比同比</span>
              <div>
             <div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-top:8px solid #52C41A;position:absolute; top:112px; left:200px;"></div>
         <span>${
           params?.data.yoy === "-"
             ? "-"
             : `${Number(params?.data.yoy).toLocaleString()}%`
         }</span>
          </div></div>`
                      }
             </div>`;
      },
    },

    legend: {
      //  padding: [0, 0, 100, 20],
      left: "left",
      icon: "circle",
      itemWidth: 8,
      itmHeight: 8,
      // height: 300,
    },
    grid: {
      left: 24,
      right: 24,
      bottom: 0,
      containLabel: true,
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: "50%",
        data,
        minAngle: 10,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          formatter: (params: any, index: number) => {
            return `{circle|} {label|${params.name}}\n{value|占比}{valueBlack|${
              data[params?.dataIndex]?.percent
            }}\n{value|同比}${
              Number(params.data.yoy) > 0 ? `{triangleUp|}` : `{triangleDown|}`
            }{valueBlack|${`${params.data.yoy}%`}}`;
          },
          height: 94,
          rich: {
            circle: {
              height: 8, // 圆圈的大小，可根据需要调整
              width: 8,
              padding: [0, 0], // 确保圆圈紧凑，无额外边距
              borderRadius: 50, // 确保形状为圆形
              backgroundColor: "inherit",
            },
            triangleUp: {
              backgroundColor: {
                image:
                  'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"%3E%3Cpolygon points="5,0 10,8 0,8" fill="%23F5222D"/%3E%3C/svg%3E',
              },
              width: 9,
              height: 6,
              align: "center",
              verticalAlign: "middle",
            },

            triangleDown: {
              backgroundColor: {
                image:
                  'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"%3E%3Cpolygon points="0,0 10,0 5,8" fill="%2352C41A"/%3E%3C/svg%3E',
              },
              width: 9,
              height: 6,
              align: "center",
              verticalAlign: "middle",
            },
            label: {
              color: "#595959",
              textAlign: "left",
              fontSize: 12,
              fontWeight: 400,
            },
            value: {
              color: "#8C8C8C",
              textAlign: "left",
              fontSize: 12,
              fontWeight: 400,
              padding: [0, 20, 0, 0],
              height: 18,
            },
            valueBlack: {
              color: "#000000",
              textAlign: "left",
              fontSize: 12,
              fontWeight: "bold",
              padding: [0, 0, 0, 4],
            },
          },
          padding: [0, -80],
        },
        labelLine: {
          show: true,
          length: 14, // 改变标示线的长度
          length2: 100,
          lineStyle: {
            color: "#DFDFDF", // 改变标示线的颜色
          },
        },
      },
    ],
  };
  useEffect(() => {
    let chartDom = document.getElementById("priceChart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [JSON.stringify(option)]);

  const openSplitDialog = () => {
    setSplitDialogVisible(true);
  };

  const onCloseSplitDialog = () => {
    setSplitDialogVisible(false);
  };

  const onConfirmDialog = (type: string, value: any) => {
    onCloseSplitDialog();
    message.success("修改成功");
    setParamForDownLoad(type, value);
    searchData(type, value);
  };

  const searchData = async (type: string, value: any) => {
      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dataType: searchForm?.dataType,
      channels: searchForm?.platformType?.includes("all")
        ? channelAllList
            .filter((item) => item.value !== "all")
            .map((item) => item.value)
        : searchForm?.platformType?.split(","),
      shopIds:finalShopId,
      indexId: "bdj",
      configType: type,
      pieIndexTypes: ["num", "yoy", "rate"],
      autoConfigDTO: null,
      customConfigList: null,
    };
    if (type === "auto") {
      params.autoConfigDTO = value;
    }
    if (type === "custom") {
      params.customConfigList = value;
    }
    let res = await getCrowdSegmentation(params);
    if (res?.code === 200) {
      if (res.data) {
        const arr: {
          value: string | number;
          name: string;
          percent: any;
          yoy: any;
          num: any;
          itemStyle: { color: string };
        }[] = [];
        res?.data?.data?.forEach((item: any, index: number) => {
          arr.push({
            value: item.num,
            name: type === "auto" ? `${item.name}元` : `${item.name}`,
            percent:
              item.rate === "-"
                ? "-"
                : Number(item.rate).toLocaleString() + "%",
            yoy: item.yoy,
            num: item.num,
            itemStyle: { color: colors[index] },
          });
        });
        setData(arr);
      }
    }
  };

  useEffect(() => {
    if (channelAllList.length > 0) {
      queryNewGrouping();
    }
  }, [searchForm, channelAllList]);

  const queryNewGrouping = async () => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? ["all"]
        : searchForm?.platformType?.split(","),
      shopIds:
        searchForm?.shopId?.includes("all")
        ? ["all"]
        : searchForm?.shopId?.split(","),
      indexId: "bdj",
    };
    // @ts-ignore
    let res = await getNewGroup(params);
    if (res?.code === 200) {
      let dto;
      if (res.data?.type === "auto") {
        dto = res.data?.autoVo;
        dto = {
          min: res.data.autoVo.minNum,
          max: res.data.autoVo.maxNum,
          numberOfGroups: res.data.autoVo.groupNum,
        };
      } else if (res.data?.type === "custom") {
        dto = res.data?.customList;
      } else {
        dto = { numberOfGroups: 5, min: null, max: null };
      }
      searchData(res.data?.type ? res.data?.type : "auto", dto);
    }
  };

  return (
    <div className={styles.normalBox} style={{ height: 570 }}>
      <div className={styles.normalHeader}>
        <div>
          笔单价分布
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                统计期内，不同区间笔单价的购买订单数分布
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
        <div>
          <SettingOutlined
            onClick={openSplitDialog}
            style={{
              color: "var(--text-font-color4)",
              fontSize: "var(--text-font5)",
              marginLeft: "6px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className={styles.normalContent}>
        <div
          id="priceChart"
          style={{
            width: "100%",
            height: "518px",
            backgroundColor: "#fff",
            paddingTop: "24px",
          }}
        />
      </div>
      {splitDialogVisible && (
        <SplitDrawer
          searchForm={searchForm}
          typeName={"笔单价"}
          splitDialogVisible={splitDialogVisible}
          onCloseSplitDialog={onCloseSplitDialog}
          onConfirmDialog={(type: string, value: any) => {
            onConfirmDialog(type, value);
          }}
          dataForShowThisTime={dataForShowThisTime}
          setDataForShowThisTime={(v: any) => {
            setDataForShowThisTime(v);
          }
          }
        />
      )}
    </div>
  );
}

export default MemberFourQuadrants;
