import { Input, InputNumber, Drawer, Space, Button, Form, Select, message, Upload, Table, Popconfirm, Checkbox, Dropdown, Tooltip } from 'antd';
import React, { useEffect, ReactNode, useImperativeHandle, useState, forwardRef } from 'react';
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import type { TableProps, SelectProps } from 'antd';
import ImageUpload from '../../../../SystemSettingManagement/SystemSetting/AppearanceConfiguration/components/ImageUpload';
import styles from '../CreadGoods/index.module.scss';
import { getChannelProfile } from '@/api/userList/userList';
import { getShopListApi } from '@/api/shop/shop';
import { GOODSMANAGE_KEY } from '@/router/Permissions';
import { getSkuNameBySkuId } from '@/api/goodsManage/goodsManage';
import checkPermission from '../../../../../crm/assets/public/checkPermission';
const defaultItem = [
  {
    platformType: '',
    shopId: '',
    shopName: '',
    platformSkuId: '',
    deleted: 0,
  },
];
const groupItem = [
  {
    combinedId: '',
    platformType: '',
    shopId: '',
    shopName: '',
    skuId: '',
    deleted: 0,
    count: null,
    skuName: '',
  },
];
let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (shopId: any, platformType: any, value: any, callback: (data: { value: any; label: any }[]) => void) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const res = await getSkuNameBySkuId({ shopId: shopId, platform: platformType, skuId: value });
    if (res?.code === 200) {
      const data = res.data?.map((item: any) => ({
        value: item.skuId,
        label: item.name,
      }));
      // 把skuId一样的去重
      const uniqueData = data.filter((item: any, index: any, self: any) => index === self.findIndex((t: any) => t.value === item.value));
      // console.log(data);
      callback(uniqueData);
    }
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
let SKUDetail = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
      relevanceItem: any;
      isCombined: any;
      onRelevanceItemChange?: Function;
      arrNumber: any;
      drawerStatus: string;
    },
    ref: any,
  ) => {
    const [editStatus, setEditStatus] = useState(false); //编辑
    const [addStatus, setAddStatus] = useState(false); //新建
    const [editItem, setEditItem] = useState<any>();
    const [skuTableList, setSkuTableList] = useState<any>([]);
    const [backgroundPic, setBackgroundPic] = useState<string>(''); //sku图片
    const [channelProfileList, setChannelProfileList] = useState<any>([]);
    const [shopList, setShopList] = useState<any>([{}]);
    const [regroupList, setRegroupList] = useState<any>([]);
    const [shopName, setShopName] = useState<any>('');
    const [isReleased, setIsReleased] = useState<any>('');
    const [data, setData] = useState<SelectProps['options']>([]);
    const [value, setValue] = useState<any>();
    const [drawerStatus, setDrawerStatus] = useState<any>(props.drawerStatus);
    const [goodsMappingList, setGoodsMappingList] = useState<any>([]);

    useEffect(() => {
      if (props.open) {
        setDrawerStatus(props.drawerStatus);
        getShopList();
        getChannelProfileList();
        let item = props.relevanceItem;
        setBackgroundPic(item.skuPic);
        if (props.isCombined === 0) {
          setSkuTableList(item.goodsMappingVOList || []);
        }
        if (props.isCombined === 1) {
          setSkuTableList(item.sysCombinedMappingVOList || []);
          if (props.drawerStatus === 'edit') {
            setGoodsMappingList(item.goodsMappingVOList || []);
          }
        }
        setIsReleased(item.isReleased);
        skuForm.setFieldsValue({
          name: item.name,
          skuPic: item.skuPic,
          color: item.color,
          size: item.size,
          totalWeight: item.totalWeight,
          netContent: item.netContent,
          isReleased: item.isReleased,
          barCode: item.barCode,
          salePrice: item.salePrice,
          costPrice: item.costPrice,
        });
      }
    }, [props.open]);

    // 保存
    const handleRelevanceItemUpdate = () => {
      // console.log(skuTableList, goodsMappingList);
      if (editStatus) {
        return message.error('请先完整编辑内容');
      }
      if (addStatus) {
        return message.error('请先完整编辑内容');
      }
      if (skuTableList.filter((item: any) => item.deleted !== 1).length <= 0) {
        return message.error('当前SKU还未进行电商平台关联，请添加关联sku建立关联映射关系。');
      }
      let { sysCombinedMappingVOList, goodsMappingVOList, ...newData } = props.relevanceItem;
      if (props.onRelevanceItemChange) {
        // 非组合商品
        if (props.isCombined === 0) {
          newData.goodsMappingVOList = skuTableList.map((item: any) => {
            const { addKey, ...newItem } = item;
            return newItem;
          });
        }
        // 组合商品
        if (props.isCombined === 1) {
          newData.sysCombinedMappingVOList = skuTableList.map((item: any) => {
            const { addKey, ...newItem } = item;
            if (newItem.skuName === newItem.skuId) {
              newItem.skuName = ''; // 如果skuName和skuId相同，则将skuName赋值为空字符串,以免对其他数据造成污染
            }
            return newItem;
          });

          newData.goodsMappingVOList = skuTableList.map((item: any, inedx: any) => {
            const matchingItem = goodsMappingList?.find((mappingItem: any) => mappingItem.platformSkuId === item.combinedId);
            const id = matchingItem && matchingItem?.platformSkuId === item.combinedId ? matchingItem?.id : null;
            // console.log(matchingItem, id);
            return {
              platformType: item.platformType,
              shopId: item.shopId,
              shopName: item.shopName,
              platformSkuId: item.combinedId,
              deleted: item.deleted,
              id: drawerStatus === 'edit' ? id : null,
            };
          });
        }

        props.onRelevanceItemChange(newData, props.arrNumber);
        handleDrawerSwitch();
      }
    };
    // 渠道列表
    const getChannelProfileList = async () => {
      const res = await getChannelProfile();
      if (res.code === 200) {
        let arr = res.data?.map((e: any) => ({ value: e.channelCode, label: e.channelName }));
        setChannelProfileList(arr);
      }
    };
    const handleSearch = (shopId: any, platformType: any, skuId: string) => {
      fetch(shopId, platformType, skuId, setData);
    };

    // 店铺列表
    const getShopList = async () => {
      const res = await getShopListApi();
      if (res.code === 200) {
        setShopList(res.data);
      }
    };
    // 注册form
    const [skuForm] = Form.useForm(); // 注册from表单
    // 关闭
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        skuForm.resetFields();
        props.drawer();
        setEditStatus(false);
        setAddStatus(false);
        setRegroupList([]);
        setShopName(null);
        setData([]);
        setValue(null);
      }
    };

    const fillLoginForm = (e: any, type?: any) => {
      skuForm.setFieldsValue(e.value);
    };

    // 抽屉顶部
    const drawerHeader = () => {
      return (
        <div>
          <span className={styles.topTitle}>SKU详情</span>
          <span className={styles.uploadText}>通过新建商品创建SPU和SKU</span>
        </div>
      );
    };
    const UploadImage = ({ index }: { index: number }) => (
      <div>
        {index === 1 && backgroundPic ? (
          <img
            src={backgroundPic}
            style={{
              width: 100,
              height: 100,
            }}
          />
        ) : (
          <button
            style={{
              border: 0,
              background: 'none',
            }}
            type="button">
            <PlusOutlined style={{ color: 'var(--text-font-color28)' }} />
            <div style={{ color: 'var(--text-font-color28)' }}>上传</div>
          </button>
        )}
      </div>
    );
    const handleInputChange = (value: string, record: any, key: string) => {
      record[key] = value;
    };
    interface DataType {
      crowdId: string;
    }
    // 找店铺列表
    const handleFindShopList = (type: any, dataIndex: any) => {
      setShopName(null);
      let foundList = channelProfileList.find((item: any) => item.value === type)?.label;
      let foundItem = shopList.find((item: any) => item.sysChannelName === foundList).shop;
      if (foundItem) {
        let foundReturn = foundItem.map((e: any) => ({ value: e.platformShopId, label: e.shopName }));
        setRegroupList(foundReturn);
      } else {
        setRegroupList([]);
      }
    };
    // 找店铺名
    // const handleFindShopName = (id: any, dataIndex: number) => {
    //   console.log(id, dataIndex);
    //   const foundShop = shopList.find((item: any) => item.sysChannelName === channelProfileList[dataIndex]?.label);
    //   console.log(foundShop);
    //   if (foundShop && foundShop.shop) {
    //     // 找到对应的 shopId 的店铺对象
    //     const foundShopId = foundShop.shop.find((idx: any) => idx.shopId === id);
    //     // 检查 foundShopId 是否存在以及是否有 shopName 属性
    //     if (foundShopId && foundShopId.shopName) {
    //       // 取得 shopName 值
    //       const shopName = foundShopId.shopName;
    //       return shopName;
    //     }
    //   }
    // };
    let columns: TableProps<DataType>['columns'] = [
      {
        title: '渠道名称',
        dataIndex: 'platformType',
        key: 'platformType',
        render: (_: any, record: any, dataIndex: any) =>
          addStatus && editItem === record ? (
            <Select
              style={{ width: 200 }}
              placeholder="请选择"
              value={record.platformType}
              onChange={e => {
                handleInputChange(e, record, 'platformType');
                handleFindShopList(e, dataIndex);
              }}
              options={channelProfileList}
            />
          ) : (
            <span>{channelProfileList.find((item: any) => item.value === record.platformType)?.label}</span>
          ),
      },
      {
        title: '店铺名称',
        dataIndex: 'shopId',
        key: 'shopId',
        render: (_: any, record: any, dataIndex) =>
          addStatus && editItem === record ? (
            <Select
              labelInValue
              value={shopName}
              disabled={regroupList.length <= 0}
              style={{ width: 200 }}
              placeholder="请选择"
              onChange={(e: any) => {
                handleInputChange(e.value, record, 'shopId');
                handleInputChange(e.label, record, 'shopName');
                setShopName(e.label);
              }}
              options={regroupList}
            />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },

      {
        title: '电商平台SKU_ID',
        dataIndex: 'platformSkuId',
        key: 'platformSkuId',
        render: (_: any, record: any) =>
          addStatus && editItem === record ? (
            <InputNumber
              style={{ width: 200 }}
              maxLength={100}
              min={0}
              controls={false}
              defaultValue={_}
              onChange={e => handleInputChange(e, record, 'platformSkuId')}
            />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        // 组合商品
        title: '电商平台SKU_ID',
        dataIndex: 'combinedId',
        key: 'combinedId',
        render: (_: any, record: any) =>
          addStatus && editItem === record ? (
            <InputNumber
              style={{ width: 200 }}
              maxLength={100}
              min={0}
              controls={false}
              defaultValue={_}
              onChange={e => handleInputChange(e + '', record, 'combinedId')}
            />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '组合SKU详情',
        dataIndex: 'skuName',
        key: 'skuName',
        render: (_: any, record: any) =>
          (addStatus || editStatus) && editItem === record ? (
            <Select
              labelInValue
              showSearch
              value={value || record.skuName}
              disabled={record.shopId === ''}
              style={{ width: 200 }}
              placeholder="请选择"
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              onSearch={e => {
                handleSearch(record.shopId, record.platformType, e);
              }}
              onChange={(e: any) => {
                handleInputChange(e.label, record, 'skuName');
                handleInputChange(e.value, record, 'skuId');
                setValue(e.label);
              }}
              notFoundContent={null}
              options={(data || []).map(d => ({
                value: d.value,
                // 有些没有skuName的,先选成skuId
                label: d.label === '' ? d.value : d.label,
              }))}
            />
          ) : (
            <span>
              {/*  有些没有skuName的,展示skuId */}
              {record.combinedId}
              {!record.skuName || record.skuName === '' ? (record.skuId === '' ? '' : ',' + record.skuId) : ',' + record.skuName}
            </span>
          ),
      },
      {
        title: 'SKU件数',
        dataIndex: 'count',
        key: 'count',
        render: (_: any, record: any) =>
          (addStatus || editStatus) && editItem === record ? (
            <InputNumber
              style={{ width: 200 }}
              maxLength={10}
              min={0}
              defaultValue={_}
              onChange={e => handleInputChange(e, record, 'count')}
            />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '是否关联',
        key: 'action',
        width: '88px',
        render: (_: any, record: any) => (
          <div>
            <Checkbox defaultChecked disabled></Checkbox>
          </div>
        ),
      },
    ];
    if (editStatus || addStatus) {
      columns = columns.filter(column => column.key !== 'action');
      columns = [
        ...columns,
        {
          title: '操作',
          key: 'save',
          align: 'center',
          fixed: 'right',
          width: '88px',
          render: (_: any, record: any, dataIndex: any) =>
            (editStatus || addStatus) && editItem === record ? (
              <Tooltip title="数据已缓存，请点击右上角保存信息">
                <Button
                  type="link"
                  onClick={() => {
                    if (!record.shopId) {
                      return message.error('请先完整编辑内容');
                    } else {
                      if (props.isCombined === 0 && !record.platformSkuId) {
                        return message.error('请先完整编辑内容');
                      }
                      if (props.isCombined === 1 && !record.combinedId) {
                        return message.error('请先完整编辑内容');
                      }
                    }
                    setEditStatus(false);
                    setAddStatus(false);
                    setRegroupList([]);
                    setShopName(null);
                    setData([]);
                    setValue(null);
                  }}>
                  确定
                </Button>
              </Tooltip>
            ) : (
              <Button type="link" disabled>
                确定
              </Button>
            ),
        },
      ];
    } else {
      columns = columns.filter(column => column.key !== 'save');

      columns = [
        ...columns,
        {
          title: '操作',
          key: 'action',
          align: 'center',
          fixed: 'right',
          width: '88px',
          render: (_: any, record: any, dataIndex: any) => (
            <div>
              <div>
                <Dropdown menu={{ items: getTableOperationButtonList(record, dataIndex) }} trigger={['click']}>
                  {/* <a onClick={e => e.preventDefault()}> */}
                  <EllipsisOutlined
                    style={{
                      color: true ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                      cursor: 'pointer',
                    }}
                  />
                </Dropdown>
              </div>
            </div>
          ),
        },
      ];
    }
    // 不组合商品显示的表头
    if (props.open) {
      if (props.isCombined === 0) {
        columns = columns.filter(column => column.key !== 'count');
        columns = columns.filter(column => column.key !== 'skuName');
        columns = columns.filter(column => column.key !== 'combinedId');
      }
      if (props.isCombined === 1) {
        columns = columns.filter(column => column.key !== 'platformSkuId');
      }
    }
    // 没有删除权限
    if (!checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) {
      columns = columns.filter(column => column.key !== 'action');
    }
    // 操作按钮的下拉栏显示数据
    const getTableOperationButtonList = (item: any, dataIndex: any) => {
      const store = [];
      if (props.isCombined === 1) {
        //创建方式为规则创建
        store.push({
          key: 'log',
          label: (
            <a style={{ color: 'var( --text-font-color9)' }} onClick={() => editSKU(item)}>
              编辑
            </a>
          ),
        });
      }
      if (checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) {
        store.push({
          key: 'del',
          label: (
            <a
              style={{ color: 'var( --text-font-color9)' }}
              onClick={() => {
                getDelete(item, dataIndex);
              }}>
              删除
            </a>
          ),
        });
      }
      return store;
    };
    // 子组件获取链接
    const getImageUrl = (url: string, index: number, name?: string) => {
      // 存进form
      skuForm.setFieldValue('planName', url);
      setBackgroundPic(url);
    };
    // 新增关联
    const handleAddRelevancy = () => {
      if (addStatus || editStatus) {
        return message.error('请先保存编辑项');
      }
      let updatedSkuTableList = [];
      // 非组合商品
      if (props.isCombined === 0) {
        updatedSkuTableList = defaultItem.map((item: any, index: any) => ({
          ...item,
          addKey: index + skuTableList ? skuTableList.length : 0,
        }));
      }
      // 组合商品
      if (props.isCombined === 1) {
        updatedSkuTableList = groupItem.map((item: any, index: any) => ({
          ...item,
          addKey: index + skuTableList ? skuTableList.length : 0,
        }));
      }
      if (skuTableList) {
        setSkuTableList([...skuTableList, ...updatedSkuTableList]);
      } else {
        setSkuTableList([...updatedSkuTableList]);
      }
      setEditStatus(true);
      setAddStatus(true);
      setEditItem(updatedSkuTableList[0]);
    };
    // 编辑sku
    const editSKU = (item: any) => {
      setEditItem(item);
      setEditStatus(true);
    };
    // 删除
    const getDelete = async (item: any, dataIndex: any) => {
      if (item.createTime) {
        skuTableList[dataIndex].deleted = 1;
        setSkuTableList([...skuTableList]);
        message.success('删除成功');
      } else {
        let deleteItem = skuTableList.filter((column: any) => column.addKey !== item.addKey);
        setSkuTableList(deleteItem);
        message.success('删除成功');
      }
    };
    return (
      <div>
        <Drawer
          width={960}
          title={drawerHeader()}
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={handleRelevanceItemUpdate}>
                确定
              </Button>
            </Space>
          }>
          <Form form={skuForm} disabled labelCol={{ span: 4 }} initialValues={{ remember: true }} style={{ maxWidth: 600 }}>
            <Form.Item label="SKU名称" name="name" rules={[{ required: false }]}>
              <Input autoComplete="off" placeholder="请输入SKU名称" showCount maxLength={100} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="SKU图片" name="skuPic">
              <div className={styles.uploadPic}>
                <div style={{ width: 100, height: 100 }}>
                  <ImageUpload getImageUrl={getImageUrl} width={100} height={100} isButton={false} imageIndex={1} isEqual={true}>
                    <UploadImage index={1} />
                  </ImageUpload>
                </div>
                <div className={styles.uploadText}>
                  <div>支持.jpg、.png格式</div>
                  <div>大小: 100 * 100 </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item label="条形码" name="barCode" rules={[{ required: true, message: '请输入条形码' }]}>
              <Input autoComplete="off" placeholder="请输入条形码" showCount maxLength={50} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="颜色" name="color" rules={[{ required: false }]}>
              <Input autoComplete="off" placeholder="请输入颜色" showCount maxLength={20} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="尺码" name="size" rules={[{ required: false }]}>
              <Input autoComplete="off" placeholder="请输入尺码" showCount maxLength={20} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="总重量(KG)" name="totalWeight" rules={[{ required: false }]}>
              <Input autoComplete="off" placeholder="请输入品牌" showCount maxLength={20} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="净含量(KG)" name="netContent" rules={[{ required: false }]}>
              <Input autoComplete="off" placeholder="请输入产品线" showCount maxLength={10} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="是否放行" name="isReleased" rules={[{ required: false }]}>
              <Select
                value={isReleased === 1 ? '是' : isReleased === 0 ? '否' : null}
                placeholder="请选择"
                options={[
                  { value: 1, label: '是' },
                  { value: 0, label: '否' },
                ]}
              />
            </Form.Item>
            <Form.Item label="销售价" name="salePrice" rules={[{ required: false }]}>
              <InputNumber
                prefix="￥"
                style={{ width: '100%' }}
                autoComplete="off"
                placeholder="请输入销售价"
                maxLength={10}
                onChange={fillLoginForm}
              />
            </Form.Item>
            <Form.Item label="成本价" name="costPrice" rules={[{ required: false }]}>
              <InputNumber
                prefix="￥"
                style={{ width: '100%' }}
                autoComplete="off"
                placeholder="请输入成本价"
                maxLength={10}
                onChange={fillLoginForm}
              />
            </Form.Item>
          </Form>
          <div className={styles.goodsDetailTitle}>
            <span className={styles.topTitle}>渠道SKU码映射关系</span>
            <Button type="default" onClick={handleAddRelevancy}>
              新增关联SKU
            </Button>
          </div>
          <div className={styles.tableBox}>
            <Table
              columns={columns as any}
              dataSource={skuTableList.filter((item: any) => item.deleted !== 1)}
              scroll={{ x: props.isCombined === 0 ? 800 : 1200 }}
              pagination={{
                // total: tableInfo.total,
                // pageSize: tableInfo.pageSize,
                // current: tableInfo.pageNum,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: total => `共 ${total} 条`,
              }}
              // onChange={handleTableChange}
            />
          </div>
        </Drawer>
      </div>
    );
  },
);
export default SKUDetail;
