/**
 * 行业看板公共方法
 */
import { useEffect, useState } from "react";
import { getMetricApi } from "../../api/industryBoardApi";
import {
  getCustomerChannelListApi,
  getCustomerShopListApi,
} from "../../api/dataBoardApi";

// 指标名和指标ID映射关系
export const useGetMetricList = () => {
  const [metricAllList, setMetricAllApi] = useState([]);
  const getMetric = async () => {
    let res = await getMetricApi();
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ metricId: e.metricId, metricName: e.metricName, frameWorkType: e.frameWorkType }));
      setMetricAllApi(arr);
    }
  };

  useEffect(() => {
    getMetric();
  }, []);

  return { metricAllList };
};

// 获取平台渠道
export const useGetChannelList = (type: string) => {
  const [channelList, setChannelList] = useState([]);
  const [channelAllList, setChannelAllList] = useState<any[]>([]);

  //获得渠道列表
  const getChannelList = async (memberCardId: string | null = null) => {
    let res: any = {};
    res = await getCustomerChannelListApi({ memberCardId });
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ value: e.id, label: e.name }));
      setChannelList(arr);
      setChannelAllList([{ value: "all", label: "全部平台" }, ...arr]);
    }
  };

  useEffect(() => {
    getChannelList();
  }, []);

  return { channelList, channelAllList, getChannelList };
};

// 通过多个渠道获取店铺
export const useGetShoplListBySeveralChannel = (type: string) => {
  const [shopList, setShopList] = useState([]);
  const [shopListBySeveralChannel, setShopListBySeveralChannel] = useState<any[]>([]);

  //获得店铺列表
  const getShopListBySeveralChannel = async (
    memberCardId: string | null = null,
    channelType: string | null = null
  ) => {
    let res: any = {};
    res = await getCustomerShopListApi({ memberCardId, channelType });
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ value: e.id, label: e.name }));
      setShopList(arr);
      setShopListBySeveralChannel([{ value: "all", label: "全部店铺" }, ...arr]);
    }
  };

  useEffect(() => {
    getShopListBySeveralChannel();
  }, []);

  return { shopListBySeveralChannel, getShopListBySeveralChannel };
};