import { Input, Drawer, Space, Button, Form, Select, Modal, message, Upload, Radio, Row, Col } from 'antd';
import React, { useEffect, ReactNode, useImperativeHandle, useState, forwardRef } from 'react';
import { getSmsPassageDetail, addSmsPassage, updateSmsPassage, } from '../../../../api/graph/graph';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import style from './index.module.scss';

let smsChannel = React.forwardRef(
    (
        props: {
            open?: boolean;
            drawer?: Function;
            channelStatus?: number;
            accountId?: number;
        },
        ref: any,
    ) => {
        const channelInfo: any = {
            accountId: '',
            apiKey: '',
            apiSecret: '',
            accountName: '',
        };

        const [creadForm] = Form.useForm();
        const [channelStatus, setChannelStatus] = useState(props.channelStatus ?? 0)
        const [signList, setSignList] = useState<any>([{ signName: '' }]);
        const [phoneNoList, setTelList] = useState<any>([{ phoneNo: '' }]);
        const [formInfo, setFormInfo] = useState<any>({ ...channelInfo });

        // 关闭抽屉
        const handleDrawerSwitch = () => {
            if (props.drawer) {
                creadForm.resetFields();
                props.drawer();
                setFormInfo({ ...channelInfo })
                setTelList([{}])
                setSignList([{}])
            }
        };

        // 新建通道
        const saveNewTemplate = async () => {
            const phoneNoList1 = phoneNoList
                .filter((item: any) => item.phoneNo)
            // .map((item: any) => item.phoneNo!);

            // const signList1 = signList
            //     .filter((item: any) => item.signName)
            //     .map((item: any) => item.signName!);

            let param = {
                signList,
                phoneNoList: phoneNoList1,
                ...formInfo
            }

            if (channelStatus < 1) {
                const res: any = await addSmsPassage(param);
                if (res?.code == 200) {
                    message.success('新建成功');
                    handleDrawerSwitch()
                }
            } else {
                param.accountId = props.accountId
                const res: any = await updateSmsPassage(param);
                if (res?.code == 200) {
                    message.success('编辑成功');
                    handleDrawerSwitch()
                }
            }
        }

        // 确定保存
        const onFinish = (values: any) => {
            let text = ''
            let rule = /^[1][3,4,5,7,8,9][0-9]{9}$/
            let contentRule = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
            // 校验通道信息
            if (!formInfo.accountName) {
                text = '通道名称'
            }
            Object.keys(formInfo).map(key => {
                if (!formInfo[key]) {
                    if (text) return
                    switch (key) {
                        case 'accountId':
                            text = 'AccountId'
                            break;
                        case 'apiKey':
                            text = 'Appkey'
                            break;
                        case 'apiSecret':
                            text = 'Appsecret'
                            break;

                        default:
                            break;
                    }
                }
                return text
            })
            // 校验短信签名
            if (!text && (signList.length < 1 || signList.some((item: any) => !item.signName))) {
                text = '短信签名'
            }
            // 校验手机号
            if (!text && (phoneNoList.some((item: any) => !rule.test(item.phoneNo) && item.phoneNo))) {
                text = '正确的手机号'
            }
            if (text) {
                message.warning(`请输入${text}`)
                return
            }
            if (contentRule.test(formInfo.accountName)) {
                message.warning('通道名称不支持特殊字符，请重新输入')
                return
            }
            if (signList.some((item: any) => contentRule.test(item.signName))) {
                message.warning('短信签名不支持特殊字符，请重新输入')
                return
            }
            if (signList.some((item: any) => item.signName.length > 9)) {
                message.warning('短信签名不可超过9个字符')
                return
            }

            if (hasDuplicateField(signList, 'signName')) {
                message.warning('同一通道的短信签名重复，请重新输入')
                return
            }
            if (hasDuplicateField(phoneNoList, 'phoneNo')) {
                message.warning('同一通道的测试手机号重复，请重新输入')
                return
            }

            saveNewTemplate()
        }

        // 校验数组中同一字段是否含有相同数据
        const hasDuplicateField = (array: any, fieldName: string): boolean => {
            const seen = new Set<string>();

            for (const item of array) {
                const value = item[fieldName];
                if (value !== undefined) {
                    if (seen.has(value)) {
                        return true; // 发现重复
                    }
                    seen.add(value);
                }
            }

            return false; // 没有发现重复
        };

        // 获取短信通道详情
        const getChannelDetail = async () => {
            const res: any = await getSmsPassageDetail({ accountId: props.accountId });
            if (res) {
                if (res?.code == 200) {
                    const { phoneNoList, signList } = res.data;
                    let info = { ...formInfo }
                    Object.keys(info).map(key => {
                        if (info.hasOwnProperty(key) && res.data.hasOwnProperty(key)) {
                            info[key] = res.data[key]
                            creadForm.setFieldValue(key, info[key])
                        }
                    })
                    if (signList.length < 1) signList.push({})
                    if (phoneNoList.length < 1) phoneNoList.push({})
                    setFormInfo(info)
                    setSignList(signList)
                    setTelList(phoneNoList)
                }
            }
        }

        useEffect(() => {
            setChannelStatus(props.channelStatus ?? 0)
            if (props.accountId) {
                getChannelDetail()
            }
        }, [props])

        return (
            <div>
                <Drawer
                    width={400}
                    title={channelStatus == 1 ? '查看短信通道' : channelStatus == 2 ? '编辑短信通道' : '新建短信通道'}
                    onClose={handleDrawerSwitch}
                    open={props.open}
                    extra={
                        <Space>
                            {channelStatus == 1 &&
                                <Button type="primary" onClick={() => { setChannelStatus(2) }}>
                                    编辑
                                </Button>}
                            {(channelStatus != 1) && <>
                                <Button onClick={handleDrawerSwitch}>取消</Button>
                                <Button type="primary" onClick={onFinish} htmlType="submit">
                                    确定
                                </Button>
                            </>
                            }
                        </Space>
                    }>
                    <Form form={creadForm} initialValues={{ remember: false }} onFinish={onFinish} style={{ maxWidth: 600 }} layout="vertical">
                        <div className={style.partTitle}>通道信息</div>
                        <Form.Item
                            label="通道名称"
                            name='accountName'
                            rules={[
                                { required: true, message: '' },
                            ]}
                        >
                            <Input className={style.formInput} disabled={channelStatus == 1} autoComplete="off" placeholder="请输入通道名称" showCount maxLength={20}
                                onChange={(e) => {
                                    setFormInfo({ ...formInfo, accountName: e.target.value })
                                }} />
                        </Form.Item>
                        <Form.Item
                            name='accountId'
                            label="AccountId"
                            rules={[
                                { required: true, message: '' },
                            ]}
                        >
                            <Input className={style.formInput} disabled={channelStatus != 0} autoComplete="off" placeholder="请输入AccountId" onChange={(e) => {
                                setFormInfo({ ...formInfo, accountId: e.target.value })
                            }} />
                        </Form.Item>
                        <Form.Item
                            name='apiKey'
                            label="Appkey"
                            rules={[
                                { required: true, message: '' },
                            ]}
                        >
                            <Input className={style.formInput} disabled={channelStatus != 0} autoComplete="off" placeholder="请输入Appkey" onChange={(e) => {
                                setFormInfo({ ...formInfo, apiKey: e.target.value })
                            }} />
                        </Form.Item>
                        <Form.Item
                            name='apiSecret'
                            label="Appsecret"
                            rules={[
                                { required: true, message: '' },
                            ]}
                        >
                            <Input className={style.formInput} disabled={channelStatus != 0} autoComplete="off" placeholder="请输入Appsecret" onChange={(e) => {
                                setFormInfo({ ...formInfo, apiSecret: e.target.value })
                            }} />
                        </Form.Item>
                        <div className={style.partTitle}>短信签名</div>
                        <Form.Item
                            label="短信签名"
                            name={signList}
                            rules={[
                                { required: true, message: '' },
                            ]}
                        >
                            {signList.map((item: any, index: number) => (
                                <div key={`signList[${index}]`}>
                                    <Input
                                        className={style.channelSign}
                                        value={item.signName}
                                        disabled={channelStatus === 1}
                                        style={{ width: signList.length < 2 ? '90%' : '85%' }}
                                        autoComplete="off"
                                        placeholder={"请输入短信签名"}
                                        showCount
                                        maxLength={9}
                                        onChange={(e) => {
                                            const newList = [...signList];
                                            newList[index].signName = e.target.value;
                                            setSignList(newList);
                                        }}
                                    />
                                    {channelStatus !== 1 && <>
                                        <PlusOutlined className={style.Icon} onClick={() => {
                                            if (channelStatus === 1) return
                                            if (signList.length > 19) {
                                                message.error('签名最多20个')
                                                return
                                            }
                                            setSignList([...signList, {}])
                                        }}></PlusOutlined>
                                        {signList.length > 1 && <DeleteOutlined className={style.Icon} onClick={() => {
                                            if (channelStatus === 1) return
                                            const newList = [...signList];
                                            newList.splice(index, 1);
                                            setSignList(newList)
                                        }}></DeleteOutlined>
                                        }
                                    </>}
                                </div>
                            ))}
                        </Form.Item>
                        <div className={style.partTitle}>测试手机号</div>
                        <Form.Item
                            label="测试手机号"
                            rules={[
                                { required: false, message: '' },
                            ]}
                        >
                            {phoneNoList.map((item: any, index: number) => (
                                <div key={`phoneNoList[${index}]`}>
                                    <Input
                                        className={style.channelSign}
                                        value={item.phoneNo}
                                        disabled={channelStatus === 1}
                                        style={{ width: phoneNoList.length < 2 ? '90%' : '85%' }}
                                        autoComplete="off"
                                        placeholder="请输入测试手机号"
                                        showCount
                                        maxLength={11}
                                        onChange={(e) => {
                                            if (channelStatus === 1) return
                                            const newList = [...phoneNoList];
                                            newList[index].phoneNo = e.target.value;
                                            setTelList(newList);
                                        }}
                                    />
                                    {channelStatus !== 1 && <>
                                        <PlusOutlined className={style.Icon} onClick={() => {
                                            if (channelStatus === 1) return
                                            if (phoneNoList.length > 19) {
                                                message.error('手机号最多20个')
                                                return
                                            }
                                            setTelList([...phoneNoList, {}])
                                        }}></PlusOutlined>
                                        {phoneNoList.length > 1 &&
                                            <DeleteOutlined className={style.Icon} disabled={channelStatus === 1} onClick={() => {
                                                if (channelStatus === 1) return
                                                const newList = [...phoneNoList];
                                                newList.splice(index, 1);
                                                setTelList(newList)
                                            }}></DeleteOutlined>
                                        }</>}
                                </div>
                            ))}
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        )

    }
)
export default smsChannel;
