import React from 'react';
import ReactDom from 'react-dom';
import { createRoot } from 'react-dom/client';
import { useState, useEffect, useRef } from 'react';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';
import { Snapline } from '@antv/x6-plugin-snapline'; //对齐线
import { Clipboard } from '@antv/x6-plugin-clipboard'; //复制粘贴
import { Selection } from '@antv/x6-plugin-selection'; //框选
import { History } from '@antv/x6-plugin-history'; //撤销重做
import { Keyboard } from '@antv/x6-plugin-keyboard'; //快捷键删除组件
import { Dnd } from '@antv/x6-plugin-dnd';
import styles from './index.module.scss';
import { Button, Modal, message, Space, Popover, Tabs, Dropdown } from 'antd';
import { CopyOutlined, DeleteOutlined, SwapOutlined } from '@ant-design/icons';

const commonAttrs = {
  body: {
    fill: '#fff',
    stroke: '#8f8f8f',
    strokeWidth: 1,
    magnet: true,
  },
};
const options = {
  offset: 30,
  useLocalStorage: true,
};
let graph: Graph | any = null;
let container: HTMLDivElement | any = null;

// #region 初始化连接点位置
const ports = {
  groups: {
    //配置每个方位的桩的属性
    top: {
      position: 'top',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    right: {
      position: 'right',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    bottom: {
      position: 'bottom',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    left: {
      position: 'left',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#5F95FF',
          strokeWidth: 1,
          fill: '#fff',
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
  },
  items: [
    //连接桩位置个数
    {
      group: 'top',
    },

    {
      group: 'bottom',
    },
  ],
};

Graph.registerNode(
  'custom-image1',
  {
    inherit: 'rect',
    width: 152,
    height: 52,
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'image',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      body: {
        stroke: 'none', //边的颜色
        fill: '#5F95FF', //填充颜色
      },
      image: {
        //图片的位置大小
        width: 26,
        height: 26,
        refX: 13,
        refY: 16,
      },
      label: {
        //文案的位置
        refX: 6,
        refY: 2,
        textAnchor: 'left',
        textVerticalAnchor: 'top',
        fontSize: 12,
        fill: '#fff',
      },
    },
    ports: { ...ports },
  },
  true
);
Graph.registerNode(
  'custom-image2',
  {
    inherit: 'rect',
    width: 50,
    height: 50,
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'image',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      body: {
        stroke: '#3369ff', //边的颜色
        fill: '#cccccc', //填充颜色
      },
      image: {
        //图片的位置大小
        width: 40,
        height: 40,
        refX: 5,
        refY: 5,
      },
      label: {
        //文案的位置
        refX: 55,
        refY: 25,
        textAnchor: 'left',
        textVerticalAnchor: 'top',
        fontSize: 12,
        fill: '#000000',
      },
    },
    ports: { ...ports },
  },
  true
);
Graph.registerNode(
  'custom-add',
  {
    inherit: 'circle',
    width: 30,
    height: 30,
    markup: [
      {
        tagName: 'circle',
        selector: 'body',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      body: {
        stroke: '#A2B1C3',
        'stroke-width': 1,
        fill: '#fff',
        cursor: 'pointer',
      },
      label: {
        //文案的位置
        text: '+',
        textAnchor: 'middle',
        textVerticalAnchor: 'middle',
        fontSize: 20,
        fill: '#A2B1C3',
      },
    },
    ports: { ...ports },
  },
  true
);
const imageShapes = [
  {
    row: '1',
    type: '1',
    label: '人群选择',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/687b6cb9-4b97-42a6-96d0-34b3099133ac.svg',
  },
  {
    row: '1',
    type: '1',
    label: '人群批量选择',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/dc1ced06-417d-466f-927b-b4a4d3265791.svg',
  },
  {
    row: '1',
    type: '2',
    label: '人群抽取',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/c55d7ae1-8d20-4585-bd8f-ca23653a4489.svg',
  },
  {
    row: '2',
    type: '2',
    key: 'combine',
    label: '合并',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/6eb71764-18ed-4149-b868-53ad1542c405.svg',
  },
  {
    row: '2',
    type: '2',
    key: 'cross',
    label: '交集',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/c36fe7cb-dc24-4854-aeb5-88d8dc36d52e.svg',
  },
  {
    row: '2',
    type: '2',
    key: 'except',
    label: '排除',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/2010ac9f-40e7-49d4-8c4a-4fcf2f83033b.svg',
  },
  {
    row: '2',
    type: '2',
    key: 'split',
    label: '拆分',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/2010ac9f-40e7-49d4-8c4a-4fcf2f83033b.svg',
  },
  {
    row: '2',
    type: '2',
    key: 'deduplication',
    label: '去重',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/2010ac9f-40e7-49d4-8c4a-4fcf2f83033b.svg',
  },
  {
    row: '3',
    type: '1',
    key: 'message',
    label: '短信',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/2010ac9f-40e7-49d4-8c4a-4fcf2f83033b.svg',
  },
  {
    row: '3',
    type: '2',
    key: 'savePeople',
    label: '保存人群',
    image: 'https://gw.alipayobjects.com/zos/bmw-prod/2010ac9f-40e7-49d4-8c4a-4fcf2f83033b.svg',
  },
];
// #region初始化图形
const TemplateGraph = () => {
  useEffect(() => {
    initTemplateContent();
  });
  const initTemplateContent = () => {
    graph = new Graph({
      container: container,
      panning: true, //左键拖动画布
      width: 300,
      height: 350,
      background: {
        color: '#F2F7FA',
      },
      scaling: {
        min: 0.5, // 默认值为 0.01
        max: 2, // 默认值为 16
      },
      connecting: {
        router: 'manhattan',
        connector: {
          name: 'rounded',
          args: {
            radius: 8,
          },
        },
        anchor: 'center',
        connectionPoint: 'boundary',
        allowBlank: false,
        allowLoop: false,
        allowMulti: false,
        snap: {
          radius: 20,
        },
        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
                targetMarker: {
                  name: 'block',
                  width: 12,
                  height: 8,
                },
              },
            },
            zIndex: 0,
          });
        },
        validateConnection({ targetMagnet }) {
          return !!targetMagnet;
        },
      },
      highlighting: {
        magnetAdsorbed: {
          //连线过程中，连接桩可以被链接时被使用
          name: 'stroke',
          args: {
            attrs: {
              fill: '#5F95FF',
              stroke: '#5F95FF',
            },
          },
        },
      },
      translating: {
        restrict: true, //节点不能移动超出画布区域
      },
    });
    const info = imageShapes[4];

    const newNode = graph.addNode({
      x: 120,
      y: 50,
      shape: 'custom-image2',
      attrs: {
        label: {
          text: info.label,
        },
        image: {
          'xlink:href': info.image,
        },
      },
      data: {
        label: info.label,
      },
    });
    graph.addEdge({
      shape: 'edge',
      source: { x: 145, y: 10 },
      target: newNode,
      attrs: {
        line: {
          stroke: '#A2B1C3',
          strokeWidth: 2,
          targetMarker: {
            name: 'block',
            width: 12,
            height: 8,
          },
        },
      },
    });
    console.log(333, newNode);
  };

  const refContainer = (container1: HTMLDivElement) => {
    container = container1;
  };
  // 控制连接桩显示/隐藏
  const showPorts = (ports: NodeListOf<SVGElement>, show: boolean) => {
    for (let i = 0, len = ports.length; i < len; i += 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden';
    }
  };
  // #region 操作按钮

  const bigGraph = () => {
    graph.zoom(0.2, { center: { x: -100, y: -100 } });
  };
  const smallGraph = () => {
    graph.zoom(-0.2, { center: { x: -100, y: -100 } });
  };

  // #region 页面布局
  return (
    <div className={styles['template-box']}>
      <div className={styles['graph-content']} id="graph-container" ref={refContainer} />
    </div>
  );
};
export default TemplateGraph;
