import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  Modal,
  Dropdown,
  Space,
  Select,
  Tooltip,
  message,
} from "antd";
import { getQueryParam } from '../../../utils/tools';
import DownloadUserDeatil from "./components/DownloadUserDeatil";
import type { TableProps } from "antd";
import {
  FilterOutlined,
  CrownOutlined,
  DeploymentUnitOutlined,
  TeamOutlined,
  IdcardOutlined,
  CopyOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import copyToClipboard from "../../../crm/assets/public/clipboard";
import ModuleTitle from "../../../component/ModuleTitle";
import SearchIconInput from "../../../component/Input/SearchIconInput/searchIconInput";
import FilterView from "../../../component/FilterView/filterView";
import BackList from '../../../component/BackList/backList';
import { state } from '../../../store';
import {
  UserIdentity,
  UserIdentityTextEnum,
} from "../../../enums/userIdentity";
import {
  getUserList,
  getCrowdUserList,
  getMemberCard,
  getGradeProfile,
  getChannelProfile,
} from "../../../api/userList/userList";
import {
  getUserQueryPageApi,
  getMemberLevelNewList,
  getShopList,
  getChannelListApi
} from "../../../api/UserInsight/UserInsight";
import FilterIcon from '@/component/FilterIcon/filterIcon'
import { UserListQuery, UserListVO, RecordQuery } from "../../../api/userList/types";
import styles from "./index.module.scss";
import { dataOf } from "@antv/g2/lib/interaction/legendFilter";
import JD from '../../../assets/images/JD.png';
import TM from '../../../assets/images/TM.png';
import DY from '../../../assets/images/DY.png';
import MD from '../../../assets/images/offlineShop.png';
import YZ from '../../../assets/images/YZ.png';
import WX from '../../../assets/images/WX.png';
import jdBule from '../../../assets/images/jdBule.png';
import tmBlue from '../../../assets/images/tmall.png';
import dyBlue from '../../../assets/images/douyin.png';
import mdBlue from '../../../assets/images/offlineShopBlue.png';
import yzBlue from '../../../assets/images/youzan.png';
import wxBlue from '../../../assets/images/WXblue.png';
import SystemButton from "@/component/Button/SystemButton";
import { checkPermissions } from '@/utils/utils';
import { LABEL_KEY, USER_KEY } from "@/router/Permissions";
import { useNavigate } from 'react-router-dom';
import { getTableScroll } from '@/store/index';
// import { Router } from "react-router-dom";
const { Option } = Select;
let channeldIconData:any = [];
// 渠道图标map
const channelIconMapList = [
  {
    level: 'JD',
    name: '京东',
    img: JD,
    isLight: false,
    imgBlue: jdBule,
  },
  {
    level: 'TM',
    name: '天猫',
    img: TM,
    isLight: false,
    imgBlue: tmBlue,
  },
  {
    level: 'DY',
    name: '抖音',
    img: DY,
    isLight: false,
    imgBlue: dyBlue,
  },
  {
    level: 'MD',
    name: '线下门店',
    img: MD,
    isLight: false,
    imgBlue: mdBlue,
  },
  {
    level: 'YZ',
    name: '有赞',
    img: YZ,
    isLight: false,
    imgBlue: yzBlue,
  },
  {
    level: 'WX',
    name: '微信',
    img: WX,
    isLight: false,
    imgBlue: wxBlue,
  },
]

let operatePageNum: any
let operatepageSize: any
let preParams: any
var shopData = [] as any;// 店铺信息
var gradeData = [] as any;// 会员等级信息
//手机号还是oneid查询字段
let phoneorIdData = "mobile" as any;
const DownLoadDeatil = () => {
  const navigate = useNavigate();
  const [userInfoRecord, setUserInfoRecord] = useState({} as RecordQuery)
  //判断是从哪个页面跳转过来的 1 分群  0 标签
  const userInfoType = getQueryParam(window.location.href, 'type') as any
  const labelId = getQueryParam(window.location.href, 'labelId') as any
  const labelValueId = getQueryParam(window.location.href, 'labelValueId') as any
  const renewTime = getQueryParam(window.location.href, 'renewTime') as any
  const renewType = getQueryParam(window.location.href, 'renewType') as any
  const taskId = getQueryParam(window.location.href, 'taskId') as any
  const filterViewRef: any = useRef(null);
  const searchIconInputRef: any = useRef(null);
  // 防连点
  const [responseFinished, setResponseFinished] = useState(false);
  //渠道数据
  const [channeldData, setChanneldData] = useState([] as any);
  //会员卡数据
  const [memberCardData, setMemberCardData] = useState([] as any);
  //会员等级数据
  const [gradeProfileData, setGradeProfileData] = useState([] as any);
  //分群数据
  const peopleList = [
    { title: '分群名称：', value: userInfoRecord.crowdName },
    { title: '分群ID：', value: userInfoRecord.crowdId },
    { title: '分群人数：', value: userInfoRecord.groupNum },
    { title: '状态：', value: userInfoRecord.enable ? '启用' : '停用' },//0是停用  1 启用
    { title: '创建时间：', value: userInfoRecord.createTime },
    { title: '描述：', value: userInfoRecord.crowdDescribe }
  ];

  //数据列表
  const [listData, setListData] = useState([] as any);
  //手机号还是oneid查询字段
  const [phoneorId, setPhoneorId] = useState("mobile");
  //改变手机号还是oneid查询key
  const changePhoneorId = (data: any) => {
    phoneorIdData = data
    setPhoneorId(data)
  };
  const selectBefore = (
    <Select defaultValue={phoneorId} onChange={changePhoneorId}>
      <Option value="mobile">手机号</Option>
      <Option value="platformId">平台ID</Option>
      <Option value="orderId">订单号</Option>
      <Option value="oneId">OneID</Option>
    </Select>
  );
  //搜索条件
  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择所属渠道",
      title: "所属渠道",
      defaultValue: preParams?.channelType ? String(preParams.channelType) : null,
      key: "channelType",
      options: channeldData,
    },
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入",
      defaultValue: preParams?.[phoneorIdData] ? preParams?.[phoneorIdData] : null,
      title: "",
      key: phoneorId,
      selectBefore: selectBefore,
    },
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择用户身份",
      defaultValue: preParams?.identity ? preParams.identity : null,
      title: "用户身份",
      key: "identity",
      options: [
        {
          value: UserIdentity.ENABLE,
          label: UserIdentityTextEnum.ENABLE_TEXT,
        },
        {
          value: UserIdentity.CHANNEL,
          label: UserIdentityTextEnum.CHANNEL_TEXT,
        },
        {
          value: UserIdentity.COMMON,
          label: UserIdentityTextEnum.COMMON_TEXT,
        },
        {
          value: UserIdentity.STAFF,
          label: UserIdentityTextEnum.STAFF_TEXT,
        },
      ],
    },
    // {
    //   searchType: "text",
    //   searchComponentType: "selectVip",
    //   placeHolder: "请选择所属会员卡",
    //   title: "所属会员卡",
    //   key: "memberCardId",
    //   options: memberCardData,
    //   changeField:'gradeId',
    // },

    // {
    //   searchType: "text",
    //   searchComponentType: "select",
    //   placeHolder: "请选择会员等级",
    //   title: "会员等级",
    //   key: "gradeId",
    //   options: gradeProfileData
    // },
    {
      searchType: "text",
      searchComponentType: "inputDouble",
      addonAfter: '元',
      placeHolder: "请输入",
      title: "累计消费金额",
      defaultValue: `${preParams?.minTradeAmount ? preParams.minTradeAmount : ''}, ${preParams?.maxTradeAmount ? preParams.maxTradeAmount : ''}`,
      limitNum: 2,
      key: "minTradeAmount,maxTradeAmount",
    },
    {
      searchType: "text",
      searchComponentType: "inputDouble",
      addonAfter: '次',
      placeHolder: "请输入",
      title: "累计下单次数",
      defaultValue: `${preParams?.minTradeCount ? preParams.minTradeCount : ''},${preParams?.maxTradeCount ? preParams.maxTradeCount : ''}`,
      maxLength: 3,
      limitNum: 0,
      max: 999,
      key: "minTradeCount,maxTradeCount",
    },
  ];

  interface SearcParamType {
    labelValueId: string;
    crowdId: any;
    labelId: string;
    renewTime: string;
    renewType: string;
    oneIdOrPhone: string;
  }
  const searcParamInt: SearcParamType = {
    labelValueId: labelValueId,
    crowdId: localStorage.getItem('setGroupDetail') ? JSON.parse(localStorage.getItem('setGroupDetail') as any).crowdId : '',
    labelId: labelId,
    renewTime: renewTime,
    renewType: renewType,
    oneIdOrPhone: '',
  };
  //搜索字段
  const [searcParam, setSearcParam] = useState({});

  // 查询
  const filterFinish = (data: any) => {
    delete data["minTradeAmount,maxTradeAmount"];
    delete data["minTradeCount,maxTradeCount"];
    setSearcParam(data);
    getUserDetailList({ pageSize: 10, pageNum: 1 }, data,
      // {
      //   orderByColumn: "createTime",
      //   isAsc: "desc",
      // }
    );
  };
  // 查询会员等级
  const handleChangeFilter = (data: any) => {
    if (data) {
      getGradeProfileList(data)
    } else {
      setGradeProfileData([])
    }
  };
  //抽屉  true  开启
  const [openDownloadPop, setOpenDownloadPop] = useState(false);
  // 打开下载抽屉
  const downloadItem = () => {
    setOpenDownloadPop(true);
  };
  // 关闭下载抽屉
  const closeDownLoadItem = (v: boolean) => {
    setOpenDownloadPop(v);
  };
  //详情跳转到新页面
  const nagativeWin = (data: any) => {
    if (checkPermissions(USER_KEY.USER_DETAIL)) {
      //点击去详情页 将筛选项存到缓存
      localStorage.setItem('pageNum', String(operatePageNum))
      localStorage.setItem('pageSize', String(operatepageSize))
      var da:any = preParams ? JSON.stringify(preParams) :null
      localStorage.setItem('searchListData', da)
      localStorage.setItem('phoneorId', phoneorIdData)
      localStorage.setItem('oneId', data)
      window.open(`/userInsight/userInsightDocument/UserDetail`);
    }
  }
  // 带有粘贴功能的文本
  const handCopy = (text: any) => {
    copyToClipboard(text)
      .then(() => {
        message.success("复制成功");
      })
      .catch((error) => {
        message.error("复制失败");
      });
  };
  //会员等级处理
  const renderGradeData = (record: any) => {
    record.customerMemberInfo?.map((item: any) => {
      item.numCard = item.memberCardId + item.gradeId
      gradeData.map((itm: any) => {
        if (itm.numCard == item.numCard) {
          item.gradeName = itm.gradeName
        }
      })
    })
    return <>
      {
        record.customerMemberInfo?.map((it: any, index: number) =>
          <div key={index}>
            {it.gradeImgUrl && <Tooltip placement="top" title={it.gradeName} >
              <img src={it.gradeImgUrl} className={styles.UserDeatil_mesImg} alt="" />
            </Tooltip>}
            {!it.gradeImgUrl && <div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', width: '60px' }}>{it.gradeName ? it.gradeName : '非会员'}</div>}
          </div>
        )
      }
    </>
  };
  //所属渠道处理
  const renderChannelType = (value: any) => {
    let newArray: any = [];
    let transformedInfo = Object.keys(value?.channelInfo).map(key => (
      { name: key, arr: value?.channelInfo[key] }
    ));
    channeldIconData.forEach((item: any) => {
      item.isLight = false
      item.unbindShopName = []
      item.bingingShopName = []
      if (transformedInfo.length > 0) {
        transformedInfo.map((itm: any) => {

          if (itm.name === item.level) {
            item.isLight = true
            shopData.map((i: any) => {
              itm.arr.forEach((im: any) => {
                if (i.shopId == im.shopId) {
                  item.bingingShopName.push(i.shopName)
                }
                // else{
                //   item.unbindShopName.push(i.shopName)
                // }
              })
            })
          }
        })
      } else {
        item.isLight = false
        item.unbindShopName = []
        item.bingingShopName = []
      }

    });

    return channeldIconData.map((item: any, index: any) => {
      return (
        <Tooltip placement="top" key={index} title={
          <>
            <div style={{ display: 'flex' }}>
              <div style={{width : (item.bingingShopName?.length > 0 || item.unbindShopName?.length > 0) ?'60px' :''}}>{item.name}</div>
              <div style={{ flex: '4' }}>
                {item.bingingShopName && <div>
                  {item.bingingShopName.map((itm: any, indx: any) =>
                    <div key={indx}>{itm}</div>
                  )}
                </div>}
                {item.unbindShopName && <div>
                  {item.unbindShopName.map((itm: any, indx: any) =>
                    <div style={{ color: 'var(--background1)', opacity: '0.4' }} key={indx}>{itm}</div>
                  )}
                </div>}
              </div>
            </div>
          </>
        } >
          <img src={item.isLight ? item.imgBlue : item.img} alt="" style={{ opacity: item.isLight ? '' : '0.5', width: '20px', height: '20px', marginRight: '4px' }} />
        </Tooltip>
      )
    });
  };
  const renderChannelType1 = (value: any) => {
    let newArray: any = [];
    let iconList: any = [],
      textArray: any = [];
    if (value) {
      iconList = value.split(',')
    }
    newArray = iconList.filter((i: any) => i)
    channelIconMapList.forEach((item: any) => {
      item.isLight = false
      item.unbindShopName = []
      item.bingingShopName = []
      if (newArray.length > 0) {
        newArray.map((itm: any) => {
          if (itm === item.level) {
            item.isLight = true
            // if (itm.unbindShopName) {
            //   var a = itm.unbindShopName
            //   item.unbindShopName = a.split(',')
            // }
            // if (itm.bingingShopName) {
            //   var b = itm.bingingShopName
            //   item.bingingShopName = b.split(',')
            // }
          }
        })
      } else {
        item.isLight = false
        item.unbindShopName = []
        item.bingingShopName = []
      }

    });

    return channelIconMapList.map((item: any, index: any) => {
      return (
        <Tooltip placement="top" key={index} title={
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '60px' }}>{item.name}</div>
              {/* <div style={{ flex: '4' }}>
                {item.bingingShopName && <div>
                  {item.bingingShopName.map((itm: any, indx: any) =>
                    <div key={indx}>{itm}</div>
                  )}
                </div>}
                {item.unbindShopName && <div>
                  {item.unbindShopName.map((itm: any, indx: any) =>
                    <div style={{ color: 'var(--background1)', opacity: '0.4' }} key={indx}>{itm}</div>
                  )}
                </div>}
              </div> */}
            </div>
          </>
        } >
          <img src={item.isLight ? item.imgBlue : item.img} alt="" style={{ opacity: item.isLight ? '' : '0.5', width: '20px', height: '20px', marginRight: '4px' }} />
        </Tooltip>
      )
    })
  };
  //tabel 列表字段
  const columns: TableProps<UserListVO>["columns"] = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      key: "sort",
      width: '88px',
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: "OneID",
      dataIndex: "oneId",
      key: "oneId",
      fixed: "left",
      width: 300,
      render: (data: any) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Space className={styles.oneidCopyOutlined} style={{ color: checkPermissions(USER_KEY.USER_DETAIL) ? '' : 'var(--text-font-color3)' }} onClick={() => nagativeWin(data)}>
            {data}
          </Space>
          <CopyOutlined
            className={styles.UserCopyOutlined}
            onClick={() => handCopy(data)}
          />
        </div>
      ),
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "客户身份",
      dataIndex: "identity",
      key: "identity",
      render: (text: string, record: any) =>
        <div style={{ display: 'flex' }}>
          <Tooltip placement="top" title={'存量会员'}>
            <CrownOutlined style={{ color: text == 'STOCK' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
          <Tooltip placement="top" title={'渠道会员'}>
            <DeploymentUnitOutlined style={{ color: text == 'CHANNEL' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
          <Tooltip placement="top" title={'普通客户'}>
            <TeamOutlined style={{ color: text == 'COMMON' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
          <Tooltip placement="top" title={'员工'}>
            <IdcardOutlined style={{ color: text == 'STAFF' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
        </div>
    },
    {
      title: "所属会员卡",
      dataIndex: "memberCardName",
      key: "memberCardName",
      render: (text: string, record: any) =>
        <>
          {record.customerMemberInfo?.map((it: any, index: number) =>
            <div
              key={index}
              style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', marginRight: '5px' }}>
              {it.memberCardName ? it.memberCardName : '无'}
            </div>
          )}
        </>
    },
    {
      title: "会员渠道",
      dataIndex: "channelType",
      key: "channelType",
      width: 200,
      render: (value: any, record: any) => renderChannelType(record)
    },
    {
      title: "会员等级",
      dataIndex: "gradeId",
      key: "gradeId",
      render: (text: string, record: any) => renderGradeData(record)
      // render: (data: any) => (
      //   <Space>
      //     <div>{data?'VIP'+ data:''}</div>
      //   </Space>
      // ),
    },
    {
      title: "当前积分",
      dataIndex: "totalPoint",
      key: "totalPoint",
      render: (text: string, record: any) =>
        <>
          {
            record.customerMemberInfo?.map((it: any, index: number) =>
              <Tooltip placement="topLeft" title={it.totalPoint} key={index}>
                <div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', cursor: 'pointer' }}>{it.totalPoint}</div>
              </Tooltip>
            )
          }
        </>
      // render: (data: any) => (
      //   <Space>
      //     <div>{data?data:'0'}</div>
      //   </Space>
      // ),
    },
    {
      title: "当前成长值",
      dataIndex: "growthValue",
      key: "growthValue",
      width: 140,
      render: (text: string, record: any) =>
        <>
          {
            record.customerMemberInfo?.map((it: any, index: number) =>
              <Tooltip placement="topLeft" title={it.growthValue} key={index}>
                <div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', cursor: 'pointer' }}>{it.growthValue}</div>
              </Tooltip>
            )
          }
        </>
      // render: (data: any) =>
      // <Space>
      //   <div>{data?data:'0'}</div>
      // </Space>
    },
    {
      title: "累计消费金额",
      dataIndex: "tradeAmount",
      key: "tradeAmount",
    },
    {
      title: <>
        <span style={{ marginRight: '5px' }}>累计下单次数</span>
        <Tooltip placement="top" title="仅统计确认收货的订单">
          <QuestionCircleOutlined style={{ color: 'var(--text-font-color3)', opacity: '0.45' }} />
        </Tooltip>
      </>,
      dataIndex: "tradeCount",
      key: "tradeCount",
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "120px",
      render: (data: any) => (
        <Space>
          {checkPermissions(USER_KEY.USER_DETAIL) &&
            <Button type="link" onClick={() => nagativeWin(data.oneId)}>详情</Button >
          }
        </Space>
      ),
    },
  ];

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 排序
  // const [sortValue, setSortValue] = useState({
  //   orderByColumn: "updateTime",
  //   isAsc: "desc",
  // });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }

    getUserDetailList(
      { pageSize: pagination.pageSize, pageNum: pagination.current },
      searcParam,
      // sortStore
    );
  };

  // 获取客户列表
  const getUserDetailList = async (
    page: { pageSize: number; pageNum: number },
    dataSerch: any,
    // sort: { orderByColumn: string; isAsc: string }
  ) => {
    const param = {
      taskId: taskId,
      ...dataSerch,
      ...searcParamInt,
      ...page,
    } as UserListQuery;
    let res: any;
    res = await getUserQueryPageApi(param);
    // res = await getCrowdUserList(param);
    var data: any = res?.data ?? { rows: [] }
    setListData(data.rows)
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: data.total,
    });
    operatePageNum = page.pageNum
    operatepageSize = page.pageSize
    preParams = dataSerch
    setScrollY(getTableScroll())
    // setSortValue({
    //   orderByColumn: sort.orderByColumn,
    //   isAsc: sort.isAsc,
    // });
  };
  // 获取渠道配置
  const getChannelList = async () => {
    const res = await getChannelListApi();
    if (res) {
      const list: any = res.data
      list.map((item: any) => {
        item.label = item.channelName;
        item.value = item.channelType;
      });
      setChanneldData(list)
      var arr:any = res.data;
      channelIconMapList.map((item:any)=>{
        arr.map((itm:any, indx:number)=>{
            if (item.level == itm.channelType) {
              itm.img = item.img
              itm.level = item.level
              itm.name = item.name
              itm.imgBlue = item.imgBlue
              itm.isLight = item.isLight
            }
          })
      })
      channeldIconData = arr
    }

  };
  // 获取会员等级  最新
  const getGradeProfileNewList = async () => {
    const res = await getMemberLevelNewList();
    if (res.data) {
      if (res.data.length > 0) {
        res.data.map((item: any) => {
          item.numCard = item.memberCardId + item.gradeCode
        })
        gradeData = res.data
      }
    }
  };
  //获取店铺信息
  const getShopListData = async () => {
    let res = await getShopList();
    //下单数据
    if (res) {
      shopData = res.data
      // setShoplistData(res.data)
    }
  };

  // 获取会员卡配置
  const getMemberCardList = async () => {
    const res = await getMemberCard();
    if (res) {
      const list: any = res.data
      list.map((item: any) => {
        item.label = item.memberCardName;
        item.value = item.memberCardId;
      });
      setMemberCardData(list)
    }

  };
  // 获取会员等级
  const getGradeProfileList = async (data: any) => {
    const res = await getGradeProfile({ memberCardId: data });
    if (res) {
      const list: any = res.data
      list.map((item: any) => {
        item.label = item.gradeName;
        item.value = item.gradeId;
      });

      setGradeProfileData(list)
    }
  };
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  }
  const [scrollY, setScrollY] = useState("")
  const getHeight = (e: string) => {
    console.log(e);
    setScrollY(e)
  }
  useEffect(() => {
    if (localStorage.getItem('setGroupDetail') && userInfoType == '1') {
      var info: any = JSON.parse(localStorage.getItem('setGroupDetail') as any)
      setUserInfoRecord(info)
    }
    getMemberCardList()//会员卡
    getShopListData()//所属店铺
    getGradeProfileNewList()//获取会员等级
    getChannelList()//所属渠道
    operatePageNum = localStorage.getItem('pageNum') ? Number(localStorage.getItem('pageNum')):null
    operatepageSize = localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')):null
    preParams = localStorage.getItem('searchListData') ? JSON.parse(localStorage.getItem('searchListData') as string):{}
    //筛选项 下拉选择 手机号 订单号 oneid
    phoneorIdData = localStorage.getItem('phoneorId')? localStorage.getItem('phoneorId'):'mobile'
    setPhoneorId(phoneorIdData)
    localStorage.removeItem('pageNum')
    localStorage.removeItem('pageSize')
    localStorage.removeItem('searchListData')
    localStorage.removeItem('phoneorId')
    setSearcParam(preParams)
    if (operatePageNum || operatepageSize ||  Object.keys(preParams).length != 0 ) {
      getUserDetailList({ pageSize: operatepageSize , pageNum: operatePageNum}, preParams);
    }else{
      getUserDetailList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },searcParam,);
    }
    return () => {

    };
  }, []);

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <BackList />
          <ModuleTitle mark="large" title="客户列表" />
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            ref={searchIconInputRef}
            onSearch={(e: string) => {
              searcParamInt.oneIdOrPhone = e
              const store: SearcParamType = {
                ...searcParamInt,
              };
              filterViewRef.current?.resetData();
              setSearcParam(store);
              getUserDetailList({ pageSize: 10, pageNum: 1 }, store,);
            }}
            placeholder="请输入OneID或手机号"
          ></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
          <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span>
          {checkPermissions(LABEL_KEY.DOWNLOAD) && <Button
            onClick={downloadItem}
            disabled={listData.length == 0}
          >下载</Button>}
        </div>
      </div>
      <div className={styles.DownLoadDeatil}>
        {/* 分群数据 */}
        {userInfoType == '1' && <div className={styles.peopleListBox}>
          {peopleList.map((item, index) => (
            <div className={styles.peopleListItem} key={index}>
              <div className={styles.peopleListItem_title}>{item.title}</div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>}
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          getHeight={getHeight}
          finish={filterFinish}
          handleChange={handleChangeFilter}
        ></FilterView>
        <div className={styles.tableBox}>
          <Table
            rowKey={(record) => record.oneId}
            columns={columns}
            scroll={{ x: 1800, y: scrollY }}
            dataSource={listData}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              current: pageQuery.pageNum,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
        </div>
        <DownloadUserDeatil
          searcParam={searcParam}
          openDownloadPop={openDownloadPop}
          closeDownLoadItem={closeDownLoadItem}
          userInfoType={userInfoType}
          labelId={labelId}
          labelValueId={labelValueId}
          renewTime={renewTime}
          crowdId={localStorage.getItem('setGroupDetail') ? JSON.parse(localStorage.getItem('setGroupDetail') as any).crowdId : ''}
        />
      </div>
    </div>
  );
};

export default DownLoadDeatil;
