/**
 * 总览每个小块的每个迷你折线图悬浮显示的完整图表
 */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { getGeneralChat } from "@/crm/api/industryBoardApi";
import { getNumberWithComma } from "@/utils/utils";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
function MiniLineChat(props: any) {
  const { chartId, searchForm, metricId, digit, titleZh } = props;

  const [xData, setXData] = useState([]);
  const [yDataNum, setYDataNum] = useState([]);
  const [yDataYoy, setYDataYoy] = useState([]);
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");

  useEffect(() => {
    if (
      metricId &&
      channelAllList.length > 0 &&
      shopListBySeveralChannel.length > 0
    ) {
      getWholeChartData();
    }
  }, [metricId, searchForm, channelAllList, shopListBySeveralChannel]);

  const getWholeChartData = async () => {
    let finalShopId = [];
    if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
    const params = {
      startDate: searchForm?.paramStartDate + starthms,
      endDate: searchForm?.paramEndDate + endhms,
      channels: searchForm?.platformType?.includes("all")
        ? channelAllList
            .filter((item) => item.value !== "all")
            .map((item) => item.value)
        : searchForm?.platformType?.split(","),
      shopIds: finalShopId,
      indexIds: [metricId],
      indexType: "yoy_dis",
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
      dateType: searchForm?.dateType,
      isSupplyDate: searchForm?.isSupplyDate,
    };
    const res = await getGeneralChat(params);
    if (res.code === 200) {
      const xDataCopy = [];
      const yDataNumCopy = [];
      const yDataYoyCopy = [];
      res?.data[metricId]?.forEach((item: { x: any; y: any; yoy: any }) => {
        xDataCopy.push(item.biz_date);
        yDataNumCopy.push(item.num === "-" ? 0 : Number(item.num));
        yDataYoyCopy.push(item.yoy === "-" ? 0 : Number(item.yoy));
      });
      setXData(xDataCopy);
      setYDataNum(yDataNumCopy);
      setYDataYoy(yDataYoyCopy);
    }
  };

  let option = {};
  useEffect(() => {
    option = {
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          return `
           <div style="width: 280px;">
           <strong>${params.name}</strong><br/>
<div style="display: flex; align-items: center;">
            <div style="width:10px; height:10px;margin-bottom: 4px; background-color: #5B8FF9;margin-right: 8px "></div>
            本期：${getNumberWithComma(params.value, digit)} ${titleZh.includes('占比') || titleZh.includes('率') || titleZh.includes('比例') ? '%' : ''}<br>
          </div>
${
             Number(yDataYoy[params.dataIndex]) > 0
               ? `
          <div style="display: flex; align-items: center;">
            <div style="width:10px; height:2px;margin-bottom: 4px; background-color: #61D7A7;margin-right: 8px "></div>
            同比：
            <div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:8px solid #F5222D;position:absolute; top:58px; left:68px;"></div>
            <span style="margin-left: 12px;">${yDataYoy[params.dataIndex]}%</span>
          </div>` :  `
          <div style="display: flex; align-items: center;">
            <div style="width:10px; height:2px;margin-bottom: 4px; background-color: #61D7A7;margin-right: 8px "></div>
             同比：<div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-top:8px solid #52C41A;position:absolute; top:58px; left:68px;"></div>
            <span style="margin-left: 12px;">${yDataYoy[params.dataIndex]}%</span>
          </div>`}
             </div>`;
        },
      },
      legend: {
        left: 24,
        itemHeight: 10,
        itemWidth: 10,
        data: [
          { name: "本期", icon: "rect" }, // 本期
          { name: "同比", icon: "line" }, // 同比
        ],
        formatter: function (name: string) {
          if (name === "本期") {
            return name; // 自定义样式
          } else {
            return "{line|} " + name; // 自定义样式
          }
        },
        textStyle: {
          rich: {
            square: {
              width: 10, // 正方形宽度
              height: 10, // 正方形高度
              backgroundColor: "#5B8FF9", // 正方形颜色
            },
            line: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "#5AD8A6", // 横线颜色
            },
          },
        },
      },
      series: [
        {
          name: "本期",
          type: "bar",
          barWidth: 30,
          itemStyle: {
            color: "#5B8FF9",
          },
          tooltip: {
            valueFormatter: function (value: string) {
              return value;
            },
          },
          data: yDataNum,
        },
        {
          name: "同比",
          type: "line",
          showSymbol: false,
          lineStyle: {
            color: "#61D7A7",
          },
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value: string) {
              return value + " %";
            },
          },
          data: yDataYoy,
        },
      ],
      grid: {
        left: 24,
        right: 24,
        bottom: 48,
        containLabel: true,
      },
      dataZoom: [
        {
          handleSize: 20,
          moveHandleSize: 0,
          borderColor: "transparent", // 去掉边框颜色
          borderWidth: 0, // 去掉边框宽度
          backgroundColor: "#F2F3F5",
          // 所选范围外的数据面积
          dataBackground: {
            // 边缘线条颜色
            lineStyle: {
              color: "#0487FF",
            },
            // 面积颜色
            areaStyle: {
              color: "#CEE0FF",
            },
          },
          selectedDataBackground: {
            lineStyle: {
              color: "#0487FF",
            },
            areaStyle: {
              color: "#0487FF",
            },
          },
          height: 14,
          moveHandleIcon: "diamond",
          handleStyle: {
            color: "rgba(41, 222, 50, 1)",
          },
          brushStyle: {
            color: "rgba(188, 42, 42, 1)",
          },
          handleIcon:
            "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAADySURBVDiNtZUxbgIxEABHLmngATQ8gI/wARTp+FCUjkcEmkQ0SU/apLrUJ4Ho0tDQ0EwKjoAsX5IT56ksrz1eWd41JFD76p26Uiv16IVjPbeq1/RTjlgY1KLe+F+qek9okvbUeQthzFztpTK9RXotD9fiogPpmdlZOrDhTu+fdH84jcuNLtbpWESlDgIwAUape394hs/Nafz6AYu3dCxiBEwCMP3zubRnGoBxBvE4AMMM4mH6UXdAAHYZvLsAlBnEZQCWGcTLXwvkcX0pgu2XvrynY6kCAUCdNRZoe4qfvM3VhGp5920zyrzbRh8dcPPX9A3bEkbAkjy82wAAAABJRU5ErkJggg==",
        },
      ],
      xAxis: [
        {
          type: "category",
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          data: xData,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          alignTicks: true,
          axisLabel: {
            formatter: (titleZh.includes('占比') || titleZh.includes('率') || titleZh.includes('比例')) ? '{value} %' : '{value}',
          },
        },
        {
          type: "value",
          name: "",
          alignTicks: true,
          axisLabel: {
            formatter: "{value} %",
          },
        },
      ],
    };
    let chartDom = document.getElementById("wholeChart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [JSON.stringify(option), xData]);
  return (
    <div
      id="wholeChart"
      style={{
        width: "1000px",
        height: "418px",
        backgroundColor: "#fff",
        paddingTop: "24px",
      }}
    />
  );
}

export default MiniLineChat;
