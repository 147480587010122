import React, { ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import style from './index.module.scss';
import { message } from 'antd'; //Progress
import { Chart } from '@antv/g2';
import _ from 'lodash';

let Histogram = forwardRef(
  (
    props: {
      listData?: any;
    },
    ref: any
  ) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({}));
    //监听或进入调用
    useEffect(() => {
      // props.listData.sort((a: any, b: any) => a.enterCount - b.enterCount);
      props.listData.sort((a: any, b: any) => a.period.replace(/-/g, '') - b.period.replace(/-/g, ''));

      const chart = new Chart({
        container: 'histogram',
        autoFit: true,
      });
      chart
        .data(props.listData)
        .encode('x', 'period')
        .encode('y', 'enterCount')
        .axis('y', { title: false })
        .axis('x', { title: false, labelTransform: 'rotate(0)' });

      chart.line().encode('shape', 'line');
      chart.area().style('fill', 'linear-gradient(-90deg, white 0%, #dbe7fe 100%)');
      chart
        // .point()
        // .encode('shape', 'point')
        .interaction('tooltip', {
          // render 回调方法返回一个innerHTML 或者 DOM
          render: (event: any, { title, items }: any) => `<div>
        <h3 style="color:rgba(0, 0, 0, 0.45);margin-bottom:12px">${title}</h3>
        <span style="color: rgba(0, 0, 0, 0.65);margin-right:37px">进入流程的用户</span> ${items[0].value}
        </div>`,
        });
      chart.interval();

      chart.render();
    }, [props.listData]);

    return (
      <>
        <div id="histogram"></div>
      </>
    );
  }
);
export default Histogram;
