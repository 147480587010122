import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import indexAILogoGif from "@/pages/LuZhiBI/assets/images/indexAILogo.gif";
import styles from "./index.module.scss";
import Menu from "../../../component/layout/Menu";
import SubMenu from "../../../component/layout/SubMenu";
import { Outlet } from "react-router-dom";
import { state, setMenu } from "../../../store";
import { useSnapshot } from "valtio";
import { LzMenu } from "../../../Types/LzMenu";
import LZHeader from "../../../component/layout/Header";
import { Spin } from "antd";
import busEvent from "../../../utils/bus";
import { useLocation, useNavigate } from "react-router-dom";
import routerMap from '../../../router/routerMap';
const { Header, Content } = Layout;

function LZLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const snap = useSnapshot(state);

  //菜单是否收回
  const [thin, setThin] = useState(false);
  //是否全屏
  const [fullScreen, setFullScreen] = useState(false)

  //展开菜单
  const arrowSubMenu = (e: boolean) => {
    setThin(!e);
  };
  useEffect(() => {
    // 全局储存菜单信息
    busEvent.on("subMenuArrow", arrowSubMenu);
  }, []);
  useEffect(() => {
    // 在路由变化时执行的操作
    const menu = state.menu
    // menu.forEach((item, index) => {
    const config = routerMap.find(item1 => item1.path === location.pathname) as any;
    console.log("变路由");
    console.log(config);

    if (config && config.fullScreen) {
      setFullScreen(true)
    } else {
      setFullScreen(false)
    }
    // })

  }, [location]);// eslint-disable-line react-hooks/exhaustive-deps
  if (snap.menu && state.menu.length > 0) {
    return (
      <Layout className="lz_container">
        {!fullScreen && <Menu />}
        {/* {(snap.menu[snap.currentMenuIndex] && snap.menu[snap.currentMenuIndex].children) && <SubMenu />} */}
        {!fullScreen && <SubMenu />}
        <Layout>
          {!fullScreen && <LZHeader></LZHeader>}
          <Content className="content-box">
            <Content
              style={{
                background: "var(--background1)",
                overflowY: "auto",
                height: "100%",
                scrollbarWidth: "none",
              }}
            >
              <Outlet />
            </Content>
          </Content>
        </Layout>
        {/* {
          (!window.location.pathname.includes('/DataBoard/LuZhiBI/ChatBI/toChat') && !window.location.pathname.includes('/DataBoard/LuZhiBI/ChatBI/noResult')) && 
          <div className="lz_logo">
            <img src={indexAILogoGif} alt="" className={styles.aiLogo} onClick={() => window.open('/bi/DataBoard/LuZhiBI/ChatBI/toChat', '_blank')} />
          </div>
        } */}
      </Layout>
    );
  } else {
    return <div></div>;
  }
}

export default LZLayout;
