/**
 * 人群细分 - 根据客户类型查询
 */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import OverviewItem from "./../../Overview/component/OverviewItem";
import { Select, Button, message, Tooltip } from "antd";
import dataMap from "./dataSource";
import {
  getGeneralChat,
  downLoadCustomerType,
} from "@/crm/api/industryBoardApi";
import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";

function ChartByCustom(props: any) {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const { metricAllList } = useGetMetricList();
  const [dataSourceMap, setDataSourceMap] = useState(dataMap.dataSourceMap);
  const [hasMetricIdDataSource, setHasMetricIdDataSource] = useState([]);
  const [propHasMetricIdDataSource, setPropHasMetricIdDataSource] = useState(
    []
  );
  const [finalSearchForm, setFinalSearchForm] = useState<any>({});
  const { searchForm } = props;
  const customTypeOptions = [
    { label: "全部客户", value: "0" },
    { label: "会员", value: "1" },
    { label: "新入会", value: "2" },
    { label: "新客", value: "3" },
    { label: "老客", value: "4" },
  ];
  const [checkedCustomType, setCheckedCustomType] = useState("0");
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);
  const [dataSourceFinal, setDataSourceFinal] = useState<any>(
    dataSourceMap.get("0")
  );

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {
      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds: finalShopId,
        isShopAll: searchForm?.isShopAll,
        isPlatformAll: searchForm?.isPlatformAll,
      };
      setFinalSearchForm(searchParams);
      setDataSourceFinal(dataSourceMap.get(checkedCustomType));
      // setHasMetricIdDataSource([]);
      const groupedDataSource = groupArrByFrameWorkType(hasMetricIdDataSource);
      const groupedDataKeys = Object.keys(groupedDataSource);
      const groupedDataValues = Object.values(groupedDataSource);
      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedDataValues[index].map((i: any) => i.metricId),
          "num"
        );
      });

      const groupedPropDataSource = groupArrByFrameWorkType(
        propHasMetricIdDataSource
      );
      const groupedPropDataKeys = Object.keys(groupedPropDataSource);
      const groupedPropDataValues = Object.values(groupedPropDataSource);
      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedPropDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedPropDataValues[index].map((i: any) => i.metricId),
          "prop"
        );
      });
    }
  }, [hasMetricIdDataSource, channelAllList]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId(checkedCustomType);
    }
  }, [searchForm, metricAllList, checkedCustomType]);

  const setMetricId = (customType) => {
    const copyDataSource = [];
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (
      searchForm?.shopId?.includes("all") &&
      !searchForm?.platformType?.includes("all")
    ) {
      str += "分渠道";
    } else if (
      searchForm?.shopId?.includes("all") &&
      searchForm?.platformType?.includes("all")
    ) {
      str = "";
    }
    const propType = dataSourceMap
      .get(customType)
      ?.filter(
        (item) => item.titleZh.includes("占比") || item.titleZh.includes("率") || item.titleZh.includes("比例")
      );
    const numType = dataSourceMap
      .get(customType)
      ?.filter(
        (item) => !item.titleZh.includes("占比") && !item.titleZh.includes("率") && !item.titleZh.includes("比例")
      );
    numType.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.metricName + str;
      });
      if (findItem.length > 0) {
        copyDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setHasMetricIdDataSource(copyDataSource);
    const propCopyDataSource = [];
    propType.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.metricName + str;
      });
      if (findItem.length > 0) {
        propCopyDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setPropHasMetricIdDataSource(propCopyDataSource);
    setGetMetricIdEnd(true);
  };
  // 获得折线图列表
  // indexIdsArr循环的当次的frameWorkType
  const getChartData = async (
    searchParams: any,
    indexIdsArr: any,
    type: string
  ) => {
    let copyDataSource = [];
    copyDataSource = [...hasMetricIdDataSource, ...propHasMetricIdDataSource];
    const params = {
      ...searchParams,
      indexIds: indexIdsArr,
      indexType: type, // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        Object.keys(res?.data).forEach((item) => {
          copyDataSource.filter((i) => i.metricId === item)[0].value =
            res?.data?.[item];
        });
      }
      // yoy
      const paramsYoy = {
        ...searchParams,
        indexIds: indexIdsArr,
        indexType: "yoy",
      };
      const resYoy = await getGeneralChat(paramsYoy);
      if (resYoy?.code === 200) {
        Object.keys(resYoy?.data).forEach((item) => {
          copyDataSource.filter((i) => i.metricId === item)[0].yoy =
            resYoy?.data?.[item];
        });
        // mom
        const paramsMom = {
          ...searchParams,
          indexIds: indexIdsArr,
          indexType: "mom",
        };
        const resMom = await getGeneralChat(paramsMom);
        if (resMom?.code === 200) {
          if (resMom?.data) {
            Object.keys(resMom?.data).forEach((item) => {
              copyDataSource.filter((i) => i.metricId === item)[0].mom =
                resMom?.data?.[item];
            });
          }
          // dis折线图
          const paramsDis = {
            ...searchParams,
            // 迷你折线图：选择少于7天/周/月/年，但参数要传近7天/周/月/年，参数用paramStartDate和paramEndDate
            startDate: searchForm?.paramStartDate + starthms,
            endDate: searchForm?.paramEndDate + endhms,
            indexIds: indexIdsArr,
            indexType: "dis",
            isSupplyDate: searchForm?.isSupplyDate,
          };
          const resDis = await getGeneralChat(paramsDis);
          if (resDis?.code === 200) {
            Object.keys(resDis?.data).forEach((item) => {
              copyDataSource.filter(
                (i) => i.metricId === item
              )[0].miniChatData = resDis?.data?.[item];
            });
            // copyDataSource根据id从小到大排序
            const dataBySort = copyDataSource.sort((a, b) => {
              return a.id - b.id;
            });
            setDataSourceFinal([...dataBySort]);
          } else {
            message.error(res?.msg);
          }
        }
      }
    }
  };

  const download = async () => {
      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
    const params = {
      moduleName: `行业看板-人群细分-客户类型-${dayjs().format(
        "YYYYMMDDHHmmss"
      )}`,
      taskLocation: "行业看板/人群细分/客户类型",
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? channelAllList
            .filter((item) => item.value !== "all")
            .map((item) => item.value)
        : searchForm?.platformType?.split(","),
      shopIds: finalShopId,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
    };
    const res = await downLoadCustomerType(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <span>
            客户类型：
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-wrap" }}>
                  当客户类型选中新客或者老客时，当前页面的各个指标均按当前筛选时间范围进行数据统计及展示，点击指标对应的折线图时：
                  （1）如果筛选时间范围小于7天，以筛选时间范围的结束时间为基准，往前倒推7天，显示每天的指标数据统计。
                  （2）如果筛选时间范围大于等于7天，显示当前筛选时间范围的指标数据统计。
                </span>
              }
            >
              <QuestionCircleOutlined
                style={{
                  color: "var(--text-font-color4)",
                  fontSize: "var(--text-font5)",
                  margin: "0 8px 0 0px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </span>
          <Select
            options={customTypeOptions}
            style={{ width: 288 }}
            value={checkedCustomType}
            onChange={(e) => setCheckedCustomType(e)}
          ></Select>
        </div>
        <Button onClick={download}>导出</Button>
      </div>
      <div className={styles.items}>
        <OverviewItem dataSource={dataSourceFinal} searchForm={searchForm} />
      </div>
    </div>
  );
}

export default ChartByCustom;
