import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useRef } from 'react';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';
import { useNavigate, useLocation } from 'react-router-dom';
import { Snapline } from '@antv/x6-plugin-snapline'; //对齐线
import { Clipboard } from '@antv/x6-plugin-clipboard'; //复制粘贴
import { Selection } from '@antv/x6-plugin-selection'; //框选
import { History } from '@antv/x6-plugin-history'; //撤销重做
import { Keyboard } from '@antv/x6-plugin-keyboard'; //快捷键删除组件
import { Dnd } from '@antv/x6-plugin-dnd';
import { register } from '@antv/x6-react-shape';
import styles from './index.module.scss';
import { Button, Switch, message, Tag, Popover, Tabs, Modal, Tooltip, Col, Row, Collapse, Popconfirm } from 'antd';
import type { MenuProps, CollapseProps } from 'antd';
import ModuleTitle from '../../../../component/ModuleTitle';
import { QuestionCircleOutlined, CaretRightOutlined } from '@ant-design/icons';
import YLIcon from '../../../../component/Icon/YLIcon.jsx';
import { LeftOutlined, RightOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import TemplateGraph from '../../component/strategy/template';
import busEvent from '@/utils/bus';
import NodesPopup from '../../component/NodesPopup/index';
import DataIndicators from '../../component/dataIndicators/index';
import SettingTab from '../../component/settingTab/index';
import TestRecord from '../../component/settingTab/component/testRecord';
import ScanTab from '../../component/scanTab/index';
import RunningTab from '../../component/runningTab/index';
import { motion } from 'framer-motion';
// #region 接口api引入
import { saveCanvas, deployCanvas, getCanvas, getUserPath, strategiesCancel, strategiesStop } from '../../../../api/graph/graph';
import bus from '@/utils/bus';
import dayjs from 'dayjs';
import { bottom } from '@antv/x6/lib/registry/port-layout/line';

const imageShapes = [
  {
    row: '1',
    type: '2',
    componentId: 'SMS',
    borderColor: '#d3adf7',
    color: '#722ed1',
    backColor: '#f9f0ff',
    label: '短信',
    icon: 'icon-duanxin',
    hoverBg: '#EFDBFF',
    hoverBorder: '#B37FEB',
  },
  {
    row: '2',
    type: '2',
    componentId: 'JOIN_GROUP',
    color: '#2f54eb',
    borderColor: '#adc6ff',
    backColor: '#f0f5ff',
    label: '加入分群',
    icon: 'icon-jiarufenqun',
    hoverBg: '#D6E4FF',
    hoverBorder: '#85A5FF',
  },
  {
    row: '3',
    type: '2',
    componentId: 'SPLITTER',
    color: '#52c41a',
    borderColor: '#b7eb8f',
    backColor: '#f6ffed',
    label: '分流器',
    icon: 'icon-fenliuqi',
    hoverBg: '#D9F7BE',
    hoverBorder: '#95DE64',
  },
  {
    row: '3',
    type: '2',
    componentId: 'WAIT',
    color: '#52c41a',
    borderColor: '#b7eb8f',
    backColor: '#f6ffed',
    label: '等待',
    icon: 'icon-dengdai',
    hoverBg: '#D9F7BE',
    hoverBorder: '#95DE64',
  },
  {
    row: '3',
    type: '2',
    componentId: 'END',
    color: '#1e1e1e',
    borderColor: '#d0d0d0',
    backColor: '#f5f5f5',
    label: '结束',
    icon: 'icon-jieshu',
    hoverBg: '#E4E4E4',
    hoverBorder: '#7D7D7D',
  },
];
const { confirm } = Modal;
let copyNode = null as any; //复制的节点信息
const nodeOffset = 0; //节点和加号节点的x方向差值11
const edgeExpendNum = 150; //再分叉节点下方存在分叉节点时，扩大的值
const multiBranchedgeNum = 300;
const edgeLength1 = 130; //每个节点的边的长度,因为加号节点和组件节点的大小不同，加号节点伸出的边长
const edgeLength2 = 110; //每个节点的边的长度，组件节点伸出的边短
let operationUpdatedTime: any = ''; //节点tooltip里的操作更新时间
let graphId: any = ''; //保存之后返回的id
let graphStatusNewLocal: any = ''; //画布状态
let undoBranchEdge: any = false;
//在中间插入节点时，后续节点的位置重新计算
const calcPosition = (node: any, offsetX: number, offsetY: number) => {
  node.position(node.position().x + offsetX, node.position().y + offsetY);
  const afterNodes = graph.getSuccessors(node, { breadthFirst: true });
  console.log('calcPosition', afterNodes);
  afterNodes.map((item: any, index: any) => {
    item.position(item.position().x + offsetX, item.position().y + offsetY);
  });
};

const getOutgoingEdges = (node: any) => {
  let edges = graph.getOutgoingEdges(node);
  let result: any = [];
  if (edges && edges[0].data && edges[0].data.sortIndex) {
    //分流器排序
    result = edges.sort((a: any, b: any) => {
      return a.data.sortIndex - b.data.sortIndex;
    });
  } else {
    result = edges;
  }
  return result;
};
//分支上有多分支节点的时候，需要扩大x的位置
const changePosition1 = (node: any, type: any, num?: any) => {
  console.log('调整位置', node);
  const predecessors = graph.getPredecessors(node);
  let findNodes = predecessors.filter((item: any) => item && getOutgoingEdges(item)?.length > 1);
  console.log(findNodes);
  function a(findNode: any) {
    let movedNode: any = [];
    let outgoingEdges = getOutgoingEdges(findNode);
    let length = outgoingEdges.length;
    let currentIndex = 0;
    outgoingEdges.forEach((e: any, index: any) => {
      if (graph.isPredecessor(node, graph.getCellById(e.target.cell)) || node === graph.getCellById(e.target.cell)) {
        //如果不是该分支上的前序节点，则移动这个节点分支的位置
        currentIndex = index;
      }
    });
    console.log('movedNode---', currentIndex, length);
    outgoingEdges.forEach((e: any, index: any) => {
      if (currentIndex === 0) {
        if (index > 0) {
          movedNode.push(graph.getCellById(e.target.cell));
        }
      } else if (currentIndex === length - 1) {
        if (index < length - 1) {
          movedNode.push(graph.getCellById(e.target.cell));
        }
      } else {
        if (index !== currentIndex) {
          movedNode.push(graph.getCellById(e.target.cell));
        }
        // movedNode.push(graph.getCellById(graph.getOutgoingEdges(findNode)[currentIndex - 1].target.cell));
        // movedNode.push(graph.getCellById(graph.getOutgoingEdges(findNode)[currentIndex + 1].target.cell));
      }
    });
    console.log('movedNode---', movedNode);
    movedNode.map((item: any) => {
      let offsetX: any = 0;
      if (item.position().x > node.position().x) {
        offsetX = edgeExpendNum * (num > 1 ? num : 1);
        if (type === 'delete') {
          offsetX = -edgeExpendNum * (num > 1 ? num : 1);
        }
      } else {
        offsetX = -edgeExpendNum * (num > 1 ? num : 1);
        if (type === 'delete') {
          offsetX = edgeExpendNum * (num > 1 ? num : 1);
        }
      }
      const afterNodes = graph.getSuccessors(item);
      item.position(item.position().x + offsetX, item.position().y);
      afterNodes.map((item: any) => {
        item.position(item.position().x + offsetX, item.position().y);
      });
    });
  }
  if (findNodes.length > 0) {
    findNodes.map((item: any, index: number) => {
      a(findNodes[index]);
    });
  }
};
var intervalId: any = null;
var intervalIds: any[] = [];
// 定时器,每隔一秒小球重复运动
const getRoundAnimation = (item: any) => {
  item.attrs.c1.atConnectionRatio = 0;
  item.transition('attrs/c1/atConnectionRatio', 0.9, {
    delay: 0,
    duration: 2000,
    timing: Timing.linear,
  });
  intervalId = setInterval(() => {
    item.attrs.c1.atConnectionRatio = 0;
    item.transition('attrs/c1/atConnectionRatio', 0.9, {
      delay: 0,
      duration: 2000,
      timing: Timing.linear,
    });
  }, 2000);
  intervalIds.push(intervalId);
};
// 清除小球重复运动
const stopRoundAnimation = () => {
  intervalIds.forEach(id => clearInterval(id));
  intervalIds.length = 0;
};
class TooltipTool extends ToolsView.ToolItem<EdgeView, TooltipToolOptions> {
  private knob: HTMLDivElement = null!;
  private timer: any = null;
  private type: string = 'normal';
  // private divRef = useRef(null);

  render() {
    if (!this.knob) {
      this.knob = ToolsView.createElement('div', false) as HTMLDivElement;
      this.knob.style.position = 'absolute';
      this.container.appendChild(this.knob);
    }
    // const div: any = this.divRef.current;
    // if (div) {
    //   div.addEventListener('mouseenter', this.handleMouseEnter);
    //   div.addEventListener('mouseleave', this.handleMouseLeave);
    // }
    return this;
  }

  private content = (
    <div
      onMouseEnter={e => {
        e.stopPropagation();
        this.popoverEnter();
      }}
      style={{ width: '100%', height: 32, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      onMouseLeave={e => {
        e.stopPropagation();
        this.popoverLeave();
      }}>
      {this.options.tooltip === 'node' ? (
        <>
          <Tooltip title="复制">
            <div onClick={this.onCopy} className={styles['node-icon']}>
              <YLIcon type="icon-fuzhi" color={'var(--text-font-color11)'} size={16}></YLIcon>
            </div>
          </Tooltip>
          <Tooltip title="替换">
            <div onClick={this.onReplace} className={styles['node-icon']}>
              <YLIcon type="icon-tihuan" color={'var(--text-font-color11)'} size={16}></YLIcon>
            </div>
          </Tooltip>
          <Tooltip title="删除">
            <div onClick={this.onDelete} className={styles['node-icon']}>
              <YLIcon type="icon-shanchu" color={'var(--text-font-color11)'} size={16}></YLIcon>
            </div>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title="复制后续节点">
            <div onClick={this.onCopyAfter} className={this.options.tooltip === 'midAdd' ? styles['node-icon'] : styles['node-icon-disable']}>
              <YLIcon
                type="icon-fuzhi"
                color={this.options.tooltip === 'midAdd' ? 'var(--text-font-color11)' : 'var(--text-font-color25)'}
                size={16}></YLIcon>
            </div>
          </Tooltip>
          <Tooltip title="粘贴">
            <div onClick={this.onPaste} className={styles['node-icon']}>
              <YLIcon type="icon-niantie" color={'var(--text-font-color11)'} size={16}></YLIcon>
            </div>
          </Tooltip>
          <Tooltip title="剪断">
            <div onClick={this.onCut} className={this.options.tooltip === 'midAdd' ? styles['node-icon'] : styles['node-icon-disable']}>
              <YLIcon
                type="icon-jianduan"
                color={this.options.tooltip === 'midAdd' ? 'var(--text-font-color11)' : 'var(--text-font-color25)'}
                size={16}></YLIcon>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
  private onReplace() {
    //打开选择节点弹窗,替换当前节点
    const cell = graph.getSelectedCells()[0];
    replaceNodeInfo = cell;
    busEvent.emit('openPopup', 2);
  }
  private onDelete() {
    const cell = graph.getSelectedCells()[0];
    //删除该节点和与其连接的加号
    const incomingEdge = graph.getIncomingEdges(cell)[0];
    const outgoingEdges = getOutgoingEdges(cell);
    const sourceNode = graph.getCellById(incomingEdge.source.cell);
    console.log('删除', cell, outgoingEdges);
    graph.startBatch('custom-batch-name');
    let removeCells: any = [];
    //该节点存入数组
    removeCells.push(cell.id);
    function cutNode(node: any) {
      if (getOutgoingEdges(node) && getOutgoingEdges(node).length > 0) {
        const edge = getOutgoingEdges(node)[0];
        removeCells.push(edge.target.cell);
        cutNode(edge.target.cell);
      }
    }
    // 获取当前删除的节点的所有前序节点 backNode 不为空就代表前序节点有分流器
    const backAllNode = graph.getPredecessors(cell);
    var backNode = [] as any;
    //是否有分叉节点
    backAllNode.forEach((item: any, index: number) => {
      if (getOutgoingEdges(item) && getOutgoingEdges(item).length > 1) {
        backNode.push(item);
      }
    });

    //当前序节点有分流器 并且 当前节点也是分流器 就改变位置
    if (backNode.length > 0 && getOutgoingEdges(cell) && getOutgoingEdges(cell).length > 1) {
      changePosition1(cell, 'delete', getOutgoingEdges(cell).length - 1);
    }
    if (outgoingEdges?.length > 1) {
      //分叉节点，删除时只保留最左侧下面的节点树，其他都删掉
      outgoingEdges.forEach((item: any, index: any) => {
        if (index > 0) {
          removeCells.push(item.target.cell);
          cutNode(graph.getCellById(item.target.cell));
          //将 后续节点的子节点全部删除
          const nextAllNode = graph.getSuccessors(item);
          graph.removeCells(nextAllNode);
        }
      });
    }
    //如果是结束按钮删除
    if (outgoingEdges) {
      //该节点下方的第一个加号节点存入数组
      removeCells.push(outgoingEdges[0].target.cell);
      //找到第一个加号节点下方的节点树，链接到该节点上面的加号节点
      let next = getOutgoingEdges(outgoingEdges[0].target.cell);
      if (next && next.length > 0) {
        graph.addEdge({
          shape: 'edge',
          source: incomingEdge.source.cell,
          target: next[0].target.cell,
          attrs: {
            line: {
              stroke: '#A2B1C3',
              strokeWidth: 2,
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
              },
            },
          },
        });

        const targetNode = graph.getCellById(next[0].target.cell);
        let offsetY = sourceNode.position().y + edgeLength2 - targetNode.position().y;
        let offsetX = sourceNode.position().x - nodeOffset - targetNode.position().x;
        calcPosition(targetNode, offsetX, offsetY);
        // targetNode.position(sourceNode.position().x - nodeOffset, sourceNode.position().y + 100);
        // setPosition(targetNode, targetNode.position().y);
      }
    }

    graph.removeCells(removeCells);
    if (!getOutgoingEdges(sourceNode) || getOutgoingEdges(sourceNode).length === 0) {
      const data = {
        label: '添加',
        componentId: 'add',
      };
      //加号节点上方的边加上箭头
      const edge = graph.getIncomingEdges(sourceNode);
      edge[0].setAttrs({
        line: {
          targetMarker: {
            name: 'block',
            width: 12,
            height: 8,
            size: 10,
          },
        },
      });
      sourceNode.setData(data, { overwrite: true });
      sourceNode.removeTool('tooltip');
      sourceNode.addTools({
        name: 'tooltip',
        args: {
          tooltip: 'add',
        },
      });
    }
    // 配置项tab 显示开始节点信息
    busEvent.emit('checkStartNode');
    //删除节点时 自动走 扫描
    busEvent.emit('scanTabBegin', graph.getNodes());
    graph.stopBatch('custom-batch-name');
    //更新操作时间
    operationUpdatedTime = dayjs();
  }
  private onCopy() {
    const cells = graph.getSelectedCells();
    if (cells && cells.length) {
      let nodes = cells;
      const outgoingEdge = getOutgoingEdges(cells[0]);
      outgoingEdge?.forEach((item: any) => {
        nodes.push(graph.getCellById(item.target.cell));
      });
      //将复制的节点存住
      copyNode = cells;
      graph.copy(nodes, options);
      message.success('复制成功');
    } else {
      message.info('请先选中节点再复制');
    }
  }
  private onCopyAfter() {
    //复制后续节点
    const cell = graph.getSelectedCells()[0];
    const afterNodes = graph.getSuccessors(cell, { breadthFirst: true });
    if (afterNodes && afterNodes.length) {
      graph.copy(afterNodes, options);
      message.success('复制成功');
    } else {
      message.info('后续无节点');
    }
  }
  private onPaste() {
    if (graph.isClipboardEmpty()) {
      message.info('剪切板为空，不可粘贴');
    } else {
      graph.startBatch('custom-batch-name');
      //此刻选择粘贴的加号位置
      const selectCells = graph.getSelectedCells();
      //当节点是中间添加  并且粘贴的节点是结束 return
      if (selectCells[0].data.label === '中间添加' && copyNode && copyNode[0]?.data.componentId === 'END') {
        return;
      }
      //粘贴，返回粘贴到画布的节点/边
      const pasteCells = graph.paste(options);
      let nodeY = selectCells[0].position().y;
      let nodeX = selectCells[0].position().x;
      //粘贴的新节点的位置
      let offsetY = nodeY + edgeLength2 - pasteCells[0].position().y;
      let offsetX = nodeX - nodeOffset - pasteCells[0].position().x;
      let lastNode: any = {};

      const preNode = graph.getCellById(graph.getIncomingEdges(selectCells[0])[0].source.cell);
      const updateData = {
        ...pasteCells[0].data,
        preNode: preNode.data.label,
      };
      pasteCells[0].setData(updateData, { overwrite: true });
      console.log('pasteCells', pasteCells);
      pasteCells.map((item: any, index: any) => {
        if (item.shape !== 'edge') {
          item.position(item.position().x + offsetX, item.position().y + offsetY);
          if (item.data.label.indexOf('添加') !== -1) {
            lastNode = item;
            let leafs = getOutgoingEdges(graph.getIncomingEdges(item)[0].source.cell);
            if (leafs && leafs.length > 1) {
              leafs.forEach((item2: any) => {
                let node = graph.getCellById(item2.target.cell);
                if (node.position().x < item.position().x) {
                  lastNode = node;
                }
              });
            }
          }
        }
      });
      if (selectCells[0].data.label === '中间添加') {
        //说明选择粘贴的加号节点后面还有后续节点，要删除连线，把复制的节点穿插进来，再把最后一个节点跟后续节点连上
        if (getOutgoingEdges(lastNode)) {
          //说明有结束节点
          let end = getOutgoingEdges(lastNode)[0].target.cell;
          graph.removeCell(end);
        }
        graph.addEdge({
          shape: 'edge',
          source: lastNode,
          target: getOutgoingEdges(selectCells[0])[0].target.cell,
          attrs: {
            line: {
              stroke: '#A2B1C3',
              strokeWidth: 2,
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
              },
            },
          },
        });
        const targetNode = graph.getCellById(getOutgoingEdges(selectCells[0])[0].target.cell);
        const updateData1 = {
          ...targetNode.data,
          preNode: lastNode.data.label,
        };
        targetNode.setData(updateData1, { overwrite: true });

        let offsetY = lastNode.position().y + edgeLength2 - targetNode.position().y;
        let offsetX = lastNode.position().x - nodeOffset - targetNode.position().x;
        calcPosition(targetNode, offsetX, offsetY);
        // targetNode.position(lastNode.position().x - nodeOffset, lastNode.position().y + 100);
        // setPosition(targetNode, targetNode.position().y);
        graph.removeEdge(getOutgoingEdges(selectCells[0])[0].id);
        const data = {
          label: '中间添加',
          componentId: 'add',
        };
        lastNode.setData(data, { overwrite: true });
        lastNode.removeTools();
        lastNode.addTools({
          name: 'tooltip',
          args: {
            tooltip: 'midAdd',
          },
        });
      }
      //此刻选择粘贴的加号和复制的节点数组的第一个节点连线
      graph.addEdge({
        shape: 'edge',
        source: selectCells[0],
        target: pasteCells[0],
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
            targetMarker: {
              name: 'block',
              width: 12,
              height: 8,
            },
          },
        },
      });
      //原来的添加节点改为中间添加
      const data = {
        label: '中间添加',
        componentId: 'add',
      };
      selectCells[0].setData(data, { overwrite: true });
      selectCells[0].removeTools();
      selectCells[0].addTools({
        name: 'tooltip',
        args: {
          tooltip: 'midAdd',
        },
      });
      var arr = [] as any;
      //后续添加的节点 是否有分叉节点
      pasteCells.forEach((item: any, index: number) => {
        if (item.shape !== 'edge' && getOutgoingEdges(item) && getOutgoingEdges(item).length > 1) {
          arr.push(item);
        }
      });
      // const arr = pasteCells.filter((item: any) => item.shape !== 'edge' && item.data.componentId === 'SPLITTER');
      arr.forEach((item: any) => {
        changePosition1(item, 'paste');
      });

      const leafNodes = graph.getLeafNodes();
      leafNodes.forEach((e: any) => {
        if (e.data.label === '中间添加') {
          const data = {
            label: '添加',
            componentId: 'add',
          };
          e.setData(data, { overwrite: true });
          e.removeTool('tooltip');
          e.addTools({
            name: 'tooltip',
            args: {
              tooltip: 'add',
            },
          });
        }
      });

      graph.cleanSelection();
      busEvent.emit('checkStartNode', settingNodeInfoLocal);
      // message.success('粘贴成功');
      graph.stopBatch('custom-batch-name');
      //更新操作时间
      operationUpdatedTime = dayjs();
    }
  }

  private onCut() {
    //剪断下面所有节点和线
    const cells = graph.getSelectedCells();
    const removeCells = graph.getSuccessors(cells[0]);
    graph.startBatch('custom-batch-name');
    // 获取当前剪断节点的所有前序节点 backNode 不为空就代表前序节点有分流器
    const backAllNode = graph.getPredecessors(cells[0]);
    //判断下方有几个分流器节点 100 * nextNodede 的长度
    const nextNode = removeCells.filter(
      (item: any) => item.data.componentId === 'SPLITTER' || (item.data.componentId === 'WAIT' && item.data.type === 'until')
    );
    const backNode = backAllNode.filter(
      (item: any) => item.data.componentId === 'SPLITTER' || (item.data.componentId === 'WAIT' && item.data.type === 'until')
    );
    if (backNode.length > 0 && nextNode.length > 0) {
      changePosition1(cells[0], 'delete', nextNode.length);
    }
    graph.removeCells(removeCells);
    const data = {
      label: '添加',
      componentId: 'add',
    };
    cells[0].setData(data, { overwrite: true });
    cells[0].removeTool('tooltip');
    cells[0].addTools({
      name: 'tooltip',
      args: {
        tooltip: 'add',
      },
    });
    busEvent.emit('checkStartNode');
    graph.stopBatch('custom-batch-name');
    //更新操作时间
    operationUpdatedTime = dayjs();
  }
  private popoverEnter() {
    clearTimeout(this.timer);
    // if (this.type === '') {
    //   this.type = 'onPop'
    //   console.log('移入');
    //   clearTimeout(this.timer)
    //   this.timer = null
    //   this.toggleTooltip(true);
    // }
  }

  private popoverLeave() {
    this.toggleTooltip(false);
    // if (this.type === 'onPop') {
    //   this.type = ''
    //   console.log('移出');
    //   // this.toggleTooltip(false);
    // }
  }

  private toggleTooltip(visible: boolean) {
    if (this.knob) {
      ReactDom.unmountComponentAtNode(this.knob);
      if (visible) {
        ReactDom.render(
          <Popover overlayInnerStyle={{ width: 80, height: 32, padding: '0 8px' }} content={this.content} open={true}></Popover>,
          // <Tooltip title={this.content} color={'#ffffff'} overlayStyle={{ padding: 0 }} destroyTooltipOnHide></Tooltip>
          this.knob
        );
      }
    }
  }

  private onMouseEnter({ e, node }: { e: MouseEvent; node: any }) {
    console.log('onMouseEnter', node);
    graph.resetSelection(node);
    clearTimeout(this.timer);
    this.updatePosition(node);
    this.toggleTooltip(true);
  }

  private onMouseLeave() {
    // this.updatePosition();
    this.timer = setTimeout(() => {
      this.toggleTooltip(false);
    }, 500);
  }

  private onMouseMove() {
    // this.updatePosition();
    this.toggleTooltip(false);
  }

  delegateEvents() {
    this.cellView.on('cell:mouseenter', this.onMouseEnter, this);
    this.cellView.on('cell:mouseleave', this.onMouseLeave, this);
    // this.cellView.on('cell:mousemove', this.onMouseMove, this);
    return super.delegateEvents();
  }

  private updatePosition(e?: any) {
    const style = this.knob.style;
    if (e) {
      let p1 = JSON.parse(JSON.stringify(e.store.data.position));
      p1.x += e.store.data.size.width / 2;
      const p2 = graph.localToGraph(p1);
      console.log(p2);
      style.display = 'block';
      style.left = `${p2.x}px`;
      style.top = `${p2.y}px`;
    } else {
      style.display = 'none';
      style.left = '-1000px';
      style.top = '-1000px';
    }
  }

  protected onRemove() {
    this.toggleTooltip(false);
    this.cellView.off('cell:mouseenter', this.onMouseEnter, this);
    this.cellView.off('cell:mouseleave', this.onMouseLeave, this);
    // this.cellView.off('cell:mousemove', this.onMouseMove, this);
  }
}

TooltipTool.config({
  tagName: 'div',
  isSVGElement: false,
});

export interface TooltipToolOptions extends ToolsView.ToolItem.Options {
  tooltip?: string;
}

Graph.registerNodeTool('tooltip', TooltipTool, true);
// Graph.registerEdgeTool('tooltip', TooltipTool, true);
// Graph.registerNodeTool('popOver', popOverTool, true);

const options = {
  offset: { dx: 0, dy: 0 },
  useLocalStorage: true,
};
let graph: Graph | any = null;
let dnd: Dnd | any = null;
let container: HTMLDivElement | any = null;
let dndContainer: HTMLDivElement | any = null;

// #region 自定义节点
Graph.registerNode(
  'custom-image2',
  {
    inherit: 'rect',
    width: 40,
    height: 40,
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'image',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      body: {
        stroke: '#c8ced9', //边的颜色
        fill: '#cccccc', //填充颜色
      },
      image: {
        //图片的位置大小
        width: 22,
        height: 22,
        refX: 9,
        refY: 9,
      },
      label: {
        //文案的位置
        refX: 45,
        refY: 20,
        textAnchor: 'left',
        textVerticalAnchor: 'top',
        fontSize: 12,
        fill: '#1a263b',
      },
    },
  },
  true
);
Graph.registerNode(
  'custom-add',
  {
    inherit: 'rect',
    width: 40,
    height: 18,
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'rect',
        selector: 'content',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
    attrs: {
      body: {
        width: 40, // 长方形宽度
        height: 18, // 长方形高度
        fill: 'transparent',
        strokeWidth: 0,
      },
      content: {
        width: 18, // 长方形宽度
        height: 18, // 长方形高度
        refX: 11,
        strokeWidth: 1,
        fill: '#fff',
        stroke: '#9ca8b4',
        strokeDasharray: '3,3', // 虚线样式
        rx: 3, // 水平方向圆角半径
        ry: 3, // 垂直方向圆角半径
        cursor: 'pointer',
      },
      label: {
        //文案的位置
        text: '+',
        textAnchor: 'middle',
        textVerticalAnchor: 'middle',
        fontSize: 18,
        fill: '#9ca8b4',
      },
    },
  },
  true
);

const nodeComponent = ({ node }: any) => {
  const attrs = node.prop('attrs');
  return (
    <div
      className={styles['node-component']}
      style={{
        background: attrs.label.backgroundColor,
        color: attrs.label.color,
        borderColor: attrs.label.textError ? '#FF4D4F' : attrs.label.borderColor,
      }}>
      <YLIcon className={styles['menu-icon']} color={attrs.label.color ?? 'var(--text-font-color33)'} size={24} type={attrs.label.icon}></YLIcon>
      {/* <Iconfont className={styles['menu-icon']} icon={attrs.label.icon}></Iconfont> */}
      <div className={styles['node-component-text']} style={{ display: attrs.label.show, color: attrs.label.textError ? '#FF4D4F' : '#000000' }}>
        {attrs.label.text}
      </div>
    </div>
  );
};
// 计算分流器边上工具 总宽度和高度
function calculateTextSize(text: string, fontSize: number): { width: string; height: number } {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = `${fontSize}px sans-serif`;
  const metrics = context.measureText(text);
  const lineHeight = fontSize * 1.2; // 约等于行高

  return {
    width: `${Math.ceil(metrics.width)}px`,
    height: Math.ceil(lineHeight),
  };
}
//流量配比
function numPercent(edgeData?: any) {
  // const branchName = edgeData.shuntObj?.branch[0]?.name || '分支2';
  // const branchPercent = edgeData.shuntObj?.branch[0]?.percent || '50%';
  const branchName = edgeData.branchName;
  const branchPercent = edgeData.branchPercent;
  const fontSize = 10;
  const maxCharsPerLine = 10;

  // 将 branchName 分成多行
  const lines = branchName.split('').reduce((acc: any, char: any, index: any) => {
    if (index % maxCharsPerLine === 0) {
      acc.push(branchName.slice(index, index + maxCharsPerLine));
    }
    return acc;
  }, [] as string[]);

  // 计算每行文本的大小
  const lineSizes = lines.map((line: any) => calculateTextSize(line, fontSize));

  // 计算总宽度和高度
  const percentWidth = calculateTextSize(branchPercent, fontSize).width.replace('px', '');
  const totalWidth = Math.max(...lineSizes.map((size: any) => size.width.replace('px', ''))) + parseInt(percentWidth) + 10; // 加上一定的间隔
  const totalHeight = lineSizes.reduce((acc: any, curr: any) => acc + curr.height, 0) + 12; // 加上一定的间隔

  return {
    name: 'button',
    args: {
      markup: [
        {
          tagName: 'ellipse',
          selector: 'button',
          attrs: {
            cx: 0,
            cy: 0,
            rx: 20,
            ry: 10,
            strokeWidth: 2,
            fill: 'none',
            cursor: 'pointer',
          },
        },
        {
          tagName: 'rect',
          attrs: {
            x: -20,
            y: -11,
            // x: -totalWidth / 5,
            // y: -totalHeight / 5,
            width: totalWidth,
            height: totalHeight,
            rx: 5, // 水平方向圆角半径
            ry: 5, // 垂直方向圆角半径
            fill: 'rgba(0, 0, 0, 0.65)',
          },
        },
        {
          tagName: 'text',
          selector: 'icon',
          attrs: {
            fill: '#ffffff',
            fontSize: 10,
            textAnchor: 'START', // 文本靠左对齐
            dominantBaseline: 'central', // 垂直居中
            pointerEvents: 'none',
            y: '3px',
          },
          children: [
            ...lines.map((line: any, index: any) => ({
              tagName: 'tspan',
              textContent: line,
              attrs: {
                x: -16,
                y: -12,
                dy: `${(index + 1) * fontSize * 1.2}px`, // 换行间距
              },
            })),
            {
              tagName: 'tspan',
              textContent: `: `,
            },
            {
              tagName: 'tspan',
              textContent: `${branchPercent}`,
            },
          ],
        },
      ],
      distance: -40,
      offset: { x: 20, y: -30 },
    },
  };
}
//等待
function waitingText(edgeData: any) {
  return {
    name: 'button',
    data: {
      parentId: edgeData.parentId,
    },
    args: {
      markup: [
        {
          tagName: 'rect',
          selector: 'button',
          attrs: {
            x: -24, // 左上角 x 坐标
            y: -12, // 左上角 y 坐标
            width: 48, // 长方形宽度
            height: 24, // 长方形高度
            rx: 5, // 水平方向圆角半径
            ry: 5, // 垂直方向圆角半径
            strokeWidth: 1,
            stroke: edgeData.branchName === '准时' ? '#B7EB8F' : '#FFA39E', // 边框颜色
            fill: edgeData.branchName === '准时' ? '#f6ffed' : '#fff1f0',
            cursor: 'pointer',
          },
        },
        {
          tagName: 'text',
          selector: 'icon',
          attrs: {
            fill: edgeData.branchName === '准时' ? '#52c41a' : '#ff4d4f',
            fontSize: 12,
            textAnchor: 'middle', // 水平居中
            dominantBaseline: 'central', // 垂直居中
            pointerEvents: 'none',
            y: '-12px',
          },
          children: [
            {
              tagName: 'tspan',
              textContent: edgeData.branchName,
              attrs: {
                dy: '1em', // 换行间距
              },
            },
          ],
        },
      ],
      distance: -42,
      offset: { x: 0, y: edgeData.branchName === '准时' ? '-40' : '40' },
    },
  };
}
let dragNodeInfo: any = {}; //拖拽生成的节点
let updateNodeInfo: any = {}; //单击用来保存节点
let replaceNodeInfo: any = {}; //保存替换前的节点
let dragFlag: any = false;
let allAddNodes: any = []; //所有的添加节点
let popShow = false; //弹窗状态  true为展开
let popNum = 0; //鼠标离开的次数
let dragNode: any = null;
let isDragging = false;
let startNode: any = {}; //开始节点
let settingNodeInfoLocal: any = '';
let publishScan = false; //是直接点的扫描还是发布的时候执行的扫描
let girdStatus: any = true;
let returnData: any = {}; //详情接口返回的数据
let deleteBranchList: any = [];
let deleteIndex: any = '';
let autoSaveIntervalId: any = null;
// #region初始化图形
const MAStrategy = () => {
  const navigate = useNavigate();
  const dragRef = useRef<any>(null);
  const settingTabRef = useRef<any>(null);
  const runningTabRef = useRef<any>(null);
  const [canRedo, setCanRedo] = useState(false);
  const [graphStatusNew, setGraphStatusNew] = useState(null as any); //临时状态
  const [fileName, setFileName] = useState(null as any); //页面 文件名称
  const [canUndo, setCanUndo] = useState(false);
  const [testRecordShow, setTestRecordShow] = useState(false); //测试记录
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showText, setShowText] = useState(true);
  const [isArrowLeft, setIsArrowLeft] = useState(true);
  const [isArrowRight, setIsArrowRight] = useState(true);
  const [nodesPanelShow, setNodesPanelShow] = useState(true);
  const [countToolShow, setCountToolShow] = useState(false); //点击数字工具 出现数据详情
  const [settingPanelShow, setSettingPanelShow] = useState(true);
  const [zoomPercent, setZoomPercent] = useState(100);
  const [allNodes, setAllNodes] = useState([] as any);
  const [activeKey, setActiveKey] = useState('1' as any);
  const [settingNodeInfo, setSettingNodeInfo] = useState<any>(null);
  const [canvasData, setCanvasData] = useState<any>('');
  const [autoSave, setAutoSave] = useState(false);
  const [timestamp, setTimestamp] = useState<any>(null);
  const [updatedTime, setUpdatedTime] = useState<any>('');
  // const [windowHeight, setWindowHeight] = useState(Number(window.innerHeight - 56));
  const windowHeight: any = Number(window.innerHeight - 56);
  let location = useLocation();
  let waitingType = null as any;
  const nameMap: any = {
    start: '开始',
    SMS: '短信',
    JOIN_GROUP: '加入分群',
    SPLITTER: '分流器',
    WAIT: '等待',
  };
  const defaultShuntBranch = [
    {
      name: '分支1',
      value: 50,
    },
    {
      name: '分支2',
      value: 50,
    },
  ];
  //人群数字工具
  function crowdCountTool(value: any, distance: any) {
    //边上工具，查看数量的一个按钮
    return {
      name: 'button',
      args: {
        markup: [
          {
            tagName: 'rect',
            selector: 'button',
            attrs: {
              x: -16, // 左上角 x 坐标
              y: -12, // 左上角 y 坐标
              rx: 5, // 水平方向圆角半径
              ry: 5, // 垂直方向圆角半径
              width: 32, // 长方形宽度
              height: 24, // 长方形高度
              fill: 'rgba(0, 0, 0, 0.65)', // 透明黑色
              cursor: 'pointer',
            },
          },
          {
            tagName: 'text',
            textContent: value,
            selector: 'icon',
            attrs: {
              fill: '#ffffff',
              fontSize: 10,
              textAnchor: 'middle',
              pointerEvents: 'none',
              y: '3px',
            },
          },
        ],
        distance,
        offset: { x: 0, y: 0 },
        onClick({ view }: any) {
          //待启动状态不可以点击数字
          if (graphStatusNewLocal === '1') {
            return;
          }
          const edge = view.cell;
          let extrData = { edgeTaskId: edge.data.taskId, createTime: returnData.createTime };
          let edgeNode = graph.getCellById(edge.target.cell);
          edgeNode.data = {
            ...edgeNode.data,
            ...extrData,
          };
          setSettingNodeInfo(edgeNode);
          settingNodeInfoLocal = edgeNode;
          setSettingPanelShow(true);
          setCountToolShow(true);
          lookLine({ lineFlag: false });
          // runningTabRef.current?.getUserDetailList({ pageSize: 10, pageNum: 1 });
          // busEvent.emit('onSettingTab', settingNodeInfo);
        },
      },
    };
  }
  const defaultLabel = (value: any) => {
    return {
      markup: [
        {
          tagName: 'ellipse',
          selector: 'labelBody',
        },
        {
          tagName: 'text',
          selector: 'labelText',
        },
      ],
      attrs: {
        labelText: {
          text: value,
          fill: '#fff',
          textAnchor: 'middle',
          textVerticalAnchor: 'middle',
        },
        labelBody: {
          ref: 'labelText',
          refRx: '80%',
          refRy: '80%',
          stroke: '#8e98c1',
          fill: '#8e98c1',
          strokeWidth: 2,
        },
      },
      position: {
        distance: 0.7,
      },
      onClick({ view }: any) {
        const edge = view.cell;
        setSettingNodeInfo(graph.getCellById(edge.target.cell));
        settingNodeInfoLocal = graph.getCellById(edge.target.cell);
        console.log(111, settingNodeInfoLocal);
        // busEvent.emit('onSettingTab', settingNodeInfo);
      },
    };
  };
  // #region 自定义节点
  register({
    shape: 'custom-node',
    width: 40,
    height: 40,
    effect: ['attrs'],
    component: nodeComponent,
  });
  useEffect(() => {
    graphStatusNewLocal = location.state.graphStatus;
    setFileName(location.state.fileName);
    setGraphStatusNew(graphStatusNewLocal);
    if (!graphStatusNewLocal || graphStatusNewLocal === '0') {
      console.log('新建');
      createGraph();
    } else {
      setSettingPanelShow(false);
      setIsArrowRight(false);
      console.log('查看');
      initGraph();
    }
    const handleLookLine = (info: any) => {
      console.log('onLookLine', info);
      lookLine(info);
    };
    busEvent.on('onLookLine', handleLookLine);
    const handleAddLine = (index: any) => {
      deleteIndex = index;
      console.log('onAddLine', deleteIndex);
      //携带index代表删除分支
      addLine(deleteIndex);
    };
    busEvent.on('onWaitingType', changeWaitingType);
    busEvent.on('onAddLine', handleAddLine);
    const handleUpdateNodeInfo = (info: any) => {
      //接收配置完的节点信息
      console.log('接收配置完的节点信息', info);

      graph.disableHistory();
      settingNodeInfoLocal.setData(info, { overwrite: true });
      settingNodeInfoLocal.setAttrs({
        label: { text: info.label },
      });
      setSettingNodeInfo(settingNodeInfoLocal);
      if (info.flag === 1) {
        //一进入创建页面获取到策略之后，就调一次保存接口，为了获取canvasid给查看测试记录用
        onCheck('draft');
      }
      //添加分支和删除分支的时候 需要先把分支删除之后再走这个边工具
      setTimeout(() => {
        graph.disableHistory();
        if (settingNodeInfoLocal.data.componentId === 'SPLITTER') {
          // onLayout();
          var edges = getOutgoingEdges(settingNodeInfoLocal);

          edges.forEach((item: any, index: number) => {
            // 确保 item.data 是一个对象
            if (!item.data) {
              item.data = {};
            }
            item.data.list = info.branch;
            item.data.index = index;
            item.data.value = info.branch[index]?.value;
            item.data.name = info.branch[index]?.name;
            item.removeTools('numPercent');
            item.addTools(
              numPercent({
                branchName: `${info.branch[index]?.name}`,
                branchPercent: `${info.branch[index]?.value}%`,
              })
            );
            console.log(222, index, graph.getCellById(item.target?.cell).position().x);
          });
          console.log('分流器的边', edges);
        }

        // onLayout();
        graph.enableHistory();
      }, 10);
      graph.enableHistory();
    };
    busEvent.on('updateNodeInfo', handleUpdateNodeInfo);
    //等待节点  准时 迟到的工具
    const handleUpdateLineCondition = (info: any) => {
      graph.disableHistory();
      console.log('handleUpdateLineCondition', info);
      let branchEdge = getOutgoingEdges(settingNodeInfoLocal)[info.index];
      let obj: any = {};
      if (info.field === 'execTime') {
        obj = {
          name: '准时',
          condition: {
            combinator: 'and',
            rules: [
              {
                field: 'execTime',
                operator: '&lt;',
                value: info.data,
              },
            ],
          },
        };
        let obj2 = {
          name: '迟到',
          condition: {
            combinator: 'and',
            rules: [
              {
                field: 'execTime',
                operator: '&gt;',
                value: info.data,
              },
            ],
          },
        };
        getOutgoingEdges(settingNodeInfoLocal)[1].setData(obj2, { overwrite: true });
      } else {
        obj = {
          ...branchEdge.data,
          list: info.data,
          index: info.index,
          field: info.field,
          pretentId: settingNodeInfoLocal.id,
        };
      }
      branchEdge.setData(obj, { overwrite: true });
      graph.enableHistory();
      console.log('handleUpdateLineCondition', branchEdge);
    };
    busEvent.on('updateLineCondition', handleUpdateLineCondition);
    //扫描tab
    const handleScanNode = (info: any) => {
      graph.disableHistory();
      console.log('handleErrorNode', info);
      if (info === 'success') {
        let cells = graph.getNodes();
        cells.forEach((item: any) => {
          item.setAttrs({
            label: {
              ...item.getAttrs().label,
              textError: false,
            },
          });
        });
        if (publishScan) {
          onCheck('publish');
        }
      } else if (info.status === 'error') {
        if (publishScan) {
          message.error('发布失败');
        }
        let cells = graph.getNodes();
        cells.forEach((item: any) => {
          item.setAttrs({
            label: {
              ...item.getAttrs().label,
              textError: info.arr.filter((e: any) => e.id === item.id).length > 0 ? true : false,
            },
          });
        });
      } else {
        settingNodeInfoLocal = graph.getCellById(info);
        setSettingNodeInfo(settingNodeInfoLocal);
        setActiveKey('1');
        const color = imageShapes.filter((i: any) => i.componentId === settingNodeInfoLocal.data.componentId)[0]?.color;
        settingNodeInfoLocal.setAttrs({
          label: {
            backgroundColor: settingNodeInfoLocal.data.componentId === 'START' ? '#1e1e1e' : color,
            color: '#ffffff',
            borderColor: settingNodeInfoLocal.data.componentId === 'START' ? '#1e1e1e' : color,
          },
        });
        graph.getNodes().forEach((item: any) => {
          if (item.id !== settingNodeInfoLocal.id && item.data.label.indexOf('添加') === -1) {
            const colorNew = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.color;
            const backColorNew = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.backColor;
            const borderColorNew = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.borderColor;
            item.setAttrs({
              label: {
                backgroundColor: item.data.componentId === 'START' ? '#f5f5f5' : backColorNew,
                color: item.data.componentId === 'START' ? '#1e1e1e' : colorNew,
                borderColor: item.data.componentId === 'START' ? '#d0d0d0' : borderColorNew,
              },
            });
          }
        });
      }
      publishScan = false;
      graph.enableHistory();
    };
    //配置选中节点
    const checkStartFuntion = (node?: any) => {
      node = node ? node : startNode;
      graph.disableHistory();
      // 配置项tab 显示开始节点信息 默认开始节点为选中状态
      setSettingNodeInfo(node);
      settingNodeInfoLocal = node;
      const color = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.color;
      const icon = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.icon;
      node.setAttrs({
        label: {
          ...node.getAttrs().label,
          backgroundColor: node.data.componentId === 'START' ? '#1e1e1e' : color,
          color: '#ffffff',
          icon: icon,
          borderColor: node.data.componentId === 'START' ? '#1e1e1e' : color,
        },
      });
      graph.getNodes().forEach((item: any) => {
        if (item.id !== node.id && item.data.label.indexOf('添加') === -1) {
          const color1 = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.color;
          const backColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.backColor;
          const borderColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.borderColor;
          const iconNew = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.icon;
          item.setAttrs({
            label: {
              ...item.getAttrs().label,
              backgroundColor: item.data.componentId === 'START' ? '#f5f5f5' : backColor,
              color: item.data.componentId === 'START' ? '#1e1e1e' : color1,
              icon: iconNew,
              borderColor: item.data.componentId === 'START' ? '#d0d0d0' : borderColor,
            },
          });
        }
      });
      graph.enableHistory();
    };
    busEvent.on('checkStartNode', checkStartFuntion);
    busEvent.on('scanNode', handleScanNode);
    // let autoSaveIntervalId: any = null;
    // if (autoSave) {
    //   console.log(`switch to ${autoSave}`);
    //   autoSaveIntervalId = setInterval(() => {
    //     console.log('每隔1分钟调用一次保存草稿接口');
    //     onCheck('draft');
    //   }, 300000); //每隔5分钟调用一次保存草稿接口
    // } else {
    //   autoSaveIntervalId = null;
    //   console.log(`switch to ${autoSave}`);
    // }
    // #region busEvent
    return () => {
      busEvent.off('onAddLine', handleAddLine);
      busEvent.off('updateNodeInfo', handleUpdateNodeInfo);
      busEvent.off('onLookLine', handleLookLine);
      busEvent.off('onWaitingType', changeWaitingType);
      busEvent.off('updateLineCondition', handleUpdateLineCondition);
      busEvent.off('scanNode', handleScanNode);
      busEvent.off('checkStartNode', checkStartFuntion);
      girdStatus = true;
      copyNode = null;
      operationUpdatedTime = '';
      graphId = '';
      returnData = {};
      graphStatusNewLocal = '';
      deleteBranchList = [];
      if (autoSaveIntervalId) {
        clearInterval(autoSaveIntervalId);
      }
    };
  }, []);
  //#region 新建流程图
  const createGraph = async () => {
    dragNodeInfo = {};
    graph = new Graph({
      container: container,
      height: windowHeight,
      background: {
        color: '#F5F6F7',
      },
      panning: {
        enabled: true,
        eventTypes: ['leftMouseDown'],
      },
      grid: {
        size: 15,
        visible: true,
        type: 'doubleMesh',
        args: [
          {
            color: '#e7e8e8', // 主网格线颜色
            thickness: 1, // 主网格线宽度
          },
          {
            color: '#d3d4d5', // 次网格线颜色
            thickness: 1, // 次网格线宽度
            factor: 5, // 主次网格线间隔
          },
        ],
      },
      scaling: {
        min: 0.1, // 默认值为 0.01
        max: 1, // 默认值为 16
      },
      connecting: {
        router: 'orth',
        connector: {
          name: 'rounded',
        },
        anchor: 'center',
        connectionPoint: 'boundary',
        allowBlank: false,
        allowLoop: false,
        allowMulti: false,
        snap: {
          radius: 20,
        },
        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
                targetMarker: {
                  name: 'block',
                  width: 12,
                  height: 8,
                },
              },
            },
            zIndex: 0,
          });
        },
        validateConnection({ targetMagnet }) {
          return !!targetMagnet;
        },
      },
      highlighting: {
        magnetAdsorbed: {
          //连线过程中，连接桩可以被链接时被使用
          name: 'stroke',
          args: {
            attrs: {
              fill: '#5F95FF',
              stroke: '#5F95FF',
            },
          },
        },
      },
      translating: {
        restrict: true, //节点不能移动超出画布区域
      },
    });
    graph
      .use(
        new History({
          enabled: true,
          // ignoreChange: true,
        })
      )
      .use(
        new Selection({
          enabled: true,
          // rubberband: true,//左键框选
          // showNodeSelectionBox: true,
        })
      )
      .use(
        new Snapline({
          enabled: true,
          sharp: true,
        })
      )
      .use(
        new Clipboard({
          enabled: true,
        })
      )
      .use(new Keyboard());
    if (graphStatusNewLocal === '0') {
      //草稿状态location.state.id
      const res = await getCanvas(location.state.id);
      if (res) {
        returnData = res.data;
        graphStatusNewLocal = res.data.state.toString();
        setCanvasData(res.data);
        if (res.data.canvasData) {
          returnData = JSON.parse(res.data.canvasData);
          setCanvasData(returnData);
          returnData.id = res.data.id;
        }
        console.log('=========草稿状态getCanvas', returnData);
        setUpdatedTime(res.data.updatedTime);
        let newNodes = returnData.nodes.map((item: any) => {
          return {
            ...item,
            ...item.nodeStyle,
          };
        });
        newNodes.forEach((item: any) => {
          if (item.attrs) {
            item.attrs.label.textError = false;
          }
        });
        graph.fromJSON({
          nodes: newNodes,
          edges: returnData.connections.map((item: any) => {
            return {
              ...item,
              ...item.nodeStyle,
            };
          }),
        });
        graph.cleanHistory();
        startNode = graph.getRootNodes()[0];
        // setSettingNodeInfo(startNode);
        // settingNodeInfoLocal = startNode;
        busEvent.emit('checkStartNode', startNode);
      }
    } else {
      returnData = {};
      // #region 开始节点
      const newNode = graph.addNode({
        x: 0,
        y: 0,
        shape: 'custom-node',
        attrs: {
          label: {
            text: '开始',
            icon: 'icon-kaishi',
            backgroundColor: '#1e1e1e',
            color: '#ffffff',
            borderColor: '#1e1e1e',
            show: 'block',
          },
        },
        data: {
          label: '开始',
          componentId: 'START',
          scnArr: location.state.arr,
        },
      });
      startNode = newNode;
      setSettingNodeInfo(newNode);
      settingNodeInfoLocal = newNode;

      // busEvent.emit('onSettingTab', settingNodeInfo);

      const newNode2 = graph.addNode({
        x: nodeOffset,
        y: edgeLength1,
        shape: 'custom-add',
        attrs: {
          label: {
            text: '+',
          },
        },
        data: {
          label: '添加',
          componentId: 'add',
        },
        tools: [
          // {
          //   name: 'contextmenu',
          //   args: { menu },
          // },
          {
            name: 'tooltip',
            args: {
              tooltip: 'add',
            },
          },
        ],
      });
      const edge = graph.addEdge({
        shape: 'edge',
        source: newNode,
        target: newNode2,
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
            targetMarker: {
              name: 'block',
              width: 12,
              height: 8,
            },
          },
        },
      });
    }

    graph.positionPoint({ x: 0, y: 0 }, '50%', 40);
    graph.cleanHistory();
    // #region 快捷键与事件
    graph.on('history:change', () => {
      console.log('history:change');
      setCanRedo(graph.canRedo());
      setCanUndo(graph.canUndo());
    });
    graph.on('history:undo', ({ cmds }: any) => {
      console.log(cmds, '=======================cmds');
      // 找到全部节点
      const redoList = graph.getNodes();
      console.log('找到全部节点', redoList);
      var status = false;
      if (cmds[0].event === 'cell:change:position') {
        status = true;
      }
      //是分流器
      let headSplitter: any = null;
      let headbranch: any = null;
      cmds.forEach((item: any) => {
        if (item.data.props?.data?.pretentId) {
          headSplitter = item.data.props?.data?.pretentId;
          if (item.data.props?.source.cell !== headSplitter) {
            headSplitter = item.data.props?.source.cell;
          }
          headbranch = item.data.id;
        }
      });
      //撤回的节点里有咩有分流器
      let isSplitter = cmds.filter((e: any) => e.data.id === headSplitter);
      console.log('headSplitter----', headSplitter, headbranch, isSplitter);
      if (headSplitter && isSplitter.length === 0) {
        //找到撤销的节点
        const redoItem = redoList.filter((item: any) => item.id === headSplitter);
        busEvent.emit('checkStartNode', redoItem[0]);
        //找到撤销节点的输出边
        if (cmds[0].event === 'cell:added') {
          //撤回增加的分支
          setTimeout(() => {
            settingTabRef.current?.deleteBranch('undo');
          }, 10);
        } else {
          //撤回删除的分支
          // let undoEdges = graph.getOutgoingEdges(headSplitter);
          let undoEdge = cmds.filter((e: any) => e.data.id === headbranch)[0].data.props.data;

          setTimeout(() => {
            onLayout();
            settingTabRef.current?.addBranch({ index: undoEdge.index, name: undoEdge.name, value: undoEdge.value });
          }, 10);
        }
        //撤回分流器的分支
        status = true;
      }
      if (headSplitter && isSplitter.length > 0) {
        //撤回分流器
        status = true;
        //找到撤销的节点
        const redoItem = redoList.filter((item: any) => item.id === headSplitter);
        busEvent.emit('checkStartNode', redoItem[0]);
      }

      const isTools = cmds.filter((item: any) => item.data?.key === 'tools');

      //是等待
      if (
        isTools &&
        isTools.length > 0 &&
        (isTools[0].data.next?.tools?.items[0].data?.parentId || isTools[0].data.prev?.tools?.items[0].data?.parentId)
      ) {
        var parentId = isTools[0].data.next?.tools?.items[0].data?.parentId
          ? isTools[0].data.next?.tools?.items[0].data?.parentId
          : isTools[0].data.prev?.tools?.items[0].data?.parentId;
        const redoItem = redoList.filter((item: any) => item.id === parentId);
        console.log('找到撤销的节点2', redoItem);
        //找到撤销节点的输入边
        const afterOutgoingEdges = graph.getOutgoingEdges(redoItem[0]);
        if (afterOutgoingEdges.length > 1) {
          setTimeout(() => {
            settingTabRef.current?.changeWaitType('until', 1);
          }, 10);
        } else {
          setTimeout(() => {
            settingTabRef.current?.changeWaitType(redoItem[0].data.preType, 1);
          }, 10);
        }
        // 选中此节点
        busEvent.emit('checkStartNode', redoItem[0]);
        status = true;
      }
      if (!status) {
        // 默认选中开始节点
        busEvent.emit('checkStartNode');
      }
    });
    graph.on('history:redo', ({ cmds }: any) => {
      console.log(cmds, '=redo==========================cmds');
      // 找到全部节点
      let headSplitter: any = null;
      let headbranch: any = null;
      cmds.forEach((item: any) => {
        if (item.data.props?.data?.pretentId) {
          headSplitter = item.data.props?.data?.pretentId;
          if (item.data.props?.source.cell !== headSplitter) {
            headSplitter = item.data.props?.source.cell;
          }
          headbranch = item.data.id;
        }
      });
      //撤回的节点里有咩有分流器
      let isSplitter = cmds.filter((e: any) => e.data.id === headSplitter);
      console.log('headSplitter----', headSplitter, headbranch, isSplitter);
      const redoList = graph.getNodes();
      var status = false;
      if (cmds[0].event === 'cell:change:position') {
        status = true;
      }
      //是分流器
      if (headSplitter) {
        const redoItem = redoList.filter((item: any) => item.id === headSplitter);
        console.log(555, redoItem);
        if (redoItem.length > 0) {
          // 选中此节点
          busEvent.emit('checkStartNode', redoItem[0]);
          if (cmds[0].event === 'cell:removed') {
            setTimeout(() => {
              settingTabRef.current?.deleteBranch('undo');
            }, 10);
          } else if (cmds[0].event === 'cell:added' && isSplitter.length === 0) {
            let redoEdge = cmds.filter((e: any) => e.data.id === headbranch)[0].data.props.data;
            setTimeout(() => {
              settingTabRef.current?.addBranch({ index: redoEdge.index, name: redoEdge.name, value: redoEdge.value });
            }, 10);
          }
          status = true;
        }
      }
      const isTools = cmds.filter((item: any) => item.data?.key === 'tools');
      //是等待
      if (
        isTools &&
        isTools.length > 0 &&
        (isTools[0].data.next?.tools?.items[0].data?.parentId || isTools[0].data.prev?.tools?.items[0].data?.parentId)
      ) {
        var parentId = isTools[0].data.next?.tools?.items[0].data?.parentId
          ? isTools[0].data.next?.tools?.items[0].data?.parentId
          : isTools[0].data.prev?.tools?.items[0].data?.parentId;
        const redoItem = redoList.filter((item: any) => item.id === parentId);
        const afterOutgoingEdges = graph.getOutgoingEdges(redoItem[0]);
        if (afterOutgoingEdges.length > 1) {
          setTimeout(() => {
            settingTabRef.current?.changeWaitType('until', 1);
          }, 10);
        } else {
          setTimeout(() => {
            settingTabRef.current?.changeWaitType(redoItem[0].data.preType, 1);
          }, 10);
        }
        // 选中此节点
        busEvent.emit('checkStartNode', redoItem[0]);
        status = true;
      }
      if (!status) {
        // 默认选中开始节点
        busEvent.emit('checkStartNode');
      }
    });
    graph.on('node:added', ({ node }: any) => {
      const { data } = node;
      console.log('4-node:added', node, graph.getConnectedEdges(node));
      //回显的时候会带过来参数  暂时没用
      delete node.store.data?.componentId;
      delete node.store.data?.name;
      if (dragFlag) {
        // #region 左侧拖拽添加
        //区分是拖拽生成的节点还是弹窗添加的节点
        // const nodes = graph.getNodes();

        const nodePosition = node.position();
        allAddNodes = graph.getNodes().filter((e: any) => e.data.label.indexOf('添加') !== -1);
        // let finalAddNodes: any = [];
        // nodes.forEach((item: any, index: any) => {
        //   if (item.data.label === '添加') {
        //     finalAddNodes.push(item);
        //   }
        // });

        //拖拽的节点必须到加号节点范围内，才能被添加
        let finalAddNodeIndex = -1;
        allAddNodes.forEach((item: any, index: any) => {
          if (
            nodePosition.x >= item.position().x - 40 &&
            nodePosition.x <= item.position().x + 40 &&
            nodePosition.y >= item.position().y - 40 &&
            nodePosition.y <= item.position().y + 40
          ) {
            finalAddNodeIndex = index;
          }
        });
        if (finalAddNodeIndex !== -1) {
          graph.enableHistory();
          graph.startBatch('custom-batch-name');
          dragNodeInfo.id = node.id;
          node.position(allAddNodes[finalAddNodeIndex].position().x - nodeOffset, allAddNodes[finalAddNodeIndex].position().y + edgeLength2);

          const preNode = graph.getCellById(graph.getIncomingEdges(allAddNodes[finalAddNodeIndex])[0].source.cell);
          const updateData = {
            label: node.data.label,
            componentId: node.data.componentId,
            preNode: preNode.data.label,
          };
          node.setData(updateData, { overwrite: true });
          //加号节点上方的边去掉箭头
          var edge = graph.getIncomingEdges(allAddNodes[finalAddNodeIndex]);
          edge[0].setAttrs({
            line: {
              targetMarker: {
                name: 'block',
                width: 0,
                height: 0,
                size: -1,
              },
            },
          });
          dragFlag = false;
          commonAdd(node, allAddNodes[finalAddNodeIndex], 'drag');

          graph.stopBatch('custom-batch-name');
          //更新操作时间
          operationUpdatedTime = dayjs();
        } else {
          graph.removeCell(node);
        }
      }
    });

    graph.on('node:mouseenter', (evt: any) => {
      const { node, e } = evt;
      e.stopPropagation();

      const pageX = e.pageX;
      const pageY = e.pageY;
      const p1 = graph.pageToLocal(pageX, pageY);
      const p2 = graph.localToGraph(p1);
      graph.disableHistory();

      if (node.data.label.indexOf('添加') === -1) {
        if (settingNodeInfoLocal.id !== node.id) {
          //鼠标移入颜色更改
          const color = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.color;
          const hoverBg = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.hoverBg;
          const hoverBorder = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.hoverBorder;
          node.setAttrs({
            label: {
              ...node.getAttrs().label,
              backgroundColor: node.data.componentId === 'START' ? '#E4E4E4' : hoverBg,
              color: color,
              borderColor: node.data.componentId === 'START' ? '#7D7D7D' : hoverBorder,
            },
          });
        }
      } else {
        node.resize(40, 24);
        node.setAttrs({
          content: {
            width: 24,
            height: 24,
            refX: 7,
          },
        });
      }
      graph.enableHistory();
    });
    graph.on('node:mouseleave', (evt: any) => {
      graph.disableHistory();
      const { node, e } = evt;
      if (node.data.label.indexOf('添加') === -1) {
        if (settingNodeInfoLocal.id !== node.id) {
          // node.setAttrs({
          //   body: { fill: '#cccccc' },
          // });
          //鼠标移出颜色更改
          const color = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.color;
          const backColor = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.backColor;
          const borderColor = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.borderColor;
          node.setAttrs({
            label: {
              ...node.getAttrs().label,
              backgroundColor: node.data.componentId === 'START' ? '#f5f5f5' : backColor,
              color: node.data.componentId === 'START' ? '#1e1e1e' : color,
              borderColor: node.data.componentId === 'START' ? '#d0d0d0' : borderColor,
            },
          });
        }
      } else {
        //打开弹窗的时候会走两次  所以打开弹窗并且走了两次的时候 需要把走的第二次的位置恢复
        popNum += 1;
        // if (node.size().width === 24) {
        //   node.resize(18, 18);
        //   node.position(node.position().x + 3, node.position().y + 3);
        //   if (popShow && popNum == 2) {
        //     node.position(node.position().x - 3, node.position().y - 3);
        //   }
        // }
        if (node.size().height === 24) {
          node.resize(40, 18);
          node.setAttrs({
            content: {
              width: 18,
              height: 18,
              refX: 11,
            },
          });
        }
      }
      graph.enableHistory();
    });
    // graph.on('node:contextmenu', (evt: any) => {
    //   const { node } = evt;
    //   graph.resetSelection(node);
    //   console.log('右键', node);
    // });
    graph.on('node:click', (evt: any) => {
      const { node } = evt;
      graph.disableHistory();
      console.log('单击', node.data.label, node.id);
      if (node.data.label.indexOf('添加') !== -1) {
        updateNodeInfo = node;
        // setIsModalVisible(true);
        popShow = true;
        popNum = 0;
        busEvent.emit('openPopup', 1);
      } else if (node.data.componentId !== 'END') {
        //修改点击的时候节点的颜色
        const color = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.color;
        // node.setAttrs({
        //   body: { fill: '#3369ff' },
        // });
        node.setAttrs({
          label: {
            ...node.getAttrs().label,
            backgroundColor: node.data.componentId === 'START' ? '#1e1e1e' : color,
            color: node.data.componentId === 'START' ? '#ffffff' : '#ffffff',
            borderColor: node.data.componentId === 'START' ? '#1e1e1e' : color,
          },
        });
        graph.getNodes().forEach((item: any) => {
          if (item.id !== node.id && item.data.label.indexOf('添加') === -1) {
            const color1 = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.color;
            const backColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.backColor;
            const borderColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.borderColor;

            item.setAttrs({
              label: {
                ...item.getAttrs().label,
                backgroundColor: item.data.componentId === 'START' ? '#f5f5f5' : backColor,
                color: item.data.componentId === 'START' ? '#1e1e1e' : color1,
                borderColor: item.data.componentId === 'START' ? '#d0d0d0' : borderColor,
              },
            });
          }
        });
        if (node.data.label !== '结束') {
          setSettingNodeInfo(node);
          settingNodeInfoLocal = node;
          setSettingPanelShow(true);
          setIsArrowRight(true);
          setActiveKey('1');

          // busEvent.emit('onSettingTab', settingNodeInfo);
        }
      }
      graph.enableHistory();
    });
    // graph.bindKey(['meta+c', 'ctrl+c'], () => {
    //   const cells = graph.getSelectedCells();
    //   if (cells.length) {
    //     graph.copy(cells);
    //   }
    //   return false;
    // });
    // graph.bindKey(['meta+x', 'ctrl+x'], () => {
    //   const cells = graph.getSelectedCells();
    //   if (cells.length) {
    //     graph.cut(cells);
    //   }
    //   return false;
    // });
    // graph.bindKey(['meta+v', 'ctrl+v'], () => {
    //   if (!graph.isClipboardEmpty()) {
    //     const cells = graph.paste({ offset: 32 });
    //     graph.cleanSelection();
    //     graph.select(cells);
    //   }
    //   return false;
    // });

    // // undo redo
    // graph.bindKey(['meta+z', 'ctrl+z'], () => {
    //   if (graph.canUndo()) {
    //     graph.undo();
    //   }
    //   return false;
    // });
    // graph.bindKey(['meta+shift+z', 'ctrl+shift+z'], () => {
    //   if (graph.canRedo()) {
    //     graph.redo();
    //   }
    //   return false;
    // });

    // // select all
    // graph.bindKey(['meta+a', 'ctrl+a'], () => {
    //   const nodes = graph.getNodes();
    //   if (nodes) {
    //     graph.select(nodes);
    //   }
    // });

    // // delete
    // graph.bindKey('backspace', () => {
    //   const cells = graph.getSelectedCells();
    //   if (cells.length) {
    //     graph.removeCells(cells);
    //   }
    // });

    // // zoom
    // graph.bindKey(['ctrl+1', 'meta+1'], () => {
    //   const zoom = graph.zoom();
    //   if (zoom < 1.5) {
    //     graph.zoom(0.1);
    //   }
    // });
    // graph.bindKey(['ctrl+2', 'meta+2'], () => {
    //   const zoom = graph.zoom();
    //   if (zoom > 0.5) {
    //     graph.zoom(-0.1);
    //   }
    // });
    // #region 左侧拖拽区
    dnd = new Dnd({
      target: graph,
      scaled: false,
      dndContainer: dndContainer,
      getDragNode(node) {
        //用户开始拖拽一个节点时会被调用
        console.log('2-getDragNode', node);
        allAddNodes = graph.getNodes().filter((e: any) => e.data.label.indexOf('添加') !== -1);

        const datalabel = dragNodeInfo.label;
        const type = imageShapes.filter((i: any) => i.label === datalabel)[0].type;
        const label = imageShapes.filter((i: any) => i.label === datalabel)[0].label;
        const icon = imageShapes.filter((i: any) => i.label === datalabel)[0].icon;
        const color = imageShapes.filter((i: any) => i.label === datalabel)[0].color;
        const backColor = imageShapes.filter((i: any) => i.label === datalabel)[0].backColor;
        const borderColor = imageShapes.filter((i: any) => i.label === datalabel)[0].borderColor;
        const componentId = imageShapes.filter((i: any) => i.label === datalabel)[0].componentId;
        // 这里返回一个新的节点作为拖拽节点 拖拽过程中的节点样式
        dragNode = graph.createNode({
          shape: 'custom-node',
          attrs: {
            label: {
              text: label,
              icon: icon,
              backgroundColor: backColor,
              borderColor: borderColor,
              color: color,
              show: 'none',
            },
          },
          data: {
            label: label,
            componentId,
          },
        });
        return dragNode;
        // return graph.createNode({
        //   width: 60,
        //   height: 60,
        //   shape: 'circle',
        //   attrs: {
        //     body: {
        //       fill: '#ccc',
        //     },
        //   },
        // });
      },
      getDropNode(node) {
        // 返回一个新的节点作为实际放置到画布上的节点
        console.log('3-getDropNode', node);
        dragFlag = true;

        return graph.createNode({
          shape: 'custom-node',
          attrs: {
            label: {
              text: dragNodeInfo.label,
              icon: dragNodeInfo.icon,
              backgroundColor: '#ffffff',
              color: '#000000',
              show: 'block',
            },
          },
          data: {
            label: dragNodeInfo.label,
            componentId: dragNodeInfo.componentId,
          },
          tools: [
            {
              name: 'tooltip',
              args: {
                tooltip: 'node',
              },
            },
          ],
        });
      },
    });
  };
  //#region 回显流程图
  const initGraph = async () => {
    graph = new Graph({
      container: container,
      height: windowHeight,
      background: {
        color: '#f5f6f7',
      },
      panning: {
        enabled: true,
        eventTypes: ['leftMouseDown'],
      },
      // grid: {
      //   size: 15,
      //   visible: true,
      //   type: 'doubleMesh',
      //   args: [
      //     {
      //       color: '#ededed', // 主网格线颜色
      //       thickness: 1, // 主网格线宽度
      //     },
      //     {
      //       color: '#ddd', // 次网格线颜色
      //       thickness: 1, // 次网格线宽度
      //       factor: 5, // 主次网格线间隔
      //     },
      //   ],
      // },
      connecting: {
        router: 'orth',
        connector: {
          name: 'rounded',
        },
        anchor: 'center',
        connectionPoint: 'boundary',
        allowBlank: false,
        allowLoop: false,
        allowMulti: false,
        snap: {
          radius: 20,
        },
        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
                targetMarker: {
                  name: 'block',
                  width: 12,
                  height: 8,
                },
              },
            },
            zIndex: 0,
          });
        },
        validateConnection({ targetMagnet }) {
          return !!targetMagnet;
        },
      },
      highlighting: {
        magnetAdsorbed: {
          //连线过程中，连接桩可以被链接时被使用
          name: 'stroke',
          args: {
            attrs: {
              fill: '#5F95FF',
              stroke: '#5F95FF',
            },
          },
        },
      },
      translating: {
        restrict: true, //节点不能移动超出画布区域
      },
    });
    graph
      .use(
        new History({
          enabled: true,
          // ignoreChange: true,
        })
      )
      .use(
        new Selection({
          enabled: true,
          // rubberband: true,//左键框选
          // showNodeSelectionBox: true,
        })
      )
      .use(
        new Snapline({
          enabled: true,
          sharp: true,
        })
      )
      .use(
        new Clipboard({
          enabled: true,
        })
      )
      .use(new Keyboard());
    //如果是草稿状态，加号节点需要存在，其他状态没有加号节点
    //接口返回数据
    //canvasId从列表页带来
    const res = await getCanvas(location.state.id);
    console.log('=========getCanvas', res);
    if (res) {
      returnData = res.data;
      graphStatusNewLocal = res.data.state.toString();
      setCanvasData(res.data);
      setUpdatedTime(res.data.updatedTime);

      // graph.container.clientWidth = canvasData.appearance.width;
      // graph.container.clientHeight = canvasData.appearance.height;
      // if(canvasData.appearance.showGrid){
      //   graph.showGrid()
      // }else {
      //   graph.hideGrid()
      // }
      // girdStatus = canvasData.appearance.showGrid;

      console.log('返回的节点信息', returnData);
      //根据节点重新连接边
      graph.fromJSON({
        nodes: returnData.nodes.map((item: any) => {
          return {
            ...item,
            ...item.nodeStyle,
          };
        }),
        edges: returnData.connections.map((item: any) => {
          return {
            ...item,
            ...item.nodeStyle,
          };
        }),
      });
      let nodes = graph.getNodes();
      console.log('生成之后的节点', nodes);

      startNode = graph.getRootNodes()[0];
      // var start = {
      //   componentId: 'START',
      //   label: '开始',
      // };
      //策略信息和人群信息放入开始节点里, 包括开始节点label和key
      // let obj = {
      //   ...start,
      //   ...returnData.strategy,
      //   ...returnData.crowd,
      // };
      // startNode.setData(obj, { overwrite: true });
      startNode.setAttrs({
        label: {
          ...startNode.getAttrs().label,
          backgroundColor: '#f5f5f5',
          color: '#1e1e1e',
          borderColor: '#d0d0d0',
        },
      });
      const afterNodes = graph.getSuccessors(startNode, { breadthFirst: true });
      afterNodes.map((item: any, index: any) => {
        item.removeTool('tooltip');
        // item.position(item.position().x, index > 0 ? afterNodes[index - 1].position().y + edgeLength1 : startNode.position().y + edgeLength1);
        //颜色赋值
        const color = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.color;
        const backColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.backColor;
        const borderColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.borderColor;
        item.setAttrs({
          label: {
            ...item.getAttrs().label,
            backgroundColor: backColor,
            color: color,
            borderColor: borderColor,
          },
        });
        if (item.data.componentId === 'WAIT' && item.data.type === 'until') {
          let edges = graph.getOutgoingEdges(item);
          edges[0].addTools(
            waitingText({
              branchName: '准时',
              parentId: item.id,
            })
          );
          edges[1].addTools(
            waitingText({
              branchName: '迟到',
              parentId: item.id,
            })
          );
        } else if (item.data.componentId === 'SPLITTER') {
          let edges = graph.getOutgoingEdges(item);
          edges.forEach((e: any, i: any) => {
            e.addTools(
              numPercent({
                branchName: `${item.data.branch[i]?.name}`,
                branchPercent: `${item.data.branch[i]?.value}%`,
              })
            );
          });
        }
      });
      let updateEdges = graph.getEdges();
      console.log('updateEdges', updateEdges);
      updateEdges.map((item: any, index: any) => {
        const itemNodes = graph.getCellById(item.source.cell);
        let distance = 40;
        if (itemNodes.data.componentId === 'WAIT' && itemNodes.data.type === 'until') {
          distance = 105;
        }
        if (itemNodes.data.componentId === 'SPLITTER') {
          distance = -40;
        }
        item.addTools(crowdCountTool(item.data && item.data?.userCount ? item.data?.userCount : '0', distance));
      });
      onLayout();
      graph.positionPoint({ x: 0, y: 0 }, '90%', 40);

      // 配置项tab 显示开始节点信息
      busEvent.emit('checkStartNode', startNode);

      graph.on('node:mouseenter', (evt: any) => {
        const { node, e } = evt;
        e.stopPropagation();

        const pageX = e.pageX;
        const pageY = e.pageY;
        const p1 = graph.pageToLocal(pageX, pageY);
        const p2 = graph.localToGraph(p1);
        graph.disableHistory();

        if (node.data.label.indexOf('添加') === -1) {
          node.setAttrs({
            body: { fill: '#3369ff' },
          });
          // node.transition('attrs/body/fill', '#86bdf4', {
          //   interp: Interp.color,
          //   timing: 'linear', // Timing.bounce
          //   duration: 300,
          // });
        } else {
          // node.scale(1.2, 1.2);
          node.resize(24, 24);
          node.position(node.position().x - 3, node.position().y - 3);
        }
        graph.enableHistory();
        // graph.resetSelection(node);
      });
      graph.on('node:mouseleave', (evt: any) => {
        graph.disableHistory();
        const { node, e } = evt;
        if (node.data.label.indexOf('添加') === -1) {
          if (settingNodeInfoLocal.id !== node.id) {
            node.setAttrs({
              body: { fill: '#cccccc' },
            });
          }

          // node.transition('attrs/body/fill', '#cccccc', {
          //   interp: Interp.color,
          //   timing: 'linear', // Timing.bounce
          //   duration: 300,
          // });
        } else {
          //打开弹窗的时候会走两次  所以打开弹窗并且走了两次的时候 需要把走的第二次的位置恢复
          popNum += 1;
          node.resize(18, 18);
          node.position(node.position().x + 3, node.position().y + 3);
          if (popShow && popNum == 2) {
            node.position(node.position().x - 3, node.position().y - 3);
          }
        }
        graph.enableHistory();
      });
      // graph.on('node:contextmenu', (evt: any) => {
      //   const { node } = evt;
      //   graph.resetSelection(node);
      //   console.log('右键', node);
      // });
      graph.on('node:click', (evt: any) => {
        const { node } = evt;
        //结束点击不了 点击数字工具 出现数据详情 置为false
        if (node.data.componentId === 'END') {
          return;
        }
        setCountToolShow(false);
        //切换节点时 只有 运行中和已停止状态 短信 切换时 置为 3
        if (node.data.componentId === 'SMS' && (graphStatusNewLocal === '2' || graphStatusNewLocal === '3')) {
          setActiveKey('3');
        } else {
          setActiveKey('1');
        }
        graph.disableHistory();

        console.log('单击', node.data.label, node.id);
        if (node.data.label.indexOf('添加') !== -1) {
          updateNodeInfo = node;
          // setIsModalVisible(true);
          popShow = true;
          popNum = 0;
          busEvent.emit('openPopup', 1);
        } else {
          //修改点击的时候节点的颜色
          const color = imageShapes.filter((i: any) => i.componentId === node.data.componentId)[0]?.color;
          // node.setAttrs({
          //   body: { fill: '#3369ff' },
          // });
          node.setAttrs({
            label: {
              ...node.getAttrs().label,
              backgroundColor: node.data.componentId === 'START' ? '#1e1e1e' : color,
              color: node.data.componentId === 'START' ? '#ffffff' : '#ffffff',
              borderColor: node.data.componentId === 'START' ? '#1e1e1e' : color,
            },
          });
          graph.getNodes().forEach((item: any) => {
            if (item.id !== node.id && item.data.label.indexOf('添加') === -1) {
              const color1 = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.color;
              const backColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.backColor;
              const borderColor = imageShapes.filter((i: any) => i.componentId === item.data.componentId)[0]?.borderColor;
              // item.setAttrs({
              //   body: { fill: '#ccc' },
              // });
              item.setAttrs({
                label: {
                  ...item.getAttrs().label,
                  backgroundColor: item.data.componentId === 'START' ? '#f5f5f5' : backColor,
                  color: item.data.componentId === 'START' ? '#1e1e1e' : color1,
                  borderColor: item.data.componentId === 'START' ? '#d0d0d0' : borderColor,
                },
              });
            }
          });
          if (node.data.label !== '结束') {
            let extrNode = {
              ...node,
              data: {
                ...node.data,
                createTime: returnData.createTime,
              },
            };
            setSettingNodeInfo(extrNode);
            settingNodeInfoLocal = node;
            setSettingPanelShow(true);
            setIsArrowRight(true);
            // busEvent.emit('onSettingTab', settingNodeInfo);
          }
        }
        graph.enableHistory();
      });
    }
  };
  // #region 点击弹窗添加节点
  const addNodes = (e: any) => {
    console.log('1-addNodes---');
    const { datalabel, operType } = e;
    if (e) {
      graph.enableHistory();
      const type = imageShapes.filter((i: any) => i.label === datalabel)[0].type;
      const label = imageShapes.filter((i: any) => i.label === datalabel)[0].label;
      const icon = imageShapes.filter((i: any) => i.label === datalabel)[0].icon;
      const color = imageShapes.filter((i: any) => i.label === datalabel)[0].color;
      const backColor = imageShapes.filter((i: any) => i.label === datalabel)[0].backColor;
      const borderColor = imageShapes.filter((i: any) => i.label === datalabel)[0].borderColor;
      const componentId = imageShapes.filter((i: any) => i.label === datalabel)[0].componentId;
      //将多个改变一次性撤销或者重做
      graph.startBatch('custom-batch-name');
      if (operType === 2) {
        var replaceNodeInfoCopy = replaceNodeInfo?.data.componentId;
        var replaceNodeEdge = graph.getOutgoingEdges(replaceNodeInfo);
        var replaceBeforeNode = graph.getIncomingEdges(replaceNodeInfo)[0]?.source.cell;

        var newNodeX = replaceNodeInfo.position().x;
        var newNodeY = replaceNodeInfo.position().y;
        //将之前的节点删除  重新加入新的替换节点
        graph.removeCell(replaceNodeInfo);
        const newNode2 = graph.addNode({
          x: newNodeX,
          y: newNodeY,
          shape: 'custom-node',
          attrs: {
            label: {
              text: label,
              icon: icon,
              backgroundColor: backColor,
              color: color,
              borderColor: borderColor,
              show: 'block',
            },
          },
          data: {
            label: label,
            componentId: componentId,
          },
          tools: [
            {
              name: 'tooltip',
              args: {
                tooltip: 'node',
              },
            },
          ],
        });
        // //替换节点
        // replaceNodeInfo.setAttrs({
        //   label: { text: label },
        // });
        // const data = {
        //   label,
        //   componentId,
        // };
        // replaceNodeInfo.setData(data, { overwrite: true });
        // var newNode2 = replaceNodeInfo;
        //移出 newNode1 相关的所有连接边
        // graph.removeConnectedEdges(newNode1);
        // var newNode2 = graph.updateCellId(graph.getSelectedCells()[0]);
        graph.addEdge({
          shape: 'edge',
          source: replaceBeforeNode,
          target: newNode2,
          connector: 'normal',
          router: {
            name: 'er',
            args: {
              offset: 'center',
              direction: 'T',
            },
          },
          attrs: {
            line: {
              stroke: '#A2B1C3',
              strokeWidth: 2,
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
              },
            },
          },
        });
        replaceNodeEdge?.forEach((item: any) => {
          graph.addEdge({
            shape: 'edge',
            source: newNode2,
            target: item.target.cell,
            connector: 'normal',
            router: {
              name: 'er',
              args: {
                offset: 'center',
                direction: 'T',
              },
            },
            attrs: {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
                targetMarker: {
                  name: 'block',
                  width: 12,
                  height: 8,
                },
              },
            },
          });
        });
        busEvent.emit('checkStartNode', newNode2);
        //replaceNodeEdge == null 是结束节点
        //只针对于 将普通节点（包括结束节点）替换为分流器节点 或者  将分流器节点替换为普通节点（包括结束节点）
        if (
          (componentId === 'SPLITTER' && (replaceNodeEdge?.length < 2 || replaceNodeEdge == null)) ||
          (componentId !== 'SPLITTER' && replaceNodeEdge?.length >= 2)
        ) {
          //newNode 替换完成的节点 afterNode 第一个加号节点
          onReplaceNode(newNode2, componentId);
        }
        //等待分叉分支 替换为分流器
        if (componentId === 'SPLITTER' && replaceNodeEdge?.length >= 2 && replaceNodeInfoCopy === 'WAIT') {
          onChangeTool(newNode2, componentId);
        }
        //只针对于 将普通节点（不包括分流器）替换为结束节点 或者  将结束节点替换为普通节点（不包括分流器）
        if (
          ((label === '结束' && replaceNodeInfoCopy !== 'END') || (label !== '结束' && replaceNodeInfoCopy === 'END')) &&
          componentId !== 'SPLITTER' &&
          replaceNodeInfoCopy !== 'SPLITTER'
        ) {
          //newNode 替换完成的节点
          onReplacOvereNode(newNode2, label);
        }
        //每次替换都需要跳到配置项
        onChangeTabs('1');
      } else {
        dragNodeInfo.type = type;
        dragNodeInfo.label = label;
        dragNodeInfo.color = color;
        dragNodeInfo.backColor = backColor;
        dragNodeInfo.borderColor = borderColor;
        dragNodeInfo.componentId = componentId;

        // const allNodes = graph.getNodes();
        // let nodeY = (allNodes.length - 1) * 150;
        let nodeY = updateNodeInfo.position().y;
        //加号节点上方的边去掉箭头
        var edge = graph.getIncomingEdges(updateNodeInfo);
        edge[0].setAttrs({
          line: {
            targetMarker: {
              name: 'block',
              width: 0,
              height: 0,
              size: -1,
            },
          },
        });
        //创建节点,携带上一个非加号节点的信息
        const preNode = graph.getCellById(graph.getIncomingEdges(updateNodeInfo)[0].source.cell);
        const newNode = graph.addNode({
          x: updateNodeInfo.position().x - nodeOffset,
          y: nodeY + edgeLength2,
          shape: 'custom-node',
          attrs: {
            label: {
              text: label,
              icon: icon,
              backgroundColor: backColor,
              color: color,
              borderColor: borderColor,
              show: 'block',
            },
          },
          ports: {
            groups: {
              group2: {
                position: { name: 'bottom' },
                attrs: {
                  circle: {
                    r: 1,
                    magnet: true,
                    stroke: '#31d0c6',
                    fill: '#fff',
                    strokeWidth: 1,
                  },
                },
              },
            },
            items: [
              { id: 'port2', group: 'group2' },
              // { id: 'port3', group: 'group1' },
            ],
          },
          data: {
            label: dragNodeInfo.label,
            componentId: dragNodeInfo.componentId,
            preNode: preNode.data.label,
          },
          tools: [
            {
              name: 'tooltip',
              args: {
                tooltip: 'node',
              },
            },
          ],
        });

        commonAdd(newNode, updateNodeInfo, 'add');
      }

      graph.stopBatch('custom-batch-name');
      busEvent.emit('openPopup', false);
    }
    popShow = false;
    popNum = 0;
  };
  // #region 拖拽和弹窗添加通用逻辑
  const commonAdd = (newNode: any, addNode: any, type: any) => {
    let afterNode: any = '';
    if (addNode.data.label === '中间添加') {
      const edgeso = graph.getOutgoingEdges(addNode)[0];
      afterNode = graph.getCellById(edgeso.target.cell);
      graph.removeEdge(edgeso.id);
      console.log('afterNode', afterNode);
    }
    //上个节点和新节点连接
    graph.addEdge({
      shape: 'edge',
      source: addNode.id,
      target: newNode,
      attrs: {
        line: {
          stroke: '#A2B1C3',
          strokeWidth: 2,
          targetMarker: {
            name: 'block',
            width: 12,
            height: 8,
          },
        },
      },
    });
    //结束节点没有加号节点
    if (newNode.data.label !== '结束') {
      //新节点下面的加号节点
      const addNode1 = graph.addNode({
        x: newNode.position().x + nodeOffset,
        y: newNode.position().y + edgeLength1,
        shape: 'custom-add',
        attrs: {
          label: {
            text: '+',
          },
        },
        ports: {
          groups: {
            group1: {
              position: { name: 'top' },
              attrs: {
                circle: {
                  r: 0,
                  magnet: true,
                  stroke: '#FFF',
                  fill: '#fff',
                  strokeWidth: 0,
                },
              },
            },
          },
          items: [{ id: 'port1', group: 'group1' }],
        },
        data: {
          label: afterNode ? '中间添加' : '添加',
          componentId: 'add',
        },
        tools: [
          {
            name: 'tooltip',
            args: {
              tooltip: afterNode ? 'midAdd' : 'add',
            },
          },
        ],
      });
      //新节点带的加号节点和新节点连接
      const edge = graph.addEdge({
        shape: 'edge',
        source: { cell: newNode, port: 'port2' },
        target: { cell: addNode1, port: 'port1' },
        connector: 'normal',
        router: {
          name: 'er',
          args: {
            offset: 'center',
            direction: 'T',
          },
        },
        vertices: [
          // { x: newNode.position().x + nodeOffset + 20, y: newNode.position().y + 75 },
          // { x: addNode1.position().x + nodeOffset, y: newNode.position().y + 75 },
        ],
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
            targetMarker: {
              name: 'block',
              width: 12,
              height: 8,
            },
          },
        },
        tools:
          newNode.data.componentId === 'SPLITTER'
            ? numPercent({
                branchName: '分支1',
                branchPercent: '50%',
              })
            : '',
      });

      //如果是分叉节点，新节点下面要再新增一个加号分支,先按一分为二算，和原来的下一个节点并列
      let addNode2: any = null;
      if (newNode.data.componentId === 'SPLITTER') {
        addNode1.position(addNode1.position().x - edgeExpendNum, addNode1.position().y);
        edge.setData(
          {
            list: defaultShuntBranch,
            index: 0,
            field: 'splitter',
            pretentId: newNode.id,
            value: 50,
            name: '分支1',
            sortIndex: 1,
          },
          { overwrite: true }
        );
        addNode2 = graph.addNode({
          x: addNode1.position().x + multiBranchedgeNum,
          y: addNode1.position().y,
          shape: 'custom-add',
          attrs: {
            label: {
              text: '+',
            },
          },
          ports: {
            groups: {
              group1: {
                position: { name: 'top' },
                attrs: {
                  circle: {
                    r: 0,
                    magnet: true,
                    stroke: '#FFF',
                    fill: '#fff',
                    strokeWidth: 0,
                  },
                },
              },
            },
            items: [{ id: 'port1', group: 'group1' }],
          },
          data: {
            label: '添加',
            componentId: 'add',
          },
          tools: [
            {
              name: 'tooltip',
              args: {
                tooltip: 'add',
              },
            },
          ],
        });
        graph.addEdge({
          shape: 'edge',
          source: { cell: newNode, port: 'port2' },
          target: { cell: addNode2, port: 'port1' },
          connector: 'normal',
          router: {
            name: 'er',
            args: {
              offset: 'center',
              direction: 'T',
            },
          },
          vertices: [
            // { x: newNode.position().x + nodeOffset + 20, y: newNode.position().y + 75 },
            // { x: addNode2.position().x + nodeOffset, y: newNode.position().y + 75 },
          ],
          attrs: {
            line: {
              stroke: '#A2B1C3',
              strokeWidth: 2,
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
              },
            },
          },
          tools: numPercent({
            branchName: '分支2',
            branchPercent: '50%',
          }),
          data: {
            list: defaultShuntBranch,
            index: 1,
            field: 'splitter',
            pretentId: newNode.id,
            value: 50,
            name: '分支2',
            sortIndex: 2,
          },
        });
      }

      if (afterNode) {
        //加号节点上方的边去掉箭头
        const edge = graph.getIncomingEdges(addNode1);
        edge[0].setAttrs({
          line: {
            targetMarker: {
              name: 'block',
              width: 0,
              height: 0,
              size: -1,
            },
          },
        });
        //新节点带的加号节点和后续节点连接
        graph.addEdge({
          shape: 'edge',
          source: addNode1,
          target: afterNode,
          attrs: {
            line: {
              stroke: '#A2B1C3',
              strokeWidth: 2,
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
              },
            },
          },
        });
        //更改前序节点信息
        const updateData = {
          ...afterNode.data,
          preNode: newNode.data.label,
        };
        afterNode.setData(updateData, { overwrite: true });
        let offsetY = addNode1.position().y + edgeLength2 - afterNode.position().y;
        let offsetX = addNode1.position().x - nodeOffset - afterNode.position().x;
        calcPosition(afterNode, offsetX, offsetY);
        if (addNode2) {
          const nextNode = graph.getSuccessors(newNode).filter((item: any) => item && graph.getOutgoingEdges(item)?.length > 1);
          addNode2.position(addNode2.position().x + nextNode.length * edgeExpendNum, addNode2.position().y);
        }
      }
      if (newNode.data.componentId === 'SPLITTER') {
        // changePosition1(addNode, type);
        changePosition1(addNode, type);
      }
      //新节点变为选中节点 颜色更改
      busEvent.emit('checkStartNode', newNode);
    } else if (afterNode) {
      const removeCells = graph.getSuccessors(afterNode);
      // 获取当前剪断节点的所有前序节点 backNode 不为空就代表前序节点有分流器
      const backAllNode = graph.getPredecessors(afterNode);
      //判断下方有几个分流器节点 100 * nextNodede 的长度
      const nextNode = [] as any;
      removeCells.forEach((item: any) => {
        if (item.shape !== 'edge' && graph.getOutgoingEdges(item) && graph.getOutgoingEdges(item).length > 1) {
          nextNode.push(item);
        }
      });
      const backNode = [] as any;
      backAllNode.forEach((item: any) => {
        if (item.shape !== 'edge' && graph.getOutgoingEdges(item) && graph.getOutgoingEdges(item).length > 1) {
          nextNode.push(item);
        }
      });
      if (backNode.length > 0) {
        changePosition1(afterNode, 'delete', nextNode.length);
      }
      graph.removeCell(afterNode);
      graph.removeCells(removeCells);
    }

    const data = {
      label: '中间添加',
      componentId: 'add',
    };
    addNode.setData(data, { overwrite: true });
    addNode.removeTool('tooltip');
    addNode.addTools({
      name: 'tooltip',
      args: {
        tooltip: 'midAdd',
      },
    });
    //更新操作时间
    operationUpdatedTime = dayjs();
    console.log('更新操作时间', operationUpdatedTime);
    //添加完节点后 将tab变为配置项
    setActiveKey('1');
  };
  //等待分叉分支 替换为分流器
  const onChangeTool = (newNode: any, componentId: any) => {
    //将节点边上的工具移出  变为分流器的工具
    var edges = graph.getOutgoingEdges(newNode);
    edges.forEach((item: any, index: number) => {
      item.removeTools('waitingText');
      item.addTools(
        numPercent({
          branchName: `分支${index + 1}`,
          branchPercent: `50%`,
        })
      );
    });
  };
  // #region 替换逻辑  newNode 替换完成的节点 afterNode 第一个加号节点
  const onReplaceNode = (newNode: any, componentId: any) => {
    //变成一步
    graph.startBatch('custom-batch-name');
    //addNode  为左连接线
    var addNode = graph.getOutgoingEdges(newNode) ? graph.getOutgoingEdges(newNode)[0] : null;
    var afterNode = addNode ? graph.getCellById(addNode.target.cell) : null;
    const middleAdd = graph.getSuccessors(newNode).filter((i: any) => i.data.label === '中间添加');

    var addFinish = false;
    //将分流器分叉节点替换为普通节点 （包括结束节点）
    if (componentId !== 'SPLITTER' && waitingType !== 'until') {
      if (componentId === 'END') {
        // 获取当前删除节点的所有前序节点 backNode 不为空就代表前序节点有分流器
        //判断下方有几个分流器节点 100 * nextNodede 的长度 加上本身的分流器节点
        const nextNode = graph.getSuccessors(newNode).filter((item: any) => item && graph.getOutgoingEdges(item)?.length > 1);
        const backNode = graph.getPredecessors(newNode).filter((item: any) => item && graph.getOutgoingEdges(item)?.length > 1);
        if (backNode.length > 0) {
          changePosition1(newNode, 'delete', nextNode.length + 1);
        }
        //后续节点全部删除
        graph.removeCells(graph.getSuccessors(newNode));
        addFinish = true;
      } else {
        //如果是中间替换 替换为普通节点 需要将后续节点删除
        if (middleAdd?.length > 0) {
          // 获取当前删除节点的所有后续节点和前序节点 backNode 不为空就代表前序节点有分流器
          const nextNode = graph.getSuccessors(newNode).filter((item: any) => item && graph.getOutgoingEdges(item)?.length > 1);
          const backNode = graph.getPredecessors(newNode).filter((item: any) => item && graph.getOutgoingEdges(item)?.length > 1);
          if (backNode.length > 0) {
            //判断下方有几个分流器节点 100 * nextNodede 的长度 加上本身的分流器节点
            changePosition1(newNode, 'delete', nextNode.length + 1);
          }
          //edge 为替换的节点的输出边
          var edge = graph.getOutgoingEdges(newNode);
          //要移出的节点
          var removeCells = [] as any;
          edge.map((item: any, index: number) => {
            //add 为 输出边的加号节点
            var add = graph.getCellById(item.target.cell);
            //只留index 为0 的加号节点 其他删除
            if (index > 0) {
              removeCells.push(add);
            }
            //将add节点的 后续节点全部删除
            const removeCells1 = graph.getSuccessors(add);
            graph.removeCells(removeCells1);
          });
          graph.removeCells(removeCells);
          //加号节点上方的边加上箭头
          const edgeChange = graph.getIncomingEdges(afterNode);
          edgeChange[0].setAttrs({
            line: {
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
                size: 10,
              },
            },
          });
          // addTwoNode index为0 的加号节点 重新赋坐标值
          var addTwoNode = graph.getCellById(edge[0].target.cell);
          addTwoNode.position(newNode.position().x + nodeOffset, addTwoNode.position().y);
          addFinish = true;
          addNode.removeTools('numPercent');
        } else {
          changePosition1(newNode, 'delete');
          //替换节点的输出边
          var edge = graph.getOutgoingEdges(newNode);
          var removeCells = [] as any;
          edge.map((item: any, index: number) => {
            if (index > 0) {
              var add = graph.getCellById(item.target.cell);
              removeCells.push(add);
            }
            item.removeTools('numPercent');
          });
          graph.removeCells(removeCells);
          //仅剩的一个 加号节点
          var addTwoNode = graph.getCellById(edge[0].target.cell);
          addTwoNode.position(newNode.position().x + nodeOffset, addTwoNode.position().y);
          addFinish = true;
        }
      }
    } else {
      changePosition1(newNode, 'add');
      //afterNode 空是 将 结束节点替换为分流器
      if (afterNode) {
        //将左连线 换成折线
        addNode.setRouter({
          name: 'er',
          args: {
            offset: 'center',
            direction: 'T',
          },
        });
        addNode.setConnector('normal');
        if (componentId === 'WAIT') {
          //将左连接线添加等待需要的工具
          addNode.addTools(
            waitingText({
              branchName: '准时',
              parentId: newNode.id,
            })
          );
        } else {
          //将左连接线添加百分比工具
          addNode.addTools(
            numPercent({
              branchName: '分支1',
              branchPercent: '50%',
            })
          );
        }
        afterNode.position(afterNode.position().x - edgeExpendNum, afterNode.position().y);
      } else {
        //第一个加号按钮
        afterNode = graph.addNode({
          x: newNode.position().x + nodeOffset - edgeExpendNum,
          y: newNode.position().y + edgeLength1,
          shape: 'custom-add',
          attrs: {
            label: {
              text: '+',
            },
          },
          data: {
            label: '添加',
            componentId: 'add',
          },
          tools: [
            {
              name: 'tooltip',
              args: {
                tooltip: 'add',
              },
            },
          ],
        });
        graph.addEdge({
          shape: 'edge',
          source: newNode,
          target: afterNode,
          connector: 'normal',
          router: {
            name: 'er',
            args: {
              offset: 'center',
              direction: 'T',
            },
          },
          attrs: {
            line: {
              stroke: '#A2B1C3',
              strokeWidth: 2,
              targetMarker: {
                name: 'block',
                width: 12,
                height: 8,
              },
            },
          },
          tools: numPercent({
            branchName: '分支1',
            branchPercent: '50%',
          }),
        });
      }
      //查找后续节点是否是分叉节点，如果有 筛选出后续有分叉的节点 创建第二个加号节点的时候就向右移动n个100单位
      const allNextNode = graph.getSuccessors(newNode);
      const numBranch = allNextNode.filter((i: any) => i && graph.getOutgoingEdges(i)?.length > 1);

      //第二个加号按钮
      const addNode2 = graph.addNode({
        x: afterNode.position().x + multiBranchedgeNum + edgeExpendNum * numBranch.length,
        y: afterNode.position().y,
        shape: 'custom-add',
        attrs: {
          label: {
            text: '+',
          },
        },
        data: {
          label: '添加',
          componentId: 'add',
        },
        tools: [
          {
            name: 'tooltip',
            args: {
              tooltip: 'add',
            },
          },
        ],
      });
      const edge2 = graph.addEdge({
        shape: 'edge',
        source: { cell: newNode, port: 'port2' },
        target: { cell: addNode2, port: 'port1' },
        connector: 'normal',
        router: {
          name: 'er',
          args: {
            offset: 'center',
            direction: 'T',
          },
        },
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
            targetMarker: {
              name: 'block',
              width: 12,
              height: 8,
            },
          },
        },
        tools:
          componentId !== 'WAIT'
            ? numPercent({
                branchName: '分支2',
                branchPercent: '50%',
              })
            : waitingText({
                branchName: '迟到',
                parentId: newNode.id,
              }),
      });

      addFinish = true;
      //如果是中间添加 需要将下方的节点往左移动 坐标系
      if (afterNode?.data.label === '中间添加') {
        //获取movedNode后续所有节点
        const nextNodes = graph.getSuccessors(afterNode);
        nextNodes.map((item: any) => {
          item.position(item.position().x - edgeExpendNum, item.position().y);
        });
        addFinish = false;
      }
    }
    const data = {
      label: addFinish ? '添加' : '中间添加',
      componentId: 'add',
    };
    graph.stopBatch('custom-batch-name');
    afterNode.setData(data, { overwrite: true });
  };
  // #region 结束节点 替换逻辑  newNode 替换完成的节点  type为 替换的节点是否是结束
  const onReplacOvereNode = (newNode: any, label: any) => {
    //如果是中间替换的话  将后续节点都删除
    //将普通节点替换为结束节点
    if (label === '结束') {
      //后续节点全部删除
      graph.removeCells(graph.getSuccessors(newNode));
    } else {
      //添加加号按钮
      const addNode = graph.addNode({
        x: newNode.position().x + nodeOffset,
        y: newNode.position().y + edgeLength1,
        shape: 'custom-add',
        attrs: {
          label: {
            text: '+',
          },
        },
        data: {
          label: '添加',
          componentId: 'add',
        },
        tools: [
          {
            name: 'tooltip',
            args: {
              tooltip: 'add',
            },
          },
        ],
      });
      graph.addEdge({
        shape: 'edge',
        source: newNode,
        target: addNode,
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
            targetMarker: {
              name: 'block',
              width: 12,
              height: 8,
            },
          },
        },
      });
    }
  };
  const startDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    console.log('1-startDrag');
    const target = e.currentTarget;
    const datalabel = target.getAttribute('data-label');
    const type = imageShapes.filter((i: any) => i.label === datalabel)[0].type;
    const label = imageShapes.filter((i: any) => i.label === datalabel)[0].label;
    const color = imageShapes.filter((i: any) => i.label === datalabel)[0].color;
    const backColor = imageShapes.filter((i: any) => i.label === datalabel)[0].backColor;
    const borderColor = imageShapes.filter((i: any) => i.label === datalabel)[0].borderColor;
    const icon = imageShapes.filter((i: any) => i.label === datalabel)[0].icon;
    const componentId = imageShapes.filter((i: any) => i.label === datalabel)[0].componentId;
    dragNodeInfo.type = type;
    dragNodeInfo.label = label;
    dragNodeInfo.color = color;
    dragNodeInfo.backColor = backColor;
    dragNodeInfo.borderColor = borderColor;
    dragNodeInfo.icon = icon;
    dragNodeInfo.componentId = componentId;
    const node = graph.createNode({
      shape: 'custom-node',
      attrs: {
        label: {
          text: label,
          icon: icon,
          backgroundColor: backColor,
          borderColor: borderColor,
          color: color,
          show: 'block',
        },
      },
    });

    dnd.start(node, e.nativeEvent as any);
  };
  const dndContainerRef = (container: HTMLDivElement) => {
    dndContainer = container;
  };
  const refContainer = (container1: HTMLDivElement) => {
    container = container1;
  };
  // #region 操作按钮
  //整理布局
  const onLayout = (type?: any) => {
    graph.startBatch('custom-batch-name');
    // 使用队列代替递归
    console.log('自动布局');
    const queue = [startNode];

    while (queue.length > 0) {
      const node = queue.shift();
      if (node && getOutgoingEdges(node)?.length > 1) {
        changePosition1(node, 'add', getOutgoingEdges(node).length - 1);
      }
      const outs = getOutgoingEdges(node);

      if (outs && outs.length > 0) {
        outs.forEach((item: any, index: any) => {
          const nextNode = graph.getCellById(item.target.cell);
          const currentPosition = node.position();
          const length = outs.length;
          let newX, newY;
          if (graphStatusNewLocal === '' || graphStatusNewLocal === '0' || graphStatusNewLocal === null) {
            if (node.shape === 'custom-add') {
              newX = currentPosition.x - nodeOffset;
              newY = currentPosition.y + edgeLength2;
            } else {
              if (node && getOutgoingEdges(node)?.length > 1) {
                newX = currentPosition.x + nodeOffset + -edgeExpendNum * (length - 1) + multiBranchedgeNum * index;
                newY = currentPosition.y + edgeLength1;
                console.log('=======qqqqq', item, newX);
              } else {
                newX = currentPosition.x + nodeOffset;
                newY = currentPosition.y + edgeLength1;
              }
            }
          } else {
            if (node && getOutgoingEdges(node)?.length > 1) {
              newX = currentPosition.x + -edgeExpendNum * (length - 1) + multiBranchedgeNum * index;
              newY = currentPosition.y + edgeLength1;
            } else {
              newX = currentPosition.x;
              newY = currentPosition.y + edgeLength1;
            }
          }
          nextNode.position(newX, newY);
          // 将下一节点添加到队列中
          queue.push(nextNode);
        });
      }
    }

    graph.stopBatch('custom-batch-name');
    if (type === 'publish') {
      //发布前，先扫描
      onChangeTabs('2');
      publishScan = true;
    }
  };
  //查看流程图时，查看路径按钮
  const lookLine = async (info: any) => {
    console.log(3434, info);
    function onGetIncomingEdges(node: any) {
      const edgesi = graph.getIncomingEdges(node);
      if (edgesi && edgesi.length > 0) {
        edgesList = edgesList.concat(edgesi);
        edgesi.forEach((item: any) => {
          onGetIncomingEdges(item.source.cell);
        });
      }
    }
    function onGetOutgoingEdges(node: any) {
      const edgeso = graph.getOutgoingEdges(node);
      if (edgeso && edgeso.length > 0) {
        edgesList = edgesList.concat(edgeso);
        edgeso.forEach((item: any) => {
          onGetOutgoingEdges(item.target.cell);
        });
      }
    }
    let edgesList: any = [];
    if (info.lineFlag) {
      const res = await getUserPath({
        canvasId: returnData.id,
        // canvasId: 'kcltest',
        oneId: info.oneId,
      });
      console.log('查看路径', res.data);
      res.data.forEach((item: any, index: any) => {
        if (index > 0) {
          let incomeEages = graph.getIncomingEdges(item);
          edgesList = edgesList.concat(incomeEages);
        }
      });
    } else {
      // onGetIncomingEdges(settingNodeInfoLocal);
      // onGetOutgoingEdges(settingNodeInfoLocal);
      edgesList = graph.getEdges();
    }

    edgesList.map((item: any) => {
      if (info.lineFlag) {
        item.setMarkup([
          ...item.markup,
          {
            tagName: 'circle',
            selector: 'c1',
          },
        ]);
        item.setAttrs({
          line: {
            stroke: '#faad14',
          },
          c1: {
            r: 2,
            stroke: '#faad14',
            fill: '#faad14',
            atConnectionRatio: 0,
            strokeWidth: 1,
          },
        });
        getRoundAnimation(item);
      } else {
        stopRoundAnimation();
        item.removeMarkup([
          {
            tagName: 'circle',
            selector: 'c1',
          },
        ]);
        item.setAttrs({
          line: {
            stroke: '#A2B1C3',
          },
        });
      }
    });
  };
  const onCopy = () => {
    const cells = graph.getSelectedCells();
    if (cells && cells.length) {
      graph.copy(cells, options);
      message.success('复制成功');
    } else {
      message.info('请先选中节点再复制');
    }
  };

  const onPaste = () => {
    if (graph.isClipboardEmpty()) {
      message.info('剪切板为空，不可粘贴');
    } else {
      const cells = graph.paste(options);
      graph.cleanSelection();
      graph.select(cells);
      console.log(cells);
      cells.map((item: any, index: any) => {
        //更新属性值
        const attrs = item.getAttrs();
        item.setAttrs({
          label: { text: attrs.label.text + '_copy_' + Number(index + 1) },
        });
      });
      message.success('粘贴成功');
    }
  };
  const onUndo = () => {
    graph.undo();
  };

  const onRedo = () => {
    graph.redo();
  };
  const bigGraph = () => {
    let num = zoomPercent;
    num = num + 10;
    if (num <= 100) {
      setZoomPercent(num);
    }
    graph.zoom(0.1);
  };
  const smallGraph = () => {
    let num = zoomPercent;
    num = num - 10;
    if (num >= 10) {
      setZoomPercent(num);
    }
    graph.zoom(-0.1);
  };
  const changeGrid = () => {
    if (girdStatus) {
      graph.hideGrid();
      girdStatus = false;
    } else {
      graph.showGrid();
      girdStatus = true;
    }
  };
  const changeDesc = () => {
    graph.disableHistory();
    const nodes = graph.getNodes();
    nodes.map((item: any, index: any) => {
      const attrs = item.getAttrs();
      if (item.data.label.indexOf('添加') === -1) {
        if (attrs.label.show === 'none') {
          item.setAttrs({
            label: {
              ...item.getAttrs().label,
              show: 'block',
            },
          });
        } else {
          item.setAttrs({
            label: {
              ...item.getAttrs().label,
              show: 'none',
            },
          });
        }
      }
    });
    graph.enableHistory();
  };

  const onOrigin = () => {
    graph.zoomTo(1); //缩放画布到指定比例
    graph.centerContent(); //将画布内容中心与视口中心对齐。
  };
  //发布
  const onCheck = async (type?: any) => {
    publishScan = false;
    let nodes = graph.toJSON();
    let paramNodes = [] as any;
    let draftParamNodes = [] as any;
    let connections = [] as any;
    let draftConnections = [] as any;
    console.log('oncheck');
    const leafNodes = graph.getLeafNodes();
    if (type === 'publish' && leafNodes.filter((e: any) => e.data.componentId === 'END').length !== leafNodes.length) {
      return message.error('未配置结束组件，请重新配置流程画布');
    }
    // let contentRule = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")

    console.log('导出的所有节点', nodes.cells);
    paramNodes = nodes.cells.filter((item: any) => item.shape !== 'edge');
    // const mesNodes = nodes.cells.filter((item: any) => item.data?.componentId === 'SMS');
    connections = nodes.cells.filter((item: any) => item.shape === 'edge');
    if (type === 'publish') {
      if (dayjs(paramNodes[0].data.strategyObj.startTime).isAfter(dayjs(paramNodes[0].data.strategyObj.endTime))) {
        return message.error('结束时间不能早于开始时间');
      }
      if (dayjs().isAfter(dayjs(paramNodes[0].data.strategyObj.startTime))) {
        return message.error('开始时间不能早于当前时间');
      }
      if (dayjs().isAfter(dayjs(paramNodes[0].data.strategyObj.endTime))) {
        return message.error('结束时间不能早于当前时间');
      }
      // const specilStg = mesNodes.filter((item: any) => contentRule.test(item.data?.text));
      // if (specilStg?.length > 0) {
      //   return message.error('短信内容不能包含特殊字符');
      // }
    }
    var arrNodes = [] as any;
    // let splitterNodes: any = [];
    paramNodes.forEach((item: any, index: any) => {
      let extraParams: any = {};
      // if (item.data.componentId === 'SPLITTER') {
      //   splitterNodes.push(item);
      // }
      if (item.data.componentId === 'START') {
        if (!item.data.strategyObj.startTime) {
          item.data.strategyObj.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
        }
      } else if (item.data.componentId === 'WAIT') {
        if (item.data.type === 'duration') {
          extraParams = {
            time: item.data.day + 'd' + item.data.hour + 'h' + item.data.minute + 'm',
          };
        } else if (item.data.type === 'until') {
          extraParams = {
            time: item.data.date,
          };
        } else {
          let array = item.data.time.split(':');
          if (array.length === 2) {
            extraParams = {
              time: array[0] + 'h' + array[1] + 'm',
            };
          }
        }
      } else if (item.data.componentId === 'SMS') {
        if (Array.isArray(item.data.vars)) {
          const varsObj: any = {};
          item.data.vars.forEach((item: any) => {
            varsObj[item.field] = item.def;
          });
          item.data.vars = varsObj;
        }
      }
      let obj = {
        name: item.data.label,
        componentId: item.data.componentId,
        ...item,
      };
      obj.nodeStyle = {
        attrs: item.attrs,
        view: item.view,
        shape: item.shape,
        router: item.router,
        connector: item.connector,
      };
      delete obj.connector;
      delete obj.router;
      delete obj.attrs;
      delete obj.view;
      delete obj.shape;
      obj.data = {
        ...item.data,
        ...extraParams,
      };
      delete obj.data.updatedTime;
      arrNodes.push(obj);
    });
    draftParamNodes = Array.from(arrNodes);
    var arrEdges = [] as any;
    connections.forEach((item: any, index: any) => {
      let condition: any = {};
      let obj = {
        ...item,
      };

      obj.nodeStyle = {
        attrs: item.attrs,
        shape: item.shape,
        router: item.router,
        connector: item.connector,
      };
      delete obj.connector;
      delete obj.router;
      delete obj.attrs;
      delete obj.shape;
      if (item.data && item.data.field === 'splitter') {
        const splitterNode = graph.getCellById(item.source.cell);
        let rules: any = [];
        if (item.data.index === 0) {
          rules = [
            {
              field: item.data.field,
              operator: '&lt;=',
              value: splitterNode.data.branch[item.data.index].value,
            },
          ];
        } else if (splitterNode.data.branch.length - 1 === item.data.index) {
          var sum = Number(
            splitterNode.data.branch.reduce((total: any, item: any) => total + item.value, 0) - splitterNode.data.branch[item.data.index].value
          );
          if (Math.floor(sum) === sum) {
            sum = sum; // 如果是整数，直接返回
          } else {
            sum = parseFloat(sum.toFixed(1)); // 如果是小数，保留两位小数
          }
          rules = [
            {
              field: item.data.field,
              operator: '&gt;',
              value: sum,
            },
          ];
        } else {
          var sum1 = splitterNode.data.branch.slice(0, item.data.index).reduce((sum: any, obj: any) => sum + obj.value, 0);
          var sum2 = Number(sum1 + splitterNode.data.branch[item.data.index].value);
          sum1 = parseFloat(sum1.toFixed(1));
          sum2 = parseFloat(sum2.toFixed(1));
          rules = [
            {
              field: item.data.field,
              operator: '&gt;',
              value: sum1,
            },
            {
              field: item.data.field,
              operator: '&lt;=',
              value: sum2,
            },
          ];
        }

        condition = {
          combinator: 'and',
          rules,
        };

        obj.data.name = splitterNode.data.branch[item.data.index].name;
        obj.data.value = splitterNode.data.branch[item.data.index].value;
        obj.data.condition = condition;
      }
      if (item.data && item.data.name && (item.data.name === '准时' || item.data.name === '迟到')) {
        obj.data.condition.rules[0].operator = item.data.name === '准时' ? '&lt;' : '&gt;';
      }
      arrEdges.push(obj);
    });
    draftConnections = Array.from(arrEdges);
    if (type === 'publish') {
      //发布的时候需要更改边的source和target，不带加号节点
      let addNodes = arrNodes.filter((item: any) => item.nodeStyle.shape === 'custom-add');
      let edgeList: any = [];
      addNodes.forEach((item: any, index: any) => {
        let incomingEdge = graph.getIncomingEdges(item)[0];
        let outgoingEdge = graph.getOutgoingEdges(item)[0];
        edgeList.push({
          ...arrEdges.filter((e: any) => e.id === incomingEdge.id)[0],
          target: {
            cell: outgoingEdge.target.cell,
          },
        });
      });
      arrEdges = edgeList;
      arrNodes = arrNodes.filter((item: any) => item.nodeStyle.shape === 'custom-node');
    }

    var params = {
      ...returnData,
      name: arrNodes[0].data.strategyObj?.name, //画布的名称
      description: '画布描述', //画布的描述信息
      appearance: {
        width: graph.container.clientWidth, //画布的宽度
        height: graph.container.clientHeight, //画布的宽度
        backgroundColor: '#ffffff', //背景颜色
        showGrid: girdStatus, //显示网格
        additionalSettings: null, //扩展字段
      },
      scnId: arrNodes[0].data.strategyObj?.scnId ? arrNodes[0].data.strategyObj?.scnId[1] : null,
      strategy: {
        ...arrNodes[0].data.strategyObj,
      },
      crowd: {
        crowdId: arrNodes[0].data.strategyObj?.crowdId, //人群的唯一标识符
        crowdName: arrNodes[0].data.strategyObj?.crowdName,
        rules: {}, //人群的条件规则
      },
      nodes: arrNodes,
      connections: arrEdges,
    };
    var draftParams = { ...params, nodes: draftParamNodes, connections: draftConnections };
    console.log('所有入参', params, draftParams);
    if (type === 'publish') {
      params.canvasData = JSON.stringify(draftParams);
      params.id = graphId ? graphId : params.id;
      const res = await deployCanvas(params);
      if (res) {
        message.success('发布成功');
        navigate('/MA/MarketPlan/MAList', { replace: true, state: { back: true } });
      }
    } else {
      if (!params.id && graphId) {
        params.id = graphId;
      }
      const res = await saveCanvas(params);
      if (res) {
        console.log('保存成功', res.data);
        setUpdatedTime(res.data.updatedTime);
        graphId = res.data.id;
        setCanvasData(res.data);
      }
    }
  };
  const onFinish = async (values: any) => {
    console.log(111, values);
  };

  const template = () => {
    return <TemplateGraph></TemplateGraph>;
  };
  //改变等待类型 1 为分叉
  const changeWaitingType = (value: any) => {
    const edge = graph.getOutgoingEdges(settingNodeInfoLocal);
    waitingType = value;
    //返回节点输出边的数组
    if (value === 'until') {
      onReplaceNode(settingNodeInfoLocal, settingNodeInfoLocal.data.componentId);
    } else if (edge?.length > 1) {
      //判断上一个是不是分叉 如果是分叉 走下面的去掉多余边
      onReplaceNode(settingNodeInfoLocal, settingNodeInfoLocal.data.componentId);
    }
  };
  const addLine = (dIndex?: any) => {
    //返回节点输出边的数组
    let outgoingEdges = getOutgoingEdges(settingNodeInfoLocal);
    graph.startBatch('custom-batch-name');
    if (dIndex !== undefined) {
      // deleteBranchList.push(outgoingEdges[dIndex]);
      // console.log('deleteBranchList', deleteBranchList);
      //将删除分支的后续节点删除
      if (graph.getCellById(outgoingEdges[dIndex].target.cell)) {
        var remove = graph.getSuccessors(graph.getCellById(outgoingEdges[dIndex].target.cell));
        graph.removeCells(remove);
      }
      graph.removeEdge(outgoingEdges[dIndex]);
      graph.removeCell(outgoingEdges[dIndex].target.cell);
      //节点输出边
      var behindNodes = getOutgoingEdges(settingNodeInfoLocal);
      behindNodes.forEach((item: any, index: any) => {
        //输出的连接的加号节点
        var afterAdd = graph.getCellById(item.target.cell);
        if (dIndex > index) {
          afterAdd.position(afterAdd.position().x + edgeExpendNum, afterAdd.position().y);
        } else {
          afterAdd.position(afterAdd.position().x - edgeExpendNum, afterAdd.position().y);
        }
        //加号节点的输出边
        var afterEdge = getOutgoingEdges(afterAdd) as any;
        //afterAdd 存在说明后续有节点
        if (afterEdge && afterEdge.length > 0) {
          afterEdge.forEach((itm: any) => {
            var afterNode = graph.getCellById(itm.target.cell);
            let offsetX = afterAdd.position().x - nodeOffset - afterNode.position().x;
            calcPosition(afterNode, offsetX, 0);
          });
        }
      });
      // var newOutgoingEdges = graph.getOutgoingEdges(settingNodeInfoLocal);
      // newOutgoingEdges.forEach((itm: any, indx: number) => {
      //   var num = settingNodeInfoLocal.position().x - (150 * outgoingEdges.length) / 2 + 150 * (indx + 1) + nodeOffset;
      //   graph.getCellById(itm.target.cell).position(num, settingNodeInfoLocal.position().y + edgeLength);
      // });
    } else {
      const successors = graph.getSuccessors(graph.getCellById(outgoingEdges[outgoingEdges.length - 1].target.cell));
      let findNodes = successors.filter(
        (item: any) => item.data.componentId === 'SPLITTER' || (item.data.componentId === 'WAIT' && item.data.type === 'until')
      );
      const addNode = graph.addNode({
        x: graph.getCellById(outgoingEdges[outgoingEdges.length - 1].target.cell).position().x + edgeExpendNum + edgeExpendNum * findNodes.length,
        y: settingNodeInfoLocal.position().y + edgeLength1,
        shape: 'custom-add',
        attrs: {
          label: {
            text: '+',
          },
        },
        data: {
          label: '添加',
          componentId: 'add',
          // pretentId: settingNodeInfoLocal.id,
        },
        tools: [
          {
            name: 'tooltip',
            args: {
              tooltip: 'add',
            },
          },
        ],
      });
      //节点输出边
      var behindNodes = getOutgoingEdges(settingNodeInfoLocal);
      //上个节点和新节点连接
      graph.addEdge({
        shape: 'edge',
        source: { cell: settingNodeInfoLocal, port: 'port2' },
        target: { cell: addNode, port: 'port1' },
        connector: 'normal',
        router: {
          name: 'er',
          args: {
            offset: 'center',
            direction: 'T',
          },
        },
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
            targetMarker: {
              name: 'block',
              width: 12,
              height: 8,
            },
          },
        },
        data: {
          list: settingNodeInfoLocal.data.branch,
          index: behindNodes.length,
          field: 'splitter',
          pretentId: settingNodeInfoLocal.id,
          value: 0,
          name: `分支${behindNodes[behindNodes.length - 1].data.sortIndex + 1}`,
          sortIndex: behindNodes[behindNodes.length - 1].data.sortIndex + 1,
        },
        tools: numPercent({
          branchName: `分支${behindNodes.length + 1}`,
          branchPercent: '0%',
        }),
      });
      //节点输出边
      behindNodes = getOutgoingEdges(settingNodeInfoLocal);
      behindNodes.forEach((item: any, index: any) => {
        if (index < behindNodes.length - 1) {
          //输出的连接的加号节点
          var afterAdd = graph.getCellById(item.target.cell);
          afterAdd.position(afterAdd.position().x - edgeExpendNum, afterAdd.position().y);
          //加号节点的输出边
          var afterEdge = getOutgoingEdges(afterAdd) as any;
          //afterAdd 存在说明后续有节点
          if (afterEdge && afterEdge.length > 0) {
            afterEdge.forEach((itm: any) => {
              var afterNode = graph.getCellById(itm.target.cell);
              let offsetX = afterAdd.position().x - nodeOffset - afterNode.position().x;
              calcPosition(afterNode, offsetX, 0);
            });
          }
        }
      });
    }

    onLayout();
    graph.stopBatch('custom-batch-name');
  };
  const handleLeftArrowClick = () => {
    if (isArrowLeft) {
      setNodesPanelShow(false);
    } else {
      setTimeout(() => {
        setNodesPanelShow(true);
      }, 300);
    }
    setIsArrowLeft(!isArrowLeft);
  };
  const handleRightArrowClick = () => {
    if (isArrowRight) {
      setSettingPanelShow(false);
    } else {
      setTimeout(() => {
        setSettingPanelShow(true);
      }, 300);
    }
    setIsArrowRight(!isArrowRight);
  };
  const nodeItems: CollapseProps['items'] = [
    {
      key: '1',
      label: '营销触达',
      children: (
        <Row gutter={16}>
          {imageShapes
            .filter((item: any) => item.row === '1')
            .map((item: any) => (
              <Col span={8} className={styles['dnd-node']}>
                <div
                  data-label={item.label}
                  style={{ border: `1px solid ${item.borderColor}`, backgroundColor: `${item.backColor}`, color: `${item.color}` }}
                  className={styles['dnd-node-img']}
                  onMouseDown={startDrag}>
                  {/* <img src={item.image} alt="" /> */}
                  <YLIcon className={styles['menu-icon']} color={item.color} size={24} type={item.icon}></YLIcon>
                  {/* <Iconfont className={styles['menu-icon']} icon={item.icon}></Iconfont> */}
                </div>
                <div className={styles['dnd-node-title']}>{item.label}</div>
              </Col>
            ))}
        </Row>
      ),
    },
    {
      key: '2',
      label: '数据操作',
      children: (
        <Row gutter={16}>
          {imageShapes
            .filter((item: any) => item.row === '2')
            .map((item: any) => (
              <Col span={8} className={styles['dnd-node']}>
                <div
                  data-label={item.label}
                  className={styles['dnd-node-img']}
                  style={{ border: `1px solid ${item.borderColor}`, backgroundColor: `${item.backColor}`, color: `${item.color}` }}
                  onMouseDown={startDrag}>
                  {/* <img src={item.image} alt="" /> */}
                  <YLIcon className={styles['menu-icon']} color={item.color} size={24} type={item.icon}></YLIcon>
                  {/* <Iconfont className={styles['menu-icon']} icon={item.icon}></Iconfont> */}
                </div>
                <div className={styles['dnd-node-title']}>{item.label}</div>
              </Col>
            ))}
        </Row>
      ),
    },
    {
      key: '3',
      label: '执行控制',
      children: (
        <Row gutter={16}>
          {imageShapes
            .filter((item: any) => item.row === '3')
            .map((item: any) => (
              <Col span={8} className={styles['dnd-node']}>
                <div
                  data-label={item.label}
                  className={styles['dnd-node-img']}
                  style={{ border: `1px solid ${item.borderColor}`, backgroundColor: `${item.backColor}`, color: `${item.color}` }}
                  onMouseDown={startDrag}>
                  <YLIcon className={styles['menu-icon']} color={item.color} size={24} type={item.icon}></YLIcon>
                  {/* <Iconfont className={styles['menu-icon']} icon={item.icon}></Iconfont> */}
                  {/* <img src={item.image} alt="" /> */}
                </div>
                <div className={styles['dnd-node-title']}>{item.label}</div>
              </Col>
            ))}
        </Row>
      ),
    },
  ];
  const leftTabItems: any = [
    {
      key: '1',
      label: '组件库',
      children: (
        <Collapse
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          items={nodeItems}
          ghost
          defaultActiveKey={['1', '2', '3']}
        />
      ),
    },
    // {
    //   key: '2',
    //   label: '策略器',
    //   children: (
    //     <div>
    //       <Popover placement="rightBottom" title="" content={template}>
    //         <div className={styles['strategy-text']}>模板消息：短信推送优先级</div>
    //       </Popover>
    //       <div>模板消息：短信推送优先级</div>
    //     </div>
    //   ),
    // },
  ];

  //测试记录
  const testRecordStatus = (type: boolean) => {
    setTestRecordShow(type);
  };
  const rightTabItems: any = [
    ...(settingNodeInfo?.data.componentId === 'SMS' && (graphStatusNew === '2' || graphStatusNew === '3')
      ? [
          {
            key: '3',
            label: '明细数据',
            children: <RunningTab ref={runningTabRef} info={settingNodeInfo} type={2}></RunningTab>,
          },
        ]
      : []),
    {
      key: '1',
      label: !graphStatusNew || graphStatusNew === '0' ? '配置' : '配置详情',
      children: (
        <SettingTab
          ref={settingTabRef}
          graphStatus={graphStatusNew}
          info={settingNodeInfo}
          canvasId={canvasData.id}
          canvasName={canvasData.name}
          opentestRecord={testRecordStatus}></SettingTab>
      ),
    },
    ...(!graphStatusNew || graphStatusNew === '0'
      ? [
          {
            key: '2',
            label: '扫描',
            children: <ScanTab graphStatus={graphStatusNew} allNodes={allNodes}></ScanTab>,
          },
        ]
      : []),
    // {
    //   key: '3',
    //   label: '帮助',
    //   children: 'Content of Tab Pane 3',
    // },
  ];
  const handleMouseDown = (event: any) => {
    isDragging = true;
  };
  let status: any = {};
  let moveAddNBode = null as any;
  let statusNum = 0;
  let statusNumLeave = 0;
  let positionStatus = 'default'; // 记录当前位置状态，默认为 'default'
  //鼠标拖拽节点改变加号节点样式
  const handleMouseMove = (event: any) => {
    if (isDragging && dragNode) {
      graph.disableHistory();
      allAddNodes.forEach((item: any, index: any) => {
        const dragNodePosition = dragNode.position();
        const itemPosition = item.position();
        // console.log(item.id);
        // console.log('位置', itemPosition);

        const inRangeX = status[item.id]
          ? dragNodePosition.x >= itemPosition.x - 40 && dragNodePosition.x <= itemPosition.x + 40
          : dragNodePosition.x >= itemPosition.x - 22 && dragNodePosition.x <= itemPosition.x + 18;
        const inRangeY = status[item.id]
          ? dragNodePosition.y >= itemPosition.y - 40 && dragNodePosition.y <= itemPosition.y + 40
          : dragNodePosition.y >= itemPosition.y - 22 && dragNodePosition.y <= itemPosition.y + 18;
        if (inRangeX && inRangeY) {
          // item.setAttrs({
          //   body: { fill: '#ffffff', stroke: '#4096ff' },
          //   label: { fill: '#4096ff' },
          // });
          item.resize(40, 40);
          item.setAttrs({
            content: {
              width: 40,
              height: 40,
              refX: 0,
              stroke: '#4096ff',
            },
            label: { fill: '#4096ff' },
          });
          // if (!status[item.id]) {
          //   item.position(itemPosition.x, itemPosition.y - 11);
          // }
          status[item.id] = true;
          moveAddNBode = item;
        } else {
          // item.setAttrs({
          //   body: { fill: '#ffffff', stroke: '#9ca8b4' },
          //   label: { fill: '#9ca8b4' },
          // });
          // item.resize(18, 18);
          // if (status[item.id]) {
          //   item.position(itemPosition.x, itemPosition.y + 11);
          // }
          item.resize(40, 18);
          item.setAttrs({
            content: {
              width: 18,
              height: 18,
              refX: 11,
              stroke: '#9ca8b4',
            },
            label: { fill: '#9ca8b4' },
          });
          status[item.id] = false;
          moveAddNBode = null;
        }
      });
      graph.enableHistory();
    }
  };

  const handleMouseUp = () => {
    graph.disableHistory();
    isDragging = false;
    allAddNodes.forEach((item: any, index: any) => {
      // item.setAttrs({
      //   body: { fill: '#ffffff', stroke: '#9ca8b4' },
      //   label: { fill: '#9ca8b4' },
      // });
      item.resize(40, 18);
      item.setAttrs({
        content: {
          width: 18,
          height: 18,
          refX: 11,
          stroke: '#9ca8b4',
        },
        label: { fill: '#9ca8b4' },
      });
      status[item.id] = false;
    });

    // if (moveAddNBode?.id) {
    //   calcPosition(moveAddNBode, nodeOffset, nodeOffset);
    // }
    moveAddNBode = null;
    graph.enableHistory();
  };
  //头部开关
  const onChange = (checked: boolean) => {
    setAutoSave(checked);
    if (checked) {
      console.log(`switch to ${checked}`);
      autoSaveIntervalId = setInterval(() => {
        console.log('每隔1分钟调用一次保存草稿接口');
        onCheck('draft');
      }, 300000); //每隔5分钟调用一次保存草稿接口
    } else {
      clearInterval(autoSaveIntervalId);
      autoSaveIntervalId = null;
      console.log(`switch to ${autoSave}`);
    }
  };
  //头部开关
  const onChangeTabs = (key: string) => {
    if (key === '2') {
      // let nodes = graph.toJSON();
      let nodes = graph.getCells();
      setAllNodes(nodes);
      console.log(`key`, nodes);
    }
    setActiveKey(key);
  };
  const arrState = [
    {
      name: '待启动',
      value: '1',
      type: 'warning',
    },
    {
      name: '运行中',
      value: '2',
      type: 'green',
    },
    {
      name: '已停止',
      value: '3',
      type: '',
    },
  ];
  //返回
  const checkBack = () => {
    //点击返回时，当前流程画布是否已保存最新配置信息。如果已保存，则直接返回。如果未保存，则显示二次确认弹窗，点击【确定】按钮，执行返回操作，同时不保存最新配置信息。点击【取消】按钮，执行取消返回操作，二次确认弹窗消失，停留在当前流程画布页面。
    if (graphStatusNewLocal === '' || graphStatusNewLocal === '0') {
      let lastTime = settingNodeInfoLocal.data.updatedTime;
      if (
        operationUpdatedTime &&
        (dayjs(settingNodeInfoLocal.data.updatedTime).isBefore(dayjs(operationUpdatedTime)) || !settingNodeInfoLocal.data.updatedTime)
      ) {
        lastTime = operationUpdatedTime;
      }
      console.log('返回', operationUpdatedTime, 88, settingNodeInfoLocal.data.updatedTime);
      if (lastTime) {
        //做了修改
        if (updatedTime && dayjs(lastTime).isBefore(dayjs(updatedTime))) {
          navigate('/MA/MarketPlan/MAList', { replace: true, state: { back: true } });
        } else {
          confirm({
            title: '流程画布尚未保存，返回后将失去您的编辑结果，确定要返回吗?',
            icon: <ExclamationCircleFilled />,
            content: '',
            onOk() {
              console.log('OK');
              navigate('/MA/MarketPlan/MAList', { replace: true, state: { back: true } });
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        }
      } else {
        //没做修改
        navigate('/MA/MarketPlan/MAList', { replace: true, state: { back: true } });
      }
    } else {
      //没做修改
      navigate('/MA/MarketPlan/MAList', { replace: true, state: { back: true } });
    }
  };
  //取消待启动 策略
  const cancelStrategyFuntion = async () => {
    const res = await strategiesCancel({ id: returnData.id });
    if (res) {
      graph = null;
      graphStatusNewLocal = '0';
      setGraphStatusNew('0');
      createGraph();
    }
  };
  //停止 策略
  const strategiesStopFuntion = async (value?: any) => {
    var param = { ids: [returnData.id] };
    const res = await strategiesStop(param);
    if (res) {
      graph = null;
      graphStatusNewLocal = '3';
      setGraphStatusNew('3');
      initGraph();
    }
  };
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  // #region 页面布局
  return (
    <div>
      <div className={styles.strategyHeadBox} style={{ zIndex: '1000' }}>
        <div className={styles.titleBox}>
          <div className={`${styles['titleBox_left']} ${styles['titleBox_cursor']}`}>
            <LeftOutlined className={`${styles.titleBox_marginRight} ${styles.grayColor}}`} onClick={checkBack} />
            <span className={styles.titleBox_span}></span>
            <ModuleTitle mark="middle" title={fileName ? fileName : graphStatusNew === '0' ? '编辑策略' : '新建策略'} />
            {/* 显示运行中还是待启动状态 */}
            {graphStatusNew && graphStatusNew !== '0' && (
              <Tag
                style={{ marginLeft: '8px', height: '22px', borderRadius: '4px' }}
                color={
                  arrState.filter((item: any) => item.value === graphStatusNew).length > 0
                    ? arrState.filter((item: any) => item.value === graphStatusNew)[0].type
                    : ''
                }>
                {arrState.filter((item: any) => item.value === graphStatusNew).length > 0
                  ? arrState.filter((item: any) => item.value === graphStatusNew)[0].name
                  : '默认消息'}
              </Tag>
              // <Alert
              //   message={
              //     arrState
              //       .filter((item: any) => item.value === graphStatusNew)
              //       .length > 0
              //       ? arrState.filter((item: any) => item.value === graphStatusNew)[0].name
              //       : '默认消息'
              //   }
              //   type={
              //     arrState
              //       .filter((item: any) => item.value === graphStatusNew)
              //       .length > 0
              //       ? (arrState.filter((item: any) => item.value === graphStatusNew)[0].type as 'warning' | 'success' | 'error' | 'info')
              //       : 'info'
              //   }
              //   style={{ marginLeft: '10px', height: '26px', borderRadius: '5px', ...(graphStatusNew === '3' ? { color: 'var(--text-font-color34)', borderColor: 'var(--text-font-color23)', backgroundColor: 'var(--background2)' } : {}) }}
              // />
            )}
          </div>
          {(!graphStatusNew || graphStatusNew === '0') && (
            <div className={styles.titleBox_left}>
              <div className={styles.titleBox_marginRight4}>自动保存</div>
              <Tooltip title="打开自动保存后，系统会根据你当前的编辑活跃状态自动保存为草稿。监测周期为 5 分钟，可自行开关。" placement="topLeft">
                <QuestionCircleOutlined className={`${styles.titleBox_marginRight4} ${styles.fontSize14} ${styles.grayColor}`} />
              </Tooltip>
              <span className={styles.titleBox_marginRight}>:</span>
              <Switch onChange={onChange} className={styles.titleBox_marginRight24} />
              <div>
                <span className={styles.titleBox_marginRight4}>最近保存</span>
                <span className={styles.titleBox_marginRight}>:</span>
                <span className={styles.titleBox_greyText}>{updatedTime || '--'}</span>
              </div>
              <Button
                className={styles.titleBox_marginRight}
                onClick={() => {
                  onCheck('draft');
                }}>
                保存草稿
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onLayout('publish');
                }}>
                发布
              </Button>
            </div>
          )}
          {graphStatusNew === '1' && (
            <div>
              <Popconfirm
                placement="left"
                title="确定取消启动此策略吗？"
                okText="确定"
                cancelText="取消"
                onConfirm={e => cancelStrategyFuntion()}
                onCancel={e => {}}
                onPopupClick={e => {
                  e.stopPropagation();
                }}>
                <Button danger>取消启动</Button>
              </Popconfirm>
            </div>
          )}
          {graphStatusNew === '2' && (
            <div>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  navigate('/MA/MarketPlan/MAList/ReportData', {
                    state: { id: canvasData.id, graphStatusNew: graphStatusNew },
                  });
                }}>
                查看报表
              </Button>
              <Popconfirm
                placement="left"
                title="确定停止此策略吗？"
                okText="确定"
                cancelText="取消"
                onConfirm={e => strategiesStopFuntion()}
                onCancel={e => {}}
                onPopupClick={e => {
                  e.stopPropagation();
                }}>
                <Button danger>停止</Button>
              </Popconfirm>
            </div>
          )}
          {graphStatusNew === '3' && (
            <div>
              <Button
                onClick={() => {
                  navigate('/MA/MarketPlan/MAList/ReportData', {
                    state: { id: canvasData.id, graphStatusNew: graphStatusNew },
                  });
                }}>
                查看报表
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles['backgournd-grid-app']} ref={dragRef} onMouseDown={handleMouseDown}>
        <div className={styles['operate-box']} style={{ right: settingPanelShow ? 510 : 16 }}>
          <div className={styles['toolbar-card']}>
            <Tooltip title="自动排列">
              <div onClick={onLayout}>
                <YLIcon type="icon-zidongpailie" color={'#8c8c8c'}></YLIcon>
              </div>
            </Tooltip>
          </div>
          {(!graphStatusNew || graphStatusNew === '0') && (
            <div className={styles['toolbar-card']}>
              <Tooltip title="撤销">
                <div onClick={onUndo} style={{ marginRight: 16 }}>
                  <YLIcon type="icon-chexiao" color={!canUndo ? '#9ca8b4' : 'var(--text-font-color18)'}></YLIcon>
                </div>
              </Tooltip>
              <Tooltip title="重做">
                <div onClick={onRedo}>
                  <YLIcon type="icon-huifu" color={!canRedo ? '#9ca8b4' : 'var(--text-font-color18)'}></YLIcon>
                </div>
              </Tooltip>
            </div>
          )}
          <div className={styles['toolbar-card']}>
            <Tooltip title="描述">
              <div onClick={changeDesc}>
                <YLIcon type="icon-miaoshu" color={'#8c8c8c'}></YLIcon>
              </div>
            </Tooltip>
            {(!graphStatusNew || graphStatusNew === '0') && (
              <Tooltip title="网格">
                <div onClick={changeGrid} style={{ marginLeft: 16 }}>
                  <YLIcon type="icon-wangge" color={'#8c8c8c'}></YLIcon>
                </div>
                {/* <TableOutlined onClick={changeGrid} /> */}
              </Tooltip>
            )}
          </div>
          <div className={styles['toolbar-zoom']}>
            <div onClick={smallGraph}>
              <YLIcon type="icon-suoxiao" color={zoomPercent > 10 ? 'var(--text-font-color4)' : 'var(--text-font-color10)'}></YLIcon>
            </div>
            <div style={{ fontSize: 10, color: '#1a263b' }}>{zoomPercent}%</div>
            <div onClick={bigGraph}>
              <YLIcon type="icon-fangda" color={zoomPercent < 100 ? 'var(--text-font-color4)' : 'var(--text-font-color10)'}></YLIcon>
            </div>
          </div>
          {/* <Button onClick={onCheck}>检测</Button> */}
        </div>
        {/* 左侧拖拽区 */}
        {(!graphStatusNew || graphStatusNew === '0') && (
          <div className={styles['dnd-wrap']} style={{ width: nodesPanelShow ? 260 : 0 }} ref={dndContainerRef}>
            {nodesPanelShow && <Tabs className={styles.leftTabsStyle} style={{ margin: '0 24px' }} defaultActiveKey="1" items={leftTabItems} />}
            <motion.div className={styles['lz_sub-menu-arrow-left']} onClick={handleLeftArrowClick}>
              <motion.div
                animate={{ rotate: isArrowLeft ? 0 : 180 }}
                whileTap={{
                  rotate: 180,
                }}>
                <RightOutlined style={{ fontSize: 'var(--text-font2)', color: 'var(--text-font-color4)' }} />
              </motion.div>
            </motion.div>
          </div>
        )}
        <div className={styles['graph-content']} id="graph-container" ref={refContainer} />
        <div className={styles['right-content']} style={{ width: settingPanelShow ? 500 : 0 }}>
          <div className={styles['settingtab-box']} style={{ width: settingPanelShow ? 500 : 0, overflowY: 'auto' }}>
            {graphStatusNew && graphStatusNew != '0' && !countToolShow && settingPanelShow && (
              <div className={styles['right-content-title']}>{nameMap[settingNodeInfo?.data.componentId]}</div>
            )}
            {!countToolShow && settingPanelShow && (
              <Tabs className={styles.tabsStyle} activeKey={activeKey} items={rightTabItems} onChange={onChangeTabs} />
            )}
            {/* 节点数据弹窗  */}
            {countToolShow && <RunningTab ref={runningTabRef} info={settingNodeInfo} type={1}></RunningTab>}
            {/* 测试记录弹窗 */}
            {/* {testRecordShow && <TestRecord accountId={settingNodeInfoLocal.data.account.id} testRecordStatus={testRecordStatus}></TestRecord>} */}
          </div>
          <motion.div className={styles['lz_sub-menu-arrow-right']} onClick={handleRightArrowClick}>
            <div style={{ marginLeft: 5 }}>
              <motion.div
                animate={{ rotate: isArrowRight ? 0 : 180 }}
                whileTap={{
                  rotate: 180,
                }}>
                <RightOutlined style={{ fontSize: 'var(--text-font2)', color: 'var(--text-font-color4)' }} />
              </motion.div>
            </div>
          </motion.div>
        </div>
        <NodesPopup visible={isModalVisible} closePopup={addNodes}></NodesPopup>
        {(graphStatusNew === '2' || graphStatusNew === '3') && canvasData.id && (
          <DataIndicators graphStatus={graphStatusNew} canvasId={canvasData.id}></DataIndicators>
        )}
      </div>
    </div>
  );
};
export default MAStrategy;
