import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Drawer, Space, Button, Form, Input, Select } from 'antd';
import type { FormInstance } from 'antd';
import { 
  getJourneyOrScenes
} from '../../../../../../api/graph/graph';
interface SubmitButtonProps {
  form: FormInstance;
}
let AddSence = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
      senceData?: any;
    },
    ref: any,
  ) => {
    const [form] = Form.useForm();
    const { senceData } = props;
    const [journeyOptions, setJourneyOptions] = useState([] as any); //获取所有旅程
    const addForm = (e: any, type?: any) => {
      form.setFieldsValue(e.value);
    };
    const onFinish = (values: any) => {
      form
        .validateFields()
        .then(res => {
          // 校验通过，执行相应操作
          console.log('校验通过，表单数据为:', res);
          handleDrawerSwitch(res);
        })
        .catch(errorInfo => {});
    };
    //获取旅程
    const getAllJourney = async() => {
      const res = await getJourneyOrScenes('journey');
      if (res) {
        setJourneyOptions(res.data)
      }
    };
    const clearFrom = () => {
      form.resetFields();
    };
    const handleDrawerSwitch = (res: any) => {
      console.log(res);
      const param = {
        id : senceData?.id,
        name : res.name,
        parentId : res.journey,
      }
      if (props.drawer) {
        props.drawer(false, param);
      }
    };
    useEffect(() => {
      if (props.open) {
        //获取旅程
        getAllJourney()
      }
    }, [props.open]);
    useEffect(() => {
      if (senceData?.id) {
        form.setFieldsValue({name: senceData.name});
        form.setFieldsValue({journey: senceData.parentId});
      }
    }, [senceData]);
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
      clearFrom
    }));
    return (
      <div>
        <Drawer
          width={400}
          title={senceData?.id ? '修改场景' : "新建场景"}
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={onFinish}>
                确定
              </Button>
            </Space>
          }>
          <div>
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={onFinish}>
              <Form.Item name="name" label="场景名称" rules={[{ required: true }]}>
                <Input maxLength={20} showCount autoComplete="off" placeholder="请输入场景名称" onChange={addForm} />
              </Form.Item>
              <Form.Item name="journey" label="所属旅程" rules={[{ required: true }]}>
                <Select placeholder="请选择所属旅程" options={ journeyOptions } fieldNames={{label: 'name',value: 'id'}}/>
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      </div>
    );
  },
);
export default AddSence;
