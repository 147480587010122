import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Drawer, Space, Button, Form, Input } from 'antd';
import type { FormInstance } from 'antd';
interface SubmitButtonProps {
  form: FormInstance;
}
let AddJourney = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
      journeyData?: any;
    },
    ref: any,
  ) => {
    const [form] = Form.useForm();
    const { journeyData } = props;
    const addForm = (e: any, type?: any) => {
      form.setFieldsValue(e.value);
    };
    const onFinish = (values: any) => {
      form
        .validateFields()
        .then(res => {
          // 校验通过，执行相应操作
          console.log('校验通过，表单数据为:', res);
          handleDrawerSwitch(res);
        })
        .catch(errorInfo => {});
    };
    const handleDrawerSwitch = (res: any) => {
      console.log(res.name);
      const param = {
        id : journeyData?.id,
        name : res.name
      }
      if (props.drawer) {
        props.drawer(false, param);
      }
    };
    const clearFrom = () => {
      form.resetFields();
    };
    useEffect(() => {
      if (journeyData?.id) {
        form.setFieldsValue({name: journeyData.name});
      }
    }, [journeyData]);
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
      clearFrom
    }))
    return (
      <div>
        <Drawer
          width={400}
          title={journeyData?.id ? '修改旅程' : "新建旅程"}
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={onFinish}>
                确定
              </Button>
            </Space>
          }>
          <div>
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={onFinish}>
              <Form.Item name="name" label="旅程名称" rules={[{ required: true }]}>
                <Input maxLength={20} showCount autoComplete="off" placeholder="请输入旅程名称" onChange={addForm} />
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      </div>
    );
  },
);
export default AddJourney;
