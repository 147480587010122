import React, { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';
import { LeftOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import type { TableProps } from 'antd';
import { Input, Table, GetProps, Tooltip } from 'antd';
import { smsSendRecord } from '../../../../../../api/graph/graph';
import dayjs from 'dayjs';
// #region初始化图形
const TestRecord = (props: { testRecordStatus: Function; accountId: any; canvasId: any }) => {
  const { testRecordStatus = () => {}, accountId, canvasId } = props;
  console.log(999, canvasId);
  //数据列表
  const [listData, setListData] = useState([] as any[]);
  // 页码信息
  type SearchProps = GetProps<typeof Input.Search>;
  const { Search } = Input;
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  //tabel 列表字段
  const columns: TableProps<any>['columns'] = [
    {
      title: '手机号',
      dataIndex: 'maskPhoneNo',
      key: 'maskPhoneNo',
    },
    {
      title: '发送时间',
      dataIndex: 'sendTime',
      key: 'sendTime',
      render(value, record, index) {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '发送内容',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: '发送状态',
      dataIndex: 'sendResult',
      key: 'sendResult',
      render: (_, record) => <span>{_ === 1 ? '成功' : _ === 2 ? '失败' : '未对账'}</span>,
    },
  ];
  // 表格change事件
  const handleTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }
    smsSendRecordFunc({ pageSize: pagination.pageSize, pageNum: pagination.current });
  };
  useEffect(() => {
    smsSendRecordFunc({ pageSize: 10, pageNum: 1 });
  }, []);
  const smsSendRecordFunc = async (page: { pageNum: number; pageSize: number }, phoneNo?: any) => {
    const params = {
      canvasId,
      accountId,
      phoneNo,
      pageNum: page.pageNum,
      pageSize: page.pageSize,
    };
    const res = await smsSendRecord(params);
    if (res) {
      setListData(res.data.records);

      setPageQuery({
        pageSize: res.data.pageSize,
        pageNum: res.data.current,
        total: res.data.total,
      });
    }
  };
  // 搜索框搜索事件
  const onSearch: SearchProps['onSearch'] = async (value, _e, info) => {
    smsSendRecordFunc({ pageSize: 10, pageNum: 1 }, value);
  };
  const closetestRecord = () => {
    testRecordStatus(false);
  };
  return (
    <div style={{ top: '-380px' }}>
      <div className={styles.backButton} onClick={closetestRecord}>
        <LeftOutlined className={styles.icon} />
        <span style={{ marginLeft: 22 }}>测试记录</span>
      </div>
      <div className={styles.testRecordMain}>
        <div className={styles.phoneBox}>
          <span>手机号：</span>
          <Search style={{ width: '86%' }} placeholder="请输入测试手机号" allowClear onSearch={onSearch} />
        </div>
        <div className={styles.tableBox}>
          <Table
            rowKey={record => record.oneId}
            columns={columns}
            // scroll={{ y: 380 }}
            tableLayout="fixed"
            dataSource={listData}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              current: pageQuery.pageNum,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: total => `共 ${total} 条`,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>

      {/* <div className={`${styles.testRecordButton} ${styles.testRecordMargin20}`}>
        <Button style={{marginRight: '10px'}} onClick={ closetestRecord }>取消</Button>
        <Button type="primary" onClick={closetestRecord}>确定</Button>
      </div> */}
    </div>
  );
};
export default TestRecord;
