
//引入所有图标
import * as Icons from '@ant-design/icons';
import React from 'react';

const Iconfont = (props) => {
    //这里传入的props是一个对象，里面有icon属性，值是antd图标的名字
    const { icon, className } = props
    if (!Icons[icon]) {
      return null
    }
    return React.createElement(Icons[icon], { className })
    // return (<i />)
}

export default Iconfont