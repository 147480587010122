import { AxiosPromise } from 'axios';
import request from '../network'


//获取所有种类列表
export function getAllCategoryListApi(): AxiosPromise<any> {
  return request({
    url: 'system/spu/getAllCategoryList',
    method: 'get',
  });
}
//商品详情
export function getSpuGoodsDetailsApi(query:any): AxiosPromise<any> {
  return request({
    url: '/system/spu/goodsDetail',
    method: 'get',
    params: query
  });
}
//删除
export function getDeleteApi(params: any): AxiosPromise<any> {
  return request({
    url: 'system/spu/delete',
    method: 'post',
    data: params
  });
}
//保存spuData
export function getSaveSpuDataApi(params: any): AxiosPromise<any> {
  return request({
    url: 'system/spu/addSpuGoods',
    method: 'post',
    data: params
  });
}
//保存spuData
export function getUpdateSpuDataApi(params: any): AxiosPromise<any> {
  return request({
    url: 'system/spu/editSpuGoods',
    method: 'post',
    data: params
  });
}

// 查看Spu page
export function getSpuListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'system/spu/getSpuList',
    method: 'post',
    data: params
  });
}

// 根据spuIdOne获取sku列表
export function getSkuListApi(query:any): AxiosPromise<any> {
  return request({
    url: 'system/spu/getSkuList',
    method: 'get',
    params: query
  });
}

// 添加类目
export function getAddCategoryApi(params:any): AxiosPromise<any> {
  return request({
    url: 'system/category/addOrDelete',
    method: 'post',
    data: params
  });
}

// 添加产品线
export function getAddProductLineApi(params:any): AxiosPromise<any> {
  return request({
    url: 'system/productLine/addProductLine',
    method: 'post',
    data: params
  });
}

// 添加新品牌类目label/business/detailInfo
export function getAddBrandApi(params:any): AxiosPromise<any> {
  return request({
    url: 'system/brand/addBrand',
    method: 'post',
    data: params
  });
}
// 查询层级
export function getFindCategoryApi(params:any): AxiosPromise<any> {
  return request({
    url: 'label/business/detailInfo',
    method: 'post',
    data: params
  });
}
// 查询父级ID
export function getParentIdApi(): AxiosPromise<any> {
  return request({
    url: 'system/category/getParentId',
    method: 'get'
  });
}
// 根据层级获取列表
export function getListByStratumApi(query:any): AxiosPromise<any> {
  return request({
    url: 'system/category/listByStratum',
    method: 'get',
    params: query
   });
}
// 删除校验
export function getCheckSkuApi(query:any): AxiosPromise<any> {
  return request({
    url: 'system/spu/checkSku',
    method: 'get',
    params: query
   });
}
// 查找商品
export function getSkuNameBySkuId(query:any): AxiosPromise<any> {
  return request({
    url: 'system/spu/getSku',
    method: 'get',
    params: query
  });
}
