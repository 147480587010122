import { useEffect, useState } from "react";
import { Tooltip, Table } from "antd";
import type { TableProps } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getToolTipText, getNumberWithComma } from '@/crm/utils';
import Top1Img from "@/crm/assets/images/top1.png";
import Top2Img from "@/crm/assets/images/top2.png";
import Top3Img from "@/crm/assets/images/top3.png";
import type { IDataType } from "../index";
import styles from "./index.module.scss";

// table columns: Rank(GMV)、产品名称、购买人数、新客二购购买人数、来源于首单同品(人数、回购占比)、来源于首单异品(人数、回购占比)、新客首单Top1(产品名称、回购人数、回购占比)、新客首单Top2(产品名称、回购人数、回购占比)、新客首单Top3(产品名称、回购人数、回购占比)、新客首单Top4(产品名称、回购人数、回购占比)、新客首单Top5(产品名称、回购人数、回购占比)

const initColumns: TableProps<IDataType>['columns'] = [
  {
    title: '排名',
    dataIndex: 'rank',
    key: 'rank',
    width: 80,
    fixed: 'left',
    render: (text) => {
      if (text === 1) {
        return <img src={Top1Img} alt="Top1" />;
      }
      if (text === 2) {
        return <img src={Top2Img} alt="Top2" />;
      }
      if (text === 3) {
        return <img src={Top3Img} alt="Top3" />;
      }
      return text;
    },
  },
  {
    title: 'analysisDim名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    width: 200,
    fixed: 'left',
    render: (text) => getToolTipText(text ?? '-'),
  },
  {
    title: '购买人数',
    dataIndex: 'buyerCount',
    key: 'buyerCount',
    width: 120,
    render: (text) => getToolTipText(getNumberWithComma(text, { digit: 0 })),
  },
  {
    title: '新客二购购买人数',
    dataIndex: 'buyerExtendCount',
    key: 'buyerExtendCount',
    width: 160,
    render: (text) => getToolTipText(getNumberWithComma(text, { digit: 0 })),
  },
  {
    title: '来源于首单同品',
    children: [
      {
        title: '人数',
        dataIndex: 'tpBuyerCount',
        key: 'tpBuyerCount',
        width: 120,
        render: (text) => getToolTipText(getNumberWithComma(text, { digit: 0 })),
      },
      {
        title: '回购占比',
        dataIndex: 'tpBuyerRate',
        key: 'tpBuyerRate',
        width: 120,
        render: (text) => getToolTipText(getNumberWithComma(text, { digit: 2, isRatio: true })),
      },
    ],
  },
  {
    title: '来源于首单异品',
    children: [
      {
        title: '人数',
        dataIndex: 'ypBuyerCount',
        key: 'ypBuyerCount',
        width: 120,
        render: (text) => getToolTipText(getNumberWithComma(text, { digit: 0 })),
      },
      {
        title: '回购占比',
        dataIndex: 'ypBuyerRate',
        key: 'ypBuyerRate',
        width: 120,
        render: (text) => getToolTipText(getNumberWithComma(text, { digit: 2, isRatio: true })),
      },
    ],
  },
];

// 使用递归计算表格横向滚动条宽度，width 为每一列的宽度
const getTableScrollWidth = (columns: TableProps<IDataType>['columns']) => {
  const getScrollWidth = (columns: TableProps<IDataType>['columns'], width: number = 0): number => {
    return columns!.reduce((prev, current: any) => {
      if (current.children) {
        return getScrollWidth(current.children, prev);
      }
      return prev + (current.width || 0);
    }, width);
  };
  return getScrollWidth(columns);
};

const analysisDimMap: any = {
  单品: '产品',
  类目: '品类',
};

interface IProps {
  dataSource: IDataType[];
  analysisDim: string;
}

const NewCustomer: React.FC<IProps> = (props) => {
  const { dataSource = [], analysisDim } = props;
  const [columns, setColums] = useState<any>(initColumns);
  useEffect(() => {
    const originColumns: any = initColumns.map((item) => ({
      ...item,
      title: (item.title as string).replace(/analysisDim/g, analysisDimMap[analysisDim] ?? '品类'),
    }));
    originColumns.splice(2, 0, analysisDim === '单品' ? {
      title: 'Spu_id',
      dataIndex: 'goodsId',
      key: 'goodsId',
      width: 160,
      render: (text: string) => getToolTipText(text ?? '-'),
    } : undefined);
    const extraColumns = new Array(dataSource[0]?.extendInfoList?.length ?? 0).fill('').map((item, index) => {
      return {
        title: `新客首单Top${index + 1}`,
        children: [
          {
            title: `${analysisDimMap[analysisDim] ?? '品类'}名称`,
            dataIndex: [`Top${index + 1}`, 'goodsName'],
            key: `Top${index + 1}`,
            width: 160,
            render: (text: string) => getToolTipText(text ?? '-'),
          },
          analysisDim === '单品' ? {
            title: 'Spu_id',
            dataIndex: [`Top${index + 1}`, 'goodsId'],
            key: `Top${index + 1}`,
            width: 120,
            render: (text: string) => getToolTipText(text ?? '-'),
          } : undefined,
          {
            title: '回购人数',
            dataIndex: [`Top${index + 1}`, 'buyerCount'],
            key: `Top${index + 1}`,
            width: 100,
            render: (text: number) => getToolTipText(getNumberWithComma(text, { digit: 0 })),
          },
          {
            title: '回购占比',
            dataIndex: [`Top${index + 1}`, 'buyerProp'],
            key: `Top${index + 1}`,
            width: 100,
            render: (text: number) => getToolTipText(getNumberWithComma(text, { digit: 2, isRatio: true })),
          },
        ].filter(Boolean),
      };
    });
    setColums([...originColumns.filter(Boolean), ...extraColumns]);
  }, [dataSource, analysisDim]);
  return (
    <div className={styles.NewCustomer}>
      <div className={styles.title}>
        <span>新客二购</span>
        <Tooltip overlayStyle={{ maxWidth: 500 }} placement="right" title={<div>
          1、新客首购商品和二购商品的流转追踪<br />
          2、以统计期销售额Top1商品1为例<br />
          购买人数：统计期内购买商品1的去重人数；<br />
          新客二购购买人数：统计期内，产生首次购买的客户，第二笔订单包含商品1的人数；<br />
          来源于首单同品人数：统计期内，产生首次购买的客户，第一笔订单包含商品1，且第二笔订单包含商品1的人数；<br />
          来源于首单同品回购占比：来源于首单同品人数/新客二购购买人数；<br />
          来源于首单异品人数：统计期内，产生首次购买的客户，第一笔订单包含其他正装商品，且第二笔订单包含商品1的人数<br />
          来源于首单异品连带率：来源于首单异品人数/新客二购购买人数<br />
          3、以新客首单购买Top1商品A，统计期购买Top商品1为例<br />
          回购人数：首单购买了商品A，二单购买了商品1的人数<br />
          回购占比：首单购买了商品A，二单购买了商品1的人数/商品1新客二购购买人数<br />
        </div>}>
          <QuestionCircleOutlined className={styles.questionIcon} />
        </Tooltip>
      </div>
      <Table
        style={{ marginTop: 24 }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: getTableScrollWidth(columns) }}
      />
    </div>
  );
};

export default NewCustomer;