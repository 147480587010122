import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import { Button, Input, Form, Select, message, Tabs, Dropdown, Tooltip } from 'antd';

import styles from './index.module.scss';
import type { FormProps } from 'antd';
const { TextArea } = Input;
// #初始化
const CreateGroup = (props: { openCreateGroupPop: Function; top?: number }) => {
  const { openCreateGroupPop = () => {} } = props;
  const [groupInfo] = Form.useForm();
  // useEffect(() => {

  // }, []);
  const closegroupMes = (type: number) => {
    if (!groupInfo.getFieldValue('crowdName')) {
      message.error('请输入分群名称');
      return;
    }
    //1为取消，2为确定
    openCreateGroupPop(false, type, { crowdName: groupInfo.getFieldValue('crowdName'), crowdDescribe: groupInfo.getFieldValue('crowdDescribe') });
  };
  type FieldType = {
    crowdName?: string;
    crowdDescribe?: string;
  };

  return (
    <div className={styles.createGroupMain} style={{ top: props.top ? `${props.top}vh` : '198px' }}>
      <div>
        <Form form={groupInfo} layout="vertical">
          <Form.Item<FieldType> label="分群名称" name="crowdName" rules={[{ required: true, message: '请输入分群名称' }]}>
            <Input
              maxLength={50}
              placeholder="请输入"
              autoComplete="off"
              showCount
              onChange={e => {
                groupInfo.setFieldValue('group', e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item<FieldType> label="备注信息" name="crowdDescribe">
            <TextArea
              rows={4}
              maxLength={100}
              placeholder="请输入描述"
              onChange={e => {
                groupInfo.setFieldValue('crowdDescribe', e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </div>
      <div className={`${styles.groupMesButton} ${styles.groupMesMargin20}`}>
        <Button style={{ marginRight: '10px' }} onClick={() => openCreateGroupPop(false, 1)}>
          取消
        </Button>
        <Button type="primary" onClick={() => closegroupMes(2)}>
          确定
        </Button>
      </div>
    </div>
  );
};
export default CreateGroup;
