//引入所有图标
import * as Icons from '@ant-design/icons';
import React from 'react';

const YLIcon = props => {
  //这里传入的props是一个对象，里面有icon属性，值是antd图标的名字
  const { type, color = 'black', size = 16, rotate = 'rotate(0)' } = props;
  // return React.createElement(Icons[icon], { className })
  // return (<i />)
  return <i className={`iconfont ${type}`} style={{ color, fontSize: size, transform: rotate }} />;
};

export default YLIcon;
