import { AxiosPromise } from 'axios';
import request from '../network'
import { BrandVO } from './index';


//获取部门列表
export function getBrandListApi(): AxiosPromise<BrandVO[]> {
    return request({
        url: `/system/brand/list`,
        method: 'get',
        data: {}
    });
}

export default {}