/**
 * 总览每个小块
 * */
import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import MiniLineChat from "./component/MiniLineChat";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
function OverviewItem(props: { dataSource: any, searchForm: any }) {
  const { dataSource, searchForm } = props;
  const chartRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // @ts-ignore
  const [chartWidth, setChartWidth] = useState((window.innerWidth - 320 - 24 * 4) / 4);

  // 浏览器大小变化赋值每一块元素宽度，作为图表宽度
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (chartRef.current) {
        // @ts-ignore
        setChartWidth(chartRef.current?.clientWidth);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {
        // @ts-ignore
        setChartWidth(chartRef.current?.clientWidth);
      });
    };
  }, [windowWidth]);
  // 同环比数据显示
  const radioDataShow = (value: any) => {
    if (!value || value === '-' || value === '') {
      return '-';
    }
    if (Number(value) > 0) {
      return (
        <div className={styles.radioDataWrap}>
          <div className={styles.value}>{value}%</div>
          <div className={styles.arrowUp}></div>
        </div>
      );
    } else if (Number(value) < 0) {
      return (
        <div className={styles.radioDataWrap}>
          <div className={styles.value}>{value}%</div>
          <div className={styles.arrowDown}></div>
        </div>
      );
    } else if (Number(value) === 0)  {
      return  <div className={styles.radioDataWrap}>
          <div className={styles.value}>{value}%</div>
        </div>
    }
  };

  return (
    <div className={styles.container}>
      {dataSource?.map((item: any) => {
        return (
          <div className={styles.overviewItem}>
            <div className={styles.overviewItemTitle}>
              <div className={styles.titleEn}>
                {item.titleEn}
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-wrap" }}>{item.tip}</span>
                  }
                >
                  <QuestionCircleOutlined
                    style={{
                      color: "var(--text-font-color4)",
                      fontSize: "var(--text-font5)",
                      marginLeft: "6px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </div>
              <div className={styles.titleZh}>{item.titleZh}</div>
            </div>
            <div className={styles.overviewItemValue}>
              {item.value ? getNumberWithComma(item.value, item.digit) : '-'}
              {item.percent ? "%" : ""}
            </div>
            <div className={styles.chat} ref={chartRef}>
              <MiniLineChat
                titleZh={item.titleZh}
                metricId={item.metricId}
                chartData={item.miniChatData}
                chartId={`${item.titleZh}}`}
                width={chartWidth}
                color={item.color}
                id={item.id}
                searchForm={searchForm}
                digit={item.digit}
              />
            </div>
            <div className={styles.yoy}>
              <div>同比</div>
              <div className={styles.yoyValue}>{radioDataShow(item.yoy)}</div>
            </div>
            <div className={styles.mom}>
              <div>环比</div>
              <div className={styles.yoyValue}>{radioDataShow(item.mom)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default OverviewItem;
