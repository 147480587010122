import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useRef } from 'react';

import styles from './index.module.scss';
import { Button, Modal, message, Space, Popover, Tabs, Collapse, Tooltip } from 'antd';
import { SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import YLIcon from '../../../../../src/component/Icon/YLIcon.jsx';
import { getStatData } from '../../../../api/graph/graph';
type ExpandIconPosition = 'start' | 'end';
// #region初始化图形
const DataIndicators = (props: { graphStatus: string; canvasId: any }) => {
  const { graphStatus, canvasId } = props;
  const [dataList, setDataList] = useState(true);
  const [userCount, setUserCount] = useState<any>(null);

  const getStatDataFunc = async () => {
    const res = await getStatData({
      canvasId,
    });
    if (res) {
      setUserCount(res.data);
    }
  };
  useEffect(() => {
    getStatDataFunc();
  }, []);
  const closeDataList = () => {
    setDataList(!dataList);
  };
  return (
    <div className={styles.dataIndicatorsMain}>
      <div className={styles.dataIndicatorsTitle}>
        <div>
          <span style={{ marginRight: '9px', fontSize: '16px' }}>数据指标</span>
          <Tooltip
            placement="topLeft"
            title={
              <>
                (1)成功进入人次:成功进入当前自动流的客户人次。
                <br />
                (2)已结束人次:成功进入当前自动流且已走完自动流至结束组件的客户人次。
              </>
            }>
            <QuestionCircleOutlined style={{ fontSize: '14px', color: 'var(--text-font-color12)' }} />
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={closeDataList}>
          <YLIcon type="icon-zhankai1" size={16} color={'var(--text-font-color18)'}></YLIcon>
        </div>
      </div>
      {dataList && (
        <div className={styles['dataIndicators-prompt']}>
          <div className={styles['dataIndicators-prompt-item']} style={{ marginRight: '12px' }}>
            <div className={styles['dataIndicators-prompt-iconBox']}>
              <YLIcon type="icon-chenggongjinru" size={18} color={'#52c41a'}></YLIcon>
              <div className={styles['dataIndicators-prompt-text']}>成功进入人次</div>
            </div>
            <div className={styles['dataIndicators-prompt-num']}>{userCount?.userEnterCount}</div>
          </div>
          <div className={styles['dataIndicators-prompt-item']} style={{ background: graphStatus === '3' ? 'var(--background2)' : '' }}>
            <div className={styles['dataIndicators-prompt-iconBox']}>
              <YLIcon type="icon-a-yijieshurenci2" size={18} color={'var(--text-font-color18)'}></YLIcon>
              <div className={styles['dataIndicators-prompt-text']}>已结束人次</div>
            </div>
            <div className={styles['dataIndicators-prompt-num']}>{userCount?.userCompleteCount}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DataIndicators;
