import { AxiosPromise } from 'axios';
import request from '@/api/network';

// 获取商品分析列表接口
export function getProductSegmentationList(params: { pageNum: number; pageSize: number }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/analysis/report/pageList',
        method: 'post',
        data: params
    });
};
// 分析名称重复校验
export function checkAnalysisName(params: { analysisName: string }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/analysis/report/repeatName',
        method: 'get',
        params,
    });
};
// 商品分析创建接口
export function createProductSegmentation(params: {
  analysisName: string,
  channels: string[],
  shops: string[],
  startDate: string,
  endDate: string,
  analysisDim: string,
  analysisRange: number,
  analysisContent: string,
}): AxiosPromise<any> {
    return request({
        url: '/cdpdata/analysis/report/save',
        method: 'post',
        data: params
    });
};
// 删除商品分析接口
export function deleteProductSegmentation(params: { id: string }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/analysis/report/delete',
        method: 'get',
        params
    });
};
// 查看分析详情接口
export function getProductSegmentationDetail(params: { id: string }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/analysis/report/view',
        method: 'get',
        params,
    });
};

// 商品细分导出 excel
export function exportProductSegmentationExcel(params: { 
  id: string
  taskLocation: string
  moduleName: string
}): AxiosPromise<any> {
    return request({
        url: '/cdpdata/analysis/report/reportDownload',
        method: 'post',
        data: params,
    });
};
const allApi = {
  getProductSegmentationList,
  checkAnalysisName,
  createProductSegmentation,
  deleteProductSegmentation,
  getProductSegmentationDetail,
  exportProductSegmentationExcel
}
export default allApi;