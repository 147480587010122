import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import { Button, Input, Form, Select, message, Divider, Space, Tooltip } from 'antd';
import { getGroupList } from '../../../../../../api/graph/graph';
import styles from './index.module.scss';
import type { FormProps } from 'antd';
// #初始化
const CheckGroup = (props: { openCheckGroupPop: Function; openCreateGroupPop: Function; top?: number; crowd?: any }) => {
  const { openCheckGroupPop, openCreateGroupPop = () => {}, crowd } = props;
  const [groupInfo] = Form.useForm();
  const [groupOptions, setGroupOptions] = useState([] as any);
  useEffect(() => {
    console.log(444, crowd);
    if (crowd.crowdName) {
      groupInfo.setFieldValue('group', crowd.crowdId);
      getGroupListFunc(crowd.crowdName);
    } else {
      getGroupListFunc();
    }
    return () => {};
  }, [crowd]);
  //查询人群
  const getGroupListFunc = async (crowdName?: any) => {
    const params = {
      crowdName: crowdName ? crowdName : '',
    };
    const res = await getGroupList(params);
    if (res) {
      console.log('查询人群', res.rows);
      setGroupOptions(
        res.rows.map((item: any) => {
          return {
            label: item.crowdName,
            value: item.crowdId,
          };
        })
      );
    }
  };
  const closegroupMes = (type: number) => {
    if (!groupInfo.getFieldValue('group')) {
      message.error('请选择加入分群名称');
      return;
    }
    //1为取消，2为确定
    openCheckGroupPop(false, type, {
      crowdId: groupInfo.getFieldValue('group'),
      crowdName: groupOptions.filter((e: any) => e.value === groupInfo.getFieldValue('group'))[0].label,
    });
  };
  //创建分群
  const openCreateGroupPopFun = (status: boolean, type?: number, param?: any) => {
    openCreateGroupPop(true);
  };
  type FieldType = {
    group?: string;
    showNull?: string;
  };
  const handleCrowdSearch = (crowdName: string) => {
    getGroupListFunc(crowdName);
  };

  return (
    <div className={styles.checkGroupMain} style={{ top: props.top ? `${props.top}vh` : '40px' }}>
      <div>
        <Form form={groupInfo} layout="vertical">
          <Form.Item<FieldType> label="加入分群" name="group" rules={[{ required: true, message: '请选择' }]}>
            <Select
              allowClear={true}
              style={{ width: '100%' }}
              placeholder="请选择加入分群名称"
              showSearch
              optionFilterProp="label"
              options={groupOptions}
              onSearch={handleCrowdSearch}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 128px 4px' }}>
                    <Button
                      type="link"
                      style={{ width: '100%' }}
                      onClick={() => {
                        openCreateGroupPopFun(true);
                      }}>
                      新建分群
                    </Button>
                  </Space>
                </>
              )}
              onChange={e => {
                if (e === undefined) {
                  getGroupListFunc();
                }
                groupInfo.setFieldValue('group', e);
              }}
            />
          </Form.Item>
        </Form>
      </div>
      <div className={`${styles.groupMesButton} ${styles.groupMesMargin20}`}>
        <Button style={{ marginRight: '10px' }} onClick={() => openCheckGroupPop(false, 1)}>
          取消
        </Button>
        <Button type="primary" onClick={() => closegroupMes(2)}>
          确定
        </Button>
      </div>
    </div>
  );
};
export default CheckGroup;
