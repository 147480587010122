import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import {
  Button,
  Select,
  SelectProps,
  Empty,
  Table,
  DatePicker,
  TableProps,
  Dropdown,
  Space,
  Input,
  Tree,
  TreeDataNode,
  Popconfirm,
  message,
  Tag,
  Tooltip,
} from 'antd';
import { getNumberWithComma } from '@/utils/utils'; //给千位数字加逗号的方法
import { getMarketMsgDetail, getTestMsgDetail, getMarketMsgDetailCsv, getTestMsgDetailCsv } from '../../../../api/graph/graph';
import { QuestionCircleOutlined, PlusOutlined, DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import ModuleTitle from '../../../../component/ModuleTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import BackList from '../../../../component/BackList/backList';
import { timestampToDateTime } from '@/utils/utils';
import { getTableScroll } from '@/store';

const { RangePicker } = DatePicker;

const SMSSENDDETAIL = () => {
  const smsInfoStr = localStorage.getItem('smsInfo');
  const smsInfo = smsInfoStr ? JSON.parse(smsInfoStr) : null;
  const [scrollY, setScrollY] = useState('' as any);

  const searchParamInt: any = {
    ...smsInfo,
    name: '',
    phoneNo: '',
    sendResult: '', //状态
    canvasName: '', //策略名称
  };

  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const [listData, setListData] = useState([] as any);

  const sendStatusOptions = [
    { label: '全部', value: '' },
    { label: '未对账', value: 0 },
    { label: '发送成功', value: 1 },
    { label: '发送失败', value: 2 },
  ];
  const canvasTypeList = [
    { label: '触发型策略', value: 1 },
    { label: '一次性策略', value: 2 },
    { label: '周期性策略', value: 3 },
    { label: '纪念日策略', value: 4 },
  ];

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  const setY = (list: any) => {
    if (list.length < 8) {
      setScrollY(undefined);
    } else {
      setScrollY(getTableScroll());
    }
  };
  const checkStatus = (value: any, data: any) => {
    const item = data.find((item: any) => item.value === value);
    return item ? item.label : '';
  };

  const sendDetailColumns: TableProps['columns'] = [
    {
      title: '策略名称',
      dataIndex: 'canvasName',
      key: 'canvasName',
      width: 300,
      render: (_: any, record: any, i: number) => (
        <Tooltip title={_} placement="topLeft">
          <span className="line-text-one">{_}</span>
        </Tooltip>
      ),
    },
    {
      title: '策略类型',
      dataIndex: 'canvasType',
      key: 'canvasType',
      width: 160,
      render: (_: any, record: any, i: number) => <span>{checkStatus(_, canvasTypeList)}</span>,
    },
    {
      title: '短信内容类型',
      dataIndex: 'smsContentType',
      key: 'smsContentType',
      width: 160,
      render: (_: any, record: any, i: number) => <span>{_ == 'custom' ? '短信自定义' : _ == 'template' ? '短信模板' : ''}</span>,
    },
    {
      title: '短信内容',
      dataIndex: 'content',
      key: 'content',
      width: 400,
      render: (_: any, record: any, i: number) => (
        <Tooltip title={_} placement="topLeft">
          <span className="line-text-two">{_}</span>
        </Tooltip>
      ),
    },
    {
      title: '字数/计费（条）',
      dataIndex: 'numberOfItems',
      key: 'numberOfItems',
      width: 160,
      render: (_: any, record: any, i: number) => (
        <>
          {record.numberOfWords && (
            <span>
              {record.numberOfWords}/{record.numberOfItems}（条）
            </span>
          )}
        </>
      ),
    },
    {
      title: '手机号',
      dataIndex: 'maskPhoneNo',
      key: 'maskPhoneNo',
      width: 150,
      render: (_: any, record: any, i: number) => <span>{_}</span>,
    },
    {
      title: '发送时间',
      dataIndex: 'sendTime',
      key: 'sendTime',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{timestampToDateTime(_)}</span>,
    },
    {
      title: '发送状态',
      dataIndex: 'sendResult',
      key: 'sendResult',
      width: 150,
      render: (_: any, record: any, i: number) => (
        <>
          <span>{checkStatus(_, sendStatusOptions)}</span>
        </>
      ),
    },
  ];
  // 获取表格信息
  const getListData = async (page: { pageSize: number; pageNum: number }) => {
    let res: any = null;
    let param = {
      ...searchParam,
      ...page,
      accountId: smsInfo.accountId,
    };
    delete param.sendType;
    if (smsInfo.sendType == -1) {
      res = await getTestMsgDetail(param);
    } else {
      res = await getMarketMsgDetail(param);
    }
    if (res?.code == 200) {
      const { total, records } = res.data;
      setListData(records);
      setY(records);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: total,
      });
    }
  };

  // 导出
  const saveCsv = async () => {
    if (smsInfo.sendType == -1) {
      const res: any = await getTestMsgDetailCsv({ ...searchParam });
      if (res?.code === 200) {
        message.success('导出中，请到下载中心查看进度');
      } else {
        message.error('导出失败');
      }
    } else {
      const res: any = await getMarketMsgDetailCsv({ ...searchParam });
      if (res?.code === 200) {
        message.success('导出中，请到下载中心查看进度');
      } else {
        message.error('导出失败');
      }
    }
  };

  // 换页
  const handleTableChange = (e: any) => {
    setPageQuery({
      ...pageQuery,
      pageSize: e.pageSize,
      pageNum: e.current,
    });
    getListData({ pageSize: e.pageSize, pageNum: e.current });
  };
  // 处理时间
  const processDate = (date: any) => {
    setDateValue(date);

    let data = [] as any;
    let dateRangeTime = false;
    date.forEach((item: any, index: number) => {
      if (index == 0) {
        data[index] = dateRangeTime
          ? `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} ${item.$H}:${item.$m}:${
              item.$s
            }`
          : `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 00:00:00`;
      } else {
        data[index] = dateRangeTime
          ? `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} ${item.$H}:${item.$m}:${
              item.$s
            }`
          : `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 23:59:59`;
      }
    });
    console.log(data);
    setSearchParam({
      ...searchParam,
      sendTimeStart: data[0],
      sendTimeEnd: data[1],
    });
  };

  const [dates, setDates] = useState([dayjs(searchParam.sendTimeStart), dayjs(searchParam.sendTimeEnd)] as any);
  const [dateValue, setDateValue] = useState([dayjs(searchParam.sendTimeStart), dayjs(searchParam.sendTimeEnd)] as any);

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  // 日期筛选截至到当前日
  const disabledDate = (date: any) => {
    if (!dates) {
      return false;
    }
    let tooLate = null;
    let tooEarly = null;
    tooLate = dates[0] && (date.diff(dates[0], 'days') > 365 || dates[0].diff(date, 'days') > 365);
    tooEarly = dates[1] && (dates[1].diff(date, 'days') > 365 || date.diff(dates[1], 'days') > 365);
    return date.valueOf() >= dayjs().valueOf() || !!tooLate || !!tooEarly;
  };

  const searchFunc = () => {
    getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum });
  };

  const taskNameColumn = {
    title: '节点名称',
    dataIndex: 'taskName',
    key: 'taskName',
    width: 160,
    render: (_: any, record: any, i: number) => (
      <Tooltip title={_} placement="topLeft">
        <span className="line-text-one">{_}</span>
      </Tooltip>
    ),
  };
  const col = (() => {
    let ll = [...sendDetailColumns];
    if (smsInfo.sendType !== -1) {
      ll.splice(2, 0, taskNameColumn);
    }
    return ll;
  })();

  useEffect(() => {
    getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum });
  }, []);

  return (
    <div>
      {/* 标题 */}
      <div className={style.headBox}>
        <BackList></BackList>
        <ModuleTitle mark="large" title={smsInfo.sendType == -1 ? '画布测试短信' : '画布营销短信'} />
        <Button
          onClick={() => {
            saveCsv();
          }}
          style={{ position: 'absolute', right: 24 }}>
          导出
        </Button>
      </div>
      <div className={style.container}>
        <div className={style.searchBox}>
          {/* 搜索条件 */}
          <div className={style.searchPart}>
            <div style={{ gap: 16, display: 'flex', flexWrap: 'wrap' }}>
              <div>
                发送时间：
                <RangePicker
                  style={{ width: 300 }}
                  disabledDate={disabledDate}
                  value={dates || dateValue}
                  onOpenChange={onOpenChange}
                  onCalendarChange={val => setDates(val)}
                  allowClear={false}
                  onChange={(e: any) => {
                    processDate(e);
                  }}
                />
              </div>
              <div>
                策略名称：
                <Input
                  style={{ width: '200px' }}
                  placeholder="请输入策略名称"
                  allowClear
                  value={searchParam.canvasName}
                  onChange={(e: any) => {
                    setSearchParam({
                      ...searchParam,
                      canvasName: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                手机号：
                <Input
                  style={{ width: '200px' }}
                  placeholder="请输入手机号"
                  allowClear
                  value={searchParam.phoneNo}
                  maxLength={11}
                  onChange={(e: any) => {
                    setSearchParam({
                      ...searchParam,
                      phoneNo: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                状态：
                <Select
                  value={searchParam.sendResult}
                  placeholder="请选择"
                  options={sendStatusOptions}
                  style={{ width: 200 }}
                  onChange={data => {
                    setSearchParam({
                      ...searchParam,
                      sendResult: data,
                    });
                  }}></Select>
              </div>
            </div>
            <div className={style.searchButton}>
              <Button
                onClick={() => {
                  let info = { ...searchParam };
                  Object.keys(info).map(key => {
                    info[key] = '';
                  });
                  info.sendTimeStart = smsInfo.sendTimeStart;
                  info.sendTimeEnd = smsInfo.sendTimeEnd;
                  setSearchParam(info);
                  setDateValue([dayjs(smsInfo.sendTimeStart), dayjs(smsInfo.sendTimeEnd)]);
                  setDates([dayjs(smsInfo.sendTimeStart), dayjs(smsInfo.sendTimeEnd)]);
                }}>
                重置
              </Button>
              <Button type="primary" style={{ marginLeft: '8px' }} onClick={searchFunc}>
                查询
              </Button>
            </div>
          </div>
        </div>
        <Table
          columns={col}
          scroll={{ x: 1400, y: scrollY }}
          dataSource={listData}
          rowKey={(record, index: any) => index.toString()}
          pagination={{
            total: pageQuery.total,
            current: pageQuery.pageNum,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={e => handleTableChange(e)}
        />
      </div>
    </div>
  );
};
export default SMSSENDDETAIL;
