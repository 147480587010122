import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import {
  Button,
  Select,
  SelectProps,
  Empty,
  Table,
  DatePicker,
  TableProps,
  Dropdown,
  Space,
  Input,
  Tree,
  TreeDataNode,
  Popconfirm,
  message,
  Tag,
  Tooltip,
} from 'antd';
import { getNumberWithComma, timestampToDateTime } from '@/utils/utils'; //给千位数字加逗号的方法，时间戳转日期
import {
  getSubAccountList,
  getAvailableBalance,
  getFreezeNum,
  getMsgSummaryByAccount,
  getSmsRechargeRecords,
  getMsgSummaryByAccounCsv,
  getSmsRechargeRecordsCsv,
} from '../../../../api/graph/graph';
import { QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import ModuleTitle from '../../../../component/ModuleTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { getTableScroll } from '@/store';

let checkId = 1

const { RangePicker } = DatePicker;
const SMSSEND = () => {
  const smsInfoStr = localStorage.getItem('smsInfo');
  const smsInfo = smsInfoStr ? JSON.parse(smsInfoStr) : null;

  const searchParamInt: any = {
    accountId: '', //短信通道id
    checkId: 1, //查看范围
    status: '', //充值状态
    sendTimeStart: smsInfo?.sendTimeStart ? smsInfo?.sendTimeStart : dayjs().add(-365, 'days').format('YYYY-MM-DD') + ' 00:00:00', //发送汇总-开始时间
    sendTimeEnd: smsInfo?.sendTimeEnd ? smsInfo?.sendTimeEnd : dayjs().format('YYYY-MM-DD') + ' 23:59:59', //发送汇总-结束时间
    rechargeTimeStart: dayjs().add(-365, 'days').format('YYYY-MM-DD') + ' 00:00:00', //发送汇总-开始时间
    rechargeTimeEnd: dayjs().format('YYYY-MM-DD') + ' 23:59:59', //发送汇总-结束时间
  };

  const optionsList: any = [
    { label: '全部', value: '' },
    { label: '充值成功', value: '1' },
    { label: '充值失效', value: '-1' },
    { label: '待处理', value: '8' },
  ];

  const [accountOptions, setAccountOptions] = useState([] as any); //短信通道
  const [sendTotalData, setSendTotalData] = useState([] as any); //短信汇总表格
  const [rechargeData, setRechargeData] = useState([] as any); //充值明细表格
  const [rechargeTable, setRechargeTable] = useState([{}] as any); //转账账号
  const [rechargeOptions, setRechargeOptions] = useState([...optionsList]); //充值状态下拉列表
  const [mesBalanceNum, setMesBalanceNum] = useState<any>(0); //短信余额条数
  const [mesFreezeNum, setMesFreezeNum] = useState<any>(0); //短信冻结条数
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const [showRecharge, setShowRecharge] = useState(false);
  const [scrollY, setScrollY] = useState('' as any);

  const navigate = useNavigate();

  const setY = (list: any) => {
    if (list.length < 5) {
      setScrollY(undefined)
    } else {
      setScrollY(getTableScroll())
    }
  }

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  const searchList: searchItem[] = [
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '短信通道',
      key: 'type',
      fieldNames: {
        label: 'name',
        value: 'id',
      },
      options: accountOptions,
      defaultValue: searchParam.accountId,
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '查看范围',
      key: 'state',
      defaultValue: searchParam.checkId,
      options: [
        { label: '短信发送记录', value: 1 },
        { label: '短信充值记录', value: 2 },
      ],
    },
  ];

  const cardInfo = [
    {
      title: '短信余额',
      tips: '账户剩余短信总量',
      icon: 'http://devdemo.oss-cn-beijing.aliyuncs.com/CJResouece/ma/mesBlue.png',
      number: mesBalanceNum,
    },
    {
      title: '冻结中短信数量',
      tips: '短信发送时，会按照发送量对短信账户进行冻结(预扣除)，在结算后进行解冻并按照实际短信发送条数进行多退少补',
      icon: 'http://devdemo.oss-cn-beijing.aliyuncs.com/CJResouece/ma/mesgreen.png',
      number: mesFreezeNum,
    },
  ];

  const rechargeTableColumns: TableProps['columns'] = [
    {
      title: '公司名称',
      dataIndex: 'sendType',
      key: 'sendType',
      width: '400px',
      render: (_: any, record: any, i: number) => <span>陆泽科技有限公司</span>,
    },
    {
      title: '对公账户',
      dataIndex: 'sendType',
      key: 'sendType',
      width: '300px',
      render: (_: any, record: any, i: number) => <span>411906767510678</span>,
    },
    {
      title: '联行号',
      dataIndex: 'sendType',
      key: 'sendType',
      width: '300px',
      render: (_: any, record: any, i: number) => <span>308222027114</span>,
    },
    {
      title: '开户行',
      dataIndex: 'sendType',
      key: 'sendType',
      width: '500px',
      render: (_: any, record: any, i: number) => <span>招商银行股份有限公司大连五一广场支行</span>,
    },
  ];

  const sendTotalColumns: TableProps['columns'] = [
    {
      title: '短信发送分类',
      dataIndex: 'smsSendType',
      key: 'smsSendType',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{_ == -1 ? '画布测试短信' : '画布营销短信'}</span>,
    },
    {
      title: '短信发送总量（条）',
      dataIndex: 'smsSendCount',
      key: 'smsSendCount',
      width: 150,
      render: (_: any, record: any, i: number) => <span>{getNumberWithComma(_)}</span>,
    },
    {
      title: '短信发送成功总量（条）',
      dataIndex: 'smsSendSuccessCount',
      key: 'smsSendSuccessCount',
      width: 150,
      render: (_: any, record: any, i: number) => <span>{getNumberWithComma(_)}</span>,
    },
    {
      title: '短信发送失败总量（条）',
      dataIndex: 'smsSendFailedCount',
      key: 'smsSendFailedCount',
      width: 150,
      render: (_: any, record: any, i: number) => <span>{getNumberWithComma(_)}</span>,
    },
    {
      title: '起始时间~最近一次发送时间',
      dataIndex: 'time',
      key: 'time',
      width: 250,
      render: (_: any, record: any, i: number) => (
        <span>
          {record.startTime && (
            <>
              {timestampToDateTime(record.startTime)}~{timestampToDateTime(record.lastSendTime)}
            </>
          )}
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: 'sort',
      key: 'sort',
      width: 100,
      fixed: 'right',
      render: (_: any, record: any, i: number) => (
        <Button
          type="link"
          style={{ paddingLeft: 0 }}
          onClick={() => {
            navigate(`/MA/reachContent/SmsSendDetail`);
            let info = {
              accountId: searchParam.accountId,
              sendTimeStart: searchParam.sendTimeStart,
              sendTimeEnd: searchParam.sendTimeEnd,
              sendType: record.smsSendType
            };
            localStorage.setItem('smsInfo', JSON.stringify(info));
          }}>
          查看明细
        </Button>
      ),
    },
  ];

  const rechargeColumns: TableProps['columns'] = [
    {
      title: '充值时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{_}</span>,
    },
    {
      title: '充值金额（元）',
      dataIndex: 'fee',
      key: 'fee',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{getNumberWithComma(_)}</span>,
    },
    {
      title: '充值短信数（条）',
      dataIndex: 'count',
      key: 'count',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{getNumberWithComma(_)}</span>,
    },
    {
      title: '订单号',
      dataIndex: 'outTradeNo',
      key: 'outTradeNo',
      width: 200,
    },
    {
      title: '备注',
      dataIndex: 'payRemark',
      key: 'payRemark',
      width: 200,
      render: (_: any, record: any, i: number) => (
        <Tooltip title={_} placement="topLeft">
          <span className="line-text-one">{_}</span>
        </Tooltip>
      ),
    },
    {
      title: '充值类型',
      dataIndex: 'fieldDifference',
      key: 'fieldDifference',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{_ == 1 ? '文本短信' : _ == 2 ? '富信' : ''}</span>,
    },
    {
      title: '充值状态',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (_: any, record: any, i: number) => <span>{checkStatus(_)}</span>,
    },
  ];

  // 换页
  const handleTableChange = (e: any) => {
    setPageQuery({
      ...pageQuery,
      pageSize: e.pageSize,
      pageNum: e.current,
    });

    getRechargeList(searchParam.accountId, e.pageSize, e.current);
  };

  // 充值状态转文字
  const checkStatus = (status: any) => {
    const option = rechargeOptions.find(item => item.value == status);
    return option ? option.label : '';
  };
  const [dates, setDates] = useState(() => {
    if (smsInfo?.sendTimeStart) {
      return [dayjs(smsInfo.sendTimeStart), dayjs(smsInfo.sendTimeEnd)] as any
    } else {
      return [dayjs().add(-365, 'days'), dayjs()] as any
    }
  }
  );
  const [dateValue, setDateValue] = useState(() => {
    if (smsInfo?.sendTimeStart) {
      return [dayjs(smsInfo.sendTimeStart), dayjs(smsInfo.sendTimeEnd)] as any
    } else {
      return [dayjs().add(-365, 'days'), dayjs()] as any
    }
  });

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  // 日期筛选截至到当前日,且最大只能选择365天
  const disabledDate = (date: any, value: any) => {
    if (!dates) {
      return false;
    }
    let tooLate = null
    let tooEarly = null
    tooLate = dates[0] && ((date.diff(dates[0], 'days') > 365) || (dates[0].diff(date, 'days') > 365));
    tooEarly = dates[1] && ((dates[1].diff(date, 'days') > 365) || (date.diff(dates[1], 'days') > 365));
    return date.valueOf() >= dayjs().valueOf() || !!tooLate || !!tooEarly;
  };

  // 处理时间
  const processDate = (date: any) => {
    setDateValue(date)

    let data = [] as any;
    let dateRangeTime = false;
    date.forEach((item: any, index: number) => {
      if (index == 0) {
        data[index] = dateRangeTime
          ? `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} ${item.$H}:${item.$m}:${item.$s
          }`
          : `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 00:00:00`;
      } else {
        data[index] = dateRangeTime
          ? `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} ${item.$H}:${item.$m}:${item.$s
          }`
          : `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 23:59:59`;
      }
    });

    setSearchParam({
      ...searchParam,
      sendTimeStart: data[0],
      sendTimeEnd: data[1],
      rechargeTimeStart: data[0],
      rechargeTimeEnd: data[1],
    });
  };

  //获取短信通道
  const getSubAccountListFunc = async () => {
    const res = await getSubAccountList({});
    if (res?.code === 200) {
      setAccountOptions(
        res.data.map((item: any) => {
          return {
            label: item.accountName,
            value: item.accountId,
          };
        })
      );
      setSearchParam({
        ...searchParam,
        accountId: smsInfo?.accountId ? smsInfo?.accountId : res.data[0].accountId,
      });
      queryFirst(smsInfo?.accountId ? smsInfo?.accountId : res.data[0].accountId);
    }
  };

  //短信 刷新可用余额
  const reFresh = async (accountId?: any) => {
    const res = await getAvailableBalance({ accountId: accountId ?? searchParam.accountId });
    if (res?.code === 200) {
      setMesBalanceNum(res.data);
    } else {
      setMesBalanceNum(0);
    }
  };
  //短信冻结条数
  const getFreezeNumInfo = async (accountId?: number) => {
    const res = await getFreezeNum({ accountId: accountId ?? searchParam.accountId });
    if (res?.code === 200) {
      setMesFreezeNum(res.data);
    }
  };

  // 获取发送汇总列表
  const getSendTotalList = async (accountId?: number) => {
    let param = {
      ...searchParam,
      accountId: accountId ?? searchParam.accountId,
    }
    const res: any = await getMsgSummaryByAccount(param);
    if (res?.code == 200) {
      if (res.data) {
        setSendTotalData(res.data);
        setY(res.data)
      }
    }
  };

  // 获取短信充值记录列表
  const getRechargeList = async (accountId?: number, pageSize?: number, pageNum?: number) => {
    let param = {
      ...searchParam,
      accountId: accountId ?? searchParam.accountId,
      pageNum: pageNum ?? pageQuery.pageNum,
      pageSize: pageSize ?? pageQuery.pageSize,
    };
    const res: any = await getSmsRechargeRecords(param);
    if (res?.code == 200) {
      if (res.data) {
        let list = [...res.data];
        let pageQuery2 = {
          pageNum: pageNum ?? pageQuery.pageNum,
          pageSize: pageSize ?? pageQuery.pageSize,
          total: 0,
        };
        pageQuery2.total =
          list.length == pageQuery2.pageSize ? list.length * pageQuery2.pageNum + 1 : list.length + pageQuery2.pageSize * (pageQuery2.pageNum - 1);
        setPageQuery({ ...pageQuery2 });
        setRechargeData(list);
        setY(list)
      } else {
        setRechargeData([]);
      }
    }
  };

  // 第一条查询
  const queryFirst = (accountId?: any) => {
    setTimeout(() => {
      reFresh(accountId);
      getFreezeNumInfo(accountId);
      if (checkId == 1) {
        getSendTotalList(accountId);
      } else {
        getRechargeList(accountId);
      }
    }, 10);
    localStorage.removeItem('smsInfo')
  };

  // 导出
  const saveCsv = async () => {
    if (checkId == 1) {
      // 短信发送导出
      const res: any = await getMsgSummaryByAccounCsv({ ...searchParam });
      if (res?.code === 200) {
        message.success('导出中，请到下载中心查看进度');
      } else {
        message.error('导出失败');
      }
    } else {
      // 充值记录导出
      const res: any = await getSmsRechargeRecordsCsv({ ...searchParam });
      if (res?.code === 200) {
        message.success('导出中，请到下载中心查看进度');
      } else {
        message.error('导出失败');
      }
    }
  };

  const rechargeTitle = () => {
    return (
      <>
        <div>输入充值时间及充值状态，可根据搜索条件查询短信充值明细</div>
        <div>转账账号</div>
        <Table columns={rechargeTableColumns} dataSource={rechargeTable} pagination={false} />
      </>
    );
  };

  useEffect(() => {
    getSubAccountListFunc();
  }, []);

  return (
    <div>
      {/* 标题 */}
      <div className={style.headBox}>
        <div className={style.titleBox}>
          <ModuleTitle mark="large" title="短信发送管理" />
        </div>
      </div>

      <div className={style.contentPage}>
        {/* 查询信息 */}
        <div className={style.searchBox}>
          {/* 查询条件 */}
          <div className={style.searchCondition}>
            {searchList.map((item, index) => (
              <div key={item.key} className={style.searchItem}>
                {item.title}：
                <Select
                  value={item.defaultValue}
                  placeholder={item.placeHolder}
                  options={item.options}
                  style={{ width: 200 }}
                  onChange={data => {
                    const newSearchParam = {
                      ...searchParam,
                      [index === 0 ? 'accountId' : 'checkId']: data,
                    };
                    setSearchParam(newSearchParam);
                    if (index == 0) {
                      queryFirst(data)
                    } else {
                      setShowRecharge(!showRecharge)
                      checkId = data
                      queryFirst()
                    }
                  }}></Select>
              </div>
            ))}
          </div>
        </div>
        {/* 卡片信息 */}
        <div className={style.cardInfoPart}>
          {cardInfo.map((item, index) => (
            <div className={style.infoCard}>
              <img className={style.icon} src={item.icon} alt="" />
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: 10 }}>
                  <span className={style.titleCardSize}>{item.title}</span>
                  <Tooltip title={item.tips} placement="topLeft">
                    <QuestionCircleOutlined className={style.questionIcon} />
                  </Tooltip>
                </div>
                <div className={style.infoCardNumber}>
                  <span style={{ fontSize: 20, fontWeight: 'bold', marginRight: 5 }}>{getNumberWithComma(item.number)}</span>
                  <span style={{ opacity: .45, fontSize: 12 }}>条</span>
                  {index == 0 && (
                    <ReloadOutlined className={style.refreshBtn} onClick={() => {
                      reFresh();
                    }} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 短信发送汇总 */}
        <div className={style.sendInfoPart}>
          {/* 标题 */}
          <div className={style.smsSendTitle}>
            <span>
              {!showRecharge && (
                <>
                  <span className={style.titleSize}>短信发送汇总</span>
                  <Tooltip title="按照短信发送分类，进行短信发送汇总及查看发送明细" placement="topLeft">
                    {' '}
                    <QuestionCircleOutlined className={style.questionIcon} />
                  </Tooltip>
                </>
              )}
              {showRecharge && (
                <>
                  <span className={style.titleSize}>短信充值明细</span>
                  <Tooltip title={rechargeTitle} overlayStyle={{ maxWidth: 700 }} placement="topLeft" fresh={true}>
                    {' '}
                    <QuestionCircleOutlined className={style.questionIcon} />
                  </Tooltip>
                </>
              )}
            </span>
            <Button
              onClick={() => {
                saveCsv();
              }}>
              导出
            </Button>
          </div>
          {/* 查询信息 */}
          <div className={style.searchBox}>
            <div>
              {!showRecharge ? '发送时间' : '充值时间'}：
              <RangePicker
                style={{ width: '400px' }}
                disabledDate={disabledDate}
                onOpenChange={onOpenChange}
                allowClear={false}
                value={dates || dateValue}
                onCalendarChange={val => setDates(val)}
                onChange={(e: any) => {
                  processDate(e);
                }}
              />
              {showRecharge && (
                <>
                  <span style={{ marginLeft: 20 }}>充值状态：</span>
                  <Select
                    value={searchParam.status}
                    placeholder="请选择"
                    options={rechargeOptions}
                    style={{ width: 200 }}
                    onChange={data => {
                      const newSearchParam = {
                        ...searchParam,
                        status: data,
                      };
                      setSearchParam(newSearchParam);
                    }}></Select>
                </>
              )}
            </div>
            {/* 查询按钮 */}
            <div>
              <Button
                onClick={() => {
                  setDateValue([dayjs().add(-365, 'days'), dayjs()])
                  setDates([dayjs().add(-365, 'days'), dayjs()])
                  setSearchParam({
                    ...searchParam,
                    sendTimeStart: dayjs().add(-365, 'days').format('YYYY-MM-DD') + ' 00:00:00',
                    sendTimeEnd: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
                    rechargeTimeStart: dayjs().add(-365, 'days').format('YYYY-MM-DD') + ' 00:00:00',
                    rechargeTimeEnd: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
                    status: '',
                  });
                }}>
                重置
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                onClick={() => {
                  if (checkId == 1) {
                    getSendTotalList();
                  } else {
                    getRechargeList();
                  }
                }}>
                查询
              </Button>
            </div>
          </div>
          {/* 数据表格 */}
          <div>
            {!showRecharge && <Table scroll={{ x: 1400, y: scrollY }} columns={sendTotalColumns} dataSource={sendTotalData} pagination={false} />}
            {showRecharge && (
              <Table
                scroll={{ x: 1400, y: scrollY }}
                columns={rechargeColumns}
                dataSource={rechargeData}
                pagination={{
                  total: pageQuery.total,
                  pageSize: pageQuery.pageSize,
                  current: pageQuery.pageNum,
                  showSizeChanger: true,
                  itemRender(current, type, element) {
                    if (type === 'page' && current === pageQuery.pageNum) {
                      return <span>{current}</span>;
                    }
                    if (type === 'prev') {
                      return <a>上一页</a>;
                    }
                    if (type === 'next') {
                      return <a>下一页</a>;
                    }
                  },
                }}
                onChange={e => handleTableChange(e)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SMSSEND;
