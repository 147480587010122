import { useEffect, useRef } from 'react';
import { getNumberWithComma } from '@/crm/utils';
import * as echarts from 'echarts';

const colorList = ['#68ABFC', '#5AD8A6', '#C2E45D', '#FFD84E', '#FFAE5E', '#F5906E', '#ED6D6D', '#F590A9', '#B0A2DB', '#948DED', '#446FD4',];

interface IProps {
  data: {
    [key: string]: any;
  };
  series: {
    name: string;
    dataIndex: string;
    type: string;
    color: string;
  }[];
}

const BarLineCahrt: React.FC<IProps> = (props) => {
  const { data, series } = props;
  const containRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const myChart = echarts.init(containRef.current as HTMLDivElement);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      color: colorList,
      legend: {
        data: series.map((item) => item.name),
        left: 0,
      },
      grid: {
        left: 0,
        right: 0,
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: data.map((item: any) => item.product),
        axisLabel: {
          interval: 0,
          width: 1000 / data.length,
          overflow: 'truncate', // or truncate
        },
      },
      yAxis: [
        {},
        {
          axisLabel: {
            formatter: '{value} %',
          },
        },
      ],
      series: series.map(item => ({
        name: item.name,
        type: item.type,
        data: data.map((d: any) => d[item.dataIndex]),
        yAxisIndex: item.type === 'line' ? 1 : 0,
        emphasis: {
          focus: 'series'
        },
        // type === bar 时 barWidth 20px
        barWidth: item.type === 'bar' ? 20 : undefined,
        barGap: '0%',
        symbol: 'none',
        itemStyle: {
          color: item.color,
        },
        tooltip: {
          valueFormatter: function (value: string | number) {
            return item.type === 'line' ? `${value}%` : getNumberWithComma(value, {
              digit: typeof value === 'number' ? 0 : 2,
            });
          }
        },
      })),
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener('resize', () => {
        myChart.resize();
      });
    };
  }, []);
  return (
    <div>
      <div ref={containRef} style={{
        width: '100%',
        height: '400px',
      }}></div>
    </div>
  );
};

export default BarLineCahrt;

