import G6 from "@antv/g6";

export interface RelatedItem {
  goodsId: string; // source
  goodsName: string; // name
  saleAmount: number; // related_amount
  buyerCount: number; // related_count
  rate: number; // related_rate
}

export interface BubbleDataItem {
  goodsId: string; // source
  goodsName: string; // name
  saleAmount: number; // amount
  buyerCount: number; // count
  tdldCount: number; // people
  tdldRate: number; // rate
  tdldTpCount: number; // sameProductPeople
  tdldTpRate: number; // sameProductRate
  tdldKpCount: number; // crossProductPeople
  tdldKpRate: number; // crossProductRate
  sitList: RelatedItem[];
}

// const data: BubbleDataItem[] = [
//   {
//     // 来源
//     "source": "商品 1",
//     // 销售额
//     "amount": 10000,
//     //购买人数
//     "count": 100,
//     // 剩余字段： 同单连带人数、同单连带率、同单连带-同品人数、同单连带-同品率、同单连带-同品金额。。。
//     // 连带商品
//     "related": [
//       {
//         "name": "商品 11",
//         // 连带人数数值
//         "related_count": 30,
//         // 连带率
//         "related_rate": 0.3,
//         // 连带商品购买金额
//         "related_amount": 3000
//       },
//       {
//         "name": "商品 12",
//         "related_count": 20,
//         "related_rate": 0.2,
//         "related_amount": 2000
//       },
//       {
//         "name": "商品 13",
//         "related_count": 10,
//         "related_rate": 0.1,
//         "related_amount": 1000
//       },
//       {
//         "name": "商品 2",
//         "related_count": 8,
//         "related_rate": 0.08,
//         "related_amount": 800
//       },
//       {
//         "name": "商品 3",
//         "related_count": 5,
//         "related_rate": 0.05,
//         "related_amount": 500
//       }
//     ]
//   },
//   {
//     "source": "商品 2",
//     // 销售额
//     "amount": 18000,
//     //购买人数
//     "count": 180,
//     "related": [
//       {
//         "name": "商品 21",
//         "related_count": 20,
//         "related_rate": 0.25,
//         "related_amount": 700
//       },
//       {
//         "name": "商品 22",
//         "related_count": 10,
//         "related_rate": 0.13,
//         "related_amount": 600
//       },
//       {
//         "name": "商品 23",
//         "related_count": 5,
//         "related_rate": 0.06,
//         "related_amount": 500
//       },
//       {
//         "name": "商品 1",
//         "related_count": 8,
//         "related_rate": 0.1,
//         "related_amount": 800
//       },
//       {
//         "name": "商品 5",
//         "related_count": 3,
//         "related_rate": 0.4,
//         "related_amount": 300
//       }
//     ]
//   },
//   {
//     "source": "商品 3",
//     // 销售额
//     "amount": 16000,
//     //购买人数
//     "count": 160,
//     "related": [
//       {
//         "name": "商品 31",
//         "related_count": 20,
//         "related_rate": 0.25,
//         "related_amount": 700
//       },
//       {
//         "name": "商品 32",
//         "related_count": 10,
//         "related_rate": 0.13,
//         "related_amount": 600
//       },
//       {
//         "name": "商品 33",
//         "related_count": 5,
//         "related_rate": 0.06,
//         "related_amount": 500
//       },
//       {
//         "name": "商品 23",
//         "related_count": 8,
//         "related_rate": 0.1,
//         "related_amount": 800
//       },
//       {
//         "name": "商品 1",
//         "related_count": 3,
//         "related_rate": 0.4,
//         "related_amount": 300
//       }
//     ]
//   },
//   {
//     "source": "商品 4",
//     // 销售额
//     "amount": 14000,
//     //购买人数
//     "count": 140,
//     "related": [
//       {
//         "name": "商品 41",
//         "related_count": 20,
//         "related_rate": 0.25,
//         "related_amount": 700
//       },
//       {
//         "name": "商品 42",
//         "related_count": 10,
//         "related_rate": 0.13,
//         "related_amount": 600
//       },
//       {
//         "name": "商品 43",
//         "related_count": 5,
//         "related_rate": 0.06,
//         "related_amount": 500
//       },
//       {
//         "name": "商品 1",
//         "related_count": 8,
//         "related_rate": 0.1,
//         "related_amount": 800
//       },
//       {
//         "name": "商品 5",
//         "related_count": 3,
//         "related_rate": 0.4,
//         "related_amount": 300
//       }
//     ]
//   },
//   {
//     "source": "商品 5",
//     // 销售额
//     "amount": 12000,
//     //购买人数
//     "count": 120,
//     "related": [
//       {
//         "name": "商品 5",
//         "related_count": 20,
//         "related_rate": 0.25,
//         "related_amount": 700
//       },
//       {
//         "name": "商品 51",
//         "related_count": 10,
//         "related_rate": 0.13,
//         "related_amount": 600,
//       },
//       {
//         "name": "商品 2",
//         "related_count": 5,
//         "related_rate": 0.06,
//         "related_amount": 500
//       },
//       {
//         "name": "商品 52",
//         "related_count": 8,
//         "related_rate": 0.1,
//         "related_amount": 800
//       },
//       {
//         "name": "商品 53",
//         "related_count": 3,
//         "related_rate": 0.4,
//         "related_amount": 300
//       }
//     ]
//   },
// ];

export const colorList = ['#68ABFC', '#5AD8A6', '#C2E45D', '#FFD84E', '#FFAE5E', '#F5906E', '#ED6D6D', '#F590A9', '#B0A2DB', '#948DED', '#446FD4',];

// 传入一个十六进制颜色值，返回 透明度为 0.1 的 rgb
export const hexToOpacity = (hex: string, opacity = 0.1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/**
 * format the string
 * @param {string} str The origin string
 * @param {number} maxWidth max width
 * @param {number} fontSize font size
 * @return {string} the processed result
 */
export const fittingString = (str: string, maxWidth: number, fontSize: number) => {
  let currentWidth = 0;
  let res = str;
  const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
  // 记录需要换行的节点位置
  const needWrapIndex: number[] = [];
  str.split('').forEach((letter, i) => {
    // if (currentWidth > Number(maxWidth)) return;
    if (pattern.test(letter)) {
      // Chinese charactors
      currentWidth += fontSize;
    } else {
      // get the width of single letter according to the fontSize
      currentWidth += G6.Util.getLetterWidth(letter, fontSize);
    }
    if (currentWidth > (Number(maxWidth) * needWrapIndex.length + 1)) {
      needWrapIndex.push(i);
    }
  });
  needWrapIndex.filter(i => i !== 0).reverse().forEach(index => {
    res = res.slice(0, index) + '\n' + res.slice(index);
  });
  
  
  return res;
};

export const getTopMaxAmount = (data: BubbleDataItem[]) => Math.max(...data.map(item => item.saleAmount));
const getLevel2MaxAmount = (data: BubbleDataItem[]) => Math.max(...data.map(item => Math.max(...item.sitList.map(relatedItem => relatedItem.saleAmount))));
const getLevel2TopRate = (data: BubbleDataItem[]) => Math.max(...data.map(item => Math.max(...item.sitList.map(relatedItem => relatedItem.rate))));

const getTopNodeSize = (amount: number, maxValue: number) => {
  if (maxValue === 0) {
    return 120;
  }
  // 最大值 140, 最小值 120
  return 120 + (Number(amount ?? 0) / maxValue) * 20;
};
const getLevel2NodeSize = (amount: number, maxValue: number) => {
  if (maxValue === 0) {
    return 80;
  }
  // 最大值 100, 最小值 80
  return 80 + (Number(amount ?? 0) / maxValue) * 20;
}
const getLineWidth = (amount: number, maxValue: number) => {
  if (maxValue === 0) {
    return 2;
  }
  // 最大值 3, 最小值 1
  return 1 + (Number(amount ?? 0) / maxValue) * 2;
};

// 生成指定数量的节点，传入节点的 index，返回节点的位置 { x: number, y: number }[], 尽量使节点分布均匀
const getTopNodePosition = (index: number, size:number) => {
  const row = Math.floor(Math.sqrt(size));
  const col = Math.ceil(size / row);
  const x = index % col;
  const y = Math.floor(index / col);
  return {
    x: x * 150,
    y: y * 150,
  };
};

export const formatData = (data: BubbleDataItem[]): { edges: any[]; nodes: any[]; mutualEdges: any[] } => {
  const topMaxAmount = getTopMaxAmount(data);
  const level2MaxAmount = getLevel2MaxAmount(data);
  const level2TopRate = getLevel2TopRate(data);
  // 平铺节点
  const nodes: any[] = data.sort((a, b) => Number(b.saleAmount) - Number(a.saleAmount)).map((item, index) => {
    return {
      id: item.goodsId,
      label: fittingString(item.goodsName, getTopNodeSize(item.saleAmount, topMaxAmount), 12),
      name: item.goodsName,
      size: getTopNodeSize(item.saleAmount, topMaxAmount),
      properties: item,
      cluster: index,
      isLeaf: false,
      parent: 'root',
      ...getTopNodePosition(index, data.length),
      style: {
        fixedColor: colorList[index],
        fill: colorList[index],
        stroke: colorList[index],
      },
    };
  });
  // 向 nodes 中添加连带商品， 如果连带商品已经在 nodes 中存在，则不添加
  data.forEach((item, index) => {
    item.sitList.forEach(relatedItem => {
      const isExist = nodes.some(node => node.id === relatedItem.goodsId);
      if (!isExist) {
        nodes.push({
          isNotMatched: relatedItem.goodsName === '未匹配的spu',
          parentName: item.goodsName,
          id: relatedItem.goodsId || `${relatedItem.goodsName}-父ID:${item.goodsId}`,
          // label: relatedItem.goodsName,
          name: relatedItem.goodsName,
          size: getLevel2NodeSize(relatedItem.saleAmount, level2MaxAmount),
          properties: relatedItem,
          cluster: index,
          isLeaf: true,
          parent: item.goodsId,
          style: {
            fixedColor: colorList[index],
            fill: hexToOpacity(colorList[index], 0.6),
            stroke: hexToOpacity(colorList[index], 0.6),
          }
        });
      }
    });
  });

  const edges = data.map(item => {
    return item.sitList.map(relatedItem => {
      return {
        source: item.goodsId,
        target: relatedItem.goodsId || `${relatedItem.goodsName}-父ID:${item.goodsId}`,
        properties: relatedItem,
        // label: `连带率 ${relatedItem.related_rate * 100}%`,
        // type: 'quadratic',
        style: {
          // fixedLineWidth: getLineWidth(relatedItem.related_rate),
          lineWidth: getLineWidth(relatedItem.rate, level2TopRate),
          fixedColor: nodes.find(node => node.id === item.goodsId).style.fill,
          stroke: nodes.find(node => node.id === item.goodsId).style.fill,
          // storke: '#e0e0e0',
          endArrow: true,
        },
        // curveOffset: 20,
      };
    });
  }).flat();
  console.log({
    nodes,
    edges: edges.filter(edge => edge.target !== edge.source),
    mutualEdges: edges.filter(edge => edge.target === edge.source),
  });
  
  return {
    nodes,
    edges: edges.filter(edge => edge.target !== edge.source),
    mutualEdges: edges.filter(edge => edge.target === edge.source),
  };
};

export const getMutualNodes = (edges: { source: string, target: string }[]) => {
  // 互相连带的节点
  return edges.filter(edge => {
    return edge.source === edge.target;
  });
};