import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown, Row, Col, message } from "antd";
import { LeftOutlined, DownOutlined } from "@ant-design/icons";
import ModuleTitle from "@/component/ModuleTitle";
import { getToolTipText } from '@/crm/utils';
import SameOrderJoint from "./SameOrderJoint";
import CrossOrderJoint from "./CrossOrderJoint";
import OldCustomer from "./OldCustomer";
import NewCustomer from "./NewCustomer";
import busEvent from "@/utils/bus";
import IndustryApi from '@/crm/api/dataBoardApi/industry';
import type { BubbleDataItem } from './RelationChart/utils';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import dayjs from 'dayjs';
import styles from "./index.module.scss";

const triggerResize = () => {
  const myEvent = new Event('resize');
  window.dispatchEvent(myEvent);
};

export interface IBarLineData {
  product: string;
  gmv: string;
  buyers: number;
  people: number;
  sameProductPeople: number;
  crossProductPeople: number;
  rate: string;
  sameProductRate: string;
  crossProductRate: string;
}
const formatJSONToSameBarLineData = (data: string): IBarLineData[] => {
  try {
    const objData = JSON.parse(data);
    return objData.map((item: any) => {
      return {
        product: item?.goodsName ?? '-',
        gmv: item.saleAmount,
        buyers: item.buyerCount,
        people: item.tdldCount,
        sameProductPeople: item.tdldTpCount,
        crossProductPeople: item.tdldKpCount,
        rate: item?.tdldRate ?? '-',
        sameProductRate: item?.tdldTpRate ?? '-',
        crossProductRate: item?.tdldKpRate ?? '-',
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};
const formatJSONToBubbleData = (data: string, originalData: IBarLineData[]): BubbleDataItem[] => {
  try {
    const objData = JSON.parse(data);
    return objData.map((item: any) => {
      const originalItem = originalData.find(it => it.product === item.goodsName)!;
      return {
        ...originalItem,
        ...item,
        saleAmount: originalItem?.gmv ?? 0, // amount
        buyerCount: originalItem?.buyers ?? 0, // count
        tdldCount: originalItem?.people ?? 0, // people
        tdldRate: originalItem?.rate ?? 0, // rate
        tdldTpCount: originalItem?.sameProductPeople ?? 0, // sameProductPeople
        tdldTpRate: originalItem?.sameProductRate ?? 0, // sameProductRate
        tdldKpCount: originalItem?.crossProductPeople ?? 0, // crossProductPeople
        tdldKpRate: originalItem?.crossProductRate ?? 0, // crossProductRate
        sitList: item.sitList.map((it: any) => ({
          ...it,
          saleAmount: it?.salesAmount ?? 0, // related_amount
          buyerCount: it?.buyerCount ?? 0, // related_count
          rate: it?.ldRate ?? '0.1', // related_rate
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};
// const formatJSONToCrossBarLineData = (data: string): IBarLineData[] => {
//   try {
//     const objData = JSON.parse(data);
//     return objData.map((item: any) => {
//       return {
//         product: item.goodsName,
//         gmv: item.saleAmount,
//         buyers: item.buyerCount,
//         people: item.kdldCount,
//         sameProductPeople: item.kdldTpCount,
//         crossProductPeople: item.kdldKpCount,
//         rate: item.kdldRate,
//         sameProductRate: item.kdldTpRate,
//         crossProductRate: item.kdldKpRate,
//       };
//     });
//   } catch (e) {
//     return [];
//   }
// };

export interface IDataType {
  key: string;
  rank: number;
  goodsName: string;
  buyerCount: number;
  buyerExtendCount: number;
  tpBuyerCount: number;
  tpBuyerRate: number;
  ypBuyerCount: number;
  ypBuyerRate: number;
  extendInfoList: {
    buyerCount: number;
    buyerProp: number;
    goodsName: string;
  }[];
};
const formatJSONToOldCustomerData = (data: string): IDataType[] => {
  try {
    const objData = JSON.parse(data);
    return objData.map((item: any, index: number) => {
      return {
        key: item.goodsId,
        rank: index + 1,
        ...item,
        tpBuyerRate: Number(item?.tpBuyerRate ?? '-') / 100,
        ypBuyerRate: Number(item?.ypBuyerRate ?? '-') / 100,
        extendInfoList: item.extendInfoList?.map((it: any) => ({
          ...it,
          buyerProp: Number(it?.buyerProp ?? '-') / 100,
        })),
        ...item.extendInfoList?.reduce((prev: any, current: any, index: number) => {
          return {
            ...prev,
            [`Top${index + 1}`]: {
              ...current,
              buyerProp: Number(current?.buyerProp ?? '-') / 100,
            },
          };
        }, {}),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

const Details: React.FC = () => {
  const [downloadType, setDownloadType] = useState<string[]>(['1']);
  const [data, setData] = useState<any>({
    analysisName: '',
    channels: '',
    shops: '',
    dateExtent: '',
    analysisDim: '',
    analysisRange: '',
    analysisContent: '',
    createTime: '',
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [sameOrderJointData, setSameOrderJointData] = useState<any>({
    barLineData: [],
    bubbleData: [],
  });
  const [crossOrderJointData, setCrossOrderJointData] = useState<any>({
    barLineData: [],
    bubbleData: [],
  }); // 跨单连带
  const [oldCustomerData, setOldCustomerData] = useState<IDataType[]>([]);
  const [newCustomerData, setNewCustomerData] = useState<IDataType[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id = '' } = location?.state ?? {};
  const DetailsRef = useRef<HTMLDivElement>(null);
  const onBack = () => {
    navigate("/DataBoard/Industry/ProductSegmentation");
  };
  const onDownload = () => {
    if (downloadType.length === 0) {
      messageApi.error('请选择导出文件类型');
      return;
    }
    if (downloadType.includes('0')) {
      const element = DetailsRef.current;
      const opt = {
        margin: 1,
        filename: `${data.analysisName}-商品细分-${dayjs().format('YYYYMMDDHHmmss')}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 }, // 调整 scale 以适应单页
        jsPDF: { unit: 'in', format: [18, 70], orientation: 'portrait' }, // 确保 format 和 orientation 正确
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };
      html2pdf().set(opt).from(element).save();
    }
    if (downloadType.includes('1')) {
      IndustryApi.exportProductSegmentationExcel({
        id,
        moduleName: "商品细分", //模块名称
        taskLocation: "看板/行业看板/商品细分", //页面位置
      }).then((res: any) => {
        if (res.code === 200) {
          message.success("导出中，请到下载中心查看进度");
        }
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      triggerResize();
    }, 0);
    // 二级菜单隐藏显示时触发 reszie 事件，使此页面下的所有需要在页面尺寸变化时重新计算宽度的子组件触发更新
    busEvent.on('subMenuArrow', () => {
      setTimeout(() => {
        triggerResize();
      }, 500);
    });
  }, []);
  useEffect(() => {
    IndustryApi.getProductSegmentationDetail({ id }).then(res => {
      if (res.data) {
        setData(res.data);
        res.data && res.data.analysisContent?.includes('同单连带(一单)') && setSameOrderJointData({
          barLineData: formatJSONToSameBarLineData(res.data.goodsAnalysisReportDetail?.tdldDisJson ?? ''),
          bubbleData: formatJSONToBubbleData(res.data.goodsAnalysisReportDetail?.tdldSitJson ?? '', formatJSONToSameBarLineData(res.data.goodsAnalysisReportDetail?.tdldDisJson ?? '')),
        });
        res.data && res.data.analysisContent?.includes('跨单连带(一人)') && setCrossOrderJointData({
          barLineData: formatJSONToSameBarLineData(res.data.goodsAnalysisReportDetail?.kdldDisJson ?? ''),
          bubbleData: formatJSONToBubbleData(res.data.goodsAnalysisReportDetail?.kdldSitJson ?? '', formatJSONToSameBarLineData(res.data.goodsAnalysisReportDetail?.kdldDisJson ?? '')),
        });
        res.data && res.data.analysisContent?.includes('老客回购') && setOldCustomerData(formatJSONToOldCustomerData(res.data.goodsAnalysisReportDetail?.lkhgJson ?? ''));
        res.data && res.data.analysisContent?.includes('新客二购') && setNewCustomerData(formatJSONToOldCustomerData(res.data.goodsAnalysisReportDetail?.xkegJson ?? ''));
      }
    });
  }, []);
  return (
    <div className={styles.Details} ref={DetailsRef}>
      {contextHolder}
      <div className={styles.header}>
        <div className={styles.titleRow}>
          <div className={styles["BackList_title"]} onClick={onBack}>
            <LeftOutlined />
            <span className={styles["BackList_text"]}>返回</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ModuleTitle mark="large" title={getToolTipText(data.analysisName) as string} />
            <Dropdown.Button
              disabled={downloadType.length === 0}
              style={{ flex: 0 }}
              icon={<DownOutlined />}
              menu={{
                items: [
                  {
                    key: '0',
                    label: <span>PDF</span>,
                  },
                  {
                    key: '1',
                    label: <span>Excel</span>,
                  },
                ],
                onClick: e => {
                  // e.domEvent.stopPropagation();
                  // 判断 selectedKeys 是否包含当前 key， 如果包含则移除，否则添加
                  setDownloadType([e.key]);
                },
                selectable: true,
                multiple: false,
                selectedKeys: downloadType,
              }}
            >
              <span onClick={onDownload}>
                导出
              </span>
            </Dropdown.Button>
          </div>
        </div>
        <div className={styles.reportInfo}>
          <Row gutter={24} wrap style={{ width: '100%' }}>
            <Col className={styles.col} span={5}>
              <span>渠道：</span>
              <span>{getToolTipText(data?.channels ?? '-')}</span>
            </Col>
            <Col className={styles.col} span={5}>
              <span>店铺：</span>
              <span>{getToolTipText(data?.shops ?? '-')}</span>
            </Col>
            <Col className={styles.col} span={8}>
              <span>时间周期：</span>
              <span>{getToolTipText(data?.dateExtent ?? '-')}</span>
            </Col>
            <Col className={styles.col} span={6}>
              <span>分析维度：</span>
              <span>{getToolTipText(data?.analysisDim ?? '-')}</span>
            </Col>
            <Col className={styles.col} span={5}>
              <span>维度范围：</span>
              <span>{getToolTipText(`Top${data?.analysisRange}`)}</span>
            </Col>
            <Col className={styles.col} span={13}>
              <span>分析内容：</span>
              <span>{getToolTipText(data?.analysisContent ?? '-')}</span>
            </Col>
            <Col className={styles.col} span={6}>
              <span>创建日期：</span>
              <span>{getToolTipText(location?.state?.createTime?.split(' ')?.[0] ?? '-')}</span>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.main}>
        {
          data && data.analysisContent?.includes('同单连带(一单)') && <SameOrderJoint
            analysisDim={data?.analysisDim}
            barLineData={sameOrderJointData.barLineData}
            bubbleData={sameOrderJointData.bubbleData}
          />
        }
        {
          data && data.analysisContent?.includes('跨单连带(一人)') && <CrossOrderJoint
            analysisDim={data?.analysisDim}
            barLineData={crossOrderJointData.barLineData}
            bubbleData={crossOrderJointData.bubbleData}
          />
        }
        {
          data && data.analysisContent?.includes('老客回购') && <OldCustomer analysisDim={data?.analysisDim} dataSource={oldCustomerData} />
        }
        {
          data && data.analysisContent?.includes('新客二购') && <NewCustomer analysisDim={data?.analysisDim} dataSource={newCustomerData} />
        }
      </div>
    </div>
  );
};

export default Details;