/**
 * 人群细分-老客By Recency回购-回购占比TTL
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import * as echarts from "echarts";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import { getGeneralChat } from "@/crm/api/industryBoardApi";
import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
function MemberFourQuadrants(props: any) {
  const colors = ["#0958D9", "#597EF7", "#85A5FF"];
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { searchForm } = props;
  const { metricAllList } = useGetMetricList();
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const [dataSource, setDataSource] = useState([
    {
      titleZh: "留存回购销售额占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "留存回购人数占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "留存回购会员人数占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "召回回购销售额占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "召回回购人数占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "召回回购会员人数占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "沉默回购销售额占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "沉默回购人数占比",
      metricId: "",
      value: "",
    },
    {
      titleZh: "沉默回购会员人数占比",
      metricId: "",
      value: "",
    },
  ]);
  const [finalDataSource, setFinalDataSource] = useState<any>(dataSource);
  const [hasMetricIdDataSource, setHasMetricIdDataSource] = useState([]);
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);

  const setMetricId = () => {
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (
      searchForm?.shopId?.includes("all") &&
      !searchForm?.platformType?.includes("all")
    ) {
      str += "分渠道";
    } else if (
      searchForm?.shopId?.includes("all") &&
      searchForm?.platformType?.includes("all")
    ) {
      str = "";
    }
    const dataSourceCopy = [];
    dataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.titleZh + str;
      });
      if (findItem.length > 0) {
        dataSourceCopy.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setHasMetricIdDataSource(dataSourceCopy);
    setGetMetricIdEnd(true);
  };

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId();
    }
  }, [searchForm, metricAllList]);

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {

      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds: finalShopId,
      };
      setFinalDataSource([]);
      const groupedDataSource = groupArrByFrameWorkType(hasMetricIdDataSource);
      const groupedDataKeys = Object.keys(groupedDataSource);
      const groupedDataValues = Object.values(groupedDataSource);
      // 有几种类别的frameWorkType就循环几次
      groupedDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedDataValues[index].map((i: any) => i.metricId)
        );
      });
    }
  }, [hasMetricIdDataSource]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  // indexIdsArr循环的当次的frameWorkType
  const getChartData = async (searchParams: any, indexIdsArr: any) => {
    const params = {
      ...searchParams,
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      indexIds: indexIdsArr,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
      dateType: searchForm?.dateType,
      indexType: "prop", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        hasMetricIdDataSource.forEach((item: any) => {
          if (res?.data[item.metricId]) {
            item.value = res?.data[item.metricId];
          }
        });
        const group1 = {};
        const group2 = {};
        const group3 = {};
        group1[hasMetricIdDataSource[0].metricId] =
          hasMetricIdDataSource[0].value;
        group1[hasMetricIdDataSource[1].metricId] =
          hasMetricIdDataSource[1].value;
        group1[hasMetricIdDataSource[2].metricId] =
          hasMetricIdDataSource[2].value;
        group2[hasMetricIdDataSource[3].metricId] =
          hasMetricIdDataSource[3].value;
        group2[hasMetricIdDataSource[4].metricId] =
          hasMetricIdDataSource[4].value;
        group2[hasMetricIdDataSource[5].metricId] =
          hasMetricIdDataSource[5].value;
        group3[hasMetricIdDataSource[6].metricId] =
          hasMetricIdDataSource[6].value;
        group3[hasMetricIdDataSource[7].metricId] =
          hasMetricIdDataSource[7].value;
        group3[hasMetricIdDataSource[8].metricId] =
          hasMetricIdDataSource[8].value;
        setFinalDataSource([group1, group2, group3]);
      }
    }
  };
  const nameList = [
    "Retention Existing",
    "Winback Existing",
    "Sleeping Existing",
  ];

  const getSeries = () => {
    const finalSeries = [];
    const result = finalDataSource;
    result?.forEach((item: any, index: number) => {
      finalSeries.push({
        name: nameList[index],
        type: "bar",
        barWidth: 58,
        stack: "total",
        emphasis: {
          focus: "series",
        },
        data: Object.values(item),
        itemStyle: {
          color: colors[index],
        },
      });
    });
    return finalSeries.slice(0, 3);
  };

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: function (value: string) {
          return value + " %";
        },
      },

      legend: {
        // width: 400,
        left: 24,
        itemHeight: 10,
        itemWidth: 10,
        data: [
          { name: "Retention Existing", icon: "rect" },
          { name: "Winback Existing", icon: "rect" },
          { name: "Sleeping Existing", icon: "rect" },
        ],
        formatter: function (name: string) {
          if (
            name === "Retention Existing" ||
            name === "Winback Existing" ||
            name === "Sleeping Existing"
          ) {
            return name;
          }
        },
        textStyle: {
          rich: {
            square: {
              width: 10, // 正方形宽度
              height: 10, // 正方形高度
              backgroundColor: "#5B8FF9", // 正方形颜色
            },
          },
        },
      },
      grid: {
        left: 24,
        right: 24,
        bottom: 0,
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
      },
      xAxis: {
        type: "category",
        data: ["Sales%", "Purchaser%", "Member%"],
        axisTick: {
          show: false, // 不显示坐标轴刻度线
        },
      },
      series: getSeries(),
    };
    let chartDom = document.getElementById("ttlChart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [finalDataSource]);
  return (
    <div className={styles.normalBox} style={{ height: 600, width: "100%" }}>
      <div className={styles.normalHeader}>
        <div>
          回购占比TTL
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                不同客群的销售指标占比选定渠道大盘情况
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div
        id="ttlChart"
        style={{
          width: "100%",
          height: "518px",
          backgroundColor: "#fff",
          paddingTop: "24px",
        }}
      />
    </div>
  );
}

export default MemberFourQuadrants;
