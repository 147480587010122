import { useRef } from 'react';
import { handUploadImgApi } from '../../../../../../api/userIntersection/userIntersection';

import { Upload, message, Button } from 'antd';

// 上传图片尺寸限制
export const checkImageWH = (file: any, width: number, height: number, isEqual?: boolean) => {
  // 参数分别是上传的file，想要限制的宽，想要限制的高
  return new Promise((resolve: any) => {
    let filereader = new FileReader();
    filereader.onload = (e: any) => {
      let src = e.target.result;
      const image = new Image();
      image.onload = function () {
        console.log(image.naturalWidth, image.naturalHeight);
        if (isEqual) {
          if (image.naturalWidth === width && image.naturalHeight === height) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          if (image.naturalWidth <= width && image.naturalHeight <= height) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      };
      image.src = src;
    };
    filereader.readAsDataURL(file);
  });
};

const ImageUpload = ({
  children,
  getImageUrl,
  width,
  height,
  isButton,
  imageIndex,
  isEqual,
}: {
  children: any;
  getImageUrl: (url: string, index: number, name: string) => void;
  width: number;
  height: number;
  isButton: boolean;
  imageIndex: number;
  isEqual?: boolean;
}) => {
  console.log(width, height);
  const pending = useRef(false);
  const beforeUpload = async (file: any) => {
    const isRight = ['image/png', 'image/jpeg'].includes(file.type);
    if (!isRight) {
      message.error('只能上传 .png / .jpg 图片');
      pending.current = true;
      return false;
    }
    const res = await checkImageWH(file, width, height, isEqual);
    if (!res) {
      if (isEqual) {
        message.error(`图片宽高需为${width}x${height}像素`);
        pending.current = true;
        return false;
      } else {
        message.error(`图片宽高需不超过${width}x${height}像素`);
        pending.current = true;
        return false;
      }
    }
    pending.current = false;
    return isRight;
  };

  const handleChange = async (info: any) => {
    if (pending.current) return;
    pending.current = true;
    const params = {
      file: info.file.originFileObj,
    };
    const res = await handUploadImgApi(params);
    if (res?.code === 200 && res?.data?.url) {
      getImageUrl(res?.data?.url || '', imageIndex, res?.data?.fileName || '');
    } else {
      message.error('图片上传失败');
    }
    pending.current = false;
  };

  return !isButton ? (
    <Upload name="avatar" listType="picture-card" showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
      {children}
    </Upload>
  ) : (
    <Upload name="avatar" showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange} maxCount={1}>
      {children}
    </Upload>
  );
};

export default ImageUpload;
