import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Drawer, Space, Button, Form, Input, Select } from 'antd';
import type { FormInstance } from 'antd';
import { 
  getJourneyOrScenes,
  getScenesByJourneyId
} from '../../../../../../api/graph/graph';
interface SubmitButtonProps {
  form: FormInstance;
}
let TransferPacket = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
    },
    ref: any,
  ) => {
    const [form] = Form.useForm();
    const [journeyOptions, setJourneyOptions] = useState([] as any); //获取所有旅程
    const [sceneOptions, setSceneOptions] = useState([] as any); //获取所有场景
    const onFinish = (values: any) => {
      form
        .validateFields()
        .then(res => {
          // 校验通过，执行相应操作
          console.log('校验通过，表单数据为:', res);
          handleDrawerSwitch(res);
        })
        .catch(errorInfo => {});
    };
    const clearFrom = () => {
      form.resetFields();
    };
    //获取旅程
    const getAllJourney = async() => {
      const res = await getJourneyOrScenes('journey');
      if (res) {
        setJourneyOptions(res.data)
      }
    };
    //获取场景
    const getAllScene = async(value:any) => {
      const res = await getScenesByJourneyId(value);
      if (res) {
        setSceneOptions(res.data)
      }
    };
    const handleDrawerSwitch = (res: any) => {
      console.log(res);
      if (props.drawer) {
        props.drawer(false, res.journey || res.scene ? res : null);
      }
    };
    useEffect(() => {
      if (props.open) {
        //获取旅程 
        getAllJourney();
      }
    }, [props.open]);
     // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
      clearFrom
    }));
    return (
      <div>
        <Drawer
          width={400}
          title="移到分组"
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={onFinish}>
                确定
              </Button>
            </Space>
          }>
          <div>
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={onFinish}>
              <Form.Item name="journey" label="所属旅程" rules={[{ required: true }]}>
                <Select placeholder="请选择所属旅程" options={journeyOptions} fieldNames={{label: 'name',value: 'id'}} 
                  onChange={getAllScene}/>
              </Form.Item>
              <Form.Item name="scene" label="所属场景" rules={[{ required: true }]}>
                <Select placeholder="请选择所属场景" options={sceneOptions} fieldNames={{label: 'name',value: 'id'}}/>
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      </div>
    );
  },
);
export default TransferPacket;
