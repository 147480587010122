/**
 * 人群细分-会员四象限追踪-会员表现
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import * as echarts from "echarts";
import styles from "./index.module.scss";
import { Empty } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import { getGeneralChat } from "@/crm/api/industryBoardApi";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function MemberPerformance(props: any) {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { searchForm } = props;
  const { metricAllList } = useGetMetricList();
  const [finalSearchForm, setFinalSearchForm] = useState<any>({});
  const [dataSourceFinal, setDataSourceFinal] = useState<any>([]);
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const [hasMetricIdDataSource, setHasMetricIdDataSource] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);
  const [byPurchaseNumDataSource, setByPurchaseNumDataSource] = useState([
    // 笔单价
    {
      titleZh: "新客新会员笔单价",
      metricId: "",
      value: null,
    },
    {
      titleZh: "老客新会员笔单价",
      metricId: "",
      value: null,
    },
    {
      titleZh: "新客老会员笔单价",
      metricId: "",
      value: null,
    },
    {
      titleZh: "老客老会员笔单价",
      metricId: "",
      value: null,
    },
    // 购买频次
      {
        titleZh: "新客新会员购买频次",
        metricId: "",
        value: null,
      },
      {
        titleZh: "老客新会员购买频次",
        metricId: "",
        value: null,
      },
      {
        titleZh: "新客老会员购买频次",
        metricId: "",
        value: null,
      },
      {
        titleZh: "老客老会员购买频次",
        metricId: "",
        value: null,
    },
      // 客单价
    {
      titleZh: "新客新会员客单价",
      metricId: "",
      value: null,
    },
    {
      titleZh: "老客新会员客单价",
      metricId: "",
      value: null,
    },
    {
      titleZh: "新客老会员客单价",
      metricId: "",
      value: null,
    },
    {
      titleZh: "老客老会员客单价",
      metricId: "",
      value: null,
    },
//笔件数
    {
      titleZh: "新客新会员笔件数",
      metricId: "",
      value: null,
    },
    {
      titleZh: "老客新会员笔件数",
      metricId: "",
      value: null,
    },
    {
      titleZh: "新客老会员笔件数",
      metricId: "",
      value: null,
    },
    {
      titleZh: "老客老会员笔件数",
      metricId: "",
      value: null,
    },
  ]);

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId();
    }
  }, [searchForm, metricAllList]);

  const setMetricId = () => {
    // @ts-ignore
    const copyDataSource = [];
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (searchForm?.shopId?.includes("all") && !searchForm?.platformType?.includes("all")) {
      str += "分渠道";
    } else if(searchForm?.shopId?.includes("all") &&  searchForm?.platformType?.includes("all")){
      str = "";
    }
    // console.log(dataSourceMap.get(customType),'获取的类型', customType)
    byPurchaseNumDataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.titleZh + str;
      });
      if (findItem.length > 0) {
        copyDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setHasMetricIdDataSource(copyDataSource);
    setGetMetricIdEnd(true);
  };

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {
      setMetricId();

      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds:finalShopId,
      };
      setFinalSearchForm(searchParams);
      setDataSourceFinal([]);
      // setHasMetricIdDataSource([]);
      const groupedDataSource = groupArrByFrameWorkType(hasMetricIdDataSource);
      const groupedDataKeys = Object.keys(groupedDataSource);
      const groupedDataValues = Object.values(groupedDataSource);

      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedDataValues[index].map((i: any) => i.metricId)
        );
      });
    }
  }, [searchForm, channelAllList, getMetricIdEnd]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  // indexIdsArr循环的当次的frameWorkType
  const getChartData = async (searchParams: any, indexIdsArr: any) => {
    // console.log("四象限-会员表现处理后的指标", hasMetricIdDataSource);
    const copyDataSource = [...hasMetricIdDataSource];
    const params = {
      ...searchParams,
      indexIds: indexIdsArr,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
        dateType: searchForm?.dateType,
      indexType: "num", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        copyDataSource.forEach((item) => {
          item.value = res?.data?.[item.metricId];
        });
         // 判断copyDataSource数组重是不是每一个的value都等于0
              const noValueSizes = copyDataSource.some(
                (item) => Number(item.value) !== 0
              );
              setShowChart(noValueSizes);
        setDataSourceFinal(copyDataSource);
      }
    }
  };

  const colors = ["#B37FEB", "#B7EB8F", "#F6BD16", "#5D7092"];
  
  useEffect(() => {
    if (showChart) {
      let chartDom = document.getElementById("performanceChart");
      let myChart = echarts.init(chartDom);
      const option = {
        grid: {
          left: 0,
          right: 24,
          bottom: 0,
          top: 44,
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: function (params: {
            name: string;
            value: string;
            data: object;
          }) {
            return `<div>
          <div style="margin-bottom: 5px">${params.name}</div>
          <div style="margin-bottom: 5px;">
          <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: space-between;">
            <span>笔单价</span>
            ${dataSourceFinal[params?.dataIndex]?.value}
          </div>
          <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: space-between;">
            <span>购买频次</span>
            ${dataSourceFinal[params?.dataIndex + 4]?.value}
          </div>
          <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: space-between;">
            <span>客单价</span>
            ${dataSourceFinal[params?.dataIndex + 8]?.value}
          </div>
          <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: space-between;">
            <span>笔件数</span>
            ${dataSourceFinal[params?.dataIndex + 12]?.value}
          </div>
          </div>
        </div>
        `;
          },
        },
        legend: {
          left: 10,
          itemHeight: 10,
          itemWidth: 10,
          data: [
            { name: "新客新会", icon: "rect" },
            { name: "老客新会", icon: "rect" },
            { name: "新客老会", icon: "rect" },
            { name: "老客老会", icon: "rect" },
          ],
          formatter: function (name: string) {
            if (name) {
              return name;
            }
          },
          textStyle: {
            rich: {
              square: {
                width: 10, // 正方形宽度
                height: 10, // 正方形高度
                backgroundColor: "#5B8FF9", // 正方形颜色
              },
            },
          },
        },
        radar: {
          indicator: [
            { name: "笔单价" },
            { name: "购买频次" },
            { name: "客单价" },
            { name: "笔件数" },
          ],
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: [
              {
                value: [dataSourceFinal[0]?.value, dataSourceFinal[4]?.value, dataSourceFinal[8]?.value, dataSourceFinal[12]?.value],
                name: "新客新会",
                symbolSize: 12,
                itemStyle: {
                  color: colors[0],
                  borderColor: "#ffffff",
                  borderWidth: 2,
                },
              },
              {
                value: [dataSourceFinal[1]?.value, dataSourceFinal[5]?.value, dataSourceFinal[9]?.value, dataSourceFinal[13]?.value],
                name: "老客新会",
                symbolSize: 12,
                itemStyle: {
                  color: colors[1],
                  borderColor: "#ffffff",
                  borderWidth: 2,
                },
              },
              {
                value: [dataSourceFinal[2]?.value, dataSourceFinal[6]?.value, dataSourceFinal[10]?.value, dataSourceFinal[14]?.value],
                name: "新客老会",
                symbolSize: 12,
                itemStyle: {
                  color: colors[3],
                  borderColor: "#ffffff",
                  borderWidth: 2,
                },
              },
              {
                value: [dataSourceFinal[3]?.value, dataSourceFinal[7]?.value, dataSourceFinal[11]?.value, dataSourceFinal[15]?.value],
                name: "老客老会",
                symbolSize: 12,
                itemStyle: {
                  color: colors[2],
                  borderColor: "#ffffff",
                  borderWidth: 2,
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      return () => {
        window.removeEventListener("resize", () => {
          myChart.resize();
        });
      };
    }
  }, [dataSourceFinal, showChart]);
  return (
    <div>
      <div className={styles.normalBox} style={{ height: 600 }}>
        <div className={styles.normalHeader}>
          <div>
            会员表现
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-wrap" }}>
                  展示四类客户的指标对比情况
                </span>
              }
            >
              <QuestionCircleOutlined
                style={{
                  color: "var(--text-font-color4)",
                  fontSize: "var(--text-font5)",
                  marginLeft: "6px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </div>
        {showChart && (
          <div
            id="performanceChart"
            style={{
              width: "100%",
              height: "518px",
              marginTop: "24px",
            }}
          ></div>)}
        {!showChart && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className={styles["levelChart_empty"]}
          />
        )}
      </div>
    </div>
  );
}

export default MemberPerformance;
