/**
 * 人群细分-会员四象限追踪-会员分布情况
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import * as echarts from "echarts";
import styles from "./index.module.scss";
import { Select, Button, message, Empty } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getGeneralChat } from "@/crm/api/industryBoardApi";
import { Tooltip } from "antd";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
function MemberFourQuadrants(props: any) {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { searchForm } = props;
  const { metricAllList } = useGetMetricList();
  const [finalSearchForm, setFinalSearchForm] = useState<any>({});
  const [dataSourceFinal, setDataSourceFinal] = useState<any>([]);
  const [showChart, setShowChart] = useState(false);
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const distributionOptions = [
    { label: "按购买人数分布", value: "0" },
    { label: "按销售额分布", value: "1" },
  ];

  const [checkedDistributionType, setCheckedDistributionType] = useState("0");
  const [hasMetricIdDataSource, setHasMetricIdDataSource] = useState([]);
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);
  const [byPurchaseNumDataSource, setByPurchaseNumDataSource] = useState([
    {
      titleZh: "新客新会员购买人数",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
    {
      titleZh: "新客老会员购买人数",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
    {
      titleZh: "老客新会员购买人数",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
    {
      titleZh: "老客老会员购买人数",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
  ]);
  const [bySaleAmountDataSource, setBySaleAmountDataSource] = useState([
    {
      titleZh: "新客新会员销售额",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
    {
      titleZh: "新客老会员销售额",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
    {
      titleZh: "老客新会员销售额",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
    {
      titleZh: "老客老会员销售额",
      metricId: "",
      value: null,
      ttl: null,
      yoy: null,
    },
  ]);

  const setMetricId = (type: string) => {
    // @ts-ignore
    const copyDataSource = [];
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (
      searchForm?.shopId?.includes("all") &&
      !searchForm?.platformType?.includes("all")
    ) {
      str += "分渠道";
    } else if (
      searchForm?.shopId?.includes("all") &&
      searchForm?.platformType?.includes("all")
    ) {
      str = "";
    }
    const dataSource =
      type === "0" ? byPurchaseNumDataSource : bySaleAmountDataSource;
    dataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.titleZh + str;
      });
      if (findItem.length > 0) {
        copyDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setHasMetricIdDataSource(copyDataSource);
    setGetMetricIdEnd(true);
  };

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId(checkedDistributionType);
    }
  }, [searchForm, metricAllList, checkedDistributionType]);

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {
      
      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds: finalShopId,
        isShopAll: searchForm?.isShopAll,
        isPlatformAll: searchForm?.isPlatformAll,
      };
      setFinalSearchForm(searchParams);
      setDataSourceFinal([]);
      // setHasMetricIdDataSource([]);
      const groupedDataSource = groupArrByFrameWorkType(hasMetricIdDataSource);
      const groupedDataKeys = Object.keys(groupedDataSource);
      const groupedDataValues = Object.values(groupedDataSource);
      // 有几种类别的frameWorkType就循环几次，最后合并
      groupedDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedDataValues[index].map((i: any) => i.metricId)
        );
      });
    }
  }, [hasMetricIdDataSource, channelAllList]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  // indexIdsArr循环的当次的frameWorkType
  const getChartData = async (searchParams: any, indexIdsArr: any) => {
    const copyDataSource = [...hasMetricIdDataSource];
    const params = {
      ...searchParams,
      indexIds: indexIdsArr,
      indexType: "num", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        copyDataSource.forEach((item) => {
          item.value = res?.data?.[item.metricId];
        });
      }
      // yoy
      const paramsYoy = {
        ...searchParams,
        indexIds: indexIdsArr,
        indexType: "yoy",
      };
      const resYoy = await getGeneralChat(paramsYoy);
      if (resYoy?.code === 200) {
        Object.keys(resYoy?.data).forEach((item) => {
          copyDataSource.filter((i) => i.metricId === item)[0].yoy =
            resYoy?.data?.[item];
        });
      }
      // mom
      const paramsMom = {
        ...searchParams,
        indexIds: indexIdsArr,
        indexType: "mom",
      };
      const resMom = await getGeneralChat(paramsMom);
      if (resMom?.code === 200) {
        if (resMom?.data) {
          Object.keys(resMom?.data).forEach((item) => {
            copyDataSource.filter((i) => i.metricId === item)[0].mom =
              resMom?.data?.[item];
              
          });
          // 渠道不为all，则指标名后面拼接 “分渠道”
          // 店铺不为all，则指标名后面拼接 “分店铺”
          let str = "";
          if (!searchForm?.shopId?.includes("all")) {
            str += "_shop";
          } else if (!searchForm?.platformType?.includes("all")) {
            str += "_platform";
          } else {
            str = "";
          }
          const paramsDis = {
            ...searchParams,
            indexIds: indexIdsArr.map(
              (item) =>
                `${
                  item.includes("shop")
                    ? item.replace("shop", "w_shop")
                    : item.includes("platform")
                    ? item.replace("platform", "w_platform")
                    : item + "_w"
                }`
            ),
            indexType: "prop",
          };
          const resProp = await getGeneralChat(paramsDis);
          if (resProp?.code === 200) {
            if (resProp?.data) {
              copyDataSource.forEach((item) => {
                Object.keys(resProp?.data).forEach((v, i) => {
                  if (
                    v.includes(
                      item.metricId.includes("shop")
                        ? item.metricId.replace("shop", "w_shop")
                        : item.metricId.includes("platform")
                        ? item.metricId.replace("platform", "w_platform")
                        : item.metricId + "_w"
                    )
                  ) {
                    item.ttl = Object.values(resProp?.data)?.[i];
                  }
                });
              });
              // 判断copyDataSource数组重是不是每一个的value都等于0
              const noValueSizes = copyDataSource.some(
                (item) => Number(item.value) !== 0
              );
              setShowChart(noValueSizes);
              setDataSourceFinal([...copyDataSource]);
            }
          } else {
            message.error(res?.msg);
          }
        }
      }
    }
  };

  const richStyle = {
    triangleUp: {
      backgroundColor: {
        image:
          'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"%3E%3Cpolygon points="5,0 10,8 0,8" fill="%23F5222D"/%3E%3C/svg%3E',
      },
      width: 9,
      height: 6,
      verticalAlign: "middle",
    },
    triangleDown: {
      backgroundColor: {
        image:
          'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"%3E%3Cpolygon points="0,0 10,0 5,8" fill="%2352C41A"/%3E%3C/svg%3E',
      },
      width: 9,
      height: 6,
      verticalAlign: "middle",
    },
    name0: {
      color: "#0054FF",
      fontSize: 16,
      fontWeight: "bold",
      padding: [12, 0, 10, 12],
    },
    name1: {
      color: "#1DB440",
      fontSize: 16,
      fontWeight: "bold",
      padding: [12, 0, 10, 12],
    },
    name2: {
      color: "rgba(0, 0, 0, 0.65)",
      fontSize: 16,
      fontWeight: "bold",
      padding: [12, 0, 10, 12],
    },
    name3: {
      color: "#D48806",
      fontSize: 16,
      fontWeight: "bold",
      padding: [12, 0, 10, 12],
    },
    value: {
      color: "#000",
      fontSize: 24,
      marginRight: 4,
      padding: [4, 4, 0, 12],
    },
    valueNormal: {
      color: "rgba(0, 0, 0, 0.85)",
      fontSize: 14,
      padding: [0, 0, 0, 6],
    },
    unit: {
      color: "rgba(0, 0, 0, 0.45)",
      fontSize: 14,
      lineHeight: 20,
      padding: [6, 0, 0, 12],
    },
    yoyUnit: {
      color: "rgba(0, 0, 0, 0.45)",
      fontSize: 14,
      lineHeight: 20,
      padding: [4, 42, 0, 12],
    },
    ttlUnit: {
      color: "rgba(0, 0, 0, 0.45)",
      fontSize: 14,
      lineHeight: 20,
      padding: [4, 26, 0, 12],
    },
  };

  useEffect(() => {
    if (showChart) {
      let chartDom = document.getElementById("container");
      let myChart = echarts.init(chartDom);
      const option = {
        breadcrumb: {
          show: false, // 禁用面包屑
        },
        visualMap: {
          show: false, // 禁用视觉映射
        },
        tooltip: {
          show: dataSourceFinal.length > 0,
          // show: false,
          trigger: "item",
          formatter: (params: any) => {
            return `${params?.name}<br/>
          ${checkedDistributionType === "0" ? "人数：" : "销售额："}
          ${Number(params?.value).toLocaleString()}
          ${checkedDistributionType === "0" ? "人" : "元"}<br/>
          占比TTL：${
            params?.data.ttl === "-" || !params?.data.ttl
              ? "-"
              : params?.data.ttl + "%"
          }<br/>
          同比：${
            params?.data.yoy === "-" || !params?.data.yoy
              ? "-"
              : params?.data.yoy + "%"
          }<br/>`;
          },
        },
        series: [
          {
            type: "treemap",
            left: 12,
            right: 12,
            top: 0,
            bottom: 12,
            breadcrumb: {
              show: false,
            },
            nodeClick: false, // 禁用点击事件
            roam: false, // 禁用点击移动效果
            minSize: 200,
            data: [
              {
                name: "parent",
                children: [
                  {
                    name: "新客新会",
                    value: dataSourceFinal[0]?.value,
                    ttl: dataSourceFinal[0]?.ttl,
                    yoy: dataSourceFinal[0]?.yoy,
                    itemStyle: {
                      color: "#CDDDFD",
                      borderWidth: 2,
                      borderColor: "#fff",
                    },
                    label: {
                      position: "insideTopLeft",
                      color: "#0054FF",
                      formatter: function (params: {
                        name: string;
                        value: string;
                        data: { ttl: string; yoy: string };
                      }) {
                        return `{name0|${params.name}}\n{unit|${
                          checkedDistributionType === "0" ? "人数" : "销售额"
                        }}\n{value|${Number(
                          params?.value
                        ).toLocaleString()}}{unit|${
                          checkedDistributionType === "0" ? "人" : "元"
                        }}\n{ttlUnit|占比TTL}{valueNormal|${
                          params?.data?.ttl === "-" || !params?.data?.ttl
                            ? "-"
                            : `${params?.data?.ttl} %`
                        }}\n{yoyUnit|同比}${
                          params?.data?.yoy !== "-"
                            ? Number(params?.data?.yoy) < 0
                              ? `{triangleDown|}`
                              : `{triangleUp|}`
                            : ""
                        }{valueNormal|${`${params?.data?.yoy}${
                          params?.data?.yoy === "-" ? "" : "%"
                        }`}}`;
                      },
                      rich: richStyle,
                    },
                  },
                  {
                    name: "新客老会",
                    value: dataSourceFinal[1]?.value,
                    ttl: dataSourceFinal[1]?.ttl,
                    yoy: dataSourceFinal[1]?.yoy,
                    itemStyle: {
                      color: "#CDF3E4",
                      borderWidth: 2,
                      borderColor: "#fff",
                    },
                    label: {
                      color: "#1DB440",

                      formatter: function (params: {
                        name: string;
                        value: string;
                        data: { ttl: string; yoy: string };
                      }) {
                        return `{name1|${params.name}}\n{unit|${
                          checkedDistributionType === "0" ? "人数" : "销售额"
                        }}\n{value|${Number(
                          params?.value
                        ).toLocaleString()}}{unit|${
                          checkedDistributionType === "0" ? "人" : "元"
                        }}\n{ttlUnit|占比TTL}{valueNormal|${
                          params?.data?.ttl === "-" || !params?.data?.ttl
                            ? "-"
                            : `${params?.data?.ttl} %`
                        }}\n{yoyUnit|同比}${
                          params?.data?.yoy !== "-"
                            ? Number(params?.data?.yoy) < 0
                              ? `{triangleDown|}`
                              : `{triangleUp|}`
                            : ""
                        }{valueNormal|${`${params?.data?.yoy}${
                          params?.data?.yoy === "-" ? "" : "%"
                        }`}}`;
                      },
                      rich: richStyle,
                    },
                  },
                  {
                    name: "老客新会",
                    value: dataSourceFinal[2]?.value,
                    ttl: dataSourceFinal[2]?.ttl,
                    yoy: dataSourceFinal[2]?.yoy,
                    itemStyle: {
                      color: "#CED4DE",
                      borderWidth: 2,
                      borderColor: "#fff",
                    },
                    label: {
                      color: "rgba(0, 0, 0, 0.65)",

                      formatter: function (params: {
                        name: string;
                        value: string;
                        data: { ttl: string; yoy: string };
                      }) {
                        return `{name2|${params.name}}\n{unit|${
                          checkedDistributionType === "0" ? "人数" : "销售额"
                        }}\n{value|${Number(
                          params?.value
                        ).toLocaleString()}}{unit|${
                          checkedDistributionType === "0" ? "人" : "元"
                        }}\n{ttlUnit|占比TTL}{valueNormal|${
                          params?.data?.ttl === "-" || !params?.data?.ttl
                            ? "-"
                            : `${params?.data?.ttl} %`
                        }}\n{yoyUnit|同比}${
                          params?.data?.yoy !== "-"
                            ? Number(params?.data?.yoy) < 0
                              ? `{triangleDown|}`
                              : `{triangleUp|}`
                            : ""
                        }{valueNormal|${`${params?.data?.yoy}${
                          params?.data?.yoy === "-" ? "" : "%"
                        }`}}`;
                      },
                      rich: richStyle,
                    },
                  },
                  {
                    name: "老客老会",
                    value: dataSourceFinal[3]?.value,
                    ttl: dataSourceFinal[3]?.ttl,
                    yoy: dataSourceFinal[3]?.yoy,
                    itemStyle: {
                      color: "#FCEBB9",
                      borderWidth: 2,
                      borderColor: "#fff",
                    },
                    label: {
                      color: "#D48806",
                      formatter: function (params: any) {
                        return `{name3|${params.name}}\n{unit|${
                          checkedDistributionType === "0" ? "人数" : "销售额"
                        }}\n{value|${Number(
                          params?.value
                        ).toLocaleString()}}{unit|${
                          checkedDistributionType === "0" ? "人" : "元"
                        }}\n{ttlUnit|占比TTL}{valueNormal|${
                          params?.data?.ttl === "-" || !params?.data?.ttl
                            ? "-"
                            : `${params?.data?.ttl} %`
                        }}\n{yoyUnit|同比}${
                          params?.data?.yoy !== "-"
                            ? Number(params?.data?.yoy) < 0
                              ? `{triangleDown|}`
                              : `{triangleUp|}`
                            : ""
                        }{valueNormal|${`${params?.data?.yoy}${
                          params?.data?.yoy === "-" ? "" : "%"
                        }`}}`;
                      },
                      rich: richStyle,
                    },
                  },
                ],
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      return () => {
        window.removeEventListener("resize", () => {
          myChart.resize();
        });
      };
    }
  }, [dataSourceFinal, showChart]);
  return (
    <div>
      <div className={styles.normalBox} style={{ height: 600 }}>
        <div className={styles.normalHeader}>
          <div>
            会员分布情况
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-wrap" }}>
                  展示四类客户的销售数据分布
                </span>
              }
            >
              <QuestionCircleOutlined
                style={{
                  color: "var(--text-font-color4)",
                  fontSize: "var(--text-font5)",
                  marginLeft: "6px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </div>
        <Select
          options={distributionOptions}
          style={{ width: 360, margin: "24px 0 24px 12px" }}
          value={checkedDistributionType}
          onChange={(e) => setCheckedDistributionType(e)}
        ></Select>
        {showChart && (
          <div>
            <div
              id="container"
              style={{
                width: "100%",
                height: "460px",
              }}
            ></div>
          </div>
        )}
        {!showChart && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className={styles["levelChart_empty"]}
          />
        )}
      </div>
    </div>
  );
}

export default MemberFourQuadrants;
