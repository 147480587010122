import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';

import styles from './index.module.scss';
import { Button, Tooltip, DatePicker, Select, Input, Form, Radio, TimePicker, Space, InputNumber, Cascader, Divider, message } from 'antd';
import dayjs from 'dayjs';
import busEvent from '@/utils/bus';
import { QuestionCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import ShortMesLink from './component/shortMesLink';
import VariableMes from './component/variableMes';
import CreateGroup from './component/createGroup';
import TestRecord from './component/testRecord';
import {
  getJourneySceneInfo,
  getGroupList,
  getSubAccountList,
  getSmsSignList,
  getMobileList,
  getTemplateList,
  getAvailableBalance,
  sendTestSms,
  getStrategyTypeList,
  createGroup,
  getGroupListFromStartNode,
} from '../../../../api/graph/graph';
import MessageTextArea from '../../../../component/Input/MessageTextArea/messageTextArea';
import YLIcon from '../../../../../src/component/Icon/YLIcon';
let operType: any = 1;
let signOptions: any = [];
let unsubscribeOptionsLocal: any = [];
let mesModelListLocal: any = [];
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const weekOptions = [
  { label: '周一', value: '1' },
  { label: '周二', value: '2' },
  { label: '周三', value: '3' },
  { label: '周四', value: '4' },
  { label: '周五', value: '5' },
  { label: '周六', value: '6' },
  { label: '周日', value: '7' },
];
const monthOptions = [
  { label: '1月', value: '1' },
  { label: '2月', value: '2' },
  { label: '3月', value: '3' },
  { label: '4月', value: '4' },
  { label: '5月', value: '5' },
  { label: '6月', value: '6' },
  { label: '7月', value: '7' },
  { label: '8月', value: '8' },
  { label: '9月', value: '9' },
  { label: '10月', value: '10' },
  { label: '11月', value: '11' },
  { label: '12月', value: '12' },
];
const dayOptions = [
  { label: '1日', value: '1' },
  { label: '2日', value: '2' },
  { label: '3日', value: '3' },
  { label: '4日', value: '4' },
  { label: '5日', value: '5' },
  { label: '6日', value: '6' },
  { label: '7日', value: '7' },
  { label: '8日', value: '8' },
  { label: '9日', value: '9' },
  { label: '10日', value: '10' },
  { label: '11日', value: '11' },
  { label: '12日', value: '12' },
  { label: '13日', value: '13' },
  { label: '14日', value: '14' },
  { label: '15日', value: '15' },
  { label: '16日', value: '16' },
  { label: '17日', value: '17' },
  { label: '18日', value: '18' },
  { label: '19日', value: '19' },
  { label: '20日', value: '20' },
  { label: '21日', value: '21' },
  { label: '22日', value: '22' },
  { label: '23日', value: '23' },
  { label: '24日', value: '24' },
  { label: '25日', value: '25' },
  { label: '26日', value: '26' },
  { label: '27日', value: '27' },
  { label: '28日', value: '28' },
  { label: '29日', value: '29' },
  { label: '30日', value: '30' },
  { label: '31日', value: '31' },
];
let nodeInfoLocal: any = null;
let vars: any = [];
// #region初始化图形
const SettingTab = React.forwardRef((props: { graphStatus: any; info: any; canvasId: any; canvasName: any; opentestRecord: Function }, ref?: any) => {
  const { graphStatus, info, canvasId, canvasName, opentestRecord } = props;
  const [nodeInfo, setNodeInfo] = useState<any>({});
  const messageRef: any = useRef(null); //短信文本框
  const [scnOptions, setScnOptions] = useState([] as any); //所属旅程/场景
  const [mesModelList, setMesModelList] = useState(
    //短信模板
    [] as any
  );
  const [crowdOptions, setCrowdOptions] = useState(
    //目标客户
    [] as any
  );
  const [accountOptions, setAccountOptions] = useState(
    //短信通道
    [] as any
  );
  const [messageSignOptions, setMessageSignOptions] = useState([] as any); //短信签名
  const [groupOptions, setGroupOptions] = useState([] as any); //加入分群列表
  const [unsubscribeOptions, setUnsubscribeOptions] = useState(
    //短退订文字
    [] as any
  );
  const [testPhoneOptions, setTestPhoneOptions] = useState([] as any); //测试手机号
  const [baseInfo] = Form.useForm();
  const [overflowText, setOverflowText] = useState(false); //短信内容 文字超出
  const [textNum, setTextNum] = useState(0); //短信内容 字数
  const [chargingNum, setChargingNum] = useState(1); //短信计费条数
  const [mesBalanceNum, setMesBalanceNum] = useState<any>(0); //短信余额 条数

  const [shortMesLinkShow, setShortMesLinkShow] = useState(false); //短信内容 短链接
  const [variableShow, setVariableShow] = useState(false); //短信插入变量
  const [groupPopShow, setGroupPopShow] = useState(false); //创建分群弹窗
  const [testPhoneButton, setTestPhoneButton] = useState(true); //发送测试按钮高亮  true 为不可点击
  const [strategyTypeList, setStrategyTypeList] = useState<any>([]); //储存策略类型
  const [testRecordShow, setTestRecordShow] = useState(false); //测试记录
  const defaultStrategyObj = {
    scnId: null, //所属旅程/场景
    name: '', //策略名称
    startTime: '', //营销类型
    endTime: '',
    type: '', //营销类型
    crowdId: null,
    crowdName: null,
    params: {
      cycleType: 'DAILY' /** 执行频率类型：每天、每周、每月、每年 */,
      // condition: '',
      // anniversaryType : '',
      weekdays: [], // 每周几执行，例如周一（适用于每周执行的情况）
      daysOfMonth: [] /** 每月几号执行（适用于每月执行的情况），1-31 表示日期 */,
      months: [] /** 每年几月几号执行（适用于每年执行的情况） */,
      timeOfDay: '00:00' /** 每天的几点执行，例如 10:00:00 */,
      advanceTime: '' /** 提前多长时间开始执行，时间单位为分钟 */,
      intervalDays: 2,
    },
  };
  const defaultMessageObj = {
    account: {
      id: null,
      name: null,
    }, //短信通道
    sign: {
      id: null,
      content: '',
    }, //短信签名
    unsubscribe: '拒收请回复R', //退订文字
    text: '', //短信内容
    type: null, //短信内容类型
    templateId: null, //短信模板id
    templateName: null, //短信模板名称
    testPhoneNum: null, //测试接受电话,
    vars: null,
  };
  const defaultCrowdObj = {
    crowdId: null, //选择分群
    crowdName: null,
  };
  const defaultWaitObj = {
    type: 'duration',
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
    date: '',
    time: '00:00',
  };
  const defaultShuntObj = {
    sum: 100,
    branch: [
      {
        name: '分支1',
        value: 50,
      },
      {
        name: '分支2',
        value: 50,
      },
    ],
  };

  useEffect(() => {
    if (info?.id) {
      console.log('settingtab-----', info);
      setTestRecordShow(false);
      nodeInfoLocal = info.data;
      setNodeInfo(info.data);
      //改变节点或者测试记录返回的时候，文本框需要拿到内容回显
      if (info.data.componentId === 'SMS') {
        var text = '';
        if (info.data.type === 'template') {
          getTemplateListFunc();
        } else {
          text = info.data?.text;
          messageRef.current?.textAreaChange('');
          //给短信文本框赋值
          setTimeout(() => {
            messageRef.current?.textAreaChange(text);
          }, 1);
        }
        getSubAccountListFunc();
      }
      changeNodeInfo(info.data);
      if (info.data.componentId === 'START') {
        getScnOptions();
        if (groupOptions.length === 0) {
          getGroupListFunc();
        }
        if (strategyTypeList.length === 0) {
          getStrategyTypeListFunc();
        }
      }
    }
    return () => {
      setVariableShow(false);
      setShortMesLinkShow(false);
      signOptions = [];
      unsubscribeOptionsLocal = [];
      // setTestRecordShow(false);
    };
  }, [info]);

  //
  const getStrategyTypeListFunc = async () => {
    const res = await getStrategyTypeList();
    if (res) {
      console.log('获取策略类型', res.data);
      setStrategyTypeList(res.data);
      if (!nodeInfoLocal.strategyObj.type) {
        let strategynNew = { ...nodeInfoLocal.strategyObj, type: res.data[0].id };
        changeNodeInfo(nodeInfoLocal, strategynNew, 1);
      }
    }
  };
  //发送测试短信
  const sendTestSmsFunc = async (params: any) => {
    const res = await sendTestSms(params);
    if (res) {
      message.success('发送成功');
    }
  };
  //获取测试手机号
  const getMobileListFunc = async (accountId: any) => {
    const res = await getMobileList({
      accountId,
    });
    if (res) {
      setTestPhoneOptions(
        res.data.map((item: any) => {
          return {
            label: item.maskPhoneNo,
            value: item.phoneNoId,
          };
        })
      );
    }
  };
  //获取短信模板
  const getTemplateListFunc = async () => {
    if (graphStatus === '' || graphStatus === '0') {
      const res = await getTemplateList();
      if (res) {
        mesModelListLocal = res.data.map((item: any) => {
          return {
            label: item.templateName,
            value: item.templateId,
            templateText: item.templateText,
            params: item.params,
          };
        });
        setMesModelList(mesModelListLocal);
        let text = '';
        if (info.data?.templateId && mesModelListLocal?.filter((item: any) => item.value === info.data?.templateId).length > 0) {
          text = mesModelListLocal?.filter((item: any) => item.value === info.data?.templateId)[0].templateText;
          info.data.templateName = mesModelListLocal?.filter((item: any) => item.value === info.data?.templateId)[0].label;
        } else {
          info.data.templateId = null;
          info.data.templateName = null;
          info.data.vars = null;
          setNodeInfo(info.data);
        }
        info.data.text = text;
        //给短信文本框赋值
        setTimeout(() => {
          messageRef.current?.textAreaChange(text);
        }, 1);
      }
    } else {
      mesModelListLocal = [
        {
          label: info.data.templateName,
          value: info.data.templateId,
          templateText: info.data.text,
        },
      ];
      setMesModelList(mesModelListLocal);
      //给短信文本框赋值
      setTimeout(() => {
        messageRef.current?.textAreaChange(info.data.text);
      }, 1);
    }
  };
  //获取通道
  const getSubAccountListFunc = async () => {
    if (graphStatus === '' || graphStatus === '0') {
      const res = await getSubAccountList({
        status: 0,
      });
      if (res) {
        let arr = res.data.map((item: any) => {
          return {
            label: item.accountName,
            value: item.accountId,
          };
        });
        setAccountOptions(arr);
        if (info.data.account?.id) {
          if (arr.filter((item: any) => item.value === info.data.account?.id).length > 0) {
            info.data.account.name = arr.filter((item: any) => item.value === info.data.account?.id)[0].label;
          } else {
            info.data.account.id = null;
            info.data.account.name = null;
            info.data.sign.id = null;
            info.data.sign.content = null;
          }
        }
      }
    } else {
      setAccountOptions([
        {
          label: info.data.account.name,
          value: info.data.account.id,
        },
      ]);
    }
    if (info.data?.account?.id) {
      getMessageSignOptions(info.data?.account?.id, 0);
      // getMessageSignOptions(info.data?.account?.id, 1);
      getMobileListFunc(info.data?.account?.id);
      reFresh(info.data?.account?.id);
    }
  };
  //查询人群
  const getGroupListFunc = async (crowdName?: any) => {
    if (graphStatus === '' || graphStatus === '0') {
      const params = {
        crowdName: crowdName ? crowdName : '',
      };
      let res: any = '';
      if (info.data.componentId === 'START') {
        res = await getGroupListFromStartNode(params);
        if (res) {
          let arr = res.rows.map((item: any) => {
            return {
              label: item.crowdName,
              value: item.crowdId,
            };
          });
          setCrowdOptions(arr);
          if (info.data.strategyObj?.crowdId) {
            let crowdName = arr.filter((e: any) => e.value === info.data.strategyObj?.crowdId);
            if (crowdName.length > 0) {
              info.data.strategyObj.crowdName = crowdName[0].label;
            } else {
              info.data.strategyObj.crowdId = null;
              info.data.strategyObj.crowdName = null;
            }
          }
        }
      } else {
        res = await getGroupList(params);
        if (res) {
          let arr = res.rows.map((item: any) => {
            return {
              label: item.crowdName,
              value: item.crowdId,
            };
          });
          setGroupOptions(arr);
          if (info.data.crowdId) {
            let crowdName = arr.filter((e: any) => e.value === info.data.crowdId);
            if (crowdName.length > 0) {
              info.data.crowdName = crowdName[0].label;
            } else {
              info.data.crowdId = null;
              info.data.crowdName = null;
            }
            setNodeInfo(info.data);
          }
        }
      }
    } else {
      if (info.data.componentId === 'START') {
        setCrowdOptions([
          {
            label: info.data.strategyObj.crowdName,
            value: info.data.strategyObj.crowdId,
          },
        ]);
      } else {
        setGroupOptions([
          {
            label: info.data.crowdName,
            value: info.data.crowdId,
          },
        ]);
      }
    }
  };
  const convertOptions = (data: any) => {
    let result: any = [];
    data.map((item: any) => {
      let children: any = null;
      if (item.sceneLists) {
        children = convertOptions(item.sceneLists);
      }
      result.push({
        label: item.name,
        value: item.id,
        children: children,
      });
    });
    return result;
  };
  //获取旅程场景信息
  const getScnOptions = async () => {
    const res = await getJourneySceneInfo();
    if (res) {
      console.log('====获取旅程场景信息', res.data);
      setScnOptions(convertOptions(res.data.filter((e: any) => e.sceneLists.length > 0)));
    }
  };
  const nameMap: any = {
    START: '开始',
    SMS: '短信',
    JOIN_GROUP: '加入分群',
    SPLITTER: '分流器',
    WAIT: '等待',
  };
  //#region changeNodeInfo
  const changeNodeInfo = (data: any, newParams?: any, flag?: number) => {
    let nodeInfoNew: any = data;
    switch (data.componentId) {
      case 'START':
        if (!data.strategyObj) {
          let defaultStrategyObj1 = { ...defaultStrategyObj, name: '新建策略' + dayjs().valueOf() };
          if (info.data.scnArr) {
            defaultStrategyObj1.scnId = info.data.scnArr;
          }
          nodeInfoNew = { ...data, strategyObj: defaultStrategyObj1 };
        } else if (newParams) {
          nodeInfoNew = { ...data, strategyObj: newParams };
        }
        break;
      case 'SMS':
        if (newParams) {
          newParams.vars = vars;
          nodeInfoNew = { ...data, ...newParams };
        } else {
          nodeInfoNew = { ...defaultMessageObj, ...data };
        }
        //用于判断发送测试按钮是否可点击
        changeSendButton(nodeInfoNew);
        //计算字数
        changeNumText(nodeInfoNew);
        break;
      case 'JOIN_GROUP':
        if (newParams) {
          nodeInfoNew = { ...data, ...newParams };
        } else if (!data.id) {
          nodeInfoNew = { ...defaultCrowdObj, ...data };
        }
        getGroupListFunc();
        break;
      case 'SPLITTER':
        if (newParams) {
          let sum = 0;
          newParams.branch.forEach((item: any) => {
            sum += item.value;
          });
          if (Math.floor(sum) === sum) {
            newParams.sum = sum; // 如果是整数，直接返回
          } else {
            newParams.sum = parseFloat(sum.toFixed(1)); // 如果是小数，保留两位小数
          }
          nodeInfoNew = { ...data, ...newParams };
        } else if (!data.branch) {
          nodeInfoNew = { ...defaultShuntObj, ...data };
        }
        break;
      case 'WAIT':
        if (newParams) {
          nodeInfoNew = { ...data, ...newParams };
        } else if (!data.type) {
          nodeInfoNew = { ...defaultWaitObj, ...data };
        }
        break;
      default:
        break;
    }
    if (nodeInfoNew) {
      nodeInfoLocal = nodeInfoNew;
      setNodeInfo(nodeInfoNew);
    }
    if (newParams) {
      console.log('onSettingTab---flag', flag);
      busEvent.emit('updateNodeInfo', { ...nodeInfoNew, updatedTime: dayjs(), flag });
    } else {
      busEvent.emit('updateNodeInfo', { ...nodeInfoNew, flag: '' });
    }
    console.log('onSettingTab---', nodeInfoNew);
  };
  //修改节点名称
  const labelParam = (newParams: any) => {
    setNodeInfo({ ...nodeInfo, label: newParams });
    busEvent.emit('updateNodeInfo', { ...nodeInfo, label: newParams, updatedTime: dayjs() });
  };
  //等待的分支，分流器的分支
  const updateLineCondition = (index: any, data: any, field: any) => {
    busEvent.emit('updateLineCondition', { index, data, field });
  };
  //删除分支
  const deleteBranch = (index: any, type?: boolean) => {
    console.log('删除分支', index);
    if (index === 'undo') {
      nodeInfo.branch.pop();
    } else {
      nodeInfo.branch.splice(index, 1);
    }
    let shuntObjNew = { ...nodeInfo, branch: nodeInfo.branch };
    changeNodeInfo(nodeInfo, shuntObjNew);
    if (type) {
      busEvent.emit('onAddLine', index);
    }
  };
  //添加分支
  const addBranch = (type?: any) => {
    if (type === '添加') {
      busEvent.emit('onAddLine');
      console.log('添加分支', type, nodeInfo);
      if (nodeInfo.branch) {
        let newBranch = {
          name: '分支' + Number(nodeInfo.branch.length + 1),
          value: 0,
        };
        nodeInfo.branch.push(newBranch);
      }
    } else {
      console.log('撤回删除的分支', type, nodeInfo);
      nodeInfo.branch.splice(type.index, 0, { name: type.name, value: type.value });
    }

    let shuntObjNew = { ...nodeInfo, branch: nodeInfo.branch };
    changeNodeInfo(nodeInfo, shuntObjNew);
  };
  //改变 等待类型
  const changeWaitType = (e: any, type?: number) => {
    let waitObjNew = { ...nodeInfo, type: e, preType: nodeInfo.type, time: '00:00' };
    changeNodeInfo(nodeInfo, waitObjNew);
    if (type === 1) {
      return;
    }
    busEvent.emit('onWaitingType', e);
  };

  //获取短信签名列表
  const getMessageSignOptions = async (accountId: any, signType: any) => {
    if (graphStatus === '' || graphStatus === '0') {
      const param = {
        accountId,
        signType, //0-头签，1-尾签
      };
      const res = await getSmsSignList(param);
      if (res) {
        console.log('短信签名', res.data);
        var arr = res.data.map((item: any) => {
          return {
            label: item.signName,
            value: item.signId,
          };
        });
        if (signType === 0) {
          signOptions = arr;
          if (info.data.sign.id) {
            info.data.sign.content = signOptions.filter((item: any) => item.value === info.data.sign.id)[0].label;
          }
          setMessageSignOptions(arr);
        } else {
          unsubscribeOptionsLocal = arr;
          setUnsubscribeOptions(arr);
        }
        changeNumText(nodeInfoLocal);
      }
    } else {
      signOptions = [
        {
          label: info.data.sign.content,
          value: info.data.sign.id,
        },
      ];
      setMessageSignOptions(signOptions);
      changeNumText(nodeInfoLocal);
    }
  };
  let isShowErrorText = false;
  const showErroeText = () => {
    if (isShowErrorText) {
      return;
    }
    isShowErrorText = true;
    message.error('请先输入短信内容');
    setTimeout(() => {
      isShowErrorText = false;
    }, 3000);
  };
  //短信插入变量
  const openVariableMes = (status: boolean, type?: number, param?: any) => {
    if (param) {
      vars.push({
        field: param.field,
        def: param.def,
      });
    }
    if (!nodeInfo.text) {
      return showErroeText();
    }
    //type 1为取消，2为确定
    setVariableShow(status);
    setShortMesLinkShow(false);
    // setTestRecordShow(false);
    if (type === 2) {
      setTimeout(() => {
        // 更新文本区域
        messageRef.current?.handleClickTitle(`${param.variableText}!"${param.def}"}`);
      }, 1);
    }
  };
  //短信插入转换短链接
  const openShortMes = (status: any, type?: number, param?: any) => {
    if (!nodeInfo.text) {
      return showErroeText();
    }
    setShortMesLinkShow(status);
    // setTestRecordShow(false);
    setVariableShow(false);
    if (type === 2) {
      let messageObjNew = { ...nodeInfo, resultLink: param.resultLink };
      changeNodeInfo(nodeInfo, messageObjNew);
      setTimeout(() => {
        messageRef.current?.handleClickTitle(param.resultLink);
      }, 1);
    }
  };
  //测试记录
  const changeTestRecord = (type: any) => {
    setTestRecordShow(type);
    // opentestRecord(type);
  };
  //短信 刷新可用余额
  const reFresh = async (accountId: any) => {
    const res = await getAvailableBalance({ accountId });
    if (res?.code === 200) {
      setMesBalanceNum(res.data);
    } else {
      setMesBalanceNum(0);
    }
  };
  //短信 发送测试按钮
  const changeSendButton = (param: any) => {
    if (param?.account.id && param?.sign && param?.unsubscribe && param?.type && param?.testPhoneNum && param?.testPhoneNum.length > 0) {
      setTestPhoneButton(false);
    } else {
      setTestPhoneButton(true);
    }
  };
  //短信 字数统计
  const changeNumText = (param: any, addNum?: number) => {
    var textNum: number = 0;
    if (param.type === 'template') {
      let arr = mesModelListLocal.filter((item: any) => item.value === param.templateId);
      textNum = param.templateId && arr.length > 0 ? Number(arr[0].templateText.length) : 0;
      console.log(1, textNum);
    } else {
      textNum = param.text ? Number(param.text.length) : 0;
    }
    let arr2 = signOptions.filter((item: any) => item.value === param.sign?.id);
    console.log(2, Number(param.sign?.id && arr2.length > 0 ? arr2[0].label.length + 2 : 6));
    var num: number =
      textNum +
      //+ 2 是中括号的字符
      Number(param.sign?.id && arr2.length > 0 ? arr2[0].label.length + 2 : 6) +
      6;
    console.log(545, num);
    // Number(param.unsubscribe ? unsubscribeOptionsLocal.filter((item: any) => item.value === param.unsubscribe)[0]?.label.length + 2 : 4);
    // // 找到 `$` 的位置  将插入变量的长度替换成为空时显示的字符 算上了旁边的两个空格

    const str = param.text;
    const lengthNullText: any = []; //找${} 中 长度
    //找${}位置
    function extractTemplateExpressions(str: string): string[] {
      const expressions: string[] = [];
      let currentIndex = 0;

      while (currentIndex < str?.length) {
        //找$位置
        const dollarIndex = str.indexOf('$', currentIndex);
        if (dollarIndex === -1) break;

        const braceIndex = str?.indexOf('}', dollarIndex);
        if (braceIndex === -1) break;

        const expression = str?.substring(dollarIndex, braceIndex + 1);
        expressions.push(expression);
        currentIndex = braceIndex + 1;

        //找${} 中  !" " 位置
        const doubleQuoteIndex = expression.indexOf('!"');
        if (doubleQuoteIndex === -1) break;

        const doubleQuoteLength = expression.indexOf('"}');
        if (doubleQuoteLength === -1) break;
        const expressionNullText = expression?.substring(doubleQuoteIndex + 2, doubleQuoteLength);
        //!"" 中的字符
        lengthNullText.push(expressionNullText);
      }
      return expressions;
    }
    // 提取模板表达式
    const templateExpressions = extractTemplateExpressions(str);

    // 计算每个表达式的长度
    const lengths = templateExpressions.map((expression: any) => expression.length);
    //${} 表达式是个数组，数组里是取的所有 ${} 的长度 包括本身
    var newNum = 0;
    lengths.map((item: any) => {
      newNum = newNum + item;
    });

    //newNumNull 是所有 变量为空时显示 的字符长度
    const lengthsNullShow = lengthNullText.map((expression: any) => expression.length);
    var newNumNull = 0;
    lengthsNullShow.map((item: any) => {
      newNumNull = newNumNull + item;
    });
    //重新结算长度
    num = num - newNum + newNumNull;
    setTextNum(num);
    var charging = num > 70 ? Math.ceil(num / 67) : 1;
    setChargingNum(charging);
    if (num > 329) {
      setOverflowText(true);
    } else {
      setOverflowText(false);
    }
  };

  //创建分群变量
  const openCreateGroupPop = (status: boolean, type?: number, param?: any) => {
    //type 1为取消，2为确定
    setGroupPopShow(status);
    //如果是确定
    if (type === 2) {
      addGroup(param);
    }
  };
  //创建分群接口
  const addGroup = async (param: any) => {
    const res = await createGroup(param);
    if (res) {
      console.log('==========res,res', res.data);
      let crowdObjNew = { ...nodeInfo, crowdId: res.data, crowdName: param.crowdName };
      changeNodeInfo(nodeInfo, crowdObjNew);
      //如果创建分群成功，重新掉加入分群列表接口
      // getGroupListFunc();
    }
  };

  // 暴露方法给父组件
  useImperativeHandle(ref, () => ({
    deleteBranch,
    addBranch,
    changeWaitType,
  }));
  const disabledDate = (date: any) => {
    return date.valueOf() < dayjs().startOf('day');
  };
  const handleCrowdSearch = (crowdName: string) => {
    getGroupListFunc(crowdName);
  };
  //测试记录
  const testRecordStatus = (type: boolean) => {
    setTestRecordShow(type);
  };
  const hasDuplicateNames = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr.some((obj: any, index: any) => index !== i && obj.name === arr[i].name)) {
        return true; // 如果找到重复的name，返回true
      }
    }
    return false; // 如果没有找到重复的name，返回false
  };
  // const openPop = () => {};
  return (
    <div className={styles['setting-box']}>
      {/* {!testRecordShow && ( */}
      <div style={{ display: testRecordShow ? 'none' : 'block' }} className={styles['setting-title']}>
        {/* 只有在草稿或者创建时显示 */}
        {(graphStatus === '0' || !graphStatus) && (
          <div style={{ marginBottom: 24, fontSize: 16, fontWeight: '600' }}>{nameMap[nodeInfo.componentId]}</div>
        )}
        {nodeInfo.componentId === 'START' && (
          <div>
            <Form form={baseInfo} layout="vertical" style={{}}>
              <Form.Item label="所属旅程/场景" required rules={[{ required: true, message: '请选择' }]}>
                <Cascader
                  placeholder="请选择所属旅程/场景"
                  allowClear={false}
                  options={scnOptions}
                  disabled={graphStatus !== '0' && graphStatus}
                  value={nodeInfo.strategyObj.scnId}
                  onChange={e => {
                    let strategynNew = { ...nodeInfo.strategyObj, scnId: e };
                    changeNodeInfo(nodeInfo, strategynNew);
                  }}
                />
              </Form.Item>
              <Form.Item
                required
                label="策略名称"
                rules={[
                  { required: true, message: '请输入策略名称' },
                  {
                    validator: (_, value) => {
                      if (/\s/.test(value)) {
                        return Promise.reject('策略名称不能包含空格');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}>
                <Input
                  placeholder="请输入策略名称"
                  autoComplete="off"
                  showCount
                  maxLength={60}
                  value={nodeInfo.strategyObj.name}
                  disabled={graphStatus !== '0' && graphStatus}
                  onChange={(e: any) => {
                    let strategynNew = { ...nodeInfo.strategyObj, name: e.target.value };
                    changeNodeInfo(nodeInfo, strategynNew);
                  }}
                />
              </Form.Item>
              <Form.Item label="起止时间" required>
                <RangePicker
                  style={{ width: '100%' }}
                  allowEmpty={[true, false]}
                  placeholder={['立即开始', '']}
                  showTime
                  format=""
                  allowClear
                  disabledDate={disabledDate}
                  disabled={graphStatus !== '0' && graphStatus}
                  value={
                    nodeInfo.strategyObj.startTime && nodeInfo.strategyObj.endTime
                      ? [dayjs(nodeInfo.strategyObj.startTime), dayjs(nodeInfo.strategyObj.endTime)]
                      : nodeInfo.strategyObj.endTime
                      ? [nodeInfo.strategyObj.startTime, dayjs(nodeInfo.strategyObj.endTime)]
                      : nodeInfo.strategyObj.startTime
                      ? [dayjs(nodeInfo.strategyObj.startTime), nodeInfo.strategyObj.endTime]
                      : [nodeInfo.strategyObj.startTime, nodeInfo.strategyObj.endTime]
                  }
                  onChange={(value: any, dateString: any) => {
                    let strategynNew = { ...nodeInfo.strategyObj, startTime: dateString[0], endTime: dateString[1] };
                    changeNodeInfo(nodeInfo, strategynNew);
                  }}
                />
              </Form.Item>
              <Form.Item label="目标客户" required rules={[{ required: true, message: '请选择' }]}>
                <Select
                  allowClear={true}
                  placeholder="请选择客户分群"
                  showSearch
                  optionFilterProp="label"
                  options={crowdOptions}
                  disabled={graphStatus !== '0' && graphStatus}
                  value={nodeInfo.strategyObj.crowdId}
                  onChange={e => {
                    if (e === undefined) {
                      handleCrowdSearch('');
                    }
                    let strategynNew = {
                      ...nodeInfo.strategyObj,
                      crowdId: e === undefined ? null : e,
                      crowdName: e === undefined ? null : crowdOptions.filter((item: any) => item.value === e)[0].label,
                    };
                    changeNodeInfo(nodeInfo, strategynNew);
                  }}
                  onSearch={handleCrowdSearch}
                />
              </Form.Item>
              <Form.Item label="策略类型" required rules={[{ required: true, message: '请选择' }]}>
                <div className={styles['strategy-type-box']}>
                  {strategyTypeList.map((item: any) => (
                    <div
                      className={styles['strategy-type-item']}
                      onClick={() => {
                        if (graphStatus !== '0' && graphStatus) {
                          return;
                        }
                        let strategynNew = { ...nodeInfo.strategyObj, type: item.id };
                        changeNodeInfo(nodeInfo, strategynNew);
                      }}
                      style={{
                        border: nodeInfo.strategyObj.type === item.id ? 'var(--border4)' : '',
                        boxShadow: nodeInfo.strategyObj.type === item.id ? '0px 0px 0px 2px rgba(5, 145, 255, 0.1)' : '',
                      }}>
                      <div
                        className={styles['icon-box']}
                        style={{ backgroundColor: nodeInfo.strategyObj.type === item.id ? 'var(--background4)' : 'var(--background2)' }}>
                        <YLIcon
                          type={item.icon}
                          size={16}
                          color={nodeInfo.strategyObj.type === item.id ? 'var(--text-font-color7)' : 'var(--text-font-color4)'}></YLIcon>
                      </div>
                      <div className={styles['text-box']}>
                        <div style={{ color: nodeInfo.strategyObj.type === item.id ? 'var(--text-font-color7)' : 'var(--text-font-color3)' }}>
                          {item.name}
                        </div>
                        <div style={{ fontSize: 'var(--text-font2)' }}>{item.description}</div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <Radio.Group
                    onChange={e => {
                      let strategynNew = { ...nodeInfo.strategyObj, type: e.target.value };
                      changeNodeInfo(nodeInfo, strategynNew);
                    }}
                    disabled = {graphStatus !== '0' && graphStatus}
                    value={nodeInfo.strategyObj.type}>
                    <Radio value="one-time">一次性策略</Radio>
                    <Radio value="periodic">周期性策略</Radio>
                  </Radio.Group> */}
              </Form.Item>
              {/* {strategyType === 'one-time' && (
                  <Form.Item label="后续目标客户设置" name="targetCustomer" rules={[{ required: true, message: '请选择' }]}>
                    <Radio>流程启动之后，新加入群组的客户将自动进入自动流程</Radio>
                  </Form.Item>
                )} */}
              {strategyTypeList.filter((item: any) => item.id === nodeInfo.strategyObj.type)[0]?.name === '周期性策略' && (
                <Form.Item
                  label="循环执行设置"
                  tooltip="日期选择：支持选择31号，如果当月没有31号时，则当月最后一天执行策略。"
                  required
                  rules={[{ required: true, message: '请选择' }]}>
                  <div>
                    <Select
                      value={nodeInfo.strategyObj.params.cycleType}
                      placeholder="请选择"
                      disabled={graphStatus !== '0' && graphStatus}
                      style={{
                        marginBottom: '10px',
                        marginRight: nodeInfo.strategyObj.params.cycleType !== 'DAILY' ? '10px' : '',
                        width: nodeInfo.strategyObj.params.cycleType === 'DAILY' ? '100%' : '140px',
                      }}
                      options={[
                        { value: 'DAILY', label: '每天' },
                        { value: 'WEEKLY', label: '每周' },
                        { value: 'MONTHLY', label: '每月' },
                        { value: 'YEARLY', label: '每年' },
                        { value: 'CUSTOM', label: '自定义' },
                      ]}
                      onChange={e => {
                        let strategynNew = {
                          ...nodeInfo.strategyObj,
                          params: {
                            ...nodeInfo.strategyObj.params,
                            cycleType: e,
                            weekdays: null,
                            months: null,
                            daysOfMonth: null,
                          },
                        };
                        changeNodeInfo(nodeInfo, strategynNew);
                      }}
                    />
                    {nodeInfo.strategyObj.params.cycleType === 'WEEKLY' && (
                      <Tooltip
                        title={
                          graphStatus !== '0' && graphStatus ? (
                            <>
                              {nodeInfo.strategyObj.params.weekdays.map((item: any, index: any) => (
                                <span>
                                  {index > 0 ? '，' : ''}
                                  {weekOptions.filter((e: any) => e.value === item)[0].label}
                                </span>
                              ))}
                            </>
                          ) : (
                            ''
                          )
                        }>
                        <Select
                          mode="multiple"
                          allowClear
                          maxTagCount="responsive"
                          style={{ width: 'calc(100% - 150px)', marginBottom: '10px' }}
                          placeholder="请选择"
                          disabled={graphStatus !== '0' && graphStatus}
                          value={nodeInfo.strategyObj.params.weekdays}
                          onChange={e => {
                            let strategynNew = {
                              ...nodeInfo.strategyObj,
                              params: {
                                ...nodeInfo.strategyObj.params,
                                weekdays: e,
                              },
                            };
                            changeNodeInfo(nodeInfo, strategynNew);
                          }}
                          options={weekOptions}
                        />
                      </Tooltip>
                    )}
                    {nodeInfo.strategyObj.params.cycleType === 'YEARLY' && (
                      <Tooltip
                        title={
                          graphStatus !== '0' && graphStatus ? (
                            <>
                              {nodeInfo.strategyObj.params.months.map((item: any, index: any) => (
                                <span>
                                  {index > 0 ? '，' : ''}
                                  {monthOptions.filter((e: any) => e.value === item)[0].label}
                                </span>
                              ))}
                            </>
                          ) : (
                            ''
                          )
                        }>
                        <Select
                          mode="multiple"
                          allowClear
                          maxTagCount="responsive"
                          style={{ width: 'calc(100% - 300px)', marginBottom: '10px', marginRight: '10px' }}
                          placeholder="请选择"
                          disabled={graphStatus !== '0' && graphStatus}
                          value={nodeInfo.strategyObj.params.months}
                          onChange={e => {
                            let strategynNew = {
                              ...nodeInfo.strategyObj,
                              params: {
                                ...nodeInfo.strategyObj.params,
                                months: e,
                              },
                            };
                            changeNodeInfo(nodeInfo, strategynNew);
                          }}
                          options={monthOptions}
                        />
                      </Tooltip>
                    )}

                    {(nodeInfo.strategyObj.params.cycleType === 'MONTHLY' || nodeInfo.strategyObj.params.cycleType === 'YEARLY') && (
                      <Tooltip
                        title={
                          graphStatus !== '0' && graphStatus ? (
                            <>
                              {nodeInfo.strategyObj.params.daysOfMonth.map((item: any, index: any) => (
                                <span>
                                  {index > 0 ? '，' : ''}
                                  {dayOptions.filter((e: any) => e.value === item)[0].label}
                                </span>
                              ))}
                            </>
                          ) : (
                            ''
                          )
                        }>
                        <Select
                          mode="multiple"
                          allowClear
                          maxTagCount="responsive"
                          style={{
                            width: nodeInfo.strategyObj.params.cycleType === 'MONTHLY' ? 'calc(100% - 150px)' : 'calc(100% - 300px)',
                            marginBottom: '10px',
                          }}
                          placeholder="请选择"
                          disabled={graphStatus !== '0' && graphStatus}
                          value={nodeInfo.strategyObj.params.daysOfMonth}
                          onChange={e => {
                            let strategynNew = {
                              ...nodeInfo.strategyObj,
                              params: {
                                ...nodeInfo.strategyObj.params,
                                daysOfMonth: e,
                              },
                            };
                            changeNodeInfo(nodeInfo, strategynNew);
                          }}
                          options={dayOptions}
                        />
                      </Tooltip>
                    )}
                    {nodeInfo.strategyObj.params.cycleType === 'CUSTOM' && (
                      <>
                        <span>从</span>
                        <DatePicker
                          style={{ marginLeft: 10, marginRight: 10, width: 120 }}
                          disabled={graphStatus !== '0' && graphStatus}
                          value={nodeInfo.strategyObj.params.startDate ? dayjs(nodeInfo.strategyObj.params.startDate) : null}
                          format="YYYY-MM-DD"
                          onChange={(date, dateString) => {
                            let strategynNew = {
                              ...nodeInfo.strategyObj,
                              params: {
                                ...nodeInfo.strategyObj.params,
                                startDate: dateString,
                              },
                            };
                            changeNodeInfo(nodeInfo, strategynNew);
                          }}
                        />
                        <span>开始</span>
                        <br />
                        <span>每隔</span>
                        <InputNumber
                          placeholder="0"
                          style={{ width: 80, marginLeft: 10, marginRight: 10, marginBottom: 10 }}
                          min={1}
                          max={300}
                          precision={0}
                          controls={false}
                          value={nodeInfo.strategyObj.params.intervalDays}
                          disabled={graphStatus !== '0' && graphStatus}
                          onChange={v => {
                            console.log(333, v);
                            let strategynNew = {
                              ...nodeInfo.strategyObj,
                              params: {
                                ...nodeInfo.strategyObj.params,
                                intervalDays: v === null ? 1 : v,
                              },
                            };
                            changeNodeInfo(nodeInfo, strategynNew);
                          }}
                        />
                        <span>
                          天执行一次<span style={{ color: 'var(--text-font-color18)' }}>（间隔时间最大300天）</span>
                        </span>
                        <br />
                        <div style={{ marginBottom: '10px' }}>第1次在2024-08-12，第2次在……，以此类推</div>
                      </>
                    )}
                  </div>
                  <TimePicker
                    style={{ width: '100%' }}
                    disabled={graphStatus !== '0' && graphStatus}
                    value={nodeInfo.strategyObj.params.timeOfDay ? dayjs(nodeInfo.strategyObj.params.timeOfDay, 'HH:mm') : dayjs('00:00:00', 'HH:mm')}
                    format="HH:mm"
                    onChange={(time, timeString) => {
                      let strategynNew = {
                        ...nodeInfo.strategyObj,
                        params: {
                          ...nodeInfo.strategyObj.params,
                          timeOfDay: timeString,
                        },
                      };
                      changeNodeInfo(nodeInfo, strategynNew);
                    }}
                    defaultValue={dayjs('00:00:00', 'HH:mm')}
                  />
                </Form.Item>
              )}
            </Form>
          </div>
        )}
        {nodeInfo.componentId === 'SMS' && (
          <div>
            <Form form={baseInfo} layout="vertical">
              <Form.Item label="节点名称" required rules={[{ required: true, message: '请输入' }]}>
                <Input
                  placeholder="短信"
                  value={nodeInfo.label}
                  disabled={graphStatus !== '0' && graphStatus}
                  onChange={e => {
                    labelParam(e.target.value);
                  }}
                  autoComplete="off"
                  showCount
                  maxLength={20}
                />
              </Form.Item>
              <Form.Item label="短信通道" required rules={[{ required: true, message: '请选择' }]}>
                <Select
                  showSearch
                  optionFilterProp="label"
                  style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}
                  placeholder="请选择短信通道"
                  value={nodeInfo.account.id}
                  disabled={graphStatus !== '0' && graphStatus}
                  onChange={e => {
                    let account = { id: e, name: accountOptions.filter((i: any) => i.value === e)[0].label };
                    //每次选择短信通道将签名和退订文字置空
                    let messageObjNew = { ...nodeInfo, account, sign: { id: null, content: '' } };
                    changeNodeInfo(nodeInfo, messageObjNew);
                    getMessageSignOptions(e, 0); //获取签名
                    // getMessageSignOptions(e, 1); //获取退订文字
                    getMobileListFunc(e);
                    reFresh(e);
                  }}
                  options={accountOptions}
                />
                <Select
                  showSearch
                  optionFilterProp="label"
                  style={{ width: 'calc(50% - 5px)' }}
                  placeholder="请选择短信签名"
                  value={nodeInfo.sign.id}
                  disabled={graphStatus !== '0' && graphStatus}
                  onChange={e => {
                    let messageObjNew = {
                      ...nodeInfo,
                      sign: {
                        id: e,
                        content: messageSignOptions.filter((obj: any) => obj.value === e)[0].label,
                      },
                    };
                    changeNodeInfo(nodeInfo, messageObjNew);
                  }}
                  options={messageSignOptions}
                />
              </Form.Item>
              <Form.Item label="退订文字" required rules={[{ required: true, message: '请选择' }]}>
                {/* <Select
                    // allowClear
                    showSearch
                    optionFilterProp="label"
                    placeholder="请选择退订文字"
                    value={nodeInfo.unsubscribe}
                    disabled={graphStatus !== '0' && graphStatus}
                    onChange={e => {
                      let messageObjNew = { ...nodeInfo, unsubscribe: e };
                      changeNodeInfo(nodeInfo, messageObjNew);
                    }}
                    options={unsubscribeOptions}
                  /> */}
                <Input value={nodeInfo.unsubscribe} disabled></Input>
              </Form.Item>
              <Form.Item label="短信内容类型" required rules={[{ required: true, message: '请选择' }]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="label"
                  style={{ width: nodeInfo.type === 'template' ? 'calc(50% - 5px)' : '100%', marginRight: '10px' }}
                  value={nodeInfo.type}
                  disabled={graphStatus !== '0' && graphStatus}
                  onChange={e => {
                    //改变短信内容 模板要清空选择 短信内容也要清空
                    let messageObjNew = { ...nodeInfo, type: e, templateId: null, text: null };
                    changeNodeInfo(nodeInfo, messageObjNew);
                    //将短信内容清空
                    messageRef.current?.textAreaChange('');
                    vars = [];
                    if (e === 'template') {
                      getTemplateListFunc();
                    }
                  }}
                  placeholder="请选择短信内容类型"
                  options={[
                    { label: '自定义内容', value: 'custom' },
                    { label: '短信模板', value: 'template' },
                  ]}
                />
                {nodeInfo.type == 'template' && (
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    style={{ width: 'calc(50% - 5px)' }}
                    placeholder="请选择短信模板"
                    disabled={graphStatus !== '0' && graphStatus}
                    value={nodeInfo.templateId}
                    onChange={e => {
                      var text = mesModelList.filter((item: any) => item.value === e)[0]?.templateText;
                      vars = mesModelList.filter((item: any) => item.value === e)[0]?.params;
                      let messageObjNew = {
                        ...nodeInfo,
                        templateId: e,
                        text,
                        templateName: mesModelList.filter((item: any) => item.value === e)[0]?.label,
                      };
                      changeNodeInfo(nodeInfo, messageObjNew);
                      //将短信内容变为选择的模板
                      messageRef.current?.textAreaChange(text ? text : '');
                    }}
                    options={mesModelList}
                  />
                )}
              </Form.Item>
              {/* 插入变量弹窗 */}
              {variableShow && nodeInfo.type && <VariableMes openVariableMes={openVariableMes}></VariableMes>}
              {/* 插入转换短链接弹窗 */}
              {shortMesLinkShow && nodeInfo.type && <ShortMesLink accountId={nodeInfo.account.id} openShortMes={openShortMes}></ShortMesLink>}
              {nodeInfo.type && (
                <Form.Item label="短信内容" required rules={[{ required: true, message: '请选择' }]}>
                  <div className={styles['messageTextArea']}>
                    <MessageTextArea
                      ref={messageRef}
                      value={nodeInfo.text}
                      disabled={nodeInfo.type === 'template' || (graphStatus !== '0' && graphStatus)}
                      messageSign={nodeInfo.sign.id ? `【${nodeInfo.sign.content}】` : '【短信签名】'}
                      unsubscribe={
                        nodeInfo.unsubscribe
                        // ? `${unsubscribeOptions.filter((item: any) => item.value === nodeInfo.unsubscribe)[0]?.label}`
                        // : '退订文字'
                      }
                      messageModel={nodeInfo.templateId}
                      useLinkDisable={nodeInfo?.resultLink?.length > 0}
                      onChangeText={(e: string) => {
                        let varsNew: any = [];
                        //检查变量是否被删除
                        vars.forEach((item: any) => {
                          console.log('校验插入变量', e, item.field);
                          if (e.indexOf(item.field) !== -1) {
                            varsNew.push(item);
                          }
                        });
                        vars = varsNew;
                        var linkUrl = nodeInfo?.resultLink;
                        //如果输入的短信内容中不包含插入的短链接  则 清空短链接信息
                        if (e.indexOf(nodeInfo?.resultLink) == -1) {
                          linkUrl = '';
                        }
                        let messageObjNew = { ...nodeInfo, text: e, resultLink: linkUrl };
                        changeNodeInfo(nodeInfo, messageObjNew);
                      }}
                      type={nodeInfo.type}
                      openVariableMes={openVariableMes}
                      openShortMes={openShortMes}
                      graphStatus={graphStatus}
                    />

                    {/* <TextArea
                        disabled={nodeInfo.type == 2}
                        autoSize={{ minRows: 3 }}
                        defaultValue="[短信签名]"
                        placeholder="请输入短信内容"
                        autoComplete="off"
                        showCount
                        value={nodeInfo.text}
                        onChange={e => {
                          let messageObjNew = { ...nodeInfo, text: e.target.value };
                          changeNodeInfo(nodeInfo, messageObjNew);
                        }}
                        maxLength={329}
                      /> */}
                  </div>
                  <div className={styles['grayTextArea']}>
                    <div style={{ color: 'var(--text-font-color4)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      短信账户可用余额<span className={styles['textBalanceNum']}>{mesBalanceNum}</span>条
                      <div
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          if (nodeInfo.account.id && (graphStatus === '0' || graphStatus === '')) {
                            reFresh(nodeInfo.account.id);
                          }
                        }}>
                        <YLIcon
                          type="icon-shuaxin"
                          size={16}
                          color={
                            nodeInfo.account.id && (graphStatus === '0' || graphStatus === '') ? 'var(--text-font-color7)' : 'var(--text-font-color4)'
                          }></YLIcon>
                      </div>
                    </div>
                    <div style={{ color: 'var(--text-font-color4)' }}>
                      已输入
                      <span style={{ color: overflowText ? 'red' : '' }}>{textNum}</span>
                      /329字(包含短信签名)，预计计费：
                      <span className={styles['textBalanceNum']} style={{ marginLeft: 0 }}>
                        {chargingNum}
                      </span>
                      条
                      <Tooltip
                        title={
                          <span style={{ whiteSpace: 'pre-wrap' }}>
                            1、70个字符算一条短信，超过70个字符按67个字符算一条短信。
                            <br />
                            2、短信签名、退订文字字符个数包含在短信字符内。
                            <br />
                            3、请确保短链接【前后各加一个空格】，避免手机无法打开链接。使用长链接转换工具生成的短链接已默认前后各加一个空格。
                            <br />
                            4、请确保每条短信中只有一条短链接，否则无法统计短信发送情况。
                            <br />
                            5、短信内容只能为普通字符，不能包含:【、】、表情符等特殊字符。
                          </span>
                        }>
                        <QuestionCircleOutlined
                          style={{
                            color: 'var(--text-font-color4)',
                            fontSize: 'var(--text-font5)',
                            marginLeft: '6px',
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles['previewImg-box']}>
                      <div className={styles['previewImg']}>
                        <div className={styles['previewImg-scorllBox']}>
                          <div className={styles['previewImg-greyBox']}>
                            &nbsp;
                            {nodeInfo.sign.id ? `【${nodeInfo.sign.content}】` : '【短信签名】'}
                            &nbsp;
                            {nodeInfo.type === 'custom'
                              ? nodeInfo.text
                              : mesModelList.filter((item: any) => item.value === nodeInfo.templateId)[0]?.templateText}
                            &nbsp;
                            {
                              nodeInfo.unsubscribe
                              // ? unsubscribeOptions.filter((item: any) => item.value === nodeInfo.unsubscribe)[0]?.label
                              // : '退订文字'
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              )}
              <Form.Item label="测试接收电话" rules={[{ required: true, message: '请选择' }]}>
                <div>
                  <Select
                    allowClear
                    showSearch
                    disabled={graphStatus !== '0' && graphStatus}
                    mode="multiple"
                    maxTagCount="responsive"
                    optionFilterProp="label"
                    placeholder="请选择测试手机号"
                    style={{ width: 'calc(50% + 10px)', marginRight: '10px' }}
                    options={testPhoneOptions}
                    value={graphStatus === '' || graphStatus === '0' ? nodeInfo.testPhoneNum : []}
                    onChange={e => {
                      let messageObjNew = { ...nodeInfo, testPhoneNum: e };
                      changeNodeInfo(nodeInfo, messageObjNew);
                    }}
                  />
                  <Button
                    style={{ width: '25%' }}
                    disabled={testPhoneButton || (graphStatus !== '0' && graphStatus)}
                    onClick={() => {
                      let varsObj: any = {};
                      if (Array.isArray(vars)) {
                        vars.forEach((item: any) => {
                          varsObj[item.field] = item.def;
                        });
                      } else {
                        varsObj = vars;
                      }
                      const param = {
                        accountId: nodeInfo.account.id,
                        headSignId: nodeInfo.sign.id,
                        unsubscribe: nodeInfo.unsubscribe,
                        smsContent: nodeInfo.text,
                        phoneNoIds: nodeInfo.testPhoneNum,
                        smsContentType: nodeInfo.type,
                        params: varsObj,
                        canvasId,
                      };
                      sendTestSmsFunc(param);
                    }}>
                    发送测试
                  </Button>
                  <Button
                    style={{ width: '15%', paddingLeft: 10, paddingRight: 0 }}
                    disabled={!nodeInfo.account.id}
                    type="link"
                    onClick={() => changeTestRecord(true)}>
                    测试记录
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )}
        {nodeInfo.componentId === 'JOIN_GROUP' && (
          <div>
            <Form form={baseInfo} layout="vertical" style={{}}>
              <Form.Item label="节点名称" required rules={[{ required: true, message: '请输入节点名称' }]}>
                <Input
                  placeholder="加入分群"
                  disabled={graphStatus !== '0' && graphStatus}
                  autoComplete="off"
                  showCount
                  value={nodeInfo.label}
                  onChange={e => {
                    labelParam(e.target.value);
                  }}
                  maxLength={20}
                />
              </Form.Item>
              <Form.Item label="加入分群" required rules={[{ required: true, message: '请选择' }]}>
                <Select
                  placeholder="请选择加入分群名称"
                  showSearch
                  optionFilterProp="label"
                  disabled={(graphStatus !== '0' && graphStatus) || groupPopShow}
                  options={groupOptions}
                  onSearch={handleCrowdSearch}
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <div style={{}}>
                        <Button
                          type="link"
                          style={{ width: '100%' }}
                          onClick={() => {
                            openCreateGroupPop(true);
                          }}>
                          新建分群
                        </Button>
                      </div>
                    </>
                  )}
                  value={nodeInfo.crowdId}
                  onChange={e => {
                    let crowdObjNew = { ...nodeInfo, crowdId: e, crowdName: groupOptions.filter((item: any) => item.value === e)[0].label };
                    changeNodeInfo(nodeInfo, crowdObjNew);
                  }}
                />
              </Form.Item>
              {/* 创建分群弹窗 */}
              {groupPopShow && <CreateGroup openCreateGroupPop={openCreateGroupPop}></CreateGroup>}
            </Form>
          </div>
        )}
        {nodeInfo.componentId === 'WAIT' && (
          <div>
            <Form form={baseInfo} layout="vertical" style={{}}>
              <Form.Item label="节点名称" required rules={[{ required: true, message: '请输入节点名称' }]}>
                <Input
                  placeholder="等待"
                  autoComplete="off"
                  showCount
                  disabled={graphStatus !== '0' && graphStatus}
                  maxLength={20}
                  value={nodeInfo.label}
                  onChange={e => {
                    labelParam(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="等待类型" required rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group
                  style={{ width: '100%' }}
                  onChange={e => {
                    changeWaitType(e.target.value);
                  }}
                  disabled={graphStatus !== '0' && graphStatus}
                  value={nodeInfo.type}>
                  <Space size={24} direction="vertical" style={{ width: '100%' }}>
                    <Radio value={'duration'}>等待一段时间</Radio>
                    {nodeInfo.type === 'duration' && (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InputNumber
                          placeholder="0"
                          min={0}
                          max={9999}
                          disabled={graphStatus !== '0' && graphStatus}
                          precision={0}
                          controls={false}
                          value={nodeInfo.day}
                          onChange={v => {
                            let waitObjNew = { ...nodeInfo, day: v };
                            changeNodeInfo(nodeInfo, waitObjNew);
                          }}
                          addonAfter="天"
                        />
                        <InputNumber
                          placeholder="0"
                          style={{ marginLeft: 8 }}
                          min={0}
                          max={23}
                          precision={0}
                          controls={false}
                          value={nodeInfo.hour}
                          disabled={graphStatus !== '0' && graphStatus}
                          onChange={v => {
                            let waitObjNew = { ...nodeInfo, hour: v };
                            changeNodeInfo(nodeInfo, waitObjNew);
                          }}
                          addonAfter="小时"
                        />

                        <InputNumber
                          placeholder="0"
                          style={{ marginLeft: 8 }}
                          min={0}
                          max={59}
                          precision={0}
                          controls={false}
                          value={nodeInfo.minute}
                          disabled={graphStatus !== '0' && graphStatus}
                          onChange={v => {
                            let waitObjNew = { ...nodeInfo, minute: v };
                            changeNodeInfo(nodeInfo, waitObjNew);
                          }}
                          addonAfter="分钟"
                        />

                        {/* <InputNumber
                              placeholder=""
                              style={{ width: 60, margin: '0 6px' }}
                              min={0}
                              max={59}
                              precision={0}
                              controls={false}
                              value={waitObj.second}
                              onChange={v => {
                                let waitObjNew = { ...waitObj, second: v };
                                waitingParam(waitObjNew);
                              }}
                            />
                            秒 */}
                      </div>
                    )}
                    <Radio value={'until'}>等待至指定时间</Radio>
                    {nodeInfo.type === 'until' && (
                      <div>
                        <DatePicker
                          disabled={graphStatus !== '0' && graphStatus}
                          value={nodeInfo.date ? dayjs(nodeInfo.date) : null}
                          style={{ marginBottom: 16, width: '100%' }}
                          format="YYYY-MM-DD HH:mm"
                          showTime
                          disabledDate={disabledDate}
                          onChange={(date, dateString) => {
                            let waitObjNew = { ...nodeInfo, date: dateString };
                            changeNodeInfo(nodeInfo, waitObjNew);
                            updateLineCondition(0, dateString, 'execTime');
                          }}
                        />
                      </div>
                    )}
                    <Radio value={'next'}>等待至下一个时间点</Radio>
                    {nodeInfo.type === 'next' && (
                      <div>
                        <TimePicker
                          allowClear={false}
                          style={{ marginBottom: 16, width: '100%' }}
                          onChange={(time, timeString) => {
                            let waitObjNew = { ...nodeInfo, time: timeString };
                            changeNodeInfo(nodeInfo, waitObjNew);
                          }}
                          disabled={graphStatus !== '0' && graphStatus}
                          value={nodeInfo.time ? dayjs(nodeInfo.time, 'HH:mm') : dayjs('00:00:00', 'HH:mm')}
                          format="HH:mm"
                        />
                      </div>
                    )}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        )}
        {nodeInfo.componentId === 'SPLITTER' && (
          <div>
            <Form form={baseInfo} layout="vertical" style={{}}>
              <Form.Item label="节点名称" required rules={[{ required: true, message: '请输入节点名称' }]}>
                <Input
                  placeholder="分流器"
                  autoComplete="off"
                  showCount
                  maxLength={20}
                  disabled={graphStatus !== '0' && graphStatus}
                  value={nodeInfo.label}
                  onChange={e => {
                    labelParam(e.target.value);
                  }}
                />
              </Form.Item>
              {nodeInfo.branch.map((item: any, index: any) => (
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '232px' }} className={styles['name-form']}>
                    <Form.Item label={index ? '' : '分支名称'} required rules={[{ required: true, message: '请选择' }]}>
                      <Input
                        placeholder=""
                        autoComplete="off"
                        showCount
                        maxLength={20}
                        value={item.name}
                        disabled={graphStatus !== '0' && graphStatus}
                        onChange={e => {
                          nodeInfo.branch[index].name = e.target.value;
                          let shuntObjNew = { ...nodeInfo, branch: nodeInfo.branch };
                          updateLineCondition(index, nodeInfo.branch, 'splitter');
                          changeNodeInfo(nodeInfo, shuntObjNew);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ flex: 1 }} className={styles['name-form']}>
                    <Form.Item label={index ? '' : '流量配比'} required rules={[{ required: true, message: '请选择' }]}>
                      <div style={{ display: 'flex' }}>
                        <InputNumber
                          placeholder=""
                          style={{ margin: '0 8px' }}
                          min={0}
                          max={99.9}
                          precision={1}
                          controls={false}
                          value={item.value}
                          disabled={graphStatus !== '0' && graphStatus}
                          onChange={v => {
                            nodeInfo.branch[index].value = v === null ? 0 : v;
                            let shuntObjNew = { ...nodeInfo, branch: nodeInfo.branch };
                            changeNodeInfo(nodeInfo, shuntObjNew);
                            updateLineCondition(index, nodeInfo.branch, 'splitter');
                          }}
                          addonAfter="%"
                        />
                        {nodeInfo.branch.length > 2 && (
                          <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => deleteBranch(index, true)}>
                            <YLIcon type="icon-shanchu" size={16} color="var(--text-font-color4)"></YLIcon>
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </div>
                </div>
              ))}
              {hasDuplicateNames(nodeInfo.branch) && <span style={{ color: 'var(--text-font-color37)' }}>分支名称不能相同</span>}
              <Button
                type="dashed"
                style={{ width: '100%' }}
                icon={<PlusOutlined style={{ fontSize: 'var(--text-font2)' }} />}
                onClick={() => addBranch('添加')}
                disabled={nodeInfo.branch.length > 7 || (graphStatus !== '0' && graphStatus)}>
                添加分支
              </Button>
              <div className={styles.textArea}>
                <span>
                  已分配：
                  <span style={{ color: nodeInfo.sum !== 100 ? 'var(--text-font-color37)' : 'var(--text-font-color7)' }}>{nodeInfo.sum}%</span>
                  （总流量：100%），
                </span>
                {nodeInfo.sum !== 100 ? (
                  <span style={{ color: 'var(--text-font-color37)' }}>
                    {/* 流量配比{nodeInfo.sum < 100 ? '少于' : '不能超过'}100% */}
                    配比必须等于100%
                  </span>
                ) : (
                  <span>配比必须等于100%</span>
                )}
              </div>
            </Form>
          </div>
        )}
      </div>
      {/* )} */}
      {/* 测试记录弹窗 */}
      {testRecordShow && <TestRecord accountId={nodeInfo.account.id} canvasId={canvasId} testRecordStatus={testRecordStatus}></TestRecord>}
    </div>
  );
});
export default SettingTab;
