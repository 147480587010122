import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  Modal,
  Dropdown,
  ConfigProvider,
  Select,
  Tooltip,
  message,
  Tag,
  Tabs,
} from "antd";
import {  CheckCircleFilled , MinusCircleFilled ,CopyOutlined} from '@ant-design/icons';
import GroupMes from './components/GroupMes';
import LabelMes from './components/LabeMes';
import VipMes from './components/VipMes';
import Order from './components/Order';
import Resume from './components/Resume';
import UserMes from './components/UserMes';
import DY from '../../../../assets/images/DY.png';
import JD from '../../../../assets/images/JD.png';
import TM from '../../../../assets/images/TM.png';
import offlineShop from '../../../../assets/images/offlineShop.png';
import YZ from '../../../../assets/images/YZ.png';
import WX from '../../../../assets/images/WX.png';
import userDetailVip from '../../../../assets/images/userDetailVip.png';
import BackList from '../../../../component/BackList/backList';
import { customerInfo } from "../../../../api/UserInsight/UserInsight";
import copyToClipboard from "../../../../crm/assets/public/clipboard";
import { UserListQuery, UserListVO , RecordQuery } from "../../../../api/UserInsight/types";
import style from "./index.module.scss";
const { Option } = Select;
const { Search } = Input;
const UserDeatil = () => { 
  //数据列表
  const [listData, setListData] = useState([] as any[]);
  const groupMesRef: any = useRef(null);
  const labelMesRef: any = useRef(null);
  const vipMesRef: any = useRef(null);
  const OrderRef: any = useRef(null);
  const resumeRef: any = useRef(null);
  const userMesRef: any = useRef(null);
  const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId'):'';
  const [peopleUserListData, setPeopleUserListData] = useState(
    {} as any
  );
  const [userTypeTabs, setUserTypeTabs] = useState('1' as any);
  const [channelMesList, setChannelMesList] = useState(
    [] as any
  );
   // 用户tab页
   const dataTagsType: any[] = [
    {
        key: '1',
        label: '客户履历',
        children: <>
        <div style={{display:'flex'}} className={style['tab-container']}>
                   
                    <Resume ref={resumeRef}></Resume>
        </div>
                  </>,
    }, 
    {
        key: '2',
        label: '订单信息',
        children: <> 
                    <div style={{display:'flex'}}>
                      <Order ref={OrderRef}></Order>
                    </div>
                  </>,
    }, 
    {
        key: '3',
        label: '会员信息',
        children: <> <div style={{display:'flex'}}>
          <VipMes ref={vipMesRef}></VipMes></div></>,
    },
    {
        key: '4',
        label: '标签信息',
        children: <> <div style={{display:'flex'}}>
          <LabelMes ref={labelMesRef} ></LabelMes></div></>,
    },
    {
        key: '5',
        label: '分群信息',
        children: <> <div style={{display:'flex'}}>
          <GroupMes ref={groupMesRef} ></GroupMes></div></>,
    }
  ]
  const setUserType = (key:any) => {
      setUserTypeTabs(key)
      switch (key) {
        case '1':
          resumeRef.current?.getUserResumeList({ pageSize: 10, pageNum: 1 } );
          resumeRef.current?.resetFilter();
          break;
        case '2':
          OrderRef.current?.getUserOrderList({ pageSize: 10, pageNum: 1 } );
          OrderRef.current?.resetFilter();
          break;
        case '3':
          vipMesRef.current?.getVipMes();
          break;
        case '4':
          labelMesRef.current?.getLabelMes();
          labelMesRef.current?.resetFilter();
          break;
        case '5':
          groupMesRef.current?.getGroupMes();
          groupMesRef.current?.resetFilter();
          break;
      
        default:
          break;
      }
  }
  // 带有粘贴功能的文本
  const handCopy = (text:any) => {
    copyToClipboard(text)
      .then(() => {
        message.success("复制成功");
      })
      .catch((error) => {
        message.error("复制成功");
      });
  };
  const channelType = (type: string) => {
    switch (type) {
      case 'JD': 
        return JD
        break;
      case 'DY': 
        return DY
        break;
      case 'TM': 
        return TM
        break;
      case 'YZ': 
        return YZ
        break;
      case 'WX': 
        return WX
        break;
      case 'offlineShop': 
        return offlineShop
        break;
      default:
        break
    }
  }
  const channelTypeName = (type: string) => {
    switch (type) {
      case 'JD': 
        return '京东'
        break;
      case 'DY': 
        return '抖音'
        break;
      case 'TM': 
        return '天猫'
        break;
      case 'YZ': 
        return '有赞'
        break;
      case 'WX': 
        return '微信'
        break;
      case 'offlineShop': 
        return '线下门店'
        break;
      default:
        break
    }
  }
    // 获取个人信息
  const getUserMesList = async () => {
      const param = {
        oneId: oneId
      } as any;
      let res; 
      res  = await customerInfo(param);
      if (res) {
        var arr1:any = res.data.customerMemberVoList
        setPeopleUserListData(res.data)
        var arr2:any = []
        if (arr1 && arr1.length > 0) {
          arr1.map((item:any)=>{
             // 查找结果数组中是否已经存在该 id 的对象
              const index = arr2.findIndex((r:any) => r.channelType === item.channelType);
              if (index === -1) {
                arr2.push({ channelType: item.channelType, shop: [item] });
              } else {
                arr2[index].shop.push(item);
              }
          })
        }
        var list:any = []
        if (arr2 && arr2.length>0) {
          arr2.map((item:any,index:any)=>{
              list.push({
                  key: index,
                  label:  
                  <>
                    <div style={{display:"flex",alignItems:'center'}}>
                      {item.channelType && <img className={style["UserDeatil_channelIcon"]} src={channelType(item.channelType)} />}
                      <div>{channelTypeName(item.channelType)}</div>
                    </div>
                  </>,
                  showArrow: true,
                  children: <div className={style["UserDeatil_peopleListBox"]} key={index}>
                      <div className={style['UserDeatil_Item-channel-box']}>
                          {item.shop.map((itm:any, indx:any) => (
                            <div className={style['UserDeatil_Itm-box']} key={indx}>
                              <div className={style['UserDeatil_Item']}>
                                  <div className={style['UserDeatil_Item-title']}>店铺名：</div>
                                  <div className={style['UserDeatil_Itm-text']}>
                                  <Tooltip placement="top" title={itm.shopName?itm.shopName:'-'}>
                                      <div className={style['UserDeatil_Item-shopName']}>{itm.shopName?itm.shopName:'-'}</div>
                                      { itm.memberTime && <CheckCircleFilled className={style['UserDeatil_Itm-icon']}/>}
                                      { !itm.memberTime && <MinusCircleFilled className={style['UserDeatil_Itm-icon1']}/>}
                                  </Tooltip>
                                  </div>
                              </div>
                              {itm.memberTime && <div className={style['UserDeatil_Item']}>
                                  <div className={style['UserDeatil_Item-title']}>入会时间：</div>
                                  <div>{itm.memberTime?itm.memberTime:'-'}</div>
                                  {/* <div className={style['UserDeatil_Itm-text']}>{itm.memberTime?itm.memberTime:'-'}</div> */}
                              </div>}
                              {itm.memberTime && <div className={style['UserDeatil_Item']}>
                                  <div className={style['UserDeatil_Item-title']}>会员等级：</div>
                                  <Tooltip placement="top" title={itm.gradeName}>
                                    <div className={style['UserDeatil_Itm-textVip']}>
                                      <div style={{zIndex:'var(--z-index4)'}}><img className={style['UserDeatil_Itm-img']} src={userDetailVip} alt="" /></div>
                                      <div className={style['UserDeatil_Itm-level']}>{itm.gradeName?itm.gradeName:'-'}</div>
                                    </div>
                                  </Tooltip>
                              </div>}
                              {itm.memberTime && <div className={style['UserDeatil_Item']}>
                                  <div style={{width: '50%'}} className={style['UserDeatil_Item-title']}>店铺级客户ID：</div>
                                  <div className={style['UserDeatil_Itm-text']}>
                                    <div style={{width:' 80px',overflow:'hidden',textOverflow: 'ellipsis'}}>{itm.shopUserId?itm.shopUserId:'-'}</div>
                                    {itm.shopUserId && <CopyOutlined className={style['UserDeatil_Itm-iconCopy']} onClick={()=>handCopy(itm.shopUserId?itm.shopUserId:'-')}/>}
                                  </div>
                              </div>}
                            </div>
                          ))}
                        </div>
                    </div>,
                  style: panelStyle,
              },)
          })
          setChannelMesList(list)
        }
      }
  };

  const panelStyle: React.CSSProperties = {
    marginBottom: 0,
    background:'var(--background1)',
    border: 'none',
  };  
  useEffect(() => {
    getUserMesList();
    return () => {

    };
  }, []);

  return (
    <div className={style.UserDeatil}>
      
      <div className={style['content']}>
          <div className={style['header']}>
              <div className={style['header-left']}>
                  {/* <BackList></BackList> */}
                  <div className={'Page_header-title'}>客户详情</div>
              </div>
          </div>
          <div style={{display:'flex',width:'100%'}}>
            <UserMes ref={userMesRef} 
              peopleUserListData={peopleUserListData} 
              userTypeTabs={userTypeTabs} 
              channelMesList={channelMesList}></UserMes>
            <ConfigProvider 
                theme={{
                    components: {
                        Tabs: {
                          horizontalMargin: '0px',
                        }
                    },
                }}
            >
              <Tabs className={style['content_tabs']} 
                    // defaultActiveKey={chartType} 
                    onChange={(key) => { setUserType(key) }} 
                    tabBarGutter={50} items={dataTagsType}>
              </Tabs>
            </ConfigProvider>
          </div>
      </div>
    </div>
  );
};

export default UserDeatil;
