import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import ModuleTitle from '../../../../component/ModuleTitle';
import { Button, Empty, Switch, Table, TableProps, Dropdown, Space, Input, Tree, TreeDataNode, Popconfirm, message, Tag, Tooltip } from 'antd';
import { QuestionCircleOutlined, PlusOutlined, DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import FilterView from '../../../../component/FilterView/filterView';
import dayjs from 'dayjs';
import SmsChannel from '../../component/smsChannel';
import { getSmsPassageList, updateSmsPassageStatus, judgmentByAccountId } from '../../../../api/graph/graph';
import { getTableScroll } from '@/store';

let preParams: any;
const dateFormat = 'YYYY/MM/DD';

const SMSACCOUNT = () => {
  const searchParamInt: any = {
    accountName: '',//通道名称
    status: '',//状态
    createBy: '',//创建人
    createTimeStart: '',//创建时间开始
    createTimeEnd: '',//创建时间结束
  };

  const [listData, setListData] = useState([] as any);
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const filterViewRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const [accountId, setAccountId] = useState(null as any);
  const [channelStatus, setChannelStatus] = useState(0);
  const [popShow, setPopShow] = useState(false);
  const [selectedKey, setSelectedleKey] = useState(null as any);
  const [scrollY, setScrollY] = useState('' as any);

  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入通道名称',
      title: '通道名称',
      key: 'accountName',
      defaultValue: preParams?.accountName ? String(preParams.accountName) : null,
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '通道状态',
      key: 'status',
      defaultValue: preParams?.status ? String(preParams.status) : null,
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '启用',
        },
        {
          value: '1',
          label: '停用',
        },
      ],
    },
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入创建人',
      title: '创建人',
      key: 'createBy',
      defaultValue: preParams?.createBy ? String(preParams.createBy) : null,
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRangeDoubel',
      placeHolder: '',
      title: '创建时间',
      key: 'createTimeStart-createTimeEnd',
      defaultValue: preParams?.createTimeStart ? [dayjs(preParams.createTimeStart, dateFormat), dayjs(preParams.createTimeEnd, dateFormat)] : null,
    },
  ];
  const getHeight = (e: string) => {
    if(filterViewRef.current?.arrow && listData.length > 8){
      setScrollY(e)
    }else{
      setY(listData)
    }
  };
  const setY =(list:any)=>{
    if(list.length<13){
      setScrollY(undefined)
    }else{
      setScrollY(getTableScroll())
    }
  }
  // 操作列
  const getTableOperationButtonList = (item: any) => {
    const store = [];
    store.push({
      key: 'check',
      label: (
        <a
          style={{ color: 'var( --text-font-color9)' }}
          onClick={() => { drawerSwitch(item.accountId, 1) }}>
          查看
        </a>
      ),
    });
    store.push({
      key: 'edit',
      label: (
        <a
          style={{ color: 'var( --text-font-color9)' }}
          onClick={() => { drawerSwitch(item.accountId, 2) }}>
          编辑
        </a>
      ),
    });
    // if (checkPermissions(GROUP_KEY.GROUP_DELETE)) {
    // }
    return store;
  }

  const columns: TableProps['columns'] = [
    {
      title: '通道名称',
      dataIndex: 'accountName',
      key: 'accountName',
      render: (_: any, record: any, i: number) => (
        <Tooltip title={_} placement="topLeft">
          <span style={{cursor:'pointer'}} className='line-text-one text-color7' onClick={() =>
            drawerSwitch(record.accountId, 1)
          }>{_}</span>
        </Tooltip>),
    },
    {
      title: '通道状态',
      dataIndex: 'status',//0-启用 1-停用
      key: 'status',
      render: (_: any, record: any, i: number) => (
        <div>
          <Popconfirm
            placement="top"
            title={`确定${_ === 0 ? '停用' : '启用'}此短信通道吗？`}
            okText="确定"
            cancelText="取消"
            open={popShow && (selectedKey === record.accountId)}
            onConfirm={() => { changeStatus(record) }}
            onCancel={() => { setPopShow(false) }}
            onPopupClick={e => {
              e.stopPropagation();
            }}>
            <Switch checked={_ === 0} checkedChildren="启用" unCheckedChildren="停用" onChange={(e) => { checkStatus(e, record.accountId) }} />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '最后更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
    },
    {
      title: '操作',
      dataIndex: 'sort',
      align: 'center',
      width: 100,
      key: 'sort',
      fixed: 'right',
      render: (_: any, record: any, i: number) => (
        <Space>
          <Dropdown menu={{ items: getTableOperationButtonList(record) }} trigger={['click']}>
            <EllipsisOutlined />
          </Dropdown>
        </Space>
      ),
    },
  ]

  const checkStatus = async (e: any, accountId: any) => {
    console.log(e, ';;;');
    setSelectedleKey(accountId)
    if (!e) {
      const res = await judgmentByAccountId({ accountId })
      if (res?.code == 200) {
        if (res.data) {
          message.warning('该短信通道正在应用中，不允许停用')
        } else {
          setPopShow(true)
        }
      }
      return
    }
    setPopShow(true)
  }
  // 停用/启用通道
  const changeStatus = (record: any) => {
    record.status = record.status === 0 ? 1 : 0
    let text = record.status === 0 ? '启用' : '停用'
    updateRecord({ accountId: record.accountId, status: record.status }, text)
  }

  // 停用/启用 短信通道
  const updateRecord = async (param: any, text?: string) => {
    const res = await updateSmsPassageStatus(param);
    if (res) {
      if (text) {
        message.success(text + '成功');
      }
      setPopShow(false)
      getListData({ pageSize: 10, pageNum: 1 }, searchParam);
    }
  };

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 获取表格信息
  const getListData = async (page: { pageSize: number; pageNum: number }, data: any) => {

    const res: any = await getSmsPassageList({ ...page, ...data });

    if (res?.code == 200) {
      const { total, records } = res.data;
      setListData(records);
      setY(records)
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: total,
      });
    }
  };

  // 换页
  const handleTableChange = (e: any) => {
    setPageQuery({
      ...pageQuery,
      pageSize: e.pageSize,
      pageNum: e.current,
    });
    getListData({ pageSize: e.pageSize, pageNum: e.current }, searchParam);
  };

  // 展开过滤器
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  // 过滤器搜索
  const filterFinish = (data: any) => {
    setSearchParam(data);
    getListData({ pageSize: 10, pageNum: 1 }, data);
  };

  const drawerSwitch = (accountId?: number, type?: number) => {
    setAccountId(accountId)
    setChannelStatus(type ?? 0)
    if (open) {
      getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searchParam);
    }
    setOpen(!open);
  };

  useEffect(() => {
    getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searchParam);
  }, [])

  return (
    <div>
      <div className={style.headBox}>
        <div className={style.titleBox}>
          <ModuleTitle mark="large" title="短信通道管理" />
        </div>
        <div className={style.buttonBox}>
          <SearchIconInput
            clear={true}
            width="310px"
            placeholder="请输入通道名称"
            onSearch={(e: string) => {
              getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, { ...searchParam, accountName: e })
            }}></SearchIconInput>
          <FilterIcon filterTitle={'过滤器'} handleFilterClick={handleFilterClick} />
          <span
            style={{
              width: '0',
              height: '16px',
              border: 'var(--border3)',
              marginRight: 16,
            }}></span>
          <Button
            type="primary"
            onClick={() => { drawerSwitch() }}>
            新建短信通道
          </Button>
        </div>
      </div>
      <div className={style.contentBox}>
        <FilterView ref={filterViewRef} searchList={searchList} getHeight={getHeight} finish={filterFinish}></FilterView>
        <div>
          <Table
            columns={columns}
            dataSource={listData}
            scroll={{x:1400, y: scrollY}}
            rowKey={record => record.accountId}
            pagination={{
              total: pageQuery.total,
              current: pageQuery.pageNum,
              pageSize: pageQuery.pageSize,
              showSizeChanger: true,
              showTotal: total => `共 ${total} 条`,
            }}
            onChange={e => handleTableChange(e)}
          />
        </div>
      </div>
      {/* 抽屉 */}
      {/* 新建短信通道 */}
      <SmsChannel open={open} drawer={drawerSwitch} accountId={accountId} channelStatus={channelStatus}></SmsChannel>
    </div>
  );
};
export default SMSACCOUNT;
