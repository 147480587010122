import { AxiosPromise } from 'axios';
import request from '../network'
import { ShopVO } from './types';

//获取部门列表
export function getShopListApi(): AxiosPromise<ShopVO[]> {
    return request({
        url: `/system/shop/list`,
        method: 'GET',
        data: {}
    });
}

export default {}