import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { tansParams, blobValidate } from '../utils/utils';
import cache from '../utils/cache';
import { HttpStatus } from '../Types/RespEnum';
import { errorCode } from '../utils/errorCode';
import FileSaver from 'file-saver';
// import { getLanguage } from '@/lang';
import { encryptBase64, encryptWithAes, generateAesKey, decryptWithAes, decryptBase64 } from '../utils/crypto';
import { encrypt, decrypt } from '../utils/jsencryptUtil';
import { Modal, message } from 'antd';
import loading from '../assets/images/newLoading.gif'
import { state } from '../store';

let requestCount = 0;

const encryptHeader = 'encrypt-key';
// let downloadLoadingInstance: LoadingInstance;
// 是否显示重新登录
export const isRelogin = { show: false };
export const globalHeaders = () => {
    return {
        // Authorization: 'Bearer ' + getToken(),
        clientid: process.env.REACT_APP_CLIENT_ID
    };
};


let modalShow = false;

let fileName = ''


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['clientid'] = process.env.REACT_APP_CLIENT_ID || '';
// 创建 axios 实例
const service = axios.create({
    // baseURL: import.meta.env.VITE_APP_BASE_API,
    baseURL: process.env.REACT_APP_BASE_API,
    timeout: 600000
});


function showLoading() {
    const dom = document.createElement('div')
    dom.setAttribute('id', '__loading')
    document.body.appendChild(dom);
    const img = document.createElement('img')
    img.setAttribute('id', '__loading-img')
    img.setAttribute('src', loading)
    dom.appendChild(img)
}
let num = 0;//数据正在处理弹窗 防止多次弹出

// 请求拦截器
service.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // 对应国际化资源文件后缀
        // config.headers['Content-Language'] = getLanguage();

        if (requestCount <= 0 && (config.url && state.NOT_NEED_LOAD_URL.indexOf(config.url.split('?')[0]) === -1)) {
            requestCount = 0
            showLoading();
        }
        if (config.url && state.NOT_NEED_LOAD_URL.indexOf(config.url) === -1) {
            requestCount++;
        }

        const isToken = (config.headers || {}).isToken === false;
        // 是否需要防止数据重复提交
        const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
        // 是否需要加密
        const isEncrypt = (config.headers || {}).isEncrypt === 'true';
        if (localStorage.getItem('userToken') && !isToken) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('userToken'); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }

        if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
            const requestObj = {
                url: config.url,
                data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
                time: new Date().getTime()
            };
            const sessionObj = cache.session.getJSON('sessionObj');
            if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
                cache.session.setJSON('sessionObj', requestObj);
            } else {
                const s_url = sessionObj.url; // 请求地址
                const s_data = sessionObj.data; // 请求数据
                const s_time = sessionObj.time; // 请求时间
                const interval = 500; // 间隔时间(ms)，小于此时间视为重复提交
                if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url && num === 0) {
                    // debugger
                    // const message = '数据正在处理，请勿重复提交';
                    // return Promise.reject(new Error(message));
                    // modalShow = false
                    // message.error({ content: '数据正在处理，请勿重复提交' });
                    // num = num + 1
                    // Modal.info({
                    //     title: '数据正在处理，请勿重复提交',
                    //     okText: '确定',
                    //     cancelText: '取消',
                    //     closable: true,
                    //     autoFocusButton: null,
                    //     onOk() {
                    //         modalShow = false
                    //         num = 0
                    //         // window.location.href = '/login'
                    //     },
                    //     onCancel() {
                    //         modalShow = false
                    //         num = 0
                    //     }
                    // })
                    // console.warn(`[${s_url}]: ` + message);
                } else {
                    cache.session.setJSON('sessionObj', requestObj);
                }
            }
        }
        // 当开启参数加密
        if (isEncrypt && (config.method === 'post' || config.method === 'put')) {
            // 生成一个 AES 密钥
            const aesKey = generateAesKey();
            config.headers[encryptHeader] = encrypt(encryptBase64(aesKey));
            config.data = typeof config.data === 'object' ? encryptWithAes(JSON.stringify(config.data), aesKey) : encryptWithAes(config.data, aesKey);
        }
        // FormData数据去请求头Content-Type
        if (config.data instanceof FormData) {
            delete config.headers['Content-Type'];
        }
        return config;
    },
    (error: any) => {
        console.log(error);

        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res: AxiosResponse) => {
        console.log(res);

        if (res.config.url && state.NOT_NEED_LOAD_URL.indexOf(res.config.url.split('?')[0]) === -1) {
            requestCount--;
        }
        if (requestCount === 0 && (res.config.url && state.NOT_NEED_LOAD_URL.indexOf(res.config.url) === -1)) {
            const dom = document.getElementById("__loading");
            if (dom) {
                document.body.removeChild(dom as HTMLElement);
            }
        }
        // 加密后的 AES 秘钥
        const keyStr = res.headers[encryptHeader];
        // 加密
        if (keyStr && keyStr !== null && keyStr !== '') {
            const data = res.data;
            // 请求体 AES 解密
            const base64Str = decrypt(keyStr);
            // base64 解码 得到请求头的 AES 秘钥
            const aesKey = decryptBase64(base64Str.toString());
            // aesKey 解码 data
            const decryptData = decryptWithAes(data, aesKey);
            // 将结果 (得到的是 JSON 字符串) 转为 JSON
            res.data = JSON.parse(decryptData);
        }
        // 未设置状态码则默认成功状态
        const code = res.data.code || HttpStatus.SUCCESS;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default'];
        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            fileName = decodeURIComponent(res.headers['download-filename'])
            return res.data;
        }
        if (code === 401) {
            // prettier-ignore
            if (window.location.pathname === '/') {
                window.location.href = '/login'
            }
            if (localStorage.getItem('userToken')) {
                if (!modalShow) {
                    modalShow = true
                    Modal.info({
                        title: '会话过期提示',
                        content: '您的会话已过期，请重新登录。',
                        okText: '确认',
                        cancelText: '取消',
                        closable: false,
                        onOk() {
                            modalShow = false
                            window.location.href = '/login'
                        },
                        onCancel() {
                            modalShow = false
                        }
                    })
                }
            } else {
                window.location.href = '/login'
            }
            return
        } else if (code === 403) { //当前操作没有权限
            message.error({ content: msg });
        }
        else if (code === HttpStatus.SERVER_ERROR || code === HttpStatus.CUSTOM_ERROR) {
            if (msg.indexOf('用户已锁定') !== -1) {
                setTimeout(() => {
                    window.location.href = '/login'
                }, 1000);
            }
            // 转换为短链接的接口特殊处理
            if (res.config.url && code === HttpStatus.SERVER_ERROR && res.config.url.indexOf('smsNode/createShortLink') !== -1) {
                return Promise.resolve(res.data);
            }
            message.error({ content: msg });
            // return Promise.reject(new Error(msg));
        } else if (code === HttpStatus.WARN) {
            // ElMessage({ message: msg, type: 'warning' });
            return Promise.reject(new Error(msg));
        } else if (code === HttpStatus.EXPORT_ERROR) {
            message.error({ content: msg });
        } else if (code !== HttpStatus.SUCCESS && code !== HttpStatus.CUSTOM_POP_ERROR) {
            // ElNotification.error({ title: msg });
            return Promise.reject('error');
        } else {
            return Promise.resolve(res.data);
        }
    },
    (error: any) => {
        console.log(error);
        message.error({ content: '网络异常' });
        requestCount = 0
        const dom = document.getElementById("__loading");
        if (dom) {
            document.body.removeChild(dom as HTMLElement);
        }

        // let { message } = error;
        // if (message === 'Network Error') {
        //     message = '后端接口连接异常';
        // } else if (message.includes('timeout')) {
        //     message = '系统接口请求超时';
        // } else if (message.includes('Request failed with status code')) {
        //     message = '系统接口' + message.substr(message.length - 3) + '异常';
        // } else {
        //     message = '网络异常';
        // }

        // ElMessage({ message: message, type: 'error', duration: 5 * 1000 });
        // return Promise.reject(error);
    }
);
// 通用下载方法
export function download(url: string) {
    // downloadLoadingInstance = ElLoading.service({ text: '正在下载数据，请稍候', background: 'rgba(0, 0, 0, 0.7)' }); 
    // prettier-ignore    
    return service.get(url, {
        responseType: 'blob'
    }).then(async (resp: any) => {
        if (resp) {
            const blob = new Blob([resp]);
            FileSaver.saveAs(blob, fileName);
            message.success('下载完成')
        }
    }).catch((r: any) => {
        console.error(r);
        // ElMessage.error('下载文件出现错误，请联系管理员！');
        // downloadLoadingInstance.close();
    });
}
// 导出 axios 实例
export default service;
