export default {
  dataSourceMap: new Map([
    [
      "0",
      [
        {
          id: "0",
          titleEn: "Sales",
          metricName: "购买金额",
          titleZh: "销售额",
          tip: "统计期内所有客户已支付的销售额",
          value: "",
          digit: 2,
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "1",
          titleEn: "Purchaser",
          titleZh: "购买人数",
          metricName: "购买人数",
          digit: 0,
          tip: "统计期内购买的总客户人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "2",
          titleEn: "Units",
          titleZh: "购买件数",
          metricName: "购买件数",
          digit: 0,
          tip: "统计期内购买的产品总件数，按sku为粒度",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
                {
          id: "3",
          titleEn: "Transactions",
          titleZh: "订单数",
          metricName: "订单数",
          digit: 0,
          tip: "统计期内所有客户已支付的订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
                {
          id: "4",
          titleEn: "ATV",
          titleZh: "笔单价",
          metricName: "笔单价",
          digit: 2,
          tip: "统计期内，平均每笔订单销售额。销售额/订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "5",
          titleEn: "FREQ",
          titleZh: "购买频次",
          metricName: "购买频次",
          digit: 2,
          tip: "统计期内，平均每个客户购买的订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "6",
          titleEn: "SPD",
          titleZh: "客单价",
          metricName: "客单价",
          digit: 2,
          tip: "统计期内，平均每个客户购买的金额。销售额/购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "7",
          titleEn: "UPT",
          titleZh: "笔件数",
          metricName: "笔件数",
          digit: 2,
          tip: "统计期内，平均每笔订单中购买的产品件数。sku数/订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          metricId: "",
          frameWorkType: 0,
        },
      ],
    ],
    // 会员
    [
      "1",
      [
        {
          id: "8",
          titleEn: "TTL Members",
          titleZh: "累计注册会员数",
          metricName: "累计注册会员数",
          tip: "截止到统计期存量会员，所有已注册的会员人数(不含解绑)",
          value: "",
          digit: 0,
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "9",
          titleEn: "Sales",
          titleZh: "销售额",
          metricName: "会员销售额",
          digit: 2,
          tip: "统计期内会员身份的销售额",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "10",
          titleEn: "Sales%",
          titleZh: "销售额占比",
          metricName: "会员销售额占比",
          percent: true,
          digit: 2,
          tip: "统计期内会员销售额占比所有客户销售额。会员销售额/销售额",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "11",
          titleEn: "Purchaser",
          titleZh: "购买人数",
          metricName: "会员购买人数",
          digit: 0,
          tip: "统计期内会员的购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "12",
          titleEn: "Purchaser%",
          titleZh: "购买人数占比",
          metricName: "会员购买人数占比",
          digit: 2,
          percent: true,
          tip: "统计期内会员的购买人数占比所有购买客户数。会员购买人数/购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "13",
          titleEn: "Units",
          titleZh: "购买件数",
          metricName: "会员购买件数",
          digit: 0,
          tip: "统计期内会员购买的商品件数，按sku粒度统计",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "14",
          titleEn: "Transactions",
          titleZh: "订单数",
          metricName: "会员订单数",
          digit: 0,
          tip: "统计期内会员购买的订单数",
          value: "",
          mom: "",
          yoy:"",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "15",
          titleEn: "ATV",
          titleZh: "笔单价",
          metricName: "会员笔单价",
          digit: 2,
          tip: "统计期内，由会员产生的订单，平均每笔订单金额。会员销售额/会员订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "16",
          titleEn: "FREQ",
          titleZh: "购买频次",
          metricName: "会员购买频次",
          digit: 2,
          tip: "统计期内，平均每个有效购买订单的会员购买的订单数。会员订单数/会员购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "17",
          titleEn: "SPD",
          titleZh: "客单价",
          metricName: "会员客单价",
          digit: 2,
          tip: "统计期内，平均每个有效购买订单的会员的销售额。会员销售额/会员购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "18",
          titleEn: "UPT",
          titleZh: "笔件数",
          metricName: "会员笔件数",
          digit: 2,
          tip: "统计期内，由会员产生的订单，平均每笔订单包含的产品sku数。会员购买件数/会员有效购买订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "lakeBlue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
      ],
    ],
    // 新入会
    [
      "2",
      [
        {
          id: "19",
          titleEn: "TTL Members",
          titleZh: "累计注册会员数",
          metricName: "累计注册会员数",
          tip: "统计期内新注册的会员，不含解绑",
          value: "",
          digit: 0,
          mom: "",
          yoy: "",
          color: "orange",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "20",
          titleEn: "Recuit",
          titleZh: "新增会员人数",
          metricName: "新注册会员数",
          digit: 0,
          tip: "统计期内新注册的会员人数",
          value: "",
          mom: "",
          yoy: "",
          color: "orange",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "21",
          titleEn: "New -CVR%",
          titleZh: "新增会员购买人数占比",
          metricName: "新会员购买人数占比",
          digit: 2,
          percent: true,
          tip: "统计期内新增会员中购买的人数/新增会员人数",
          value: "",
          mom: "",
          yoy: "",
          color: "orange",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
      ],
    ],
    // 新客
    [
      "3",
      [
        {
          id: "22",
          titleEn: "Sales",
          titleZh: "销售额",
          metricName: "新客销售额",
          tip: "统计期内，首次购买的客户的销售额",
          value: "",
          digit: 2,
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "23",
          titleEn: "Sales%",
          titleZh: "销售额占比",
          metricName: "新客销售额占比",
          digit: 2,
          percent: true,
          tip: "统计周期内，首次购买的客户的销售额占比所有客户的销售额。新客销售额/总销售额",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "24",
          titleEn: "Purchaser",
          titleZh: "购买人数",
          metricName: "新客购买人数",
          digit: 0,
          tip: "统计期内，首次购买的人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "25",
          titleEn: "Purchaser%",
          titleZh: "购买人数占比",
          metricName: "新客购买人数占比",
          percent: true,
          digit: 2,
          tip: "统计周期内，首次购买的客户人数占比所有购买人数。新客购买人数/总购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "26",
          titleEn: "Members",
          titleZh: "新客会员人数",
          metricName: "新客会员购买人数",
          digit: 0,
          tip: "统计期内，首次购买的客户且是全渠道会员身份的人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "27",
          titleEn: "Members%",
          titleZh: "新客会员人数占比",
          metricName: "新客会员购买人数占比",
          digit: 2,
          percent: true,
          tip: "新客会员人数/新客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "28",
          titleEn: "Units",
          titleZh: "购买件数",
          metricName: "新客购买件数",
          digit: 0,
          tip: "统计期内，首次购买的客户购买的商品件数，粒度按sku统计",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "29",
          titleEn: "Transactions",
          titleZh: "订单数",
          metricName: "新客订单数",
          digit: 0,
          tip: "统计期内，首次购买的客户的订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "30",
          titleEn: "ATV",
          titleZh: "笔单价",
          metricName: "新客笔单价",
          digit: 2,
          tip: "统计期内，首次购买的客户平均每笔订单销售额",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "31",
          titleEn: "FREQ",
          titleZh: "购买频次",
          metricName: "新客购买频次",
          digit: 2,
          tip: "统计期内，首次购买的客户平均购买笔数。新客订单数/新客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "32",
          titleEn: "SPD",
          titleZh: "客单价",
          metricName: "新客客单价",
          digit: 2,
          tip: "统计期内，首次购买的客户平均每人的销售额。新客销售额/新客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },

        {
          id: "33",
          titleEn: "UPT",
          titleZh: "笔件数",
          metricName: "新客笔件数",
          digit: 2,
          tip: "统计期内，首次购买的客户平均每笔订单包含的商品件数，粒度按sku统计。新客购买件数/新客订单数数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "34",
          titleEn: "Repeat rate",
          titleZh: "新客二回购比例",
          metricName: "新客二回率",
          digit: 2,
          percent: true,
          tip: "统计期内，首次购买的客户且有效购买订单2笔及以上的人数占比新客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "blue",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
      ],
    ],
    // 老客
    [
      "4",
      [
        {
          id: "35",
          titleEn: "Sales",
          titleZh: "销售额",
          metricName: "老客销售额",
          tip: "统计期内，老客购买的销售额",
          value: "",
          digit: 2,
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "36",
          titleEn: "Sales%",
          titleZh: "销售额占比",
          metricName: "老客销售额占比",
          digit: 2,
          percent: true,
          tip: "统计期内，老客购买的销售额占比所有客户购买的销售额",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "37",
          titleEn: "Purchaser",
          titleZh: "购买人数",
          metricName: "老客购买人数",
          digit: 0,
          tip: "统计期内，老客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "38",
          titleEn: "Purchaser%",
          titleZh: "购买人数占比",
          metricName: "老客购买人数占比",
          digit: 2,
          percent: true,
          tip: "统计期内，老客购买人数占比购买总客户数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "39",
          titleEn: "Members",
          titleZh: "老客会员购买人数",
          metricName: "老客会员购买人数",
          digit: 0,
          tip: "统计期内，历史购买过的客户本期购买且是会员身份的人数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "40",
          titleEn: "Members%",
          titleZh: "老客会员购买人数占比",
          metricName: "老客会员购买人数占比",
          digit: 2,
          percent: true,
          tip: "老客会员购买人数/老客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "41",
          titleEn: "Existing Base",
          titleZh: "老客存量人数",
          metricName: "老客存量人数",
          digit: 0,
          tip: "统计期前，历史有效购买订单的客户数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "42",
          titleEn: "Units",
          titleZh: "购买件数",
          metricName: "老客购买件数",
          digit: 0,
          tip: "统计期内，老客购买的商品件数，按sku粒度统计",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "43",
          titleEn: "Transactions",
          titleZh: "订单数",
          metricName: "老客订单数",
          digit: 0,
          tip: "统计期内，老客购买的订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "44",
          titleEn: "ATV",
          titleZh: "笔单价",
          metricName: "老客笔单价",
          digit: 2,
          tip: "统计期内，由老客购买的订单，平均每笔订单金额。老客销售额/老客订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "45",
          titleEn: "FREQ",
          titleZh: "购买频次",
          metricName: "老客购买频次",
          digit: 2,
          tip: "统计期内，平均每个有效购买订单的老客购买的订单笔数。老客订单数/老客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "46",
          titleEn: "SPD",
          titleZh: "客单价",
          metricName: "老客客单价",
          digit: 2,
          tip: "统计期内，平均每个有效购买订单的老客的销售额。老客销售额/老客购买人数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "47",
          titleEn: "UPT",
          titleZh: "笔件数",
          metricName: "老客笔件数",
          digit: 2,
          tip: "统计期内，由老客购买的订单，平均每笔订单金包含的商品sku数。老客购买件数/老客订单数",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "48",
          titleEn: "Exisiting Consumer Recency",
          titleZh: "平均复购周期",
          metricName: "平均复购周期",
          digit: 2,
          tip: "老客购买的平均复购周期（最晚一笔订单时间-最早一笔订单时间）/（有效购买订单数-1）",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
        {
          id: "49",
          titleEn: "Retention Rate",
          titleZh: "老客回购率",
          metricName: "老客回购率",
          digit: 2,
          percent: true,
          tip: "统计期前12个月有购买的老客在本期的回购比例。",
          value: "",
          mom: "",
          yoy: "",
          color: "green",
          miniChatData: [],
          metricId: "",
          frameWorkType: 0,
        },
      ],
    ],
  ]),
};
