import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import busEvent from '@/utils/bus';
import { Button, Empty, Table, Dropdown, Space, Input, Tree, TreeDataNode, Popconfirm, message, Tag, Tooltip } from 'antd';
import type { GetProps, TableColumnsType, TableProps, MenuProps } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { LeftOutlined, PlusOutlined, DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import ModuleTitle from '../../../../component/ModuleTitle';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterView from '../../../../component/FilterView/filterView';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import { motion } from 'framer-motion';
import AddJourney from './component/AddJourney';
import AddSence from './component/AddSence';
import TransferPacket from './component/TransferPacket';
import { getNumberWithComma } from '@/utils/utils'; //给千位数字加逗号的方法
import { checkPermissions } from '@/utils/utils';
import UnderLineInput from '../../../../component/Input/UnderLineInput/underLineInput';
import { async } from '@antv/x6/lib/registry/marker/async';
import { getTableScroll, state } from '@/store/index';
import {
  getStrategyInfo,
  getStrategyStateNumByScnId,
  getStrategyStateList,
  getStrategyTypeList,
  strategiesDelete,
  addJourneyInfo,
  updateSceneInfo,
  updateJourneyInfo,
  addSceneInfo,
  getJourneySceneInfo,
  getJourneySceneInfoByName,
  strategiesCancel,
  strategiesStop,
  strategiesChangeGroup,
  strategiesCopy,
  deleteJourneyInfo,
  deleteSceneInfo,
  updateSortIndex,
} from '../../../../api/graph/graph';
import bus from '@/utils/bus';
import YLIcon from '../../../../../src/component/Icon/YLIcon.jsx';
const { Search } = Input;
const { TreeNode } = Tree;
type SearchProps = GetProps<typeof Input.Search>;
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

interface DataType {
  key: React.Key;
  name: string;
  id: string;
  user: string;
  type: string;
  status: string;
  startTime: string;
  endTime: string;
  createTime: string;
  updateTime: string;
}

let travelId: any = null; //选中旅程的id
let scnId: any = null; //选中场景的id
const menuWidth = 88;
const MAList = () => {
  const treeContainerRef = useRef<HTMLDivElement | null>(null);
  const [isArrow, setIsArrow] = useState(true); //折叠框
  const [isShow, setIsShow] = useState(true); //
  const [listDataShow, setListDataShow] = useState(false); //右侧策略列表显示
  const [addJourneyOpen, setAddJourneyOpen] = useState(false); //新建旅程
  const [journeyData, setJourneyData] = useState(null as any); //旅程信息
  const [senceData, setSenceData] = useState(null as any); //场景信息
  const [addSenceOpen, setAddSenceOpen] = useState(false); //新建场景
  const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number);
  const [changeStopAll, setChangeStopAll] = useState(false); //批量操作
  const [resetConfirm, setResetConfirm] = useState(false); //二次确认
  const [confirmText, setConfirmText] = useState(''); //二次确认
  const [transferPacketOpen, setTransferPacketOpen] = useState(false); //新建策略
  const [strategyNumOptions, setStrategyNumOptions] = useState({} as any); //策略数量
  const [strategyTypeOptions, setStrategyTypeOptions] = useState([] as any); //策略类型
  const [strategyStateOptions, setStrategyStateOptions] = useState([] as any); //策略状态列表
  const [currentHoverTreeKey, setCurrentHoverTreeKey] = useState(''); //鼠标移入移除标记
  const [threePoint, sethreePoint] = useState<any>({ title: '', key: '' }); //二次确认框信息
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const filterViewRef: any = useRef(null); //过滤器
  const addSenceRef: any = useRef(null); //新建场景
  const addJourneyRef: any = useRef(null); //新建旅程
  const transferPacketRef: any = useRef(null); //移入分组
  const searchParamInt: any = {};
  // 树状列表
  const [treeData, setTreeData]: any = useState([]);
  //选中的状态
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const navigate = useNavigate();
  const [showStopBtn, setShowStopBtn] = useState(false); //批量操作中是否显示批量停止？
  let location = useLocation();
  // 顶部数据
  const list: any = [
    {
      title: '全部',
      borderColor: 'var(--border19)',
      backgroundColor: 'var(--background4)',
      icon: 'icon-quanbu',
      iconColor: 'var(--text-font-color7)',
      num: strategyNumOptions.all,
    },
    {
      title: '草稿',
      borderColor: 'var(--border3)',
      backgroundColor: 'var(--background2)',
      icon: 'icon-caogao',
      iconColor: 'var(--text-font-color4)',
      num: strategyNumOptions.draft,
    },
    {
      title: '待启动',
      borderColor: 'var(--label-border6)',
      backgroundColor: 'var(--label-background-color6)',
      icon: 'icon-daiqidong',
      iconColor: 'var(--text-font-color40)',
      num: strategyNumOptions.pend,
    },
    {
      title: '运行中',
      borderColor: 'var(--label-border1)',
      backgroundColor: 'var(--label-background-color1)',
      icon: 'icon-yunhangzhong',
      iconColor: 'var(--text-font-color19)',
      num: strategyNumOptions.run,
    },
    {
      title: '已停止',
      borderColor: 'var(--border3)',
      backgroundColor: 'var(--background2)',
      icon: 'icon-yitingzhi',
      iconColor: 'var(--text-font-color4)',
      num: strategyNumOptions.stop,
    },
  ];
  const columns: TableColumnsType<DataType> = [
    {
      title: '策略名称',
      dataIndex: 'name',
      key: 'name',
      width: '150px',
      render: (_, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <div
            className={style.nameOutlined}
            onClick={() => {
              // '' 创建  0, "草稿  1, "待启动"  2, "运行中"  3, "已停止"  4, "已删除"
              navigate('/MA/MarketPlan/MAList/strategy', {
                state: { graphStatus: record.state.toString(), fileName: _, id: record.id },
              });
            }}>
            {_ ? _ : '--'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '策略ID',
      dataIndex: 'id',
      key: 'id',
      width: '88px',
      render: (_, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <div className={style.nameOutlined2}>{_}</div>
        </Tooltip>
      ),
    },
    {
      title: '目标客户',
      dataIndex: 'crowdName',
      key: 'crowdName',
      width: '140px',
    },
    {
      title: '策略类型',
      dataIndex: 'type',
      key: 'type',
      width: '140px',
      render: (_, record) => (
        <>
          {_ === 1 && <div>触发型策略</div>}
          {_ === 2 && <div>一次性策略</div>}
          {_ === 3 && <div>周期性策略</div>}
          {_ === 4 && <div>纪念日策略</div>}
        </>
      ),
    },
    {
      title: '策略状态',
      dataIndex: 'state',
      key: 'state',
      width: '140px',
      render: (_, record) => (
        <>
          {_ === 0 && <Tag style={{ color: 'var(--text-font-color9)' }}>草稿</Tag>}
          {_ === 1 && <Tag color="warning">待启动</Tag>}
          {_ === 2 && <Tag color="success">运行中</Tag>}
          {_ === 3 && <Tag style={{ color: 'var(--text-font-color4)' }}>已停止</Tag>}
        </>
      ),
    },
    {
      title: '起止时间',
      dataIndex: 'startTime',
      key: 'startTime',
      width: '200px',
      render: (_, record) => (
        <>
          <div>起：{record.startTime}</div>
          <div>止：{record.endTime}</div>
        </>
      ),
      sorter: (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: '140px',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime(),
    },
    {
      title: '最后更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '140px',
      sorter: (a, b) => new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime(),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: '88px',
      align: 'center',
      fixed: 'right',
      render: (_, record: any) => (
        <Space>
          <Popconfirm
            placement="right"
            title={confirmText}
            okText="确定"
            cancelText="取消"
            onConfirm={e => handleConfirmClickPop(e, record)}
            onCancel={e => handleCancelClickPop(e)}
            open={selectedTableKey === record.id && resetConfirm}
            onPopupClick={e => {
              e.stopPropagation();
            }}>
            <Dropdown menu={{ items: getTableOperationButtonList(record) }} trigger={['click']}>
              <div>
                <EllipsisOutlined
                  style={{
                    color: 'var(--text-font-color33)',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </Dropdown>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  // 操作列
  const getTableOperationButtonList = (item: any) => {
    const store = [];
    store.push({
      key: 'copy',
      label: (
        <a
          style={{ color: 'var(--text-font-color9)' }}
          onClick={() => {
            strategiesCopyFunction(item.id);
          }}>
          复制
        </a>
      ),
    });
    if (item.state === 2) {
      store.push({
        key: 'stop',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              changeStrategyFuntion(item.id, 0);
            }}>
            停止
          </a>
        ),
      });
    }
    if (item.state === 0) {
      store.push({
        key: 'log',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              // '' 创建  0, "草稿  1, "待启动"  2, "运行中"  3, "已停止"  4, "已删除"
              navigate('/MA/MarketPlan/MAList/strategy', {
                state: { graphStatus: '0', fileName: item.name, id: item.id },
              });
            }}>
            编辑
          </a>
        ),
      });
    }
    if (item.state === 0 || item.state === 3) {
      store.push({
        key: 'delete',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              changeStrategyFuntion(item.id, 2);
            }}>
            删除
          </a>
        ),
      });
    }
    if (item.state === 1) {
      store.push({
        key: 'cancel',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              changeStrategyFuntion(item.id, 3);
            }}>
            取消启动
          </a>
        ),
      });
    }
    return store;
  };
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入策略名称',
      title: '策略名称',
      key: 'name',
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '策略类型',
      key: 'type',
      fieldNames: {
        label: 'name',
        value: 'id',
      },
      options: strategyTypeOptions,
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '策略状态',
      key: 'state',
      fieldNames: {
        label: 'name',
        value: 'id',
      },
      options: strategyStateOptions,
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '创建时间',
      key: 'createStartTime-createEndTime',
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '起止时间',
      key: 'startTime-endTime',
      dateFalse: true,
    },
  ];
  const [dataSource, setDataSource]: any = useState([] as any);
  const [pageQuery, setPageQuery] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  });
  const [scrollY, setScrollY] = useState('' as any);
  const [messageApi, contextHolder] = message.useMessage();
  const [dropKey, setDropKey] = useState<any>(null as unknown as number);

  // 提示
  const messageSuccess = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
    });
  };
  const messageWarning = (text: string) => {
    messageApi.open({
      type: 'warning',
      content: text,
    });
  };
  const getHeight = (e: string) => {
    if (filterViewRef.current?.arrow && dataSource.length > 4) {
      setScrollY(e);
    } else {
      setY(dataSource);
    }
  };
  // 左侧栏滑动
  const handleArrowClick = () => {
    setIsArrow(!isArrow);
    if (!isShow) {
      setTimeout(() => {
        setIsShow(!isShow);
      }, 300);
    } else {
      setIsShow(!isShow);
    }
  };
  //获取策略列表
  const getStrategydata = async (page: { pageNum: number; pageSize: number }, taskLocation?: any) => {
    const param: any = {
      pageNum: page.pageNum,
      pageSize: page.pageSize,
      ...taskLocation,
      scnId: scnId,
    };
    const res: any = await getStrategyInfo(param);
    if (res) {
      setDataSource(res.data.content);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      // 清空选中
      setSelectedRowKeys([]);
      setY(res.data.content);
    }
  };
  const setY = (list: any) => {
    if (list.length < 7) {
      setScrollY(undefined);
    } else {
      setScrollY(getTableScroll());
    }
  };
  //获取策略数量
  const getStrategyNumdata = async (id: any) => {
    const res = await getStrategyStateNumByScnId({ scnId: id });
    if (res) {
      setStrategyNumOptions(res.data);
    }
  };
  //获取策略状态列表
  const getStrategyStatedata = async () => {
    const res = await getStrategyStateList();
    if (res) {
      setStrategyStateOptions(res.data);
    }
  };
  //获取策略类型列表
  const getStrategyTypedata = async () => {
    const res = await getStrategyTypeList();
    if (res) {
      setStrategyTypeOptions(res.data);
    }
  };
  //复制策略
  const strategiesCopyFunction = async (id: any) => {
    const res = await strategiesCopy({ id: id });
    if (res) {
      messageSuccess('复制成功');
      getStrategyNumdata(scnId);
      getStrategydata({ pageSize: 10, pageNum: 1 });
    }
  };
  //操作策略
  const changeStrategyFuntion = async (id: any, type: number) => {
    //只有1 是批量停止 两个不可同时出现
    if (type === 1) {
      setChangeStopAll(true);
      setResetConfirm(false);
      setSelectedTableKey(null as unknown as number);
    } else {
      setSelectedTableKey(id);
      setChangeStopAll(false);
      setResetConfirm(true);
    }
    var text = '';
    switch (type) {
      case 0:
        text = '确定停止此策略吗？';
        break;
      case 1:
        text = '确定批量停止这些策略吗？';
        break;
      case 2:
        text = '确定删除此策略吗？';
        break;
      case 3:
        text = '确定取消启动此策略吗？';
        break;
      default:
        break;
    }
    setConfirmText(text);
  };
  //删除策略
  const deleteStrategyFuntion = async (value: any) => {
    const res = await strategiesDelete({ id: value });
    if (res) {
      messageSuccess('删除成功');
      getStrategyNumdata(scnId);
      getStrategydata({ pageSize: 10, pageNum: 1 });
      handleCancelClickPop();
    }
    setClickStatus(false);
  };
  //取消待启动 策略
  const cancelStrategyFuntion = async (value: any) => {
    const res = await strategiesCancel({ id: value });
    if (res) {
      messageSuccess('取消成功');
      getStrategyNumdata(scnId);
      getStrategydata({ pageSize: 10, pageNum: 1 });
      handleCancelClickPop();
    }
    setClickStatus(false);
  };
  //停止 策略
  const strategiesStopFuntion = async (value?: any) => {
    var param = { ids: value ? [value] : selectedRowKeys };
    const res = await strategiesStop(param);
    if (res) {
      messageSuccess(value ? '停止成功' : '批量停止成功');
      getStrategyNumdata(scnId);
      getStrategydata({ pageSize: 10, pageNum: 1 });
      handleCancelClickPop();
    }
    setClickStatus(false);
  };
  //防连点
  const [clickStatus, setClickStatus] = useState(false);
  //列表中二次确认框 确认之后
  const handleConfirmClickPop = async (value: any, record?: any) => {
    var type = record?.state;
    var id = record?.id;
    if (clickStatus) {
      return;
    }
    setClickStatus(true);
    switch (type) {
      case 0:
        deleteStrategyFuntion(id);
        break;
      case 1:
        cancelStrategyFuntion(id);
        break;
      case 2:
        strategiesStopFuntion(id);
        break;
      case 3:
        deleteStrategyFuntion(id);
        break;
      default:
        strategiesStopFuntion();
        break;
    }
  };
  //列表中二次确认框 取消之后
  const handleCancelClickPop = (info?: any) => {
    setResetConfirm(false);
    setChangeStopAll(false);
    setSelectedTableKey(null as unknown as number);
  };
  //获取旅程
  const getJourneySceneData = async () => {
    const res = await getJourneySceneInfo();
    if (res) {
      if (res.data) {
        let firstShow: any = [];
        console.log(
          322,
          res.data.map((item: any, index: any) => {
            let obj = {
              ...item,
              floor: 0,
            };
            if (item.sceneLists.length > 0) {
              firstShow.push(index);
              obj.sceneLists = item.sceneLists.map((item1: any) => {
                return {
                  ...item1,
                  floor: 1,
                };
              });
            }
            return obj;
          })
        );
        setTreeData(
          res.data.map((item: any) => {
            let obj = {
              ...item,
              floor: 0,
            };
            if (item.sceneLists.length > 0) {
              obj.sceneLists = item.sceneLists.map((item1: any) => {
                return {
                  ...item1,
                  floor: 1,
                };
              });
            }
            return obj;
          })
        );
        if (!scnId) {
          scnId = res.data[firstShow[0]]?.sceneLists[0]?.id;
          travelId = res.data[firstShow[0]]?.sceneLists[0]?.parentId;
        }
        console.log('==========defaultSelectedKeydefaultSelectedKey', scnId, travelId);
        setSelectedKeys([scnId]);
        setHasExpandedKeys([travelId, scnId]);
        setListDataShow(true);
        //策略查询
        getStrategydata({ pageSize: 10, pageNum: 1 });
        getStrategyNumdata(scnId);
        getStrategyStatedata();
        getStrategyTypedata();
      }
    }
  };
  //新建/更新旅程
  const changeAddJourneyOpen = async (type: any, params?: any) => {
    console.log('===========params', params);
    var state = false;
    if (params?.id && params?.name && !type) {
      const res = await updateJourneyInfo(params);
      if (res) {
        message.success('更新成功');
        getJourneySceneData();
      } else {
        state = true;
      }
    } else if (params?.name && !type) {
      const res = await addJourneyInfo({ name: params.name });
      if (res) {
        message.success('新建成功');
        getJourneySceneData();
      } else {
        state = true;
      }
    }
    if (state) {
      return;
    }
    addJourneyRef.current?.clearFrom();
    setAddJourneyOpen(type);
    //抽屉打开时将二次确认框关闭
    handleCancelClickPop();
  };

  //新建/更新场景
  const changeAddSenceOpen = async (type: any, info?: any) => {
    var state = false;
    if (info?.id && info?.name && info?.parentId && !type) {
      const res = await updateSceneInfo(info);
      if (res) {
        message.success('更新成功');
        getJourneySceneData();
      } else {
        state = true;
      }
    } else if (info?.name && info?.parentId && !type) {
      const params = {
        parentId: info.parentId,
        name: info.name,
      };
      const res = await addSceneInfo(params);
      if (res) {
        message.success('新建成功');
        getJourneySceneData();
      } else {
        state = true;
      }
    }
    if (state) {
      return;
    }
    addSenceRef.current?.clearFrom();
    setAddSenceOpen(type);
    //抽屉打开时将二次确认框关闭
    handleCancelClickPop();
  };
  //删除旅程
  const deleteJourneyFuntion = async (id: any) => {
    const res = await deleteJourneyInfo({ id: id });
    if (res) {
      message.success('删除成功');
      getJourneySceneData();
    }
  };
  //删除场景
  const deleteSceneFuntion = async (id: any) => {
    const res = await deleteSceneInfo({ id: id });
    if (res) {
      message.success('删除成功');
      getJourneySceneData();
    }
  };
  //树形结构被选中
  const onSelect = (selectedKeys: any, info?: any) => {
    if (info.node.pos.split('-').length === 3 && selectedKeys.length > 0 && scnId !== selectedKeys[0]) {
      scnId = info.node.key;
      setSelectedKeys([scnId]);
      var index = info.node.pos.split('-')[1];
      travelId = treeData[index].id;
      setListDataShow(true);
      //策略查询
      getStrategydata({ pageSize: 10, pageNum: 1 });
      getStrategyNumdata(scnId);
      getStrategyStatedata();
      getStrategyTypedata();
      if (filterViewRef.current?.arrow) {
        handleFilterClick();
      }
    }
    //是第二级才走策略查询
    // if (info.node.pos.split('-').length === 3 && selectedKeys?.length > 0) {
    //   scnId = info.node.key[0];
    //   var index = info.node.pos.split('-')[1];
    //   travelId = treeData[index].id;
    //   setListDataShow(true);
    //   //策略查询
    //   getStrategydata({ pageSize: 10, pageNum: 1 });
    //   getStrategyNumdata(scnId);
    //   getStrategyStatedata();
    //   getStrategyTypedata();
    // }
    // else {
    //   scnId = null;
    //   setListDataShow(false);
    // }
  };
  //移动分组
  const changeTransferPacketOpen = async (type: any, info?: any) => {
    if (info && !type) {
      const params = {
        ids: selectedRowKeys,
        parentId: info.journey,
        scnId: info.scene,
      };
      const res = await strategiesChangeGroup(params);
      if (res) {
        message.success('移动成功');
        getStrategyNumdata(scnId);
        getStrategydata({ pageSize: 10, pageNum: 1 });
      } else {
        return;
      }
    }
    transferPacketRef.current?.clearFrom();
    setTransferPacketOpen(type);
    //抽屉打开时将二次确认框关闭
    handleCancelClickPop();
  };

  const filterFinish = (data: any) => {
    setSearchParam(data);
    getStrategydata({ pageSize: 10, pageNum: 1 }, data);
  };
  // 过滤器
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  //根据名称模糊查询旅程场景信息
  const onSearch: SearchProps['onSearch'] = async (value, _e, info) => {
    const res = await getJourneySceneInfoByName({ name: value });
    if (res) {
      setTreeData(res.data);
    }
    console.log(info?.source, value);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    const param = dataSource.filter((item: any) => newSelectedRowKeys.includes(item.id));
    let status = param.some((item: any) => item.state === 2);
    setShowStopBtn(status);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const items: MenuProps['items'] = [
    {
      label: '移到分组',
      key: '1',
    },
    ...(showStopBtn ? [{ label: '批量停止', key: '2' }] : []),
    {
      label: '取消选择',
      key: '3',
    },
  ];
  // 批量操作
  const handleMenuClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      // 移到分组
      case '1':
        changeTransferPacketOpen(true);
        break;
      // 批量停止
      case '2':
        changeStrategyFuntion(null, 1);
        break;
      // 取消选择
      case '3':
        setSelectedRowKeys([]);
        break;
      default:
        break;
    }
  };
  const menuProps = {
    items: items,
    onClick: handleMenuClick,
  };
  //选择树节点
  const handleTreeSelect = (selectedKeys: any, e: any) => {
    // if (!isEditing && inputType.indexOf('add') === -1) {
    //     pos = e.node.pos
    //     setSelectedKeys([e.node.key])
    //     getUserList(e.node.key.toString(), '', {})
    // }
  };
  const onConfirm = (e: any, node: any) => {
    switch (threePoint.key) {
      case 'modification':
        // 修改的逻辑
        if (node.sceneLists) {
          setJourneyData({
            id: node.id,
            name: node.name,
          });
          changeAddJourneyOpen(true);
        } else {
          setSenceData({
            id: node.id,
            parentId: node.parentId,
            name: node.name,
          });
          //修改场景
          changeAddSenceOpen(true);
        }
        break;
      case 'delete':
        // 删除的逻辑
        //删除旅程
        if (node.sceneLists) {
          deleteJourneyFuntion(node.id);
        } else {
          //删除场景
          deleteSceneFuntion(node.id);
        }
        break;

      default:
        break;
    }
    // e.stopPropagation();
  };
  const onCancel = (e: any) => {
    sethreePoint({ title: '', key: '' });
    e.stopPropagation();
  };
  const [hasExpandedKeys, setHasExpandedKeys] = useState([] as any[]);
  const onExpand = async (selectedKeys: any, e: any) => {
    // const { key, pos } = e.node
    // //展开第一层标签
    // if (pos.split('-').length === 2) {
    //     //未加载过数据
    //     if (hasLoadNode.indexOf(key) === -1) {
    //         await getCategoryList(pos.split('-').length, key, parseInt(pos.split('-')[1]))
    //     }
    // } else {
    //     if (hasLoadNode.indexOf(key) === -1) {

    //     }
    // }
    console.log(selectedKeys);

    setHasExpandedKeys(selectedKeys);
  };
  //树状图加...按钮
  const renderTreeNodes = (data: any) => {
    return data.map((node: any) => {
      const { sceneLists, name, parentId, id } = node;
      const opertion = (
        <Space style={{ position: 'absolute', right: '12px' }}>
          <Dropdown open={id === dropKey} menu={{ items: getOperationList(node) }} trigger={['click']} placement="bottom">
            <Popconfirm
              placement="right"
              title={threePoint.title}
              okText="确定"
              description={threePoint.description}
              cancelText="取消"
              onConfirm={e => onConfirm(e, node)}
              onCancel={e => onCancel(e)}
              open={threePoint.key !== ''}>
              <a onClick={e => {}}>
                <Space
                  onClick={e => {
                    console.log(e);
                    setDropKey(id);
                    // onSelect(e, { node });
                    e.stopPropagation();
                  }}>
                  <EllipsisOutlined style={{ color: 'var(--text-font-color3)', opacity: '0.45' }} />
                </Space>
              </a>
            </Popconfirm>
          </Dropdown>
        </Space>
      );

      if (sceneLists && sceneLists.length > 0) {
        return (
          <TreeNode
            key={id}
            title={
              <div
                onMouseLeave={() => {
                  setCurrentHoverTreeKey('');
                  sethreePoint({ title: '', key: '' });
                }}
                onMouseEnter={() => setCurrentHoverTreeKey(id)}
                style={{ display: 'flex', flex: 1 }}>
                <Tooltip mouseEnterDelay={0.5} title={name}>
                  <span
                    className="line-text"
                    style={{
                      width: parentId === '0' || !parentId ? menuWidth : Number(menuWidth - 25),
                    }}>
                    {name}
                  </span>
                </Tooltip>
                <span style={{ width: 20 }}>{id === currentHoverTreeKey && opertion}</span>
              </div>
            }>
            {renderTreeNodes(sceneLists)}
          </TreeNode>
        );
      }

      return (
        <TreeNode
          key={id}
          title={
            <div
              style={{ display: 'flex', flex: 1 }}
              onMouseLeave={() => {
                setCurrentHoverTreeKey('');
                sethreePoint({ title: '', key: '' });
              }}
              onMouseEnter={() => setCurrentHoverTreeKey(id)}>
              <Tooltip mouseEnterDelay={0.5} title={name}>
                <span
                  className="line-text"
                  style={{
                    width: parentId === '0' || !parentId ? menuWidth : Number(menuWidth - 25),
                  }}
                  title={name}>
                  {name}
                </span>
              </Tooltip>
              <span style={{ width: 20 }}>{id === currentHoverTreeKey && opertion}</span>
            </div>
          }
        />
      );
    });
  };
  // 树状图三点里的按钮
  const getOperationList = (node: any) => {
    let operationList = [];
    operationList.push({
      key: 'modification',
      label: (
        <a
          onClick={e => {
            sethreePoint({ title: '确定修改吗？', key: 'modification' });
            e.preventDefault();
          }}>
          修改
        </a>
      ),
    });
    operationList.push({
      key: 'delete',
      label: (
        <a
          onClick={e => {
            sethreePoint({
              title: node.parentId ? '确定删除此场景吗？' : '确定删除此旅程吗？',
              description: node.parentId ? '删除时，将同时删除该场景下的策略' : '删除时，将同时删除该旅程下的场景和策略',
              key: 'delete',
            });
            e.stopPropagation();
          }}>
          删除
        </a>
      ),
    });
    return operationList;
  };
  // 表格change事件
  const handleTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }
    const store = {
      ...searchParam,
    };
    setSearchParam(store);
    getStrategydata({ pageSize: pagination.pageSize, pageNum: pagination.current} , store);
  };

  useEffect(() => {
    //获取旅程
    console.log(323, location);
    if (!location.state) {
      scnId = null;
      travelId = null;
    }
    getJourneySceneData();

    return () => {
      // scnId = null;
      // travelId = null;
    };
  }, []);
  const [draggable, setDraggable] = useState(true);
  //拖动
  const handleTreeDrop = (e: any) => {
    console.log('handleTreeDrop', e);
    loop(treeData, e.dragNode.key, '', (item, index, arr, parentKey) => {
      if (parentKey.toString() === e.node.key.toString()) {
        loop(treeData, parentKey, '', item2 => {
          item2.sceneLists = item2.sceneLists || [];
          // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
          item2.sceneLists.splice(index, 1);
          item2.sceneLists.unshift(item);
          let updateInfos: any[] = [];
          item2.sceneLists.map((item: any, index: number) => {
            updateInfos.push({
              id: item.id,
              sortIndex: index,
            });
          });
          const param = {
            parentId: item2.id,
            updateInfos,
          };
          updateSortIndex(updateInfos);
        });
      } else {
        loop(treeData, e.dragNode.key, '', (item1, index1, arr1, parentKey1) => {
          if (parentKey === parentKey1) {
            if (item1.floor === 0) {
              console.log(index1);
              console.log(e.dropPosition);

              if (e.dropPosition === -1) {
                treeData.splice(index1, 1);
                treeData.splice(0, 0, item1);
              } else if (index1 > e.dropPosition) {
                treeData.splice(index1, 1);
                treeData.splice(e.dropPosition, 0, item1);
              } else if (index1 < e.dropPosition) {
                treeData.splice(e.dropPosition, 0, item1);
                treeData.splice(index1, 1);
              }
              let updateInfos: any[] = [];
              treeData.map((item: any, index: number) => {
                updateInfos.push({
                  id: item.id,
                  sortIndex: index,
                });
              });
              const param = {
                type: 1,
                updateInfos,
              };
              updateSortIndex(updateInfos);
              // console.log(555, param);
            } else {
              loop(treeData, parentKey, '', item2 => {
                item2.sceneLists = item2.sceneLists || [];
                if (index1 > e.dropPosition) {
                  item2.sceneLists.splice(index1, 1);
                  item2.sceneLists.splice(e.dropPosition, 0, item1);
                } else if (index1 < e.dropPosition) {
                  item2.sceneLists.splice(e.dropPosition, 0, item1);
                  item2.sceneLists.splice(index1, 1);
                }

                let updateInfos: any[] = [];
                item2.sceneLists.map((item: any, index: number) => {
                  updateInfos.push({
                    id: item.id,
                    sortIndex: index,
                  });
                });
                const param = {
                  type: item.floor + 1,
                  updateInfos,
                };
                console.log(666, param);
                updateSortIndex(updateInfos);
              });
            }
          }
        });
      }
    });
    setTreeData(treeData);
  };

  const loop = (data: any[], key: string, parentKey: string, callback: (node: any, i: number, data: any[], parentKey: string) => void) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id.toString() === key.toString()) {
        return callback(data[i], i, data, parentKey);
      }
      if (data[i].sceneLists) {
        loop(data[i].sceneLists!, key, data[i].id, callback);
      }
    }
  };
  return (
    <div className={style['container1']}>
      <div className={style.headBox}>
        <div className={style.titleBox}>
          <ModuleTitle mark="large" title="策略列表" />
        </div>
      </div>
      <div className={style['container2']}>
        {/* 侧边伸缩栏 */}
        <motion.div className={style['lz_sub-menu']} animate={{ width: isArrow ? 200 : 0 }} transition={{ duration: 0.3 }}>
          {isShow && (
            <motion.div
              id="menu"
              style={{ padding: '24px 16px 0', width: 200 }}
              animate={{ width: isArrow ? 200 : 0 }}
              transition={{ duration: 0.3 }}>
              <Button type="default" icon={<PlusOutlined />} style={{ width: '100%' }} onClick={() => changeAddJourneyOpen(true)}>
                新建旅程
              </Button>
              <Button
                type="primary"
                disabled={treeData.length === 0}
                icon={<PlusOutlined />}
                style={{ width: '100%', marginTop: 8 }}
                onClick={() => changeAddSenceOpen(true)}>
                新建场景
              </Button>
              <Search placeholder="请输入名称" onSearch={onSearch} style={{ marginTop: 16, width: 168 }} />
              <div className={style['tree-container']} ref={treeContainerRef}>
                {treeData.length > 0 ? (
                  <Tree
                    draggable={draggable}
                    defaultExpandAll={true}
                    style={{ width: '164px', marginTop: '16px' }}
                    selectedKeys={selectedKeys}
                    onDrop={handleTreeDrop}
                    onSelect={onSelect}>
                    {/*    fieldNames={{ title: 'name', key: 'id', children: 'sceneLists' }} */}
                    {renderTreeNodes(treeData)}
                  </Tree>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: 80 }} />
                )}
              </div>
            </motion.div>
          )}
          <div className="lz_sub-menu-arrow click-element" onClick={handleArrowClick}>
            <LeftOutlined
              style={{
                fontSize: 'var(--text-font2)',
                color: 'var(--text-font-color4)',
                transform: `rotate(${isArrow ? 0 : 180}deg)`,
                transition: 'transform 0.3s ease',
              }}
            />
          </div>
        </motion.div>
        <div className={style.contentBox}>
          {listDataShow ? (
            <>
              {/* 顶层 */}
              <div className={style.contentTop}>
                {list.map((item: any) => (
                  <div className={style.contentItem}>
                    <div className={style.icon} style={{ border: item.borderColor, background: item.backgroundColor }}>
                      <YLIcon type={item.icon} color={item.iconColor} size={24}></YLIcon>
                      {/* <img src={item.icon} className={style.iconImg} alt="" /> */}
                    </div>
                    <div className={style.textArea}>
                      <span className={style.text1}>{item.title}</span>
                      <span className={style.text2}>{getNumberWithComma(item.num)}</span>
                    </div>
                  </div>
                ))}
              </div>
              {/* 搜索蹭 */}
              <div className="flexEnd">
                <div className={style.buttonBox}>
                  {hasSelected ? (
                    <div>
                      已选择 <span style={{ color: 'var(--text-font-color7)' }}>{selectedRowKeys.length}</span> 项
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchIconInput
                      clear={true}
                      width="310px"
                      placeholder="请输入策略名称"
                      onSearch={(e: string) => {
                        const store = {
                          ...searchParam,
                          name: e,
                        };
                        setSearchParam(store);
                        getStrategydata({ pageSize: 10, pageNum: 1 }, store);
                      }}></SearchIconInput>
                    <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
                    <span
                      style={{
                        width: '0',
                        height: '16px',
                        border: 'var(--border3)',
                        marginRight: 16,
                      }}></span>
                    <Popconfirm
                      placement="right"
                      title={confirmText}
                      okText="确定"
                      cancelText="取消"
                      onConfirm={e => handleConfirmClickPop(e)}
                      onCancel={e => handleCancelClickPop(e)}
                      open={changeStopAll}
                      onPopupClick={e => {
                        e.stopPropagation();
                      }}>
                      <Dropdown menu={menuProps} disabled={!hasSelected}>
                        <Button style={{ marginRight: '16px' }}>
                          <Space>
                            批量操作
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </Popconfirm>
                    <Button
                      type="primary"
                      onClick={() => {
                        // '' 创建  0, "草稿  1, "待启动"  2, "运行中"  3, "已停止"  4, "已删除"
                        navigate('/MA/MarketPlan/MAList/strategy', {
                          state: { graphStatus: '', fileName: '', arr: [travelId, scnId] },
                        });
                      }}>
                      新建策略
                    </Button>
                  </div>
                </div>
              </div>
              <FilterView ref={filterViewRef} searchList={searchList} finish={filterFinish} getHeight={getHeight}></FilterView>
              {/* 表单 */}
              <Table
                rowSelection={rowSelection}
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 'max-content', y: scrollY }}
                pagination={{
                  current: pageQuery.pageNum,
                  pageSize: pageQuery.pageSize,
                  total: pageQuery.total,
                  showTotal: total => `共${total}条`,
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
              />
            </>
          ) : (
            <div>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      </div>
      {contextHolder}
      <AddJourney ref={addJourneyRef} open={addJourneyOpen} journeyData={journeyData} drawer={changeAddJourneyOpen}></AddJourney>
      <AddSence ref={addSenceRef} open={addSenceOpen} senceData={senceData} drawer={changeAddSenceOpen}></AddSence>
      <TransferPacket ref={transferPacketRef} open={transferPacketOpen} drawer={changeTransferPacketOpen}></TransferPacket>
    </div>
  );
};
export default MAList;
