import { AxiosPromise } from 'axios';
import request from '../../../api/network';
import {
  AutoConfigQuery, CustomConfigQuery
} from './types';

// 指标名和指标ID映射关系
export function getMetricApi(): AxiosPromise<any> {
    return request({
        url: '/bi/metric/all',
        method: 'get',
    });
}

// 通用图表查询接口

export function getGeneralChat(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/base',
        method: 'post',
        data: param,
    });
}

// 人群细分-获取区间最大值最小值
export function getMinAndMax(query: AutoConfigQuery): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/groupConfig/getMinAndMax',
        method: 'post',
        data: query,
    });
}

// 人群细分-区间自动分组设计-查询最新保存过的分组
export function getNewGroup(query: AutoConfigQuery): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/groupConfig/getGroupConfig',
        method: 'post',
        data: query,
    });
}

// 人群细分-区间自动分组设计-查询自动分组配置
export function getAutoConfig(query: AutoConfigQuery): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/groupConfig/getAutoConfig',
        method: 'post',
        data: query,
    });
}

// 人群细分-区间自动分组设计-查询自定义分组配置
export function getCustomConfig(query: {type: string}): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/groupConfig/getCustomConfig',
        method: 'get',
        params: query
    });
}

// 人群细分-区间自动分组设计-新增自动分组配置
export function addAutoConfig(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/groupConfig/insertAuto',
        method: 'post',
        data: param,
    });
}

// 人群细分-区间自动分组设计-新增自定义分组配置
export function addCustomConfig(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/groupConfig/insertCustom',
        method: 'post',
        data: param,
    });
}

// 客质拆分饼图查询接口

export function getCrowdSegmentation(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/custom/getCustomPieResult',
        method: 'post',
        data: param,
    });
}

// 商品销售top10分布

export function getTopTenList(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/custom/getTopTenList',
        method: 'post',
        data: param,
    });
}

// 商品销售帕累托分析

export function getTopTenTradeList(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/custom/getTopTenTradeList',
        method: 'post',
        data: param,
    });
}

// 品类销售top7

export function getCategoryTop(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/custom/getCategoryTop',
        method: 'post',
        data: param,
    });
}

// 销售明细自定义查询

export function getSalesDetails(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/custom/getSalesDetails',
        method: 'post',
        data: param,
    });
}

// 品类下拉项

export function getCategoryList(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/system/sales/category',
        method: 'get',
    });
}


// 销售明细自定义查询-保存所选自定义项
export function addCustomForSalesDetails(param:any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/goodsSales/addCustom',
        method: 'post',
        data: param,
    });
}

// 销售明细自定义查询-查询保存所选自定义项
export function getCustomForSalesDetails(query: {type: string}): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/goodsSales/queryCustom',
        method: 'get',
        params: query
    });
}

// 下载客质拆分excel
export function downLoadCustomerSplit(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/export/customerSplit',
        method: 'post',
        data: query
    });
}

// 导出总览
export function downLoadOverview(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/export/overviewExport',
        method: 'post',
        data: query
    });
}

// 导出客户类型excel
export function downLoadCustomerType(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/export/customerTypeExport',
        method: 'post',
        data: query
    });
}

// 导出会员四象限
export function downLoadMemberFour(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/export/memberFourExport',
        method: 'post',
        data: query
    });
}

// 导出老客回购
export function downLoadOldCustomer(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/export/oldCustomerExport',
        method: 'post',
        data: query
    });
}

// 导出销售明细excel
export function downSalesDetails(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/board/export/salesDetails',
        method: 'post',
        data: query
    });
}
