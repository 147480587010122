import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useRef, useImperativeHandle } from 'react';
import styles from './index.module.scss';
import { Button, Input, message, Space, Popover, Tabs, Dropdown, Tooltip } from 'antd';
import { createShortLink } from '../../../../../../api/graph/graph';
import busEvent from '@/utils/bus';

// #region初始化图形
const ShortMesLink = React.forwardRef((
  props: 
  { 
    openShortMes: Function; 
    accountId: any; 
    type?: string;
    top?: any, 
    left?: any
  }, ref) => {
  const { openShortMes = () => { }, accountId ,top ,left } = props;
  const [linkMes, setLinkMes] = useState<any>({});
  // useEffect(() => {

  // }, []);
  const closeShortMes = () => {
    if (!linkMes.longLink) {
      message.error('请输入要转换的长链接');
      return;
    }
    if (!linkMes.resultLink) {
      message.error('请输入转换的链接结果');
      return;
    }
    openShortMes(false, 2, linkMes);
  };
  //转换为短链接
  const resultShortLink = async (accountId2?: any) => {
    const param = {
      accountId: accountId2 ?? accountId,
      longLink: linkMes.longLink,
    };
    if (!linkMes.longLink) {
      message.error('请输入要转换的长链接');
      return;
    }
    if (!param.accountId) {
      message.error('请先选择短信通道');
      return;
    }
    const res = await createShortLink(param);
    console.log('==========转换为短链接,res', res);
    if (res.code === 200) {
      setLinkMes({ ...linkMes, resultLink: ` ${res.data} ` });
    } else if (res.code == 500) {
      // 短信模板时的逻辑
      if (res.msg.indexOf('通道异常') !== -1 && props.type == 'template') {
        // 隐藏错误提示
        message.destroy()
        busEvent.emit('linkError');
        return
      }
      message.error(res.msg);
    }
  };
  useImperativeHandle(ref, () => ({
    resultShortLink,
  }));
  return (
    <div className={styles.shortMesLinkMain} style={{ top: props.top? props.top : '', left: props.left? props.left : '' }}>
      <div>
        <Input
          placeholder="请输入要转换的长链接"
          autoComplete="off"
          value={linkMes.longLink}
          onChange={e => {
            setLinkMes({ ...linkMes, longLink: e.target.value });
          }}
          suffix={
            <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => { resultShortLink() }}>
              转化成短链接
            </Button>
          }
        />
      </div>
      <div className={styles.shortMesMargin}>
        <Input placeholder="转换的链接结果" disabled value={linkMes.resultLink} autoComplete="off" />
      </div>
      <div className={`${styles.shortMesButton} ${styles.shortMesMargin20}`}>
        <Button style={{ marginRight: '10px' }} onClick={() => openShortMes(false, 1)}>
          取消
        </Button>
        <Button type="primary" onClick={closeShortMes}>
          确定
        </Button>
      </div>
    </div>
  );
})
export default ShortMesLink;
