import React, { useState, useImperativeHandle, useRef, useEffect } from 'react';
import { Button } from 'antd';

import style from './messageTextArea.module.scss';
import YLIcon from '@/component/Icon/YLIcon';
import { Editor, EditorState, convertToRaw, RichUtils, Modifier, ContentState, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';

let messageRange: any;
let textAreaText: string = '';
const MessageTextArea = React.forwardRef(
  (
    props: {
      style?: object;
      messageSign: string; //短信签名
      unsubscribe: string; //退订文字
      disabled: boolean; //禁用
      messageModel: any; //短信模板
      onChangeText: Function; //改变字体
      value: string;
      type: string;
      openVariableMes: Function;
      openShortMes: Function;
      graphStatus: any;
      useLinkDisable?: boolean;
    },
    ref
  ) => {
    const { onChangeText } = props;
    const textAreaRef: any = useRef(null);
    const [initValue, setInitValue] = useState('');
    const [textIndent, setTextIndent] = useState('70px'); //短信内容的首行缩进
    const [clickTag, setClickTag] = useState(''); // 点击标签添加的链接

    useEffect(() => {
      // document.addEventListener('selectionchange', (event) => {
      //     const sel = window.getSelection();
      //     const range = sel!.rangeCount > 0 ? sel!.getRangeAt(0) : null;
      //     //如果当前元素是我的富文本
      //     if (range && range.commonAncestorContainer.ownerDocument && range.commonAncestorContainer.ownerDocument.activeElement && range.commonAncestorContainer.ownerDocument.activeElement.id ===
      //         'mtx'
      //     ) {
      //         messageRange = range;
      //     }
      // });
    }, []);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // 点击按钮添加文案
    const handleClickTitle = (v: string) => {
      // setClickTag(v);
      console.log('=========点击按钮添加文案', v);

      onAddLinkClick(v);
    };
    const onAddLinkClick = (text: any) => {
      const selectionState = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('TAG', 'Segmented', { tag: 'tag' });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithLink = Modifier.insertText(
        contentStateWithEntity,
        selectionState,
        text, //插入的变量
        null,
        entityKey
      );
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithLink,
      });

      console.log(newEditorState);

      setEditorState(newEditorState);

      //获取最新的文本内容  给外部传过去
      const rawContentState = convertToRaw(newEditorState.getCurrentContent());
      const textNew = rawContentState.blocks.map((block: any) => block.text).join('\n');
      console.log(textNew, '3333333'); // 输出用户键入的文本内容
      //改变时调用外部方法
      onChangeText(textNew);
    };
    //外部重新赋值 清空或者选择短信模板
    const textAreaChange = (txt: string) => {
      // 清空当前的 EditorState
      const emptyContentState = ContentState.createFromText('');
      const emptyEditorState = EditorState.createWithContent(emptyContentState);
      setEditorState(emptyEditorState);

      if (!txt) {
        // 处理空字符串的情况
        return;
      }

      // 将外部传来的文本转换为 ContentState
      const regex = /(\${[^}]+}|{{[^}]+}}|[^${]+)/g;
      const segments = txt.match(regex)?.reverse();

      if (segments) {
        let contentState = emptyContentState;
        const selectionState = emptyEditorState.getSelection();

        segments.forEach((item: any) => {
          // 检查两边是否有空格  results 就是 输出前后都有空格的实体  将实体变为一整块可以删除
          const regexNew = /\s(\S+)\s/g;
          let match;
          let matches = [];
          const results: string[] = [];

          while ((match = regexNew.exec(item)) !== null) {
            results.push(match[1]);
          }
          if (results.length > 0) {
            const regexNew1 = /(\S+|\s+\S+\s+)/g;
            matches = item.match(regexNew1).reverse();
          }
          if (item.indexOf('${') !== -1) {
            // 创建实体
            const contentStateWithEntity = contentState.createEntity('TAG', 'SEGMENTED', { tag: 'tag' });

            // 获取实体 ID
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

            // 插入文本
            const contentStateWithLink = Modifier.insertText(contentStateWithEntity, selectionState, item, null, entityKey);
            contentState = contentStateWithLink;
          } else if (matches.length > 0) {
            matches.forEach((itm: any) => {
              if (itm.indexOf(' ') !== -1) {
                // 创建实体
                const contentStateWithEntity = contentState.createEntity('TAG', 'SEGMENTED', { tag: 'tag' });
    
                // 获取实体 ID
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
                // 插入文本
                const contentStateWithLink = Modifier.insertText(contentStateWithEntity, selectionState, itm, null, entityKey);
                contentState = contentStateWithLink;
              } else {
                // 直接插入普通文本
                const contentStateWithText = Modifier.insertText(contentState, selectionState, itm);
                contentState = contentStateWithText;
              }
            })
          }else {
            // 直接插入普通文本
            const contentStateWithText = Modifier.insertText(contentState, selectionState, item);
            contentState = contentStateWithText;
          }
        });

        // 更新 EditorState
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);

        // 获取最新的文本内容
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const textNew = rawContentState.blocks.map((block: any) => block.text).join('\n');
        console.log(textNew, '3333333'); // 输出用户键入的文本内容
      } else {
        // 处理没有匹配到任何段落的情况
        const contentState = ContentState.createFromText(txt);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    };

    useImperativeHandle(ref, () => ({
      handleClickTitle,
      textAreaChange,
    }));

    useEffect(() => {
      setTextIndent(`${props.messageSign.length * 15}px `);
    }, [props.messageSign]);

    const handlePastedText = (text: string, html: string, editorState: EditorState, event: React.ClipboardEvent | undefined) => {
      console.log("=============是粘贴板事件=============");
      // 阻止粘贴操作
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      return 'handled';
    };
    const handleBeforeInput = (chars: any) => {
      // 指定要禁止输入的字符
      // const forbiddenChars = ['@', '#', '$'];

      const regex = /^[\u4E00-\u9FA5A-Za-z0-9,.!?():;，。！？（）：；、]+$/

      if (!regex.test(chars)) {
        return 'handled'; // 阻止输入指定字符
      }

      return 'not-handled'; // 允许输入其他字符
    };

    const handleKeyCommand = (command: any) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
      return 'not-handled';
    };

    return (
      <div
        className={`${style['messageTextArea']} ${props.disabled && (props.type === 'custom' ? style['messageTextAreaBack'] : style['messageTextAreaBack2'])
          } `}>
        <div className={style['messageText_leftTopText']}>{props.messageSign}</div>
        <div
          className={`${style['messageText_rightBottomText']} ${props.disabled && (props.type === 'custom' ? '' : style['messageText_rightBottomText2'])
            }`}>
          {props.unsubscribe}
        </div>
        {props.type == 'custom' && (
          <div className={style['messageText_buttonBottom']}>
            <Button
              onClick={() => props.openVariableMes(true)}
              className={style.iconButton}
              icon={<YLIcon type="icon-bianliang" size={14} color="var(--text-font-color4)"></YLIcon>}
              type="text"
              disabled={props.graphStatus !== '0' && props.graphStatus}>
              插入变量
            </Button>
            <Button
              onClick={() => props.openShortMes(true)}
              className={style.iconButton}
              icon={<YLIcon type="icon-lianjie" size={14} color="var(--text-font-color4)"></YLIcon>}
              type="text"
              disabled={(props.graphStatus !== '0' && props.graphStatus) || props.useLinkDisable}>
              插入转换短链接
            </Button>
          </div>
        )}
        <div style={{ margin: '5px', userSelect: 'auto', textIndent: textIndent, maxHeight: '325px', overflowY: 'auto' }}>
          <Editor
            editorState={editorState}
            placeholder={'请输入短信内容'}
            onChange={(editorState: any) => {
              const contentState = editorState.getCurrentContent();
              const rawContentState = convertToRaw(contentState);
              const text = rawContentState.blocks.map((block: any) => block.text).join('\n');
              console.log(text, '3333333'); // 输出用户键入的文本内容
              //改变时调用外部方法
              onChangeText(text);
              setEditorState(editorState);
            }}
            handleBeforeInput={handleBeforeInput}
            handleKeyCommand={handleKeyCommand}
            handlePastedText={handlePastedText}
            readOnly={props.type !== 'custom'}
          />
        </div>
      </div>
    );
  }
);

export default MessageTextArea;
