/**
 * 总览每个小块的每个迷你折线图
 */
import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import WholeChart from "./../WholeChart";
import styles from "./index.module.scss";
import { CloseOutlined } from "@ant-design/icons";

function MiniLineChat({
  id,
  chartId,
  chartData,
  width,
  color,
  titleZh,
  searchForm,
  metricId,
  digit,
}: {
  id: string;
  chartId: string;
  chartData: any;
  width: number;
  color: string;
  titleZh: string;
  searchForm: object;
    metricId: string;
    digit: number;
}) {
  const colorMap = new Map([
    ["blue", "#1890FF"],
    ["lakeBlue", "#14C9C9"],
    ["purple", "#722ED1"],
    ["orange", "#FAAD14"],
    ["green", "#52C41A"],
  ]);
  const [wholeChartId, setWholeChartId] = useState("");
  const [data, setData] = useState<any>([]);
  // 特殊指标：迷你图特殊指标，超过7天则仅显示近7天
  const specialMetricIds = ["老客回购率", "新客二回购比例", "平均复购周期", "老客存量人数"];

  // 打开完整图表
  const openwholeChart = () => {
    setWholeChartId(id);
  };
  // 关闭完整图表
  const closewholeChart = () => {
    setWholeChartId("");
  };
  let option = {};
  useEffect(() => {
    const keys = chartData ? Object.keys(chartData) : [];
    const values = chartData ? Object.values(chartData) : [];
    // console.log(keys, '!!!!', values)
    const keys7 = chartData ? Object.keys(chartData).slice(0, 7) : [];
    const values7 = chartData ? Object.values(chartData).slice(0, 7) : [];
    option = {
      grid: {
        containLabel: false,
        left: "-7%",
        right: "-7%",
      },
      xAxis: {
        type: "category",
        data: specialMetricIds.includes(titleZh) ? keys7 : keys,
        show: false,
      },
      yAxis: {
        type: "value",
        show: false,
      },
      series: [
        {
          data: specialMetricIds.includes(titleZh) ? values7 : values,
          type: "line",
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: colorMap.get(color) || "#1890FF",
          },
          areaStyle: {
            opacity: 0.2,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: colorMap.get(color) || "#1890FF",
              },
              {
                offset: 1,
                color: "#fff",
              },
            ]),
          },
        },
      ],
    };
    // setData(chartData);
  }, [chartData]);

  useEffect(() => {
    let chartDom = document.getElementById(chartId);
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
    // JSON.stringify(option), width
  }, [chartData, width]);
  return (
    <div>
      <div
        id={chartId}
        style={{ width, height: "80px" }}
        onClick={openwholeChart}
      />
      {wholeChartId !== "" && (
        //  onClick={closewholeChart}
        <div className={styles.wholeChartMask}>
          <div className={styles.wholeChart}>
            <span onClick={closewholeChart} className={styles.closeBtn}>
              <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
            </span>
            <WholeChart chartId={id} searchForm={searchForm} metricId={metricId} digit={digit} titleZh={titleZh} />
          </div>
        </div>
      )}
    </div>
  );
}

export default MiniLineChat;
