/**
 * 任务列表-总览/人群细分/商品销售追踪
 */
import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import type { TableProps } from "antd";
import {
  Drawer,
  Button,
  Table,
  Tag,
  Select,
  Space,
  Dropdown,
  Tooltip,
} from "antd";

function TaskList(props: any) {
  const { taskListVisible, from , closeDrawer} = props;
  const [taskTypeOptions, setTaskTypeOptions] = useState([
    {
      label: "总览",
      value: "0",
    },
    {
      label: "人群细分",
      value: "1",
    },
    {
      label: "商品销售追踪",
      value: "2",
    },
  ]);
  const [taskTypeChecked, setTaskTypeChecked] = useState("0");
  const [tableList, setTableList] = useState<any[]>([]);

  useEffect(() => {
    setTaskTypeChecked(from);
  }, [props.from])
  const handleResult = async (record?: any) => { };
  const handleTableChange = (pagination: any, _: any) => {
    setTableInfo({
      ...tableInfo,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    });
  };
    const defaultTableInfo = {
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
    const [tableInfo, setTableInfo] = useState(defaultTableInfo);

    interface DataType {
      planName: string;
      createTime: string;
      memberCardName: string;
      planStatus: string;
    }

    const columns: TableProps<DataType>["columns"] = [
      {
        title: "模块",
        dataIndex: "todo",
        key: "todo",
        width: "300px",
      },
      {
        title: "日期范围",
        dataIndex: "todo",
        key: "todo",
        width: "300px",
      },
      {
        title: "创建时间",
        dataIndex: "todo",
        key: "todo",
        width: "300px",
      },
      {
        title: "平台",
        dataIndex: "todo",
        key: "todo",
        width: "300px",
      },
      {
        title: "店铺",
        dataIndex: "todo",
        key: "todo",
        width: "300px",
      },
      {
        title: "计算完成时间",
        dataIndex: "todo",
        key: "todo",
        width: "300px",
      },
      {
        title: "状态",
        dataIndex: "planStatus",
        key: "planStatus",
        width: "200px",
        render: (_, record) => (
          <>
            {record.planStatus === "生成成功" && (
              <Tag style={{ width: 52, textAlign: "center" }} color="success">
                生成成功
              </Tag>
            )}
            {record.planStatus === "生成失败" && (
              <Tag style={{ width: 52, textAlign: "center" }} color="error">
                生成失败
              </Tag>
            )}
            {record.planStatus === "进行中" && (
              <Tag style={{ width: 52, textAlign: "center" }} color="processing">
                进行中
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "操作",
        key: "action",
        width: "168px",
        render: (_: any, record: any) => (
          <Space>
            <Dropdown
              menu={{ items: getTableOperationButtonList(record) }}
              trigger={["click"]}
            ></Dropdown>
          </Space>
        ),
      },
    ];
    // 操作列
    const getTableOperationButtonList = (item: any) => {
      const store = [];
      store.push({
        key: "detail",
        label: (
          <a
            style={{ color: "var( --text-font-color9)" }}
            onClick={() => {
              // handelUpdata(item)
            }}
          >
            查看
          </a>
        ),
      });

      return store;
    };

    return (
      <div>
        <Drawer
          width={960}
          title="查询任务列表"
          onClose={() => {
            closeDrawer();
          }}
          open={taskListVisible}
        >
          <div>
            <Select
              options={taskTypeOptions}
              value={taskTypeChecked}
              onChange={(v) => {
                setTaskTypeChecked(v);
              }}
              style={{ width: 396, marginBottom: 24 }}
            />
            <Table
              columns={columns}
              dataSource={tableList}
              pagination={{
                total: tableInfo.total,
                pageSize: tableInfo.pageSize,
                current: tableInfo.pageNum,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total) => `共 ${total} 条`,
                // defaultCurrent: 1
              }}
              onChange={handleTableChange}
            />
          </div>
        </Drawer>

      </div>
    );
  }

export default TaskList;