import { proxy } from "valtio";
import { MenuProps } from "antd";
import { LzMenu } from "../Types/LzMenu";
import { createSubMenu } from "../utils/menuTool";
import { UserInfo, UserVO, DetailVO } from "../api/auth/types";
import { getUserClickApi } from '../api/home/home'
import _ from 'lodash'
import routeList from "@/router/routerMap";
import bus from "@/utils/bus";

type MenuItem = Required<MenuProps>['items'][number];


const initalState = {
    menu: [] as LzMenu[],
    currentMenuIndex: 0,
    subMenu: [] as MenuItem[],
    selectedKeys: [] as string[],
    defaultOpenKeys: [] as string[],
    currentPath: '',
    isArrow: true,
    labelId: 0,
    checkLabelType: '1',//1全部标签 2我创建的
    token: '',
    client_id: '',

    permissions: [] as string[],
    roles: [] as string[],
    user: {} as UserVO,
    detail: {} as DetailVO,
    groupDetail: {} as any,

    NOT_NEED_LOAD_URL: [
        '/system/user/list',
        '/auth/codeByUserName',
        '/label/label/getLabelUserStat',
        '/label/label/labelStatusManage',
        '/label/label/queryLabelInfoDetail',
        '/label/label/getLabelUserStat',
        '/system/home/click',
        '/chat/chat/conversation',
        '/chat/chat/interpretation',
        '/system/home/click',
        '/cdpdata/analysis/report/repeatName',
    ],
    parentMenuId: 0 as any,
    childMenuId: 0 as any,
    styleStoreData: {} as any,
    // tableScrollY: '' as any

};
let allMenu: any = [];
const extractKeys = (data: any) => {
    data.forEach((item: any) => {
        allMenu.push({
            key: item.key,
            menuId: item.menuId
        });
        if (item.children) {
            extractKeys(item.children);
        }
    });
}

export const changeCurrentMenuIndex = (index: number, key?: string) => {
    state.currentMenuIndex = index
    if (state.menu[state.currentMenuIndex]) {
        document.title = `${state.menu[state.currentMenuIndex].label}--${state.styleStoreData.groundTitle}`
    } else {
        document.title = `${state.styleStoreData.groundTitle?state.styleStoreData.groundTitle:''}`
    }
    bus.emit('menuSwitch', Boolean(state.menu[state.currentMenuIndex] && state.menu[state.currentMenuIndex].children))

    if (state.menu.length > 0) {
        state.subMenu = createSubMenu(state.menu[state.currentMenuIndex].children || [])
        state.defaultOpenKeys = state.subMenu.map(obj => obj ? obj.key?.toString() : []) as string[]
        allMenu = []
        extractKeys(state.menu)
        // 埋点参数 ———— menuId: number, level: number, crowdId: number, labelId: number, parentId: number, router: any
        getUserClickApi(
            state.menu[index].menuId, 1, undefined, undefined, state.menu[index].menuId, undefined
        )

        const currentIndex = allMenu.findIndex((it: any) => it.key === key)

        if (key) {

            if (currentIndex >= 0) {
                state.parentMenuId = state.menu[index].menuId
                if (state.menu[index].menuId !== 1688) { // 1688--首页没有子级菜单，只用调一次埋点接口
                    // const menuIndex = allMenu.findIndex((it: any) => it.key === key)
                    getUserClickApi(
                        allMenu[currentIndex].menuId, 3, undefined, undefined, state.menu[index].menuId, undefined
                    )
                    state.childMenuId = allMenu[currentIndex].menuId
                }
            }

            // 解决同一个菜单下使用路由跳转到该菜单下的另一页面，左侧菜单不高亮问题
            // const levelIndex = allMenu.findIndex((it: any) => key.indexOf(it.key) > -1)
            const t: any[] = _.filter(routeList, function (o: any) {
                return o.path === key
            })
            let a = key
            if (t[0].parentKey) {
                a = t[0].parentKey
            }
            state.selectedKeys = [a, `${a.split('/')[2]}`]
        }
    }
}

export const setMenu = (menu: LzMenu[]) => {
    state.menu = menu
    state.subMenu = menu.length < 1 ? [] : createSubMenu(menu[state.currentMenuIndex].children || [])
    console.log(state.subMenu);
}

export const setSelectkeys = (arr: string[]) => {
    state.selectedKeys = arr
}


export const setOpenKeys = (arr: string[]) => {
    state.defaultOpenKeys = arr
    console.log(state.defaultOpenKeys)
}

export const setCurrentPath = (s: string) => {
    state.currentPath = s
}

export const setArrow = (arrow: boolean) => {
    state.isArrow = arrow
}

export const setUserToken = (token: string, client_Id: string) => {
    state.token = token
    state.client_id = client_Id
    localStorage.setItem('userToken', token)
}

export const setUserInfo = (userInfo: UserInfo) => {
    state.permissions = userInfo.permissions
    state.roles = userInfo.roles
    state.user = userInfo.user
}

export const setUserDetail = (userDetail: any) => {
    state.detail = userDetail
}

export const setStateLabelId = (labelId: number) => {
    state.labelId = labelId
}

export const setStyleStoreData = (data: any) => {
    state.styleStoreData = data
    document.title = `${state.styleStoreData.groundTitle}`
}

export const setCheckLabelType = (type: any) => {
    state.checkLabelType = type
}

export const state = proxy({ ...initalState });


// 计算当前列表高度

/**
* 获取第一个表格的可视化高度
* @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为74)
* @param {*} id 当前页面中有多个table时需要制定table的id
*/
export const getTableScroll = (extraHeight?: number, id?: any) => {
    if (typeof extraHeight == "undefined") {
        //  默认底部分页64 + 边距10
        // 64+24=88
        // 118-24-24 == 70
        //   extraHeight = 94
        // extraHeight = 70
        extraHeight = 90
    }
    let tHeader = null
    if (id) {
        tHeader = document.getElementById(id) ? document.getElementById(id)?.getElementsByClassName("ant-table-thead")[0] : null
    } else {
        tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
        tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    //窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    // console.log(tHeaderBottom, extraHeight, 'tHeaderBottom + extraHeight');

    let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
    // localStorage.setItem('tableScrollY', height)
    // set TableScrollY(height)
    // console.log(height, 'height3333333333333333333333333333');
    // state.tableScrollY = height

    return height
}

