/**
 * 商品销售追踪-品类-top7品类销售趋势
 */
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip, Select } from "antd";
import * as echarts from "echarts";
import { getCategoryTop } from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
function Top7Chart(props: any) {
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const [chartData, setChartData] = useState<any>([]);
  const [legendData, setLegendData] = useState<any>([]);
  const { searchForm } = props;
  const top7Options = [
    { label: "销售额", value: "sales" },
    { label: "购买件数", value: "buyNum" },
    { label: "购买人数", value: "ordNum" },
    { label: "购买订单数", value: "skuNum" },
  ];
  const colors = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#FFADD2",
    "#948DED",
  ];
  const [checkedtop7Type, setCheckedtop7Type] = useState("sales");

  const getTop7Data = async () => {
    const params = {
      // 选择少于7天/周/月/年，但参数要传近7天/周/月/年，参数用paramStartDate和paramEndDate
      startDate: searchForm?.paramStartDate,
      endDate: searchForm?.paramEndDate,
      dateType: searchForm?.dateType,
       channels: searchForm?.platformType?.includes("all")
          ? ["all"]
          : searchForm?.platformType?.split(","),
        shopIds: searchForm?.shopId?.includes("all")
          ? ["all"]
          : searchForm?.shopId?.split(","),
      goodType: "CATEGORY",
      indexId: checkedtop7Type,
    };
    const res = await getCategoryTop(params);
    if (res.code === 200) {
      setChartData(res.data);
      const xArr: { name: any; icon: string; itemStyle: object }[] = [];
      if (res?.data?.[0]?.category) {
        const typesArr = Object.keys(res?.data?.[0]?.category);
        typesArr.forEach((item, index) => {
          xArr.push({
            name: item,
            icon: "line",
            itemStyle: { color: colors[index] },
          });
        });
        setLegendData(xArr);
      }
    }
  };

  useEffect(() => {
    if (channelAllList.length > 0) {
      getTop7Data();
    }
  }, [searchForm, channelAllList, checkedtop7Type]);
  const getSeries = () => {
    let arrFinal: {
      name: string;
      type: string;
      showSymbol: boolean;
      barWidth: number;
      lineStyle: { color: string };
      tooltip: { valueFormatter: (value: string) => string };
      data: any;
      yAxisIndex: number;
    }[] = [];
    if (chartData[0]?.category) {
      const typeKeys = Object.keys(chartData[0]?.category);
      typeKeys.forEach((item, index) => {
        arrFinal.push({
          name: item,
          type: "line",
          showSymbol: false,
          barWidth: 30,
          lineStyle: {
            color: colors[index],
          },
          tooltip: {
            valueFormatter: function (value: string) {
              return value;
            },
          },
          data: chartData.map((i: any) => i.category[item]),
          yAxisIndex: 0,
        });
      });
    }
    return arrFinal;
  };
  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "axis",
      },

      legend: {
        left: 12,
        itemHeight: 10,
        itemWidth: 10,
        data: legendData,
        formatter: function (name: string) {
          return "{line|} " + name;
        },
        textStyle: {
          rich: {
            line: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: function (params: any) {
                return colors[params.dataIndex];
              },
            },
          },
        },
      },
      series: getSeries(),
      grid: {
        left: 24,
        bottom: 20,
        right: 24,
        containLabel: true,
      },

      dataZoom: [
        {
          handleSize: 20,
          moveHandleSize: 0,
          borderColor: "transparent", // 去掉边框颜色
          borderWidth: 0, // 去掉边框宽度
          backgroundColor: "#F2F3F5",
          bottom: 0,
          // 所选范围外的数据面积
          dataBackground: {
            // 边缘线条颜色
            lineStyle: {
              color: "#0487FF",
            },
            // 面积颜色
            areaStyle: {
              color: "#CEE0FF",
            },
          },
          selectedDataBackground: {
            lineStyle: {
              color: "#0487FF",
            },
            areaStyle: {
              color: "#0487FF",
            },
          },
          height: 14,
          moveHandleIcon: "diamond",
          handleStyle: {
            color: "rgba(41, 222, 50, 1)",
          },
          brushStyle: {
            color: "rgba(188, 42, 42, 1)",
          },
          handleIcon:
            "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAADySURBVDiNtZUxbgIxEABHLmngATQ8gI/wARTp+FCUjkcEmkQ0SU/apLrUJ4Ho0tDQ0EwKjoAsX5IT56ksrz1eWd41JFD76p26Uiv16IVjPbeq1/RTjlgY1KLe+F+qek9okvbUeQthzFztpTK9RXotD9fiogPpmdlZOrDhTu+fdH84jcuNLtbpWESlDgIwAUape394hs/Nafz6AYu3dCxiBEwCMP3zubRnGoBxBvE4AMMM4mH6UXdAAHYZvLsAlBnEZQCWGcTLXwvkcX0pgu2XvrynY6kCAUCdNRZoe4qfvM3VhGp5920zyrzbRh8dcPPX9A3bEkbAkjy82wAAAABJRU5ErkJggg==",
        },
      ],
      xAxis: [
        {
          type: "category",
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          data: chartData.map((item: any) => item.date),
          // data: ["Retention Existing", "Winback Existing", "Sleeping Existing"],
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          min: 0,
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
    };
    let chartDom = document.getElementById("Top7Chart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [chartData]);
  return (
    <div className={styles.normalBox} style={{ height: 600, width: "100%" }}>
      <div className={styles.normalHeader}>
        <div>
          品类销售趋势
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
               展示不同品类的销售趋势
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </div>
      <Select
        options={top7Options}
        style={{ width: 360, margin: "24px 0 24px 12px" }}
        value={checkedtop7Type}
        onChange={(e) => setCheckedtop7Type(e)}
      ></Select>
      <div
        id="Top7Chart"
        style={{
          width: "100%",
          height: "430px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
}

export default Top7Chart;
