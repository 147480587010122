import { Card, ConfigProvider, Input, Pagination, Modal, Button, Tag, Popover } from 'antd';
import type { PaginationProps } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import styles from './index.module.scss';
import { useEffect, useState } from 'react'
import { queryPageListApi, detailtApi, uploadTaskDownloadApi } from '@/api/taskUpload/task'
import { download } from '@/api/network'
import Iconfont from '@/component/Icon/index'
import { DownloadOutlined } from '@ant-design/icons';


const { Search } = Input;

const UploadTask = (props: {
  tabKey: string;
  downLoadCenterOpen: boolean
}) => {
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setTaskLocation(value);
    getUploadList({ pageSize: 10, pageNum: 1 }, value, 2);
  };
  const [uploadTask, setUploadTask] = useState({} as any)
  // 1:计算中；2：计算成功；3：计算失败
  const statusList = ['', '计算中', '成功', '失败']
  const colorList = ['', 'warning', 'success', 'error']
  const iconList = ['', 'HourglassOutlined', 'CheckCircleOutlined', 'StopOutlined']
    // 页码信息
    const [pageQuery, setPageQuery] = useState({
      pageSize: 10,
      pageNum: 1,
      total: 0,
    });

    const [taskLocation, setTaskLocation] = useState("");
    const [showModal, setShowModal] = useState(false)
    // 任务详情
    const [taskDetail, setTaskDetail] = useState({} as any)
  
    // 换页
    const handleTableChange: PaginationProps['onShowSizeChange'] = (current: any, pageSize: any) => {
      getUploadList({pageNum: current, pageSize: pageSize }, taskLocation, 2);
    };
    // 上传任务列表
    const getUploadList = async(page: {pageNum: number, pageSize: number}, taskLocation: any, type: number) => {
      const params = {
        ...page,
        taskName: taskLocation,
        type,
      }
      const res = await queryPageListApi(params)
      if (res) {
        setUploadTask(res.data)
        setPageQuery({
          pageSize: page.pageSize,
          pageNum: page.pageNum,
          total: res.data.total,
        });    
      }
    }
    // 任务详情
    const getDetail = async(id: number) => {
      const res = await detailtApi({
        id,
      })
      if (res) {
        setTaskDetail(res.data)
      }
    }
    // 下载错误数据
    const downLoadErrorData = async() => {
      const res = await download(`/resource/downloadCenter/${taskDetail.id}/download`)
    }
    const onCloseModal = () => {
      setShowModal(false)
    }
    const openModal = (id: number) => {
      // setShowModal(true)
      getDetail(id)
    }
    const cardTitle = (taskLocation: string, time: string, id: number) => {
      const content = (
        <>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>导入类型：</span>{taskDetail.uploadTaskType? (taskDetail.uploadTaskType=== 1? '标签': '人群') : '--'} </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务名称：</span>{taskDetail.taskName? taskDetail.taskName : '--'} </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>导入用户：</span>{taskDetail.createUser? taskDetail.createUser : '--'} </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务状态：</span> {taskDetail.taskState? <Tag icon={<Iconfont icon='HourglassOutlined' />} color={colorList[taskDetail.taskState]}>{statusList[taskDetail.taskState]}</Tag> : '--'} </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>上传条数：</span>成功{taskDetail.successNum? taskDetail.successNum : '--'}条，失败{taskDetail.failNum? taskDetail.failNum : '--'}条 </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务开始时间：</span>{taskDetail.taskBeginTime? taskDetail.taskBeginTime : '--'} </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务结束时间：</span>{taskDetail.taskEndTime? taskDetail.taskEndTime : '--'} </div>
        <div className={styles.line}></div>
        <div><Button icon={<DownloadOutlined />} disabled={taskDetail.taskState === 1 || (taskDetail.taskState === 2 && !taskDetail.failNum)} onClick={downLoadErrorData}>下载错误数据</Button> </div>
      </>
      )
      const popoverTitle = <div style={{color: 'transparent', position: 'absolute'}}>title</div>
      return (
        <ConfigProvider
          theme={{
            components: {
              Popover: {
                /* 这里是你的组件 token */
                titleMinWidth: 314
              },
            },
          }}
        >
          <Popover overlayInnerStyle={{padding: 16}} content={content} title={popoverTitle} trigger="click">
            <Button type='link' className={styles.btnText} onClick={() => openModal(id)}>{taskLocation}</Button>
          </Popover>
        </ConfigProvider>
      )

    }
    const statusTag = (state: number) => {
      
      return <Tag icon={<Iconfont icon={iconList[state]} />} color={colorList[state]}>{statusList[state]}</Tag>
    }

  useEffect(() => {
    if (props.downLoadCenterOpen && props.tabKey === '2') {
      getUploadList({pageNum: 1, pageSize: 10}, '', 2)
    }
  }, [props.tabKey, props.downLoadCenterOpen])
  return (
    <div>
      <div className={styles.searchBox}>
        <Search
          placeholder="请输入查询的任务名称"
          allowClear
          onSearch={onSearch}
          style={{ width: '100%' }}
        />
      </div>
      {uploadTask.rows &&
        <>
          <div>
            {
              uploadTask.rows.map((it: any) =>
                <ConfigProvider
                  theme={{
                    components: {
                      Card: {
                        extraColor: colorList[it.taskState]
                      },
                    },
                  }}
                >
                  <Card size="small" title={cardTitle(it.taskLocation, it.taskBeginTime, it.id)} extra={statusTag(it.taskState)} classNames={{header: styles.cardTitle, body: styles.cardBody}} style={{marginBottom: '24px'}}>
                    <div>导入类型：{it.uploadTaskType === 1? '标签': '人群'}</div>
                    <div>任务名称：{it.taskName}</div>
                    <div>任务开始时间：{it.taskBeginTime? it.taskBeginTime : '--'}</div>
                    <div>创建人：{it.createUser? it.createUser: '--'}</div>
                  </Card>
                </ConfigProvider>
              )
            }
          </div>
          {
            uploadTask.rows.length === 0 && <div className={styles.noData}>暂无数据</div>
          }
          {pageQuery.total > 0 && <Pagination
            className={styles.paginationSimple}
            simple
            current={pageQuery.pageNum}
            size="small"
            total={pageQuery.total}
            style={{ textAlign: 'end' }}
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleTableChange}
          />}
        </>
      }

      <Modal
      title=""
      open={false}
      // footer={(_, { OkBtn, CancelBtn }) => (
      //   <Button type='primary' disabled={taskDetail.taskState === 0 || taskDetail.taskState === 1} onClick={downLoadErrorData}>下载错误数据</Button> //计算中的不能下载
      // )}
      onCancel={onCloseModal}>
      <>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>导入类型：</span>{taskDetail.uploadTaskType? taskDetail.uploadTaskType : '--'} </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务名称：</span>{taskDetail.taskName? taskDetail.taskName : '--'} </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>导入用户：</span>{taskDetail.createUser? taskDetail.createUser : '--'} </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务状态：</span> {taskDetail.taskState? <Tag icon={<Iconfont icon='HourglassOutlined' />} color={colorList[taskDetail.taskState]}>{statusList[taskDetail.taskState]}</Tag> : '--'} </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>上传条数：</span>成功{taskDetail.successNum? taskDetail.successNum : '--'}条，失败{taskDetail.failNum? taskDetail.failNum : '--'}条 </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务开始时间：</span>{taskDetail.taskBeginTime? taskDetail.taskBeginTime : '--'} </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>任务结束时间：</span>{taskDetail.taskEndTime? taskDetail.taskEndTime : '--'} </div>
      <div className={styles.line}></div>
      <div><Button icon={<DownloadOutlined />} disabled={taskDetail.taskState === 0 || taskDetail.taskState === 1} onClick={downLoadErrorData}>下载错误数据</Button> </div>
    </>
     </Modal>
    </div>
    
  )
}
export default UploadTask