import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import { Button, Input, Form, Select, message, Tabs, Dropdown, Tooltip } from 'antd';

import styles from './index.module.scss';
import type { FormProps } from 'antd';
import { getUserFieldVarList } from '../../../../../../api/graph/graph';
// #初始化
const VariableMes = (props: { openVariableMes: Function , top?: any, left?: any }) => {
  const { openVariableMes = () => {} } = props;
  const [variableInfo] = Form.useForm();
  const [variableOptions, setVariableOptions] = useState([
    //变量列表 客户属性
    // { value: 1, label: '会员身份', showTitle: '${identity}'},
    // { value: 2, label: '会员等级', showTitle: '${level}' },
    // { value: 3, label: '当前积分值', showTitle: '${Integral value}' },
    // { value: 4, label: '当前成长值', showTitle: '${growth value}' },
    // { value: 5, label: '常用收货省份', showTitle: '${province}' },
    // { value: 6, label: '常用收货城市', showTitle: '${city}' },
  ] as any);
  useEffect(() => {
    getUserFieldVarListFunc();
  }, []);
  const getUserFieldVarListFunc = async () => {
    const res = await getUserFieldVarList({});
    if (res) {
      setVariableOptions(
        res.data.map(item => {
          return {
            label: item.name,
            value: item.id,
            field: item.field,
            showTitle: '${' + item.field + '',
          };
        })
      );
    }
  };
  const closeVariableMes = (type: number) => {
    if (!variableInfo.getFieldValue('variable')) {
      message.error('请输入变量');
      return;
    }
    if (!variableInfo.getFieldValue('def')) {
      message.error('请输入变量为空时显示');
      return;
    }
    let contentRule = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
    
    if (contentRule.test(variableInfo.getFieldValue('def'))) {
      message.error('变量为空时显示不能包含特殊字符');
      return;
    }
    //1为取消，2为确定
    openVariableMes(false, type, {
      field: variableOptions.filter((item: any) => item.value === variableInfo.getFieldValue('variable'))[0].field,
      variableText: variableOptions.filter((item: any) => item.value === variableInfo.getFieldValue('variable'))[0].showTitle,
      def: variableInfo.getFieldValue('def'),
    });
  };
  type FieldType = {
    variable?: string;
    def?: string;
  };

  const onFinish: FormProps<FieldType>['onFinish'] = values => {
    console.log('Success:', values);
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className={styles.variableMesMain} style={{ top: props.top? props.top : '', left: props.left? props.left : '' }}>
      <div>
        <Form form={variableInfo} layout="vertical">
          <Form.Item<FieldType> label="变量" name="variable" rules={[{ required: true, message: '请选择' }]}>
            <Select
              placeholder="请选择客户属性"
              options={variableOptions}
              onChange={e => {
                variableInfo.setFieldValue('variable', e);
              }}
            />
          </Form.Item>
          <Form.Item<FieldType> label="变量为空时显示" name="def" rules={[{ required: true, message: '请输入' }]}>
            <Input
              placeholder="请输入"
              autoComplete="off"
              showCount
              maxLength={10}
              onChange={e => {
                variableInfo.setFieldValue('def', e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </div>
      <div className={`${styles.variableMesButton} ${styles.variableMesMargin20}`}>
        <Button style={{ marginRight: '10px' }} onClick={() => openVariableMes(false, 1)}>
          取消
        </Button>
        <Button type="primary" onClick={() => closeVariableMes(2)}>
          确定
        </Button>
      </div>
    </div>
  );
};
export default VariableMes;
