/**
 * 人群细分-客质拆分-复购周期分布
 */
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import * as echarts from "echarts";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import SplitDrawer from "../SplitDrawer";
import { getCrowdSegmentation, getNewGroup } from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function CustomQualitySplitByBuyFrequency(props: any) {
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const [dataForShowThisTime, setDataForShowThisTime] = useState<any>([]);
  // linkage true联动 recencyValue:同级组件传递的点击饼图的部分的值 recencyName:同级组件传递的点击饼图的部分的名称
  const { linkage, recencyValue, linkageType, searchForm, setParamForDownLoad } = props;
  const [splitType, setSplitType] = useState("auto");
  const [splitAutoData, setSplitAutoData] = useState<any>({});
  const [splitCustomData, setSplitCustomData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const colors = ["#F0F5FF", "#D6E4FF", "#ADC6FF", "#85A5FF", "#597EF7", "#2F54EB", "#1D39C4", "#10239E", "#061178", "#001D66"]
  
  useEffect(() => {
    if(channelAllList. length > 0){
    if (linkage) {
      searchData(
        splitType,
        splitType === "auto" ? splitAutoData : splitCustomData
      );
    }
      }
  }, [recencyValue, channelAllList]);
  const [splitDialogVisible, setSplitDialogVisible] = useState(false);
  const option = {
        tooltip: {
      trigger: "item",
      itemWidth: 280,
      formatter: (params: any) => {
        return `
           <div style="width: 280px;">
<div style="display: flex; align-items: center; justify-content: space-between;">
             <div style="width:10px; height:10px; border-radius:50%; background-color: ${
               colors[params.dataIndex]
             };margin-right: 8px "></div>
           <div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;width: 270px;">
              <span>复购周期：</span>
              <div>
         <span>${params.name}</span>
          </div></div>
</div>
          <div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>人数：</span>
              <div>
         <span>${Number(params?.data.num).toLocaleString()}人</span>
          </div></div>
          <div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>人数占比：</span>
              <div>
         <span>${data[params?.dataIndex]?.percent}</span>
          </div></div>
                      ${
                        params?.data.yoy > 0
                          ? `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>人数占比同比：</span>
              <div>
             <div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:8px solid #F5222D;position:absolute; top:112px; left:200px;"></div>
         <span>${
           params?.data.yoy === "-"
             ? "-"
             : `${Number(params?.data.yoy).toLocaleString()}%`
         }</span>
          </div></div>`
                          : `<div style="display: flex; align-items: center; justify-content: space-between;height: 30px;line-height: 30px;margin-left: 18px">
              <span>购买人数占比同比</span>
              <div>
             <div style="width:0; height:0; border-left:6px solid transparent; border-right:6px solid transparent; border-top:8px solid #52C41A;position:absolute; top:112px; left:200px;"></div>
         <span>${
           params?.data.yoy === "-"
             ? "-"
             : `${Number(params?.data.yoy).toLocaleString()}%`
         }</span>
          </div></div>`
                      }
             </div>`;
      },
    },
    legend: {
      left: "left",
      icon: "circle",
      itemWidth: 8,
      itmHeight: 8,
    },
    grid: {
      left: 24,
      right: 24,
      bottom: 0,
      containLabel: true,
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: "50%",
        data,
        minAngle: 10,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          formatter: (params: any, index: number) => {
            return `{circle|} {label|${
              params.name
            }}\n{value|${getNumberWithComma(params.value, 0)}}{value|人}\n\n`;
          },
          height: 94,
          rich: {
            circle: {
              height: 8, // 圆圈的大小，可根据需要调整
              width: 8,
              padding: [0, 0], // 确保圆圈紧凑，无额外边距
              borderRadius: 50, // 确保形状为圆形
              backgroundColor: "inherit",
            },
            label: {
              color: "#595959",
              textAlign: "left",
              fontSize: 12,
              fontWeight: 400,
            },
            value: {
              color: "#8C8C8C",
              textAlign: "left",
              fontSize: 12,
              fontWeight: 400,
            },
          },
          padding: [0, -50],
        },
        labelLine: {
          show: true,
          length: 14, // 改变标示线的长度
          length2: 100,
          lineStyle: {
            color: "#DFDFDF", // 改变标示线的颜色
          },
        },
      },
    ],
  };
  useEffect(() => {
    let chartDom = document.getElementById("frequencyChart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
      myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [JSON.stringify(option)]);
  const openSplitDialog = () => {
    setSplitDialogVisible(true);
  };

  const onCloseSplitDialog = () => {
    setSplitDialogVisible(false);
  };

  const onConfirmDialog = (type: string, value: any) => {
    setSplitType(type);
    if (type === "auto") {
      setSplitAutoData(value);
    }
    if (type === "custom") {
      setSplitCustomData(value);
    }
    onCloseSplitDialog();
    message.success("修改成功");
    setParamForDownLoad(type, value);
    searchData(type, value);
  };

  const searchData = async (type: string, value: any) => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dataType: searchForm?.dataType,
      channels: searchForm?.platformType?.includes("all")
        ? ['all']
        : searchForm?.platformType?.split(","),
      shopIds:
        searchForm?.shopId?.includes("all")
        ? ['all']
        : searchForm?.shopId?.split(","),
      indexId: "fgzq",
      configType: type,
      pieIndexTypes: ["num", "rate", "yoy"],
      autoConfigDTO: null,
      customConfigList: null,
      linkType: linkageType ? linkageType : null,
      groupList: recencyValue ? recencyValue: null,
    };
    if (type === "auto") {
      setParamForDownLoad("auto", value);
      params.autoConfigDTO = value;
    }
    if (type === "custom") {
      setParamForDownLoad("custom", value);
      params.customConfigList = value;
    }
    // console.log(recencyValue, '接收的')
    let res = await getCrowdSegmentation(params);
    if (res?.code === 200) {
      if (res.data) {
        // 联动的情况，从获取到的返回值中拼接出来一个下载参数
        if (linkage) {
          // @ts-ignore
          const downLoadList = [];
          res?.data?.data?.forEach((item: any, index: number) => {
            downLoadList.push({
              ascCode: index.toString(),
              startNum: item.startNum,
              endNum: item.endNum,
              startSymbol: item.startSymbol,
              endSymbol: item.endSymbol,
              name: item.name,
              type: 'fgzq',
            });
          })
          // @ts-ignore
          setParamForDownLoad(type, downLoadList);
        }
        const arr: { value: string | number; name: string; yoy: any; percent: any; num: any; itemStyle: { color: string; }; }[] = [];
        res?.data?.data?.forEach((item: any, index: number) => {
          arr.push({
            value: item.num,
            name: type === "auto" ? `${item.name}天` : `${item.name}`,
            yoy: item.yoy,
            percent: item.rate === '-' ? '-' : Number(item.rate).toLocaleString() + "%",
            num: item.num,
            itemStyle: { color: colors[index] },
          });
        });
        setData(arr);
      }
    }
  };

  useEffect(() => {
    if (channelAllList.length > 0) {
      queryNewGrouping();
    }
  }, [searchForm, channelAllList]);

  useEffect(() => {
    if (channelAllList.length > 0 && !linkage) {
      queryNewGrouping();
    }
  }, [linkage])

  const queryNewGrouping = async () => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.split(","),
      shopIds: searchForm?.shopId?.split(","),
      indexId: 'fgzq',
    };
    // @ts-ignore
    let res = await getNewGroup(params);
    if (res?.code === 200) {
      let dto;
      if (res.data?.type === "auto") {
        dto = {
          min: res.data.autoVo.minNum,
          max: res.data.autoVo.maxNum,
          numberOfGroups: res.data.autoVo.groupNum
        }
      } else if (res.data?.type === "custom") {
        dto = res.data?.customList;
      } else {
        dto = { numberOfGroups: 5, min: null, max: null };
      }
      searchData(res.data?.type ? res.data?.type : 'auto', dto);
    }
  }
  return (
    <div className={styles.normalBox} style={{ height: 570 }}>
      <div className={styles.normalHeader}>
        <div>
          复购周期分布
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                本期有过购买行为的客户的复购平均周期分布
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
        <div>
          <SettingOutlined
            onClick={openSplitDialog}
            style={{
              color: "var(--text-font-color4)",
              fontSize: "var(--text-font5)",
              marginLeft: "6px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className={styles.normalContent}>
        <div
          id="frequencyChart"
          style={{
            width: "100%",
            height: "518px",
            backgroundColor: "#fff",
            paddingTop: "24px",
          }}
        />
      </div>
      {splitDialogVisible && (
        <SplitDrawer
          searchForm={searchForm}
          typeName={"复购周期"}
          splitDialogVisible={splitDialogVisible}
          onCloseSplitDialog={onCloseSplitDialog}
          onConfirmDialog={(type: string, value: any) => {
            onConfirmDialog(type, value);
          }}
          dataForShowThisTime={dataForShowThisTime}
          setDataForShowThisTime={(v: any) => {
            setDataForShowThisTime(v);
          }}
        />
      )}
    </div>
  );
}

export default CustomQualitySplitByBuyFrequency;
