import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';
import styles from './index.module.scss';
import { Button, Table, Select, Input, Form, GetProps, DatePicker, Alert, Space, Tooltip } from 'antd';
import type { TableProps } from 'antd';
import dayjs from 'dayjs';
import busEvent from '@/utils/bus';
import { QuestionCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import CheckGroup from '../settingTab/component/checkGroup';
import CreateGroup from '../settingTab/component/createGroup';
import {
  getNodeVisitsData,
  getSmsNodeVisitsData,
  getSmsNodeStatData,
  createGroup,
  addSmsNodeVisitorToCrowd,
  addNodeVisitorToCrowd,
} from '../../../../api/graph/graph';
import { calc } from 'antd/es/theme/internal';
type SearchProps = GetProps<typeof Input.Search>;
const { Search } = Input;
const { RangePicker } = DatePicker;
// #region初始化图形
const RunningTab = forwardRef(
  (
    props: {
      info: any; //节点信息
      type: number; // type 1 是 节点信息展示 2 是 短信详情数据
    },
    ref: any
  ) => {
    type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
    const { info, type } = props;
    const [nodeInfo, setNodeInfo] = useState<any>({});
    const [groupPopShow, setGroupPopShow] = useState(false); //选择分群弹窗
    const [chooseIndex, setChooseIndex] = useState(null as any); //选择的实际发送人数还是成功或者失败
    const [phoneOrId, setPhoneOrId] = useState(null as any); //搜索的oneid或者手机号
    const [groupCreatePopShow, setGroupCreatePopShow] = useState(false); //创建分群弹窗
    // 选中周期  今天 7天
    const [selectedCycle, setSelectedCycle] = useState('1');
    const [dateValue, setDateValue] = useState([dayjs().startOf('day'), dayjs()] as any);
    // 选择周期  今天  7天
    const selectCycle = [
      { label: '今天', value: '1' },
      { label: '最近7天', value: '2' },
      { label: '最近30天', value: '3' },
      { label: '最近90天', value: '4' },
      { label: '至今', value: '5' },
      { label: '自定义', value: '6' },
    ];
    const cycleDays = [
      { day: 0, value: '1' },
      { day: 7, value: '2' },
      { day: 30, value: '3' },
      { day: 90, value: '4' },
      { day: 0, value: '5' },
    ];
    //数据列表
    const [listData, setListData] = useState([] as any);
    const [listShow, setListShow] = useState(true);
    const [closeButton, setCloseButton] = useState(false);
    const [sendData, setSendData] = useState([
      {
        name: '实际发送人次',
        tooltip: '陆泽实际推送给渠道服务商的人次总数',
        num: 0,
        status: '2',
      },
      {
        name: '发送成功人次',
        tooltip: '渠道服务商返回的发送成功人次总数，代表服务商成功收到消息',
        num: 0,
        status: '0',
      },
      {
        name: '发送失败人次',
        tooltip: '渠道服务商返回的发送失败人次总数，代表服务商未成功收到消息',
        num: 0,
        status: '1',
      },
    ] as any);
    const alertText = (
      <>
        <InfoCircleFilled style={{ marginRight: '9px', color: 'var(--text-font-color7)' }} />
        统计的数值是人次，1个客户重复进入流程，在这里会有多条记录
      </>
    );
    const [crowd, setCrowd] = useState({
      crowdId: null,
      crowdName: null,
    });
    //禁止选择今天之后的日期
    const disabledDate: RangePickerProps['disabledDate'] = current => {
      return current && current > dayjs().endOf('day');
    };

    // 页码信息
    const [pageQuery, setPageQuery] = useState({
      pageSize: 10,
      pageNum: 1,
      total: 0,
    });
    //tabel 列表字段
    const columns: TableProps<any>['columns'] = [
      {
        title: 'OneID',
        dataIndex: 'oneId',
        key: 'oneId',
        width: '80px',
        render: (_, record: any) => (
          <Tooltip title={_} placement="topLeft">
            <div className={styles.nameOutlined}>{_}</div>
          </Tooltip>
        ),
      },
      {
        title: '手机号',
        dataIndex: 'maskPhoneNo',
        key: 'maskPhoneNo',
        width: '80px',
        render: (_, record: any) => <div>{_ ? _ : '--'}</div>,
      },
      {
        title: '进入分支时间',
        dataIndex: 'enterTime',
        key: 'enterTime',
        width: '120px',
        render(value, record, index) {
          return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作',
        key: 'action',
        width: '88px',
        render: (value: any, data: any, index: any) => (
          <Space>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                busEvent.emit('onLookLine', { lineFlag: data.lookLine, oneId: data.oneId });
                let listDataCopy = [...listData];
                listDataCopy.forEach((e: any, i: any) => {
                  if (!e.lookLine && i !== index) {
                    listDataCopy[i].lookLine = true;
                    busEvent.emit('onLookLine', { lineFlag: false, oneId: data.oneId });
                  }
                });
                listDataCopy[index].lookLine = !listDataCopy[index].lookLine;
                setListData(listDataCopy);
              }}>
              {data.lookLine ? '查看路径' : '取消查看'}
            </Button>
          </Space>
        ),
      },
    ];

    //列表数据
    const getUserDetailList = async (page: { pageNum: number; pageSize: number }, enterTime: any, findCondition?: any, status?: any) => {
      const params: any = {
        pageNum: page.pageNum,
        pageSize: page.pageSize,
        enterTimeStart: enterTime[0].format('YYYY-MM-DD HH:mm:ss'),
        enterTimeEnd: enterTime[1].format('YYYY-MM-DD HH:mm:ss'),
        findCondition, //手机号或者oneId
        taskId: type === 2 ? info?.data?.taskId : info?.data?.edgeTaskId,
        // taskId: 'testTask', //任务Id
      };
      let res: any = null;
      if (status === '0' || status === '1' || status === '2') {
        //短信节点
        params.sendTimeStart = params.enterTimeStart;
        params.sendTimeEnd = params.enterTimeEnd;
        params.sendResult = status === '0' ? 1 : status === '1' ? 2 : '';
        delete params.enterTimeStart;
        delete params.enterTimeEnd;
        res = await getSmsNodeVisitsData(params);
      } else {
        //普通节点
        res = await getNodeVisitsData(params);
      }
      if (res) {
        setListData(
          res.data.records.map((item: any) => {
            return {
              ...item,
              lookLine: true,
            };
          })
        );
        setPageQuery({
          pageSize: page.pageSize,
          pageNum: page.pageNum,
          total: res.data.total,
        });
        busEvent.emit('onLookLine', { lineFlag: false, oneId: '' });
      }
    };
    //短信明细数据中  实际发送人数 发送成功失败人数
    const smsNodeStatFunction = async () => {
      const params: any = {
        taskId: info?.data?.taskId, //任务Id / 只有短信 是节点id
      };
      const res: any = await getSmsNodeStatData(params);
      if (res) {
        var obj: any = res.data;
        var arr = [
          {
            name: '实际发送人次',
            tooltip: '陆泽实际推送给渠道服务商的人次总数',
            num: obj.sendCount >= 0 ? obj.sendCount : '--',
            status: '2',
          },
          {
            name: '发送成功人次',
            tooltip: '渠道服务商返回的发送成功人次总数，代表服务商成功收到消息',
            num: obj.successCount >= 0 ? obj.successCount : '--',
            status: '0',
          },
          {
            name: '发送失败人次',
            tooltip: '渠道服务商返回的发送失败人次总数，代表服务商未成功收到消息',
            num: obj.failCount >= 0 ? obj.failCount : '--',
            status: '1',
          },
        ];
        setSendData(arr);
      }
    };
    //收起列表
    const closeList = () => {
      setListShow(!listShow);
    };
    //展开列表
    const openlistData = (index: any, status: any) => {
      setChooseIndex(index);
      setListShow(true);
      setCloseButton(true);
      getUserDetailList({ pageSize: 10, pageNum: 1 }, dateValue, phoneOrId, status);
    };

    //保存分群
    const addNodeToGroup = async (crowd: any) => {
      let res: any = null;
      const params: any = {
        ...crowd,
        taskId: info.data.taskId,
        findCondition: phoneOrId,
        enterTimeStart: dateValue[0].format('YYYY-MM-DD HH:mm:ss'),
        enterTimeEnd: dateValue[1].format('YYYY-MM-DD HH:mm:ss'),
      };
      //分为普通节点和短信节点
      if (type === 1) {
        res = await addNodeVisitorToCrowd(params);
      } else {
        params.status = sendData[chooseIndex].status === '0' ? 1 : sendData[chooseIndex].status === '1' ? 2 : ''; //只有短信有状态
        res = await addSmsNodeVisitorToCrowd(params);
      }
    };
    //分群弹窗
    const openCheckGroupPop = (status: boolean, type?: number, param?: any) => {
      if (status) {
        setGroupCreatePopShow(false);
      }
      //type 1为取消，2为确定
      if (type === 2) {
        addNodeToGroup({ crowdId: param.crowdId, crowdName: param.crowdName });
      } else if (type === 3) {
        setCrowd(param);
      }
      setGroupPopShow(status);
    };
    //创建分群变量
    const openCreateGroupPop = (status: boolean, type?: number, param?: any) => {
      //如果是关闭，那就打开选择分群弹窗
      if (!status) {
        openCheckGroupPop(true);
      } else {
        openCheckGroupPop(false);
      }
      //type 1为取消，2为确定
      setGroupCreatePopShow(status);
      //如果是确定
      if (type === 2) {
        addGroup(param);
      }
    };
    //创建分群接口
    const addGroup = async (param: any) => {
      const res = await createGroup(param);
      if (res) {
        console.log('==========res,res', res.data);
        openCheckGroupPop(true, 3, { crowdId: res.data, crowdName: param.crowdName });
      }
    };
    useEffect(() => {
      if (info?.id) {
        console.log('changeRunningData', info);
        //节点改变时，保存分群 弹窗 关闭
        openCheckGroupPop(false);
        setNodeInfo(info);
        setPhoneOrId('');
        // type 1 是 节点信息展示 2 是 短信详情数据
        if (type === 2) {
          smsNodeStatFunction();
          setListShow(false);
        } else {
          getUserDetailList({ pageSize: 10, pageNum: 1 }, dateValue, '');
        }
      }
      return () => {
        setListShow(true);
        setCloseButton(false);
      };
    }, [info]);

    useEffect(() => {
      return () => {};
    }, []);
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
      getUserDetailList,
    }));
    return (
      <div className={styles['runningTab-box']}>
        {type === 2 && (
          <div className={styles['runningTab-cardBoxBig']}>
            {sendData.map((item: any, index?: number) => (
              <div
                key={index}
                className={styles['runningTab-itemBox']}
                style={{
                  border: chooseIndex === index ? 'var(--border4)' : 'var(--border8)',
                }}
                onClick={() => openlistData(index, item.status)}>
                <div className={styles['runningTab-cardBox']}>
                  <div style={{ color: 'var(--text-font-color18)' }}>{item.name}</div>
                  <Tooltip title={item.tooltip} className={styles['runningTab-cardBox-icon']}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div className={styles['runningTab-cardBox-num']}>{item.num}</div>
              </div>
            ))}
          </div>
        )}
        {listShow && (
          <div className={styles['runningTab-searchBox']}>
            <Search
              placeholder="请输入客户手机号或OneID"
              allowClear
              onSearch={(value, _e, info) => {
                var status = '';
                if (chooseIndex) {
                  status = sendData.filter((item: any, index: any) => index === chooseIndex)[0].status;
                }
                getUserDetailList({ pageSize: 10, pageNum: 1 }, dateValue, value, status);
              }}
              value={phoneOrId}
              onChange={e => {
                setPhoneOrId(e.target.value);
              }}
              style={{ width: '100%' }}
            />
          </div>
        )}
        {/* icon-jianduan */}
        {/* checkGroup */}
        {listShow && (
          <div>
            <div className={`${styles['runningTab-marginTop24']}`} style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '14px', width: 70, wordBreak: 'keep-all' }}>选择时间：</span>
              <Select
                defaultValue={selectedCycle}
                options={selectCycle}
                style={{ width: 88, marginRight: '8px' }}
                onChange={e => {
                  //重新给日期选择框赋值
                  setSelectedCycle(e);
                  if (e !== '6') {
                    var status = '';
                    if (chooseIndex) {
                      status = sendData.filter((item: any, index: any) => index === chooseIndex)[0].status;
                    }
                    let timeArr: any = [];
                    if (e === '5') {
                      setDateValue([dayjs(nodeInfo.data.createTime), dayjs()]);
                      timeArr = [dayjs(nodeInfo.data.createTime), dayjs()];
                    } else if (e === '1') {
                      setDateValue([dayjs().startOf('day'), dayjs()]);
                      timeArr = [dayjs().startOf('day'), dayjs()];
                    } else {
                      var day = cycleDays.filter((item: any) => item.value === e)[0].day;
                      setDateValue([dayjs().add(-day, 'days'), dayjs()]);
                      timeArr = [dayjs().add(-day, 'days'), dayjs()];
                    }
                    getUserDetailList({ pageSize: 10, pageNum: 1 }, timeArr, phoneOrId, status);
                  }
                }}></Select>
              <RangePicker
                value={dateValue}
                allowClear={false}
                disabled={selectedCycle !== '6'}
                disabledDate={disabledDate}
                onChange={(val: any, string: any) => {
                  console.log(22, val);
                  if (val) {
                    setDateValue(val);
                    var status = '';
                    if (chooseIndex) {
                      status = sendData.filter((item: any, index: any) => index === chooseIndex)[0].status;
                    }
                    getUserDetailList({ pageSize: 10, pageNum: 1 }, val, phoneOrId, status);
                  }
                }}
                showTime
                style={{}}
              />
            </div>
            <div className={styles['runningTab-saveGroup']}>
              <Button onClick={() => openCheckGroupPop(true)} disabled={listData.length === 0}>
                保存分群
              </Button>
              {closeButton && (
                <Button style={{ padding: '4px 0px 4px 15px' }} type="link" onClick={closeList}>
                  收起
                </Button>
              )}
            </div>
            {/* 创建分群弹窗 */}
            {groupPopShow && (
              <CheckGroup openCreateGroupPop={openCreateGroupPop} openCheckGroupPop={openCheckGroupPop} crowd={crowd} top={18}></CheckGroup>
            )}
            {/* 创建分群弹窗 */}
            {groupCreatePopShow && <CreateGroup openCreateGroupPop={openCreateGroupPop} top={18}></CreateGroup>}
            <Alert message={alertText} type="info" className={`${styles['runningTab-marginTop24']}`} />
            <Table
              style={{ width: '100%' }}
              rowKey={record => record.oneId}
              columns={columns}
              dataSource={listData}
              scroll={{ x: 'max-content' }}
              pagination={{
                total: pageQuery.total,
                pageSize: pageQuery.pageSize,
                current: pageQuery.pageNum,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: total => `共 ${total} 条`,
              }}
              className={`${styles['runningTab-marginTop24']}`}
              onChange={(pagination: any, _: any, sorter: any) => {
                let sortStore: any = {};
                if (!sorter.order) {
                  sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
                } else {
                  sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
                }
                var status = '';
                if (chooseIndex) {
                  status = sendData.filter((item: any, index: any) => index === chooseIndex)[0].status;
                }
                getUserDetailList({ pageSize: pagination.pageSize, pageNum: pagination.current }, dateValue, phoneOrId, status);
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
export default RunningTab;
