import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  Button,
  Form,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  message,
} from "antd";
import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
import {
  getCustomerShopListApi,
} from "@/crm/api/dataBoardApi";
const { RangePicker } = DatePicker;
const SearchForm = ({
  onFinish,
  type,
  background = "#f5f5f5",
}: {
  onFinish: (e: any) => void;
  background?: string;
  type: "customer";
}) => {
  const dateOptions = [
    { label: "日", value: "day" },
    { label: "周", value: "week" },
    { label: "月", value: "month" },
    { label: "年", value: "year" },
    { label: "MTD", value: "mtd" },
    // { label: "自定义", value: "custom" },
  ];
  // 自然日和自定义
  const defaultStartDay = dayjs().add(-1, "day").format("YYYY-MM-DD");
  const defaultEndDay = dayjs().add(-1, "day").format("YYYY-MM-DD");
  // MTD
  const defaultStartMTD = dayjs().startOf("month").format("YYYY-MM-DD");
  const defaultEndMTD = dayjs().add(-1, "day").format("YYYY-MM-DD");
  // 自然周
  const defaultWeek = dayjs().add(-1, "week");
  // 自然月
  const defaultMonth = dayjs().add(-1, "month").format("YYYY-MM-DD");
  // 自然年
  const defaultYear = dayjs().add(-1, "year").format("YYYY-MM-DD");

  const { channelAllList } = useGetChannelList(type);
  const { shopListBySeveralChannel, getShopListBySeveralChannel } =
    useGetShoplListBySeveralChannel(type);
  
  const [shopList, setShopList] = useState<any>([]);
    //获得店铺列表
  const getShopByChannel = async (
    memberCardId: string | null = null,
    channelType: string | null = null
  ) => {
    let res: any = {};
    res = await getCustomerShopListApi({ memberCardId, channelType });
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ value: e.id, label: e.name }));
      setShopList(arr);
      setSearchInfo({...searchInfo, allShopIds: arr.map((e: any) => e.value)});
    }
  };

  const defaultSearchInfo = {
    dateType: "day",
    platformType: "all",
    shopId: "all",
    startDate: dayjs().add(-1, "day").format("YYYY-MM-DD"),
    endDate: dayjs().add(-1, "day").format("YYYY-MM-DD"),
    paramStartDate: dayjs().add(-7, "day").format("YYYY-MM-DD"), // 有的功能模块选择少于7天/周/月/年，但参数要传近7天/周/月/年
    paramEndDate: dayjs().add(-1, "day").format("YYYY-MM-DD"), // 有的功能模块选择少于7天/周/月/年，但参数要传近7天/周/月/年
    isSupplyDate: 1,
    allShopIds: shopList.map((e: any) => e.value),
  };
  const [form] = Form.useForm();
  const [searchInfo, setSearchInfo] = useState<any>(defaultSearchInfo);

  useEffect(() => {
    getShopByChannel('customer', searchInfo.platformType)
  }, [searchInfo.platformType])

  useEffect(() => {
    onFinish({
      ...defaultSearchInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentDate = dayjs(); // 当前日期
  // 禁用T+1
  const disabledDate = (current: any, e: any) => {
    const yesToday = dayjs().add(-1, "day");
    return (
      (current && current.isAfter(yesToday)) ||
      (e.from && Math.abs(current.diff(e.from, "days")) >= 45)
    );
  };

  // 禁用当周及以后的日期
  const disabledDateWeek = (current: any, e: any) => {
    return (
      current.valueOf() >=
      currentDate.subtract(1, "day").startOf("week").valueOf()
    );
  };
  // 禁用当月及以后的日期
  const disabledDateMonth = (current: any, e: any) => {
    return (
      current.valueOf() >=
      currentDate.subtract(1, "day").startOf("month").valueOf()
    );
  };

  // 禁用当年及以后的日期
  const disabledDateYear = (current: any, e: any) => {
    return (
      current.valueOf() >=
      currentDate.subtract(1, "day").startOf("year").valueOf()
    );
  };

  const setDefaultSearchInfo = (e: any) => {
    if (e.target.value === "day" || e.target.value === "custom") {
      setSearchInfo({
        ...searchInfo,
        dateType: e.target.value,
        startDate: defaultStartDay,
        endDate: defaultEndDay,
        paramStartDate: dayjs().add(-7, "day").format("YYYY-MM-DD"),
        paramEndDate: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        isSupplyDate: 1,
      });
    }
    if (e.target.value === "mtd") {
      const diff = Math.abs(
        dayjs(dayjs().startOf("month")).diff(dayjs().add(-1, "day"), "day")
      );
      setSearchInfo({
        ...searchInfo,
        dateType: e.target.value,
        startDate: defaultStartMTD,
        endDate: defaultEndMTD,
        paramStartDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        paramEndDate: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        isSupplyDate: diff < 6 ? 1 : 0,
      });
    }
    if (e.target.value === "week") {
      setSearchInfo({
        ...searchInfo,
        dateType: e.target.value,
        startDate: dayjs(defaultWeek)
          .startOf("week")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        endDate: dayjs(defaultWeek)
          .endOf("week")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        paramStartDate: dayjs()
          .add(-7, "week")
          .startOf("week")
          .format("YYYY-MM-DD"),
        paramEndDate: dayjs(defaultWeek).endOf("week").format("YYYY-MM-DD"),
        isSupplyDate: 1,
      });
    }
    if (e.target.value === "month") {
      setSearchInfo({
        ...searchInfo,
        dateType: e.target.value,
        startDate: dayjs(defaultMonth).startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs(defaultMonth).endOf("month").format("YYYY-MM-DD"),
        paramStartDate: dayjs()
          .add(-7, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        paramEndDate: dayjs(defaultMonth).endOf("month").format("YYYY-MM-DD"),
        isSupplyDate: 1,
      });
    }
    if (e.target.value === "year") {
      setSearchInfo({
        ...searchInfo,
        dateType: e.target.value,
        startDate: dayjs(defaultYear).startOf("year").format("YYYY-MM-DD"),
        endDate: dayjs(defaultYear).endOf("year").format("YYYY-MM-DD"),
        paramStartDate: dayjs()
          .add(-7, "year")
          .startOf("year")
          .format("YYYY-MM-DD"),
        paramEndDate: dayjs(defaultYear).endOf("year").format("YYYY-MM-DD"),
        isSupplyDate: 1,
      });
    }
  };

  // 传入数组返回对应字符串逗号分隔
  const getArrString = (list: string[], disabledText: string) => {
    const key: string =
      list.length > 1
        ? list.filter((e: string) => e !== disabledText).join(",") || ""
        : list.join(",");
    const channelKey =
      list.includes(disabledText) &&
      list.findIndex((e: string) => e === disabledText) !== 0
        ? disabledText
        : key;
    return channelKey;
  };

  return (
    <div className={styles["dataBoard_tableSearch"]} style={{ background }}>
      <Form
        form={form}
        name="advanced_search"
        style={{ width: "100%" }}
        onFinish={onFinish}
      >
        <Row gutter={[32, 0]}>
          <Col span={12} style={{ display: "flex", gap: "8px" }}>
            <Form.Item label="时间" style={{ marginBottom: "16px" }}>
              <Radio.Group
                options={dateOptions}
                optionType="button"
                value={searchInfo.dateType}
                onChange={(e) => {
                  setDefaultSearchInfo(e);
                }}
                style={{ whiteSpace: "nowrap" }}
              />
            </Form.Item>
            <Form.Item label="" style={{ marginBottom: "16px", flex: "1 1 0" }}>
              {(searchInfo.dateType === "day" ||
                searchInfo.dateType === "mtd") && (
                <RangePicker
                  // MTD 时禁止更改日期
                  disabled={searchInfo.dateType === "mtd"}
                  picker="date"
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  allowClear={false}
                  value={[
                    searchInfo.startDate
                      ? dayjs(searchInfo.startDate)
                      : dayjs().add(-1, "day"),
                    searchInfo.endDate
                      ? dayjs(searchInfo.endDate)
                      : dayjs().add(-1, "day"),
                  ]}
                  onChange={
                    ((
                      e: (
                        | string
                        | number
                        | dayjs.Dayjs
                        | Date
                        | null
                        | undefined
                      )[]
                    ) => {
                      const diff = Math.abs(
                        dayjs(e[1]).diff(dayjs(e[0]), "day")
                      );
                      setSearchInfo({
                        ...searchInfo,
                        startDate:
                          e && e[0] ? dayjs(e[0]).format("YYYY-MM-DD") : "",
                        endDate:
                          e && e[1] ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                        paramStartDate:
                          e && e[0]
                            ? diff < 6
                              ? dayjs(e[1]).add(-6, "day").format("YYYY-MM-DD")
                              : dayjs(e[0]).format("YYYY-MM-DD")
                            : "",
                        paramEndDate:
                          e && e[1] ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                        isSupplyDate: diff < 6 ? 1 : 0,
                      });
                    }) as any
                  }
                />
              )}

              {searchInfo.dateType === "week" && (
                <RangePicker
                  style={{ width: "100%" }}
                  picker="week"
                  disabledDate={disabledDateWeek}
                  allowClear={false}
                  value={[
                    searchInfo.startDate ? dayjs(searchInfo.startDate) : null,
                    searchInfo.endDate ? dayjs(searchInfo.endDate) : null,
                  ]}
                  onChange={(
                    e: (
                      | string
                      | number
                      | dayjs.Dayjs
                      | Date
                      | null
                      | undefined
                    )[]
                  ) => {
                    const diff = Math.abs(
                      dayjs(e[1]).diff(dayjs(e[0]), "week")
                    );
                    setSearchInfo({
                      ...searchInfo,
                      startDate:
                        e && e[0]
                          ? dayjs(e[0])
                              .startOf("week")
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          : "",
                      endDate:
                        e && e[1]
                          ? dayjs(e[1])
                              .endOf("week")
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          : "",
                      paramStartDate:
                        e && e[0]
                          ? diff < 6
                            ? dayjs(e[1])
                                .add(-6, "week")
                                .startOf("week")
                                .add(1, "day")
                                .format("YYYY-MM-DD")
                            : dayjs(e[0]).format("YYYY-MM-DD")
                          : "",
                      paramEndDate:
                        e && e[1]
                          ? dayjs(e[1])
                              .endOf("week")
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          : "",
                      isSupplyDate: diff < 6 ? 1 : 0,
                    });
                  }}
                />
              )}
              {searchInfo.dateType === "month" && (
                <RangePicker
                  style={{ width: "100%" }}
                  picker="month"
                  disabledDate={disabledDateMonth}
                  allowClear={false}
                  value={[
                    searchInfo.startDate
                      ? dayjs(searchInfo.startDate).startOf("month")
                      : null,
                    searchInfo.endDate
                      ? dayjs(searchInfo.endDate).endOf("month")
                      : null,
                  ]}
                  onChange={(
                    e: (
                      | string
                      | number
                      | dayjs.Dayjs
                      | Date
                      | null
                      | undefined
                    )[]
                  ) => {
                    const diff = Math.abs(
                      dayjs(e[1]).diff(dayjs(e[0]), "month")
                    );
                    setSearchInfo({
                      ...searchInfo,
                      startDate:
                        e && e[0]
                          ? dayjs(e[0]).startOf("month").format("YYYY-MM-DD")
                          : "",
                      endDate:
                        e && e[1]
                          ? dayjs(e[1]).endOf("month").format("YYYY-MM-DD")
                          : "",
                      paramStartDate:
                        e && e[0]
                          ? diff < 6
                            ? dayjs(e[1])
                                .add(-6, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD")
                            : dayjs(e[0]).format("YYYY-MM-DD")
                          : "",
                      paramEndDate:
                        e && e[1]
                          ? dayjs(e[1]).endOf("month").format("YYYY-MM-DD")
                          : "",
                      isSupplyDate: diff < 6 ? 1 : 0,
                    });
                  }}
                />
              )}
              {searchInfo.dateType === "year" && (
                <RangePicker
                  style={{ width: "100%" }}
                  picker="year"
                  disabledDate={disabledDateYear}
                  allowClear={false}
                  value={[
                    searchInfo.startDate ? dayjs(searchInfo.startDate) : null,
                    searchInfo.endDate ? dayjs(searchInfo.endDate) : null,
                  ]}
                  onChange={(
                    e: (
                      | string
                      | number
                      | dayjs.Dayjs
                      | Date
                      | null
                      | undefined
                    )[]
                  ) => {
                    const diff = Math.abs(
                      dayjs(e[1]).diff(dayjs(e[0]), "year")
                    );
                    setSearchInfo({
                      ...searchInfo,
                      startDate:
                        e && e[0]
                          ? dayjs(e[0]).startOf("year").format("YYYY-MM-DD")
                          : "",
                      endDate:
                        e && e[1]
                          ? dayjs(e[1]).endOf("year").format("YYYY-MM-DD")
                          : "",
                      paramStartDate:
                        e && e[0]
                          ? diff < 6
                            ? dayjs(e[1])
                                .add(-6, "year")
                                .startOf("year")
                                .format("YYYY-MM-DD")
                            : dayjs(e[0]).format("YYYY-MM-DD")
                          : "",
                      paramEndDate:
                        e && e[1]
                          ? dayjs(e[1]).endOf("year").format("YYYY-MM-DD")
                          : "",
                      isSupplyDate: diff < 6 ? 1 : 0,
                    });
                  }}
                />
              )}
              {searchInfo.dateType === "custom" && (
                <RangePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  allowClear={false}
                  value={[
                    searchInfo.startDate ? dayjs(searchInfo.startDate) : null,
                    searchInfo.endDate ? dayjs(searchInfo.endDate) : null,
                  ]}
                  onChange={(e) =>
                    setSearchInfo({
                      ...searchInfo,
                      startDate:
                        e && e[0] ? dayjs(e[0]).format("YYYY-MM-DD") : "",
                      endDate:
                        e && e[1] ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="渠道" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={channelAllList}
                value={
                  searchInfo.platformType
                    ? searchInfo.platformType.split(",")
                    : []
                }
                onChange={(value) => {
                  getShopListBySeveralChannel(null, getArrString(value, "all"));
                  const platformTypeValue = getArrString(value, "all");
                  setSearchInfo({
                    ...searchInfo,
                    platformType: platformTypeValue,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="店铺" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={shopListBySeveralChannel}
                value={searchInfo.shopId ? searchInfo.shopId.split(",") : []}
                onChange={(value) => {
                  const shopIdValue = getArrString(value, "all");
                  setSearchInfo({ ...searchInfo, shopId: shopIdValue });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => {
                getShopListBySeveralChannel(null, "0");
                setSearchInfo(defaultSearchInfo);
              }}
            >
              重置
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => {
                if (searchInfo.platformType === "") {
                  message.warning("请至少选择一个渠道");
                  return false;
                }
                if (searchInfo.shopId === "") {
                  message.warning("请至少选择一个店铺");
                  return false;
                }
                if (
                  searchInfo.platformType !== "" &&
                  searchInfo.shopId !== ""
                ) {
                  onFinish({
                    ...searchInfo,
                    platformType: searchInfo.platformType.toLowerCase(),
                  });
                }
              }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchForm;
