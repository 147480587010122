/**
 * 人群细分
 */
import React, { useEffect, useState } from "react";
import ChartByCustom from "./ChartByCustom"; // 人群细分-自定义人群
import MemberFourQuadrants from "./MemberFourQuadrants"; // 会员四象限追踪
import OldCustomByRecency from "./OldCustomByRecency"; // 老客by recency回购
import TaskList from "./../../Industry/component/TaskList";
import Header from "../component/Header";

import CustomQualitySplit from "./CustomQualitySplit"; // 客质拆分
const CrowdSegmentation: React.FC = () => {
  const [searchForm, setSearchForm] = useState<any>({});

  return (
    <div>
      <Header
        title={"人群细分"}
        searchForm={searchForm}
        hasExport={false}
        setSearchForm={(v: object) => {
          setSearchForm(v);
        }}
      />
      {searchForm && (
        <>
          <ChartByCustom searchForm={searchForm} />
          <MemberFourQuadrants searchForm={searchForm} />
          <OldCustomByRecency searchForm={searchForm} />
          <CustomQualitySplit searchForm={searchForm} />
        </>
      )}
    </div>
  );
};

export default CrowdSegmentation;
