import { AxiosPromise } from 'axios';
import request from '../network';
//画布
// 保存草稿
export function saveCanvas(query: any): AxiosPromise<any> {
  return request({
    url: '/ma/canvas/save',
    method: 'post',
    data: query,
  });
}
// 发布画布
export function deployCanvas(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/canvas/deploy',
    method: 'post',
    data: query,
  });
}
// 查看画布
export function getCanvas(id: any): AxiosPromise<any> {
  return request({
    url: `/ma/canvas/detail/${id}`,
    method: 'get',
    params: {},
  });
}
//效果数据(报表）
// 查询统计信息
export function getSummaryData(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/report/getSummaryData',
    method: 'get',
    params: query,
  });
}
// 查看人次数据
export function getVisitsData(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/report/getVisitsData',
    method: 'get',
    params: query,
  });
}
// 导出csv
export function csvExport(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/report/csvExport',
    method: 'get',
    params: query,
  });
}
// 查看按时间段的统计信息
export function getSummaryByPeriod(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/report/getSummaryByPeriod',
    method: 'get',
    params: query,
  });
}
//工作流查看
// 查询统计信息
export function getStatData(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/nodedata/getStatData',
    method: 'get',
    params: query,
  });
}
// 查看节点人次数据
export function getNodeVisitsData(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/nodedata/getNodeVisitsData',
    method: 'get',
    params: query,
  });
}
// 查看人的路径信息
export function getUserPath(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/nodedata/getUserPath',
    method: 'get',
    params: query,
  });
}
// 查看短信节点的人次信息
export function getSmsNodeVisitsData(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/nodedata/getSmsNodeVisitsData',
    method: 'get',
    params: query,
  });
}
// 查看短信节点的统计信息
export function getSmsNodeStatData(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/nodedata/getSmsNodeStatData',
    method: 'get',
    params: query,
  });
}
// 查看短信节点的基本信息
export function getSmsNodeInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/nodedata/getSmsNodeInfo',
    method: 'get',
    params: query,
  });
}
//数据操作（加入分群组件）
// 查询分群
export function getGroupList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/groupNode/getGroupList',
    method: 'get',
    params: query,
  });
}
// 查询分群开始节点用）
export function getGroupListFromStartNode(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/groupNode/getGroupListFromStartNode',
    method: 'get',
    params: query,
  });
}
// 新建分群
export function createGroup(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/groupNode/createGroup',
    method: 'post',
    data: query,
  });
}
// 加入分群（工作流的整体）
export function addWorkflowVisitorToCrowd(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/groupNode/addWorkflowVisitorToCrowd',
    method: 'post',
    data: query,
  });
}
// 加入分群（短信节点）
export function addSmsNodeVisitorToCrowd(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/groupNode/addSmsNodeVisitorToCrowd',
    method: 'post',
    data: query,
  });
}
// 加入分群（普通节点）
export function addNodeVisitorToCrowd(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/groupNode/addNodeVisitorToCrowd',
    method: 'post',
    data: query,
  });
}
//营销触达（短信组件）
// 获取子账号列表
export function getSubAccountList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/getSubAccountList',
    method: 'get',
    params: query,
  });
}
// 获取可插入用户变量信息
export function getUserFieldVarList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/getUserFieldVarList',
    method: 'get',
    params: query,
  });
}
// 获取签名信息
export function getSmsSignList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/getSmsSignList',
    method: 'get',
    params: query,
  });
}
// 获取测试手机号
export function getMobileList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/getMobileList',
    method: 'get',
    params: query,
  });
}
// 获取账号余额
export function getAvailableBalance(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/getAvailableBalance',
    method: 'get',
    params: query,
  });
}
// 短链接转换
export function createShortLink(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/createShortLink',
    method: 'post',
    data: query,
  });
}
// 发送测试短信
export function sendTestSms(query: any): AxiosPromise<any> {
  return request({
    url: '/ma/smsNode/sendTestSms',
    method: 'post',
    data: query,
  });
}
// 查询短信发送记录
export function smsSendRecord(query: any): AxiosPromise<any> {
  return request({
    url: '/ma/smsNode/smsSendRecord',
    method: 'get',
    params: query,
  });
}
// 查询短信模板
export function getTemplateList(): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsNode/getTemplateList',
    method: 'get',
  });
}

// 场景接口
// 通过旅程ID获取场景信息
export function getScenesByJourneyId(id: any): AxiosPromise<any[]> {
  return request({
    url: `/ma/scene/getScenesByJourneyId/${id}`,
    method: 'get',
    params: {},
  });
}
// 添加场景信息
export function addSceneInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/scene/addSceneInfo',
    method: 'post',
    data: query,
  });
}
// 删除场景信息
export function deleteSceneInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: `/ma/scene/deleteSceneInfo`,
    method: 'delete',
    data: query,
  });
}
// 更新场景信息
export function updateSceneInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/scene/updateSceneInfo',
    method: 'put',
    data: query,
  });
}
// 旅程接口
//获取旅程场景信息
export function getJourneySceneInfo(): AxiosPromise<any[]> {
  return request({
    url: 'ma/journey/getJourneySceneInfo',
    method: 'get',
  });
}
// 获取旅程or场景信息
export function getJourneyOrScenes(type: any): AxiosPromise<any[]> {
  return request({
    url: `/ma/journey/getJourneyOrScenes/${type}`,
    method: 'get',
    params: {},
  });
}
// 根据名称模糊查询旅程场景信息
export function getJourneySceneInfoByName(query: any): AxiosPromise<any[]> {
  return request({
    url: `/ma/journey/getJourneySceneInfoByName`,
    method: 'post',
    data: query,
  });
}
// 添加旅程信息
export function addJourneyInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/journey/addJourneyInfo',
    method: 'post',
    data: query,
  });
}
// 删除旅程信息
export function deleteJourneyInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: `/ma/journey/deleteJourneyInfo`,
    method: 'delete',
    data: query,
  });
}
// 更新旅程信息
export function updateJourneyInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/journey/updateJourneyInfo',
    method: 'put',
    data: query,
  });
}
// 更新旅程信息
export function updateSortIndex(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/journey/updateSortIndex',
    method: 'put',
    data: query,
  });
}
//策略查询接口
// 根据参数获取策略信息
export function getStrategyInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategy/getStrategyInfo',
    method: 'post',
    data: query,
  });
}
// 根据场景获取不同状态下策略数量
export function getStrategyStateNumByScnId(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategy/getStrategyStateNumByScnId',
    method: 'get',
    params: query,
  });
}
// 获取策略状态列表
export function getStrategyStateList(): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategy/getStrategyStateList',
    method: 'get',
    params: {},
  });
}
// 获取策略类型列表
export function getStrategyTypeList(): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategy/getStrategyTypeList',
    method: 'get',
    params: {},
  });
}
//策略操作接口
// 复制策略信息
export function strategiesCopy(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategies/copy',
    method: 'post',
    data: query,
  });
}
// 取消待启动策略（仅待启动状态）
export function strategiesCancel(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategies/cancel',
    method: 'put',
    data: query,
  });
}
// 删除策略，仅草稿和已停止状态（草稿状态物理删除，其他为逻辑删除
export function strategiesDelete(query: any): AxiosPromise<any[]> {
  return request({
    url: `/ma/strategies/delete`,
    method: 'delete',
    data: query,
  });
}
// 批量停止运行中的策略（仅运行中状态
export function strategiesStop(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategies/stop',
    method: 'put',
    data: query,
  });
}
// 批量停止运行中的策略（仅运行中状态
export function strategiesChangeGroup(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategies/changeGroup',
    method: 'put',
    data: query,
  });
}

// 获取短信模板列表
export function getSmsTemplatesList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsTemplate/getSmsTemplates',
    method: 'get',
    params: query,
  });
}

// 查看短信模板
export function getSmsTemplateDetail(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsTemplate/getSmsTemplate',
    method: 'get',
    params: query,
  });
}

// 创建短信模板
export function createSmsTemplate(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsTemplate/createSmsTemplate',
    method: 'put',
    data: query,
  });
}

// 编辑短信模板（启用/停用/编辑/删除）
export function updateSmsTemplateInfo(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsTemplate/updateSmsTemplate',
    method: 'post',
    data: query,
  });
}

// 获取短信冻结数量
export function getFreezeNum(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getFreezeNum',
    method: 'get',
    params: query,
  });
}

// 获取短信发送汇总列表
export function getMsgSummaryByAccount(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getMsgSummary',
    method: 'get',
    params: query,
  });
}

// 短信发送汇总导出
export function getMsgSummaryByAccounCsv(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getMsgSummaryCsv',
    method: 'get',
    params: query,
  });
}

// 短信充值明细
export function getSmsRechargeRecords(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getSmsRechargeRecords',
    method: 'get',
    params: query,
  });
}

// 充值记录导出
export function getSmsRechargeRecordsCsv(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getSmsRechargeRecordsCsv',
    method: 'get',
    params: query,
  });
}
// 短信明细查询(营销）
export function getMarketMsgDetail(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getMarketMsgDetail',
    method: 'post',
    data: query,
  });
}
// 短信明细查询(测试）
export function getTestMsgDetail(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getTestMsgDetail',
    method: 'post',
    data: query,
  });
}
// 短信明细查询(营销）导出
export function getMarketMsgDetailCsv(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getMarketMsgCsv',
    method: 'post',
    data: query,
  });
}
// 短信明细查询(测试）导出
export function getTestMsgDetailCsv(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/msgManage/getTestMsgCsv',
    method: 'post',
    data: query,
  });
}

// 获取短信通道列表
export function getSmsPassageList(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsConfig/getSmsPassageList',
    method: 'get',
    params: query,
  });
}
// 短信通道查询
export function getSmsPassageDetail(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsConfig/getSmsPassageDetail',
    method: 'get',
    params: query,
  });
}
// 创建短信通道
export function addSmsPassage(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsConfig/addSmsPassage',
    method: 'put',
    data: query,
  });
}

// 编辑短信通道 （编辑/删除）
export function updateSmsPassage(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsConfig/updateSmsPassage',
    method: 'post',
    data: query,
  });
}

// 启用/停用 短信通道
export function updateSmsPassageStatus(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/smsConfig/updateSmsPassageStatus',
    method: 'post',
    data: query,
  });
}

// 根据通道ID判断是否存在待启动或者运行中的策略
export function judgmentByAccountId(query: any): AxiosPromise<any[]> {
  return request({
    url: '/ma/strategy/judgmentByAccountId',
    method: 'post',
    data: query,
  });
}

export default {};
