import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useRef } from 'react';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';

import styles from './index.module.scss';
import { Button, Modal, message, Space, Popover, Tabs, Dropdown, Tooltip } from 'antd';
import busEvent from '@/utils/bus';
import Iconfont from '../../../../component/Icon';
import YLIcon from '../../../../../src/component/Icon/YLIcon.jsx';
const imageShapes = [
  {
    row: '1',
    type: '2',
    componentId: 'message',
    label: '短信',
    borderColor: '#d3adf7',
    color: '#722ed1',
    backColor: '#f9f0ff',
    icon: 'icon-duanxin',
  },
  {
    row: '2',
    type: '2',
    componentId: 'crowd',
    label: '加入分群',
    color: '#2f54eb',
    borderColor: '#adc6ff',
    backColor: '#f0f5ff',
    icon: 'icon-jiarufenqun',
  },
  {
    row: '3',
    type: '2',
    componentId: 'shunt',
    label: '分流器',
    color: '#52c41a',
    borderColor: '#b7eb8f',
    backColor: '#f6ffed',
    icon: 'icon-fenliuqi',
  },
  {
    row: '3',
    type: '2',
    componentId: 'waiting',
    label: '等待',
    color: '#52c41a',
    borderColor: '#b7eb8f',
    backColor: '#f6ffed',
    icon: 'icon-dengdai',
  },
  {
    row: '3',
    type: '2',
    componentId: 'end',
    label: '结束',
    color: '#1e1e1e',
    borderColor: '#d0d0d0',
    backColor: '#f5f5f5',
    icon: 'icon-jieshu',
  },
];
let operType: any = 1;
type TabPosition = 'left' | 'right' | 'top' | 'bottom';
// #region初始化图形
const NodesPopup = (props: { visible: boolean; closePopup: Function }) => {
  const { visible, closePopup = () => {} } = props;
  const [tabPosition, setTabPosition] = useState<TabPosition>('left');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState<any>({});
  const [currentType, setCurrentType] = useState('1');
  const [nodesList, setNodesList] = useState<any>([]);
  const cancelModal = () => {
    setIsModalVisible(false);
    closePopup(false);
  };
  const ComponentItem = () => {
    return (
      <div className={styles['flex-box']}>
        {nodesList.map((item: any, index: number) => (
          <div data-label={item.label} key={index} className={styles['dnd-node']} onClick={addNodes}>
            <div
              data-label={item.label}
              className={styles['dnd-node-img']}
              style={{ border: `1px solid ${item.borderColor}`, backgroundColor: `${item.backColor}`, color: `${item.color}` }}>
              {/* <Iconfont className={styles['menu-icon']} icon={item.icon}></Iconfont> */}
              <YLIcon className={styles['menu-icon']} color={item.color} size={24} type={item.icon}></YLIcon>
              {/* <img src={item.image} alt="" /> */}
            </div>
            <div>{item.label}</div>
          </div>
        ))}
      </div>
    );
  };
  const componentLibrary: any = [
    {
      key: '1',
      label: '营销触达',
      children: <ComponentItem />,
    },
    {
      key: '2',
      label: '数据操作',
      children: <ComponentItem />,
    },
    {
      key: '3',
      label: '执行控制',
      children: <ComponentItem />,
    },
  ];
  const addNodes = (e: any) => {
    const target = e.currentTarget;
    const datalabel = target.getAttribute('data-label');
    console.log('1-addNodes', { datalabel, operType });
    closePopup({ datalabel, operType });
  };
  useEffect(() => {
    console.log('1---');
    setIsModalVisible(visible);
    busEvent.on('openPopup', show => {
      if (show) {
        setIsModalVisible(true);
        operType = show;
        setCurrentType('1');
        setNodesList(imageShapes.filter((item: any) => item.row === '1'));
      } else {
        setIsModalVisible(false);
      }
    });
  }, [visible]);
  const changeNodeType = (e: any) => {
    console.log('==', e);
    // const type = e.currentTarget.getAttribute('data-type');
    setCurrentType(e);
    setNodesList(imageShapes.filter((item: any) => item.row === e));
  };
  return (
    <div>
      <Modal title={'组件库'} visible={isModalVisible} onCancel={cancelModal} footer={null}>
        {/* 在这里根据 nodeData 展示节点的信息 */}
        <Tabs
          tabPosition={tabPosition}
          className={styles['NodesPopup_tabs']}
          items={componentLibrary}
          activeKey={currentType}
          style={{ height: '297px' }}
          onChange={e => {
            changeNodeType(e);
          }}
        />
      </Modal>
    </div>
  );
};
export default NodesPopup;
