  // 表格label value对应
export default {
    goodsTableColumnMap: [
        {
            label: "日期",
            value: "bizDate",
        },
        {
            label: "商品名称",
            value: "spuName",
        },
        {
            label: "Spu_id",
            value: "spuId",
        },
        {
            label: "Sku名称",
            value: "skuName",
        },
        {
            label: "Sku_id",
            value: "skuId",
        },
        {
            label: "条形码",
            value: "barCode",
        },
        {
            label: "品类",
            value: "categoryPrimaryName",
        },
        {
            label: "渠道",
            value: "platformType",
        },
        {
            label: "店铺",
            value: "shopId",
        },
        {
            label: "购买人数",
            value: "buyNum",
        },
        {
            label: "购买人数同比",
            value: "buyNumYoy",
        },
        {
            label: "购买人数占比",
            value: "buyNumRate",
        },
        {
            label: "购买人数占比值同比",
            value: "buyNumRateYoy",
        },
        {
            label: "销售额",
            value: "sales",
        },
        {
            label: "销售额同比",
            value: "salesYoy",
        },
        {
            label: "购买件数",
            value: "skuNum",
        },
        {
            label: "购买件数同比",
            value: "skuNumYoy",
        },
        {
            label: "订单数",
            value: "ordNum",
        },
        {
            label: "订单数同比",
            value: "ordNumYoy",
        },
        {
            label: "会员新会购买人数",
            value: "newMemberBuyNum",
        },
        {
            label: "新客购买人数同比",
            value: "newBuyNumYoy",
        },
        {
            label: "新客购买人数占比",
            value: "newBuyNumRate",
        },
        {
            label: "新客购买人数占比同比",
            value: "newBuyNumRateYoy",
        },
        {
            label: "新客销售额",
            value: "newSales",
        },
        {
            label: "新客销售额同比",
            value: "newSalesYoy",
        },
        {
            label: "新客购买件数",
            value: "newSkuNum",
        },
        {
            label: "新客购买件数同比",
            value: "newSkuNumYoy",
        },
        {
            label: "新客订单数",
            value: "newOrdNum",
        },
        {
            label: "新客订单数同比",
            value: "newOrdNumYoy",
        },

        {
            label: "会员老会购买人数",
            value: "oldMemberBuyNum",
        },
        {
            label: "老客购买人数同比",
            value: "oldBuyNumYoy",
        },
        {
            label: "老客购买人数占比",
            value: "oldBuyNumRate",
        },
        {
            label: "老客购买人数占比同比",
            value: "oldBuyNumRateYoy",
        },
        {
            label: "老客销售额",
            value: "oldSales",
        },
        {
            label: "老客销售额同比",
            value: "oldSalesYoy",
        },
        {
            label: "老客购买件数",
            value: "oldSkuNum",
        },
        {
            label: "老客购买件数同比",
            value: "oldSkuNumYoy",
        },
        {
            label: "新客购买人数",
            value: "newBuyNum",
        },
        {
            label: "老客购买人数",
            value: "oldBuyNum",
        },
        {
            label: "老客订单数",
            value: "oldOrdNum",
        },
        {
            label: "老客订单数同比",
            value: "oldOrdNumYoy",
        },
        {
            label: "会员购买人数",
            value: "memberBuyNum",
        },
        {
            label: "会员购买人数同比",
            value: "memberBuyNumYoy",
        },
        {
            label: "会员购买人数占比",
            value: "memberBuyNumRate",
        },
        {
            label: "会员购买人数占比同比",
            value: "memberBuyNumRateYoy",
        },
        {
            label: "会员销售额",
            value: "memberSales",
        },
        {
            label: "会员销售额同比",
            value: "memberSalesYoy",
        },
        {
            label: "会员购买件数",
            value: "memberSkuNum",
        },
        {
            label: "会员购买件数同比",
            value: "memberSkuNumYoy",
        },
        {
            label: "会员订单数",
            value: "memberOrdNum",
        },
        {
            label: "会员订单数同比",
            value: "memberOrdNumYoy",
        },
        {
            label: "会员新客购买人数",
            value: "newMemberBuyNum",
        },
        {
            label: "会员老客购买人数",
            value: "oldMemberBuyNum",
        },
    ],
    categoryTableColumnMap: [
        {
            label: "日期",
            value: "bizDate",
        },
        {
            label: "品类",
            value: "categoryPrimaryName",
        },
        {
            label: "渠道",
            value: "platformType",
        },
        {
            label: "店铺",
            value: "shopId",
        },
        {
            label: "购买人数",
            value: "buyNum",
        },
        {
            label: "购买人数同比",
            value: "buyNumYoy",
        },
        {
            label: "购买人数占比",
            value: "buyNumRate",
        },
        {
            label: "购买人数占比值同比",
            value: "buyNumRateYoy",
        },
        {
            label: "销售额",
            value: "sales",
        },
        {
            label: "销售额同比",
            value: "salesYoy",
        },
        {
            label: "购买件数",
            value: "skuNum",
        },
        {
            label: "购买件数同比",
            value: "skuNumYoy",
        },
        {
            label: "订单数",
            value: "ordNum",
        },
        {
            label: "订单数同比",
            value: "ordNumYoy",
        },
        {
            label: "会员新会购买人数",
            value: "newMemberBuyNum",
        },
        {
            label: "新客购买人数同比",
            value: "newBuyNumYoy",
        },
        {
            label: "新客购买人数占比",
            value: "newBuyNumRate",
        },
        {
            label: "新客购买人数占比同比",
            value: "newBuyNumRateYoy",
        },
        {
            label: "新客销售额",
            value: "newSales",
        },
        {
            label: "新客销售额同比",
            value: "newSalesYoy",
        },
        {
            label: "新客购买件数",
            value: "newSkuNum",
        },
        {
            label: "新客购买件数同比",
            value: "newSkuNumYoy",
        },
        {
            label: "新客订单数",
            value: "newOrdNum",
        },
        {
            label: "新客订单数同比",
            value: "newOrdNumYoy",
        },

        {
            label: "会员老会购买人数",
            value: "oldMemberBuyNum",
        },
        {
            label: "老客购买人数同比",
            value: "oldBuyNumYoy",
        },
        {
            label: "老客购买人数占比",
            value: "oldBuyNumRate",
        },
        {
            label: "老客购买人数占比同比",
            value: "oldBuyNumRateYoy",
        },
        {
            label: "老客销售额",
            value: "oldSales",
        },
        {
            label: "老客销售额同比",
            value: "oldSalesYoy",
        },
        {
            label: "老客购买件数",
            value: "oldSkuNum",
        },
        {
            label: "老客购买件数同比",
            value: "oldSkuNumYoy",
        },
        {
            label: "老客订单数",
            value: "oldOrdNum",
        },
        {
            label: "老客订单数同比",
            value: "oldOrdNumYoy",
        },
        {
            label: "新客购买人数",
            value: "newBuyNum",
        },
        {
            label: "老客购买人数",
            value: "oldBuyNum",
        },
        {
            label: "会员购买人数",
            value: "memberBuyNum",
        },
        {
            label: "会员购买人数同比",
            value: "memberBuyNumYoy",
        },
        {
            label: "会员购买人数占比",
            value: "memberBuyNumRate",
        },
        {
            label: "会员购买人数占比同比",
            value: "memberBuyNumRateYoy",
        },
        {
            label: "会员销售额",
            value: "memberSales",
        },
        {
            label: "会员销售额同比",
            value: "memberSalesYoy",
        },
        {
            label: "会员购买件数",
            value: "memberSkuNum",
        },
        {
            label: "会员购买件数同比",
            value: "memberSkuNumYoy",
        },
        {
            label: "会员订单数",
            value: "memberOrdNum",
        },
        {
            label: "会员订单数同比",
            value: "memberOrdNumYoy",
        },
        {
            label: "会员新客购买人数",
            value: "newMemberBuyNum",
        },
        {
            label: "会员老客购买人数",
            value: "oldMemberBuyNum",
        },
    ],
}