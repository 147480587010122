import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Tag, message, Popover, DatePicker, Tooltip, Select } from 'antd';
import { CopyOutlined, FilterOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import { channelIconMap } from '../../assets/public/pageMap';
import { getShopListApi, getMemberLevelListsApi, getChannelListsApi } from '../../api/memberCardList';
import { getMemberCardListApi } from '../../api/distReward';
import copyToClipboard from '../../assets/public/clipboard';
import dayjs from 'dayjs';

import checkPermission from '../../assets/public/checkPermission';

// 带有粘贴功能的文本
export const StickText = ({ text, show = true, onClick }: { text: any; show?: boolean; onClick?: () => void }) => {
  const handCopy = () => {
    // const text: any = copyText ? copyText : copyText;
    copyToClipboard(text)
      .then(() => {
        message.success('复制成功');
      })
      .catch(error => {
        message.error('复制成功');
      });
  };

  return (
    <div style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
      <span className={style['StickText-text']} onClick={onClick}>
        {text}
      </span>
      {show && <CopyOutlined className={style['MiniStyle-StickText_icon']} onClick={handCopy} />}
    </div>
  );
};

// 两种种颜色状态文本
export const TextColorTypes = ({ text = '', status = false }: { text: string; status: boolean }) => {
  return <>{status ? <div className={style['MiniStyle-tag-green']}>{text}</div> : <div className={style['MiniStyle-tag-red']}>{text}</div>}</>;
};

// 变更量，带有+ — 符号的数字
export const NumberStatus = ({ number, status }: { number: number; status: boolean }) => {
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {status ? '+' : '-'}
      {number}
    </div>
  );
};

// 标题，左侧标题右侧展开按钮
export const TitleWithExpansion = ({
  title = '',
  operaTionTitle = '',
  otherTitle = '',
  otherType = '',
  children,
  onChange,
  onBtnChange,
  btnDisabled = false,
  searchPermissions,
  operaTionPermissions,
  otherChange,
}: {
  title: string;
  operaTionTitle?: string;
  otherTitle?: string;
  otherType?: any;
  children?: any;
  btnDisabled?: boolean;
  searchPermissions?: string;
  operaTionPermissions?: string;
  onChange: () => void;
  onBtnChange?: () => void;
  otherChange?: () => void;
}) => {
  const [iconColor, setIconColor] = useState('var(--text-font-color4)');
  const handleMouseEnter = () => {
    // console.log('鼠标移入')
    setIconColor('var(--text-font-color9)');
  };
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setIconColor('var(--text-font-color4)');
  };

  return (
    <div className={style['MiniStyle-TitleWithExpansion-bgd']}>
      <div className={style['MiniStyle-TitleWithExpansion']}>
        <div className={style['title']}>{title}</div>
        <div>
          {checkPermission(searchPermissions) && (
            <Tooltip placement="bottom" title={'过滤器'}>
              <Button
                onClick={onChange}
                icon={
                  <FilterOutlined
                    style={{
                      color: iconColor,
                      fontSize: 'var(--text-font7)',
                    }}
                  />
                }
                type="text"
                className="click-element"
                style={{ marginRight: '10px' }}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}></Button>
            </Tooltip>
          )}
          {operaTionTitle && checkPermission(operaTionPermissions) && (
            <>
              {checkPermission(searchPermissions) && (
                // <Divider type="vertical" style={{ margin: "0 16px 0 10px" }} />
                <span
                  style={{
                    width: '0',
                    height: '16px',
                    border: 'var(--border3)',
                    marginRight: 16,
                  }}></span>
              )}
              <Button type="primary" onClick={onBtnChange} disabled={btnDisabled}>
                {operaTionTitle}
              </Button>
            </>
          )}
          {otherTitle && (
            // 忠诚度管理-奖励事件-新增重复奖励策略按钮
            <>
              {checkPermission(searchPermissions) && (
                <span
                  style={{
                    width: '0',
                    height: '16px',
                    border: 'var(--border3)',
                    marginRight: 16,
                  }}></span>
              )}
              <Button type={otherType || 'default'} onClick={otherChange}>
                {otherTitle}
              </Button>
            </>
          )}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

// 渠道图标map
export const ChannelIcon = ({ channelType = 'JD', style = {} }) => {
  return <img src={channelIconMap.get(channelType)} alt="" style={{ width: '20px', height: '20px', ...style }} />;
};

// 渠道对应标签颜色
export const ChannelTag = ({ type = 'JD', children, hidden = false }: { type: string; children: string; hidden?: boolean }) => {
  return (
    <div
      className={style[`MiniStyle-ChannelTag`]}
      style={{
        zIndex: `${hidden ? '-1' : '1'}`,
        opacity: `${hidden ? '0' : '1'}`,
      }}>
      {children}
    </div>
  );
};

// 左侧标题右侧输入框等内容布局
export const PageItem = ({
  title = '',
  require = true,
  tips = '',
  children,
}: {
  title?: string;
  require?: boolean;
  tips?: string;
  children?: any;
}) => {
  return (
    <div className={style['MiniStyle-pageItem-bgd']}>
      <div className={style['title-bgd']}>
        {title && (
          <>
            {require && <div className={style['require-icon']}>*</div>}
            <div>{title}</div>
            {tips && (
              <Popover  overlayInnerStyle={{padding: 16}}  content={tips}>
                <QuestionCircleOutlined className={style['tip-icon']} />
              </Popover>
            )}
            <div>：</div>
          </>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

// 页面小标题
export const PageTitle = ({ children }: { children: string }) => {
  return <div className={style['MiniStyle-pageTitle']}>{children}</div>;
};

//Radio中的时间框
export const RadioDatePicker = ({ value, onChange, disabled = false }: { value: string; onChange: (e: string) => void; disabled?: boolean }) => {
  const [open, setOpen] = useState(false);

  const onOpenChange = (e: boolean) => {
    setTimeout(() => {
      setOpen(e);
    }, 10);
  };

  return (
    <DatePicker
      disabled={disabled}
      value={value ? dayjs(value) : null}
      style={{ margin: '0 8px' }}
      open={open}
      placeholder="请选择日期"
      allowClear={false}
      onOpenChange={onOpenChange}
      onChange={e => onChange(e?.format('YYYY-MM-DD') || '')}
    />
  );
};

// 时间框 多少天后的多少日有效
export const CardDatePicker = ({
  expireYear,
  expireDate,
  onChange,
  width = 160,
  disabled = false,
}: {
  expireYear: number;
  expireDate: string;
  width?: number;
  onChange: (e: string) => void;
  disabled?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const defaultDate: any = useMemo(() => {
    return expireYear === 0 ? dayjs().add(1, 'day').format('MM-DD') : dayjs().format('MM-DD');
  }, [expireYear]);
  const dateValue = useMemo(() => {
    return `${Number(dayjs().format('YYYY')) + Number(expireYear)}-${expireDate || defaultDate}`;
  }, [defaultDate, expireDate, expireYear]);

  const onOpenChange = (e: boolean) => {
    setTimeout(() => {
      setOpen(e);
    }, 10);
  };

  return (
    <DatePicker
      style={{ margin: '0 8px', width }}
      open={open}
      disabled={disabled}
      defaultPickerValue={dayjs(dateValue)}
      disabledDate={current => {
        if (Number(expireYear) === 0) {
          const tomorrow = dayjs().endOf('day');
          const endOfYear = dayjs().endOf('year');
          return current.isBefore(tomorrow) || current.isAfter(endOfYear) || (current.date() === 29 && current.month() === 1);
        }

        return (
          current.year() < dayjs().add(Number(expireYear), 'year').year() ||
          current.year() > dayjs().add(Number(expireYear), 'year').year() ||
          (current.date() === 29 && current.month() === 1)
        );
      }}
      value={expireDate ? dayjs(dateValue, 'YYYY-MM-DD') : null}
      format="MM-DD"
      placeholder="请选择日期"
      allowClear={false}
      onChange={e => onChange(e?.format('MM-DD') || '')}
      onOpenChange={onOpenChange}
    />
  );
};

// Radio中的下拉框
let cardSelectTime: any = null;
export const CardSelect = ({
  options,
  onChange,
  value = 160,
  disabled = false,
}: {
  options: any[];
  value: any;
  onChange: (e: string) => void;
  disabled?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const onOpenChange = () => {
    if (!cardSelectTime) {
      setOpen(e => !e);
      cardSelectTime = setTimeout(() => {
        cardSelectTime = null;
      }, 500);
    }
  };

  return (
    <Select
      open={open}
      onDropdownVisibleChange={onOpenChange}
      className={style['MiniStyle-input-short']}
      options={options}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );
};

// 超过两行显示省略号
export const LinesText = ({ children }: { children: string }) => {
  const lineTextRef = useRef<any>(null);
  const lineHideRef = useRef<any>(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    checkShowToolTips();
  }, [lineTextRef, lineHideRef]);

  // 判断是否显示
  const checkShowToolTips = () => {
    if (
      lineTextRef.current.offsetHeight > 0 &&
      lineHideRef.current.offsetHeight > 0 &&
      lineHideRef.current.offsetHeight > lineTextRef.current.offsetHeight
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  return (
    <div ref={lineTextRef} className={style['MiniStyle_linesText']}>
      {show && <Tooltip title={children}>{children}</Tooltip>}
      {!show && children}
      <div ref={lineHideRef} className={style['MiniStyle_linesText-hide']}>
        {children}
      </div>
    </div>
  );
};

// 问号文本
export const TextWithQuestion = ({ title = '', tips = '', black = false, style = {} }: { title: any; tips?: any; black?: any; style?: any }) => (
  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
    {title && <span style={{ fontSize: 'var(--text-font5)' }}>{title}</span>}
    {tips &&
      (black ? (
        <Tooltip title={tips}>
          <QuestionCircleOutlined style={{ fontSize: 'var(--text-font5)', marginLeft: '6px', ...style }} />
        </Tooltip>
      ) : (
        <Popover  overlayInnerStyle={{padding: 16}}  content={<span style={{ whiteSpace: 'pre-wrap' }}>{tips}</span>}>
          <QuestionCircleOutlined
            style={{
              fontSize: 'var(--text-font5)',
              marginLeft: '6px',
              ...style,
            }}
          />
        </Popover>
      ))}
  </span>
);

// 获得店铺列表
export const useGetShopList = () => {
  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    // shopList.current.length === 0 && getList();
    getShopListApi().then(res => setShopList(res?.data || []));
  }, []);

  return { shopList };
};

// 获得会员卡下拉框选项列表
export const useGetMemberCardList = () => {
  const [memberCardOptions, setMemberCardOptions] = useState<any>([]);
  const [memberCardAllOptions, setMemberCardAllOptions] = useState<any>([]);

  useEffect(() => {
    // shopList.current.length === 0 && getList();
    getMemberCardListApi().then(res => {
      const arr = res?.data || [];
      const options = arr.map((item: any) => ({
        ...item,
        value: item.id,
        label: item.memberCardName,
      }));
      setMemberCardOptions(options);
      setMemberCardAllOptions([{ label: '全部会员卡', value: '' }, ...options]);
    });
  }, []);

  return { memberCardOptions, memberCardAllOptions };
};

// 获得会员卡对应的等级列表
export const useGetMemberLevelList = () => {
  const levelList = useRef([]);

  const getMemberLevelList = async (memberCardId: number | string) => {
    const res = await getMemberLevelListsApi(memberCardId);
    if (res.code === 200) {
      levelList.current =
        res.data?.map((item: any) => ({
          value: item.gradeCode,
          label: `VIP${item.gradeCode}`,
        })) || [];
    }
    return levelList.current;
  };
  return { getMemberLevelList };
};

// 获得渠道列表
export const useGetChannelList = () => {
  const channelList = useRef([]);
  const channelNameMap = useRef(new Map());

  const getChannelList = async () => {
    const res = await getChannelListsApi();
    if (res.code === 200) {
      channelList.current =
        res?.data?.map((item: any) => {
          if (!channelNameMap.current.has(item.channelCode)) {
            channelNameMap.current.set(item.channelCode, item.channelName);
          }

          return {
            value: item.channelCode,
            label: item.channelName,
          };
        }) || [];
    }
    return {
      options: channelList.current,
      nameMap: channelNameMap.current,
    };
  };
  return { getChannelList };
};
