import { useState, useEffect, useMemo } from 'react';
import { ZoomInOutlined, ZoomOutOutlined, ExpandAltOutlined, SyncOutlined } from '@ant-design/icons';
import G6 from '@antv/g6';
import { Graph } from '@antv/g6';
import { getNumberWithComma } from '@/crm/utils';
import { formatData, fittingString, hexToOpacity } from './utils';
import type { BubbleDataItem } from './utils';
// @ts-ignore
// import insertCss from 'insert-css';
import Legend from './Legend';

// insertCss(`
//   .g6-component-tooltip {
//     border: 1px solid #e2e2e2;
//     border-radius: 4px;
//     font-size: 12px;
//     color: #545454;
//     background-color: #fff;
//     padding: 10px 8px;
//     box-shadow: rgb(174, 174, 174) 0px 0px 10px;
//     opacity: 1;
//     width: 250px;
//     position: absolute;
//     top: 80px !important;
//     right: 80px !important;
//     left: unset !important;
//   }
// `);
const { getLabelPosition, transform } = G6.Util;
// 注册边的动画：arrow-running
G6.registerEdge(
  'arrow-running',
  {
    afterDraw(cfg, group) {
      // get the first shape in the group, it is the edge's path here=
      const shape = group!.get('children')[0];

      const arrow = group!.addShape('marker', {
        attrs: {
          x: 16,
          y: 0,
          r: 8,
          lineWidth: 2,
          stroke: '#3370ff',
          fill: '#fff',
          symbol: (x: number, y: number, r: number) => {
            return [
              ['M', x - 6, y - 4],
              ['L', x, y],
              ['L', x - 6, y + 4],
            ];
          },
        },
      });

      // animation for the red circle
      arrow!.animate(
        (ratio: any) => {
          // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
          // get the position on the edge according to the ratio
          const tmpPoint = shape.getPoint(ratio);
          const pos = getLabelPosition(shape, ratio);
          let matrix = [1, 0, 0, 0, 1, 0, 0, 0, 1];
          matrix = transform(matrix, [
            ['t', -tmpPoint.x, -tmpPoint.y],
            ['r', pos.angle],
            ['t', tmpPoint.x, tmpPoint.y],
          ]);

          // returns the modified configurations here, x and y here
          return {
            x: tmpPoint.x,
            y: tmpPoint.y,
            matrix,
          };
        },
        {
          repeat: false, // Whether executes the animation repeatly
          duration: 3000, // the duration for executing once
        },
      );
    },
  },
  'quadratic', // extend the built-in edge 'cubic'
);

const lineDash = [4, 2, 1, 2];
G6.registerEdge(
  'line-dash',
  {
    setState(name, value, item) {
      // console.log(name, value, item);
      const shape = item!.get('keyShape');
      if (!item) {
        return;
      }
      if (name === 'running') {
        if (value) {
          let index = 0;
          shape.animate(
            () => {
              index++;
              if (index > 9) {
                index = 0;
              }
              const res = {
                lineDash,
                lineDashOffset: -index,
              };
              return res;
            },
            {
              repeat: true,
              duration: 3000,
            },
          );
        } else {
          shape.stopAnimate();
          shape.attr('lineDash', null);
        }
      }
    },
  },
  'quadratic', // extend the built-in edge 'cubic'
);

// 注册自定义节点
G6.registerNode(
  'customNode',
  {
    // 绘制节点
    drawShape: function drawShape(cfg, group) {
      // @ts-ignore
      const shapeType = this.shapeType;
      // @ts-ignore
      const shapeStyle = this.getShapeStyle(cfg);

      const style = Object.assign({}, shapeStyle, {
        opacity: 1,
        y: 10,
        r: shapeStyle.r - 10,
        lineWidth: 0,
      });

      // 小圆
      group!.addShape(shapeType, {
        attrs: style,
        name: 'key-shape',
      });
      // 大圆
      const shape = group!.addShape('circle', {
        attrs: {
          r: shapeStyle.r,
          fill: shapeStyle.fill,
          fillOpacity: 1,
          // stroke: shapeStyle.fill as string,
          // strokeOpacity: 0.6,
        },
        name: 'circle-shape',
      });
      return shape;
    },
    // 根据状态修改节点
    setState: function setState(name, value, item) {
      if (!item) {
        return;
      }
      const group = item.getContainer();
      // 获取 shape === circle-shape 的图形
      const shapeInner = group.get('children')[0];
      const shapeOuter = group.get('children')[1];
      // console.log(name, value, item, shapeOuter);

      if (name === 'cDark') {
        if (value) {
          shapeInner.attr('fillOpacity', 0);
          shapeOuter.attr('fillOpacity', 0.05);
          shapeOuter.attr('lineWidth', 0);
          // shapeOuter.attr('strokeOpacity', 0.6);
        } else {
          shapeInner.attr('fillOpacity', 1);
          shapeOuter.attr('fillOpacity', 1);
          shapeOuter.attr('lineWidth', 0);
          // shapeOuter.attr('strokeOpacity', 0.6);
        }
      }
      if (name === 'CHighlight') {
        if (value) {
          shapeInner.attr('fillOpacity', 1);
          shapeOuter.attr('fillOpacity', 0.05);
          shapeOuter.attr('lineWidth', 1);
          // shapeOuter.attr('strokeOpacity', 0.6);
        } else {
          shapeInner.attr('fillOpacity', 1);
          shapeOuter.attr('fillOpacity', 1);
          shapeOuter.attr('lineWidth', 0);
          // shapeOuter.attr('strokeOpacity', 0.6);
        }
      }
      if (name === 'cFullDark') {
        if (value) {
          shapeInner.attr('fillOpacity', 0);
          shapeOuter.attr('fillOpacity', 0.05);
          shapeOuter.attr('lineWidth', 0);
        } else {
          shapeInner.attr('fillOpacity', 1);
          shapeOuter.attr('fillOpacity', 1);
          shapeOuter.attr('lineWidth', 0);
        }
      }
      if (name === 'cFullHighlight') {
        if (value) {
          shapeInner.attr('fillOpacity', 0);
          shapeOuter.attr('fillOpacity', 1);
          shapeOuter.attr('lineWidth', 1);
        } else {
          shapeInner.attr('fillOpacity', 1);
          shapeOuter.attr('fillOpacity', 1);
          shapeOuter.attr('lineWidth', 0);
        }
      }
    },
  },
  'circle',
);

let graphInstance: { [propName: string]: Graph } = {};

function refreshDragedNodePosition(e: any) {
  const model = e.item.get('model');
  model.fx = e.x;
  model.fy = e.y;
  model.x = e.x;
  model.y = e.y;
}

interface IProps {
  instanceName: string;
  data: BubbleDataItem[];
  analysisDim: string;
}
const checkedNode: any = {};
const hoverNode: any = {};
const RelationChart: React.FC<IProps> = props => {
  const { instanceName, data = [], analysisDim = '单品' } = props;
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const onLegendChange = (checkedList: string[]) => {
    setCheckedList(checkedList);
  };
  const dataSource = useMemo(() => formatData(data), [data]);
  const [, setChangeRender] = useState<any>(new Date().getTime());

  useEffect(() => {
    if (graphInstance[instanceName]) {
      graphInstance[instanceName].destroy();
    }
    if (data.length === 0) return;
    // const dataSource = formatData(data);
    const { nodes, mutualEdges, edges } = dataSource;

    const container = document.getElementById(`container-${instanceName}`);
    const width = container!.scrollWidth;
    const height = container!.scrollHeight || 700;
    graphInstance[instanceName] = new G6.Graph({
      container: `container-${instanceName}`,
      width,
      height,
      // fitView: true,
      fitViewPadding: 20,
      // plugins: [tooltip],
      modes: {
        default: ['drag-canvas', 'drag-node'],
      },
      layout: {
        type: 'force',
        clusterGravity: 2,
        gravity: 2,
        speed: 5,
        clustering: true,
        nodeClusterBy: 'cluster',
        preventOverlap: true,
        // clusterNodeStrength: 1,
        // getCenter: [0, 1000],
        linkDistance: (d: any) => {
          if (d.isLeaf) {
            return analysisDim === '单品' ? 75 : 100;
          }
          return analysisDim === '单品' ? 150 : 200;
        },
        nodeStrength: (d: any) => {
          if (d.isLeaf) {
            return -1000;
          }
          return -800;
        },
      },
      defaultNode: {
        lineWidth: 2,
        fill: '#fcd681',
        // stroke: '#fcd681',
      },
      defaultEdge: {
        type: 'line-dash',
      },
      nodeStateStyles: {
        CHighlight: {
          opacity: 1,
        },
        cDark: {
          opacity: 0.05,
        },
      },
      animate: true,
      animateCfg: {
        duration: 1000,
      },
    });

    graphInstance[instanceName].on('node:dragstart', function (e) {
      graphInstance[instanceName].layout();
      refreshDragedNodePosition(e);
    });
    graphInstance[instanceName].on('node:drag', function (e) {
      refreshDragedNodePosition(e);
    });
    graphInstance[instanceName].on('node:dragend', function (e: any) {
      e.item.get('model').fx = null;
      e.item.get('model').fy = null;
    });

    function clearAllStats() {
      checkedNode[instanceName] = null;
      hoverNode[instanceName] = null;
      setChangeRender(new Date().getTime());
      graphInstance[instanceName].setAutoPaint(false);
      graphInstance[instanceName].getNodes().forEach(function (node) {
        graphInstance[instanceName].clearItemStates(node);
        const nodeModel = node.getModel();
        graphInstance[instanceName].updateItem(node, {
          label: nodeModel.isLeaf
            ? undefined
            : (fittingString(nodeModel.name as string, nodeModel.size as number, 12) as string),
        });
      });
      graphInstance[instanceName].getEdges().forEach(function (edge) {
        graphInstance[instanceName].clearItemStates(edge);
        graphInstance[instanceName].updateItem(edge, {
          label: undefined,
          style: {
            // lineWidth: Number(edge.getModel().style!.fixedLineWidth),
            stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 1),
          },
        });
      });
      graphInstance[instanceName].paint();
      graphInstance[instanceName].setAutoPaint(true);
    }

    // 节点 hover
    graphInstance[instanceName].on('node:mouseenter', function (e) {
      if (checkedNode[instanceName]) {
        return;
      }
      setChangeRender(new Date().getTime());
      setChangeRender(new Date().getTime());
      hoverNode[instanceName] = e.item!.getModel();
      const item: any = e.item;
      graphInstance[instanceName].setAutoPaint(false);
      graphInstance[instanceName].getNodes().forEach(function (node) {
        graphInstance[instanceName].clearItemStates(node);
        graphInstance[instanceName].setItemState(node, 'cDark', true);
        graphInstance[instanceName].updateItem(node, {
          label: undefined,
        });
      });
      graphInstance[instanceName].setItemState(item, 'cDark', false);
      graphInstance[instanceName].setItemState(item, 'CHighlight', true);
      graphInstance[instanceName].updateItem(item, {
        label: fittingString(item.getModel().name as string, item.getModel().size as number, 12),
      });

      graphInstance[instanceName].getEdges().forEach(function (edge) {
        if (edge.getSource() === item) {
          // @ts-ignore
          const isMutualNode = edge.getTarget().getModel().properties?.sitList?.find((item) => item.goodsId === edge.getTarget().getModel().properties?.goodsId)
          graphInstance[instanceName].setItemState(edge.getTarget(), isMutualNode ? 'cFullDark' : 'cDark', false);
          graphInstance[instanceName].setItemState(
            edge.getTarget(),
            isMutualNode ? 'cFullHighlight' : 'CHighlight',
            true,
          );
          graphInstance[instanceName].setItemState(edge, 'running', true);
          graphInstance[instanceName].updateItem(edge, {
            label: `连带率 ${(edge.getModel().properties as any)?.rate}%`,
            style: {
              stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 1),
            },
          });
          graphInstance[instanceName].updateItem(edge.getTarget(), {
            label: fittingString(
              edge.getTarget().getModel().name as string,
              edge.getTarget().getModel().size as number,
              12,
            ),
          });
          edge.toFront();
        } else if (edge.getTarget() === item) {
          const isHoverLeaf = item.getModel().isLeaf;
          if (isHoverLeaf) {
            graphInstance[instanceName].setItemState(edge.getSource(), 'cDark', false);
            graphInstance[instanceName].setItemState(edge.getSource(), 'CHighlight', true);
            graphInstance[instanceName].updateItem(edge.getSource(), {
              label: fittingString(
                edge.getSource().getModel().name as string,
                edge.getSource().getModel().size as number,
                12,
              ),
            });
          }
          graphInstance[instanceName].setItemState(edge, 'running', isHoverLeaf ? true : false);
          graphInstance[instanceName].updateItem(edge, {
            label: isHoverLeaf ? `连带率 ${(edge.getModel().properties as any)?.rate}%` : undefined,
            style: isHoverLeaf
              ? {
                  stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 1),
                }
              : { stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 0.05) },
          });
          edge.toFront();
        } else {
          graphInstance[instanceName].setItemState(edge, 'running', false);
          graphInstance[instanceName].updateItem(edge, {
            label: undefined,
            style: {
              stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 0.05),
            },
          });
        }
      });
      graphInstance[instanceName].paint();
      graphInstance[instanceName].setAutoPaint(true);
    });
    // 节点添加点击事件，保持高亮
    graphInstance[instanceName].on('node:click', function (e) {
      if (checkedNode[instanceName]) {
        return;
      }
      setChangeRender(new Date().getTime());
      checkedNode[instanceName] = e.item!.getModel();
    });
    graphInstance[instanceName].on('node:mouseleave', () => {
      if (checkedNode[instanceName]) {
        return;
      }
      setChangeRender(new Date().getTime());
      clearAllStats();
    });

    // 边 hover
    graphInstance[instanceName].on('edge:mouseenter', function (e) {
      if (checkedNode[instanceName]) {
        return;
      }
      setChangeRender(new Date().getTime());
      const item: any = e.item;
      graphInstance[instanceName].setAutoPaint(false);
      graphInstance[instanceName].getNodes().forEach(function (node) {
        graphInstance[instanceName].clearItemStates(node);
        graphInstance[instanceName].setItemState(node, 'cDark', true);
        graphInstance[instanceName].updateItem(node, {
          label: undefined,
        });
      });
      graphInstance[instanceName].getEdges().forEach(function (edge) {
        if (edge === item) {
          graphInstance[instanceName].setItemState(edge.getSource(), 'cDark', false);
          graphInstance[instanceName].setItemState(edge.getSource(), 'CHighlight', true);
          graphInstance[instanceName].setItemState(edge.getTarget(), 'cDark', false);
          graphInstance[instanceName].setItemState(edge.getTarget(), 'CHighlight', true);
          graphInstance[instanceName].setItemState(edge, 'running', true);
          graphInstance[instanceName].updateItem(edge, {
            label: `连带率 ${(edge.getModel().properties as any)?.rate}%`,
            style: {
              // lineWidth: Number(edge.getModel().style!.fixedLineWidth) + 1,
              stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 1),
            },
          });
          graphInstance[instanceName].updateItem(edge.getSource(), {
            label: fittingString(
              edge.getSource().getModel().name as string,
              edge.getSource().getModel().size as number,
              12,
            ),
          });
          graphInstance[instanceName].updateItem(edge.getTarget(), {
            label: fittingString(
              edge.getTarget().getModel().name as string,
              edge.getTarget().getModel().size as number,
              12,
            ),
          });
          edge.toFront();
        } else {
          graphInstance[instanceName].setItemState(edge, 'running', false);
          graphInstance[instanceName].updateItem(edge, {
            label: undefined,
            style: {
              stroke: hexToOpacity(edge.getSource().getModel().style!.fixedColor as string, 0.05),
            },
          });
        }
      });
      graphInstance[instanceName].paint();
      graphInstance[instanceName].setAutoPaint(true);
    });
    graphInstance[instanceName].on('edge:mouseleave', () => {
      if (checkedNode[instanceName]) {
        return;
      }
      clearAllStats();
    });
    graphInstance[instanceName].on('canvas:click', clearAllStats);
    graphInstance[instanceName].data({
      nodes: nodes.map((node: any) => ({
        ...node,
        // label: fittingString(node.name as string, node.size as number, 14) as string,
        type: mutualEdges.some(edge => edge.source === node.id && edge.target === node.id) ? 'customNode' : 'circle',
      })),
      edges: edges,
    });
    graphInstance[instanceName].render();
    if (typeof window !== 'undefined') {
      window.onresize = () => {
        if (!graphInstance[instanceName] || graphInstance[instanceName].get('destroyed')) return;
        if (!container || !container.scrollWidth || !container.scrollHeight) return;
        graphInstance[instanceName].changeSize(container.scrollWidth, container.scrollHeight);
      };
    }
  }, [analysisDim, data.length, dataSource, instanceName]);

  useEffect(() => {
    if (data.length === 0) return;
    if (!checkedList.length) return;

    // const dataSource = formatData(data);

    const surplusEdges = [...dataSource.edges, ...dataSource.mutualEdges].filter(
      edge => checkedList.includes(edge.source) && checkedList.includes(edge.target),
    );

    graphInstance[instanceName].changeData({
      nodes: dataSource.nodes
        .filter(node => checkedList.includes(node.id))
        .map((node: any) => ({
          ...node,
          // label: fittingString(node.name as string, node.size as number, 12) as string,
          type: dataSource.mutualEdges.some(edge => edge.source === node.id && edge.target === node.id)
            ? 'customNode'
            : 'circle',
        })),
      edges: surplusEdges,
    });
  }, [checkedList, data.length, dataSource, instanceName]);

  const onChangeGrapgZoom = (zoom: number) => {
    const _zoom = zoom === 1 ? 1 / graphInstance[instanceName].getZoom() : zoom;
    graphInstance[instanceName].zoom(_zoom, { x: 800, y: 350 }, true, {
      duration: 100,
    });
  };
  const onGraphFitview = () => {
    graphInstance[instanceName].fitView(20, {} , true, {
      duration: 100,
    });
  };

  const getNodeProps = (key: string) => {
    const node = checkedNode[instanceName] || hoverNode[instanceName];
    if (key === 'goodsName' && !node?.isLeaf) {
      return (
        <span style={{ color: node?.style?.fixedColor }}>
          Top{node?.cluster + 1}：{node?.properties[key] || '-'}
        </span>
      );
    }
    if (key === 'goodsName') {
      return <span style={{ color: node?.style?.fixedColor }}>{node?.properties[key] || '-'}</span>;
    }
    return node?.properties[key] || '-';
  };
  const getNodeIsLeaf = () => {
    return checkedNode[instanceName]?.isLeaf || hoverNode[instanceName]?.isLeaf || false;
  };
  const getToolTipStatus = () => {
    return checkedNode[instanceName] || hoverNode[instanceName];
  };
  return (
    <div style={{ position: 'relative' }}>
      <Legend nodes={dataSource.nodes} onLegendChange={onLegendChange} />
      <div id={`container-${instanceName}`} style={{ position: 'relative', overflow: 'hidden', marginTop: 16 }} />
      <div
        style={{
          border: '1px solid #e2e2e2',
          borderRadius: 4,
          fontSize: 16,
          color: '#545454',
          backgroundColor: '#fff',
          padding: '10px 8px',
          boxShadow: 'rgb(174, 174, 174) 0px 0px 10px',
          position: 'absolute',
          top: 35,
          right: 80,
          display: 'flex',
          gap: 16,
        }}
      >
        <ZoomInOutlined onClick={() => { onChangeGrapgZoom(1.1) }} style={{ cursor: 'pointer' }} />
        <ZoomOutOutlined onClick={() => { onChangeGrapgZoom(0.9)}} style={{ cursor: 'pointer' }} />
        <ExpandAltOutlined onClick={onGraphFitview} style={{ cursor: 'pointer' }} />
        <SyncOutlined onClick={() => { onChangeGrapgZoom(1)}} style={{ cursor: 'pointer' }} />
      </div>
      <div
        style={{
          border: '1px solid #e2e2e2',
          borderRadius: 4,
          fontSize: 12,
          color: '#545454',
          backgroundColor: '#fff',
          padding: '10px 8px',
          boxShadow: 'rgb(174, 174, 174) 0px 0px 10px',
          opacity: getToolTipStatus() ? 1 : 0,
          width: 250,
          position: 'absolute',
          top: 80,
          right: 80,
          left: 'unset',
        }}
      >
        <p style={{ fontSize: 16 }}>{getNodeProps('goodsName')}</p>
        <div style={{ width: '100%', fontSize: 14 }}>
          {analysisDim === '单品' && (
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
              <span>Spu_id: </span>
              <span>{getNodeProps('goodsId')}</span>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>销售金额: </span>
            <span>{getNumberWithComma(getNodeProps('saleAmount'))}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>购买人数: </span>
            <span>{getNumberWithComma(getNodeProps('buyerCount'), { digit: 0 })}</span>
          </div>
          <div style={{ display: getNodeIsLeaf() ? 'none' : 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{instanceName === 'same' ? '同' : '跨'}单连带人数: </span>
            <span>{getNumberWithComma(getNodeProps('tdldCount'), { digit: 0 })}</span>
          </div>
          <div style={{ display: getNodeIsLeaf() ? 'none' : 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{instanceName === 'same' ? '同' : '跨'}单连带率: </span>
            <span>{getNodeProps('tdldRate')}%</span>
          </div>
          <div style={{ display: getNodeIsLeaf() ? 'none' : 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{instanceName === 'same' ? '同' : '跨'}单同品连带人数: </span>
            <span>{getNumberWithComma(getNodeProps('tdldTpCount'), { digit: 0 })}</span>
          </div>
          <div style={{ display: getNodeIsLeaf() ? 'none' : 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{instanceName === 'same' ? '同' : '跨'}单同品连带率: </span>
            <span>{getNodeProps('tdldTpRate')}%</span>
          </div>
          <div style={{ display: getNodeIsLeaf() ? 'none' : 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{instanceName === 'same' ? '同' : '跨'}单跨品连带人数: </span>
            <span>{getNumberWithComma(getNodeProps('tdldKpCount'), { digit: 0 })}</span>
          </div>
          <div style={{ display: getNodeIsLeaf() ? 'none' : 'flex', justifyContent: 'space-between', margin: '6px 0' }}>
            <span>{instanceName === 'same' ? '同' : '跨'}单跨品连带率: </span>
            <span>{getNodeProps('tdldKpRate')}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelationChart;
