import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import ModuleTitle from '../../../../component/ModuleTitle';
import { Button, Empty, Switch, Table, TableProps, Dropdown, Space, Input, Tree, TreeDataNode, Popconfirm, message, Tag, Tooltip } from 'antd';
import { QuestionCircleOutlined, PlusOutlined, DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import { getSmsTemplatesList, updateSmsTemplateInfo } from '../../../../api/graph/graph';
import { checkPermissions } from '@/utils/utils';
import FilterView from '../../../../component/FilterView/filterView';
import dayjs from 'dayjs';
import SmsTemplate from '../../component/smsTemplate';
import { getTableScroll } from '@/store';


const dateFormat = 'YYYY/MM/DD';

let preParams: any;
const SMSTEMPLATE = () => {

  const searchParamInt: any = {
    templateName: '',//模板名称
    status: '',//状态
    createBy: '',//创建人
    createTimeStart: '',//创建时间开始
    createTimeEnd: '',//创建时间结束
  };

  const [listData, setListData] = useState([] as any);
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const [deletePopConfirm, setDeletePopConfirm] = useState(false);
  const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number);
  const [templateId, setTemplateId] = useState(null as any);
  const [scrollY, setScrollY] = useState('' as any);

  // 新建短信模板
  const [open, setOpen] = useState(false);
  const [templateType, setTemplateType] = useState(0)//1-查看 2-编辑

  const filterViewRef: any = useRef(null);
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入模板名称',
      title: '模板名称',
      key: 'templateName',
      defaultValue: preParams?.templateName ? String(preParams.templateName) : null,
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '状态',
      key: 'status',
      defaultValue: preParams?.status ? String(preParams.status) : null,
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '启用',
        },
        {
          value: '1',
          label: '停用',
        },
      ],
    },
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入创建人',
      title: '创建人',
      key: 'createBy',
      defaultValue: preParams?.createBy ? String(preParams.createBy) : null,
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '创建时间',
      key: 'createTimeStart-createTimeEnd',
      defaultValue: preParams?.createTimeStart ? [dayjs(preParams.createTimeStart, dateFormat), dayjs(preParams.createTimeEnd, dateFormat)] : null,
    },
  ];

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  const getHeight = (e: string) => {
    if(filterViewRef.current?.arrow && listData.length > 8){
      setScrollY(e)
    }else{
      setY(listData)
    }
  };
  const setY =(list:any)=>{
    if(list.length<13){
      setScrollY(undefined)
    }else{
      setScrollY(getTableScroll())
    }
  }
  // 获取表格信息
  const getListData = async (page: { pageSize: number; pageNum: number }, data: any) => {
    console.log(data);
    const res: any = await getSmsTemplatesList({
      ...page,
      ...data,
    });
    if (res) {
      const { total, records } = res.data;
      setListData(records);
      setY(records)
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: total,
      });
    }
  };

  // 换页
  const handleTableChange = (e: any) => {
    setPageQuery({
      ...pageQuery,
      pageSize: e.pageSize,
      pageNum: e.current,
    });
    getListData({ pageSize: e.pageSize, pageNum: e.current }, searchParam);
  };

  // 操作列
  const getTableOperationButtonList = (item: any) => {
    const store = [];
    store.push({
      key: 'check',
      label: (
        <a
          style={{ color: 'var( --text-font-color9)' }}
          onClick={() => { drawerSwitch(item.templateId, 1) }}>
          查看
        </a>
      ),
    });
    store.push({
      key: 'edit',
      label: (
        <a
          style={{ color: 'var( --text-font-color9)' }}
          onClick={() => { drawerSwitch(item.templateId, 2) }}>
          编辑
        </a>
      ),
    });
    // if (checkPermissions(GROUP_KEY.GROUP_DELETE)) {
    store.push({
      key: 'del',
      label: (
        <a
          style={{ color: 'var( --text-font-color9)' }}
          onClick={() => {
            operateRecord(item, 'del');
          }}>
          删除
        </a>
      ),
    });
    // }
    return store;
  }

  // 删除/编辑/停用/启用 短信模板
  const updateRecord = async (record: any, isDeleted?: any, text?: string) => {
    setDeletePopConfirm(false);
    let param = {
      templateId: record.templateId,
      status: record.status,
      isDeleted
    }
    if (!isDeleted) {
      delete param.isDeleted
    }
    const res = await updateSmsTemplateInfo(param);
    if (res) {
      if (isDeleted) {
        message.success('删除成功');
      }
      if (text) {
        message.success(text + '成功');
      }
      getListData({ pageSize: 10, pageNum: 1 }, searchParam);
    }
  };

  // 操作列确认弹框
  const handleCancelClick = (record: any) => {
    setDeletePopConfirm(false);
  };
  // 操作列表 删除
  const operateRecord = (info: any, type: string) => {
    setSelectedTableKey(info.templateId);
    if (type === 'del') {
      setDeletePopConfirm(true);
    }
  };

  // 停用/启用模板
  const changeStatus = (record: any) => {
    record.status = record.status === 0 ? 1 : 0
    let text = record.status === 0 ? '启用' : '停用'
    updateRecord(record, null, text)
  }

  // 表格标题字段
  const columns: TableProps['columns'] = [
    {
      title: '模板名称',
      dataIndex: 'templateName',
      key: 'templateName',
      width: 150,
      render: (_: any, record: any, i: number) => (
        <Tooltip title={_} placement="topLeft">
          <span style={{cursor:'pointer'}} className='line-text-one text-color7' onClick={() =>
            drawerSwitch(record.templateId, 1)
          }>{_}</span>
        </Tooltip>),
    },
    {
      title: '模板内容',
      dataIndex: 'templateText',
      key: 'templateText',
      width: 200,
      render: (_: any, record: any, i: number) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-one'>{_}</span>
        </Tooltip>),
    },
    {
      title: '模板状态',
      dataIndex: 'status',//0-启用 1-停用
      key: 'status',
      width: 100,
      render: (_: any, record: any, i: number) => (
        <div>
          <Popconfirm
            placement="top"
            title={`确定${_ === 0 ? '停用' : '启用'}此短信模板吗？`}
            okText="确定"
            cancelText="取消"
            onConfirm={() => { changeStatus(record) }}
            onPopupClick={e => {
              e.stopPropagation();
            }}>
            <div className={style.switchText}>
              <Switch checked={_ === 0} checkedChildren="启用" unCheckedChildren="停用" />
            </div>
          </Popconfirm>
        </div>
      ),
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 150,
    },
    {
      title: '最后更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 150,
    },
    {
      title: '操作',
      dataIndex: 'sort',
      width: 50,
      align: 'center',
      key: 'sort',
      fixed: 'right',
      render: (_: any, record: any, i: number) => (
        <Space>
          <Dropdown menu={{ items: getTableOperationButtonList(record) }} trigger={['click']}>
            <Popconfirm
              placement="right"
              className={style.popconfirmBox}
              title="确定删除此短信模板吗？"
              onConfirm={() => updateRecord(record, 1)}
              onCancel={() => handleCancelClick(record)}
              open={selectedTableKey === record.templateId && deletePopConfirm}
              onPopupClick={e => {
                e.stopPropagation();
              }}
              okButtonProps={{ loading: false }}>
              <EllipsisOutlined />
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ]

  // 展开过滤器
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  // 过滤器搜索
  const filterFinish = (data: any) => {
    setSearchParam(data);
    getListData({ pageSize: 10, pageNum: 1 }, data);
  };

  const drawerSwitch = (templateId?: number, type?: number) => {
    if (open) {
      getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searchParam);
    }
    setOpen(!open);
    setTemplateType(type ?? 0)
    setTemplateId(templateId)
  };

  // 初始进入页面
  useEffect(() => {
    getListData({ pageSize: 10, pageNum: 1 }, searchParam)
  }, [])

  return (
    <div>
      {/* 标题 */}
      <div className={style.headBox}>
        <div className={style.titleBox}>
          <ModuleTitle mark="large" title="短信模板管理" />
        </div>
        <div className={style.buttonBox}>
          <SearchIconInput
            clear={true}
            width="310px"
            placeholder="请输入模板名称"
            onSearch={(e: string) => {
              getListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, { ...searchParam, templateName: e })
            }}></SearchIconInput>
          <FilterIcon filterTitle={'过滤器'} handleFilterClick={handleFilterClick} />
          <span
            style={{
              width: '0',
              height: '16px',
              border: 'var(--border3)',
              marginRight: 16,
            }}></span>
          <Button
            type="primary"
            onClick={() => { drawerSwitch() }}>
            新建短信模板
          </Button>
        </div>
      </div>
      <FilterView ref={filterViewRef} searchList={searchList} getHeight={getHeight} finish={filterFinish}></FilterView>
      <div className={style.content}>
        <div>
          <Table
            columns={columns}
            dataSource={listData}
            scroll={{ x: 1400, y: scrollY }}
            rowKey={record => record.templateId}
            pagination={{
              total: pageQuery.total,
              current: pageQuery.pageNum,
              pageSize: pageQuery.pageSize,
              showSizeChanger: true,
              showTotal: total => `共 ${total} 条`,
            }}
            onChange={e => handleTableChange(e)}
          />
        </div>
      </div>
      {/* 抽屉 */}
      {/* 新建短信模板 */}
      <SmsTemplate open={open} drawer={drawerSwitch} templateType={templateType} templateId={templateId}></SmsTemplate>
    </div>
  );
};
export default SMSTEMPLATE;
