/**
 * 人群细分-老客By Recency回购-回购情况
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import * as echarts from "echarts";
import { getNumberWithComma } from "@/utils/utils";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import { getGeneralChat } from "@/crm/api/industryBoardApi";
import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function MemberFourQuadrants(props: any) {
  const colors = [
    "#1677FF",
    "#7AA4FA",
    "#85E6BC",
    "#91CAFF",
    "#FB9747",
    "#6D7E9D",
    "#B7EB8F",
    "#B37FEB",
    "#FF4D4F",
  ];
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { searchForm } = props;
  const { metricAllList } = useGetMetricList();
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const [dataSource, setDataSource] = useState([
    {
      titleZh: "留存回购销售额",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Sales",
      digit: 2,
    },
    {
      titleZh: "留存回购人数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Purchaser",
      digit: 0,
    },
    {
      titleZh: "留存回购会员人数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Members",
      digit: 0,
    },
    {
      titleZh: "留存回购件数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Units",
      digit: 0,
    },
    {
      titleZh: "留存回购订单数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Transactions",
      digit: 0,
    },
    {
      titleZh: "留存回购笔单价",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "ATV",
      digit: 2,
    },
    {
      titleZh: "留存回购频次",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "FREQ",
      digit: 2,
    },
    {
      titleZh: "留存回购客单价",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "SPD",
      digit: 2,
    },
    {
      titleZh: "留存回购笔件数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "UPT",
      digit: 2,
    },

    {
      titleZh: "召回回购销售额",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Sales",
      digit: 2,
    },
    {
      titleZh: "召回回购人数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Purchaser",
      digit: 0,
    },
    {
      titleZh: "召回回购会员人数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Members",
      digit: 0,
    },
    {
      titleZh: "召回回购件数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Units",
      digit: 0,
    },
    {
      titleZh: "召回回购订单数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Transactions",
      digit: 0,
    },
    {
      titleZh: "召回回购笔单价",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "ATV",
      digit: 2,
    },
    {
      titleZh: "召回回购频次",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "FREQ",
      digit: 2,
    },
    {
      titleZh: "召回回购客单价",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "SPD",
      digit: 2,
    },
    {
      titleZh: "召回回购笔件数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "UPT",
      digit: 2,
    },

    {
      titleZh: "沉默回购销售额",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Sales",
      digit: 2,
    },
    {
      titleZh: "沉默回购人数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Purchaser",
      digit: 0,
    },
    {
      titleZh: "沉默回购会员人数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Members",
      digit: 0,
    },
    {
      titleZh: "沉默回购件数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Units",
      digit: 0,
    },
    {
      titleZh: "沉默回购订单数",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "Transactions",
      digit: 0,
    },
    {
      titleZh: "沉默回购笔单价",
      metricId: "",
      value: "",
      yoy: "",
      filedName: "ATV",
      digit: 2,
    },
    {
      titleZh: "沉默回购频次",
      digit: 2,
      metricId: "",
      value: "",
      yoy: "",
      filedName: "FREQ",
    },
    {
      titleZh: "沉默回购客单价",
      digit: 2,
      metricId: "",
      value: "",
      yoy: "",
      filedName: "SPD",
    },
    {
      titleZh: "沉默回购笔件数",
      digit: 2,
      metricId: "",
      value: "",
      yoy: "",
      filedName: "UPT",
    },
  ]);
  const [finalDataSource, setFinalDataSource] = useState<any>(dataSource);
  const [hasMetricIdDataSource, setHasMetricIdDataSource] = useState([]);
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);

  const setMetricId = () => {
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (
      searchForm?.shopId?.includes("all") &&
      !searchForm?.platformType?.includes("all")
    ) {
      str += "分渠道";
    } else if (
      searchForm?.shopId?.includes("all") &&
      searchForm?.platformType?.includes("all")
    ) {
      str = "";
    }
    const metricIdArr = [];
    dataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return i.metricName === item.titleZh + str;
      });
      if (findItem.length > 0) {
        metricIdArr.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setHasMetricIdDataSource(metricIdArr);
    setGetMetricIdEnd(true);
  };

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId();
    }
  }, [searchForm, metricAllList]);

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {

      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds: finalShopId,
      };
      setFinalDataSource([]);
      const groupedDataSource = groupArrByFrameWorkType(hasMetricIdDataSource);
      const groupedDataKeys = Object.keys(groupedDataSource);
      const groupedDataValues = Object.values(groupedDataSource);
      // 有几种类别的frameWorkType就循环几次
      groupedDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedDataValues[index].map((i: any) => i.metricId)
        );
      });
    }
  }, [hasMetricIdDataSource]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  // indexIdsArr循环的当次的frameWorkType
  const getChartData = async (searchParams: any, indexIdsArr: any) => {
    const params = {
      ...searchParams,
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      indexIds: indexIdsArr,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
      dateType: searchForm?.dateType,
      indexType: "num", // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        const copyDataSourceArr = [...hasMetricIdDataSource];
        Object.keys(res?.data).forEach((item) => {
          copyDataSourceArr.filter((i) => i.metricId === item)[0].value =
            res?.data?.[item] !=='-' ? getNumberWithComma(res?.data?.[item], copyDataSourceArr.filter((i) => i.metricId === item)[0].digit) : '—';
        });
        const yoyParams = {
          ...searchParams,
          startDate: searchForm?.startDate + starthms,
          endDate: searchForm?.endDate + endhms,
          indexIds: indexIdsArr,
          isShopAll: searchForm?.isShopAll,
          isPlatformAll: searchForm?.isPlatformAll,
          dateType: searchForm?.dateType,
          indexType: "yoy",
        };
        const resYoy = await getGeneralChat(yoyParams);
        if (resYoy?.code === 200) {
          if (resYoy?.data) {
            Object.keys(resYoy?.data).forEach((item) => {
              copyDataSourceArr.filter((i) => i.metricId === item)[0].yoy =
                resYoy?.data?.[item];
            });
          }
        }

        // console.log("回购情况图表数据最终", copyDataSourceArr);
        setFinalDataSource(copyDataSourceArr);
      }
    }
  };
  const getSeries = () => {
    const finalSeries = [];
    finalDataSource
      .filter((item) => item.titleZh.includes("留存"))
      .forEach((item, index) => {
        const seriesBarObj = {
          name: item.filedName,
          type: "bar",
          barWidth: 10,
          itemStyle: {
            color: colors[index],
          },
          tooltip: {
            valueFormatter: function (value: string, index: number) {
              return value && value !== "-" ? value : "-";
            },
          },
          data: [
            item.value,
            finalDataSource.filter((item) => item.titleZh.includes("召回"))[
              index
            ].value,
            finalDataSource.filter((item) => item.titleZh.includes("沉默"))[
              index
            ].value,
          ],
          yAxisIndex: 0,
        };
        const seriesLineObj = {
          name: `${item.filedName}同比`,
          type: "line",
          barWidth: 10,
          itemStyle: {
            color: colors[index],
          },
          tooltip: {
            valueFormatter: function (value: string) {
              return value === '-' ? '-' : `${value}%`;
            },
          },
          data: [
            item.yoy,
            finalDataSource.filter((item) => item.titleZh.includes("召回"))[
              index
            ].yoy,
            finalDataSource.filter((item) => item.titleZh.includes("沉默"))[
              index
            ].yoy,
          ],
          yAxisIndex: 1,
        };
        finalSeries.push(seriesBarObj, seriesLineObj);
      });

    return finalSeries;
  };

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        selected: {
          Sales: true,
          Members: true,
          Sales同比: true,
          Members同比: true,
          Purchaser: false,
          Units: false,
          Transactions: false,
          ATV: false,
          FREQ: false,
          SPD: false,
          UPT: false,
          Purchaser同比: false,
          Units同比: false,
          Transactions同比: false,
          ATV同比: false,
          FREQ同比: false,
          SPD同比: false,
          UPT同比: false,
        },
        left: 24,
        right: 44,
        itemHeight: 10,
        itemWidth: 10,
        type: "scroll", // 启用滚动模式
        pageFormatter: "{current}/{total}", // 当前页/总页数
        pageIconSize: 14, // 分页按钮的大小
        pageIconColor: "rgba(0, 0, 0, 0.88)", // 分页按钮的颜色
        pageIcon: {
          previous: "M 0,0 L 0,10 L 10,5 Z", // 向左箭头（自定义路径）
          next: "M 0,0 L 10,5 L 0,10 Z", // 向右箭头（自定义路径）
        },
        data: [
          { name: "Sales", icon: "rect" },
          { name: "Purchaser", icon: "rect" },
          { name: "Members", icon: "rect" },
          { name: "Units", icon: "rect" },
          { name: "Transactions", icon: "rect" },
          { name: "ATV", icon: "rect" },
          { name: "FREQ", icon: "rect" },
          { name: "SPD", icon: "rect" },
          { name: "UPT", icon: "rect" },

          { name: "Sales同比", icon: "line" },
          { name: "Purchaser同比", icon: "line" },
          { name: "Members同比", icon: "line" },
          { name: "Units同比", icon: "line" },
          { name: "Transactions同比", icon: "line" },
          { name: "ATV同比", icon: "line" },
          { name: "FREQ同比", icon: "line" },
          { name: "SPD同比", icon: "line" },
          { name: "UPT同比", icon: "line" },
        ],
        formatter: function (name: string) {
          if (
            name === "Sales" ||
            name === "Purchaser" ||
            name === "Members" ||
            name === "Units" ||
            name === "Transactions" ||
            name === "ATV" ||
            name === "FREQ" ||
            name === "SPD" ||
            name === "UPT"
          ) {
            return name;
          } else if (name === "Sales同比") {
            return "{line1|} " + name;
          } else if (name === "Purchaser同比") {
            return "{line2|} " + name;
          } else if (name === "Members同比") {
            return "{line3|} " + name;
          } else if (name === "Units同比") {
            return "{line1|} " + name;
          } else if (name === "Transactions同比") {
            return "{line2|} " + name;
          } else if (name === "ATV同比") {
            return "{line3|} " + name;
          } else if (name === "FREQ同比") {
            return "{line1|} " + name;
          } else if (name === "SPD同比") {
            return "{line2|} " + name;
          } else if (name === "UPT同比") {
            return "{line3|} " + name;
          }
        },
        textStyle: {
          rich: {
            square: {
              width: 10, // 正方形宽度
              height: 10, // 正方形高度
              backgroundColor: "#5B8FF9", // 正方形颜色
            },
            line1: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "rgba(91, 143, 249, 0.85)", // 横线颜色
            },
            line2: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "#85E6BC", // 横线颜色
            },
            line3: {
              width: 10, // 横线宽度
              height: 2, // 横线高度
              backgroundColor: "#A1A5AB", // 横线颜色
            },
          },
        },
      },
      series: getSeries(),
      grid: {
        left: 24,
        right: 34,
        bottom: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          axisLabel: {
            interval: 0,
          },
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          data: ["Retention Existing", "Winback Existing", "Sleeping Existing"],
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          // min: 0,
          // interval: 0,
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          name: "",
          // min: 0,
          alignTicks: true,
          axisLabel: {
            formatter: "{value} %",
          },
        },
      ],
    };
    let chartDom = document.getElementById("situationChart");
    let myChart = echarts.init(chartDom);
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [finalDataSource]);
  return (
    <div className={styles.normalBox} style={{ height: 600, width: "100%" }}>
      <div className={styles.normalHeader}>
        <div>
          回购情况
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                不同客群的销售指标对比
              </span>
            }
          >
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div
        id="situationChart"
        style={{
          width: "100%",
          height: "518px",
          backgroundColor: "#fff",
          paddingRight: "4px",
          paddingTop: "24px",
        }}
      />
    </div>
  );
}

export default MemberFourQuadrants;
