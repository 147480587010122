/**
 * 商品销售追踪-销售明细
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  Col,
  Button,
  Select,
  Input,
  Form,
  Row,
  Table,
  Drawer,
  Space,
  Checkbox,
  message,
  Tooltip,
} from "antd";
import { RenderFunction } from "antd/es/_util/getRenderPropValue";
import type { CheckboxProps, TableProps } from "antd";
import type { TableColumnsType } from "antd";
import { mapValues } from "lodash";
import tableMap from "./tableMap";
import {
  getCategoryList,
  getSalesDetails,
  addCustomForSalesDetails,
  getCustomForSalesDetails,
  downSalesDetails,
} from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function SalesDetail(props) {
  const { goodsTableColumnMap, categoryTableColumnMap } = tableMap;
  //父组件切换spu/sku goodsType === '0' spu,goodsType === '1' sku
  // tabType === '0' 商品，tabType === '1' 品类
  const { goodsType, tabType, searchForm } = props;
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm();
  const [selectEnd, setSelectEnd] = useState(false);
  const [checkedAllShowColumn, setCheckedAllShowColumn] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  // 根据商品/品类类别，传字段名，返回字段值
  const getFiledList = (type: string, filedNameArr: any) => {
    const arr = [];
    if (tabType === "1") {
      filedNameArr.forEach((item) => {
        arr.push(
          categoryTableColumnMap.filter((i) => i.label === item)?.[0]?.value
        );
      });
    }
    if (tabType === "0") {
      filedNameArr.forEach((item) => {
        arr.push(
          goodsTableColumnMap.filter((i) => i.label === item)?.[0]?.value
        );
      });
    }
    return arr;
  };

  useEffect(() => {
    getCategoryList().then((res) => {
      if (res?.code === 200) {
        setCategoryOptions([
          { value: "0", label: "全部品类" },
          ...res.data.map((item) => ({ value: item, label: item })),
        ]);
      }
    });
  }, []);

  const getTableList = async (indexIds, ...otherParams) => {
    const pages = { pageNum: 1, pageSize: 10 };
    if (tabType === "0") {
      pages.pageNum = goodsPageInfo.current;
      pages.pageSize = goodsPageInfo.size;
    }
    if (tabType === "1") {
      pages.pageNum = categoryPageInfo.current;
      pages.pageSize = categoryPageInfo.size;
    }
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? ["all"]
        : searchForm?.platformType?.split(","),
      shopIds: searchForm?.shopId?.includes("all")
        ? ["all"]
        : searchForm?.shopId?.split(","),
      goodType:
        tabType === "1" ? "CATEGORY" : goodsType === "SPU" ? "SPU" : "SKU",
      indexIds:  [
          ...new Set(
            indexIds && indexIds.length > 0
          ? indexIds
          : tabType === "1"
          ? showColumnCategory
          : goodsType === "SPU"
          ? showColumnSpu
          : showColumnSku
          ),
        ],
      ...pages,
      ...otherParams[0],
      ...searchInfo,
      skuId: tabType === "1" ? "" : searchInfo.skuId,
      skuName: tabType === "1" ? "" : searchInfo.skuName,
      spuId: tabType === "1" ? "" : searchInfo.spuId,
      spuName: tabType === "1" ? "" : searchInfo.spuName,
      categoryList:
        searchInfo.categoryList.toString() === "0"
          ? []
          : searchInfo.categoryList.split(","),
    };
    await getSalesDetails(params).then((res) => {
      if (res?.code === 200) {
        if (tabType === "0") {
          setGoodsTableData(res.data.records);
          setGoodsPageInfo({
            current: res.data.current,
            size: res.data.size,
            total: res.data.total,
          });
        }
        if (tabType === "1") {
          setCategoryTableData(res.data.records);
          setCategoryPageInfo({
            current: res.data.current,
            size: res.data.size,
            total: res.data.total,
          });
        }
        // console.log(res.data.records, "===表格数据===");
      }
    });
  };

  // 购买人数
  const plainOptionsBuyNum = [
    "购买人数",
    "购买人数同比",
    "购买人数占比",
    "购买人数占比值同比",
  ];
  const defaultCheckedListBuyNum = ["购买人数", "购买人数占比"];
  const [checkedListBuyNum, setCheckedListBuyNum] = useState<string[]>(
    defaultCheckedListBuyNum
  );
  const checkAllBuyNum = plainOptionsBuyNum.length === checkedListBuyNum.length;
  const indeterminateBuyNum =
    checkedListBuyNum.length > 0 &&
    checkedListBuyNum.length < plainOptionsBuyNum.length;
  const onChangeBuyNum = (list: string[]) => {
    setCheckedListBuyNum(list);
  };
  const onCheckAllChangeBuyNum: CheckboxProps["onChange"] = (value) => {
    setCheckedListBuyNum(value ? plainOptionsBuyNum : []);
  };

  // 获取两数组的交集
  const intersection = (arr1, arr2) => {
    const set2 = new Set(arr2);
    return arr1.filter((item) => set2.has(item));
  };

  // 不属于某类别的
  const spuSetOptions = [
    "日期",
    "商品名称",
    "Spu_id",
    "条形码",
    "品类",
    "渠道",
    "店铺",
  ];
  const skuSetOptions = [
    "日期",
    "Sku名称",
    "Sku_id",
    "条形码",
    "品类",
    "渠道",
    "店铺",
  ];
  const categorySetOptions = ["日期", "品类", "渠道", "店铺"];
  const plainOptionsOthers =
    tabType === "0"
      ? goodsType === "SPU"
        ? spuSetOptions
        : skuSetOptions
      : categorySetOptions;
  const spuSetChecked = ["日期", "商品名称", "条形码", "Spu_id", "品类"];
  const skuSetChecked = ["日期", "Sku名称", "条形码", "Sku_id", "品类"];
  const categorySetChecked = ["日期", "品类"];
  const defaultCheckedListOthers =
    tabType === "0"
      ? goodsType === "SPU"
        ? spuSetChecked
        : skuSetChecked
      : categorySetChecked;
  const [checkedListOthers, setCheckedListOthers] = useState<string[]>(
    defaultCheckedListOthers
  );
  const onChangeOthers = (list: string[]) => {
    setCheckedListOthers(list);
  };
  const onCheckAllChangeOthers: CheckboxProps["onChange"] = (value) => {
    setCheckedListOthers(value ? plainOptionsOthers : []);
  };
  // 销售额
  const plainOptionsSalesVolume = ["销售额", "销售额同比"];
  const defaultCheckedListSalesVolume = ["销售额"];
  const [checkedListSalesVolume, setCheckedListSalesVolume] = useState<
    string[]
  >(defaultCheckedListSalesVolume);
  // const checkAllSalesVolume =
  //   plainOptionsSalesVolume.length === checkedListSalesVolume.length;
  // const indeterminateSalesVolume =
  //   checkedListSalesVolume.length > 0 &&
  //   checkedListSalesVolume.length < plainOptionsSalesVolume.length;
  const onChangeSalesVolume = (list: string[]) => {
    setCheckedListSalesVolume(list);
  };
  const onCheckAllChangeSalesVolume: CheckboxProps["onChange"] = (value) => {
    setCheckedListSalesVolume(value ? plainOptionsSalesVolume : []);
  };
  // 购买件数
  const plainOptionsSalesNums = ["购买件数", "购买件数同比"];
  const defaultCheckedListSalesNums = ["购买件数"];
  const [checkedListSalesNums, setCheckedListSalesNums] = useState<string[]>(
    defaultCheckedListSalesNums
  );
  const checkAllSalesNums =
    plainOptionsSalesNums.length === checkedListSalesNums.length;
  const indeterminateSalesNums =
    checkedListSalesNums.length > 0 &&
    checkedListSalesNums.length < plainOptionsSalesNums.length;
  const onChangeSalesNums = (list: string[]) => {
    setCheckedListSalesNums(list);
  };
  const onCheckAllChangeSalesNums: CheckboxProps["onChange"] = (value) => {
    setCheckedListSalesNums(value ? plainOptionsSalesNums : []);
  };
  // 订单数
  const plainOptionsOrderNums = ["订单数", "订单数同比"];
  const defaultCheckedListOrderNums = ["订单数"];
  const [checkedListOrderNums, setCheckedListOrderNums] = useState<string[]>(
    defaultCheckedListOrderNums
  );
  const checkAllOrderNums =
    plainOptionsOrderNums.length === checkedListOrderNums.length;
  const indeterminateOrderNums =
    checkedListOrderNums.length > 0 &&
    checkedListOrderNums.length < plainOptionsOrderNums.length;
  const onChangeOrderNums = (list: string[]) => {
    setCheckedListOrderNums(list);
  };
  const onCheckAllChangeOrderNums: CheckboxProps["onChange"] = (value) => {
    setCheckedListOrderNums(value ? plainOptionsOrderNums : []);
  };

  // 新客
  const plainOptionsNewCustoms = [
    "购买人数",
    "购买人数同比",
    "购买人数占比",
    "购买人数占比同比",
    "销售额",
    "销售额同比",
    "购买件数",
    "购买件数同比",
    "订单数",
    "订单数同比",
  ];
  const defaultCheckedListNewCustoms = [
    "购买人数",
    "购买人数占比",
    "销售额",
    "购买件数",
    "订单数",
  ];
  const [checkedListNewCustoms, setCheckedListNewCustoms] = useState<string[]>(
    defaultCheckedListNewCustoms
  );
  const checkAllNewCustoms =
    plainOptionsNewCustoms.length === checkedListNewCustoms.length;
  const indeterminateNewCustoms =
    checkedListNewCustoms.length > 0 &&
    checkedListNewCustoms.length < plainOptionsNewCustoms.length;
  const onChangeNewCustoms = (list: string[]) => {
    setCheckedListNewCustoms(list);
  };
  const onCheckAllChangeNewCustoms: CheckboxProps["onChange"] = (value) => {
    setCheckedListNewCustoms(value ? plainOptionsNewCustoms : []);
  };

  // 老客
  const plainOptionsOldCustoms = [
    "购买人数",
    "购买人数同比",
    "购买人数占比",
    "购买人数占比同比",
    "销售额",
    "销售额同比",
    "购买件数",
    "购买件数同比",
    "订单数",
    "订单数同比",
  ];
  const defaultCheckedListOldCustoms = [
    "购买人数",
    "购买人数占比",
    "销售额",
    "购买件数",
    "订单数",
  ];
  const [checkedListOldCustoms, setCheckedListOldCustoms] = useState<string[]>(
    defaultCheckedListOldCustoms
  );
  const checkAllOldCustoms =
    plainOptionsOldCustoms.length === checkedListOldCustoms.length;
  const indeterminateOldCustoms =
    checkedListOldCustoms.length > 0 &&
    checkedListOldCustoms.length < plainOptionsOldCustoms.length;
  const onChangeOldCustoms = (list: string[]) => {
    setCheckedListOldCustoms(list);
  };
  const onCheckAllChangeOldCustoms: CheckboxProps["onChange"] = (value) => {
    setCheckedListOldCustoms(value ? plainOptionsOldCustoms : []);
  };

  // 会员
  const plainOptionsVip = [
    "购买人数",
    "购买人数同比",
    "购买人数占比",
    "购买人数占比同比",
    "销售额",
    "销售额同比",
    "购买件数",
    "购买件数同比",
    "订单数",
    "订单数同比",
    "新会购买人数",
    "老会购买人数",
  ];
  const defaultCheckedListVip = [
    "购买人数",
    "购买人数占比",
    "销售额",
    "购买件数",
    "订单数",
    "新会购买人数",
    "老会购买人数",
  ];
  const [checkedListVip, setCheckedListVip] = useState<string[]>(
    defaultCheckedListVip
  );
  const checkAllVip = plainOptionsVip.length === checkedListVip.length;
  const indeterminateVip =
    checkedListVip.length > 0 && checkedListVip.length < plainOptionsVip.length;
  const onChangeVip = (list: string[]) => {
    setCheckedListVip(list);
  };
  const onCheckAllChangeVip: CheckboxProps["onChange"] = (value) => {
    setCheckedListVip(value ? plainOptionsVip : []);
  };

  const [searchAllIndeterminate, setSearchAllIndeterminate] = useState(false);

  // 恢复默认数据
  const toDefault = () => {
    setKeepStatus(false);
    setCheckedListVip(defaultCheckedListVip);
    setCheckedListOldCustoms(defaultCheckedListOldCustoms);
    setCheckedListNewCustoms(defaultCheckedListNewCustoms);
    setCheckedListBuyNum(defaultCheckedListBuyNum);
    setCheckedListSalesVolume(defaultCheckedListSalesVolume);
    setCheckedListSalesNums(defaultCheckedListSalesNums);
    setCheckedListOrderNums(defaultCheckedListOrderNums);
    setCheckedListOthers(defaultCheckedListOthers);
  };

  useEffect(() => {
    const checkedLength =
      checkedListVip.length +
      checkedListOldCustoms.length +
      checkedListNewCustoms.length +
      checkedListBuyNum.length +
      checkedListSalesVolume.length +
      checkedListSalesNums.length +
      checkedListOrderNums.length +
      checkedListOthers.length;
    const allLength =
      plainOptionsVip.length +
      plainOptionsOldCustoms.length +
      plainOptionsNewCustoms.length +
      plainOptionsBuyNum.length +
      plainOptionsSalesVolume.length +
      plainOptionsSalesNums.length +
      plainOptionsOrderNums.length +
      plainOptionsOthers.length;
    const indeterminate = checkedLength > 0 && checkedLength < allLength;
    setSearchAllIndeterminate(indeterminate);
  }, [
    checkedListVip,
    checkedListOldCustoms,
    checkedListNewCustoms,
    checkedListBuyNum,
    checkedListSalesVolume,
    checkedListSalesNums,
    checkedListOrderNums,
    checkedListOthers,
  ]);
  //  表格列全选点击
  const setCheckedAll = () => {
    setCheckedAllShowColumn(!checkedAllShowColumn);
    if (!checkedAllShowColumn) {
      onCheckAllChangeBuyNum(plainOptionsBuyNum);
      onCheckAllChangeSalesVolume(plainOptionsSalesVolume);
      onCheckAllChangeSalesNums(plainOptionsSalesNums);
      onCheckAllChangeOrderNums(plainOptionsOrderNums);
      onCheckAllChangeNewCustoms(plainOptionsNewCustoms);
      onCheckAllChangeOldCustoms(plainOptionsOldCustoms);
      onCheckAllChangeVip(plainOptionsVip);
      onCheckAllChangeOthers(plainOptionsOthers);
    } else {
      onCheckAllChangeBuyNum();
      onCheckAllChangeSalesVolume();
      onCheckAllChangeSalesNums();
      onCheckAllChangeOrderNums();
      onCheckAllChangeNewCustoms();
      onCheckAllChangeOldCustoms();
      onCheckAllChangeVip();
      onCheckAllChangeOthers();
    }
  };

  const [columnSettingVisible, setColumnSettingVisible] = useState(false);
  const [keepStatus, setKeepStatus] = useState(false); // 是否保留本次更改
  const defaultSearchInfo = {
    categoryList: "0",
    skuName: "",
    skuId: "",
    spuName: "",
    spuId: "",
  };
  const [goodsPageInfo, setGoodsPageInfo] = useState({
    current: 1,
    size: 10,
    total: 0,
  });
  const [categoryPageInfo, setCategoryPageInfo] = useState({
    current: 1,
    size: 10,
    total: 0,
  });
  const [searchInfo, setSearchInfo] = useState<any>(defaultSearchInfo);
  const [goodsTableData, setGoodsTableData] = useState<DataType[]>([]);
  const [categoryTableData, setCategoryTableData] = useState<DataType[]>([]);

  const handelReset = () => {
    setSearchInfo(defaultSearchInfo);
  };

  // 打开自定义指标抽屉设置表格显示字段
  const onOpenSettingColumn = () => {
    setColumnSettingVisible(true);
    // getCustomFiled(
    //   tabType === "0" ? (goodsType === "SPU" ? "SPU" : "SKU") : "CATEGORY",
    //   false
    // );
  };
  // 关闭字段设置
  const onCloseSettingColumn = () => {
    setColumnSettingVisible(false);
  };

  const defaultArr = [
    ...defaultCheckedListOthers,
    ...defaultCheckedListBuyNum,
    ...defaultCheckedListSalesVolume,
    ...defaultCheckedListSalesNums,
    ...defaultCheckedListOrderNums,
    ...defaultCheckedListNewCustoms.map((item) => `新客${item}`),
    ...defaultCheckedListOldCustoms.map((item) => `老客${item}`),
    ...defaultCheckedListVip.map((item) => `会员${item}`),
  ];

  const [showColumnSpu, setShowColumnSpu] = useState([]); // 所选spu展示列
  const [showColumnSpuCopy, setShowColumnSpuCopy] = useState([]); // 所选spu展示列-用于反显抽屉配置项目
  const [showColumnSku, setShowColumnSku] = useState(
    getFiledList("0", defaultArr)
  ); // 所选sku展示列
  const [showColumnCategory, setShowColumnCategory] = useState([]); // 所选品类展示列

  useEffect(() => {
    // toDefault();
    if (channelAllList.length > 0) {
      if (tabType === "1") {
        getCustomFiled("CATEGORY", true);
      }
      if (tabType === "0") {
        if (goodsType === "SPU") {
          getCustomFiled("SPU", true);
        }
        if (goodsType === "SKU") {
          getCustomFiled("SKU", true);
        }
      }
    }
    setAscListArr([]);
  }, [tabType, goodsType, channelAllList, searchForm]);

  const getCustomFiled = (type, refresh) => {
    if (searchInfo.categoryList === '') {
      message.warning("请至少选择一个品类")
      return false;
    }
    const params = { type };
    // 默认显示的表格列名 如果勾选了记住此次选择，则从接口获取上次保存的指标选项
    const defaultArr = [
      ...defaultCheckedListOthers,
      ...defaultCheckedListBuyNum,
      ...defaultCheckedListSalesVolume,
      ...defaultCheckedListSalesNums,
      ...defaultCheckedListOrderNums,
      ...defaultCheckedListNewCustoms.map((item) => `新客${item}`),
      ...defaultCheckedListOldCustoms.map((item) => `老客${item}`),
      ...defaultCheckedListVip.map((item) => `会员${item}`),
    ];
    getCustomForSalesDetails(params).then((res) => {
      if (res?.code === 200) {
        let defaultFiledArr;
        // 有数据赋值，没数据赋默认初始选项
        if (
          res.data.length === 0 ||
          (res.data.length === 1 && res.data[0] === "")
        ) {
          toDefault();
          setKeepStatus(false);
          defaultFiledArr =
            res.data.length === 0 ||
            (res.data.length === 1 && res.data[0] === "")
              ? getFiledList(type === "CATEGORY" ? "1" : "0", defaultArr)
              : res.data;
          // setShowColumnCategory(defaultFiledArr);
          if (type === "SKU") {
            setShowColumnSku(defaultFiledArr);
          }
          if (type === "SPU") {
            setShowColumnSpu(defaultFiledArr);
          }
          if (type === "CATEGORY") {
            setShowColumnCategory(defaultFiledArr);
          }
          if (refresh) {
            getTableList(defaultFiledArr, {
              pageNum: 1,
              pageSize: 10,
              ascList: [],
            });
          }
          // 处理新客、老客、会员 子项的长度
          const dataMap =
            tabType === "1" ? categoryTableColumnMap : goodsTableColumnMap;
          const newDataMap = [];
          const oldDataMap = [];
          const vipDataMap = [];
          defaultFiledArr.forEach((item) => {
            if (
              dataMap
                .filter((i) => i.value === item)?.[0]
                ?.label.indexOf("新客") > -1
            ) {
              newDataMap.push(
                dataMap
                  .filter((findItem) => findItem.value === item)?.[0]
                  ?.label.slice(2)
              );
              setCheckedListNewCustoms(newDataMap);
            }
            if (
              dataMap
                .filter((i) => i.value === item)?.[0]
                ?.label.indexOf("老客") > -1
            ) {
              oldDataMap.push(
                dataMap
                  .filter((findItem) => findItem.value === item)?.[0]
                  ?.label.slice(2)
              );
              setCheckedListOldCustoms(oldDataMap);
            }
            if (
              dataMap
                .filter((i) => i.value === item)?.[0]
                ?.label.indexOf("会员") > -1
            ) {
              vipDataMap.push(
                dataMap
                  .filter((findItem) => findItem.value === item)?.[0]
                  ?.label.slice(2)
              );
              setCheckedListVip(vipDataMap);
            }
          });
        } else {
          setKeepStatus(true);
          if (type === "SKU") {
            setShowColumnSku(res.data);
          }
          if (type === "SPU") {
            setShowColumnSpu(res.data);
          }
          if (type === "CATEGORY") {
            setShowColumnCategory(res.data);
          }
          // 勾选上次所保留的更改项
          const mapSource =
            tabType === "0" ? goodsTableColumnMap : categoryTableColumnMap;
          let checkedLabelArr = [];
          res.data.forEach((item) => {
            checkedLabelArr.push(
              mapSource.filter((i) => i.value === item)?.[0]?.label
            );
          });
          // 不属于某类别的
          // 区分sku spu category
          let checkedOthers = [];
          if (tabType === "0") {
            if (goodsType === "SPU") {
              checkedOthers = intersection(checkedLabelArr, spuSetOptions);
            } else {
              checkedOthers = intersection(checkedLabelArr, skuSetOptions);
            }
            setCheckedListOthers(checkedOthers);
          } else {
            checkedOthers = intersection(checkedLabelArr, categorySetOptions);
            setCheckedListOthers(checkedOthers);
          }
          // 订单数
          const checkedOrderNums = intersection(
            checkedLabelArr,
            plainOptionsOrderNums
          );
          setCheckedListOrderNums(checkedOrderNums);
          // 购买人数
          const checkedNums = intersection(checkedLabelArr, plainOptionsBuyNum);
          setCheckedListBuyNum(checkedNums);
          // 销售额
          const checkedSales = intersection(
            checkedLabelArr,
            plainOptionsSalesVolume
          );
          setCheckedListSalesVolume(checkedSales);
          // 购买件数
          const checkedBuyNum = intersection(
            checkedLabelArr,
            plainOptionsSalesNums
          );
          setCheckedListSalesNums(checkedBuyNum);
          // 新客
          const checkedNewCustoms = intersection(
            checkedLabelArr,
            plainOptionsNewCustoms.map((i) => `新客${i}`)
          );
          setCheckedListNewCustoms(checkedNewCustoms.map((i) => i.slice(2)));
          // 老客
          const checkedOldCustoms = intersection(
            checkedLabelArr,
            plainOptionsOldCustoms.map((i) => `老客${i}`)
          );
          setCheckedListOldCustoms(checkedOldCustoms.map((i) => i.slice(2)));
          // 会员
          const checkedVip = intersection(
            checkedLabelArr,
            plainOptionsVip.map((i) => `会员${i}`)
          );
          setCheckedListVip(checkedVip.map((i) => i.slice(2)));
          if (refresh) {
            getTableList(res.data, {
              pageNum: 1,
              pageSize: 10,
              ascList: [],
            });
          }
        }
      }
    });
  };

  // 根据默认展示的列名
  // 提交字段设置
  const confirmSettingColumn = () => {
    const checkedLength =
      checkedListVip.length +
      checkedListOldCustoms.length +
      checkedListNewCustoms.length +
      checkedListBuyNum.length +
      checkedListSalesVolume.length +
      checkedListSalesNums.length +
      checkedListOrderNums.length +
      checkedListOthers.length;
    if (checkedLength === 0) {
      message.warning("请选择至少一个指标");
      return;
    }
    // 拼接全部所选配置项数组名称
    const showColumnLabelArr = [
      ...checkedListOthers,
      ...checkedListOrderNums,
      ...checkedListSalesNums,
      ...checkedListSalesVolume,
      ...checkedListBuyNum,
      ...checkedListNewCustoms.map((item) => `新客${item}`),
      ...checkedListOldCustoms.map((item) => `老客${item}`),
      ...checkedListVip.map((item) => `会员${item}`),
    ];

    const baseFiled =
      tabType === "0"
        ? goodsType === "SPU"
          ? ["日期", "商品名称", "Spu_id", "条形码"]
          : ["日期", "Sku名称", "Sku_id", "条形码"]
        : ["日期", "品类"];
    const finalFiled = baseFiled.concat(showColumnLabelArr);
    // 品类 到品类categoryTableColumnMap下找字段名传给后端
    if (tabType === "1") {
      setShowColumnCategory(getFiledList("1", finalFiled));
      getTableList(getFiledList("1", finalFiled));
      saveCheckedStatus("CATEGORY", getFiledList("0", finalFiled));
    }
    // 商品 到商品goodsTableColumnMap下找字段名传给后端
    if (tabType === "0") {
      if (goodsType === "SPU") {
        setShowColumnSpu(getFiledList("0", finalFiled));
        getTableList(getFiledList("0", finalFiled));
        saveCheckedStatus("SPU", getFiledList("0", finalFiled));
      }
      if (goodsType === "SKU") {
        setShowColumnSku(getFiledList("0", finalFiled));
        getTableList(getFiledList("0", finalFiled));
        saveCheckedStatus("SKU", getFiledList("0", finalFiled));
      }
    }
    setColumnSettingVisible(false);
  };

  // 保留本次更改至下次打开
  const saveCheckedStatus = (type, list) => {
    const params = {
      type,
      list: keepStatus ? list : [],
    };
    addCustomForSalesDetails(params).then((res) => {
      if (res?.code === 200) {
      }
    });
  };
  // 列是否展示 key 当前key, tableType 表格类型:sku spu category
  const ifShowCurrentColumn = (key: string, tableType: string) => {
    // 根据表格字段列的key到key value中找当前的label
    const columnMap =
      tableType === "category" ? categoryTableColumnMap : goodsTableColumnMap;
    if (tableType === "sku") {
      return !showColumnSku.includes(key);
    }
    if (tableType === "spu") {
      return !showColumnSpu.includes(key);
    }
    if (tableType === "category") {
      return !showColumnCategory.includes(key);
    }
  };

  interface DataType {}

  // 表格排序数组
  const [ascListArr, setAscListArr] = useState([]);

  const getSortStatus = (columnKey: string) => {
    return ascListArr.filter((item) => item.columnKey === columnKey).length > 0
      ? ascListArr.filter((item) => item.columnKey === columnKey)[0].order
      : null;
  };

  // 商品-columns
  const goodsColumns: TableProps<DataType>["columns"] = [
    {
      title: "日期",
      className: "highlight-column",
      dataIndex: "bizDate",
      key: "bizDate",
      width: "140px",
      fixed: "left",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("bizDate"),
    },
    {
      title: "商品名称",
      width: "140px",
      dataIndex: "spuName",
      key: "spuName",
      fixed: "left",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("spuName"),
      render: (value: string) => {
        return value?.length > 40 ? (
          <Tooltip overlayInnerStyle={{ width: "400px" }} title={value}>
            {value.substring(0, 40)}
            {value.length > 40 && <span>......</span>}
          </Tooltip>
        ) : (
          value
        );
      },
    },
    {
      title: "Sku名称",
      width: "140px",
      dataIndex: "skuName",
      key: "skuName",
      fixed: "left",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("skuName"),
      render: (value: string) => {
        return value?.length > 40 ? (
          <Tooltip overlayInnerStyle={{ width: "400px" }} title={value}>
            {value.substring(0, 40)}
            {value.length > 40 && <span>......</span>}
          </Tooltip>
        ) : (
          value
        );
      },
    },
    {
      title: "Spu_id",
      dataIndex: "spuId",
      key: "spuId",
      width: "140px",
      fixed: "left",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("spuId"),
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "Sku_id",
      dataIndex: "skuId",
      key: "skuId",
      fixed: "left",
      width: "140px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("skuId"),
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "条形码",
      dataIndex: "barCode",
      key: "barCode",
      fixed: "left",
      width: "120px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("barCode"),
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "品类",
      dataIndex: "categoryPrimaryName",
      key: "categoryPrimaryName",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("categoryPrimaryName"),
      width: "120px",
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "渠道",
      dataIndex: "platformType",
      key: "platformType",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("platformType"),
      width: "80px",
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "店铺",
      dataIndex: "shopId",
      key: "shopId",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("shopId"),
      width: "160px",
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "销售额",
      dataIndex: "sales",
      key: "sales",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("sales"),
      width: "120px",
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "销售额同比",
      dataIndex: "salesYoy",
      key: "salesYoy",
      defaultSortOrder: "",
      width: "120px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买人数",
      dataIndex: "buyNum",
      key: "buyNum",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("buyNum"),
      width: "120px",
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "购买人数同比",
      dataIndex: "buyNumYoy",
      key: "buyNumYoy",
      defaultSortOrder: "",
      width: "120px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买人数占比",
      dataIndex: "buyNumRate",
      key: "buyNumRate",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("buyNumRate"),
      width: "160px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买人数占比值同比",
      dataIndex: "buyNumRateYoy",
      key: "buyNumRateYoy",
      defaultSortOrder: "",
      width: "160px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买件数",
      dataIndex: "skuNum",
      key: "skuNum",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("skuNum"),
      width: "120px",
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "购买件数同比",
      dataIndex: "skuNumYoy",
      key: "skuNumYoy",
      defaultSortOrder: "",
      width: "120px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "订单数",
      dataIndex: "ordNum",
      key: "ordNum",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("ordNum"),
      width: "120px",
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "订单数同比",
      dataIndex: "ordNumYoy",
      key: "ordNumYoy",
      defaultSortOrder: "",
      width: "120px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "新客",
      children: [
        {
          title: "购买人数",
          dataIndex: "newBuyNum",
          key: "newBuyNum",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买人数同比",
          dataIndex: "newBuyNumYoy",
          key: "newBuyNumYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比",
          dataIndex: "newBuyNumRate",
          key: "newBuyNumRate",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newBuyNumRate"),
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比同比",
          dataIndex: "newBuyNumRateYoy",
          key: "newBuyNumRate",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "销售额",
          dataIndex: "newSales",
          key: "newSales",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newSales"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "销售额同比",
          dataIndex: "newSalesYoy",
          key: "newSalesYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买件数",
          dataIndex: "newSkuNum",
          key: "newSkuNum",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newSkuNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买件数同比",
          dataIndex: "newSkuNumYoy",
          key: "newSkuNumYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "订单数",
          dataIndex: "newOrdNum",
          key: "newOrdNum",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newOrdNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "订单数同比",
          dataIndex: "newOrdNumYoy",
          key: "newOrdNumYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
      ],
    },
    {
      title: "老客",
      children: [
        {
          title: "购买人数",
          dataIndex: "oldBuyNum",
          key: "oldBuyNum",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买人数同比",
          dataIndex: "oldBuyNumYoy",
          key: "oldBuyNumYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比",
          dataIndex: "oldBuyNumRate",
          key: "oldBuyNumRate",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldBuyNumRate"),
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比同比",
          dataIndex: "oldBuyNumRateYoy",
          key: "oldBuyNumRateYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "销售额",
          dataIndex: "oldSales",
          key: "oldSales",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldSales"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "销售额同比",
          dataIndex: "oldSalesYoy",
          key: "oldSalesYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买件数",
          dataIndex: "oldSkuNum",
          key: "oldSkuNum",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldSkuNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买件数同比",
          dataIndex: "oldSkuNumYoy",
          key: "oldSkuNumYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "订单数",
          dataIndex: "oldOrdNum",
          key: "oldOrdNum",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldOrdNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "订单数同比",
          dataIndex: "oldOrdNumYoy",
          key: "oldOrdNumYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
      ],
    },
    {
      title: "会员",
      children: [
        {
          title: "购买人数",
          dataIndex: "memberBuyNum",
          key: "memberBuyNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买人数同比",
          dataIndex: "memberBuyNumYoy",
          key: "memberBuyNumYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比",
          dataIndex: "memberBuyNumRate",
          key: "memberBuyNumRate",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberBuyNumRate"),
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比同比",
          dataIndex: "memberBuyNumRateYoy",
          key: "memberBuyNumRateYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "销售额",
          dataIndex: "memberSales",
          key: "memberSales",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberSales"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "销售额同比",
          dataIndex: "memberSalesYoy",
          key: "memberSalesYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买件数",
          dataIndex: "memberSkuNum",
          key: "memberSkuNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberSkuNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买件数同比",
          dataIndex: "memberSkuNumYoy",
          key: "memberSkuNumYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "订单数",
          dataIndex: "memberOrdNum",
          key: "memberOrdNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberOrdNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "订单数同比",
          dataIndex: "memberOrdNumYoy",
          key: "memberOrdNumYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "新会购买人数",
          dataIndex: "newMemberBuyNum",
          key: "newMemberBuyNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newMemberBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "老会购买人数",
          dataIndex: "oldMemberBuyNum",
          key: "oldMemberBuyNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldMemberBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
      ],
    },
  ];
  // 品类-columns
  const categoryColumns: TableProps<DataType>["columns"] = [
    {
      title: "日期",
      className: "highlight-column",
      dataIndex: "bizDate",
      key: "bizDate",
      width: "100px",
      fixed: "left",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("bizDate"),
    },
    {
      title: "品类",
      width: "120px",
      fixed: "left",
      dataIndex: "categoryPrimaryName",
      key: "categoryPrimaryName",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("categoryPrimaryName"),
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "渠道",
      dataIndex: "platformType",
      key: "platformType",
      width: "80px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("platformType"),
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "店铺",
      dataIndex: "shopId",
      key: "shopId",
      width: "160px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("shopId"),
      render: (value: string) => {
        return value ? value : "-";
      },
    },
    {
      title: "销售额",
      dataIndex: "sales",
      key: "sales",
      width: "120px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("sales"),
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "销售额同比",
      dataIndex: "salesYoy",
      key: "salesYoy",
      width: "120px",
      defaultSortOrder: "",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买人数",
      dataIndex: "buyNum",
      key: "buyNum",
      width: "120px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("buyNum"),
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "购买人数同比",
      dataIndex: "buyNumYoy",
      key: "buyNumYoy",
      width: "120px",
      defaultSortOrder: "",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买人数占比",
      dataIndex: "buyNumRate",
      key: "buyNumRate",
      width: "160px",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("buyNumRate"),
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买人数占比值同比",
      dataIndex: "buyNumRateYoy",
      key: "buyNumRateYoy",
      width: "160px",
      defaultSortOrder: "",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "购买件数",
      dataIndex: "skuNum",
      key: "skuNum",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("skuNum"),
      width: "120px",
      defaultSortOrder: "",
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "购买件数同比",
      dataIndex: "skuNumYoy",
      key: "skuNumYoy",
      width: "120px",
      defaultSortOrder: "",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "订单数",
      dataIndex: "ordNum",
      key: "ordNum",
      defaultSortOrder: "",
      sorter: {
        multiple: 1,
      },
      sortOrder: getSortStatus("ordNum"),
      width: "120px",
      render: (value: string) => {
        return value ? Number(value).toLocaleString() : "-";
      },
    },
    {
      title: "订单数同比",
      dataIndex: "ordNumYoy",
      key: "ordNumYoy",
      defaultSortOrder: "",
      width: "120px",
      render: (value: string) => {
        return value && value !== "-" ? `${value}%` : "-";
      },
    },
    {
      title: "新客",
      children: [
        {
          title: "购买人数",
          dataIndex: "newBuyNum",
          key: "newBuyNum",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买人数同比",
          dataIndex: "newBuyNumYoy",
          key: "newBuyNumYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比",
          dataIndex: "newBuyNumRate",
          key: "newBuyNumRate",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newBuyNumRate"),
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比同比",
          dataIndex: "newBuyNumRateYoy",
          key: "newBuyNumRate",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "销售额",
          dataIndex: "newSales",
          key: "newSales",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newSales"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "销售额同比",
          dataIndex: "newSalesYoy",
          key: "newSalesYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买件数",
          dataIndex: "newSkuNum",
          key: "newSkuNum",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newSkuNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买件数同比",
          dataIndex: "newSkuNumYoy",
          key: "newSkuNumYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "订单数",
          dataIndex: "newOrdNum",
          key: "newOrdNum",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newOrdNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "订单数同比",
          dataIndex: "newOrdNumYoy",
          key: "newOrdNumYoy",
          width: 200,
          className: `${styles.newCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
      ],
    },
    {
      title: "老客",
      children: [
        {
          title: "购买人数",
          dataIndex: "oldBuyNum",
          key: "oldBuyNum",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买人数同比",
          dataIndex: "oldBuyNumYoy",
          key: "oldBuyNumYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比",
          dataIndex: "oldBuyNumRate",
          key: "oldBuyNumRate",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldBuyNumRate"),
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比同比",
          dataIndex: "oldBuyNumRateYoy",
          key: "oldBuyNumRateYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "销售额",
          dataIndex: "oldSales",
          key: "oldSales",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldSales"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "销售额同比",
          dataIndex: "oldSalesYoy",
          key: "oldSalesYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买件数",
          dataIndex: "oldSkuNum",
          key: "oldSkuNum",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldSkuNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买件数同比",
          dataIndex: "oldSkuNumYoy",
          key: "oldSkuNumYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "订单数",
          dataIndex: "oldOrdNum",
          key: "oldOrdNum",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldOrdNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "订单数同比",
          dataIndex: "oldOrdNumYoy",
          key: "oldOrdNumYoy",
          width: 200,
          className: `${styles.oldCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
      ],
    },
    {
      title: "会员",
      children: [
        {
          title: "购买人数",
          dataIndex: "memberBuyNum",
          key: "memberBuyNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买人数同比",
          dataIndex: "memberBuyNumYoy",
          key: "memberBuyNumYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比",
          dataIndex: "memberBuyNumRate",
          key: "memberBuyNumRate",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberBuyNumRate"),
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买人数占比同比",
          dataIndex: "memberBuyNumRateYoy",
          key: "memberBuyNumRateYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "销售额",
          dataIndex: "memberSales",
          key: "memberSales",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberSales"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "销售额同比",
          dataIndex: "memberSalesYoy",
          key: "memberSalesYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "购买件数",
          dataIndex: "memberSkuNum",
          key: "memberSkuNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberSkuNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "购买件数同比",
          dataIndex: "memberSkuNumYoy",
          key: "memberSkuNumYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "订单数",
          dataIndex: "memberOrdNum",
          key: "memberOrdNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("memberOrdNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "订单数同比",
          dataIndex: "memberOrdNumYoy",
          key: "memberOrdNumYoy",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          render: (value: string) => {
            return value && value !== "-" ? `${value}%` : "-";
          },
        },
        {
          title: "新会购买人数",
          dataIndex: "newMemberBuyNum",
          key: "newMemberBuyNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("newMemberBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
        {
          title: "老会购买人数",
          dataIndex: "oldMemberBuyNum",
          key: "oldMemberBuyNum",
          width: 200,
          className: `${styles.vipCustom}`,
          defaultSortOrder: "",
          sorter: {
            multiple: 1,
          },
          sortOrder: getSortStatus("oldMemberBuyNum"),
          render: (value: string) => {
            return value ? Number(value).toLocaleString() : "-";
          },
        },
      ],
    },
  ];

  const showParent = (title: string) => {
    // console.log("父类别是否存在", title);
    // console.log(checkedListNewCustoms.length, "新客有子么");
    if (title === "新客") {
      return checkedListNewCustoms.length === 0;
    }
    if (title === "老客") {
      return checkedListOldCustoms.length === 0;
    }
    if (title === "会员") {
      return checkedListVip.length === 0;
    }
  };

  const handelGoodsColumns = goodsColumns.map((item) =>
    "children" in item
      ? {
          ...item,
          hidden: showParent(item.title),
          children: item.children.map((child: any) => ({
            ...child,
            hidden: ifShowCurrentColumn(
              child.key as string,
              goodsType === "SPU" ? "spu" : "sku"
            ),
          })),
        }
      : {
          ...item,
          hidden: ifShowCurrentColumn(
            item.key as string,
            goodsType === "SPU" ? "spu" : "sku"
          ),
        }
  );

  const handelCategoryColumns = categoryColumns.map((item) =>
    "children" in item
      ? {
          ...item,
          hidden: showParent(item.title),
          children: item.children.map((child: any) => ({
            ...child,
            hidden: ifShowCurrentColumn(child.key as string, "category"),
          })),
        }
      : {
          ...item,
          hidden: ifShowCurrentColumn(item.key as string, "category"),
        }
  );

  // 页面跳转或切换显示条数
  const pageChange = (page: any, size: any, sorter: any) => {
    let sorterSearchFiled = [];
    let saveArr = [];
    if (Array.isArray(sorter) && sorter.length > 0) {
      sorter.forEach((item) => {
        sorterSearchFiled.push({
          index: item.columnKey,
          asc: item.order === "ascend" ? "asc" : "desc",
        });
        saveArr.push({
          columnKey: item.columnKey,
          order: item.order,
        });
      });
    } else {
      sorterSearchFiled.push({
        index: sorter.columnKey,
        asc: sorter.order === "ascend" ? "asc" : "desc",
      });
      saveArr.push({
        columnKey: sorter.columnKey,
        order: sorter.order,
      });
    }
    setAscListArr(saveArr);
    getListByPageAndSort({
      pageNum: page.current,
      pageSize: page.pageSize,
      ascList: sorterSearchFiled,
    });
  };

  const getListByPageAndSort = async (params: any) => {
    getTableList([], params);
  };

  // 传入数组返回对应字符串逗号分隔
  const getArrString = (list: string[], disabledText: string) => {
    const specialKey = "未匹配的品类";
    const key: string =
      list.length > 1
        ? list
            .filter((e: string) => e !== disabledText && e !== specialKey)
            .join(",") || ""
        : list.join(",");
    const channelKey =
      list.includes(disabledText) &&
      list.findIndex((e: string) => e === disabledText && e !== specialKey) !==
        0
        ? disabledText
        : key;
    // const key: string =
    //   list.length > 1
    //     ? list.filter((e: string) => e !== disabledText).join(",") || ""
    //     : list.join(",");
    // const channelKey =
    //   list.includes(disabledText) &&
    //   list.findIndex((e: string) => e === disabledText) !== 0
    //     ? disabledText
    //     : key;
    return channelKey;
  };
  const download = async () => {
    const params = {
      file: {
        moduleName: `行业看板-商品销售追踪-销售明细-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`,
        originalFilename: "销售明细",
        name: `销售明细-${dayjs().format("YYYYMMDDHHmmss")}`,
        taskLocation: "行业看板/商品销售追踪/销售明细",
      },
      query: {
        startDate: searchForm?.startDate,
        endDate: searchForm?.endDate,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? ["all"]
          : searchForm?.platformType?.split(","),
        shopIds: searchForm?.shopId?.includes("all")
          ? ["all"]
          : searchForm?.shopId?.split(","),
        goodType:
          tabType === "1" ? "CATEGORY" : goodsType === "SPU" ? "SPU" : "SKU",
        indexIds: [
          ...new Set(
            tabType === "1"
              ? showColumnCategory
              : goodsType === "SPU"
              ? showColumnSpu
              : showColumnSku
          ),
        ],
        ...searchInfo,
        categoryList:
          searchInfo.categoryList.toString() === "0"
            ? []
            : searchInfo.categoryList.split(","),
      },
    };
    const res = await downSalesDetails(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };

  const getDisabledStatus = (label) => {
    const disabledArr = ["日期", "Spu_id", "商品名称", "Sku_id", "sku_name"];
    return disabledArr.includes(label);
  };

  const getDisabledStatusCategory = (label) => {
    const disabledArr = ["日期", "品类"];
    return disabledArr.includes(label);
  };

  return (
    <div className={styles.normalBox} style={{ height: 600, width: "100%" }}>
      <div className={styles.normalHeader}>
        <div>销售明细</div>
        <Button onClick={download}>导出</Button>
      </div>
      <Form form={form} name="advanced_search" style={{ width: "100%" }}>
        <Row gutter={[18, 0]}>
          <Col span={8}>
            <Form.Item label="品类：" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={categoryOptions}
                value={
                  categoryOptions.length > 0
                    ? searchInfo.categoryList
                      ? searchInfo.categoryList.split(",")
                      : []
                    : "全部品类"
                }
                onChange={(value) => {
                  const categoryValue = getArrString(value, "0");
                  setSearchInfo({
                    ...searchInfo,
                    categoryList: categoryValue,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={8}
            style={{
              marginBottom: "16px",
              visibility: `${tabType === "1" ? "hidden" : "visible"}`,
            }}
          >
            <Form.Item label="商品：" style={{ marginBottom: "16px" }}>
              <Input
                placeholder={
                  goodsType === "SPU" ? "请输入商品名称" : "请输入Sku名称"
                }
                value={
                  goodsType === "SPU" ? searchInfo.spuName : searchInfo.skuName
                }
                onChange={(e) => {
                  if (goodsType === "SPU") {
                    setSearchInfo({
                      ...searchInfo,
                      skuName: "",
                      spuName: e.target.value,
                    });
                  }
                  if (goodsType === "SKU") {
                    setSearchInfo({
                      ...searchInfo,
                      skuName: e.target.value,
                      spuName: "",
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={8}
            style={{
              marginBottom: "16px",
              visibility: `${tabType === "1" ? "hidden" : "visible"}`,
            }}
          >
            <Form.Item label="商品id：" style={{ marginBottom: "16px" }}>
              <Input
                placeholder={
                  goodsType === "SPU"
                    ? "请输入Spu_id/条形码"
                    : "请输入Sku_id/条形码"
                }
                value={
                  goodsType === "SPU" ? searchInfo.spuId : searchInfo.skuId
                }
                onChange={(e) => {
                  if (goodsType === "SPU") {
                    setSearchInfo({
                      ...searchInfo,
                      skuId: "",
                      spuId: e.target.value,
                    });
                  }
                  if (goodsType === "SKU") {
                    setSearchInfo({
                      ...searchInfo,
                      skuId: e.target.value,
                      spuId: "",
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col span={12} style={{ marginBottom: 24 }}>
            <Button
              onClick={() => {
                onOpenSettingColumn();
              }}
            >
              自定义指标
            </Button>
          </Col>
          <Col span={12} style={{ marginBottom: 24 }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button style={{ marginRight: 8 }} onClick={handelReset}>
                重置
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setAscListArr([]);
                  getCustomFiled(
                    tabType === "0"
                      ? goodsType === "SPU"
                        ? "SPU"
                        : "SKU"
                      : "CATEGORY",
                    true
                  );
                }}
              >
                查询
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className={styles.salesTable}>
        {tabType === "0" && (
          <Table
            columns={handelGoodsColumns}
            dataSource={goodsTableData}
            className={styles.customTable}
            pagination={{
              total: goodsPageInfo.total,
              showTotal: (total) => `共${total}条`,
              pageSize: goodsPageInfo.size,
              showSizeChanger: true,
            }}
            scroll={{ x: "max-content", y: 55 * 10 }}
            onChange={(page, pageSize, sort) =>
              pageChange(page, pageSize, sort)
            }
          />
        )}
        {tabType === "1" && (
          <Table
            columns={handelCategoryColumns}
            dataSource={categoryTableData}
            className={styles.customTable}
            pagination={{
              total: categoryPageInfo.total,
              showTotal: (total) => `共${total}条`,
              pageSize: categoryPageInfo.size,
              showSizeChanger: true,
            }}
            scroll={{ x: "max-content", y: 55 * 10 }}
            onChange={(page, pageSize, sort) =>
              pageChange(page, pageSize, sort)
            }
          />
        )}
      </div>

      <Drawer
        width={576}
        title="选择展示列"
        onClose={onCloseSettingColumn}
        open={columnSettingVisible}
        extra={
          <Space>
            <Button onClick={onCloseSettingColumn}>取消</Button>
            <Button type="primary" onClick={confirmSettingColumn}>
              确认
            </Button>
          </Space>
        }
      >
        <div className={styles.drawer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              marginBottom: 12,
            }}
          >
            <span>
              <Checkbox
                checked={keepStatus}
                onChange={() => {
                  setKeepStatus(!keepStatus);
                }}
                style={{ marginRight: 4 }}
              />
              保留本次更改至下次打开
            </span>
            <Button onClick={toDefault}>恢复默认</Button>
          </div>
          <div>
            <Checkbox
              checked={checkedAllShowColumn}
              onChange={() => {
                setCheckedAll();
              }}
              indeterminate={searchAllIndeterminate}
              style={{ marginRight: 4 }}
            />
            全选
          </div>
          <div className={styles.checkboxGroup}>
            <>
              {(tabType === "0"
                ? plainOptionsOthers.slice(0, 4)
                : plainOptionsOthers.slice(0, 2)
              ).map((item) => {
                return (
                  <Checkbox checked disabled={true}>
                    {item}
                  </Checkbox>
                );
              })}
              <CheckboxGroup
                options={
                  tabType === "0"
                    ? plainOptionsOthers.slice(4)
                    : plainOptionsOthers.slice(2)
                }
                value={checkedListOthers}
                onChange={onChangeOthers}
              />
              <br />
            </>
            <>
              <CheckboxGroup
                options={plainOptionsBuyNum}
                value={checkedListBuyNum}
                onChange={onChangeBuyNum}
              />
              <br />
            </>
            <>
              <CheckboxGroup
                options={plainOptionsSalesVolume}
                value={checkedListSalesVolume}
                onChange={onChangeSalesVolume}
              />
              <br />
            </>
            <>
              <CheckboxGroup
                options={plainOptionsSalesNums}
                value={checkedListSalesNums}
                onChange={onChangeSalesNums}
              />
              <br />
            </>
            <>
              <CheckboxGroup
                options={plainOptionsOrderNums}
                value={checkedListOrderNums}
                onChange={onChangeOrderNums}
              />
              <br />
            </>
            <>
              <Checkbox
                indeterminate={indeterminateNewCustoms}
                onChange={(e) => onCheckAllChangeNewCustoms(e.target.checked)}
                checked={checkAllNewCustoms}
              >
                新客
              </Checkbox>
              <br />
              <CheckboxGroup
                options={plainOptionsNewCustoms}
                value={checkedListNewCustoms}
                onChange={onChangeNewCustoms}
                style={{ margin: "10px 0 20px 20px" }}
              />
              <br />
            </>
            <>
              <Checkbox
                indeterminate={indeterminateOldCustoms}
                onChange={(e) => onCheckAllChangeOldCustoms(e.target.checked)}
                checked={checkAllOldCustoms}
              >
                老客
              </Checkbox>
              <br />
              <CheckboxGroup
                options={plainOptionsOldCustoms}
                value={checkedListOldCustoms}
                onChange={onChangeOldCustoms}
                style={{ margin: "10px 0 20px 20px" }}
              />
              <br />
            </>
            <>
              <Checkbox
                indeterminate={indeterminateVip}
                onChange={(e) => onCheckAllChangeVip(e.target.checked)}
                checked={checkAllVip}
              >
                会员
              </Checkbox>
              <br />
              <CheckboxGroup
                options={plainOptionsVip}
                value={checkedListVip}
                onChange={onChangeVip}
                style={{ margin: "10px 0 20px 20px" }}
              />
              <br />
            </>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default SalesDetail;
