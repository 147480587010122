import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  Modal,
  Dropdown,
  Space,
  Select,
  Popconfirm,
  message,
} from "antd";
import { getQueryParam } from '../../../../utils/tools';
import ModuleTitle from "../../../../component/ModuleTitle";
import BackList from '../../../../component/BackList/backList';
import { DeleteOutlined } from '@ant-design/icons';
// import {
//   getUserList,
// } from "../../../../api/UserInsight/UserInsight";
import { UserListQuery, UserListVO , RecordQuery } from "../../../../api/UserInsight/types";
import styles from "./index.module.scss";
import type { SearchProps } from 'antd/es/input/Search';
import { useNavigate } from "react-router-dom";
// import { Router } from "react-router-dom";
const { Option } = Select;
const { Search } = Input;
const UserSerch = () => { 
  const navigate = useNavigate();
  // 防连点
  const [responseFinished, setResponseFinished] = useState(false);
  //数据列表
  const [listData, setListData] = useState([] as UserListVO[]);
  //手机号还是oneid查询字段
  const [phoneorId, setPhoneorId] = useState("mobile");
  //输入的数据
  const [inputValue, setInputValue] = useState('');
  
  //改变手机号还是oneid查询key
  const changePhoneorId = (data:any) => {
    setPhoneorId(data)
  };
  const selectBefore = (
    <Select defaultValue={phoneorId} onChange={changePhoneorId}>
      <Option value="mobile">手机号</Option>
      <Option value="platformId">平台ID</Option>
      <Option value="orderId">订单号</Option>
      <Option value="oneId">OneID</Option>
    </Select>
  );

  
  //查询记录
  const recordList = [
    {title: '分群名称：',value: '2324324324232'},
    {title: '分群ID：',value: '2324324324232'},
    {title: '分群人数：',value: '2324324324232'},
    {title: '状态：',value: '2324324324232'},//0是禁用  1 启用
    {title: '创建时间：',value: '23243243242322324324324232'},
    {title: '描述：',value:'2324324324232'}
  ];
  // 获取客户列表
  const getUserDetailList = async () => {
    const param = {
      [phoneorId] :inputValue
    } as any;
    let res;
    console.log("========",param);
    window.open(`/userInsight/userInsightDocument/UserDetail`);
    // res  = await getUserList(param);
    // var data:any = res.data
    // setListData(data.userInfos)
  };
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    getUserDetailList()
  };
  const onChange: SearchProps['onChange'] = (e:any) => {
    setInputValue(e.target.value)
  };
  useEffect(() => {
    // getUserDetailList(
    //   searcParam,
    // );
    return () => {

    };
  }, []);

  return (
    <div className={styles.UserSerch}>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="客户查询" />
        </div>
      </div>
      <div className={styles.contentSearch}>
        <Search
          placeholder="请输入"
          enterButton="立即查询"
          size="large"
          addonBefore={selectBefore}
          onSearch={onSearch}
          onChange={onChange}
          style={{
            fontSize: 'var(--text-font5)',
            width:'30%',
          }}
        />
        <div className={styles.contentSearch_Record}>
          <div style={{marginBottom:'8px'}}>
            <span style={{marginRight:'8px'}}>查询记录</span>
            {recordList.length > 0 && <DeleteOutlined />}
          </div>
          <div className={styles.contentSearch_listBox}>
            {recordList.map((item, index) => (
              <div className={styles.recordListItem} key={index}>
                <div>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSerch;
