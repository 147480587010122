import React from 'react';
import ReactDom from 'react-dom';
import { useState, useEffect, useRef } from 'react';
import { Graph, Shape, ToolsView, EdgeView, Timing, Interp } from '@antv/x6';

import styles from './index.module.scss';
import { Button, Alert, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import busEvent from '@/utils/bus';
import YLIcon from '../../../../../src/component/Icon/YLIcon.jsx';
import type { Dayjs } from 'dayjs';
import { iteratee } from 'lodash';
// #region初始化图形
const ScanTab = (props: { graphStatus: any; allNodes: any }) => {
  const { graphStatus = () => {}, allNodes } = props;
  // const openPop = () => {};
  const [errorObj, setErrorObj] = useState<any>([]);
  const hasDuplicateNames = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr.some((obj: any, index: any) => index !== i && obj.name === arr[i].name)) {
        return true; // 如果找到重复的name，返回true
      }
    }
    return false; // 如果没有找到重复的name，返回false
  };
  //检测节点
  const checkNodes = (nodes: any) => {
    var arr = [] as any; // 可疑配置数据
    var mesArr = [] as any;
    // scanNodes
    let scanNodes = nodes.filter((item: any) => item.shape !== 'edge' && item.shape !== 'custom-add');
    let scanAdds = nodes.filter((item: any) => item.shape === 'custom-add' && item.data.label === '添加');
    console.log('=======nodes=========', nodes);
    scanNodes.forEach((item: any, index: number) => {
      if (item.data.componentId === 'START') {
        if (
          !item.data.strategyObj?.scnId ||
          !item.data.strategyObj?.name ||
          !item.data.strategyObj?.crowdId ||
          !item.data.strategyObj?.type ||
          !item.data.strategyObj?.endTime
        ) {
          arr.push({
            type: 'error',
            label: '开始',
            id: item.id,
            message: '数据不完整',
          });
        } else if (item.data.strategyObj.startTime) {
          if (dayjs().isAfter(dayjs(item.data.strategyObj.startTime))) {
            arr.push({
              type: 'error',
              label: '开始',
              id: item.id,
              message: '开始时间不能早于当前时间',
            });
          }
        } else if (item.data.strategyObj.endTime) {
          if (dayjs().isAfter(dayjs(item.data.strategyObj.endTime))) {
            arr.push({
              type: 'error',
              label: '开始',
              id: item.id,
              message: '结束时间不能早于当前时间',
            });
          }
        }
      }
      if (item.data.componentId === 'SMS') {
        mesArr.push(item);
        if (
          !item.data?.label ||
          !item.data?.account?.id ||
          !item.data?.sign?.id ||
          !item.data?.unsubscribe ||
          !item.data?.type ||
          (item.data?.type === 'template' && !item.data?.templateId) ||
          !item.data?.text
        ) {
          arr.push({
            type: 'error',
            label: item.data.label ? item.data.label : '短信',
            id: item.id,
            message: '数据不完整',
          });
        }
        //字符不可大于329,尾签6+签名中括号2
        let sign = Number(item.data.sign.content ? item.data.sign.content.length : 4) + 8;
        if (item.data?.text?.length + sign > 329) {
          arr.push({
            type: 'error',
            label: item.data.label ? item.data.label : '短信',
            id: item.id,
            message: '数据异常',
          });
        }
      }
      if (item.data.componentId === 'JOIN_GROUP') {
        if (!item.data?.label || !item.data?.crowdId) {
          arr.push({
            type: 'error',
            label: item.data.label ? item.data.label : '加入分群',
            id: item.id,
            message: '数据不完整',
          });
        }
      }
      if (item.data.componentId === 'SPLITTER') {
        var status = false;
        let sum = 0;
        item.data.branch?.forEach((itm: any) => {
          if (Math.floor(sum) === sum) {
            sum = sum; // 如果是整数，直接返回
          } else {
            sum = parseFloat(sum.toFixed(1)); // 如果是小数，保留两位小数
          }
          sum += itm.value;
          if (!itm.name || !itm.value) {
            status = true;
          }
        });
        if (!item.data?.label || !item.data.branch || status || sum !== 100 || hasDuplicateNames(item.data.branch)) {
          arr.push({
            type: 'error',
            label: item.data.label ? item.data.label : '分流器',
            id: item.id,
            message: '数据不完整',
          });
        }
      }
      if (item.data.componentId === 'WAIT') {
        if (
          !item.data?.label ||
          (item.data?.type === 'duration' && !(item.data?.day || item.data?.hour || item.data?.minute)) ||
          (item.data?.type === 'until' && !item.data?.date) ||
          (item.data?.type === 'next' && !item.data?.time)
        ) {
          arr.push({
            type: 'error',
            label: item.data.label ? item.data.label : '等待',
            id: item.id,
            message: '数据不完整',
          });
        }
      }
    });
    if (scanAdds.length > 0) {
      arr.push({
        type: 'error',
        label: '',
        id: '',
        message: '未配置结束组件',
      });
    }
    // 用于存储分组后的结果
    const groupedMessages = {} as any;
    mesArr.forEach((item: any) => {
      console.log(999, item);
      const { text } = item.data;
      // 生成唯一的键
      const key = `${text}`;
      // 检查键是否已经存在
      if (!groupedMessages[key]) {
        groupedMessages[key] = [];
      }
      // 将当前对象添加到对应的分组
      groupedMessages[key].push(item);
    });
    // 将分组结果转换为数组
    const result = Object.values(groupedMessages);
    result.forEach((itm: any, inx: number) => {
      if (itm?.length > 1) {
        arr.push({
          type: 'warning',
          label: itm,
          // id: itm.map((i:any) => i.id).join(' '),
          message: '数据相同',
        });
      }
    });

    setErrorObj(arr);
    if (arr.length === 0) {
      busEvent.emit('scanNode', 'success');
    } else if (arr.filter((item: any) => item.type === 'warning').length === arr.length) {
      //只有警告
      busEvent.emit('scanNode', 'success');
    } else {
      busEvent.emit('scanNode', { status: 'error', arr });
    }
  };
  useEffect(() => {
    // paramNodes = props.allNodes.cells.filter((item: any) => item.shape !== 'edge' && item.shape !== 'custom-add');
    checkNodes(allNodes);
  }, [props.allNodes]);
  useEffect(() => {
    //删除节点时 自动走 扫描
    busEvent.on('scanTabBegin', checkNodes);
    return () => {
      busEvent.off('scanTabBegin', checkNodes);
    };
  }, []);
  return (
    <div className={styles['scanTab-box']}>
      <div className={styles['scanTabTop']}>
        <div style={{ color: 'var(--text-font-color12)' }}>支持扫描以下可疑配置：</div>
        <ul style={{ padding: ' 1px 0px 0px 28px', marginBottom: 0 }}>
          <li>节点配置内容不完整</li>
          <li>节点关联数据异常，例如关联优惠券已失效或已删除</li>
          <li>多个节点关联相同数据，例如关联相同短消息</li>
        </ul>
      </div>
      <div
        className={`${styles['scanTabMargin24']} ${styles['scanTabButton']}`}
        style={{ justifyContent: errorObj.length > 0 ? 'space-between' : 'flex-end' }}>
        {errorObj.length > 0 && (
          <div>
            共扫描出<span className={styles['scanTabButton_text']}>{errorObj.length}</span>个可疑配置
          </div>
        )}
        {/* <Button>重新扫描</Button> */}
      </div>
      <div>
        {errorObj.map((item: any, index: number) => (
          <Alert
            key={index}
            message={
              <>
                {/* 用于展示label不是数组的情况 */}
                {!Array.isArray(item.label) && (
                  <span
                    className={styles['scanErrorText']}
                    style={{ margin: !item.label ? '0' : '' }}
                    onClick={() => {
                      busEvent.emit('scanNode', item.id ? item.id : item);
                    }}>
                    {item.label}
                  </span>
                )}
                {/* 用于展示label是数组的情况  短信数据相同校验*/}
                {Array.isArray(item.label) && (
                  <>
                    {item.label.map((itm: any, indx: number) => (
                      <span
                        onClick={() => {
                          busEvent.emit('scanNode', itm.id);
                        }}>
                        <span className={styles['scanErrorText']}>{itm.data.label}</span>
                      </span>
                    ))}
                  </>
                )}
                <span>{item.message}</span>
              </>
            }
            type={item.type}
            showIcon
            className={`${styles['scanTabMargin8']}`}
          />
        ))}
      </div>
      {errorObj.length < 1 && (
        <div className={styles['scanTabSuccess']}>
          <YLIcon type="icon-dui" size={65} color={'var(--text-font-color19)'}></YLIcon>
          <div className={styles['scanTabSuccessText']}>配置正常，可继续执行!</div>
        </div>
      )}
    </div>
  );
};
export default ScanTab;
